<template>
  {{ compute.profileLabel }}
</template>

<script setup lang="ts">
import { EnumRoles, getRoleById, type Role } from '@/utilities/user-roles'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Props {
  profile: EnumRoles
}
const props = defineProps<Props>()

const compute = reactive({
  userType: computed((): Role => {
    return getRoleById(props.profile)
  }),

  profileLabel: computed((): string => {
    return i18n.t(`mywb.common.${compute.userType.name}`)
  })
})
</script>
