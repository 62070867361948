<template>
  <auth-layout
    v-if="isCurrentState('typeSelector')"
    v-bind="$attrs"
    :play-animation="props.playAnimation"
  >
    <template #title>
      <p class="is-size-500">
        <span
          v-t="'mywb.auth.login-signup'"
          class="is-font-weight-700"
        />
        <span>&nbsp;</span>
        <span v-t="'mywb.auth.login-signup-explain'" />
      </p>
    </template>
    <wb-form class="justify-center">
      <auth-login-apple-button
        data-test-id="appleButton"
        @on-click="methods.appleRequest"
      />
      <auth-login-google-button
        data-test-id="googleButton"
        @on-click="methods.googleRequest"
      />
      <wb-button
        class="login-button"
        data-test-id="continueEmailButton"
        @click="methods.handleEmailProvider"
      >
        <span class="wb-icons mr-8 is-size-400">email</span>
        {{ i18n.t('mywb.auth.continue-email') }}
      </wb-button>
      <p class="is-size-400 has-margin-auto">
        <span v-t="'mywb.auth.new-user'" />
        <span>&nbsp;</span>
        <wb-link
          data-test-id="registerButton"
          @click="methods.handleEmailRegister"
        >
          {{ i18n.t('mywb.auth.start-here') }}
        </wb-link>
      </p>
    </wb-form>
  </auth-layout>

  <auth-login-name
    v-if="isCurrentState('appleSignupNeedUser')"
    data-test-id="authName"
    @back="send('back')"
    @continue-register="methods.appleName"
  />

  <auth-terms-and-conditions
    v-if="isCurrentState('termsAndConditions')"
    data-test-id="termsAndConditions"
    @back="send('back')"
    @terms-accept="info => methods.socialSignup(info)"
  />
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import lang from '@/engine/lang'

import AuthLoginGoogleButton from '@/components/auth/AuthLoginGoogleButton.vue'
import AuthLoginAppleButton from '@/components/auth/AuthLoginAppleButton.vue'
import AuthLayout from '@/components/auth/AuthLayout.vue'
import AuthTermsAndConditions from '@/components/auth/AuthTermsAndConditions.vue'
import AuthLoginName from '@/components/auth/AuthLoginName.vue'

import { useToast } from '@wallbox/toolkit-ui'
import { getServerError } from '@/utilities/errorMessages'
import { useStateMachine } from '@wallbox/hooks'
import { trackAction, trackEvent, trackScreen } from '@wallbox/metrics'
import { useI18n } from '@/hooks/useI18n.hook'
import { parseJwt } from '@/utilities/jwt'
import { HttpError } from '@wallbox/http'
import type { Auth } from '@/types'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { OAUTH_PROVIDER, USER_EMAIL_STATUS, type SignUp } from '@/core/auth'
import type { CountryIso2 } from '@/core/international'
import state from '@/state'
import ROUTES from '@/engine/router/routes'

const authUseCases = injectStrict(AUTH_USE_CASES)
const toast = useToast()
const router = useRouter()
const i18n = useI18n()

interface Events {
  (e: 'continue-email'): void
}

const emit = defineEmits<Events>()

const props = defineProps({
  playAnimation: {
    type: Boolean,
    default: false
  }
})

const { isCurrentState, send } = useStateMachine({
  initial: 'typeSelector',
  states: {
    typeSelector: {
      transitions: {
        googleSignup: 'termsAndConditions',
        appleSignup: 'termsAndConditions',
        appleSignupNeedUser: 'appleSignupNeedUser'
      },
      on: {
        googleSignup: () => trackScreen('terms', { provider: 'google' }),
        appleSignup: () => trackScreen('terms', { provider: 'apple' }),
        appleSignupNeedUser: () => trackScreen('name_apple', { provider: 'apple' })
      }
    },
    appleSignupNeedUser: {
      transitions: {
        back: 'typeSelector',
        next: 'termsAndConditions'
      },
      on: {
        termsAndConditions: () => trackScreen('terms', { provider: 'apple' })
      }
    },
    termsAndConditions: {
      transitions: {
        back: 'typeSelector'
      }
    }
  }
})

interface Data {
  socialFlow?: OAUTH_PROVIDER
  token: string
  name: string
  surname: string
}

const data: Data = reactive({
  token: '',
  name: '',
  surname: ''
})

const methods = {
  async googleRequest (token: string) {
    type GoogleJWT = { given_name: string, family_name: string, email: string }
    const info = parseJwt<GoogleJWT>(token)

    data.socialFlow = OAUTH_PROVIDER.GOOGLE
    data.token = token
    data.name = info.given_name
    data.surname = info.family_name

    try {
      const status = await authUseCases.getIsUserEmailRegistered(info)

      if (status === USER_EMAIL_STATUS.CONFIRMED) {
        trackAction('signin_submit', { provider: 'google', new_user: false })
        await methods.socialSignin()
      } else if (status === USER_EMAIL_STATUS.NOT_REGISTERED) {
        trackAction('signin_submit', { provider: 'google', new_user: true })
        send('googleSignup')
      } else if (status === USER_EMAIL_STATUS.NOT_CONFIRMED) {
        toast.error(getServerError({ code: status }))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  },

  async appleRequest ({ authorization, user }: Auth.AppleOauthResponse) {
    type AppleAWT = { email: string }
    const info = parseJwt<AppleAWT>(authorization.id_token)

    if (!info.email) {
      return toast.error(i18n.t('mywb.error.apple-login'))
    }

    data.socialFlow = OAUTH_PROVIDER.APPLE
    data.token = authorization.id_token
    data.name = user?.name?.firstName
    data.surname = user?.name?.lastName

    try {
      const status = await authUseCases.getIsUserEmailRegistered(info)

      if (status === USER_EMAIL_STATUS.CONFIRMED) {
        trackAction('signin_submit', { provider: 'apple', new_user: false })
        await methods.socialSignin()
      } else if (status === USER_EMAIL_STATUS.NOT_REGISTERED) {
        trackAction('signin_submit', { provider: 'apple', new_user: true })
        data.name ? send('appleSignup') : send('appleSignupNeedUser')
      } else if (status === USER_EMAIL_STATUS.NOT_CONFIRMED) {
        toast.error(getServerError({ code: status }))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  },

  handleEmailProvider () {
    trackAction('signin_continue', { provider: 'email' })
    emit('continue-email')
  },

  handleEmailRegister () {
    trackAction('register_email')
    emit('continue-email')
  },

  async socialSignin ({ isNewUser = false } = {}) {
    if (!data.socialFlow) return

    try {
      const userId = await authUseCases.doOauthSignIn({
        oauthProvider: data.socialFlow,
        idToken: data.token
      })

      state.global.setUserId(userId)

      trackEvent('signin_success', {
        user_id: userId,
        provider: data.socialFlow,
        new_user: isNewUser
      })

      methods.handleRedirect()
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
        trackEvent('login-error', { type: data.socialFlow })
      } else {
        throw error
      }
    }
  },

  async socialSignup ({ terms, countryCode }: { terms: SignUp['terms'], countryCode: CountryIso2 }) {
    if (!data.socialFlow) return

    try {
      await authUseCases.doOauthSignUp({
        oauthProvider: data.socialFlow,
        idToken: data.token,
        name: data.name,
        surname: data.surname,
        countryIso2: countryCode,
        language: lang.__rootLanguage,
        terms
      })

      methods.socialSignin({ isNewUser: true })
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
        trackEvent('register-error', { type: data.socialFlow })
      } else {
        throw error
      }
    }
  },

  appleName ({ name }: { name: string }) {
    data.name = name
    send('next')
  },

  handleRedirect () {
    const storage = localStorage.getItem('wb-redirection')
    const wbRedirection = storage && JSON.parse(storage)

    if (wbRedirection) {
      const route = { name: wbRedirection.name, query: wbRedirection.query }
      localStorage.removeItem('wb-redirection')
      router.push(route)
    } else {
      router.push({ name: ROUTES.DASHBOARD })
    }
  }
}
</script>

<style scoped lang="postcss">
.login-button {
  height: 40px;
  width: 300px;

  @media (--tablet) {
    width: 375px;
  }
}

.justify-center {
  justify-content: center;
}
</style>
