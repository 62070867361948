import toolkitInit from '@/engine/vendors/toolkit'
import theseusInit from '@/engine/vendors/theseus'
import mqInit from '@/engine/vendors/mq'
import structuredClonePolyfillInit from '@/engine/vendors/structuredClonePolyfill'

import type { App } from 'vue'
import type { I18n } from '@/engine/lang'

export default function initVendors (app: App, i18n: I18n) {
  toolkitInit(app)
  theseusInit(app, i18n)
  mqInit(app)
  structuredClonePolyfillInit()
}
