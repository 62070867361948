<template>
  <wb-table-row
    class="grid-table-row"
    :data-test-id="`invitation-${props.userInvitation.id}`"
  >
    <wb-table-row-item>
      <div class="is-flex is-align-center ">
        <wb-user-avatar
          class="mr-16"
          data-test-id="userPicture"
          size="small"
          :src="props.userInvitation.avatar"
          :initials="props.userInvitation.initials"
        />
        <div class="is-name has-text-grey-500">
          <p data-test-id="userEmail">
            {{ props.userInvitation.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userGroup">
      {{ props.userInvitation.accessGroup?.name || '' }}
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userProfile">
      <user-role-item :profile="props.userInvitation.role" />
    </wb-table-row-item>

    <wb-table-row-item
      v-if="permissions.showPayments"
      class="has-text-centered"
    >
      <div data-test-id="stateSubscription" />
    </wb-table-row-item>

    <wb-table-row-item class="has-text-centered">
      <wb-badge
        variant="yellow"
        data-test-id="userActivationStatus"
      >
        {{ i18n.t('mywb.common.invitation-sent') }}
      </wb-badge>
    </wb-table-row-item>

    <wb-table-row-item>
      <wb-button-groups v-if="props.hasPermisionToActions" data-test-id="userActionsDropdown">
        <wb-dropdown class="group-item">
          <template #activator>
            <wb-button
              variant="white"
              outlined
              size="small"
              icon="email"
              data-test-id="userResendInvitation"
              @click="methods.handleResendInvitation"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.resend') }}
          </template>
        </wb-dropdown>
        <wb-dropdown class="group-item">
          <template #activator>
            <wb-button
              variant="danger"
              outlined
              size="small"
              icon="person_remove"
              data-test-id="userRevokeInvitation"
              @click="methods.handleRevokeInvitation"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.revoke') }}
          </template>
        </wb-dropdown>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import type { UserList } from '@/core/user'
import UserRoleItem from '@/components/users/UserRoleItem.vue'

const i18n = useI18n()

interface Props {
  userInvitation: UserList,
  hasPermisionToActions?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-revoke'): void
  (e: 'on-resend'): void
}

const emit = defineEmits<Events>()

const methods = {
  handleRevokeInvitation () {
    emit('on-revoke')
  },

  handleResendInvitation () {
    emit('on-resend')
  }
}
</script>

<style lang="postcss" scoped>
.grid-table-row {
  cursor: default;
}
</style>
