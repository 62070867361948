<template>
  <div class="add-chargers-results">
    <div v-if="loading && totalChargersError > 0">
      <div class="loading">
        <div class="loading-spinner">
          <wb-loading type="Ring" variant="grey" width="30px" />

          <div>
            <p class="loading-title">
              {{ i18n.t('mywb.common.adding-x-chargers', { count: totalChargersError }) }}
            </p>
            <p class="loading-wait">
              {{ i18n.t('mywb.common.please-wait') }}
            </p>
          </div>
        </div>

        <div class="loading-status">
          {{ totalChargersError }}
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="isSummaryMode">
        <slot />
      </div>

      <div v-else>
        <div v-if="$slots.chargersWithSuccess" class="result result-success">
          <div class="result-accordion-top">
            <div class="result-title">
              <span class="wb-icons result-icon">check_circle_filled</span>
              <p>{{ totalChargersAdded }} {{ i18n.t('mywb.common.added-x-chargers', totalChargersAdded) }}</p>
            </div>

            <span class="total">
              <span class="total-success">
                {{ totalChargersAdded }}</span> / {{ totalChargersAdded + totalChargersError }}
              <span
                v-if="!data.isSuccessChargersOpen"
                class="wb-icons accordion-toggle"
                @click="data.isSuccessChargersOpen = true"
              >expand_more
              </span>

              <span
                v-else
                class="wb-icons accordion-toggle"
                @click="data.isSuccessChargersOpen = false"
              >expand_less
              </span>
            </span>
          </div>

          <Transition name="fade">
            <div v-if="data.isSuccessChargersOpen" class="result-accordion-panel">
              <slot name="chargersWithSuccess" />
            </div>
          </Transition>
        </div>

        <div v-if="totalChargersError" class="result result-error">
          <div class="result-accordion-top">
            <div class="result-title">
              <span class="wb-icons result-icon">error_outline_filled</span>
              <p>{{ totalChargersError }} {{ i18n.t('mywb.common.error', totalChargersError) }}</p>
            </div>

            <span class="total">
              <span class="total-error">{{ totalChargersError }}</span> / {{ totalChargersAdded + totalChargersError }}
              <span
                v-if="!data.isErrorChargersOpen"
                class="wb-icons accordion-toggle"
                @click="data.isErrorChargersOpen = true"
              >expand_more
              </span>

              <span
                v-else
                class="wb-icons accordion-toggle"
                @click="data.isErrorChargersOpen = false"
              >expand_less</span>
            </span>
          </div>

          <Transition name="fade">
            <div v-if="data.isErrorChargersOpen" class="result-accordion-panel">
              <slot />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

type Props = {
  isSummaryMode: boolean
  loading: boolean
  totalChargersAdded: number
  totalChargersError: number
}

withDefaults(defineProps<Props>(), {
  loading: true
})

const data = reactive({
  isSuccessChargersOpen: false,
  isErrorChargersOpen: true
})

const methods = {
  async isValidateForm () {
    return true
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style scoped lang="postcss">
.add-chargers-results {
  background-color: var(--grey-50);
  padding: 0.9rem 2.4rem;
  border-radius: 1.2rem;

  .loading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .loading-spinner {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .loading-title {
        color: var(--grey-900);
        font-size: var(--size-400);
      }

      .loading-wait {
        color: var(--grey-500);
        font-size: var(--size-300);
      }
    }

    .loading-status {
      color: var(--grey-500);
    }
  }

  .result {
    padding: 1.6rem 0;

    .result-accordion-top {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: var(--size-400);
    }

    .result-title {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &.result-success {
      .result-icon, .total-success {
        color: var(--green-700);
      }
    }

    &.result-error {
      .result-icon, .total-error {
        color: var(--red-500);
      }
    }

    .total {
      color: var(--grey-500);
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .accordion-toggle {
      color: var(--black);
      cursor: pointer;
    }

    .result-accordion-panel {
      margin-top: 1.6rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-200);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
