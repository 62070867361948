<template>
  <div class="auth-layout-content g-64 tablet:g-16">
    <div
      v-if="clientConfig.loginLogo?.lottie && props.playAnimation"
      id="logo"
      class="anim"
    />

    <img
      v-else
      class="auth-login__logo"
      v-bind="clientConfig.loginLogo"
      alt="logo"
    >

    <div
      v-if="slots.title"
      class="title"
      data-test-id="formTitle"
    >
      <slot name="title" />
    </div>
    <div class="content">
      <slot />
    </div>
    <template v-if="slots.footer">
      <slot name="footer" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, useSlots } from 'vue'
import { clientConfig } from '@/engine/clients'
import lottie from 'lottie-web'
import lottieInfo from '@/assets/logos/logo-wallbox-lottie.json'

const slots = useSlots()

interface PropsType {
  playAnimation?: boolean
}

const props = withDefaults(defineProps<PropsType>(), {
  playAnimation: false
})

onMounted(() => {
  if (props.playAnimation && clientConfig.loginLogo?.lottie) {
    const svgContainer = document.getElementById('logo')

    if (svgContainer) {
      lottie.loadAnimation({
        container: svgContainer,
        renderer: 'svg',
        loop: false,
        animationData: lottieInfo
      })
    }
  }
})

</script>

<style lang="postcss" scoped>
.auth-login__logo {
  display: block;
  align-self: flex-end;
  margin: 0 auto;
}

.auth-layout-content {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  align-items: center;
  width: 100%;

  & .anim {
    transition: transform 200ms ease-out;
    margin-top: -100px;
    margin-bottom: -150px;

    @media (--tablet) {
      margin-top: -180px;
      height: 400px;
      margin-bottom: -200px;
    }
  }
}

.title {
  width: 100%;
  align-self: flex-start;
  justify-self: center;
  text-align: center;
}

.content {
  align-self: flex-start;
  width: 100%;
  height: 100%;
}
</style>
