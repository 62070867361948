<template>
  <div>
    <access-configs-groups-table
      v-if="data.accessConfigs.length || data.loading"
      :access-configs="data.accessConfigs ?? []"
      :loading="data.loading"
      @on-delete="methods.deleteGroup"
      @on-edit="methods.editGroup"
    />
    <access-config-empty-state
      v-else
      @on-create-group="data.isModalUserListAccessConfigAddOpen = true"
    />

    <access-config-edit-modal
      v-if="data.isModalUserListAccessConfigEditOpen && data.selectedAccessConfig"
      :access-config-id="data.selectedAccessConfig.id"
      @close="methods.onEditAccessConfigdata()"
    />

    <access-config-delete-modal
      v-if="data.isModalUserListAccessConfigDeleteOpen && data.selectedAccessConfig"
      :access-config="data.selectedAccessConfig"
      @close="methods.onDeleteAccessConfig()"
    />

    <access-config-creation-modal
      v-if="data.isModalUserListAccessConfigAddOpen"
      @close="methods.onCreateAccessConfig()"
    />
  </div>
</template>

<script setup lang="ts">
import AccessConfigsGroupsTable from '@/components/tables/AccessConfigsGroupsTable.vue'
import AccessConfigEditModal from '@/components/modals/AccessConfigEditModal.vue'
import AccessConfigDeleteModal from '@/components/modals/AccessConfigDeleteModal.vue'
import AccessConfigCreationModal from '@/components/modals/AccessConfigCreationModal.vue'
import AccessConfigEmptyState from '@/components/emptyStates/AccessConfigEmptyState.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive } from 'vue'
import state from '@/state'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'
import type { AccessConfig } from '@/core/accessConfig'

const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

interface Data {
  loading: boolean
  selectedAccessConfig?: AccessConfig
  isModalUserListAccessConfigDeleteOpen: boolean
  isModalUserListAccessConfigEditOpen: boolean
  isModalUserListAccessConfigAddOpen: boolean
  accessConfigs: AccessConfig[]
}
const data = reactive<Data>({
  loading: false,
  isModalUserListAccessConfigEditOpen: false,
  isModalUserListAccessConfigDeleteOpen: false,
  isModalUserListAccessConfigAddOpen: false,
  accessConfigs: []
})

const methods = {
  async getAllAccessConfigGroups (withLoading = true) {
    data.loading = withLoading
    data.accessConfigs = await accessConfigUseCases
      .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
    data.loading = false
  },

  deleteGroup (accessConfig: AccessConfig) {
    data.selectedAccessConfig = accessConfig
    trackDataAction('delete-group', { group_id: accessConfig.groupId })
    data.isModalUserListAccessConfigDeleteOpen = true
  },

  editGroup (accessConfig: AccessConfig) {
    data.selectedAccessConfig = accessConfig
    trackDataAction('edit-group', { group_id: accessConfig.groupId })
    data.isModalUserListAccessConfigEditOpen = true
  },

  handleAddGroup () {
    trackDataAction('add-group')
    data.isModalUserListAccessConfigAddOpen = true
  },

  onEditAccessConfigdata () {
    data.isModalUserListAccessConfigEditOpen = false
    methods.getAllAccessConfigGroups(false)
  },

  onDeleteAccessConfig () {
    data.isModalUserListAccessConfigDeleteOpen = false
    methods.getAllAccessConfigGroups(false)
  },

  onCreateAccessConfig () {
    data.isModalUserListAccessConfigAddOpen = false
    methods.getAllAccessConfigGroups(false)
  }
}

function created () {
  methods.getAllAccessConfigGroups()
}

created()

defineExpose({ handleAddGroup: () => methods.handleAddGroup() })
</script>
