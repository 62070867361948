import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const getAllChargersActiveSession: UseCase<ChargerRepository, 'getChargersActiveSession'> = repository => {
  return async (organizationId) => {
    const result = await repository.getChargersActiveSession(organizationId)

    return result
  }
}
