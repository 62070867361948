
import countries from 'i18n-iso-countries'
import caCountry from 'i18n-iso-countries/langs/ca.json'
import deCountry from 'i18n-iso-countries/langs/de.json'
import enCountry from 'i18n-iso-countries/langs/en.json'
import esCountry from 'i18n-iso-countries/langs/es.json'
import frCountry from 'i18n-iso-countries/langs/fr.json'
import itCountry from 'i18n-iso-countries/langs/it.json'
import nlCountry from 'i18n-iso-countries/langs/nl.json'
import noCountry from 'i18n-iso-countries/langs/no.json'
import zhCountry from 'i18n-iso-countries/langs/zh.json'
import ptCountry from 'i18n-iso-countries/langs/pt.json'
import svCountry from 'i18n-iso-countries/langs/sv.json'
import jaCountry from 'i18n-iso-countries/langs/ja.json'

import { createI18n } from 'vue-i18n'
import ca from './ca.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import no from './no.json'
import zh from './zh.json'
import pt from './pt.json'
import sv from './sv.json'
import ja from './ja.json'

import enUS from './en_US.json'
import { isRef } from 'vue'

countries.registerLocale(caCountry)
countries.registerLocale(deCountry)
countries.registerLocale(enCountry)
countries.registerLocale(esCountry)
countries.registerLocale(frCountry)
countries.registerLocale(itCountry)
countries.registerLocale(nlCountry)
countries.registerLocale(noCountry)
countries.registerLocale(zhCountry)
countries.registerLocale(ptCountry)
countries.registerLocale(svCountry)
countries.registerLocale(jaCountry)

export type Langs = 'ca' | 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'no' | 'zh' | 'pt' | 'sv' | 'enUS' | 'ja'

type MessageSchema = typeof enUS

const i18n = createI18n<[MessageSchema], Langs, false, Record<string, unknown>, Record<string, unknown>>({
  locale: navigator?.language,
  legacy: false,
  fallbackLocale: {
    nb: ['no'],
    nn: ['no'],
    default: ['en'],
    'en-US': ['enUS']
  },
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlInMessage: 'off',
  warnHtmlMessage: false,
  messages: {
    ca,
    de,
    en,
    es,
    fr,
    it,
    nl,
    no,
    zh,
    pt,
    sv,
    enUS,
    ja
  }
})

export type I18n = typeof i18n

export default {
  ...i18n,
  get __rootLanguage () {
    let localeCandidate = (i18n.global.locale as any)?.value?.substring(0, 2).toLowerCase()

    if (localeCandidate === 'nb' || localeCandidate === 'nn') {
      localeCandidate = 'no'
    }

    const weAllowThatLocale = new Set([
      'ca',
      'de',
      'en',
      'es',
      'fr',
      'it',
      'nl',
      'no',
      'zh',
      'pt',
      'sv',
      'ja'
    ]).has(localeCandidate)

    const locale = weAllowThatLocale ? localeCandidate : 'en'

    return locale as Langs
  },

  get locale (): string {
    if (isRef(i18n.global.locale)) {
      return i18n.global.locale.value
    }

    return ''
  }
}
