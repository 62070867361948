<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        class="table"
        :options="data.options"
        :loading="props.loading"
        data-test-id="invoicesSection"
        @on-pagination-change="methods.handlePaginationChange"
      >
        <wb-table-row
          v-for="invoice in compute.invoicesParsed"
          :key="invoice.id"
        >
          <wb-table-row-item data-test-id="invoiceDate">
            {{ methods.getDate(invoice.issueDate) }}
          </wb-table-row-item>
          <wb-table-row-item data-test-id="invoiceStatus">
            <wb-badge
              v-if="invoice.statusLabel"
              :variant="invoice.statusColor"
            >
              {{ invoice.statusLabel }}
            </wb-badge>
          </wb-table-row-item>
          <wb-table-row-item data-test-id="invoiceType">
            {{ invoice.typeTranslated }}
          </wb-table-row-item>
          <wb-table-row-item data-test-id="invoiceUser">
            {{ invoice.userName }}
          </wb-table-row-item>
          <wb-table-row-item data-test-id="invoiceTotalAmount">
            {{ invoice.totalLocalized }}
          </wb-table-row-item>
          <wb-table-row-item data-test-id="invoiceView" @click.stop>
            <wb-button-groups>
              <wb-dropdown
                v-for="(option, key) in methods.getInvoiceOptions(invoice)"
                :key="key"
                class="group-item"
              >
                <template #activator>
                  <wb-button
                    :id="option.id"
                    variant="white"
                    outlined
                    size="small"
                    :icon="option.icon"
                    :data-test-id="option.dataTestId"
                    @click="methods.handleClick(invoice, option.id)"
                  />
                </template>
                <template #tooltip>
                  {{ option.label }}
                </template>
              </wb-dropdown>
            </wb-button-groups>
          </wb-table-row-item>
        </wb-table-row>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { dates, numbers, type TableProps } from '@wallbox/toolkit-ui'
import { permissions } from '@/engine/clients'
import { type PaymentsInvoice, INVOICE_STATUS, INVOICE_TYPE } from '@/core/invoices'

const i18n = useI18n()

interface Events {
  (e: 'on-mark-as-paid', invoice: PaymentsInvoice): void,
  (e: 'on-pagination-change', offset: number): void
}
const emit = defineEmits<Events>()

interface Props {
  invoices: PaymentsInvoice[],
  loading?: boolean,
  pagination?: {
    offset: number,
    total: number,
    limit: number,
    multiPage: boolean
  }
}
const props = defineProps<Props>()

const data = reactive({
  options: {
    offset: '0 9%',
    stickyHead: 'var(--header-height)',
    pagination: props.pagination,
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    },
    columns: [
      { name: i18n.t('mywb.common.date'), align: 'left', minWidth: 100 },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: 100 },
      {
        name: i18n.t('mywb.common.type'),
        align: 'left',
        minWidth: 150,
        lineBehaviour: 'multiline'
      },
      {
        name: i18n.t('mywb.common.user'),
        align: 'left',
        minWidth: 150,
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.common.total-amount'), align: 'left', minWidth: 150 },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: 300 }
    ] as TableProps['options']['columns']
  }
})

const compute = reactive({
  invoicesParsed: computed(() => {
    return props.invoices.map(invoice => ({
      ...invoice,
      userName: invoice.customerName ?? '',
      ...methods.getStatusInvoice(invoice.status),
      typeTranslated: invoice.type === INVOICE_TYPE.PAY_PER_MONTH
        ? i18n.t('mywb.charger.pay-per-month')
        : i18n.t('mywb.charger.pay-per-charge'),
      totalLocalized: numbers.toLocaleCurrencySymbol(
        invoice.total / 100,
        invoice.currencyCode,
        i18n.locale.value)
    }))
  })
})

const methods = {
  getStatusInvoice (status: INVOICE_STATUS) {
    switch (status) {
      case (INVOICE_STATUS.OPEN): return { statusColor: 'yellow', statusLabel: i18n.t('mywb.common.pending') } as const
      case (INVOICE_STATUS.PAID): return { statusColor: 'green', statusLabel: i18n.t('mywb.payments.paid') }as const
      case (INVOICE_STATUS.VOID): return { statusColor: 'green', statusLabel: i18n.t('mywb.payments.paid') } as const
      case (INVOICE_STATUS.PENDING):
        return { statusColor: 'yellow', statusLabel: i18n.t('mywb.common.pending') } as const
      case (INVOICE_STATUS.UNCOLLECTIBLE):
        return { statusColor: 'yellow', statusLabel: i18n.t('mywb.common.pending') } as const
      default: return { statusColor: 'yellow', statusLabel: i18n.t('mywb.common.pending') } as const
    }
  },

  getDate: dates.toDate,

  getInvoiceOptions (invoice: PaymentsInvoice) {
    const options = []

    options.push({
      id: 'view-invoice',
      dataTestId: 'view-invoice',
      icon: 'visibility',
      label: i18n.t('mywb.common.view')
    } as const)

    if (permissions.canMarkAsPaid && invoice.status === 'open') {
      options.push({
        id: 'mark-as-paid-invoice',
        dataTestId: 'mark-as-paid-invoice',
        icon: 'download_done',
        label: i18n.t('mywb.common.mark-as-paid')
      } as const)
    }
    return options
  },

  handleClick (invoice: PaymentsInvoice, option: 'view-invoice' | 'mark-as-paid-invoice') {
    if (option === 'view-invoice') {
      window.open(invoice.attachment, '_blank', 'noopener')
    }

    if (option === 'mark-as-paid-invoice') {
      emit('on-mark-as-paid', invoice)
    }
  },

  handlePaginationChange (offset: number) {
    emit('on-pagination-change', offset)
  }
}
</script>

<style lang="postcss" scoped>
.table {
  height: 100%;
}
</style>
