<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-role.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-role.description') }}
    </div>

    <wb-radio-card
      v-model="compute.profile"
      :value="EnumRoles.user"
      :error="data.error"
      @click="data.error = false"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="userTypeUser">
        {{ i18n.t('mywb.common.user') }}
      </p>
      <p class="is-size-300 has-text-grey-900">
        {{ i18n.t('mywb.profile.user.description') }}
      </p>
    </wb-radio-card>

    <wb-radio-card
      v-model="compute.profile"
      class="my-16"
      :value="EnumRoles.admin"
      :error="data.error"
      @click="data.error = false"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="userTypeAdmin">
        {{ i18n.t('mywb.common.admin') }}
      </p>
      <p class="is-size-300 has-text-grey-900">
        {{ i18n.t('mywb.profile.admin.description') }}
      </p>
    </wb-radio-card>

    <wb-radio-card
      v-if="permissions.canAddOperators"
      v-model="compute.profile"
      :value="EnumRoles.operator"
      :error="data.error"
      @click="data.error = false"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="userTypeOperator">
        {{ i18n.t('mywb.common.operator') }}
      </p>
      <p class="is-size-300 has-text-grey-900">
        {{ i18n.t('mywb.profile.operator.description') }}
      </p>
    </wb-radio-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { permissions } from '@/engine/clients'
import { EnumRoles } from '@/utilities/user-roles'

const i18n = useI18n()

interface Events {
  (e: 'update:modelValue', role: EnumRoles): void
}
const emit = defineEmits<Events>()

const props = defineProps<{
  modelValue: number
}>()

const data = reactive({
  error: false
})

const compute = reactive({
  profile: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})

const methods = {
  isValidateForm () {
    data.error = !props.modelValue
    return !data.error
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
:deep(.radio) {
  padding: 2.4rem !important;
}
</style>
