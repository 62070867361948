import type { CountryIso2 } from '@/core/international'

import { object, string, type InferType, boolean } from 'yup'

const signUpSchema = object({
  name: string(),
  email: string().email().required(),
  password: string().required(),
  terms: object({
    policy: boolean().required(),
    marketing: boolean().optional(),
    profile: boolean().optional()
  }),
  countryIso2: string<CountryIso2>().required(),
  language: string().required(),
  clientType: string().required(),
  activationCode: string().optional()
})

export type SignUp = InferType<typeof signUpSchema>

export type SignUpActivated = SignUp & {
  activationCode: string
}

export async function ensureSignUpIsValid (payload: any) {
  return await signUpSchema.validate(payload, { abortEarly: false })
}

export async function ensureSignUpActivatedIsValid (payload: any) {
  const result = await signUpSchema.validate(payload, { abortEarly: false })

  return result && payload.activationCode
}
