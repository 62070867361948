import { onBeforeUnmount, ref } from 'vue'

export const useIsOnline = () => {
  const isOnline = ref(true)

  function onlineHandler () {
    isOnline.value = true
  }

  function offlineHandler () {
    isOnline.value = false
  }

  addEventListener('online', onlineHandler)
  addEventListener('offline', offlineHandler)

  onBeforeUnmount(() => {
    removeEventListener('online', onlineHandler)
    removeEventListener('offline', offlineHandler)
  })

  return {
    isOnline
  }
}
