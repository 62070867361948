import i18n from '@/engine/lang'
import { OCPP_CONNECTION_STATUS, type Charger as ChargerCore } from '@/core/charger'

export enum OCPP {
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
  CONNECTED = 'connected',
}

export const ocppStatuses = () => [
  {
    code: OCPP.DISCONNECTED,
    label: i18n.global.t('mywb.common.disabled'),
    ids: [OCPP_CONNECTION_STATUS.DISCONNECTED, OCPP_CONNECTION_STATUS.NOT_CONFIGURED]
  },
  {
    code: OCPP.CONNECTING,
    label: i18n.global.t('mywb.common.connecting'),
    ids: [
      OCPP_CONNECTION_STATUS.CONNECTION_REJECTED
    ]
  },
  {
    code: OCPP.ERROR,
    label: i18n.global.t('mywb.ocpp.connection-rejected'),
    ids: [OCPP_CONNECTION_STATUS.CONNECTION_ERROR]
  },
  {
    code: OCPP.CONNECTED,
    label: i18n.global.t('mywb.ocpp.connection-accepted'),
    ids: [OCPP_CONNECTION_STATUS.CONNECTED]
  }
]

export const ocppStatus = (charger: ChargerCore) => {
  const statusId = charger.ocppConnectionStatus

  return ocppStatuses().find(status => status.ids.includes(statusId)) ??
  {
    ids: [OCPP_CONNECTION_STATUS.DISCONNECTED],
    code: OCPP.DISCONNECTED,
    label: i18n.global.t('mywb.common.disconnected')
  }
}

export const supportsOcpp = (charger: ChargerCore) => {
  return !!charger.ocppProtocol
}

export const isOcpp = (charger: ChargerCore) => {
  return charger.operationMode === 'ocpp'
}
