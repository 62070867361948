import { EnumRoles } from '@/utilities/user-roles'
import state from '@/state'
import i18n from '@/engine/lang'
import type { Charger } from '@/core/charger'
import type { UserList } from '@/core/user'

export const checkIfUserIsSameAs = (email: string) => {
  if (!email) return

  const userFound = state.user.getAllUsers.find(user => user.email === email)

  if (userFound == null) return false

  return (userFound.email === state.user.userLogged.email)
}

export const hasRoleGreaterThan = (email: string) => {
  if (!email) return false

  const organization = state.organizations.getCurrentOrganization
  const userFound = state.user.getAllUsers.find(user => user.email === email)

  const roleUserAccessConfigSelected = Object.values(EnumRoles).indexOf(userFound?.role ?? '')
  const roleRootUserGroupSelected = Object.values(EnumRoles).indexOf(organization.role.id)

  return roleRootUserGroupSelected > roleUserAccessConfigSelected
}

export const userInitials = (user: { name: string, surname?: string, lastname?: string }) => {
  if (user.surname) return user.name.charAt(0) + user.surname.charAt(0)
  if (user.lastname) return user.name.charAt(0) + user.lastname.charAt(0)

  return (user.name ?? '').substring(0, 2)
}

export const getContractStatus = (user: UserList) => {
  interface Contract {
    class: string
    label: string
    status: 'active' | 'unpaid' | 'error' | 'pending' | ''
    details?: Record<'paidInvoices' | 'signed' | 'billingInfo' | 'paymentMethod', {
      label: string
      status: boolean
    }>
  }

  let contract: Contract = {
    class: '',
    label: '',
    status: ''
  }

  if (user.contract != null) {
    const status = user.contract?.status ?? 'pending'
    const statusObject: Record<string, any> = {
      active: { status: 'active', class: 'has-text-success-500', label: i18n.global.t('mywb.common.active') },
      unpaid: { status: 'unpaid', class: 'has-text-warning-500', label: i18n.global.t('mywb.common.unpaid') },
      error: { status: 'error', class: 'has-text-danger-500', label: i18n.global.t('mywb.common.error') },
      pending: { status: 'pending', class: 'has-text-warning-700', label: i18n.global.t('mywb.common.pending') },
      no: { status: 'no', class: '', label: '' }
    }

    contract = statusObject[status] ?? statusObject.no
  }

  if ((user.contractStatus?.user) != null) {
    contract.details = {
      paidInvoices: {
        label: i18n.global.t('mywb.common.subscription-created'),
        status: user.contractStatus.user.paidInvoices
      },

      signed: {
        label: i18n.global.t('mywb.common.contract-signed'),
        status: user.contractStatus.user.signed
      },

      billingInfo: {
        label: i18n.global.t('mywb.common.billing-info-filled'),
        status: user.contractStatus.user.billingInfo
      },

      paymentMethod: {
        label: i18n.global.t('mywb.common.payment-method-filled'),
        status: user.contractStatus.user.paymentMethod
      }
    }
  }

  return contract
}

export const filterChargerByUsers = (chargers: Charger[], users: number[]) => {
  if (state.filters.dashboardFilters.users.length <= 0) {
    return chargers
  }

  const usersSet = new Set(users)

  return chargers.filter(charger => {
    const inRoot = state.user.getAllUsers.some(user => {
      return user.hasCustomAccess && usersSet.has(user.id)
    })

    const inSubGroup = state.user.getAccessConfig.some(accessConfig => {
      const accessConfigHasUser = accessConfig.users.some(user => usersSet.has(user))
      const accessConfigHasCharger = accessConfig.chargers.some(chargerInAccess => chargerInAccess === charger.id)

      return accessConfigHasUser && accessConfigHasCharger
    })

    return inRoot || inSubGroup
  })
}
