import {
  type AccessConfigRepository,
  AccessConfigForUserContractStatus,
  type AccessConfigContract
} from '@/core/accessConfig'
import ENV from '@/engine/env/web.env'
import {
  type ApiGetAccessConfig,
  type ApiGetUserGroupsResponse,
  type ApiGetUserResponse,
  ApiGetAccessConfigForUserContractStatus,
  type ApiGetUserAccessConfigContract
} from './types'
import { endpoints } from '@/core/shared/endpoints.config'
import type { RepositoryHttpBuild } from '@/core/shared'
import { provideAuthUseCases } from '@/core/auth'

export const wapiRepositoryBuilder: RepositoryHttpBuild<AccessConfigRepository> = ({ httpService }) => {
  const instance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  async function getOldGroupId (groupUid: string): Promise<number> {
    const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
      headers: {
        'workbox-cache-type': 'cache-first'
      }
    })
    return groupId.data.attributes.value
  }

  return {
    async getAllAccessConfigs (groupUid) {
      const groupId = await getOldGroupId(groupUid)

      if (!groupId) return []

      const userGroups = await instance.get<ApiGetUserGroupsResponse>(endpoints.v3.users_groups)
        .then(response => response?.result?.groups)

      if (!userGroups) return []

      const accessConfigs = userGroups.find(group => group.id === groupId)?.accessConfigs || []

      return accessConfigs.map(accessConfig => {
        let users: number[] = []
        if (!Array.isArray(accessConfig.users)) {
          users = Object.values(accessConfig.users)
        } else {
          users = accessConfig.users
        }

        return {
          ...accessConfig,
          users,
          groupId: accessConfig.group
        }
      })
    },

    async getUserAccessConfigByGroup (userId, groupUid) {
      const groupId = await getOldGroupId(groupUid)

      if (!groupId) return undefined

      const user = await instance.get<ApiGetUserResponse>(
        endpoints.v2.user_userId.replace('{userId}', userId?.toString())
      )

      if (!user) return undefined

      const accessGroup = user.data.accessConfigs
        .find(accessConfig => groupId === accessConfig.group)

      if (!accessGroup) throw new Error('can not be undefined')

      function mapAccessGroupContract (contract: ApiGetUserAccessConfigContract): AccessConfigContract {
        return {
          createdAt: contract.createdAt,
          id: contract.id,
          signed: contract.signed,
          status: contract.status ? mapContractStatus(contract.status) : undefined,
          updatedAt: contract.updatedAt
        }
      }

      function mapContractStatus (apiContractStatus: ApiGetAccessConfigForUserContractStatus):
      AccessConfigForUserContractStatus | undefined {
        if (apiContractStatus === ApiGetAccessConfigForUserContractStatus.ACTIVE) {
          return AccessConfigForUserContractStatus.ACTIVE
        } else if (apiContractStatus === ApiGetAccessConfigForUserContractStatus.UNPAID) {
          return AccessConfigForUserContractStatus.UNPAID
        }
      }

      return {
        groupId: accessGroup?.group,
        id: accessGroup?.id,
        contract: accessGroup.contract ? mapAccessGroupContract(accessGroup.contract) : undefined,
        chargers: accessGroup.chargers,
        name: accessGroup.name,
        users: [userId],
        profile: accessGroup.profile,
        hasCustomAccess: !accessGroup.name
      }
    },

    async getAccessConfig (accessConfigId) {
      const result = await instance.get<ApiGetAccessConfig>(endpoints.v3.accessConfigs_accessConfigId
        .replace('{accessConfigId}', accessConfigId.toString()))

      if (!result) return undefined

      return {
        chargers: result.result.chargers.map(charger => charger.id),
        name: result.result.name,
        groupId: result.result.group.id,
        users: result.result.users.flatMap((user) => user.users),
        id: result.result.id
      }
    },

    async editAccessConfig (editAccessConfig) {
      await instance.put(endpoints.v3.accessConfigs_accessConfigId
        .replace('{accessConfigId}', editAccessConfig.id.toString()),
      {
        chargers: editAccessConfig.chargers,
        name: editAccessConfig.name
      })
    },

    async createAccessConfig (accessConfigCreate) {
      interface Response {
        result: {
          id: number
        }
      }

      const groupId = await getOldGroupId(accessConfigCreate.group)

      const info = await instance.post<Response>(endpoints.v3.accessConfigs, {
        chargers: accessConfigCreate.chargers,
        group: groupId,
        name: accessConfigCreate.name,
        createdByUser: true
      })

      return info.data.result.id
    },

    async removeAccessConfig (accessConfigId) {
      await instance.delete(endpoints.v3.accessConfigs_accessConfigId
        .replace('{accessConfigId}', accessConfigId.toString()))
    },

    async changeUserRole (changeUserRole) {
      const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
        .replace('{accessConfigId}', changeUserRole.accessConfigId.toString())
        .replace('{userId}', changeUserRole.userId.toString())

      await instance.put(endpoint, { profile: changeUserRole.role })
    },

    async subscribeUserToPayPerMonth (subscribeUserToPayPerMonth) {
      const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
        .replace('{accessConfigId}', subscribeUserToPayPerMonth.accessConfigId.toString())
        .replace('{userId}', subscribeUserToPayPerMonth.userId.toString())

      await instance.put(endpoint, { subscribed: true })
    },

    async addUserToAccessConfig (addUserToAccessConfig) {
      const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
        .replace('{accessConfigId}', addUserToAccessConfig.accessConfigId.toString())
        .replace('{userId}', addUserToAccessConfig.userId.toString())

      await instance.put(endpoint, { profile: addUserToAccessConfig.role })
    },

    async removeUserFromAccessConfig (removeUserFromAccessConfig) {
      const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
        .replace('{accessConfigId}', removeUserFromAccessConfig.accessConfigId.toString())
        .replace('{userId}', removeUserFromAccessConfig.userId.toString())

      await instance.delete(endpoint, { params: { action: 'change' } })
    }
  }
}
