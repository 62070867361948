<template>
  <wb-card
    :headline="i18n.t('mywb.common.energy-cost')"
    :subhead="i18n.t('mywb.common.energy-cost.description')"
    alignment="center"
  >
    <template #content>
      <wb-card
        :headline=" compute.energyCostParsed + '/' + i18n.t('mywb.common.kwh')"
        filled
      >
        <template #actions>
          <wb-button
            icon="edit"
            data-test-id="editBtn"
            :label="i18n.t('mywb.common.edit')"
            variant="white"
            outlined
            @click="data.isChargerEnergyCostModalOpen = true"
          />
        </template>
      </wb-card>
    </template>

    <template #modal>
      <charger-edit-energy-cost-modal
        v-if="data.isChargerEnergyCostModalOpen && props.location && data.energyCost"
        :location="props.location"
        :energy-cost="data.energyCost"
        @close="data.isChargerEnergyCostModalOpen = false"
        @on-energy-cost-updated="methods.loadData"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerEditEnergyCostModal from '@/components/modals/ChargerEditEnergyCostModal.vue'

import state from '@/state'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import { EnumPlans } from '@/utilities/plans'
import type { Location } from '@/core/location'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'

const i18n = useI18n()
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

interface Props {
  loading?: boolean
  location: Location
}
const props = defineProps<Props>()

interface Data {
  keepEnergyCostFromLocation: boolean
  loading: boolean
  isChargerEnergyCostModalOpen: boolean
  energyCost?: number
}

const data = reactive<Data>({
  keepEnergyCostFromLocation: false,
  loading: false,
  isChargerEnergyCostModalOpen: false
})

const compute = reactive({
  energyCost: computed((): number | undefined => {
    let energyCost
    if (data.keepEnergyCostFromLocation) {
      energyCost = props.location?.energyCost
    } else {
      energyCost = data.energyCost
    }

    return energyCost
  }),

  energyCostParsed: computed((): string => {
    return compute.energyCost
      ? numbers.toLocaleCurrencySymbol(
        compute.energyCost,
        state.organizations.getCurrentOrganization.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  getGroupName: computed(() => props.location?.name || '')
})

const methods = {
  async loadData (withLoading = false) {
    data.loading = withLoading

    data.energyCost = await chargerUseCases.getChargerEnergyCost(state.charger.getChargerCore.uid)

    if (!state.organizations.isPlan()([EnumPlans.BASIC])) {
      data.keepEnergyCostFromLocation = data.energyCost === props.location.energyCost
    }
    data.loading = false
  }
}

methods.loadData(true)
</script>
