<template>
  <div v-if="props.charger.id" class="grid">
    <template
      v-for="(widget, key) in computeWidgets"
      :key="key"
    >
      <wb-dropdown v-if="widget.show" is-hover :distance-offset="0">
        <template #activator>
          <charger-feature-widget-icon
            :widget="widget"
          >
            <wb-wifi-icon
              v-if="widget.name === 'connectivity' && !widget.icon"
              :percentage="props.charger.wifiSignal"
              size="20px"
              class="mt-4"
              :data-test-id="widget.dataTestId"
            />

            <span
              v-else-if="!widget.icon"
              class="max-amps"
            >
              {{ props.charger.maxAvailableAmps }}A
            </span>
          </charger-feature-widget-icon>
        </template>

        <template #container>
          <div class="content-dropdown">
            <div v-if="widget.name === 'energy'" class="widget is-flex">
              <charger-feature-widget
                v-for="(widgetEnergy, keyEnergy) in computeEnergyWidgets"
                :key="keyEnergy"
                :widget="widgetEnergy"
              />
            </div>

            <div v-else-if="widget.name === 'payments'" class="widget">
              <p class="is-size-400 is-font-weight-500 mb-12">
                {{ i18n.t('mywb.common.payments') }}
              </p>
              <div class="is-flex">
                <charger-feature-widget
                  v-for="(widgetPayments, keyPayments) in computePaymentsWidgets"
                  :key="keyPayments"
                  :widget="widgetPayments"
                />
              </div>
              <wb-button
                variant="white"
                outlined
                size="small"
                :label="i18n.t('mywb.common.view-payments-settings')"
                icon="chevron_right"
                class="mt-16"
                icon-position="right"
                content-position="right"
                @mousedown.stop="router.push({
                  name: ROUTES.CHARGER_DETAIL_SETTINGS,
                  params: {chargerUid: props.charger.uid},
                  query: { selected: 'payments'}
                })"
              />
            </div>

            <charger-feature-widget
              v-else
              :widget="widget"
            />
          </div>
        </template>
      </wb-dropdown>
    </template>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { STATUSES } from '@/utilities/charger/chargerStatuses'
import { permissions } from '@/engine/clients'
import {
  connectivityByConnectionType,
  connectivityTypeIcon,
  connectivityTypeLabel
} from '@/utilities/charger/chargerConnectivity'

import { POWER_SHARING, getPowerSharingStatus } from '@/utilities/charger/chargerPowerSharingSettings'
import { supportsOcpp, ocppStatus, isOcpp, OCPP } from '@/utilities/charger/chargerOcppSettings'
import { isChargerRemoteAction, REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { FEATURES, type Charger as ChargerCore } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { MID_STATUS } from '@/utilities/charger/midInformation'
import ChargerFeatureWidget, { type Props as WidgetProps } from './ChargerFeatureWidget.vue'
import ChargerFeatureWidgetIcon from './ChargerFeatureWidgetIcon.vue'
import { useRouter } from 'vue-router'
import ROUTES from '@/engine/router/routes'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()
const router = useRouter()

interface PropsType {
  charger: ChargerCore
}

type WidgetType = WidgetProps['widget']

const props = defineProps<PropsType>()

const data = reactive({
  capabilities: {
    supportPayPerCharge: false,
    supportPayPerMonth: false,
    supportsDynamicPowerSharing: false,
    supportsPowerBoost: false,
    supportsPowerSharing: false,
    supportsEcoSmart: false
  }
})

const compute = reactive({
  isDisconnected: computed((): boolean => {
    return props.charger.status.code === STATUSES.DISCONNECTED
  }),

  isChargingDischarging: computed((): boolean => {
    return props.charger.status.code === STATUSES.CHARGING || props.charger.status.code === STATUSES.DISCHARGING
  })
})

const computeEnergyWidgets = reactive({
  powerSharing: computed((): WidgetType => {
    const powerSharing = getPowerSharingStatus(props.charger)
    const bulletStatus = {
      [POWER_SHARING.DISABLED]: undefined,
      [POWER_SHARING.MASTER]: 'SUCCESS' as const,
      [POWER_SHARING.SLAVE]: 'SUCCESS' as const,
      [POWER_SHARING.MASTER_INCOMPLETE]: 'ERROR' as const,
      [POWER_SHARING.SLAVE_UNPAIRED]: 'ERROR' as const
    }[powerSharing.code]

    return {
      show: !compute.isDisconnected &&
        data.capabilities.supportsPowerSharing &&
        powerSharing.code !== POWER_SHARING.DISABLED,
      name: 'powerSharing',
      title: i18n.t('mywb.common.power-sharing'),
      subtitle: powerSharing.label,
      bulletStatus,
      dataTestId: 'powerSharing',
      bullet: true,
      bulletInside: true
    }
  })
})

const computePaymentsWidgets = reactive({
  payPerCharge: computed((): WidgetType => {
    const status = props.charger.isPayPerChargeEnabled
    const text = status ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled')
    const bulletStatus = status ? 'SUCCESS' : undefined

    return {
      show: status,
      name: 'payPerCharge',
      title: i18n.t('mywb.charger.pay-per-charge'),
      subtitle: text,
      bulletStatus,
      dataTestId: 'payPerCharge',
      bullet: true,
      bulletInside: true
    }
  }),

  payPerMonth: computed((): WidgetType => {
    const status = props.charger.isPayPerMonthEnabled
    const text = status ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled')
    const bulletStatus = status ? 'SUCCESS' : undefined

    return {
      show: status,
      name: 'payPerMonth',
      title: i18n.t('mywb.charger.pay-per-month'),
      subtitle: text,
      bulletStatus,
      dataTestId: 'payPerMonth',
      bullet: true,
      bulletInside: true
    }
  })
})

const computeWidgets = reactive({
  disconnected: computed((): WidgetType => {
    return {
      show: compute.isDisconnected,
      name: 'disconnected',
      icon: 'cloud_disabled',
      title: i18n.t('mywb.charger.last-connection'),
      subtitle: props.charger.lastConnection && new Intl.DateTimeFormat(i18n.locale.value, {
        dateStyle: 'short',
        timeStyle: 'short'
      }).format(props.charger.lastConnection * 1000),
      bullet: false,
      bulletInside: false,
      dataTestId: 'diconnected'
    }
  }),

  connectivity: computed((): WidgetType => {
    return {
      show: !compute.isDisconnected,
      name: 'connectivity',
      icon: connectivityTypeIcon(props.charger) || '',
      title: connectivityTypeLabel(props.charger),
      subtitle: connectivityByConnectionType(props.charger),
      bulletStatus: !compute.isDisconnected ? 'SUCCESS' : undefined,
      dataTestId: 'connectivity',
      bullet: false,
      bulletInside: true
    }
  }),

  power: computed((): WidgetType => {
    return {
      show: !compute.isDisconnected,
      name: 'power',
      title: i18n.t('mywb.common.power-limit'),
      subtitle: `${props.charger.maxAvailableAmps}A`,
      dataTestId: 'powerLimit',
      bullet: false,
      bulletInside: false
    }
  }),

  mid: computed((): WidgetType => {
    const bulletStatus = {
      [MID_STATUS.DISABLED]: undefined,
      [MID_STATUS.ENABLED]: 'SUCCESS' as const,
      [MID_STATUS.ERROR]: 'ERROR' as const
    }[props.charger.mid.code]

    return {
      show: !compute.isDisconnected && props.charger.mid.code !== MID_STATUS.DISABLED,
      name: 'mid',
      icon: 'mid',
      title: i18n.t('mywb.common.mid-power-meter'),
      subtitle: props.charger.mid?.label || '',
      bulletStatus,
      dataTestId: 'chargerMid',
      bullet: props.charger.mid.code !== MID_STATUS.ENABLED,
      bulletInside: true
    }
  }),

  energy: computed((): WidgetType => {
    const powerSharingStatus = getPowerSharingStatus(props.charger)

    const isPowerSharingOnError =
      powerSharingStatus.code === POWER_SHARING.MASTER_INCOMPLETE ||
      powerSharingStatus.code === POWER_SHARING.SLAVE_UNPAIRED

    const isPowerSharingDisabled = powerSharingStatus.code === POWER_SHARING.DISABLED

    const bulletStatus = {
      [POWER_SHARING.MASTER_INCOMPLETE]: 'ERROR' as const,
      [POWER_SHARING.SLAVE_UNPAIRED]: 'ERROR' as const,
      [POWER_SHARING.MASTER]: 'SUCCESS' as const,
      [POWER_SHARING.SLAVE]: 'SUCCESS' as const,
      [POWER_SHARING.DISABLED]: undefined
    }[powerSharingStatus.code]

    return {
      show: !compute.isDisconnected &&
        (data.capabilities.supportsDynamicPowerSharing || data.capabilities.supportsPowerSharing) &&
        (!isPowerSharingDisabled || props.charger.powerSharingEnabled),

      name: 'energy',
      icon: 'power_sharing_filled',
      title: i18n.t('mywb.common.energy'),
      subtitle: '',
      bulletStatus,
      dataTestId: 'energy',
      bullet: isPowerSharingOnError,
      bulletInside: true
    }
  }),

  powerBoost: computed((): WidgetType => {
    const bulletStatus = props.charger.homeSharing ? 'SUCCESS' : undefined

    return {
      show: !compute.isDisconnected && data.capabilities.supportsPowerBoost && props.charger.homeSharing,
      name: 'powerBoost',
      icon: 'charge_boost_filled',
      title: i18n.t('mywb.common.power-boost'),
      subtitle: i18n.t('mywb.common.enabled'),
      bulletStatus,
      dataTestId: 'powerBoost',
      bullet: false,
      bulletInside: true
    }
  }),

  ecoSmart: computed((): WidgetType => {
    const bulletStatus = props.charger.ecosmartEnabled ? 'SUCCESS' : undefined

    return {
      show: !compute.isDisconnected && data.capabilities.supportsEcoSmart && props.charger.ecosmartEnabled,
      name: 'ecoSmart',
      icon: 'leaf_filled',
      title: i18n.t('mywb.common.eco-smart'),
      subtitle: i18n.t('mywb.common.enabled'),
      bulletStatus,
      dataTestId: 'ecoSmart',
      bullet: false,
      bulletInside: true
    }
  }),

  ocpp: computed((): WidgetType => {
    const status = ocppStatus(props.charger)

    const bulletStatus = {
      [OCPP.CONNECTED]: 'INFO' as const,
      [OCPP.CONNECTING]: 'WARNING' as const,
      [OCPP.ERROR]: 'ERROR' as const,
      [OCPP.DISCONNECTED]: undefined
    }[status.code]

    return {
      show: isOcpp(props.charger) && !compute.isDisconnected && supportsOcpp(props.charger),
      name: 'ocpp',
      icon: 'ocpp',
      title: i18n.t('mywb.charger.ocpp-socket'),
      subtitle: status.label,
      bulletStatus,
      dataTestId: 'ocpp',
      bullet: status.code !== OCPP.CONNECTED,
      bulletInside: true
    }
  }),

  softwareVersion: computed((): WidgetType => {
    const isUpdading = isChargerRemoteAction(props.charger, REMOTE_ACTIONS.UPDATE)
    const isUpdateAvailable = isUpdatesAvailable(props.charger)
    const currentVersion = props.charger.software.currentVersion
    const newVersion = props.charger.software.latestVersion
    const updateToLabel = isUpdateAvailable
      ? ` - ${i18n.t('mywb.common.update-to-version', [newVersion])}`
      : ''

    return {
      show: !compute.isDisconnected && !compute.isChargingDischarging &&
        permissions.hasAuthToRestartAndUpdate && (isUpdateAvailable || isUpdading),
      name: 'softwareVersion',
      icon: 'download_circle',
      title: i18n.t('mywb.charger.software-version'),
      subtitle: !isUpdading
        ? currentVersion + updateToLabel
        : i18n.t('mywb.charger.status.updating'),
      bulletStatus: 'INFO',
      dataTestId: 'softwareUpdate',
      bullet: true,
      bulletInside: true
    }
  }),

  payments: computed((): WidgetType => {
    return {
      show: !compute.isDisconnected && permissions.showPayments &&
        (data.capabilities.supportPayPerCharge || data.capabilities.supportPayPerMonth) &&
        (props.charger.isPayPerChargeEnabled || props.charger.isPayPerMonthEnabled),
      name: 'payments',
      icon: 'dollar',
      title: '',
      subtitle: '',
      dataTestId: 'payments',
      bullet: false,
      bulletInside: true
    }
  })
})

watchEffect(async () => {
  const info = await chargerUseCases.getChargerTypeMetaData(props.charger.model)
  const infoPayments = await chargerUseCases.getIsChargeCompatibleWithPayments(props.charger)

  data.capabilities = {
    supportPayPerCharge: infoPayments.payPerCharge.isCompatible,
    supportPayPerMonth: infoPayments.payPerMonth.isCompatible,
    supportsDynamicPowerSharing: info.features.includes(FEATURES.DYNAMIC_POWER_SHARING) ?? false,
    supportsPowerBoost: info.features.includes(FEATURES.POWER_BOOST) ?? false,
    supportsPowerSharing: info.features.includes(FEATURES.POWER_SHARING) ?? false,
    supportsEcoSmart: info.features.includes(FEATURES.ECO_SMART) ?? false
  }
})
</script>

<style lang="postcss" scoped>
.grid {
  display: flex;
}

.content-dropdown {
  padding: 16px;
  width: 200px;
}

.max-amps {
  font-size: 9px;
}

.is-flex {
  display: flex;
  align-self: flex-start;
}
</style>
