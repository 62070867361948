<template>
  <div class="settings-overview">
    <template
      v-for="widget in computeWidgets"
      :key="widget.name"
    >
      <div class="is-size-300 is-font-weight-500">
        {{ methods.getSectionTitle(widget.name) }}
      </div>

      <wb-button-card
        v-if="widget.show"
        :icon="widget.icon"
        :data-test-id="widget.dataTestId"
        :title="widget.title"
        :subtitle="widget.subtitle"
        :bullet-status="widget.bulletStatus"
        :bullet="widget.bullet"
        :action="widget.action"
        :force-open="widget.forceOpen"
        @on-click="widget.onClick"
      >
        <template v-if="widget.name === 'power'" #icon>
          <wb-radial-progress
            :percentage="compute.percentageCurrentChargerAmps"
            :r="14"
            :stroke-width="2"
            stroke-color="var(--grey-700)"
            class="widget-icon has-offset"
          >
            <span class="wb-icons is-size-600 has-text-grey-700">bolt_filled</span>
          </wb-radial-progress>
        </template>

        <template v-else-if="widget.name === 'connectivity'" #icon>
          <wb-wifi-icon
            :percentage="!compute.isDisconnected
              ? state.charger.getChargerCore.wifiSignal
              : undefined"
            color="var(--grey-700)"
            class="widget-icon"
          />
        </template>

        <template v-if="widget.name === 'powerSharing'">
          <template
            v-for="powerSharingWidget in computePowerSharingWidgets"
            :key="powerSharingWidget.name"
          >
            <wb-button-card
              v-if="powerSharingWidget.show"
              :data-test-id="powerSharingWidget.dataTestId"
              :title="powerSharingWidget.title"
              :subtitle="powerSharingWidget.subtitle"
              :bullet-status="powerSharingWidget.bulletStatus"
              :bullet="powerSharingWidget.bullet"
              no-padding
            />
          </template>
        </template>

        <template v-if="widget.name === 'payments'">
          <template
            v-for="paymentWidget in computePaymentsWidgets"
            :key="paymentWidget.name"
          >
            <wb-button-card
              v-if="paymentWidget.show"
              :data-test-id="paymentWidget.dataTestId"
              :title="paymentWidget.title"
              :subtitle="paymentWidget.subtitle"
              :bullet-status="paymentWidget.bulletStatus"
              :bullet="paymentWidget.bullet"
              filled
              no-padding
              :action="paymentWidget.action"
              @on-click="paymentWidget.onClick"
            />
          </template>
        </template>

        <template v-if="widget.name === 'manufacturer'">
          <div
            v-for="(item, key2) in compute.getManufacturerData"
            :key="`data-${key2}`"
            class="mt-8 mb-8"
          >
            <div class="is-size-300 is-font-weight-500">
              {{ i18n.t(item.label) }}
            </div>
            <div
              class="is-size-300 has-text-grey-500"
              :data-test-id="item.dataTestId"
            >
              {{ item.value }}
            </div>
          </div>
        </template>
      </wb-button-card>
    </template>

    <charger-power-limit-modal
      v-if="data.editingMaxCurrent"
      data-test-id="editMaxCurrentModal"
      @on-close="data.editingMaxCurrent = false"
    />
  </div>
</template>

<script setup lang="ts">
import ChargerPowerLimitModal from '@/components/modals/ChargerPowerLimitModal.vue'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { permissions } from '@/engine/clients'
import { supportsOcpp, ocppStatus, OCPP, isOcpp } from '@/utilities/charger/chargerOcppSettings'
import { POWER_SHARING, getPowerSharingStatus } from '@/utilities/charger/chargerPowerSharingSettings'
import { STATUSES } from '@/utilities/charger/chargerStatuses'
import {
  connectivityByConnectionType,
  connectivityTypeIcon,
  connectivityTypeLabel
} from '@/utilities/charger/chargerConnectivity'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import state from '@/state'
import { dates, useToast } from '@wallbox/toolkit-ui'
import { isChargerRemoteAction, REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { Location } from '@/core/location'
import { useAssignedRatesApi, type ChargerWithRates } from '@/utilities/charger/assignedRates'
import { CHARGER_USE_CASES, LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import { FEATURES } from '@/core/charger'
import { MID_STATUS } from '@/utilities/charger/midInformation'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()
const toast = useToast()
const usesCases = injectStrict(LOCATION_USE_CASES)
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

interface WidgetType {
  show: boolean
  name: string
  icon?: string
  title: string
  subtitle?: string
  bulletStatus?: string
  bullet?: boolean
  dataTestId: string
  action?: 'emit' | 'emitAlways' | 'content' | undefined
  forceOpen?: boolean
  onClick?: () => void
}

interface DataType {
  chargerWithRates?: ChargerWithRates
  location?: Location
  editingMaxCurrent: boolean,
  gunLockError?: string,
  capabilities: {
    supportsDynamicPowerSharing: boolean,
    supportsPowerBoost: boolean,
    supportsPowerSharing: boolean,
    supportsEcoSmart: boolean,
    supportAutoLock: boolean,
    supportGunLock: boolean,
    supportsPayPerCharge: boolean,
    supportsPayPerMonth: boolean
  }
}

const data: DataType = reactive({
  chargerWithRates: undefined,
  location: undefined,
  editingMaxCurrent: false,
  capabilities: {
    supportsDynamicPowerSharing: false,
    supportsPowerBoost: false,
    supportsPowerSharing: false,
    supportsEcoSmart: false,
    supportAutoLock: false,
    supportGunLock: false,
    supportsPayPerCharge: false,
    supportsPayPerMonth: false
  }
})

const compute = reactive({
  percentageCurrentChargerAmps: computed((): number => {
    const current = state.charger.getChargerCore.maxAvailableAmps
    const min = state.charger.getChargerCore.minAmps
    const max = state.charger.getChargerCore.maxAmps
    return (current - min) / (max - min) * 100
  }),

  isDisconnected: computed((): boolean => {
    return state.charger.getChargerCore.status?.code === STATUSES.DISCONNECTED
  }),

  isChargingDischarging: computed((): boolean => {
    return state.charger.getChargerCore.status.code === STATUSES.CHARGING ||
      state.charger.getChargerCore.status.code === STATUSES.DISCHARGING
  }),

  getManufacturerData: computed(() => {
    return [
      {
        label: 'mywb.common.name',
        value: state.charger.getChargerCore.name || '-',
        dataTestId: 'chargerName'
      },
      {
        label: 'mywb.common.model',
        value: state.charger.getChargerCore.modelName || '-',
        dataTestId: 'type'
      },
      {
        label: 'mywb.charger.serial-number',
        value: state.charger.getChargerCore.serialNumber || '-',
        dataTestId: 'serialNumber'
      },
      {
        label: 'mywb.common.puk',
        value: state.charger.getChargerCore.uniqueIdentifier || '-',
        dataTestId: 'uniqueIdentifier'
      },
      {
        label: 'mywb.common.part-number',
        value: state.charger.getChargerCore.partNumber || '-',
        dataTestId: 'partNumber'
      },
      {
        label: 'mywb.charger.charging-type',
        value: state.charger.getChargerCore.powerType || '-',
        dataTestId: 'chargingType'
      },
      {
        label: 'mywb.charger.connection-type',
        value: state.charger.getChargerCore.connectorType || '-',
        dataTestId: 'connectorType'
      }
    ]
  })
})

const computePowerSharingWidgets = reactive({
  powerSharing: computed((): WidgetType => {
    const powerSharing = getPowerSharingStatus(state.charger.getChargerCore)
    const bulletStatus = {
      [POWER_SHARING.DISABLED]: 'DISABLED',
      [POWER_SHARING.MASTER]: 'SUCCESS' as const,
      [POWER_SHARING.SLAVE]: 'SUCCESS' as const,
      [POWER_SHARING.MASTER_INCOMPLETE]: 'ERROR' as const,
      [POWER_SHARING.SLAVE_UNPAIRED]: 'ERROR' as const
    }[powerSharing.code]

    return {
      show: data.capabilities.supportsPowerSharing,
      name: 'powerSharing',
      title: i18n.t('mywb.common.power-sharing'),
      subtitle: powerSharing.label,
      bulletStatus,
      bullet: bulletStatus !== 'DISABLED',
      dataTestId: 'powerSharing'
    }
  })
})

const computePaymentsWidgets = reactive({
  payPerCharge: computed((): WidgetType => {
    const status = state.charger.getChargerCore.isPayPerChargeEnabled
    const text = status ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled')
    const bulletStatus = status ? 'SUCCESS' : 'DISABLED'

    return {
      show: data.capabilities.supportsPayPerCharge,
      name: 'payPerCharge',
      title: i18n.t('mywb.charger.pay-per-charge'),
      subtitle: text,
      bulletStatus,
      bullet: bulletStatus !== 'DISABLED',
      dataTestId: 'payPerCharge',
      action: 'emitAlways',
      onClick: () => router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, query: { selected: 'payPerCharge' } })
    }
  }),

  payPerMonth: computed((): WidgetType => {
    const status = state.charger.getChargerCore.isPayPerMonthEnabled
    const text = status ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled')
    const bulletStatus = status ? 'SUCCESS' : 'DISABLED'

    return {
      show: data.capabilities.supportsPayPerMonth,
      name: 'payPerMonth',
      title: i18n.t('mywb.charger.pay-per-month'),
      subtitle: text,
      bulletStatus,
      bullet: bulletStatus !== 'DISABLED',
      dataTestId: 'payPerMonth',
      action: 'emitAlways',
      onClick: () => router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, query: { selected: 'payPerMonth' } })
    }
  })
})

const computeWidgets = reactive({
  power: computed((): WidgetType => {
    return {
      show: true,
      name: 'power',
      title: i18n.t('mywb.common.power-limit'),
      subtitle: `${state.charger.getChargerCore.maxAvailableAmps + i18n.t('mywb.charger.amps')}`,
      dataTestId: 'power',
      action: 'emit',
      onClick: () => { data.editingMaxCurrent = true }
    }
  }),

  mid: computed((): WidgetType => {
    const bulletStatus = {
      [MID_STATUS.DISABLED]: 'DISABLED',
      [MID_STATUS.ENABLED]: 'SUCCESS' as const,
      [MID_STATUS.ERROR]: 'ERROR' as const
    }[state.charger.getChargerCore.mid.code]

    return {
      show: true,
      name: 'mid',
      icon: 'mid',
      title: i18n.t('mywb.common.mid-power-meter'),
      subtitle: state.charger.getChargerCore.mid?.label || '',
      bulletStatus,
      bullet: bulletStatus !== 'DISABLED',
      dataTestId: 'mid'
    }
  }),

  powerSharing: computed((): WidgetType => {
    return {
      show: computePowerSharingWidgets.powerSharing.show,
      name: 'powerSharing',
      icon: 'power_sharing_filled',
      title: i18n.t('mywb.common.power-sharing'),
      dataTestId: 'powerSharingWidget',
      action: 'content'
    }
  }),

  powerBoost: computed((): WidgetType => {
    const hasPowerBoost = state.charger.getChargerCore.homeSharing

    return {
      show: data.capabilities.supportsPowerBoost,
      name: 'powerBoost',
      icon: 'charge_boost_filled',
      title: i18n.t('mywb.common.power-boost'),
      subtitle: hasPowerBoost ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled'),
      bulletStatus: hasPowerBoost ? 'SUCCESS' : 'DISABLED',
      bullet: hasPowerBoost,
      dataTestId: 'powerBoost'
    }
  }),

  ecoSmart: computed((): WidgetType => {
    const hasEcoSmart = state.charger.getChargerCore.ecosmartEnabled

    return {
      show: data.capabilities.supportsEcoSmart,
      name: 'ecoSmart',
      icon: 'leaf_filled',
      title: i18n.t('mywb.common.eco-smart'),
      subtitle: hasEcoSmart ? i18n.t('mywb.common.enabled') : i18n.t('mywb.common.disabled'),
      bulletStatus: hasEcoSmart ? 'SUCCESS' : 'DISABLED',
      bullet: hasEcoSmart,
      dataTestId: 'ecoSmart'
    }
  }),

  autolock: computed((): WidgetType => {
    const hasAutolock = state.charger.getChargerCore.autoLock
    const autolockTime = hasAutolock ? Math.round(state.charger.getChargerCore.autoLockTime / 60) : 0

    return {
      show: data.capabilities.supportAutoLock,
      name: 'autolock',
      icon: state.charger.getChargerCore.locked ? 'lock' : 'lock_open',
      title: i18n.t('mywb.common.auto-lock'),
      subtitle: hasAutolock
        ? `${i18n.t('mywb.common.enabled')} - ${autolockTime} ${i18n.t('mywb.common.min')}`
        : i18n.t('mywb.common.disabled'),
      bulletStatus: hasAutolock ? 'SUCCESS' : 'DISABLED',
      bullet: hasAutolock,
      dataTestId: 'autolock',
      action: 'emitAlways',
      onClick: () => router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, query: { selected: 'lock' } })
    }
  }),

  gunLock: computed((): WidgetType => {
    const hasGunLock = !!state.charger.getChargerCore.userSocketLocking || false

    return {
      show: data.capabilities.supportGunLock,
      name: 'gunLock',
      icon: 'ev_plug_type2',
      title: i18n.t('mywb.charger.gun-lock'),
      subtitle: hasGunLock
        ? i18n.t('mywb.common.enabled')
        : data.gunLockError || i18n.t('mywb.common.disabled'),
      bulletStatus: hasGunLock ? 'SUCCESS' : (data.gunLockError ? 'ERROR' : 'DISABLED'),
      dataTestId: 'gunLock',
      action: 'emitAlways',
      onClick: () => router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, query: { selected: 'gunLock' } })
    }
  }),

  connectivity: computed((): WidgetType => {
    return {
      show: true,
      name: 'connectivity',
      icon: connectivityTypeIcon(state.charger.getChargerCore) || '',
      title: connectivityTypeLabel(state.charger.getChargerCore),
      subtitle: connectivityByConnectionType(state.charger.getChargerCore),
      bulletStatus: !compute.isDisconnected ? 'SUCCESS' : 'DISABLED',
      bullet: !compute.isDisconnected,
      dataTestId: 'connectivity'
    }
  }),

  ocpp: computed((): WidgetType => {
    const status = ocppStatus(state.charger.getChargerCore)

    const bulletStatus = {
      [OCPP.CONNECTED]: 'INFO' as const,
      [OCPP.CONNECTING]: 'WARNING' as const,
      [OCPP.ERROR]: 'ERROR' as const,
      [OCPP.DISCONNECTED]: 'DISABLED'
    }[status.code]

    return {
      show: supportsOcpp(state.charger.getChargerCore),
      name: 'ocpp',
      icon: 'ocpp',
      title: i18n.t('mywb.charger.ocpp-socket'),
      subtitle: status.label,
      bulletStatus,
      bullet: bulletStatus !== 'DISABLED',
      dataTestId: 'ocpp',
      action: 'emitAlways',
      onClick: () => router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, query: { selected: 'ocpp' } })
    }
  }),

  payments: computed((): WidgetType => {
    return {
      show: permissions.showPayments &&
        (computePaymentsWidgets.payPerCharge.show || computePaymentsWidgets.payPerMonth.show),
      name: 'payments',
      icon: 'dollar',
      title: i18n.t('mywb.common.payments'),
      dataTestId: 'paymentsWidget',
      action: 'content'
    }
  }),

  softwareVersion: computed((): WidgetType => {
    const isUpdating = isChargerRemoteAction(state.charger.getChargerCore, REMOTE_ACTIONS.UPDATE)
    const isUpdateAvailable = isUpdatesAvailable(state.charger.getChargerCore)
    const currentVersion = state.charger.getChargerCore.software.currentVersion
    const newVersion = state.charger.getChargerCore.software.latestVersion

    const when = dates
      .toDateWithHoursAndMinutes(state.charger.getChargerCore.software.softwareLastUpdatedAt, i18n.locale.value)

    const updateToLabel = isUpdateAvailable
      ? ` - ${i18n.t('mywb.common.update-to-version', [newVersion])}`
      : ` ${i18n.t('mywb.common.installed', [when])}`

    const hasAutoUpdate = state.charger.getChargerCore.softwareAutoUpdateEnabled &&
      hasFeatureFlagActive('auto-update')

    let subtitle = currentVersion + updateToLabel

    if (hasFeatureFlagActive('auto-update') && hasAutoUpdate) {
      subtitle = `${i18n.t('mywb.charger.auto-update')} ${i18n.t('mywb.common.enabled')} - ${currentVersion}`
    }

    if (isUpdating) {
      subtitle = i18n.t('mywb.charger.status.updating')
    }

    return {
      show: !compute.isChargingDischarging && permissions.hasAuthToRestartAndUpdate,
      name: 'softwareVersion',
      icon: 'download_circle',
      title: i18n.t('mywb.charger.software-version'),
      subtitle,
      bulletStatus: isUpdateAvailable || isUpdating ? 'INFO' : hasAutoUpdate ? 'SUCCESS' : 'DISABLED',
      bullet: isUpdateAvailable || isUpdating || hasAutoUpdate,
      dataTestId: 'softwareUpdate',
      action: (isUpdateAvailable && !isUpdating) ? 'emit' : undefined,
      onClick: () => methods.updateOrRestartAction()
    }
  }),

  location: computed((): WidgetType => {
    return {
      show: permissions.canSeeLocations,
      name: 'location',
      icon: 'location',
      title: i18n.t('mywb.common.location'),
      subtitle: data.location?.name || '',
      dataTestId: 'location',
      action: 'emit',
      onClick: () => router.push({
        name: ROUTES.LOCATION_DETAIL, params: { locationId: state.charger.getChargerCore.locationId }
      })
    }
  }),

  manufacturer: computed((): WidgetType => {
    return {
      show: true,
      name: 'manufacturer',
      icon: 'info_filled',
      title: i18n.t('mywb.common.manufacturer'),
      subtitle: i18n.t('mywb.common.wallbox'),
      dataTestId: 'manufacturer',
      action: 'content'
    }
  })
})

const methods = {
  async getAssignedSchedule () {
    const { getData, chargersWithRates } = useAssignedRatesApi()
    await getData([state.charger.getChargerCore] as any)
    data.chargerWithRates = chargersWithRates?.value?.[0]
  },

  async updateOrRestartAction () {
    try {
      await chargerUseCases.sendUpdateOrRestartRemoteAction({
        chargers: [state.charger.getChargerCore.id],
        action: REMOTE_ACTIONS.UPDATE
      })

      const charger = await chargerUseCases.getCharger(
        state.charger.getChargerCore.uid,
        state.organizations.getCurrentOrganization.groupUid,
        { cache: 'network' }
      )

      charger && state.charger.setCharger(charger)
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async getLocation () {
    data.location = await usesCases.getLocation(state.charger.getChargerCore.locationId)
  },

  getSectionTitle (name: string) {
    switch (name) {
      case ('power'): return i18n.t('mywb.common.power')
      case ('powerSharing'): {
        return (computeWidgets.powerSharing.show || computeWidgets.powerBoost.show || computeWidgets.ecoSmart.show)
          ? i18n.t('mywb.common.energy-management')
          : ''
      }
      case ('autolock'): {
        return (computeWidgets.autolock.show || computeWidgets.gunLock.show)
          ? i18n.t('mywb.common.security')
          : ''
      }
      case ('connectivity'): return i18n.t('mywb.common.connectivity')
      case ('payemnts'): {
        return computeWidgets.payments.show
          ? i18n.t('mywb.common.payments')
          : ''
      }
      case ('softwareVersion'): return i18n.t('mywb.common.system')
      default: return ''
    }
  }
}

async function created () {
  if (permissions.canSeeLocations) await methods.getLocation()

  let supportsPayPerCharge = false
  let supportsPayPerMonth = false

  if (permissions.showPayments && data.location) {
    await methods.getAssignedSchedule()
    const paymentsInfo = await chargerUseCases.getIsChargeCompatibleWithPayments(
      state.charger.getChargerCore
    )

    supportsPayPerMonth = paymentsInfo.payPerMonth.isCompatible && !isOcpp(state.charger.getChargerCore)
    supportsPayPerCharge = paymentsInfo.payPerCharge.isCompatible && !isOcpp(state.charger.getChargerCore)
  }

  const info = await chargerUseCases.getChargerTypeMetaData(state.charger.getChargerCore.model)
  const lock = await chargerUseCases.getIsChargerCompatibleWithGunLock(state.charger.getChargerCore)
  const autoLock = await chargerUseCases.getIsChargerCompatibleWithAutoLock(state.charger.getChargerCore)

  data.capabilities = {
    supportsDynamicPowerSharing: info.features.includes(FEATURES.DYNAMIC_POWER_SHARING) ?? false,
    supportsPowerBoost: info.features.includes(FEATURES.POWER_BOOST) ?? false,
    supportsPowerSharing: info.features.includes(FEATURES.POWER_SHARING) ?? false,
    supportsEcoSmart: info.features.includes(FEATURES.ECO_SMART) ?? false,
    supportAutoLock: (autoLock.isCompatible && !isOcpp(state.charger.getChargerCore)) ?? false,
    supportGunLock: lock.isCompatible ?? false,
    supportsPayPerCharge,
    supportsPayPerMonth
  }

  data.gunLockError = lock.reason ?? undefined
}

created()
</script>

<style lang="postcss" scoped>
.widget-icon {
  & div {
    height: 24px;
    width: 24px;
  }

  &.has-offset {
    margin-top: -6px;
    margin-left: -6px;

    & .wb-icons {
      margin-left: 6px;
      margin-top: -6px;
    }
  }
}

.settings-overview :deep(.widget-main.is-filled) {
  border-radius: 0.6rem!important;
}
</style>
