<template>
  <wb-card
    :headline="i18n.t('mywb.common.monthly-insights')"
  >
    <template #place-upper-right>
      <div class="grid-month-controler">
        <wb-button
          icon="chevron_left"
          variant="white"
          rounded
          class="mr-12"
          size="small"
          @click="methods.changePeriodData('previous')"
        />
        <span class="is-size-300 has-text-overflow">
          {{ methods.getMonthLabel(data.currentDate, 'long') }} {{ data.currentDate.getFullYear() }}
        </span>
        <wb-button
          icon="chevron_right"
          variant="white"
          rounded
          class="ml-12"
          size="small"
          :disabled="data.isLastDatePeriod"
          @click="methods.changePeriodData('next')"
        />
      </div>
    </template>

    <template #content>
      <div class="is-grid mb-16">
        <wb-card
          v-for="(option, key) in compute.sections"
          :key="key"
          class="card-height"
          filled
        >
          <template #content>
            <div class="is-size-200">
              {{ option.label }}
            </div>
            <div class="is-size-700">
              {{ option.value }}
            </div>
            <wb-chart
              type="area"
              :colors="data.chartTypes[option.name as keyof typeof chartTypes].colors"
              :options="chart.options"
              :series="methods.getSerie(option.name as string)"
            />
          </template>
        </wb-card>
      </div>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { numbers, time } from '@wallbox/toolkit-ui'
import { reactive, computed, onMounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { getEnergyFromKiloWatts } from '@/utilities/energy'
import { getMonthlyData } from '@/utilities/chartSessions/chart'
import { chartTypes } from '@/utilities/chartSessions/chartOptions'
import type { ChartSeries } from '@/utilities/chartSessions/chart.types'
import state from '@/state'

const i18n = useI18n()

interface PropsType {
  chargerId?: number
}

const props = defineProps<PropsType>()

interface Data {
  currentDate: Date
  isLastDatePeriod: boolean
  series: ChartSeries
  chartTypes: typeof chartTypes
}

interface Chart {
  options: any
}

const data = reactive<Data>({
  currentDate: new Date(),
  isLastDatePeriod: true,
  series: {},
  chartTypes
})

const chart = reactive<Chart>({
  options: {
    chart: { height: 100 },
    stroke: { curve: 'straight' },
    grid: { show: false },
    fill: { type: 'gradient' },
    xaxis: { labels: { show: false } },
    yaxis: { labels: { show: false } },
    tooltip: { enabled: false }
  }
})

const compute = reactive({
  energy: computed(() => {
    if (!data.series.energy) return 0
    const totalEnergy = Object.values(data.series.energy[0]?.data)
      .reduce((partialSum: number, a: number) => partialSum + a, 0) || 0
    return getEnergyFromKiloWatts(totalEnergy)
  }),

  time: computed(() => {
    if (!data.series.time) return 0
    const totalTime = Object.values(data.series.time[0]?.data)
      .reduce((partialSum: number, a: number) => partialSum + a, 0) || 0
    return time.getTimeDurationString(totalTime, ['h', 'm'])
  }),

  sessions: computed(() => {
    if (!data.series.sessions) return 0
    return Object.values(data.series.sessions[0]?.data)
      .reduce((partialSum: number, a: number) => partialSum + a, 0) || 0
  }),

  cost: computed(() => {
    if (!data.series.cost) return 0
    const totalCost = Object.values(data.series.cost[0]?.data)
      .reduce((partialSum: number, a: number) => partialSum + a, 0) || 0
    return numbers.toLocaleCurrencySymbol(
      totalCost,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  }),

  sections: computed((): Record<string, string | number>[] => {
    return [
      {
        name: 'energy',
        label: data.chartTypes.energy.label,
        dataTestId: 'chargingEnergy',
        value: compute.energy
      },
      {
        name: 'time',
        label: data.chartTypes.time.label,
        dataTestId: 'chargingTime',
        value: compute.time
      },
      {
        name: 'sessions',
        label: data.chartTypes.sessions.label,
        dataTestId: 'chargingSessions',
        value: compute.sessions
      },
      {
        name: 'cost',
        label: data.chartTypes.cost.label,
        dataTestId: 'chargingCost',
        value: compute.cost
      }
    ]
  })
})

const methods = {
  getSerie (name: string) {
    if (!data.series[name]) return [{ data: [] }]
    return [{ data: methods.accumArray(Object.values(data.series[name][0].data) ?? []) ?? [] }]
  },

  accumArray (array: number[]) {
    const cumulativeSum = (sum => (value: number) => (sum += value))(0)
    return array.map(cumulativeSum)
  },

  getMonthLabel (date: Date, month: 'long' | 'short') {
    return new Intl.DateTimeFormat(i18n.locale.value, { month }).format(date)
  },

  async changePeriodData (period: 'previous' | 'next') {
    if (period === 'previous') data.currentDate.setMonth(data.currentDate.getMonth() - 1)
    if (period === 'next') data.currentDate.setMonth(data.currentDate.getMonth() + 1)

    data.isLastDatePeriod = new Date().getMonth() <= data.currentDate.getMonth() &&
      new Date().getFullYear() === data.currentDate.getFullYear()

    const { series } = await getMonthlyData(data.currentDate, props.chargerId)
    data.series = series
  }
}

onMounted(async () => {
  const { series } = await getMonthlyData(data.currentDate, props.chargerId)
  data.series = series
})
</script>

<style lang="postcss" scoped>
.card-height {
  height: 130px;
  overflow: hidden;

  & :deep(.vue-apexcharts) {
    position: relative;
    top: -20px;
  }
}

.grid-month-controler {
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  align-items: center;
  text-align: center;

  & :deep(.button.is-disabled) {
    background: none !important;
  }
}

.is-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
