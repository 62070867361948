<template>
  <div
    v-if="props.productId !== EnumPlans.OPERATOR || hasFeatureFlagActive('operator-plan')"
    class="card"
    :data-plan="compute.selectedPlan.name"
    :data-test-id="`${compute.selectedPlan.name}PlanSection`"
  >
    <h2 class="is-size-1000 is-font-weight-800" :data-test-id="`${compute.selectedPlan.name}PlanName`">
      {{ compute.selectedPlanTitle }}
    </h2>

    <!-- eslint-disable-next-line -->
    <div class="is-plan-description is-inline is-size-400 mt-16 mb-16" v-html="compute.selectedPlan.description" />

    <i18n-t
      keypath="mywb.common.contact-with-us-sales"
      tag="p"
      class="is-size-400"
    >
      <wb-link
        href="mailto:sales.na@wallbox.com"
        target="_blank"
      >
        sales.na@wallbox.com
      </wb-link>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import { EnumPlans } from '@/utilities/plans'
import state from '@/state'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

const props = defineProps<{
  productId: number
}>()

const compute = reactive({
  selectedPlan: computed(() => state.plans.getPlan(props.productId)),

  selectedPlanTitle: computed(() => {
    switch (compute.selectedPlan.product_id) {
      case (EnumPlans.OPERATOR): return i18n.t('mywb.common.plan-operator')
      case (EnumPlans.BUSINESS): return i18n.t('mywb.common.plan-business')
      case (EnumPlans.BASIC): return i18n.t('mywb.common.plan-basic')
      default: return ''
    }
  }),

  currentPlan: computed((): number => state.organizations.getCurrentOrganization.plan.product_id),

  isCurrentPlan: computed((): boolean => compute.currentPlan === compute.selectedPlan.product_id)
})

</script>

<style lang="postcss" scoped>
.card {
  position: relative;
  border-radius: 1rem;
  background: white;
  display: grid;
  grid-template-rows: 48px min-content min-content 32px;
  grid-template-rows: 1;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 3.2rem;
  margin-top: 10px;
  border: 1px solid var(--grey-200);

  & .card-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.card[data-plan="basic"] {
  & .title {
    color: var(--grey-400);
  }
}

.is-plan-description {
  & :deep(b) {
    font-weight: 500;
  }

  & :deep(ul) {
    list-style: none;
    margin-left: 0;
    padding-left: 1.6em;
    text-indent: -1.6em;
  }

  & :deep(li) {
    &::before {
      content: "check_circle_filled";
      font-weight: 900;
      color: var(--primary-500);
      font-family: "Wallbox Icons", sans-serif;
      margin-right: 6px;
    }

    &:nth-child(even) {
      margin: 1.6rem 0;
    }
  }
}
</style>
