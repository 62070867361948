import {
  wapiRateRepository,
  createRate,
  deleteRate,
  editRate,
  getAllRates,
  getChargerRatesWeekSchedules,
  getIsRateAssigned,
  getRate,
  saveChargerRateSchedule
} from '@/core/rate'

export function provideRateUseCases () {
  const repo = wapiRateRepository()

  return {
    createRate: createRate(repo),
    deleteRate: deleteRate(repo),
    editRate: editRate(repo),
    getAllRates: getAllRates(repo),
    getChargerRatesWeekSchedules: getChargerRatesWeekSchedules(repo),
    getIsRateAssigned: getIsRateAssigned(repo),
    getRate: getRate(repo),
    saveChargerRateSchedule: saveChargerRateSchedule(repo)
  }
}
