import { object, string, type InferType } from 'yup'
import type { Organization } from '@/core/organization'

const organizationSchema = object({
  name: string().required(),
  organizationType: string<Organization['organizationType']>().required(),
  countryCode: string<Organization['countryCode']>().required()
})

export type OrganizationCreate = InferType<typeof organizationSchema>

export async function ensureCreateOrganizationIsValid (organization: any) {
  return await organizationSchema.validate(organization, { abortEarly: false })
}
