import { endpoints } from '@/core/shared/endpoints.config'
import type { ReturnType, SessionFieldsQuery, SessionRepository } from '@/core/session'
import type { GetGroupChargingSessions, GetSessionsFilterApi } from './types'
import type { CachedQuery, FiltersQuery, PaginatedQuery, RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import i18n from '@/engine/lang'
import { provideAuthUseCases } from '@/core/auth'

export const wapiSessionRepositoryBuilder: RepositoryHttpBuild<SessionRepository> = ({ httpService }) => {
  const instance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAll<T> (
      groupUid: string,
      params: FiltersQuery & PaginatedQuery & CachedQuery & SessionFieldsQuery,
      format: T
    ): Promise<ReturnType<T>> {
      const groupId: any = await instance.get(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!groupId) {
        if (format === 'csv') {
          return '' as ReturnType<T>
        }

        return {
          total: 0,
          data: []
        } as unknown as ReturnType<T>
      }

      const url = endpoints.v4.groups_groupId_charger_charging_sessions
        .replace('{groupId}', groupId.data.attributes.value?.toString())

      const result = await instance.get(url, {
        headers: {
          'content-type': format === 'csv' ? 'text/csv' : 'application/json',
          'workbox-cache-type': params.cache
        },
        params: {
          filters: JSON.stringify({ filters: params.filters }),
          'fields[charger_charging_session]': (params.fields ?? []).join(','),
          limit: params.limit,
          offset: params.offset
        },
        data: {}
      })

      if (format === 'csv') {
        return result as ReturnType<T>
      }

      const jsonResult = result as GetGroupChargingSessions | undefined

      if (!jsonResult) {
        return {
          total: 0,
          data: []
        } as unknown as ReturnType<T>
      }

      return {
        total: jsonResult.meta.count,
        data: jsonResult.data.map(session => {
          return {
            id: session.id,
            userId: session.attributes.user_id,
            userUid: session.attributes.user_uid,
            userName: session.attributes.user_name,
            userSurname: session.attributes.user_surname,
            userEmail: session.attributes.user_email,
            userAvatar: session.attributes.user_avatar,
            startTime: session.attributes.start_time,
            endTime: session.attributes.end_time,
            chargingTime: session.attributes.charging_time,
            totalCost: session.attributes.total_cost,
            currencyCode: session.attributes.currency_code,
            taxAmount: session.attributes.tax_amount,
            applicationFeePercentage: session.attributes.application_fee_percentage,
            taxPercentage: session.attributes.tax_percentage,
            sessionType: session.attributes.session_type,
            ratePrice: session.attributes.rate_price,
            accessPrice: session.attributes.access_price,
            chargerUid: session.attributes.charger_uid,
            chargerName: session.attributes.charger_name,
            locationName: session.attributes.location_name,
            locationId: session.attributes.location_uid,
            organizationId: session.attributes.group_uid,
            energy: session.attributes.energy,
            midEnergy: session.attributes.mid_energy,
            total: session.attributes.total,
            subtotal: session.attributes.subtotal,
            rateVariableType: session.attributes.rate_variable_type
          }
        })
      } as unknown as ReturnType<T>
    },

    async getAllFilters (groupUid) {
      const groupId: any = await instance.get(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!groupId) {
        return {
          chargers: [],
          groups: [],
          users: [],
          type: []
        }
      }

      const filters = await instance.get<GetSessionsFilterApi>(
        endpoints.v1.sessions_filters, { params: { group_id: groupId.data.attributes.value } }
      )

      if (!filters) {
        return {
          chargers: [],
          groups: [],
          users: [],
          type: []
        }
      }

      const reduceOptions = (obj: Record<string, string>) =>
        Object.entries(obj).map(([key, value]) => ({ label: value, value: +key }))

      const users = reduceOptions(filters.users)
      users.unshift({ label: i18n.global.t('mywb.common.anonymous'), value: 1 })

      return {
        chargers: reduceOptions(filters.chargers),
        groups: reduceOptions(filters.subgroups),
        users,
        type: [
          {
            label: i18n.global.t('mywb.common.free'),
            value: 'free'
          },
          {
            label: i18n.global.t('mywb.charger.pay-per-month'),
            value: 'pay_per_month'
          },
          {
            label: i18n.global.t('mywb.charger.pay-per-charge'),
            value: 'pay_per_charge'
          }
        ]
      }
    }
  }
}
