import type { VariableFeeType } from '@/core/rate'
import { useValidator } from '@/hooks/useValidator.hook'
import { reactive, type Ref } from 'vue'
import { getPriceAmountUpperLimit } from './ratesPriceLimit'
import state from '@/state'

const { yup, errors, validate, defineSchema } = useValidator()

interface Rate {
  name?: string
  fixedFee?: number
  variableType?: VariableFeeType
  variablePrice?: number
}

let field = reactive<Rate>({})

export function useFormValidator (variableActivated: Ref<boolean>, fixedActivated: Ref<boolean>) {
  const amountUpperLimit = getPriceAmountUpperLimit(state.organizations.getCurrentOrganization.currencyCode)

  field = reactive<Rate>({})

  defineSchema(field, {
    name: yup.string().required(),
    fixedFee: yup.lazy(() => fixedActivated.value
      ? yup.number().moreThan(0).max(amountUpperLimit).required()
      : yup.number().optional()
    ),

    variableType: yup.lazy(() => variableActivated.value
      ? yup.string().required()
      : yup.string().optional()
    ),

    variablePrice: yup.lazy(() => variableActivated.value
      ? yup.number().moreThan(0).max(amountUpperLimit).required()
      : yup.number().optional()
    )
  })

  return { errors, validate }
}

export function useValidatedRate () {
  return { field, errors }
}
