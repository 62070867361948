<template>
  <div class="is-fullwidth">
    <p class="label">
      {{ i18n.t('mywb.common.location') }}
      <wb-badge
        v-if="permissions.showUpSellingToPlanBusiness"
        inverted
        variant="grey"
        icon="lock"
        class="has-position-relative"
      >
        {{ i18n.t('mywb.plans.business') }}
      </wb-badge>

      <wb-link
        v-if="permissions.showUpSellingToPlanBusiness && permissions.canChangePlan"
        class="is-size-400 ml-8 has-position-relative"
        @click="methods.goToPlans"
      >
        {{ i18n.t('mywb.plan.upgrade') }}
      </wb-link>
    </p>

    <location-group
      v-model="compute.model"
      :reduce="props.reduce"
      :disabled="permissions.showUpSellingToPlanBusiness"
      :loading="props.loading"
      :locations="props.locations"
      :error="props.error"
      @on-change="emit('on-change')"
    />

    <wb-button
      v-if="permissions.canSeeLocations"
      :label="i18n.t('mywb.common.add-new-location')"
      data-test-id="addLocation"
      inverted
      variant="primary"
      icon="add"
      class="mt-16 has-offset"
      @click="emit('on-create-location')"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { permissions } from '@/engine/clients'
import LocationGroup from '@/components/locations/LocationGroup.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import type { Location } from '@/core/location'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

interface Props {
  modelValue?: string | Location
  error?: string
  loading?: boolean
  locations: Location[]
  reduce?: 'groupUid' | 'id'
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', model?: string | Location): void,
  (e: 'on-change'): void,
  (e: 'on-create-location'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})

const methods = {
  goToPlans () {
    router.push({ name: ROUTES.PLAN_BUSINESS })
    trackDataAction('upgrade_plan', {
      source: 'add_chargers_locations'
    })
  }
}
</script>

<style lang="postcss" scoped>

.has-position-relative {
  position: relative;
  top: -3px;
}

.is-primary-link {
  color: var(--primary-500);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  margin-bottom: 8px;
}

.has-offset {
  position: relative;
  left: -10px;
}
</style>
