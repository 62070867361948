<template>
  <wb-modal
    width="40"
    @close="emit('on-close')"
  >
    <p
      v-t="'mywb.payments.in-progress-description'"
      class="is-size-400 mb-24"
    />

    <wb-button
      data-test-id="cancelButton"
      variant="primary"
      size="block"
      :label="i18n.t('mywb.payments.continue-linking')"
      :disabled="props.loadingResetLinking"
      :loading="props.loadingContinueLinking"
      @click="emit('continue-linking')"
    />

    <wb-button
      class="mt-16"
      variant="white"
      outlined
      data-test-id="confirmButton"
      size="block"
      :label="i18n.t('mywb.payments.restart')"
      :disabled="props.loadingContinueLinking"
      :loading="props.loadingResetLinking"
      @click="emit('delete-account')"
    />
  </wb-modal>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Props {
  loadingContinueLinking?: boolean,
  loadingResetLinking?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-close'): void
  (e: 'continue-linking'): void
  (e: 'delete-account'): void
}
const emit = defineEmits<Events>()

</script>
