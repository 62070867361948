<template>
  <touch-header class="header" @on-handle-menu="data.isOpen = !data.isOpen" />
  <div
    id="main-panel"
    class="wrapper"
    :class="compute.wrapperClasses"
  >
    <aside-menu
      v-model="data.isOpen"
      class="aside"
      :class="{'is-open': data.isOpen}"
      @on-collapse="methods.collapseLayout"
    />

    <div class="content">
      <router-view v-slot="{ Component }">
        <div class="component-header">
          <div id="teleport-header" class="width-boundaries" />
        </div>
        <wb-skeleton-loader
          :type="mq.current !== 'mobile' ? 'page' : 'dots'"
          class="width-boundaries mt-24"
          :loading="data.loading"
        >
          <component :is="Component" />
        </wb-skeleton-loader>
      </router-view>
    </div>

    <footer-component class="footer" />

    <organization-edit-modal
      v-if="permissions.canSeeModalEditOrganization && data.showOrganizationEditModal && data.organizationToEdit"
      :organization="data.organizationToEdit"
      show-notification
      @on-close="methods.onCloseModal"
    />
  </div>
</template>

<script setup lang="ts">
import FooterComponent from '@/components/FooterComponent.vue'
import AsideMenu from '@/components/asideMenu/AsideMenu.vue'
import TouchHeader from '@/components/headers/TouchHeader.vue'
import state, { type FilterState } from '@/state'
import { trackDataScreen, trackDataEvent, trackDataTime, trackDataError } from '@/engine/metrics/trackDataManager'
import { useRoute, useRouter } from 'vue-router'
import { computed, watch, reactive, nextTick, onMounted, watchEffect } from 'vue'
import OrganizationEditModal from '@/components/modals/OrganizationEditModal.vue'
import { permissions } from '@/engine/clients'
import type { Organization } from '@/core/organization'
import {
  AUTH_USE_CASES,
  CONFIG_USE_CASES,
  injectStrict,
  INTERNATIONAL_USE_CASES,
  ORGANIZATION_USE_CASES
} from '@/engine/injectors'
import ENV from '@/engine/env/web.env'
import { useIsOnline } from '@/hooks/useIsOnline'
import { useMq } from 'vue3-mq'

const authUseCase = injectStrict(AUTH_USE_CASES)
const route = useRoute()
const router = useRouter()
const organizationUsesCases = injectStrict(ORGANIZATION_USE_CASES)
const internationalUseCases = injectStrict(INTERNATIONAL_USE_CASES)
const configUseCases = injectStrict(CONFIG_USE_CASES)
const { isOnline } = useIsOnline()
const mq = useMq()

interface Data {
  loading: boolean
  isOpen: boolean
  isCollapsed: boolean
  showOrganizationEditModal: boolean
  organizationToEdit?: Organization
}

const data = reactive<Data>({
  loading: false,
  isOpen: false,
  isCollapsed: false,
  showOrganizationEditModal: false
})

watch(() => route.name, (newRoute, oldRoute) => {
  if (newRoute !== oldRoute) {
    nextTick(() => {
      data.isOpen = false
      route.name && trackDataScreen(route.name.toString())
    })
  }

  if (route.query.update === 'organizations') {
    router.push({ query: {} }).catch(() => {})
    methods.getOrganizations()
  }
}, { deep: true, immediate: true })

const compute = reactive({
  wrapperClasses: computed(() => {
    return {
      'is-collapsed': data.isCollapsed,
      'is-open': data.isOpen
    }
  })
})

async function created () {
  data.loading = true

  await Promise.allSettled([
    methods.loadCurrencies(),
    methods.loadTimezones(),
    methods.loadCountries(),
    methods.loadConfig(),
    methods.loadUser()
  ])
  await methods.getOrganizations()
  await methods.setGroupRelatedToOrganization()

  methods.trackActiveSession()
  methods.manageQueryFilters()

  data.loading = false

  trackDataTime('layout-complete')
}

const methods = {
  collapseLayout (value: boolean) {
    data.isCollapsed = value
  },

  async getOrganizations () {
    const organizations = await organizationUsesCases.getAllOrganizations()
    state.organizations.setOrganizations(organizations)
  },

  onCloseModal () {
    state.global.setOrganizationTypeModalViewed(state.organizations.getCurrentOrganization.id)
    data.showOrganizationEditModal = false
  },

  async setGroupRelatedToOrganization () {
    const defaultOrg = state.organizations.allOrganizations.find(org => {
      return (org.ownerEmail === state.user.userLogged.email) && (org.createdBy === 'superadmin')
    })?.id

    let currentOrganizationId = state.organizations.getCurrentOrganization?.id || defaultOrg

    if (!currentOrganizationId) {
      trackDataError('organization-not-setted', {
        user: state.user.userLogged,
        allOrganizations: state.organizations.allOrganizations,
        currentOrganization: state.organizations.getCurrentOrganization
      })

      currentOrganizationId = state.organizations.allOrganizations[0]?.id

      if (!currentOrganizationId) {
        sessionStorage.removeItem('wb-state')
        state.global.removeLocalStorageSensitiveInfo()
        window.location.href = '/login'
        return
      }
    }

    state.organizations.setCurrentOrganization(currentOrganizationId)

    if (!state.organizations.getCurrentOrganization.groupUid) {
      const organizations = state.organizations.allOrganizations
      state.organizations.setCurrentOrganization(organizations[0].id)
    }

    if (!state.organizations.getCurrentOrganization.organizationType) {
      data.organizationToEdit = state.organizations.getCurrentOrganization
      data.showOrganizationEditModal = !state.global.hasViewedModal(state.organizations.getCurrentOrganization.id)
    }
  },

  async loadUser () {
    const me = await authUseCase.getMe()
    me && state.user.setUserLogged(me)
  },

  async loadTimezones () {
    const timezones = await internationalUseCases.getAllTimezones()
    state.global.setTimezones(timezones)
  },

  async loadCurrencies () {
    const currencies = await internationalUseCases.getAllCurrencies()
    state.global.setCurrencies(currencies)
  },

  async loadCountries () {
    const countries = await internationalUseCases.getAllCountries()
    state.global.setCountries(countries)
  },

  async loadConfig () {
    const config = await configUseCases.getConfig()
    state.config.setConfig(config)
  },

  trackActiveSession () {
    state.global.setSession('start')
    if (state.global.getSessionDurationInMinutes > 5) {
      trackDataEvent('portal-session-start')
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        state.global.setSession('end')
      } else if (document.visibilityState === 'visible') {
        state.global.setSession('start')
        if (state.global.getSessionDurationInMinutes > 5) {
          trackDataEvent('portal-session-start')
        }
      }
    })
  },

  manageQueryFilters () {
    function applyFilter <T extends keyof FilterState> (filter: T) {
      try {
        const filters = JSON
          .parse(route.query[filter]?.toString() ?? '{}') as FilterState[T]

        const entries = Object.entries(filters) as Array<[keyof FilterState[T], any]>
        entries.forEach(([key, value]) => {
          if (key === 'dates' && Array.isArray(value)) {
            value = value.map(date => new Date(date))
          }

          state.filters.setFilterValue({ filter, key, value })
        })
      } catch {}
    }

    applyFilter('accessConfigFilters')
    applyFilter('chargersFilters')
    applyFilter('dashboardFilters')
    applyFilter('invoiceFilters')
    applyFilter('locationsFilters')
    applyFilter('organizationsFilters')
    applyFilter('ratesFilters')
    applyFilter('sessionsFilters')
  }
}

onMounted(() => {
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://app.mavenoid.com/embedded/embedded.js'
  document.head.appendChild(script)
  window.mavenoid = window.mavenoid || []

  window.mavenoid.config = {
    noIframe: false,
    clientId: ENV.mavenoid.clientId,
    initFormData: { userId: state.user.userLogged.id, country: state.user.userLogged.countryIso2 }
  }
})

watchEffect(() => {
  if (isOnline.value) {
    created()
  } else {
    window.location.reload()
  }
})

</script>

<style lang="postcss">
:root {
  --max-width-content: 1728px;
  --header-touch-size: 6rem;
  --filters-size: 5.4rem;
  --header-height: 20.5rem;
}

@media (--tablet) {
  :root {
    --header-height: 8.5rem;
    --header-filters-height: 13.9rem;
  }
}
</style>

<style lang="postcss" scoped>

.wrapper {
  display: grid;
  grid-template-columns: auto;
  min-height: 100vh;

  @media (--tablet) {
    grid-template-columns: 24rem auto;
    grid-template-rows: minmax(0, auto) auto 48px;
    grid-template-areas:
      "aside content"
      "aside content"
      "aside footer";

    &.is-collapsed {
      grid-template-columns: 7rem auto;
    }
  }
}

.header {
  @media (--tablet) {
    grid-area: header;
  }
}

.aside {
  grid-column: 1;
  grid-row: 1 / span 3;

  @media (--tablet) {
    grid-area: aside;
  }
}

.content {
  grid-column: 1;
  grid-row: 1;

  @media (--tablet) {
    grid-area: content;
  }
}

.component-header {
  position: sticky;
  top: var(--header-touch-size);
  z-index: 200;
  background: var(--white);

  @media (--tablet) {
    top: 0;
  }
}

.width-boundaries {
  justify-self: center;
  margin: 0 auto;
  width: 95%;

  @media (--tablet) {
    max-width: var(--max-width-content);
  }
}

.footer {
  grid-column: 1;
  grid-row: 3;
  place-self: center;
  width: 95%;

  @media (--tablet) {
    grid-area: footer;
    max-width: var(--max-width-content);
  }
}
</style>
