<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.chargers') }}
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canAddCharger"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.add-charger') : ''"
        data-test-id="addChargerButton"
        icon="add"
        variant="primary"
        class="ml-12"
        @click="refs.chargers.handleAddCharger()"
      />
    </template>

    <template #filters>
      <chargers-filters :loading="!compute.chargersLoaded" />
    </template>
  </shared-teleport-header>

  <charger-list :ref="setRef('chargers')" />
</template>

<script setup lang="ts">
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import ChargersFilters from '@/components/filters/ChargersFilters.vue'
import ChargerList from '@/components/charger/ChargerList.vue'
import { useI18n } from 'vue-i18n'
import { permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import state from '@/state'
import { reactive, computed } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'

const mq = useMq()
const i18n = useI18n()
const { refs, setRef } = useTemplateRef()

const compute = reactive({
  chargersLoaded: computed((): boolean => state.global.get('chargersLoaded'))
})
</script>
