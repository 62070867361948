import i18n from '@/engine/lang'
import type { IntervalOption } from '@/utilities/chartSessions/chart.types'
import { IntervalEnum, PeriodicityEnum } from '@/utilities/chartSessions/chartTypeEnums'
import type { calendarOptions } from '@/utilities/chartSessions/chartOptions'
import { subMonths } from 'date-fns'

function getMonday (date: Date) {
  date = new Date(date)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)
  return new Date(date.setDate(diff))
}

function countIntervalsInHours (date: Date, interval: IntervalOption) {
  if (interval.type === IntervalEnum.DAYS) return interval.value * 24
  if (interval.type === IntervalEnum.MONTHS) return countIntervalsInDays(date, interval) * 24
  return 0
}

function countIntervalsInDays (date: Date, interval: IntervalOption) {
  if (interval.type === IntervalEnum.DAYS) return interval.value
  if (interval.type === IntervalEnum.MONTHS) {
    const from = new Date(date.getFullYear(), date.getMonth() - interval.value + 1, 0).getTime()
    return Math.abs(Math.ceil((from - date.getTime()) / (1000 * 3600 * 24)))
  }
  return 0
}

function countIntervalsInWeeks (date: Date, interval: IntervalOption) {
  const days = countIntervalsInDays(date, interval)
  let day = 1
  let lastMondayDay = -1
  let numOfWeeks = 0
  while (day <= days) {
    const currentDate = new Date(Date.now() - (days - (days - day) - 1) * 24 * 60 * 60 * 1000)
    const monday = getMonday(currentDate)
    if (lastMondayDay !== monday.getDate()) numOfWeeks++
    lastMondayDay = monday.getDate()
    day++
  }
  return numOfWeeks
}

function getIntervalPerHour (date: Date, offset: number) {
  return {
    startTimeInterval: new Date(new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(), 0, 0
    ).setHours(date.getHours() - offset)).getTime() / 1000,

    endTimeInterval: new Date(new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(), 0, 0
    ).setHours(date.getHours() - (offset - 1))).getTime() / 1000
  }
}

function getIntervalPerDay (date: Date, offset: number) {
  date = new Date(date.getTime() - offset * 24 * 60 * 60 * 1000)
  return {
    startTimeInterval: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0, 0, 0
    ).getTime() / 1000,

    endTimeInterval: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23, 59, 59
    ).getTime() / 1000
  }
}

function getIntervalPerWeek (date: Date, offset: number) {
  let firstDayOfWeek = getMonday(date)
  firstDayOfWeek = new Date(firstDayOfWeek.setDate(firstDayOfWeek.getDate() - (7 * offset)))
  const lastDayOfWeek = new Date(firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000)
  return {
    startTimeInterval: new Date(
      firstDayOfWeek.getFullYear(),
      firstDayOfWeek.getMonth(),
      firstDayOfWeek.getDate(),
      0, 0, 0
    ).getTime() / 1000,

    endTimeInterval: new Date(
      lastDayOfWeek.getFullYear(),
      lastDayOfWeek.getMonth(),
      lastDayOfWeek.getDate(),
      23, 59, 59
    ).getTime() / 1000
  }
}

function getIntervalPerMonth (date: Date, offset: number) {
  date = subMonths(date, offset)
  return {
    startTimeInterval: new Date(
      date.getFullYear(),
      date.getMonth(),
      1,
      0, 0, 0
    ).getTime() / 1000,

    endTimeInterval: new Date(
      date.getFullYear(),
      date.getMonth(),
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(),
      23, 59, 59
    ).getTime() / 1000
  }
}

export function getIntervalDateTime (date: Date, interval: IntervalOption) {
  if (interval.type === IntervalEnum.DAYS) {
    return {
      startInterval: new Date(new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0
      ).setDate(date.getDate() - interval.value + 1)).getTime(),
      endInterval: date.getTime()
    }
  }

  if (interval.type === IntervalEnum.MONTHS) {
    return {
      startInterval: new Date(
        date.getFullYear(),
        date.getMonth() - (interval.value - 1),
        1,
        0, 0, 0
      ).getTime(),
      endInterval: date.getTime()
    }
  }
  return { startInterval: 0, endInterval: 0 }
}

export function getTotalIntervals (date: Date, periodicity: PeriodicityEnum, interval: IntervalOption) {
  switch (periodicity) {
    case (PeriodicityEnum.HOUR): return countIntervalsInHours(date, interval)
    case (PeriodicityEnum.DAY): return countIntervalsInDays(date, interval)
    case (PeriodicityEnum.WEEK): return countIntervalsInWeeks(date, interval)
    case (PeriodicityEnum.MONTH): return interval.value - 1
    default: return 0
  }
}

export function getInterval (date: Date, periodicity: PeriodicityEnum, offset: number) {
  switch (periodicity) {
    case (PeriodicityEnum.HOUR): return getIntervalPerHour(date, offset)
    case (PeriodicityEnum.DAY): return getIntervalPerDay(date, offset)
    case (PeriodicityEnum.WEEK): return getIntervalPerWeek(date, offset)
    case (PeriodicityEnum.MONTH): return getIntervalPerMonth(date, offset)
    default: return { startTimeInterval: 0, endTimeInterval: 0 }
  }
}

export function getDatesLabel (dates: Date[]) {
  return `
    ${new Intl.DateTimeFormat(i18n.locale, { dateStyle: 'medium' }).format(dates[0])}
      -
    ${new Intl.DateTimeFormat(i18n.locale, { dateStyle: 'medium' }).format(dates[1])}
  `
}

export function getIntervalLabel (option: typeof calendarOptions[0]) {
  let label = ''
  if (new Date(option.startInterval).getDate() !== new Date(option.endInterval).getDate()) {
    label = new Intl.DateTimeFormat(i18n.locale, { dateStyle: 'medium' }).format(option.startInterval)
    label = label + ' - '
  }
  return label + new Intl.DateTimeFormat(i18n.locale, { dateStyle: 'medium' }).format(option.endInterval)
}

function monthDiff (d1: Date, d2: Date) {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

function weeksDiff (d1: Date, d2: Date) {
  let diff = (d2.getTime() - d1.getTime()) / 1000
  return Math.abs(Math.round(diff /= (60 * 60 * 24 * 7))) + 1
}

export function daysDiff (d1: Date, d2: Date) {
  const diff = d2.getTime() - d1.getTime()
  return Math.ceil(diff / (1000 * 3600 * 24)) + 1
}

function hoursDiff (d1: Date, d2: Date) {
  return Math.abs(d2.getTime() - d1.getTime()) / 36e5
}

export function getTotalDatesIntervals (dates: Date[], periodicity: PeriodicityEnum) {
  switch (periodicity) {
    case (PeriodicityEnum.HOUR): return hoursDiff(dates[0], dates[1])
    case (PeriodicityEnum.DAY): return daysDiff(dates[0], dates[1])
    case (PeriodicityEnum.WEEK): return weeksDiff(dates[0], dates[1])
    case (PeriodicityEnum.MONTH): return monthDiff(dates[0], dates[1])
    default: return 0
  }
}
