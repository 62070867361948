import countries from 'i18n-iso-countries'
import { computed, reactive } from 'vue'
import i18n from '@/engine/lang'
import type { CountryIso2 } from '@/core/international'
import type { Config } from '@/core/config'

interface State {
  midWithCountriesNeeded: CountryIso2[]
  countriesWithPayments: CountryIso2[]
}

const initialState = (): State => ({
  midWithCountriesNeeded: [],
  countriesWithPayments: []
})

const state: State = reactive(initialState())

const getters = {
  countriesWithMidNeeded: computed(() => {
    return state.midWithCountriesNeeded
  }),

  countriesWithPayments: computed(() => {
    return state.countriesWithPayments.map(code => {
      return {
        code,
        name: countries.getName(code, i18n.__rootLanguage, { select: 'official' }) ?? ''
      }
    }).sort((a, b) => a.name.localeCompare(b.name))
  })
}
const setters = {
  setConfig (config: Config) {
    state.midWithCountriesNeeded = config.midCountries
    state.countriesWithPayments = config.countriesWithPayments
  }
}

export default reactive({
  ...getters,
  ...setters
})
