<template>
  <div class="wrapper">
    <container-component class="m-32">
      <div class="subscription has-centered-content">
        <client-img-component src="logo-wallbox-icon" class="logo-wallbox" />

        <div class="has-text-centered py-32">
          <!-- 1. Subscribing... -->
          <template v-if="data.loading">
            <client-img-component
              inline
              alt="myWallbox"
              title="myWallbox"
              class="spin-loading mt-32"
              src="loading_icon"
            />
            <div class="is-size-900 has-text-primary-500 mt-24 is-uppercase">
              {{ i18n.t('mywb.subscription.subscribing') }}
            </div>
            <div class="is-size-500 mt-24">
              {{ i18n.t('mywb.subscription.wait-moment-please') }}
            </div>
          </template>

          <!-- 2. Error on subscription -->
          <template v-else-if="data.hasError">
            <div class="is-size-900 has-text-danger-500 mt-24 is-uppercase">
              {{ i18n.t('mywb.subscription.error-on-subscription') }}
            </div>
            <div class="is-size-500 mt-24">
              {{ i18n.t('mywb.subscription.something-went-wrong') }}
            </div>
            <div class="is-size-500 mt-24">
              {{ i18n.t('mywb.subscription.contact-group-admin') }}
            </div>
            <wb-button
              class="mt-32"
              :label="i18n.t('mywb.common.go-to-mywallbox')"
              :to="{ name: state.organizations.defaultHomePage }"
            />
          </template>

          <!-- 3. Confirmed subscription -->
          <template v-else>
            <div class="is-size-900 has-text-primary-500 mt-24 is-uppercase">
              {{ i18n.t('mywb.subscription.confirmed-subscription') }}
            </div>
            <div class="is-size-500 mt-24">
              {{ i18n.t('mywb.subscription.subscribed-succesfully') }}
            </div>
            <wb-button
              class="mt-32"
              :label="i18n.t('mywb.common.go-to-mywallbox')"
              :to="{ name: state.organizations.defaultHomePage }"
            />
          </template>
        </div>
      </div>
    </container-component>
  </div>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'

import state from '@/state'
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import type { ActivateContract } from '@/core/user'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'

const userUseCases = injectStrict(USER_USE_CASES)
const i18n = useI18n()
const route = useRoute()

const data = reactive({
  loading: false,
  hasError: false
})

onMounted(() => {
  methods.subscriptionConfirmEmail()
})

const form = reactive<ActivateContract>({
  contractId: +(route.query.contractId ?? 1),
  confirmToken: route.query.confirmToken?.toString() ?? ''
})

const methods = {
  async subscriptionConfirmEmail () {
    if (route.query.confirmToken && route.query.contractId) {
      data.loading = true
      try {
        await userUseCases.activatePayPerMonthContract(form)
      } catch (error) {
        data.hasError = true
      } finally {
        data.loading = false
      }
    } else {
      data.hasError = true
    }
  }
}
</script>

<style lang="postcss" scoped>
.wrapper {
  display: grid;
  width: 100vw;
  min-height: 100vh;
  place-items: center;
}

.subscription {
  min-width: 60vw;
  min-height: 60vh;
  padding: 100px;
}

.spin-loading {
  width: 44px;
  animation: spin 1s infinite linear;
  fill: var(--primary-500);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.has-centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.logo-wallbox {
  width: 50px;
}
</style>
