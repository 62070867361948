export enum ChartTypesEnum {
  ENERGY = 'energy',
  TIME = 'time',
  SESSIONS = 'sessions',
  COST = 'cost',
  INCOME = 'income'
}

export enum PeriodicityEnum {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum IntervalEnum {
  DAYS = 'days',
  MONTHS = 'months'
}

export enum AnalysisEnum {
  LINEAR = 'linear',
  CUMULATIVE = 'cumulative'
}

export enum VisualizationEnum {
  AREA = 'area',
  BAR = 'bar',
  LINE = 'line'
}
