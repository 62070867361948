<template>
  <wb-table-row
    :id="props.location.id"
    @click="
      router.push({ name: ROUTES.LOCATION_DETAIL, params: { locationId: props.location.id ?? '' } })"
  >
    <wb-table-row-item>
      <span class="wb-icons">location</span>
    </wb-table-row-item>
    <wb-table-row-item :data-test-id="'name-'+props.location.name">
      <p class="is-font-weight-500">
        {{ props.location.name }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item :data-test-id="'address-'+props.location.name">
      {{ props.location.address }}
      <wb-button
        v-if="compute.incompleteAddress"
        :label="i18n.t('mywb.common.complete-address')"
        variant="warning"
        shape="squircle"
        size="small"
        icon-position="right"
        icon="edit"
        outlined
        @mousedown="emit('on-edit', props.location)"
      />
    </wb-table-row-item>
    <wb-table-row-item data-test-id="chargerAmount">
      <wb-badge variant="green">
        {{ props.location?.chargers?.length ?? 0 }}
      </wb-badge>
    </wb-table-row-item>
    <wb-table-row-item @click.stop>
      <wb-button-groups>
        <wb-dropdown class="group-item">
          <template #activator>
            <wb-button
              id="configGroup-payments"
              icon="edit"
              data-test-id="editOption"
              variant="white"
              outlined
              size="small"
              @mousedown="emit('on-edit', props.location)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.edit') }}
          </template>
        </wb-dropdown>

        <wb-dropdown v-if="!compute.isOrganization" class="group-item">
          <template #activator>
            <wb-button
              id="configGroup-delete"
              icon="delete"
              data-test-id="deleteOption"
              variant="danger"
              size="small"
              outlined
              @mousedown="emit('on-delete', props.location)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.delete') }}
          </template>
        </wb-dropdown>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import type { Location } from '@/core/location'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

interface PropsType {
  location: Location
}

const props = defineProps<PropsType>()

type Events = {
  (e: 'on-edit', location?: Location): void,
  (e: 'on-delete', location?: Location): void,
}

const emit = defineEmits<Events>()

const compute = reactive({
  isOrganization: computed((): boolean => {
    return props.location?.groupUid === state.organizations.getCurrentOrganization.groupUid
  }),

  incompleteAddress: computed((): boolean => {
    return !props.location.latitude || !props.location.longitude
  })
})
</script>
