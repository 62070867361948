import type { UserRepository } from '@/core/user'
import { userInitials } from '@/utilities/users'
import { EnumRoles } from '@/utilities/user-roles'
import type {
  ApiGetUserGroupsResponse,
  ApiInvitationsResponse,
  GetChargerData,
  ApiGetUserResponse,
  GetUserDetail
} from './types'
import { endpoints } from '@/core/shared/endpoints.config'
import { clientConfig } from '@/engine/clients'
import ENV from '@/engine/env/web.env'
import type { RepositoryHttpBuild } from '@/core/shared'
import { provideAuthUseCases } from '@/core/auth'

export const wapiRepositoryBuilder: RepositoryHttpBuild<UserRepository> = ({ httpService }) => {
  const instance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const userApiInstance = httpService.create({
    baseURL: ENV.api.userApiBaseUrl,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  async function getOldId (userId: string | number): Promise<number> {
    if (typeof userId === 'string') {
      const info = await instance.get<any>(endpoints.v4.user_ulid_id.replace('{ulid}', userId), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      return info.data.attributes.value
    } else {
      return userId
    }
  }

  async function getOldGroupId (groupUid: string): Promise<number> {
    const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
      headers: {
        'workbox-cache-type': 'cache-first'
      }
    })

    return groupId.data.attributes.value
  }

  return {
    async getAllByCharger (chargerUid) {
      const id = await instance.get<any>(endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', chargerUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })
        .then(result => result.data.attributes.value) as number

      const chargerData = await instance.get<GetChargerData>(
        endpoints.v2.charger_chargerId.replace('{chargerId}', id.toString())
      )

      if (!chargerData) return []

      return chargerData.data.users.map(user => {
        return {
          id: user.id,
          uid: user.uid,
          name: user.name,
          surname: user.surname,
          avatar: user.avatar,
          email: user.email,
          assigned: user.assigned,
          role: EnumRoles[user.profile],
          initials: userInitials(user)
        }
      })
    },

    async getAllByOrganization (groupUid) {
      const groupId = (await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })).data.attributes.value as number

      if (!groupId) return []

      const userGroups = await instance.get<ApiGetUserGroupsResponse>(endpoints.v3.users_groups)
        .then(response => response?.result?.groups)

      if (!userGroups) return []

      const rootGroup = userGroups.find(group => group.id === groupId)

      return userGroups
        .find(group => group.id === groupId)?.users
        .map(user => {
          const accessConfig = rootGroup?.accessConfigs?.find(accessConfig => accessConfig.id === user.accessConfig)
          const rootGroupUser = (rootGroup?.users.find(rootUser => user.id === rootUser?.id))
          const IsCustomAccess = rootGroupUser?.accessConfig !== accessConfig?.id

          return {
            id: user.id,
            uid: user.uid,
            name: user.name || '',
            surname: user.lastname || '',
            avatar: user.avatar || '',
            email: user.email,
            assigned: false,
            role: user.profile,
            initials: userInitials({ name: user.name || '', surname: user.lastname }),
            status: 'active' as const,
            hasCustomAccess: IsCustomAccess,
            accessGroup: !IsCustomAccess
              ? {
                  chargers: accessConfig?.chargers ?? [],
                  id: accessConfig?.id || -1,
                  name: accessConfig?.name
                }
              : undefined,
            contract: user.contract,
            contractStatus: user.contractStatus
          }
        }) || []
    },

    async getAllInvitations (groupUid) {
      const groupId = (await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })).data.attributes.value as number

      if (!groupId) return []

      const userGroups = await instance.get<ApiGetUserGroupsResponse>(endpoints.v3.users_groups)
        .then(response => response?.result?.groups)

      if (!userGroups) return []

      const rootGroup = userGroups.find(group => group.id === groupId)

      const invitations = await instance.get<ApiInvitationsResponse>(
        endpoints.v4.groups_groupId_invitations.replace('{groupId}', groupId.toString()), {
          params: {
            limit: 9999
          }
        })

      if (!invitations) return []

      return invitations.data.map(invitation => {
        const accessConfig = rootGroup?.accessConfigs?.find(accessConfig =>
          accessConfig.id === invitation.attributes.access_config_id)

        return {
          uid: invitation.id,
          id: +invitation.id,
          name: '',
          surname: '',
          avatar: '',
          email: invitation.attributes.email,
          assigned: false,
          role: invitation.attributes.profile || EnumRoles.user,
          initials: '',
          status: 'pending' as const,
          hasCustomAccess: false,
          accessGroup: {
            chargers: accessConfig?.chargers ?? [],
            id: accessConfig?.id || -1,
            name: accessConfig?.name
          },
          contract: undefined,
          contractStatus: undefined
        }
      })
    },

    async getUserDetail (userId) {
      const userOldId = await getOldId(userId)

      if (!userOldId) return undefined

      const detail = await instance.get<GetUserDetail>(
        endpoints.v1.users_data_userId.replace('{userId}', userOldId?.toString())
      )

      if (!detail) return undefined

      return {
        id: detail.id,
        name: detail.name,
        surname: detail.surname,
        countryIso3: detail.country_code,
        registerDate: detail.register_date,
        email: detail.email,
        avatar: detail.avatar
      }
    },

    async getRfidByUser (groupUid, userId) {
      const userOldId = await getOldId(userId)
      if (!userOldId) return undefined

      const groupId = await getOldGroupId(groupUid)

      const user = await instance.get<ApiGetUserResponse>(
        endpoints.v2.user_userId.replace('{userId}', userOldId?.toString())
      )

      if (!user) return undefined

      return user.data.access.find(access => {
        return access.type === 'rfid' && access.group === groupId
      })?.authentication
    },

    async updateUserRfid (rfidUpdate) {
      const userOldId = await getOldId(rfidUpdate.userId)
      const groupId = await getOldGroupId(rfidUpdate.groupUid)

      await instance.post(endpoints.v3.access, {
        user: userOldId,
        group: groupId,
        type: 'rfid',
        authentication: rfidUpdate.rfid
      })
    },

    async deleteUserRfid (rfidDelete) {
      const userOldId = await getOldId(rfidDelete.userId)
      const groupId = await getOldGroupId(rfidDelete.groupUid)

      await instance.delete(endpoints.v3.access, {
        data: {
          user: userOldId,
          group: groupId,
          type: 'rfid'
        }
      })
    },

    async updateUserImage (imageUpdate) {
      const readAsDataURL = async () => await new Promise<ProgressEvent<FileReader>>(resolve => {
        const reader = new FileReader()
        reader.onload = file => { resolve(file) }
        reader.readAsDataURL(imageUpdate.image)
      })

      const fileContents = await readAsDataURL()

      const result = fileContents?.target?.result as string
      const bytes = result.split?.(',')?.[1]

      await userApiInstance.put(endpoints.userApi.usersUserIdAvatar.replace('{userId}', imageUpdate.user),
        {
          data: {
            type: 'user-avatar',
            attributes: {
              name: imageUpdate.image.name,
              bytes
            }
          }
        }, {
          Partner: clientConfig.partner
        }
      )
    },

    async activatePayPerMonthContract (activateContract) {
      const endpoint = endpoints.v3.users_contracts_contractId_active
        .replace('{contractId}', activateContract.contractId.toString())

      await instance.post(endpoint, {
        confirmToken: activateContract.confirmToken
      })
    },

    async unsubscribeUserFromPayPerMonth (unsubscribeContract) {
      await instance.delete(endpoints.v3.contracts_contractId
        .replace('{contractId}', unsubscribeContract.contractId.toString()))
    },

    async inviteUsersWithChargers (inviteUsersWithChargers) {
      interface Response {
        result: {
          id: number
        }
      }

      const groupId = await getOldGroupId(inviteUsersWithChargers.groupUid)

      await Promise.all(inviteUsersWithChargers.users.map(async user => {
        const { data: { result } } = await instance.post<Response>(endpoints.v3.accessConfigs, {
          chargers: inviteUsersWithChargers.chargers,
          group: groupId,
          createdByUser: false
        })

        await instance.post(endpoints.v3.add_users_to_access_config, {
          profile: inviteUsersWithChargers.role,
          users: [
            {
              email: user.email,
              rfid: user.rfid
            }
          ],
          subscribed: inviteUsersWithChargers.hasPayPerMonth,
          access_config: result.id,
          lang: inviteUsersWithChargers.lang,
          branding_id: inviteUsersWithChargers.branding
        })
      }))
    },

    async addChargersToUser (addChargersToUser) {
      const user = await instance.get<ApiGetUserResponse>(
        endpoints.v2.user_userId.replace('{userId}', addChargersToUser.userId?.toString())
      )

      if (!user) return

      const groupId = await getOldGroupId(addChargersToUser.groupUid)

      const accessGroup = user.data.accessConfigs
        .find(accessConfig => groupId === accessConfig.group)

      if (!accessGroup) throw new Error('can not be undefined')

      await instance.put(endpoints.v3.accessConfigs_accessConfigId
        .replace('{accessConfigId}', accessGroup.id.toString()), {
        chargers: addChargersToUser.chargers,
        id: accessGroup.id
      })
    },

    async inviteUsersToAccessConfig (inviteUsersToAccessConfig) {
      await instance.post(endpoints.v3.add_users_to_access_config, {
        profile: inviteUsersToAccessConfig.role,
        users: inviteUsersToAccessConfig.users,
        subscribed: inviteUsersToAccessConfig.hasPayPerMonth,
        access_config: inviteUsersToAccessConfig.accessConfig,
        lang: inviteUsersToAccessConfig.lang,
        branding_id: inviteUsersToAccessConfig.branding
      })
    },

    async removeUser (removeUser) {
      if (removeUser.accessConfig) {
        const endpoint = endpoints.v3.accessConfigs_accessConfigId_users_userId
          .replace('{accessConfigId}', removeUser.accessConfig.toString())
          .replace('{userId}', removeUser.user.toString())

        await instance.delete(endpoint, { params: { action: 'delete' } })
      }

      const groupId = await getOldGroupId(removeUser.groupUid)

      const endpoint = endpoints.v2.user_userId_assignGroup_groupId
        .replace('{userId}', removeUser.user.toString())
        .replace('{groupId}', groupId.toString())

      await instance.put(endpoint, { assign: false })
    },

    async revokeUserInvitation (revokeUserInvitation) {
      const groupId = await getOldGroupId(revokeUserInvitation.groupUid)

      await instance.delete(endpoints.v4.groups_groupId_invitations_invitationId
        .replace('{groupId}', groupId.toString())
        .replace('{invitationId}', revokeUserInvitation.invitationId.toString())
      )
    },

    async resendUserInvitation (resendUserInvitation) {
      const groupId = await getOldGroupId(resendUserInvitation.groupUid)

      await instance.patch(endpoints.v4.groups_groupId_invitations_invitationId
        .replace('{groupId}', groupId.toString())
        .replace('{invitationId}', resendUserInvitation.invitationId.toString()), {
        lang: resendUserInvitation.lang,
        branding_id: resendUserInvitation.branding
      })
    }

  }
}
