<template>
  <wb-modal
    width="40"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', {element: props.location?.name}) }}
    </template>

    <p class="is-size-400">
      {{ i18n.t('mywb.location.delete-location-description', {element: props.location?.name}) }}
    </p>

    <location-group
      v-model="data.groupUid"
      reduce="groupUid"
      :error="errors.groupUid"
      :filter-location="props.location?.id"
      :locations="props.locations"
      class="is-fullwidth mt-8"
    />

    <template #actions>
      <div class="is-flex">
        <wb-button
          data-test-id="cancelButton"
          class="mr-12"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          variant="danger"
          size="block"
          :label="i18n.t('mywb.common.move-delete')"
          :loading="data.loading"
          @click="validate(methods.handleClick)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import LocationGroup from '@/components/locations/LocationGroup.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import type { Location } from '@/core/location'
import { useValidator } from '@/hooks/useValidator.hook'
import type { Charger } from '@/core/charger'
import { CHARGER_USE_CASES, LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'

const locationUsesCases = injectStrict(LOCATION_USE_CASES)
const chargerUsesCases = injectStrict(CHARGER_USE_CASES)

const toast = useToast()
const i18n = useI18n()
const emit = defineEmits(['on-close', 'on-confirm'])
const { yup, errors, defineSchema, validate } = useValidator()

interface PropsType {
  location?: Location
  locations: Location[]
  chargers?: Charger[]
}

const props = defineProps<PropsType>()

interface Data {
  loading: boolean,
  groupUid?: string
}

const data = reactive<Data>({
  loading: false,
  groupUid: undefined
})

defineSchema(data, {
  groupUid: yup.string().required()
})

const methods = {
  async handleClick () {
    if (!props.location || !data.groupUid) return
    data.loading = true

    try {
      await chargerUsesCases.updateChargersLocation(
        (props.location.chargers || []).map(charger => charger.uid),
        data.groupUid
      )

      await locationUsesCases.deleteLocation(props.location.id)
      emit('on-confirm')
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }

    emit('on-close')
    data.loading = false
  }
}

function created () {
  trackDataScreen('chargers-delete-group-with-chargers')
}

created()
</script>
