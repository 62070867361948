<template>
  <chargers-summary
    title="mywb.dashboard.active-chargers"
    subtitle="mywb.dashboard.total-active-chargers"
    icon="bolt"
    icon-color="var(--info-500)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-active-chargers"
    :total-chargers="compute.totalChargers.length"
    :series="compute.activeChargersSeries"
    data-test-id="activeChargers"
  />

  <chargers-summary
    title="mywb.dashboard.available-chargers"
    subtitle="mywb.dashboard.total-available-chargers"
    icon="check_circle"
    icon-color="var(--success-700)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-available-chargers"
    :total-chargers="compute.totalChargers.length"
    :series="compute.onlineChargersSeries"
    data-test-id="availableChargers"
  />

  <chargers-summary
    title="mywb.dashboard.unavailable-chargers"
    subtitle="mywb.dashboard.total-unavailable-chargers"
    icon="error_outline"
    icon-color="var(--danger-500)"
    :loading="props.loading"
    subtotal-text="mywb.dashboard.nth-unavailable-chargers"
    :total-chargers="compute.totalChargers.length"
    :series="compute.offlineChargersSeries"
    data-test-id="unavailableChargers"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed } from 'vue'
import { getColorByStatus, STATUSES, type Status } from '@/utilities/charger/chargerStatuses'
import { objects } from '@wallbox/toolkit-ui'
import { useI18n } from 'vue-i18n'
import ChargersSummary from './ChargersSummary.vue'
import { filterChargerByUsers } from '@/utilities/users'
import type { Charger } from '@/core/charger'

const { t } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

interface ChartInfo {
  amount: number
  data: number[]
  name: string
  color: string
  status: STATUSES
}

const compute = reactive({
  chargersFilterByLocation: computed((): Charger[] => {
    let chargers = state.charger.getChargers

    if (state.filters.dashboardFilters.locations.length > 0) {
      chargers = chargers.filter(charger => state.filters
        .dashboardFilters.locations.some(location => location.id === charger.locationId))
    }

    return chargers
  }),

  totalChargers: computed((): Charger[] => {
    return filterChargerByUsers(
      compute.chargersFilterByLocation,
      state.filters.dashboardFilters.users.map(user => user.id)
    )
  }),

  chargersByStatus: computed((): Record<string, Status[]> => {
    return objects.groupBy(
      compute.totalChargers.map(charger => charger.status),
      'code'
    )
  }),

  onlineChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.LOCKED,
      STATUSES.READY,
      STATUSES.UPDATING
    ])
  }),

  activeChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.SCHEDULED,
      STATUSES.WAITING,
      STATUSES.CHARGING,
      STATUSES.DISCHARGING,
      STATUSES.PAUSED
    ])
  }),

  offlineChargersSeries: computed((): ChartInfo[] => {
    return methods.getLabelByStatuses([
      STATUSES.ERROR,
      STATUSES.DISCONNECTED
    ])
  })
})

const methods = {
  getLabelByStatuses (statuses: STATUSES[]) {
    return statuses.map(status => {
      const amount = compute.chargersByStatus[status]?.length ?? 0
      return {
        amount,
        status,
        data: [(amount / compute.totalChargers.length * 100) || 0],
        name: t(compute.chargersByStatus[status]?.[0]?.label ?? ''),
        color: getColorByStatus(status).bg
      }
    })
  }
}

</script>
