<template>
  <wb-table-row :data-test-id="`${props.session.chargerUid}-${props.session.startTime}`">
    <wb-table-row-item
      data-test-id="sessionId"
      class="has-text-primary-700 py-16 has-cursor-pointer"
      @click="methods.copy"
    >
      {{ compute.sessionId }}
    </wb-table-row-item>
    <wb-table-row-item data-test-id="sessionStart">
      <div data-test-id="date">
        {{ compute.startTimeDate }}
      </div>
      <div data-test-id="time" class="has-text-grey-500">
        {{ compute.startTimeHour }} - {{ compute.endTimeHour }}
      </div>
    </wb-table-row-item>
    <wb-table-row-item class="py-16">
      <div class="is-grid">
        <wb-user-avatar
          class="mr-8 avatar"
          data-test-id="userPicture"
          size="small"
          :src="compute.user.avatar"
          :initials="compute.user.initials"
        />
        <div class="has-text-overflow">
          <wb-link
            v-if="compute.user.id"
            type="primary"
            data-test-id="userName"
            @click="compute.isOwnerUser
              ? router.push({ name: ROUTES.ACCOUNT })
              : methods.goToUser(compute.user.uid ?? '1')"
          >
            {{ compute.user.name }}
          </wb-link>
          <p v-else data-test-id="userName" class="has-text-overflow">
            {{ compute.user.name }}
          </p>
          <p
            v-if="compute.user.email"
            data-test-id="userEmail"
            class="has-text-overflow has-text-grey-500"
          >
            {{ compute.user.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>
    <wb-table-row-item class="py-16">
      <div>
        <wb-link
          type="primary"
          data-test-id="charger"
          @click="methods.goToChargers"
        >
          {{ props.session.chargerName }}
        </wb-link>
        <div class="has-text-overflow" data-test-id="location">
          <span>
            {{ props.session.locationName }}
          </span>
        </div>
      </div>
    </wb-table-row-item>
    <wb-table-row-item class="py-16">
      <span data-test-id="sessionEnergy">
        {{ compute.sessionEnergy }}
      </span>
      •
      <span data-test-id="timeDuration" class="has-text-grey-500">
        {{ compute.timeDuration }}
      </span>

      <div
        v-if="compute.midInformation"
        class="wb-icons mid-icon"
        :data-test-id="`chargerMid-${compute.midInformation}`"
      >
        {{ compute.midInformation !== MID_STATUS.ERROR ? 'mid': 'mid_error' }}
      </div>
    </wb-table-row-item>
    <wb-table-row-item
      data-test-id="sessionType"
      class="py-16"
    >
      <div class="types">
        <wb-badge
          v-if="props.session.sessionType !== 'free'"
          variant="grey"
          class="label"
        >
          {{ compute.rateVariableTypeParsed }}
        </wb-badge>
        <wb-badge
          :variant="compute.typeParsed?.color"
          class="label"
        >
          {{ compute.typeParsed?.text }}
        </wb-badge>
      </div>
    </wb-table-row-item>
    <wb-table-row-item class="py-16">
      <div data-test-id="energyCost">
        {{ compute.costParsed }}
      </div>
    </wb-table-row-item>
    <wb-table-row-item class="py-16">
      <wb-dropdown
        v-if="props.session.sessionType !== 'free'"
        menu-position="bottom-end"
        :distance-offset="16"
        @click="methods.trackPayments"
      >
        <template #activator>
          <div class="is-flex is-align-center has-cursor-pointer">
            <div data-test-id="amoutPaid">
              {{ compute.totalParsed }}
            </div>
            <span
              class="wb-icons details-links"
              data-test-id="paymentDetailsBtn"
            >
              chevron_right
            </span>
          </div>
        </template>
        <template #container>
          <dl class="dropdown" data-test-id="paymentDetails">
            <dt
              v-t="{ path:'mywb.session.user-has-paid', args: [compute.user.name] }"
              class="is-size-300 is-font-weight-500 mr-24 name"
              data-test-id="userName"
            />
            <dd class="is-font-weight-500" data-test-id="total">
              {{ compute.totalParsed }}
            </dd>

            <dl
              v-t="{ path:'mywb.common.subtotal-amount' }"
              data-test-id="subtotalTitle"
              class="is-size-300 has-text-grey-500"
            />

            <dd class="is-size-300 has-text-grey-500" data-test-id="subtotal">
              {{ compute.subtotalParsed }}
            </dd>
            <dl
              data-test-id="vat"
              class="is-size-300 has-text-grey-500"
            >
              {{ i18n.t('mywb.common.vat') }} ({{ compute.vatPercentage }})
            </dl>
            <dd class="is-size-300 has-text-grey-500" data-test-id="tax">
              {{ compute.taxAmountParsed }}
            </dd>

            <wb-separator class="my-12 span-2" />

            <dl class="is-size-300 has-text-grey-500 mr-24">
              <span
                v-t="'mywb.common.wallbox-fee'"
                class="pr-8"
              />
              <span data-test-id="feePercentage">
                {{ compute.feePercentage }}
              </span>
            </dl>
            <dd class="is-size-300 has-text-grey-500" data-test-id="totalFee">
              {{ compute.feeParsed }}
            </dd>
            <wb-separator class="my-12 span-2" />
            <dl
              v-t="'mywb.session.total-you-recibe'"
              class="is-size-300 is-font-weight-500 mr-24"
            />
            <dd class="is-size-500 is-font-weight-500" data-test-id="totalReceived">
              {{ compute.amountParsed }}
            </dd>
          </dl>
        </template>
      </wb-dropdown>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { time, dates, numbers, useToast } from '@wallbox/toolkit-ui'
import { getSessionEnergy } from '@/utilities/energy'
import { getMidSession, MID_STATUS } from '@/utilities/charger/midInformation'
import { userInitials } from '@/utilities/users'
import { trackAction } from '@wallbox/metrics'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { type Session, RateVariableTypeEnum } from '@/core/session'
import state from '@/state'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const toast = useToast()
const router = useRouter()

const props = defineProps<{
  session: Session
  showChargerColumn?: boolean
}>()

const compute = reactive({
  user: computed(() => {
    return props.session.userId === 1
      ? {
          initials: 'A',
          name: i18n.t('mywb.common.anonymous')
        }
      : {
          uid: props.session.userUid,
          id: props.session.userId,
          initials: userInitials({ name: props.session.userName, surname: props.session.userSurname }),
          name: [props.session.userName, props.session.userSurname].filter(Boolean).join(' '),
          email: props.session.userEmail,
          avatar: props.session.userAvatar
        }
  }),

  isOwnerUser: computed((): boolean => compute.user.email === state.user.userLogged.email),

  startTimeDate: computed(() => {
    return dates.toDate((props.session.startTime), i18n.locale.value)
  }),

  startTimeHour: computed(() => {
    return dates.toHoursAndMinutes((props.session.startTime), i18n.locale.value)
  }),

  endTimeHour: computed(() => {
    return dates.toHoursAndMinutes((props.session.endTime), i18n.locale.value)
  }),

  timeDuration: computed(() => {
    return time.getTimeDurationString(props.session.chargingTime)
  }),

  costParsed: computed(() => {
    return props.session.totalCost
      ? numbers.toLocaleCurrencySymbol(
        props.session.totalCost,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  amountParsed: computed(():string => {
    return props.session.total
      ? numbers.toLocaleCurrencySymbol(
        props.session.total - compute.variableFee,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  taxAmountParsed: computed(() => {
    return props.session.taxAmount
      ? numbers.toLocaleCurrencySymbol(
        props.session.taxAmount,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  subtotalParsed: computed(() => {
    return props.session.subtotal
      ? numbers.toLocaleCurrencySymbol(
        props.session.subtotal,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  totalParsed: computed(() => {
    return props.session.total
      ? numbers.toLocaleCurrencySymbol(
        props.session.total,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  variableFee: computed(() => {
    return (props.session.total) * props.session.applicationFeePercentage / 100
  }),

  feeParsed: computed(():string => {
    return props.session.applicationFeePercentage
      ? numbers.toLocaleCurrencySymbol(
        -1 * compute.variableFee,
        props.session.currencyCode,
        i18n.locale.value)
      : '-'
  }),

  feePercentage: computed(() => {
    return props.session.applicationFeePercentage
      ? numbers.toLocalePercent(
        props.session.applicationFeePercentage,
        i18n.locale.value)
      : '-'
  }),

  vatPercentage: computed(() => {
    return props.session.taxPercentage
      ? numbers.toLocalePercent(
        props.session.taxPercentage * 100,
        i18n.locale.value,
        3)
      : '-'
  }),

  sessionId: computed(() => {
    return `#S${props.session.id}`
  }),

  sessionEnergy: computed(() => {
    return getSessionEnergy(props.session, i18n.locale.value)
  }),

  midInformation: computed(() => {
    return getMidSession(props.session)
  }),

  typeParsed: computed(() => {
    const obj = {
      free: {
        text: i18n.t('mywb.common.free'),
        color: 'green'
      },

      pay_per_month: {
        text: i18n.t('mywb.charger.pay-per-month'),
        color: 'blue'
      },

      pay_per_charge: {
        text: i18n.t('mywb.charger.pay-per-charge'),
        color: 'blue'
      }
    } as const

    return obj[props.session.sessionType]
  }),

  rateVariableTypeParsed: computed(() => {
    if (props.session.ratePrice === 0 && props.session.accessPrice > 0) {
      return i18n.t('mywb.common.without-fee-variable')
    } else if (props.session.rateVariableType === RateVariableTypeEnum.TIME) {
      return i18n.t('mywb.common.charging-time.fee')
    } else if (props.session.rateVariableType === RateVariableTypeEnum.ENERGY) {
      return i18n.t('mywb.common.consumed-energy.fee')
    } else if (props.session.rateVariableType === RateVariableTypeEnum.RESERVATION_TIME) {
      return i18n.t('mywb.common.reserved-time-fee')
    } else {
      return ''
    }
  })
})

const methods = {
  async goToChargers () {
    router.push({ name: ROUTES.CHARGER, params: { chargerUid: props.session.chargerUid } })
  },

  async goToUser (id: string) {
    router.push({ name: ROUTES.USER_DETAIL, params: { uid: id } })
  },

  trackPayments () {
    trackAction('view_payment')
  },

  copy () {
    navigator.clipboard.writeText(compute.sessionId)
      .then(() => toast.success(i18n.t('mywb.common.copied')))
      .catch(() => toast.error(i18n.t('mywb.error.unexpected-error')))
  }
}
</script>

<style lang="postcss" scoped>
.data-row-icon {
  font-size: 12px !important;
  color: var(--grey-400);
  margin-right: 4px;
  position: relative;
  top: 3px;
}

.details-links {
  color: var(--primary-500);
  transform: rotateZ(90deg);
}

.dropdown {
  padding: 32px;
  min-width: 300px;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;

  & dd {
    text-align: right;
  }

  &::after {
    bottom: 100%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--white);
    border-width: 15px;
    right: 0;
    filter: drop-shadow(0 -5px 4px rgb(207 207 207 / 70%));
  }
}

.span-2 {
  grid-column: span 2;
}

.avatar {
  flex-shrink: 0;
}

.types {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.types .label {
  display: inline-block;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--black) !important;

  & + .label {
    margin-top: 0.5rem;
  }
}

.mid-icon {
  display: block !important;
  height: 20px;
}

.name {
  max-width: 100px;
}

.is-grid {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 2px;
}
</style>
