<template>
  <wb-card
    :headline="i18n.t('mywb.common.charge-with-contract-subscription')"
    :subhead="i18n.t('mywb.common.charge-with-contract-subscription.description')"
    data-test-id="subscriptionSection"
    v-bind="$attrs"
  >
    <template #content>
      <wb-badge
        v-if="compute.subscriptionStatus.color && compute.subscriptionStatus.date"
        inverted
        :variant="compute.subscriptionStatus.color"
        data-test-id="subscriptionDate"
      >
        {{ compute.subscriptionStatus.date }}
      </wb-badge>
      <wb-alert
        v-if="data.isChecked"
        variant="info"
        icon="info_filled"
        class="mt-8"
      >
        <p v-t="'mywb.common.charge-with-contract-subscription.title'" />
      </wb-alert>
    </template>

    <template #place-upper-right>
      <wb-switch
        v-model="data.isChecked"
        name="subscribe"
        @input="methods.handleSubscription"
      />
    </template>
  </wb-card>

  <shared-confirmation-modal
    v-if="data.isModalSettingsSubscribeConfirmationOpen"
    :title="!data.isChecked
      ? i18n.t('mywb.user.unsubscribe-charge-with-contract', { username: props.user.name })
      : i18n.t('mywb.user.subscribe-charge-with-contract', { username: props.user.name })"
    :label-confirmation-button="!data.isChecked
      ? i18n.t('mywb.common.unsubscribe')
      : i18n.t('mywb.common.subscribe')"
    :type="!data.isChecked ? 'danger' : 'primary'"
    :loading="data.loading"
    @on-confirm="methods.confirmAction(!data.isChecked)"
    @on-cancel="methods.cancelAction"
    @on-close="data.isModalSettingsSubscribeConfirmationOpen = false"
  />

  <shared-confirmation-modal
    v-if="data.isModalSettingsUnpaidBillsOpen"
    :title="i18n.t('mywb.user.has-unpaid-bills', { username: props.user.name })"
    :label-confirmation-button="i18n.t('mywb.common.show-pending-invoices')"
    @on-confirm="methods.showPendingBills"
    @on-cancel="methods.cancelAction"
    @on-close="data.isModalSettingsUnpaidBillsOpen = false"
  />
</template>

<script setup lang=ts>
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import state from '@/state'
import { getServerError } from '@/utilities/errorMessages'
import { trackDataAction, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { getRoleById } from '@/utilities/user-roles'
import { reactive, computed, inject } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { useToast, dates } from '@wallbox/toolkit-ui'
import { HttpError } from '@wallbox/http'
import type { UserDetail } from '@/core/user'
import { type AccessConfigForUser, AccessConfigForUserContractStatus } from '@/core/accessConfig'
import { ACCESS_CONFIG_USE_CASES, USER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const userUseCases = injectStrict(USER_USE_CASES)
const accessConfigUseCases = inject(ACCESS_CONFIG_USE_CASES)
const toast = useToast()
const router = useRouter()
const i18n = useI18n()

interface Props {
  user: UserDetail
  accessConfig: AccessConfigForUser
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-update'): void
}
const emit = defineEmits<Events>()

interface Data {
  isChecked: boolean
  isModalSettingsSubscribeConfirmationOpen: boolean
  isModalSettingsUnpaidBillsOpen: boolean
  loading: boolean
}
const data = reactive<Data>({
  isChecked: false,
  isModalSettingsSubscribeConfirmationOpen: false,
  isModalSettingsUnpaidBillsOpen: false,
  loading: false
})

const compute = reactive({
  hasUnpaidBills: computed((): boolean =>
    props.accessConfig?.contract?.status === AccessConfigForUserContractStatus.UNPAID),

  subscriptionStatus: computed(() => {
    let subscriptionStatus: { color: 'yellow' | 'green', date: string} = { color: 'yellow', date: '' }

    if (props.accessConfig?.contract) {
      const { contract } = props.accessConfig

      subscriptionStatus = {
        color: 'yellow',
        date: i18n.t('mywb.common.pending')
      }

      if (contract.signed && contract.status === AccessConfigForUserContractStatus.ACTIVE) {
        const subscriptionDateTranslate = i18n.t('mywb.common.active')
        const dateFormatSubscription = dates.toLongRedableDate(contract.createdAt, i18n.locale.value)

        subscriptionStatus = {
          color: 'green',
          date: `${subscriptionDateTranslate}: ${dateFormatSubscription}`
        }
      }
    }

    return subscriptionStatus
  })
})

const methods = {
  cancelAction () {
    data.isChecked = !data.isChecked
  },

  showPendingBills () {
    state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'status', value: 'pending' })
    router.push({ name: ROUTES.PAYMENTS_INVOICES })
  },

  handleSubscription () {
    if (!compute.hasUnpaidBills) {
      data.isModalSettingsSubscribeConfirmationOpen = true
    } else {
      data.isModalSettingsUnpaidBillsOpen = true
    }
  },

  async confirmAction (checked: boolean) {
    data.loading = true
    checked ? await methods.unsubscribeUser() : await methods.subscribeUser()
    data.loading = false
    trackDataEvent('user-subscription-invited')
  },

  async subscribeUser () {
    if (!props.accessConfig?.id) return

    try {
      await accessConfigUseCases?.subscribeUserToPayPerMonth({
        accessConfigId: props.accessConfig.id,
        userId: props.user.id
      })

      emit('on-update')

      trackDataAction('user-subscription', {
        pay_per_month: data.isChecked,
        user_type: getRoleById(props.accessConfig.profile).name
      })

      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  },

  async unsubscribeUser () {
    try {
      if (props.accessConfig?.contract) {
        await userUseCases.unsubscribeUserFromPayPerMonth({
          contractId: props.accessConfig.contract.id
        })
      }
      emit('on-update')
      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  }
}

async function created () {
  data.isChecked = !!props.accessConfig.contract
}

created()
</script>
