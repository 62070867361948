import { endpoints } from '@/core/shared/endpoints.config'
import type { ChargePointRepository } from '../../domain'
import type
{
  GetAllBranding,
  GetAllChargePoint,
  GetChargePointAttributes,
  GetChargePointIncludedConnector,
  GetChargePointIncludedLocation,
  GetChargePointDataRelationShipConnector
} from './types'
import ENV from '@/engine/env/web.env'

import {
  ChargePointStatus,
  type ChargePointConnector,
  ChargePointConnectorStatus
} from '@/core/chargePoint'
import { ulid } from 'ulid'
import type { RepositoryHttpBuild } from '@/core/shared'
import { provideAuthUseCases } from '@/core/auth'

export const perseusChargePointRepositoryBuilder: RepositoryHttpBuild<ChargePointRepository> = ({ httpService }) => {
  const instance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAllBranding () {
      const info = await instance.get<GetAllBranding>(endpoints.v4.charge_points_commissioning_wizard_info)

      if (!info) return []

      return info.data.attributes.brands.map(brand => {
        return {
          active: brand.active,
          name: brand.name,
          slug: brand.slug,
          image: brand.slug === 'wallbox' ? 'http://cdn0.wall-box.com/logo_wallbox.png' : undefined,
          models: brand.models.map(model => {
            return {
              active: model.active,
              brand: model.brand,
              connectors: model.connectors,
              name: model.model_name,
              slug: model.model_slug,
              provideOcppId: model.provide_ocpp_id,
              maxKw: model.max_kw
            }
          })
        }
      })
    },

    async createChargePoint (chargePoint) {
      const id = ulid()

      await instance.post(endpoints.v4.charge_points, {
        data: {
          id,
          type: 'charge_point',
          attributes: {
            name: chargePoint.name,
            vendor: chargePoint.vendor,
            model: chargePoint.model,
            ocpp_identifier: chargePoint.ocppIdentifier
          }
        }
      })

      await instance.post(
        endpoints.v4.locations_locationId_charge_point.replace('{locationId}', chargePoint.locationId),
        {
          data: [
            { type: 'charge_point', id }
          ]
        }
      )

      await Promise.all(chargePoint.connectors.map(async (connector, index) => {
        await instance.post(
          endpoints.v4.charge_points_chargePointId_connectors.replace('{chargePointId}', id),
          {
            data: {
              type: 'connector',
              attributes: {
                number: index,
                name: connector.name,
                connector_type: connector.type,
                format: connector.format,
                voltage: connector.voltage,
                amperage: connector.amps,
                max_capacity: connector.maxPower,
                power_type: connector.powerType
              }
            }
          }
        )
      }))
    },

    async getAllChargePoint (groupUid, params) {
      const info = await instance.get<GetAllChargePoint>(
        endpoints.v4.organizations_groupUid_charge_points.replace('{groupUid}', groupUid),
        {
          params: {
            include: 'location,connector',
            limit: params.limit,
            offset: params.offset
          }
        }
      )

      if (!info) {
        return {
          data: [],
          total: 0
        }
      }

      function getIncluded (included: GetAllChargePoint['included'], id: string,
        type: 'location' | 'connector') {
        return included
          .find(
            include => include.type === type &&
            include.id === id
          )
      }

      function mapChargePointStatus (status: GetChargePointAttributes['status']) {
        if (status === 'Available') return ChargePointStatus.AVAILABLE
        if (status === 'Faulted') return ChargePointStatus.FAULTED
        return ChargePointStatus.UNAVAILABLE
      }

      function getIncludedConnectors (included: GetAllChargePoint['included'],
        relatedConnectors: GetChargePointDataRelationShipConnector): ChargePointConnector[] {
        return relatedConnectors.data.map(connector => {
          const includedConnector = getIncluded(
            included,
            connector.id,
            'connector'
          ) as GetChargePointIncludedConnector

          return {
            type: includedConnector.attributes.connector_type,
            amperage: includedConnector.attributes.amperage,
            power: includedConnector.attributes.max_capacity,
            status: ChargePointConnectorStatus.UNAVAILABLE
          }
        })
      }

      return {
        data: info.data.map(chargePoint => {
          const includedLocation = getIncluded(
            info.included,
            chargePoint.relationships.location.data.id,
            'location'
          ) as GetChargePointIncludedLocation

          return {
            id: chargePoint.id,
            name: chargePoint.attributes.name,
            status: mapChargePointStatus(chargePoint.attributes.status),
            locationId: chargePoint.relationships.location.data.id,
            locationName: includedLocation.attributes.name,
            connectors: chargePoint.relationships.connector?.data.length
              ? getIncludedConnectors(info.included, chargePoint.relationships.connector)
              : []
          }
        }),
        total: info.meta.count
      }
    }
  }
}
