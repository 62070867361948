import i18n from '@/engine/lang'

const { global: { t } } = i18n

export enum LocationTypesEnum {
  ON_STREET = 'on_street',
  PARKING = 'parking',
  AIRPORT = 'airport',
  CAMPING = 'camping',
  HOTEL = 'hotel',
  PRIVATE_COMPANY = 'private_company',
  RESTAURANT = 'restaurant',
  SHOP = 'shop',
  WORKSHOP = 'workshop',
  FUEL_STATION = 'fuel_station',
  CAR_DEALER = 'car_dealer',
  MALL = 'mall',
  TAXI = 'taxi',
  UNKNOWN = 'unknown'
}

const locationTypes = {
  [LocationTypesEnum.ON_STREET]: t('mywb.location.publish-type-street'),
  [LocationTypesEnum.PARKING]: t('mywb.location.publish-type-parking'),
  [LocationTypesEnum.AIRPORT]: t('mywb.location.publish-type-airport'),
  [LocationTypesEnum.CAMPING]: t('mywb.location.publish-type-camping'),
  [LocationTypesEnum.HOTEL]: t('mywb.location.publish-type-hotel'),
  [LocationTypesEnum.PRIVATE_COMPANY]: t('mywb.location.publish-type-company'),
  [LocationTypesEnum.RESTAURANT]: t('mywb.location.publish-type-restaurant'),
  [LocationTypesEnum.SHOP]: t('mywb.location.publish-type-shop'),
  [LocationTypesEnum.WORKSHOP]: t('mywb.location.publish-type-workshop'),
  [LocationTypesEnum.FUEL_STATION]: t('mywb.location.publish-type-fuel-station'),
  [LocationTypesEnum.CAR_DEALER]: t('mywb.location.publish-type-car-dealer'),
  [LocationTypesEnum.MALL]: t('mywb.location.publish-type-mall'),
  [LocationTypesEnum.TAXI]: t('mywb.location.publish-type-taxi'),
  [LocationTypesEnum.UNKNOWN]: t('mywb.common.unknown')
}

export const locationTypesObject = locationTypes

export interface LocationTypeArray {
  key: LocationTypesEnum
  value: string
}

const entries = Object.entries(locationTypes) as Array<[LocationTypesEnum, string]>

export const locationTypesArray =
  entries.reduce<LocationTypeArray[]>((arr, [key, value]) => {
    arr.push({ key, value })
    return arr
  }, [])
