<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-payment-subscription.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-payment-subscription.description') }}
    </div>

    <wb-radio-card
      v-model="compute.hasSubscription"
      :value="0"
      :image="freeChargeImage"
      class="mb-16"
      data-test-id="free-charge-radio-card"
    >
      <p
        class="is-size-400 has-text-grey-900 is-font-weight-500"
        data-test-id="paymentSubscriptionFreeCharge"
      >
        {{ i18n.t('mywb.common.free-charging') }}
      </p>
      <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
        {{ i18n.t('mywb.common.free-charging.description') }}
      </p>
    </wb-radio-card>

    <wb-radio-card
      v-model="compute.hasSubscription"
      :value="1"
      :image="payPerMonthImage"
      :disabled="!isPayPerMonthEnabled"
      data-test-id="ppm-radio-card"
    >
      <p
        class="is-size-400 has-text-grey-900 is-font-weight-500"
        data-test-id="paymentSubscriptionPayPerMonth"
      >
        {{ i18n.t('mywb.common.charge-with-contract-subscription') }}
      </p>
      <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
        {{
          isPayPerMonthEnabled
            ? i18n.t('mywb.common.charge-with-contract-subscription.description')
            : i18n.t('mywb.common.charge-with-contract-subscription-unavailable.description')
        }}
        <br>
        <span v-if="isPayPerMonthEnabled">{{ i18n.t('mywb.common.charge-with-contract-subscription.title') }}</span>
      </p>
    </wb-radio-card>

    <router-link v-if="!isPayPerMonthEnabled" :to="{ name: ROUTES.PAYMENTS_INVOICES }">
      <wb-button
        icon="dollar"
        variant="white"
        outlined
        class="mt-16"
        :label="i18n.t('mywb.common.enable-payments')"
        data-test-id="enable-payments-btn"
      />
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import freeChargeImage from '@/assets/inline/common/free-charge.svg'
import payPerMonthImage from '@/assets/inline/common/pay-per-month.svg'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

interface Events {
  (e: 'update:subscription', subscription: number): void
}
const emit = defineEmits<Events>()

interface PropsType {
  hasSubscription?: number
  isPayPerMonthEnabled: boolean
}

const props = withDefaults(defineProps<PropsType>(), {
  hasSubscription: 0,
  isPayPerMonthEnabled: true
})

const compute = reactive({
  hasSubscription: computed({
    get () {
      return props.hasSubscription
    },
    set (value) {
      emit('update:subscription', value)
    }
  })
})

const methods = {
  isValidateForm () {
    return true
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
:deep(.radio) {
  padding: 2.4rem !important;
}
</style>
