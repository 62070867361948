<template>
  <transition-group name="aside" tag="aside" appear>
    <dl
      v-for="(step, index) in props.steps"
      :key="index"
      class="step"
      :style="{ '--step-index': data.useDelayAnimation ? index : 0 }"
      @click="methods.onClick(index)"
    >
      <div class="circle">
        <p>{{ index + 1 }}</p>
        <transition name="circle-spin-1">
          <div
            v-if="index === compute.model"
            class="circle-spin-1"
          />
        </transition>

        <transition name="circle-spin-2">
          <div
            v-if="index === compute.model"
            class="circle-spin-2"
          />
        </transition>

        <transition name="circle-scale">
          <div
            v-if="index <= compute.model"
            class="circle-inner"
          >
            {{ index + 1 }}
          </div>
        </transition>
      </div>
      <dt class="title">
        {{ step.title }}
      </dt>
      <dd
        class="description"
      >
        {{ step.description }}
      </dd>

      <div
        v-if="steps.length !== index + 1"
        class="separator"
      >
        <transition name="separator-inner">
          <div v-if="index + 1 <= compute.model" class="separator-inner" />
        </transition>
      </div>
    </dl>
  </transition-group>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

interface Props {
  modelValue: number
  steps: Array<{ title: string, description: string }>
}

const props = defineProps<Props>()

const data = reactive({
  useDelayAnimation: true
})

interface Emits {
  (e: 'update:modelValue', step: number): void
  (e: 'click', step: number): void
}

const emit = defineEmits<Emits>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },

    set (step) {
      emit('update:modelValue', step)
    }
  })
})

const methods = {
  onClick (index: number) {
    emit('click', index)
  }
}

async function created () {
  setTimeout(() => {
    data.useDelayAnimation = false
  }, props.steps.length * 100)
}
created()
</script>

<style lang="postcss" scoped>

.aside {
  counter-reset: list-number;
  justify-self: center;
}

.step {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 0 8px;
  cursor: pointer;
}

.separator {
  margin: 2px 0 2px 23px;
  display: block;
  height: 42px;
  background: transparent;
  grid-column: 1;
  grid-row: 3 / span 1;
  border-left: 1px solid var(--grey-300);
  box-sizing: border-box;
}

.separator-inner {
  height: 42px;
  width: 100%;
  border-left: 2px solid #232931;
  transform: scaleY(1);
  transform-origin: 0 0;
  transition: transform 500ms 200ms;
}

.separator-inner-enter-from {
  &.separator-inner {
    transform: scaleY(0);
  }
}

.separator-inner-leave-from {
  &.separator-inner {
    transform: scaleY(1);
  }
}

.separator-inner-leave-to {
  &.separator-inner {
    transition-delay: 500ms;
    transform: scaleY(0);
  }
}

.circle {
  width: 46px;
  height: 46px;
  border-radius: 999px;
  background: var(--white);
  display: flex;
  place-content: center;
  place-items: center;
  border: 1px solid var(--grey-300);
  grid-column: 1;
  grid-row: 1 / span 2;
  position: relative;
  z-index: 2;
}

.circle-spin-2,
.circle-spin-1 {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
}

.circle-spin-1 {
  border: 2px solid transparent;
}

.circle-spin-2 {
  border: 0 solid transparent;
  opacity: 0;
}

.circle-spin-1-enter-active {
  transition:
    border-top-color 0.15s linear 750ms,
    border-right-color 0.15s linear 760ms
    border-bottom-color 0.15s linear 770ms;
}

.circle-spin-2-enter-active {
  transition:
      opacity 0s linear 750ms,
      transform 0.4s linear 750ms,
      border-left-width 0s linear 785ms,
      transform 0.4s linear 750ms
}

.circle-spin-1-enter-to {
  &.circle-spin-1 {
    border-top-color: #232931;
    border-right-color: #232931;
    border-bottom-color: #232931;
  }
}

.circle-spin-2-enter-to {
  &.circle-spin-2 {
    border-top: 2px solid #232931;
    border-left-width: 2px;
    border-right-width: 2px;
    transform: rotate(270deg);
    opacity: 1;
  }
}

.circle-inner {
  position: absolute;
  display: flex;
  place-content: center;
  place-items: center;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  background: #232931;
  color: var(--white)
}

.description {
  grid-row: 2;
  grid-column: 2;
}

.title {
  color: #232931;
  font-size: 1.6rem;
  font-weight: 500;
  grid-column: 2;
  grid-row: 1;
}

.circle-scale-enter-active,
.circle-scale-leave-active {
  transition: transform 0.5s ease;
}

.circle-scale-enter-active {
  transition-delay: 1000ms;
}

.circle-scale-enter-from,
.circle-scale-leave-to {
  transform: scale(0);
}

.aside-enter-active,
.aside-leave-active {
  transition: all 0.5s ease calc(100ms * var(--step-index));
}

.aside-enter-from,
.aside-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.aside-leave-active {
  position: absolute;
}
</style>
