import clientInit from '@/engine/clients'
import i18n from '@/engine/lang'
import initRouter from '@/engine/router'
import initVendors from '@/engine/vendors'
import featureFlagsInit from '@/engine/featureFlags'
import initPwa from '@/engine/pwa'
import type { App } from 'vue'
import initInjects from './injectors'
import ENV from '@/engine/env/web.env'
import { initClientTrackers } from '@wallbox/metrics'
import { loadStates } from '@/state'

export default async function initApp (app: App) {
  initPwa()
  await featureFlagsInit()
  await clientInit()

  initVendors(app, i18n)
  initClientTrackers({
    datadog: {
      active: ENV.mixpanel.active,
      allowedTracingOrigins: ['https://api-pre.wall-box.com', 'https://api.wall-box.com'],
      clientToken: ENV.datadog.clientToken ?? '',
      env: ENV.datadog.env ?? 'development',
      projectId: ENV.datadog.projectId ?? '',
      service: 'portal',
      version: ENV.datadog.version
    },
    mixpanel: {
      active: ENV.mixpanel.active,
      token: ENV.mixpanel.token ?? ''
    }
  })
  loadStates()
  initInjects(app)

  const router = initRouter()

  return { i18n, router }
}
