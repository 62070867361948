import yup from '@/hooks/useValidator.hook'

const updateAutoReportingSchema = yup.object({
  userId: yup.string().required(),
  autoReporting: yup.boolean().required()
})

export type UpdateAutoReporting = yup.InferType<typeof updateAutoReportingSchema>

export async function ensureUpdateAutoReportingIsValid (payload: any) {
  return await updateAutoReportingSchema.validate(payload, { abortEarly: false })
}
