import type { InternationalRepository } from '@/core/international'
import type { UseCase } from '@/core/shared'

export const getAllStates: UseCase<InternationalRepository, 'getStatesByCountry'> = repository => {
  return async (iso3) => {
    const states = await repository.getStatesByCountry(iso3)

    return states.sort((a, b) => a.name.localeCompare(b.name))
  }
}
