<template>
  <wb-modal
    width="54"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.remote-assistance') }}
    </template>

    <p class="is-size-400 mb-24">
      {{ i18n.t('mywb.common.remote-assistance.description-long') }}
    </p>

    <wb-skeleton-loader type="card" :loading="data.loading || props.loading">
      <provider-form
        v-for="provider in data.provider"
        :key="provider.id"
        :provider="provider"
        :disabled="data.savingLoading"
        @on-update="(value) => methods.updateProviderSelected(provider, value)"
      />
    </wb-skeleton-loader>

    <p class="is-size-300 has-text-grey-500 mt-16">
      <span class="wb-icons">info</span>
      {{ i18n.t('mywb.common.remote-assistance.privacy') }}
    </p>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="closeBtn"
          size="block"
          :disabled="props.loading || data.savingLoading || data.loading"
          :label="i18n.t('mywb.common.close')"
          @click="emit('on-close')"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ProviderForm from '@/components/forms/ProviderForm.vue'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { Partner } from '@/core/charger'
import { trackDataAction, trackDataError } from '@/engine/metrics/trackDataManager'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()

const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  loading: boolean
  savingLoading: boolean
  provider: Partner[]
}

const data = reactive<Data>({
  loading: false,
  savingLoading: false,
  provider: []
})

interface Events {
  (e: 'on-close'): void
  (e: 'on-save'): void
}
const emit = defineEmits<Events>()

const methods = {
  updateProviderSelected (provider: Partner, value: boolean) {
    data.savingLoading = true
    const status = value ? 'accepted' : 'rejected'

    try {
      chargerUseCases.updateChargerRemoteAssistance(provider.id, status)
      trackDataAction('change-charger-serviceability-status', {
        partnerName: provider.partnerName,
        status
      })
      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
      trackDataError('change-charger-serviceability-status', {
        error
      })
    }

    data.savingLoading = false
  },

  async getProvidersList () {
    data.provider = await chargerUseCases.getChargerServiceabilities(state.charger.getChargerCore.uid)
  }
}

async function created () {
  data.loading = true
  await methods.getProvidersList()
  data.loading = false
}

created()
</script>

<style scoped lang="postcss">
.wb-icons {
  font-size: 12px !important;
  margin-right: 4px;
}
</style>
