import {
  wapiInvoicesRepository,
  getAllPlanInvoices,
  getAllPaymentsInvoices,
  getAllFeeInvoices,
  payPlanInvoice,
  payPaymentsInvoice
} from '@/core/invoices'

export function provideInvoicesUseCases () {
  const repo = wapiInvoicesRepository()
  return {
    getAllPaymentsInvoices: getAllPaymentsInvoices(repo),
    getAllPlanInvoices: getAllPlanInvoices(repo),
    getAllFeeInvoices: getAllFeeInvoices(repo),
    payPlanInvoice: payPlanInvoice(repo),
    payPaymentsInvoice: payPaymentsInvoice(repo)
  }
}
