<template>
  <wb-alert
    variant="info"
    icon="info_filled"
    :action="i18n.t('mywb.common.enable')"
    @on-action-click="emit('enable-auto-refresh')"
  >
    <p v-t="'mywb.charger.inactivity-description'" />
  </wb-alert>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Events {
  (e: 'enable-auto-refresh'): boolean
}
const emit = defineEmits<Events>()
</script>
