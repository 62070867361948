import { STATUSES } from '@/utilities/charger/chargerStatuses'
import { numbers } from '@wallbox/toolkit-ui'
import { provideChargersUsesCases, type Charger as ChargerCore } from '@/core/charger'

export enum REMOTE_ACTIONS {
  UPDATE = 5,
  RESTART = 3,
  CHARGE = 1,
  PAUSE = 2
}

export enum LOCK_STATUS {
  LOCK = 1,
  UNLOCK = 0
}

export const chargerRemoteActions = [
  {
    id: REMOTE_ACTIONS.CHARGE
  },
  {
    id: REMOTE_ACTIONS.PAUSE
  },
  {
    id: REMOTE_ACTIONS.RESTART,
    label: 'mywb.charger.status.restarting'
  },
  {
    id: REMOTE_ACTIONS.UPDATE,
    label: 'mywb.charger.status.updating'
  }
]

export const isChargerRemoteAction = (charger: ChargerCore, actionId: REMOTE_ACTIONS) => {
  const remoteAction = charger.remoteAction
  const action = chargerRemoteActions.find(action => action.id === actionId)
  const hasAction = action?.id === remoteAction
  return !!remoteAction && hasAction
}

export const hasActionsSupport = async (charger: ChargerCore) => {
  const chargerType = await provideChargersUsesCases().getChargerTypeMetaData(charger.model)
  const minVersion = chargerType.actions?.minVersion
  return !minVersion
    ? false
    : numbers.toCompareVersion(charger.software.currentVersion, minVersion, numbers.OPERATOR.GTE)
}

export const hasActionsAvailable = async (charger: ChargerCore) => {
  const remoteActionRunning = charger.remoteAction === REMOTE_ACTIONS.UPDATE ||
    charger.remoteAction === REMOTE_ACTIONS.RESTART
  const chargerStatus = charger.status.code
  const forbiddenStatus =
    (chargerStatus === STATUSES.DISCONNECTED) ||
    (chargerStatus === STATUSES.CHARGING) ||
    (chargerStatus === STATUSES.DISCHARGING)

  return !remoteActionRunning && !forbiddenStatus && await hasActionsSupport(charger)
}
