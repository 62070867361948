<template>
  <div
    v-t="compute.statusLabel"
    class="status-label"
  />
</template>

<script setup lang="ts">
import type { ChargePointStatus } from '@/core/chargePoint'
import { getColorByStatus, chargePointStatuses } from '@/utilities/chargePoint/chargePointStatuses'

import { computed, reactive } from 'vue'

interface Props {
  chargePointStatus: ChargePointStatus
}
const props = defineProps<Props>()

const compute = reactive({
  statusLabel: computed(() => chargePointStatuses.find(status => status.code === props.chargePointStatus)?.label),
  colors: computed((): { bg: string, text: string } => getColorByStatus(props.chargePointStatus))
})
</script>

<style lang="postcss" scoped>
.status-label {
  border-radius: 26px;
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 11px;
  background: v-bind(compute.colors.bg);
  color: v-bind(compute.colors.text);
  letter-spacing: 0.08rem;
}
</style>
