import state from '@/state'
import type { Charger as ChargerCore } from '@/core/charger'
import type { Session } from '@/core/session'
import type { CountryIso2 } from '@/core/international'

export enum MID_STATUS {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ERROR = 'error'
}

export enum MID_ERROR_COMMUNICATION {
  STATUS_1 = 187,
  STATUS_2 = 188
}

export const midStatuses = [
  {
    code: MID_STATUS.DISABLED,
    label: 'mywb.common.disabled',
    ids: [0]
  },
  {
    code: MID_STATUS.ENABLED,
    label: 'mywb.common.enabled',
    ids: [4]
  },
  {
    code: MID_STATUS.ERROR,
    label: 'mywb.common.error',
    ids: [1, 2, 3]
  }
]

export const getMidSession = (session: Session) => {
  if (!session.midEnergy) return ''
  else if (session.midEnergy > 0 && session.energy > 0) return MID_STATUS.ERROR
  else if (session.midEnergy > 0 && session.energy === 0) return MID_STATUS.ENABLED
}

export const hasMidErrorCommunication = (statusId: number) => {
  return (statusId === MID_ERROR_COMMUNICATION.STATUS_1) || (statusId === MID_ERROR_COMMUNICATION.STATUS_2)
}

export const canAddEnergyRate = (charger: ChargerCore, iso2?: CountryIso2) => {
  if (!iso2) return false
  return !state.config.countriesWithMidNeeded.includes(iso2) || charger.mid.code === MID_STATUS.ENABLED
}
