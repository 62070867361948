import {
  wapiInternationalRepository,
  getAllCountries,
  getAllCurrencies,
  getAllTimezones,
  getAllStates,
  getTimezoneByCoords
} from '@/core/international'

export function provideInternationalUseCases () {
  const repo = wapiInternationalRepository()

  return {
    getAllCountries: getAllCountries(repo),
    getAllCurrencies: getAllCurrencies(repo),
    getAllTimezones: getAllTimezones(repo),
    getAllStates: getAllStates(repo),
    getTimezoneByCoords: getTimezoneByCoords(repo)
  }
}
