import yup from '@/hooks/useValidator.hook'

const removeUserSchema = yup.object({
  user: yup.number().required(),
  groupUid: yup.string().required(),
  accessConfig: yup.number()
})

export type RemoveUser = yup.InferType<typeof removeUserSchema>

export async function ensureRemoveUserIsValid (payload: any) {
  return await removeUserSchema.validate(payload, { abortEarly: false })
}
