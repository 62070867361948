<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.charge-points') }}
    </template>

    <template #actions>
      <wb-button
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.add-charge-point') : ''"
        data-test-id="addChargerButton"
        icon="add"
        variant="primary"
        class="ml-12"
        @click="data.isModalAddChargePointOpen = true"
      />
    </template>
  </shared-teleport-header>

  <charge-points-table
    :charge-points="data.chargePoints"
    :loading="data.isTableLoading"
    :options="data.options"
    :pagination="data.options.pagination"
    @on-pagination-change="methods.getNextPage"
  />

  <add-charge-point-modal
    v-if="data.isModalAddChargePointOpen"
    @charge-point-created="methods.getChargePoints(0)"
    @close="data.isModalAddChargePointOpen = false"
  />
</template>

<script setup lang="ts">
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import AddChargePointModal from '@/components/modals/AddChargePointModal.vue'
import ChargePointsTable from '@/components/tables/ChargePointsTable.vue'
import type { ChargePoint } from '@/core/chargePoint'
import { CHARGE_POINT_USE_CASES, injectStrict } from '@/engine/injectors'
import type { TableProps } from '@wallbox/toolkit-ui'
import state from '@/state'

import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMq } from 'vue3-mq'

const chargePointUseCases = injectStrict(CHARGE_POINT_USE_CASES)
const i18n = useI18n()
const mq = useMq()

interface Data {
  isTableLoading: boolean
  isModalAddChargePointOpen: boolean
  chargePoints: ChargePoint[],
  options: TableProps['options']
}

const data = reactive<Data>({
  isTableLoading: false,
  isModalAddChargePointOpen: false,
  chargePoints: [],
  options: {
    offset: '0 10px',
    pagination: {
      limit: 50,
      offset: 0,
      total: 0
    },
    columns: [
      {
        key: 'name',
        name: i18n.t('mywb.common.name'),
        align: 'left',
        minWidth: 250
      },
      {
        key: 'location_name',
        name: i18n.t('mywb.common.location'),
        align: 'left',
        minWidth: 150,
        width: 'fit'
      },
      {
        key: 'connectors',
        name: i18n.t('mywb.charge-point.connectors'),
        align: 'left',
        minWidth: 200,
        width: 'fit'
      },
      {
        key: 'status',
        name: i18n.t('mywb.common.status'),
        align: 'left',
        minWidth: 150,
        width: 'fit'
      },
      {
        key: 'actions',
        name: i18n.t('mywb.common.actions'),
        align: 'left',
        minWidth: 150,
        width: 'fit'
      }
    ],
    stickyHead: 'var(--header-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

const methods = {
  async getChargePoints (offset: number) {
    data.isTableLoading = true
    try {
      const result = await chargePointUseCases.getAllChargePoint(state.organizations.getCurrentOrganization.groupUid, {
        limit: data.options.pagination?.limit ?? 50,
        offset
      })

      data.chargePoints = result.data

      if (data.options.pagination) {
        data.options.pagination.total = result.total
      }
    } catch (error) {

    } finally {
      data.isTableLoading = false
    }
  },

  getNextPage (offset: number) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    methods.getChargePoints(offset)
  }
}

function created () {
  methods.getChargePoints(0)
}
created()
</script>

<style scoped lang='postcss'>
.chargepoint-connector-icon {
  margin: 0.5rem;
  color: var(--grey-600);
}
</style>
