<template>
  <wb-select
    v-model="compute.value"
    uid="_rates"
    :disabled="props.loading"
    :label="i18n.t('mywb.common.select-rate')"
    class="rate"
    option-label="name"
    :options="compute.ratesParsed"
    :append-to-body="props.appendToBody"
    :placeholder="props.loading ? i18n.t('mywb.common.loading'): i18n.t('mywb.common.no-rate-selected')"
    :searchable="false"
    :selectable="(option: ReturnType<typeof methods.mapRatePrices>) => option?.selectable?.isSelectable || false"
  >
    <template #option="{ option }">
      <div class="is-flex is-align-center" :data-test-id="option.name">
        <div
          class="rate-color"
          :style="option.styles"
        />
        <div class="option">
          <p data-test-id="optionName" class="is-size-300 has-text-grey-700 is-font-weight-500">
            {{ option.name }}
            <span v-if="!option.selectable?.isSelectable" class="has-text-grey-500">
              ({{ getPaymentsTranslations(option.selectable?.reason) }})
            </span>
          </p>
          <p class="is-size-200 has-text-grey-500">
            <span v-if="option.fixedFee" data-test-id="optionFixedFee"> {{ option.fixedFeePriceParsed }}</span>
            <span v-if="option.fixedFee && option.variableFeePriceParsed">&nbsp;+&nbsp;</span>
            <span
              v-if="option.variableFeePrice"
              data-test-id="optionVariablePrice"
            >
              {{ option.variableFeePriceParsed }} {{ option.variableLabel }}
            </span>
          </p>
        </div>
      </div>
    </template>

    <template #selected-option="{ option }">
      <div
        class="rate-color"
        :style="option.styles"
      />

      <div v-if="loading" v-t="'mywb.common.loading'" class="has-text-grey-400" />

      <div v-else>
        <p class="is-size-300 has-text-grey-700 is-font-weight-500">
          {{ option.name }}
        </p>
        <p class="is-size-200 has-text-grey-500">
          <span v-if="option.fixedFee"> {{ option.fixedFeePriceParsed }}</span>
          <span v-if="option.fixedFee && option.variableFeePrice">&nbsp;+&nbsp;</span>
          <span v-if="option.variableFeePrice">{{ option.variableFeePriceParsed }} {{ option.variableLabel }} </span>
        </p>
      </div>
    </template>

    <template #list-footer>
      <wb-button
        data-test-id="createRateCta"
        :label="i18n.t('mywb.common.create-rate')"
        variant="white"
        size="block"
        content-position="left"
        unrounded
        :to="{
          name: ROUTES.PAYMENTS_CREATE_RATE,
          query: {
            chargers: props.charger
              ? [props.charger.uid]
              : state.charger.getCheckedChargers.map(charger => charger.uid),
            paymentType: props.type
          }
        }"
        @mousedown="methods.createRate"
      />
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { canAddEnergyRate } from '@/utilities/charger/midInformation'
import state from '@/state'
import type { Rate } from '@/core/rate'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import type { Charger as ChargerCore } from '@/core/charger'
import { getPaymentsTranslations } from '@/utilities/translations'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

interface Events {
  (e: 'update:modelValue', rate?: Rate | null): void
}

const emit = defineEmits<Events>()

interface Props {
  modelValue?: Rate
  loading?: boolean
  appendToBody?: boolean
  rates?: Rate[]
  type: 'pay_per_charge' | 'pay_per_month'
  charger?: ChargerCore
}
const props = defineProps<Props>()

const compute = reactive({
  value: computed({
    get () {
      return methods.mapRatePrices(props.modelValue)
    },

    set (value) {
      emit('update:modelValue', value)
    }
  }),

  ratesParsed: computed(() => {
    if (!props.rates) return []

    return [...props.rates
      .map(methods.mapRatePrices)]
      .filter(Boolean as unknown as ExcludesNullish)
      .sort((first, second) => Number(second?.hasCustomColor) - Number(first?.hasCustomColor))
      .sort((first, second) => Number(second?.selectable?.isSelectable) - Number(first?.selectable?.isSelectable))
  })
})

const methods = {
  mapRatePrices (rate?: Rate) {
    if (!rate) return null

    let selectable: { isSelectable: boolean, reason?: string } | undefined

    if (props.type === 'pay_per_month') {
      selectable = {
        access: {
          isSelectable: true
        },
        time: {
          isSelectable: true
        },
        energy: {
          isSelectable: props.charger
            ? canAddEnergyRate(
              props.charger,
              state.organizations.getCurrentOrganization.countryCode
            )
            : true,
          reason: i18n.t('mywb.error.mid-required')
        }
      }[rate.variableFeeType]
    } else {
      selectable = {
        access: {
          isSelectable: true
        },
        time: {
          isSelectable: true
        },
        energy: {
          isSelectable: props.charger
            ? canAddEnergyRate(
              props.charger,
              state.organizations.getCurrentOrganization.countryCode
            )
            : true,
          reason: i18n.t('mywb.error.mid-required')
        }
      }[rate.variableFeeType]
    }

    if (!state.rates.hasCustomColor(rate.id, props.type) && (state.rates.selectedRates[props.type].size) >= 5) {
      selectable = {
        isSelectable: false,
        reason: i18n.t('mywb.rates.rate-limit', [5])
      }
    }

    return {
      ...rate,
      selectable,
      styles: state.rates.getStyleByRate(rate.id, props.type, 'white'),
      hasCustomColor: state.rates.hasCustomColor(rate.id, props.type),
      fixedFeePriceParsed: numbers
        .toLocaleCurrencySymbol(
          rate.fixedFee,
          state.organizations.getCurrentOrganization.currencyCode,
          i18n.locale.value
        ),
      variableFeePriceParsed: numbers
        .toLocaleCurrencySymbol(
          rate.variableFeePrice,
          state.organizations.getCurrentOrganization.currencyCode,
          i18n.locale.value
        ),
      variableLabel: methods.getLabel(rate)
    }
  },

  getLabel (rate: Rate) {
    if (rate.variableFeeType === 'energy') {
      return i18n.t('mywb.common.cost-per-kwh')
    } else if (rate.variableFeeType === 'time') {
      return i18n.t('mywb.common.cost-per-hour')
    }
    return ''
  },

  createRate () {
    trackDataAction('create-rate')
  }
}
</script>

<style lang="postcss" scoped>
.price {
  color: var(--grey-400);
  text-align: left;
}

.rate :deep(.vs__search) {
  line-height: 3;
}

.rate-color {
  background: var(--primary-500);
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 20px;
}
</style>
