import type {
  Organization,
  OrganizationCreate,
  OrganizationEdit,
  OrganizationRepository
} from '@/core/organization'
import state from '@/state'
import { getRoleById } from '@/utilities/user-roles'
import { endpoints } from '@/core/shared/endpoints.config'
import { ulid } from 'ulid'
import type { FiltersQuery, RepositoryHttpBuild } from '@/core/shared'
import type { ApiOrganizationsResponse, GetBillingInfo } from './types'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'
import { trackEvent } from '@wallbox/metrics'

export const wapiOrganizationRepositoryBuilder: RepositoryHttpBuild<OrganizationRepository> = ({ httpService }) => {
  const perseusInstance = httpService.create({
    baseURL: ENV.api.perseusApiBaseUrl,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAll (
      params?: FiltersQuery
    ): Promise<Organization[]> {
      const organizations = await wapiInstance.get<ApiOrganizationsResponse>(endpoints.v4.spaces, {
        params: {
          filters: params?.filters ? JSON.stringify({ filters: params.filters }) : {},
          limit: 999
        }
      })

      trackEvent('get-all-organizations', {
        allOrganizations: organizations
      })

      if (!organizations) {
        return []
      }

      return organizations.data.map(organization => {
        return {
          id: organization.id,
          groupUid: organization.attributes.group_uid,
          name: organization.attributes.name,
          ownerId: organization.attributes.owner_id,
          ownerEmail: organization.attributes.owner_email,
          paymentsAccount: organization.attributes.payments_account,
          countryCode: organization.attributes.country_code,
          createdBy: organization.attributes.created_by,
          organizationType: organization.attributes.organization_type,
          role: getRoleById(organization.attributes.profile_id),
          plan: state.plans.getPlan(organization.attributes.product_id),
          currencyCode: organization.attributes.currency_code
        }
      })
    },

    async create (organization: OrganizationCreate): Promise<void> {
      await perseusInstance.post(endpoints.perseus.organizations, {
        data: {
          type: 'organization',
          id: ulid(),
          attributes: {
            name: organization.name,
            organization_type: organization.organizationType,
            country_code: organization.countryCode
          }
        }
      })
    },

    async edit (organization: OrganizationEdit): Promise<void> {
      await perseusInstance.patch(
        endpoints.perseus.organization.replace('{groupUid}', organization.groupUid.toString()
        ), {
          data: {
            type: 'organization',
            attributes: {
              name: organization.name,
              organization_type: organization.organizationType,
              currency_code: organization.currency
            }
          }
        })
    },

    async getBillingInfo (groupUid) {
      const groupId = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      const result = await wapiInstance.get<GetBillingInfo>(
        endpoints.v3.groups_groupId_billingInfo.replace('{groupId}', groupId.data.attributes.value?.toString())
      )

      if (result?.result) {
        return {
          address: result.result.address,
          city: result.result.city,
          company: result.result.company,
          country: {
            iso2: result.result.country.iso2,
            iso3: result.result.country.code,
            name: result.result.country.name,
            phoneCode: result.result.country.phone_code
          },
          email: result.result.email,
          id: result.result.id,
          isTaxFree: result.result.isTaxFree,
          isTaxFreeForPlans: result.result.isTaxFreeForPlans,
          name: result.result.name,
          state: result.result.state,
          surname: result.result.surname,
          zipCode: result.result.zipCode,
          cif: result.result.cif ?? undefined,
          phone: result.result.phone ?? undefined
        }
      }
    },

    async editBillingInfo (groupUid, billingInfo) {
      const groupId = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      const info: any = (await wapiInstance.get(endpoints.v2.countries, {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      }))

      const countryId = info.countries.find((country: any) => country.iso2 === billingInfo.country)?.id ?? 1

      await wapiInstance.put(endpoints.v3.groups_groupId_billingInfo
        .replace('{groupId}', groupId.data.attributes.value?.toString()), {
        company: billingInfo.company,
        name: billingInfo.name,
        surname: billingInfo.surname,
        address: billingInfo.address,
        city: billingInfo.city,
        zipCode: billingInfo.zipCode,
        country: countryId,
        state: billingInfo.state,
        cif: billingInfo.cif
      })
    }
  }
}
