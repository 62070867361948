<template>
  <div class="wrapper-grid g-16">
    <wb-card class="menu-sticky">
      <template #content>
        <wb-menu
          v-model="data.selected"
          :items="compute.menu"
        />
      </template>
    </wb-card>

    <div>
      <user-settings-personal-info
        v-if="props.user"
        id="user"
        :user="props.user"
        class="mb-16"
        :class="{ 'selected-blink': data.selected === 'user'}"
      />

      <user-settings-group-settings
        v-if="data.accessConfig"
        id="userType"
        :user="props.user"
        :access-config="data.accessConfig"
        class="mb-16"
        :class="{ 'selected-blink': data.selected === 'userType'}"
      />

      <user-settings-subscribe
        v-if="compute.canSubscribe && data.accessConfig"
        id="payPerMonth"
        :user="props.user"
        :access-config="data.accessConfig"
        class="mb-16"
        :class="{ 'selected-blink': data.selected === 'payPerMonth'}"
        @on-update="emit('on-user-update')"
      />

      <user-settings-rfid
        id="rfid"
        :user="props.user"
        class="mb-16"
        :class="{ 'selected-blink': data.selected === 'rfid'}"
      />

      <user-settings-charger-access
        v-if="props.user && data.accessConfig"
        id="chargers"
        :user="props.user"
        :access-config="data.accessConfig"
        :class="{ 'selected-blink': data.selected === 'chargers'}"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import UserSettingsPersonalInfo from '@/components/users/UserSettingsPersonalInfo.vue'
import UserSettingsGroupSettings from '@/components/users/UserSettingsGroupSettings.vue'
import UserSettingsSubscribe from '@/components/users/UserSettingsSubscribe.vue'
import UserSettingsRfid from '@/components/users/UserSettingsRfid.vue'
import UserSettingsChargerAccess from '@/components/users/UserSettingsChargerAccess.vue'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { reactive, computed, watchEffect } from 'vue'
import type { UserDetail } from '@/core/user'
import type { AccessConfigForUser } from '@/core/accessConfig'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'
import { useI18n } from '@/hooks/useI18n.hook'
import type { ExcludesNullish, MenuItem } from '@wallbox/toolkit-ui'

const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)
const i18n = useI18n()

const props = defineProps<{
  user: UserDetail
}>()

interface Emits {
  (e: 'on-user-update'): void
}

const emit = defineEmits<Emits>()

interface Data {
  accessConfig?: AccessConfigForUser,
  selected?: string
}
const data = reactive<Data>({
  selected: undefined
})

const compute = reactive({
  menu: computed((): MenuItem[] => {
    return [
      {
        name: 'user',
        icon: 'account_circle',
        label: i18n.t('mywb.user.personal-data')
      },
      {
        name: 'userType',
        icon: 'people_filled',
        label: i18n.t('mywb.common.users-role.title')
      },
      compute.canSubscribe && {
        name: 'payPerMonth',
        icon: 'card',
        label: i18n.t('mywb.common.charge-with-contract-subscription')
      },
      {
        name: 'rfid',
        icon: 'rfid_card',
        label: i18n.t('mywb.common.rfid')
      },
      {
        name: 'chargers',
        icon: 'ev_station',
        label: i18n.t('mywb.common.users-access.title')
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  }),

  canSubscribe: computed(() => {
    return permissions.showPayments &&
      state.organizations.getCurrentOrganization.paymentsAccount
  })
})

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView?.({
    behavior: 'smooth',
    block: 'center'
  })
})

async function created () {
  data.accessConfig = await accessConfigUseCases.getUserAccessConfigByGroup(
    props.user.id,
    state.organizations.getCurrentOrganization.groupUid
  )
}
created()
</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.menu-sticky {
  display: none;

  @media (--tablet) {
    display: block;
    position: sticky!important;
    top: 105px!important;
    height: fit-content;
  }
}
</style>
