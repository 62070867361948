<template>
  <div>
    <wb-select
      v-model="compute.currency"
      option-label="name"
      uid="_currencies"
      data-test-id="currencySelect"
      :label="i18n.t('mywb.common.currency')"
      :disabled="props.isDisabled"
      :reduce="props.reduce"
      :options="state.global.getCurrencies"
    >
      <template #option="{ option }">
        <div class="is-size-400">
          {{ option.name }}
        </div>
        <div class="is-size-500 has-text-grey-500">
          {{ `${ option.code} - ${ option.symbol}` }}
        </div>
      </template>

      <template #selected-option="{ option }">
        {{ option.name }} ({{ `${ option.code} - ${ option.symbol}` }})
      </template>
    </wb-select>
    <p v-if="props.isDisabled" class="is-size-300 has-text-grey-500 mt-8">
      {{ i18n.t('mywb.payments.currency-disabled') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Currency } from '@/core/international'

const i18n = useI18n()

interface Events {
  (e: 'update:modelValue', value: Currency | undefined | string): void
}
const emit = defineEmits<Events>()

interface Props {
  modelValue?: Currency | string
  reduce?: (value: Currency) => Currency | string
  isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  reduce: (value: Currency) => value,
  countryIso2: undefined
})

const compute = reactive({
  currency: computed({
    get () {
      return props.modelValue
    },

    set (currency) {
      emit('update:modelValue', currency)
    }
  })
})
</script>
