import { number, array, object, string, type InferType } from 'yup'

const accessConfigEditSchema = object({
  id: number().required(),
  name: string().required(),
  chargers: array().of(number().required()).required()
})

export type AccessConfigEdit = InferType<typeof accessConfigEditSchema>

export async function ensureEditAccessConfigIsValid (payload: any) {
  return await accessConfigEditSchema.validate(payload, { abortEarly: false })
}
