import { endpoints } from '@/core/shared/endpoints.config'
import type { ApiFeeInvoices, ApiPaymentsInvoice, ApiPlanInvoices } from './types'
import { INVOICE_STATUS, INVOICE_TYPE, type InvoicesRepository } from '@/core/invoices'
import type { RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

const enumStatusMapper = {
  open: INVOICE_STATUS.OPEN,
  paid: INVOICE_STATUS.PAID,
  void: INVOICE_STATUS.VOID,
  pending: INVOICE_STATUS.PENDING,
  uncollectible: INVOICE_STATUS.UNCOLLECTIBLE
}

const enumTypeMapper = {
  'post-payment': INVOICE_TYPE.PAY_PER_MONTH,
  pay_per_charge: INVOICE_TYPE.PAY_PER_CHARGE,
  plan: INVOICE_TYPE.PLAN
}

export const wapiInvoicesRepositoryBuilder: RepositoryHttpBuild<InvoicesRepository> = ({ httpService }) => {
  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAllPaymentsInvoices (groupUid, params) {
      const result = await wapiInstance.get<ApiPaymentsInvoice>(
        endpoints.v4.organizations_invoices.replace('{groupUid}', groupUid?.toString()), {
          params: {
            filters: JSON.stringify({ filters: params.filters }),
            limit: params.limit,
            offset: params.offset
          }
        }
      )

      if (!result) {
        return {
          data: [],
          total: 0
        }
      }

      return {
        data: result.data.map(invoice => {
          return {
            id: invoice.attributes.id,
            type: enumTypeMapper[invoice.attributes.type],
            currencyCode: invoice.attributes.currency_code,
            attachment: invoice.attributes.invoice_html,
            status: enumStatusMapper[invoice.attributes.status],
            total: invoice.attributes.total,
            issueDate: invoice.attributes.issue_date,
            customerName: invoice.attributes.customer_data?.name ?? ''
          }
        }),
        total: result.meta.count
      }
    },

    async getAllPlanInvoices (groupUid) {
      const groupId = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!groupId) return []

      const info = await wapiInstance.get<ApiPlanInvoices>(
        endpoints.v3.groups_groupId_invoices.replace('{groupId}', groupId.data.attributes.value?.toString())
      )

      if (!info) {
        return []
      }

      return info.result.map(invoice => {
        return {
          total: invoice.amountDue,
          type: INVOICE_TYPE.PLAN,
          currencyCode: invoice.currency.code,
          id: invoice.id,
          attachment: invoice.invoicePdf,
          issueDate: invoice.issueDate,
          planName: invoice.planName,
          status: enumStatusMapper[invoice.status]
        }
      })
    },

    async getAllFeeInvoices (groupUid: string) {
      const result = await wapiInstance.get<ApiFeeInvoices>(
        endpoints.v4.fee_invoices.replace('{organizationUid}', groupUid)
      )

      if (!result) {
        return {
          data: [],
          total: 0
        }
      }

      const attachmentMaker = (invoiceNumber: string) => {
        return wapiInstance.getUri() + endpoints.v4.fee_invoice_details
          .replace('{organizationUid}', groupUid)
          .replace('{invoiceNumber}', invoiceNumber)
      }

      return {
        data: result.data.map((fee, index) => {
          return {
            id: index,
            currencyCode: fee.attributes.currency_code,
            type: INVOICE_TYPE.FEE,
            attachment: attachmentMaker(fee.id),
            status: INVOICE_STATUS.PAID,
            total: fee.attributes.total_fee_amount / 100,
            issueDate: fee.attributes.issue_date
          }
        }),
        total: result.meta.count
      }
    },

    async payPlanInvoice (invoiceId: number) {
      await wapiInstance.post(endpoints.v3.invoices_invoiceId_payments.replace('{invoiceId}', invoiceId.toString()))
    },

    async payPaymentsInvoice (invoiceId: number) {
      await wapiInstance.post(endpoints.v4.payments_invoices_void.replace('{invoiceId}', invoiceId.toString()))
    }
  }
}
