<template>
  <wb-modal
    data-test-id="exportModal"
    width="64"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.sessions.download-cta') }}
    </template>

    <p class="is-size-400 has-text-grey-700 mb-12">
      {{ i18n.t('mywb.common.sessions-from-date-to-date', [compute.formattedDate.start, compute.formattedDate.end]) }}
    </p>

    <wb-alert
      v-if="compute.numFilters"
      variant="info"
      icon="info_filled"
      class="mb-16"
    >
      {{ i18n.t('mywb.common.export-has-n-filters-added', [compute.numFilters]) }}
    </wb-alert>

    <p class="is-size-400 is-font-weight-500 mb-8">
      {{ i18n.t('mywb.common.columns') }}
    </p>

    <div class="columns-actions-grid mb-16">
      <wb-link
        data-test-id="selectAllFields"
        type="text"
        @click="methods.selectColumnsAs(true)"
      >
        {{ i18n.t('mywb.common.select-all') }}
      </wb-link>

      <wb-link
        data-test-id="unselectAllFields"
        type="text"
        @click="methods.selectColumnsAs(false)"
      >
        {{ i18n.t('mywb.common.unselect-all') }}
      </wb-link>
    </div>

    <div class="checkbox-content mb-16">
      <div
        v-for="columnName in data.columnNames"
        :key="columnName.key"
        class="checkbox-item"
      >
        <wb-checkbox v-model="data.column[columnName.key]">
          {{ i18n.t(columnName.label) }}
        </wb-checkbox>
      </div>
    </div>

    <p class="is-size-400 is-font-weight-500 mb-8">
      {{ i18n.t('mywb.common.format') }}
    </p>

    <wb-radio-card
      v-model="data.exportFormat"
      value="csv"
      class="mt-8"
      icon="description"
      data-test-id="csvFormatButton"
    >
      <p
        v-t="{ path: 'mywb.sessions.download-file', args: ['CSV'] }"
        class="is-size-400 has-text-grey-700 is-font-weight-500"
      />
    </wb-radio-card>

    <wb-radio-card
      v-model="data.exportFormat"
      value="xlsx"
      class="mt-8"
      icon="document_xlsx"
      data-test-id="xlsxFormatButton"
    >
      <p
        v-t="{ path: 'mywb.sessions.download-file', args: ['XLSX'] }"
        class="is-size-400 has-text-grey-700 is-font-weight-500"
      />
    </wb-radio-card>

    <div class="is-flex mt-24">
      <wb-switch
        v-model="data.isSystemFormat"
        data-test-id="systemFormatSwitch"
        @update:model-value="methods.updateSystemFormat"
      />
      <p class="is-size-300 ml-8" data-test-id="commaText">
        {{ compute.systemFormat }}
      </p>
    </div>
    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          :label="i18n.t('mywb.common.export')"
          :loading="data.loading"
          data-test-id="downloadCta"
          size="block"
          icon="download"
          @click="methods.handleGenerateReport"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, onMounted, watch } from 'vue'
import { AUTH_USE_CASES, SESSION_USE_CASES, injectStrict } from '@/engine/injectors'

const sessionUsesCases = injectStrict(SESSION_USE_CASES)
const authUseCases = injectStrict(AUTH_USE_CASES)
const i18n = useI18n()

interface Props {
  modelValue: 'eu' | 'us'
}

const props = defineProps<Props>()
type Events = {
  (e: 'close'): void
}

const emit = defineEmits<Events>()

type dataType = {
  exportFormat: 'csv' | 'xlsx'
  loading: boolean
  isSystemFormat: boolean
  column: Record<string, boolean>
  columnNames: Record<string, string>[]
}
const data: dataType = reactive({
  exportFormat: 'csv',
  loading: false,
  systemFormats: [],
  isSystemFormat: false,
  column: {},
  columnNames: [
    { key: 'organization', label: 'mywb.organization.organization-name' },
    { key: 'location', label: 'mywb.common.location-name' },
    { key: 'charger_name', label: 'mywb.charger.charger-name' },
    { key: 'serial_number', label: 'mywb.charger.serial-number' },
    { key: 'session_id', label: 'mywb.common.session-id' },
    { key: 'start_time', label: 'mywb.common.start-time' },
    { key: 'end_time', label: 'mywb.common.end-time' },
    { key: 'charging_time', label: 'mywb.charger.charging-time' },
    { key: 'energy', label: 'mywb.common.energy-kwh' },
    { key: 'mid', label: 'mywb.common.mid-energy-kwh' },
    { key: 'cost', label: 'mywb.common.cost-per-kwh' },
    { key: 'total_cost', label: 'mywb.common.session-cost' },
    { key: 'user_name', label: 'mywb.common.user-name' },
    { key: 'email', label: 'mywb.common.user-email' },
    { key: 'role', label: 'mywb.common.user-role' },
    { key: 'rfid_code', label: 'mywb.common.user-rfid' },
    { key: 'subscriber', label: 'mywb.common.users-payment-subscription.title' },
    { key: 'payment_product', label: 'mywb.common.session-type' },
    { key: 'access_fee', label: 'mywb.common.fixed-fee' },
    { key: 'variable_type', label: 'mywb.rates.variable-fee-type' },
    { key: 'variable_fee', label: 'mywb.rates.variable-fee' },
    { key: 'subtotal_paid', label: 'mywb.common.subtotal-amount' },
    { key: 'tax_rate', label: 'mywb.common.vat-percentage' },
    { key: 'tax_paid', label: 'mywb.common.vat' },
    { key: 'total_paid', label: 'mywb.common.total-amount' },
    { key: 'cost_currency', label: 'mywb.common.currency' }
  ]
})

watch(() => data.column, (value) => {
  localStorage.setItem('wb-export-columns', JSON.stringify(value))
}, { deep: true })

const compute = reactive({
  systemFormat: computed(() => {
    return data.isSystemFormat
      ? i18n.t('mywb.common.format-csv-report-comma')
      : i18n.t('mywb.common.format-csv-report-dot')
  }),

  formattedDate: computed(() => {
    const dates = state.filters.sessionsFilters.dates
    const locale = i18n.locale.value
    const start = new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(dates[0])
    const end = new Intl.DateTimeFormat(locale, { dateStyle: 'short' }).format(dates[1])
    return { start, end }
  }),

  numFilters: computed(() => {
    return Object.keys(state.filters.sessionsFilters).length - 1
  })
})

async function created () {
  data.isSystemFormat = props.modelValue === 'eu'
}

const methods = {
  selectColumnsAs (selected: boolean) {
    for (const columnName of data.columnNames) {
      data.column[columnName.key] = selected
    }
  },

  getFields () {
    return Object.entries(data.column)
      .filter(([, value]) => value === true)
      .map(([key]) => key)
  },

  async handleGenerateReport () {
    trackDataAction('generate-report')
    data.loading = true

    const params = {
      filters: state.filters.backendSessionsFilters,
      fields: methods.getFields(),
      limit: 5000,
      offset: 0,
      cache: 'network' as const
    }

    if (data.exportFormat === 'csv') {
      sessionUsesCases.downloadCSV(
        state.organizations.getCurrentOrganization.groupUid,
        params
      )
    }

    if (data.exportFormat === 'xlsx') {
      sessionUsesCases.downloadXLSX(
        state.organizations.getCurrentOrganization.groupUid,
        params
      )
    }

    data.loading = false
    emit('close')
  },

  updateSystemFormat (value: boolean) {
    authUseCases.updateUserSystemFormat({
      userUid: state.global.getUserId,
      systemFormat: value ? 'eu' : 'us'
    })
  }
}

onMounted(() => {
  const storedColumnsString = localStorage.getItem('wb-export-columns')
  const storedColumnsObj = storedColumnsString ? JSON.parse(storedColumnsString) : {}

  for (const columnName of data.columnNames) {
    data.column[columnName.key] = storedColumnsObj[columnName.key] ?? true
  }
})

created()
</script>

<style lang="postcss" scoped>
.justify-between {
  justify-content: space-between;
}

.checkbox-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  & .checkbox-item {
    font-size: var(--size-300);
    align-items: center;
    cursor: pointer;
    margin-bottom: 3px;
  }
}

.columns-actions-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: max-content max-content;

  & a {
    color: var(--grey-500) !important;
  }
}
</style>
