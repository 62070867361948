<template>
  <wb-card
    class="rate-card"
    :headline="props.title"
    :subhead="props.subtitle"
  >
    <template #place-upper-right>
      <div class="is-flex">
        <wb-badge
          v-if="!props.compatible.isCompatible"
          variant="grey"
          class="label"
        >
          {{ i18n.t('mywb.error.not-compatible') }}
        </wb-badge>
        <wb-badge
          v-if="permissions.showUpSellingToPlanBusiness && props.compatible.isCompatible"
          variant="grey"
          icon="lock"
          class="label"
        >
          {{ i18n.t('mywb.plans.business') }}
        </wb-badge>

        <wb-switch
          v-model="compute.activatedProxy"
          :disabled="compute.isSwitchDisabled"
          data-test-id="switch"
        />
      </div>
    </template>

    <template #content>
      <wb-link
        v-if="permissions.showUpSellingToPlanBusiness && permissions.canChangePlan && props.compatible.isCompatible"
        class="is-size-400 u-block mb-8"
        @click="methods.goToPlans"
      >
        {{ i18n.t('mywb.plan.upgrade') }}
      </wb-link>

      <wb-link
        v-if="!compute.hasOrganizationPaymentsAccount &&
          permissions.canConnectStripe &&
          props.compatible.isCompatible"
        class="is-size-400 u-block mb-8"
        :to="{ name: ROUTES.PAYMENTS_CONFIGURATION }"
      >
        {{ i18n.t('mywb.dashboard.stripe-cta') }}
      </wb-link>
      <wb-alert
        v-if="!props.compatible.isCompatible && props.compatible.reason"
        icon="info_filled"
        variant="info"
        class="mb-8 mt-8"
      >
        {{ getPaymentsTranslations(props.compatible.reason) }}
      </wb-alert>

      <template v-if="compute.activatedProxy && props.chooseRate || data.isAddRateModalOpen">
        <charger-rates-select
          v-if="!props.allowHourlySelection"
          v-model="compute.selectedRate"
          data-test-id="selectInput"
          append-to-body
          class="mt-8"
          :rates="props.rates"
          :error="props.error"
          :type="props.type"
          :disabled="props.loading"
          :charger="props.charger"
        />

        <charger-rate-schedule
          v-else
          v-model:schedule="compute.schedule"
          v-model:is-add-rate-modal-open="data.isAddRateModalOpen"
          :charger-schedule-id="props.chargerScheduleId"
          class="mt-24"
          :rates="props.rates"
          :type="props.type"
          :charger="props.charger"
          @close="methods.cancelSchedule"
        />
      </template>

      <wb-alert
        v-if="props.type === 'pay_per_charge' &&
          compute.minimumChargeAmount && compute.activatedProxy && compute.selectedRate"
        variant="info"
        icon="info_filled"
        class="mt-8"
      >
        {{ i18n.t('mywb.currencies.minimum-charge-amount.html', { amount: compute.minimumChargeAmount }) }}
      </wb-alert>

      <slot />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { computed, reactive, toRaw } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { useRouter } from 'vue-router'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { numbers } from '@wallbox/toolkit-ui'
import { getMinimumChargeAmount } from '@/utilities/rates/ratesPriceLimit'
import ChargerRateSchedule from './charger/ChargerRateSchedule.vue'
import ChargerRatesSelect from '@/components/charger/ChargerRatesSelect.vue'
import type { Rate, RateSchedule } from '@/core/rate'
import type { Charger as ChargerCore } from '@/core/charger'
import type { ChargerPaymentsCompatible } from '@/core/charger/domain/ChargerPaymentsCompatible'
import { getPaymentsTranslations } from '@/utilities/translations'
import ROUTES from '@/engine/router/routes'
const i18n = useI18n()
const router = useRouter()

interface Events {
  (e: 'update:activated', activated: boolean): void
  (e: 'update:rate', rate?: Rate): void
  (e: 'update:schedule', schedule?: RateSchedule): void
}
const emit = defineEmits<Events>()

interface PropsType {
  activated: boolean
  title: string
  subtitle: string
  error?: string
  rates?: Rate[]
  rate?: Rate
  chooseRate?: boolean
  type: 'pay_per_charge' | 'pay_per_month'
  loading?: boolean
  charger?: ChargerCore
  schedule?: RateSchedule
  allowHourlySelection?: boolean
  compatible: ChargerPaymentsCompatible['payPerCharge']
  chargerScheduleId?: string
}

const props = defineProps<PropsType>()

const data = reactive({
  isAddRateModalOpen: false,
  isTemporaryDisabled: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.paymentsAccount),

  isSwitchDisabled: computed((): boolean => {
    return props.loading ||
      !props.compatible.isCompatible ||
      !compute.hasOrganizationPaymentsAccount ||
      data.isTemporaryDisabled
  }),

  activatedProxy: computed({
    get () {
      return props.activated
    },

    set (value) {
      data.isTemporaryDisabled = true

      setTimeout(() => {
        data.isTemporaryDisabled = false
      }, 1000)

      const rates = Object.values(props.schedule ?? {}).map(Object.values).flat()
      const notSchedule = rates.length === 0 || !rates?.some(Boolean)

      data.isAddRateModalOpen = (props.chooseRate || false) && value && notSchedule

      emit('update:activated', value)
    }
  }),

  selectedRate: computed({
    get () {
      return props.rate
    },

    set (value) {
      emit('update:rate', value)
    }
  }),

  schedule: computed({
    get () {
      return props.schedule
    },

    set (value) {
      emit('update:schedule', value)
    }
  }),

  minimumChargeAmount: computed(() => {
    const minimumChargeAmount = getMinimumChargeAmount(state.organizations.getCurrentOrganization.currencyCode)

    return numbers.toLocaleCurrencySymbol(
      minimumChargeAmount,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  })
})

const methods = {
  goToPlans () {
    router.push({ name: ROUTES.PLAN_BUSINESS })
    trackDataAction('upgrade_plan', {
      source: 'charger_settings'
    })
  },

  cancelSchedule (schedule: RateSchedule) {
    compute.schedule = toRaw(schedule)
    const notSchedule = !Object.values(props.schedule ?? {}).flat()?.every(Boolean) ?? true

    if (!schedule || notSchedule) {
      compute.activatedProxy = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.is-flex {
  display: flex;
  align-items: center;

  & .label {
    padding: 0 8px;
    margin-right: 8px;
  }
}

.rate-card :deep(p) {
  white-space: initial;
}
</style>
