import {
  type Charger,
  type ChargerRepository,
  type ChargerCreateResponse,
  type ChargerCreate,
  OCPP_CONNECTION_STATUS,
  PAYMENTS_ERROR,
  AUTOLOCK_ERROR,
  GUNLOCK_ERROR
} from '@/core/charger'

import
api,
{
  type ChargerConfig,
  type ChargerInfo,
  type ChargerStatus,
  type ApiChargerCreateResponse,
  type ChargerConfigOld,
  type OcppSettings,
  type ChargerApi,
  type GetChargerServiceabilities
} from './api'
import { userInitials } from '@/utilities/users'
import i18n from '@/engine/lang'
import { endpoints } from '@/core/shared/endpoints.config'
import { chargerStatuses, getIdsByStatuses, STATUSES, type Status } from '@/utilities/charger/chargerStatuses'
import { MID_STATUS, midStatuses } from '@/utilities/charger/midInformation'
import { chargerTypes } from './chargerTypesData'
import { HttpError } from '@wallbox/http'
import { numbers } from '@wallbox/toolkit-ui'
import type { ChargerPaymentsCompatible } from '../../domain/ChargerPaymentsCompatible'
import type { RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

type ChargerResponse = ChargerApi['data'][number]
type Included = ChargerApi['included']

const getMidStatusPerseus = (config: ChargerConfig, chargerStatus: ChargerStatus) => {
  const disabledStatus = midStatuses.find(status => status.code === MID_STATUS.DISABLED)

  if (!disabledStatus) throw new TypeError('disabled status should not be undefined')
  if (!config.attributes.mid_enabled) {
    return {
      ...disabledStatus,
      label: i18n.global.t(disabledStatus.label)
    }
  }

  const midStatus =
    midStatuses.find(status => status.ids.includes(chargerStatus.attributes.mid_status ?? 0)) ?? disabledStatus
  return {
    ...midStatus,
    label: i18n.global.t(midStatus.label)
  }
}

const chargerMapper = (
  charger: ChargerResponse,
  info: ChargerInfo,
  config: ChargerConfig,
  status: ChargerStatus
): Charger => {
  const chargerStatus = charger.attributes.connection_status === 'offline' ? 0 : charger.attributes.status

  return {
    dtoVersion: 1 as const,
    uid: charger.id,
    id: parseInt(charger.attributes.serial_number, 10),
    serialNumber: charger.attributes.serial_number.toString(),
    uniqueIdentifier: charger.attributes.unique_identifier,
    name: charger.attributes.name,
    status: { id: chargerStatus, ...chargerStatuses.find(status => status.ids.includes(chargerStatus)) as Status },
    image: charger.attributes.image,
    model: charger.attributes.model,
    modelName: charger.attributes.model_name,
    partNumber: charger.attributes.part_number,
    organizationId: charger.attributes.organization_id,
    oldGroupId: -1, // dont have it
    locationId: charger.attributes.location_id,
    locationName: charger.attributes.location_name,
    mid: getMidStatusPerseus(config, status),
    lastConnection: charger.attributes.last_connection,
    locked: !!config.attributes.locked,
    connectionType: config.attributes.connectivity_type,
    wifiSignal: status.attributes.connectivity_signal,
    ocppConnectionStatus: status.attributes.ocpp_status,
    software: {
      currentVersion: info.attributes.software_current_version,
      softwareLastUpdatedAt: info.attributes.software_last_updated_at,
      updateAvailable: charger.attributes.software_update_available,
      latestVersion: info.attributes.software_latest_version
    },
    ocppProtocol: config.attributes.ocpp_protocol,
    maxAmps: info.attributes.max_amps,
    minAmps: info.attributes.min_amps,
    remoteAction: config.attributes.remote_action_id,
    operationMode: config.attributes.operation_mode,
    autoLock: config.attributes.auto_lock,
    autoLockTime: config.attributes.auto_lock_time,
    connectorType: charger.attributes.connector_type,
    ecosmartEnabled: config.attributes.eco_smart_enabled,
    homeSharing: config.attributes.power_boost_enabled,
    powerSharingEnabled: config.attributes.dynamic_power_sharing_enabled,
    powerSharingStatus: status.attributes.power_sharing_status,
    userSocketLocking: status.attributes.user_socket_locking,
    maxAvailableAmps: config.attributes.current_amps,
    powerType: info.attributes.power_type,
    timezone: charger.attributes.timezone,
    isPayPerChargeEnabled: charger.attributes.pay_per_charge_enabled,
    isPayPerMonthEnabled: charger.attributes.pay_per_month_enabled,
    softwareAutoUpdateEnabled: config.attributes.software_auto_update_enabled
  }
}

function getIncluded (included: Included, id: string, type: 'charger_info' | 'charger_config' | 'charger_status') {
  return included
    .find(
      include => include.type === type &&
      include.id === id
    )
}

export const perseusChargerRepositoryBuilder: RepositoryHttpBuild<ChargerRepository> = ({ httpService }) => {
  const perseusInstance = httpService.create({
    baseURL: ENV.api.perseusApiBaseUrl,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAllChargers (organizationId, params): Promise<Charger[]> {
      const chargers = await api.getChargers(
        perseusInstance,
        organizationId.toString(),
        0,
        5000,
        'charger_info,charger_config,charger_status',
        params.filters?.[0] && [{
          field: 'location_id',
          operator: 'in',
          value: params.filters?.[0].value as number
        }]
      )

      if (!chargers) {
        return []
      }

      return chargers.data.map((charger) => {
        const info = getIncluded(
          chargers.included,
          charger.relationships.charger_info.data.id,
          'charger_info'
        ) as ChargerInfo

        const config = getIncluded(
          chargers.included,
          charger.relationships.charger_info.data.id,
          'charger_config'
        ) as ChargerConfig

        const status = getIncluded(
          chargers.included,
          charger.relationships.charger_status.data.id,
          'charger_status'
        ) as ChargerStatus

        return chargerMapper(charger, info, config, status)
      })
    },

    async getAllChargersPaginated (organizationId, params) {
      const chargers = await api.getChargers(
        perseusInstance,
        organizationId.toString(),
        params.offset,
        params.limit,
        'charger_info,charger_config,charger_status',
        params.filters,
        params.sort
      )

      if (!chargers) {
        return {
          total: 0,
          data: []
        }
      }

      const chargersMapped = chargers.data.map((charger) => {
        const info = getIncluded(
          chargers.included,
          charger.relationships.charger_info.data.id,
          'charger_info'
        ) as ChargerInfo

        const config = getIncluded(
          chargers.included,
          charger.relationships.charger_info.data.id,
          'charger_config'
        ) as ChargerConfig

        const status = getIncluded(
          chargers.included,
          charger.relationships.charger_status.data.id,
          'charger_status'
        ) as ChargerStatus

        return chargerMapper(charger, info, config, status)
      })

      return {
        total: chargers.meta.count,
        data: chargersMapped
      }
    },

    async getAmountOfChargersWithUpdates (groupUid) {
      const infos = await api.getChargers(
        perseusInstance,
        groupUid,
        0,
        1,
        '',
        [
          {
            field: 'status',
            operator: 'not_in',
            value: getIdsByStatuses([
              STATUSES.DISCHARGING,
              STATUSES.CHARGING,
              STATUSES.UPDATING
            ]).join(',')
          },
          { field: 'connection_status', operator: 'eq', value: 'online' },
          { field: 'software_update_available', operator: 'eq', value: true }
        ]
      )

      if (!infos) return 0

      return infos.meta.count
    },

    async get (chargerUid, groupUid) {
      const chargers = await api.getChargers(
        perseusInstance,
        groupUid.toString(),
        0,
        1,
        'charger_info,charger_config,charger_status',
        [{
          field: 'id',
          operator: 'in',
          value: chargerUid
        }]
      )

      if (!chargers) {
        return undefined
      }

      const charger = chargers.data[0]

      const info = getIncluded(
        chargers.included,
        charger.relationships.charger_info.data.id,
        'charger_info'
      ) as ChargerInfo

      const config = getIncluded(
        chargers.included,
        charger.relationships.charger_config.data.id,
        'charger_config'
      ) as ChargerConfig

      const status = getIncluded(
        chargers.included,
        charger.relationships.charger_status.data.id,
        'charger_status'
      ) as ChargerStatus

      return chargerMapper(charger, info, config, status)
    },

    async getChargersActiveSession (organizationId: string, filters) {
      const activeSessions = await api.getChargerActiveSessions(perseusInstance, organizationId, {
        chargerId: filters?.chargerId
      })

      if (!activeSessions) return []

      return activeSessions.data.map(session => {
        const user = activeSessions.included?.find(user => user.id === session.relationships?.user?.data?.id)

        return {
          user: {
            initials: userInitials({
              name: user?.attributes.name ?? i18n.global.t('mywb.common.anonymous'),
              surname: user?.attributes.surname
            }),
            uid: user?.id,
            name: user?.attributes?.name ?? i18n.global.t('mywb.common.anonymous'),
            surname: user?.attributes?.surname ?? '',
            avatar: user?.attributes?.avatar ?? '',
            hasAccess: session.attributes.has_user_charger_access
          },
          chargerUid: session.id,
          chargingPower: session.attributes.charging_power,
          greenEnergy: session.attributes.green_energy,
          gridEnergy: session.attributes.grid_energy,
          chargingEnergy: session.attributes.charging_energy,
          chargingTime: session.attributes.charging_time
        }
      })
    },

    async createChargers (groupUuid: string, chargers: ChargerCreate[]): Promise<ChargerCreateResponse> {
      const result = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUuid || '-1'))
      const groupId = result.data.attributes.value

      const chargersWithGroupId = chargers
        .map(charger => ({
          ...charger,
          group: groupId
        }))

      const maxChargersPerBatch = 10
      const chargersInBatches = chargersWithGroupId.reduce((result: ChargerCreate[][], item, idx) => {
        const batchIdx = Math.floor(idx / maxChargersPerBatch)

        if (!result[batchIdx]) {
          result[batchIdx] = []
        }

        result[batchIdx].push(item)

        return result
      }, [])

      const allPromises = chargersInBatches.map(async (batch) => {
        return await wapiInstance.post<ApiChargerCreateResponse>(
          endpoints.v1.users_chargers_bulkAdd, batch)
          .then(res => {
            return {
              chargersAdded: res.data.chargersAdded,
              errors: res.data.errors.map(error => {
                return {
                  serial: error.serial,
                  code: error.code,
                  msg: error.msg
                }
              })
            }
          })
      })

      return await Promise.all(allPromises).then((results) => {
        const total: ChargerCreateResponse = {
          chargersAdded: [],
          errors: []
        }

        results.forEach(result => {
          total.chargersAdded = [...total.chargersAdded, ...result.chargersAdded]
          total.errors = [...total.errors, ...result.errors]
        })

        return total
      })
    },

    async updateChargersLocation (chargers, groupUid) {
      const result = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid || '-1'))
      const groupId = result.data.attributes.value

      await Promise.all(chargers.map(async (charger) => {
        const id = await wapiInstance.get<any>(endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', charger))
          .then(result => result.data.attributes.value) as string

        return await wapiInstance
          .put(endpoints.v3.chargers_chargerId
            .replace('{chargerId}', id), {
            group: groupId
          })
      }))
    },

    async getChargerEnergyCost (chargerUid) {
      const id = await wapiInstance.get<any>(endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', chargerUid))
        .then(result => result.data.attributes.value) as number

      const result = await wapiInstance.get<ChargerConfigOld>(endpoints.v1.chargers_config_chargerId
        .replace('{chargerId}', id.toString()))

      return result?.energyCost?.value
    },

    async unlinkCharger (chargerUid) {
      const id = await wapiInstance.get<any>(endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', chargerUid))
        .then(result => result.data.attributes.value) as number

      await wapiInstance.delete(endpoints.v2.charger_chargerId_group.replace('{chargerId}', id.toString()))
    },

    async sendUpdateOrRestartRemoteAction (remoteAction) {
      await wapiInstance.put(endpoints.v3.chargers, {
        chargers: remoteAction.chargers,
        data: {
          remoteAction: remoteAction.action
        }
      })
    },

    async sendStartOrPauseRemoteAction (remoteAction) {
      await wapiInstance.post(
        endpoints.v3.chargers_chargerId_remoteAction.replace('{chargerId}', remoteAction.chargerId.toString()), {
          action: remoteAction.action
        })
    },

    async updateCharger (chargerUpdate) {
      const id = await wapiInstance.get<any>(
        endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', chargerUpdate.uid))
        .then(result => result.data.attributes.value) as number

      if (
        chargerUpdate.locked !== undefined ||
        chargerUpdate.maxAmps !== undefined ||
        chargerUpdate.name) {
        await wapiInstance.put(endpoints.v2.charger_chargerId.replace('{chargerId}', id.toString()), {
          locked: chargerUpdate.locked,
          maxChargingCurrent: chargerUpdate.maxAmps,
          name: chargerUpdate.name
        })
      }

      if (
        chargerUpdate.timezone ||
        chargerUpdate.energyCost || chargerUpdate.energyCost === null ||
        chargerUpdate.userSocketLocking !== undefined ||
        chargerUpdate.autoLock !== undefined ||
        chargerUpdate.autoLockTime !== undefined ||
        chargerUpdate.softwareAutoUpdateEnabled !== undefined
      ) {
        await wapiInstance.post(endpoints.v1.chargers_config_chargerId.replace('{chargerId}', id.toString()), {
          timezone: chargerUpdate.timezone,
          energyCost: chargerUpdate.energyCost,
          user_socket_locking: chargerUpdate.userSocketLocking,
          auto_lock: chargerUpdate.autoLock != null ? +chargerUpdate.autoLock : undefined,
          auto_lock_time: chargerUpdate.autoLockTime,
          software_auto_update_enabled: chargerUpdate.softwareAutoUpdateEnabled != null
            ? +chargerUpdate.softwareAutoUpdateEnabled
            : undefined
        })
      }
    },

    async getOcppSettings (chargerUid) {
      const id = await wapiInstance.get<any>(endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', chargerUid))
        .then(result => result.data.attributes.value) as number

      return await wapiInstance.get<OcppSettings | undefined>(endpoints.v3.chargers_chargerId_ocppConfiguration
        .replace('{chargerId}', id.toString()))
    },

    async updateOcppSettings (ocppSettingsUpdate) {
      const id = await wapiInstance.get<any>(
        endpoints.v4.charger_chargerUid_id.replace('{chargerUid}', ocppSettingsUpdate.uid))
        .then(result => result.data.attributes.value) as number

      await wapiInstance.post(endpoints.v3.chargers_chargerId_ocppConfiguration
        .replace('{chargerId}', id.toString()), {
        address: ocppSettingsUpdate.address,
        chargePointIdentity: ocppSettingsUpdate.chargePointIdentity,
        password: ocppSettingsUpdate.password,
        type: ocppSettingsUpdate.type
      })
    },

    async getIsChargeCompatibleWithPayments (charger) {
      const info = await this.getChargerTypeMetaData(charger.model)

      if (!info.payments) {
        return await Promise.resolve({
          payPerCharge: {
            isCompatible: false,
            reason: PAYMENTS_ERROR.MODEL_NOT_COMPATIBLE
          },

          payPerMonth: {
            isCompatible: false,
            reason: PAYMENTS_ERROR.MODEL_NOT_COMPATIBLE
          }
        })
      }

      if (
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.DISCONNECTED &&
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.NOT_CONFIGURED
      ) {
        return await Promise.resolve({
          payPerCharge: {
            isCompatible: false,
            reason: PAYMENTS_ERROR.OCPP
          },

          payPerMonth: {
            isCompatible: false,
            reason: PAYMENTS_ERROR.OCPP
          }
        })
      }

      const result: ChargerPaymentsCompatible = {
        payPerCharge: {
          isCompatible: false,
          reason: PAYMENTS_ERROR.MODEL_NOT_COMPATIBLE
        },

        payPerMonth: {
          isCompatible: false,
          reason: PAYMENTS_ERROR.MODEL_NOT_COMPATIBLE
        }
      }

      if (!numbers.toCompareVersion(
        charger.software.currentVersion, info.payments.ppc.minVersion, numbers.OPERATOR.GTE)
      ) {
        result.payPerCharge.isCompatible = false
        result.payPerCharge.reason = PAYMENTS_ERROR.NEEDS_UPDATE
      } else {
        result.payPerCharge.isCompatible = true
        result.payPerCharge.reason = undefined
      }

      if (!numbers.toCompareVersion(
        charger.software.currentVersion, info.payments.ppm.minVersion, numbers.OPERATOR.GTE)
      ) {
        result.payPerMonth.isCompatible = false
        result.payPerMonth.reason = PAYMENTS_ERROR.NEEDS_UPDATE
      } else {
        result.payPerMonth.isCompatible = true
        result.payPerMonth.reason = undefined
      }

      return await Promise.resolve(result)
    },

    async getChargerTypeMetaData (chargerModel: string) {
      const info = chargerTypes.find(type => type.chargers.includes(chargerModel))

      if (!info) {
        return await Promise.reject(new HttpError({
          errors: [{
            code: '400',
            status: 400,
            message: `Charger model invalid: ${chargerModel}`
          }]
        }, 400))
      }

      return await Promise.resolve(info)
    },

    async getChargerTypes () {
      return await Promise.resolve(chargerTypes)
    },

    async getIsChargerCompatibleWithAutoLock (charger) {
      const chargerType = await this.getChargerTypeMetaData(charger.model)

      if (
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.DISCONNECTED &&
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.NOT_CONFIGURED
      ) {
        return {
          isCompatible: false,
          reason: AUTOLOCK_ERROR.OCPP
        }
      } else if (
        chargerType?.actions?.minVersion &&
        !numbers.toCompareVersion(
          charger.software.currentVersion, chargerType?.actions?.minVersion, numbers.OPERATOR.GTE)
      ) {
        return {
          isCompatible: false,
          reason: AUTOLOCK_ERROR.MODEL_NOT_COMPATIBLE
        }
      } else {
        return {
          isCompatible: true
        }
      }
    },

    async getIsChargerCompatibleWithGunLock (charger) {
      const chargerType = await this.getChargerTypeMetaData(charger.model)

      if (!chargerType.gunLock) {
        return {
          isCompatible: false,
          reason: GUNLOCK_ERROR.MODEL_NOT_COMPATIBLE
        }
      } else if (
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.DISCONNECTED &&
        charger.ocppConnectionStatus !== OCPP_CONNECTION_STATUS.NOT_CONFIGURED
      ) {
        return {
          isCompatible: false,
          reason: GUNLOCK_ERROR.OCPP
        }
      } else if (
        chargerType?.gunLock?.minVersion &&
        !numbers.toCompareVersion(
          charger.software.currentVersion, chargerType?.gunLock?.minVersion, numbers.OPERATOR.GTE)
      ) {
        return {
          isCompatible: false,
          reason: GUNLOCK_ERROR.NEEDS_UPDATE
        }
      } else {
        return {
          isCompatible: true
        }
      }
    },

    async getChargerServiceabilities (chargerUid) {
      const serviceabilities = await wapiInstance.get<GetChargerServiceabilities>(
        endpoints.v4.charger_chargerUid_charger_serviceabilities.replace('{chargerUid}', chargerUid)
      )

      if (!serviceabilities) return []

      return serviceabilities.data.map(serviceability => {
        return {
          id: serviceability.id,
          partnerId: serviceability.attributes.partner_id,
          partnerName: serviceability.attributes.partner_name,
          status: serviceability.attributes.status
        }
      })
    },

    async updateChargerRemoteAssistance (chargerServiceabilitiesId, status) {
      if (status === 'accepted') {
        await wapiInstance.patch(
          endpoints.v4.charger_chargerServiceabilityUid_accept
            .replace('{chargerServiceabilityUid}', chargerServiceabilitiesId),
          {}
        )
      } else if (status === 'rejected') {
        await wapiInstance.patch(
          endpoints.v4.charger_chargerServiceabilityUid_revoke
            .replace('{chargerServiceabilityUid}', chargerServiceabilitiesId),
          {}
        )
      }
    },

    async acceptServiceability (acceptLink) {
      await wapiInstance.get(acceptLink, {})
    }
  }
}
