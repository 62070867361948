<template>
  <wb-modal
    is-fullscreen
    hide-close-button
    class="charge-point-modal"
  >
    <div class="header">
      <shared-header filled class="width-boundaries">
        <template #title>
          {{ i18n.t('mywb.common.add-charge-point') }}
        </template>

        <template #actions>
          <wb-button
            variant="white"
            :label="i18n.t('mywb.common.close')"
            @click="emit('close')"
          />
        </template>
      </shared-header>
    </div>

    <div class="grid-content width-boundaries">
      <add-charge-point-aside-menu
        v-model="data.currentStep"
        class="aside-menu"
        :steps="compute.steps"
        @click="methods.goToSpecificState"
      />

      <div>
        <wb-button
          v-if="hasStateTransition('back')"
          class="mb-24"
          variant="white"
          outlined
          @click="methods.goBack()"
        >
          <span class="is-size-500 wb-icons mr-8">arrow_back</span>
          <span class="has-text-black">{{ i18n.t('mywb.common.back') }}</span>
        </wb-button>

        <h4
          v-if="compute.currentStep.pageTitle"
          class="is-font-weight-500 is-size-1000"
        >
          {{ compute.currentStep.pageTitle }}
        </h4>
        <p
          v-if="compute.currentStep.pageDescription"
          class="is-size-500 mb-24"
        >
          {{ compute.currentStep.pageDescription }}
        </p>

        <add-charge-point-select-brand
          v-if="isCurrentState('selectBrand')"
          v-model="data.selectedBrand"
          :brands="data.brands"
          @click="methods.goNext()"
        />

        <add-charge-point-select-brand
          v-else-if="isCurrentState('selectModel')"
          v-model="data.selectedModel"
          :brands="data.brands"
          :selected-brand="data.selectedBrand"
          @click="methods.goNext()"
        />

        <add-charge-point-connectors
          v-if="isCurrentState('connectors') && data.selectedModel"
          v-model="chargePoint.connectors"
          :model="data.selectedModel"
          @on-click="methods.goNext()"
        />

        <add-charge-point-location
          v-if="isCurrentState('location')"
          v-model:name="chargePoint.name"
          v-model:location="data.selectedLocation"
          @click="methods.goNext()"
          @add-location="methods.addLocation()"
        />

        <div v-if="isCurrentState('createLocation')">
          <edit-location
            :ref="setRef('editLocation')"
            :location-id="chargePoint.locationId"
            @on-edit="methods.onUpsertLocation"
          />

          <wb-button
            class="mt-24"
            :loading="data.loading"
            @click="methods.createLocation"
          >
            {{ i18n.t('mywb.common.continue') }}
          </wb-button>
        </div>

        <add-charge-point-ocpp-id
          v-if="isCurrentState('provideOcppId') && data.selectedModel"
          v-model="chargePoint.ocppIdentifier"
          :model="data.selectedModel"
          :loading="data.saving"
          @click="methods.linkCharger"
        />
      </div>
    </div>
  </wb-modal>
</template>

<script lang="ts" setup>
import SharedHeader from '@/components/headers/SharedHeader.vue'
import { CHARGE_POINT_USE_CASES, LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import { computed, reactive, watchEffect } from 'vue'
import type { Brand, ChargePointCreate, Model } from '@/core/chargePoint'
import addChargePointAsideMenu from '@/components/addChargePoint/addChargePointAsideMenu.vue'
import addChargePointSelectBrand from '../addChargePoint/addChargePointSelectBrand.vue'
import addChargePointOcppId from '../addChargePoint/addChargePointOcppId.vue'
import addChargePointLocation from '../addChargePoint/addChargePointLocation.vue'
import { useStateMachine } from '@wallbox/hooks'
import { useI18n } from 'vue-i18n'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import EditLocation from '@/components/addChargers/EditLocation.vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import type { Location } from '@/core/location'
import AddChargePointConnectors from '../addChargePoint/addChargePointConnectors.vue'

const chargePointUseCases = injectStrict(CHARGE_POINT_USE_CASES)
const locationUseCases = injectStrict(LOCATION_USE_CASES)

const i18n = useI18n()
interface Emits {
  (e: 'close'): void,
  (e: 'charge-point-created'): void
}

const emit = defineEmits<Emits>()

const { refs, setRef } = useTemplateRef()

interface Data {
  currentStep: number
  brands: Brand[],
  selectedBrand?: Brand
  selectedModel?: Model
  selectedLocation?: Location
  showAddLocation?: boolean,
  loading: boolean
  saving: boolean
}

interface Step {
  title: string
  description: string
  pageTitle?: string
  pageDescription?: string
}

const data = reactive<Data>({
  currentStep: 0,
  brands: [],
  showAddLocation: false,
  loading: false,
  saving: false
})

const chargePoint = reactive<ChargePointCreate>({
  model: '',
  locationId: '',
  ocppIdentifier: '',
  name: '',
  vendor: '',
  connectors: []
})

watchEffect(() => {
  chargePoint.model = data.selectedModel?.name ?? ''
  chargePoint.vendor = data.selectedBrand?.name ?? ''
  chargePoint.locationId = data.selectedLocation?.id ?? ''
})

const { isCurrentState, send, hasStateTransition } = useStateMachine({
  initial: 'selectBrand',
  states: {
    selectBrand: {
      transitions: {
        next: 'selectModel'
      }
    },

    selectModel: {
      transitions: {
        next: 'connectors',
        back: 'selectBrand',
        toBrand: 'selectBrand'
      },
      on: {
        next: () => {
          if (!chargePoint.name) {
            chargePoint.name = data.selectedModel?.name ?? ''
          }
        },
        back: () => {
          data.selectedModel = undefined
        },

        toBrand: () => {
          data.selectedModel = undefined
        }
      }
    },

    connectors: {
      transitions: {
        next: 'location',
        back: 'selectModel',
        toBrand: 'selectBrand',
        toModel: 'selectModel'
      },

      on: {
        back: () => {
          chargePoint.name = ''
          data.selectedLocation = undefined
        },

        toBrand: () => {
          chargePoint.name = ''
          data.selectedModel = undefined
          data.selectedLocation = undefined
        },

        toModel: () => {
          chargePoint.name = ''
          data.selectedLocation = undefined
        }
      }
    },

    location: {
      transitions: {
        next: 'provideOcppId',
        back: 'connectors',
        toBrand: 'selectBrand',
        toModel: 'selectModel',
        toConnectors: 'connectors',
        toCreateLocation: 'createLocation'
      },

      on: {
        back: () => {
          chargePoint.name = ''
          data.selectedLocation = undefined
        },

        toConnectors: () => {
          chargePoint.name = ''
          data.selectedLocation = undefined
        },

        toBrand: () => {
          chargePoint.name = ''
          data.selectedModel = undefined
          data.selectedLocation = undefined
        },

        toModel: () => {
          chargePoint.name = ''
          data.selectedLocation = undefined
        }
      }
    },

    createLocation: {
      transitions: {
        next: 'provideOcppId',
        back: 'location',
        toBrand: 'selectBrand',
        toModel: 'selectModel',
        toLocation: 'location',
        toConnectors: 'connectors'
      },

      on: {
        back: () => {
          data.showAddLocation = false
        },
        toLocation: () => {
          data.showAddLocation = false
        },

        toConnectors: () => {
          data.showAddLocation = false
        },

        toBrand: () => {
          data.showAddLocation = false
          data.selectedModel = undefined
          data.selectedLocation = undefined
        },

        toModel: () => {
          data.showAddLocation = false
          data.selectedModel = undefined
          data.selectedLocation = undefined
        }
      }
    },

    provideOcppId: {
      transitions: {
        back: 'location',
        toBrand: 'selectBrand',
        toModel: 'selectModel',
        toLocation: 'location',
        toCreateLocation: 'createLocation',
        toConnectors: 'connectors'
      },
      on: {
        back: () => {
          data.showAddLocation = false
        },
        toBrand: () => {
          data.selectedLocation = undefined
          data.showAddLocation = false
          data.selectedModel = undefined
        },

        toModel: () => {
          data.selectedLocation = undefined
          data.showAddLocation = false
          data.selectedModel = undefined
        }
      }
    }
  }
})

const compute = reactive({
  currentStep: computed((): Step => {
    return compute.steps[data.currentStep]
  }),

  steps: computed((): Step[] => {
    return [
      {
        title: i18n.t('mywb.charge-point.charger-brand'),
        description: !data.selectedBrand ? i18n.t('mywb.charge-point.select-charger-brand') : data.selectedBrand.name,
        pageTitle: i18n.t('mywb.charge-point.select-brand'),
        pageDescription: i18n.t('mywb.charge-point.select-the-brand-of-charger')
      },
      {
        title: i18n.t('mywb.charge-point.charger-model'),
        description: !data.selectedModel ? i18n.t('mywb.charge-point.select-charger-model') : data.selectedModel.name,
        pageTitle: i18n.t('mywb.charge-point.select-model'),
        pageDescription: i18n.t('mywb.charge-point.select-the-model-of-charger')
      },
      {
        title: i18n.t('mywb.charge-point.connectors'),
        description: i18n.t('mywb.connectors.setup-charge-point-connectors'),
        pageTitle: i18n.t('mywb.charge-point.connectors'),
        pageDescription: i18n.t(
          'mywb.charge-point.selected-charge-point-connectors',
          data.selectedModel?.connectors.length ?? 0
        )
      },
      {
        title: i18n.t('mywb.charge-point.charger-settings'),
        description: !data.selectedLocation
          ? i18n.t('mywb.charge-point.setup-name-location')
          : data.selectedLocation.name,
        pageTitle: i18n.t('mywb.charge-point.charger-settings'),
        pageDescription: i18n.t('mywb.charge-point.where-is-the-charger')
      },
      data.showAddLocation && {
        title: i18n.t('mywb.charge-point.new-location'),
        description: i18n.t('mywb.common.add-new-location')
      },
      {
        title: i18n.t('mywb.charge-point.connection-parameters'),
        description: i18n.t('mywb.charge-point.setup-ocpp'),
        pageTitle: i18n.t('mywb.charge-point.connection-parameters'),
        pageDescription: i18n.t('mywb.charge-point-ocpp-description')
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

const methods = {
  goNext (transition: 'next' | 'toCreateLocation' = 'next') {
    data.currentStep++

    if (document.startViewTransition) {
      document.startViewTransition(async () => {
        send(transition)
      })
    } else {
      send(transition)
    }
  },

  goBack () {
    data.currentStep--

    if (document.startViewTransition) {
      document.startViewTransition(async () => {
        if (isCurrentState('provideOcppId') && data.showAddLocation) {
          send('toCreateLocation')
        } else {
          send('back')
        }
      })
    } else {
      send('back')
    }
  },

  goToSpecificState (index: 0 | 1 | 2) {
    const steps = {
      0: 'toBrand',
      1: 'toModel',
      2: 'toConnectors',
      3: 'toLocation'
    } as const

    try {
      send(steps[index])
      data.currentStep = index
    } catch {}
  },

  async linkCharger () {
    data.saving = true
    await chargePointUseCases.createChargePoint(chargePoint)
    data.saving = false
    emit('close')
    emit('charge-point-created')
  },

  async createLocation () {
    data.loading = true
    await refs.editLocation.validateForm()
    data.loading = false
  },

  async onUpsertLocation (locationId: string) {
    const location = await locationUseCases.getLocation(locationId)
    data.selectedLocation = location
    methods.goNext()
  },

  addLocation () {
    data.showAddLocation = true
    chargePoint.locationId = ''
    methods.goNext('toCreateLocation')
  }
}
async function created () {
  data.brands = await chargePointUseCases.getAllBrandingInfo()
}
created()
</script>

<style lang="postcss" scoped>
.charge-point-modal {
  :deep(.modal-content) {
    padding: 0!important;
  }

  :deep(.modal-container){
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    border: 0!important;
    background: #F8F8F8 !important;
  }
}

.header {
  background: #232931;
  position: sticky;
  top: 0;
  z-index: 10;
}

.width-boundaries {
  justify-self: center;
  margin: 0 auto;
  width: 80%;

  @media (--tablet) {
    max-width: var(--max-width-content);
  }
}

.grid-content {
  display: grid;
  gap: 42px;
  margin-top: 24px;
  margin-bottom: 100px;
  grid-template-columns: 1fr;

  @media (--tablet) {
    margin-top: 100px;
    grid-template-columns: 3fr 4fr 1fr;
  }
}

.aside-menu {
  display: none;

  @media (--tablet) {
    display: grid;
    position: sticky;
    top: 190px;
    height: fit-content;
  }
}
</style>
