interface Region {
  key: 'usa' | 'spain' | 'europeanUnion'
  countries: string[]
}

export const USA: Region = {
  key: 'usa',
  countries: ['US', 'MX', 'CA']
}

export const SPAIN: Region = {
  key: 'spain',
  countries: ['ES']
}

export const EU: Region = {
  key: 'europeanUnion',
  countries: [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK',
    'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL',
    'PT', 'RO', 'SK', 'SI', 'SE'
  ]
}

export const getRegionByCountry = (countryToSearch: string): Region | undefined => {
  return [
    USA,
    SPAIN,
    EU
  ].find(({ countries }) => countries.some(country => country.toLowerCase() === countryToSearch?.toLowerCase()))
}
