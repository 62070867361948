<template>
  <div
    v-if="props.charger.id"
    v-t="props.charger.status.label"
    class="status-label"
  />
</template>

<script setup lang="ts">
import type { Charger as ChargerCore } from '@/core/charger'
import { getColorByStatus } from '@/utilities/charger/chargerStatuses'

import { computed, reactive } from 'vue'

interface Props {
  charger: ChargerCore
}
const props = defineProps<Props>()

const compute = reactive({
  colors: computed((): { bg: string, text: string } => getColorByStatus(props.charger.status?.code))
})
</script>

<style lang="postcss" scoped>
.status-label {
  border-radius: 26px;
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 11px;
  background: v-bind(compute.colors.bg);
  color: v-bind(compute.colors.text);
  letter-spacing: 0.08rem;
}
</style>
