import type { EnumRoles } from '@/utilities/user-roles'
import { number, object, type InferType, mixed } from 'yup'

const changeUserRoleSchema = object({
  accessConfigId: number().required(),
  userId: number().required(),
  role: mixed<EnumRoles>().required()
})

export type ChangeUserRole = InferType<typeof changeUserRoleSchema>

export async function ensureChangeUserRoleIsValid (payload: any) {
  return await changeUserRoleSchema.validate(payload, { abortEarly: false })
}
