import { endpoints } from '@/core/shared/endpoints.config'
import type { InternationalRepository } from '@/core/international'
import type { GetCountriesResponse, GetCurrenciesResponse, GetStatesResponse, GetTimezonesResponse } from './types'
import type { RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

export const wapiInternationalRepositoryBuilder: RepositoryHttpBuild<InternationalRepository> = ({ httpService }) => {
  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const timezoneApi = httpService.create({
    baseURL: ENV.api.timezoneApi,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getCountries () {
      const result = await wapiInstance.get<GetCountriesResponse>(endpoints.v2.countries, {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!result) return []

      return result.countries.map(country => {
        return {
          iso2: country.iso2,
          name: country.name,
          phoneCode: country.phone_code,
          iso3: country.code
        }
      })
    },

    async getCurrencies () {
      const result = await wapiInstance.get<GetCurrenciesResponse>(endpoints.v2.currencies, {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!result) return []

      return result.currencies
    },

    async getStatesByCountry (countryCode) {
      const result = await wapiInstance.get<GetStatesResponse>(
        endpoints.v2.countries_countryCode_states.replace('{countryCode}', countryCode), {
          headers: {
            'workbox-cache-type': 'cache-first'
          }
        })

      if (!result) return []

      return result.states
    },

    async getTimezones () {
      const timezones = await wapiInstance.get<GetTimezonesResponse>(endpoints.v4.chargers_timezones, {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!timezones) return []

      return timezones.data.attributes.timezones
    },

    async getTimezoneByCoords (payload) {
      const timezone = await timezoneApi.get<{ data: string }>('timezone', {
        params: {
          ...payload,
          env: ENV.env
        },
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      if (!timezone) return

      return timezone.data
    }
  }
}
