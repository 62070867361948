import { computed, reactive } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import type { UserLogged } from '@/core/auth'
import type { UserList } from '@/core/user'
import type { AccessConfig } from '@/core/accessConfig'

interface UserState {
  userLogged: UserLogged
  users: UserList[]
  accessConfigs: AccessConfig[]
}

const initialState = (): UserState => ({
  userLogged: {
    id: '0',
    email: '',
    name: '',
    surname: '',
    avatar: '',
    phone: '',
    countryIso2: 'ES'
  },
  users: [],
  accessConfigs: []
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<UserState>) {
    return objects.getProperty(state, path)
  },

  userLogged: computed(() => state.userLogged),

  getAllUsers: computed(() => {
    return state.users
  }),

  getAccessConfig: computed(() => {
    return state.accessConfigs
  })
}

const setters = {
  set (path: Paths<UserState>, value: unknown) {
    objects.setProperty(state, path, value)
  },

  setUsers (users: UserList[]) {
    state.users = users
  },

  setAccessConfigs (accessConfigs: AccessConfig[]) {
    state.accessConfigs = accessConfigs
  },

  setUserLogged (user: UserLogged) {
    state.userLogged = { ...state.userLogged, ...user }
  },

  setInitialState () {
    state = reactive(initialState())
  }
}

export default reactive({
  ...getters,
  ...setters
})
