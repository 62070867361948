<template>
  <shared-filter
    v-model="compute.filteredLocations"
    multiple
    with-search
    label="name"
    icon="locations"
    :name="i18n.t('mywb.common.locations')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    data-test-id="groupsFilter"
    :options="props.locations"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'dashboardFilters', key: 'locations' })"
  />

  <shared-filter
    v-model="compute.filterUser"
    multiple
    with-search
    label="email"
    icon="person"
    :name="i18n.t('mywb.common.user')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.users') })"
    :empty-text="i18n.t('mywb.users.empty')"
    :options="state.user.getAllUsers"
    data-test-id="usersFilter"
    :loading="props.loading"
    @on-error="state.filters.resetFilterValue({ filter: 'dashboardFilters', key: 'users' })"
  >
    <template #option="{ option }">
      <div class="grid g-8">
        <wb-user-avatar
          data-test-id="userPicture"
          size="small"
          :src="option.avatar"
          :initials="userInitials(option)"
        />
        <div>
          <p
            v-if="option.name"
            class="is-font-weight-500"
          >
            {{ option.name }} {{ option.surname }}
          </p>
          <p>{{ option.email }}</p>
        </div>
      </div>
    </template>
  </shared-filter>
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { userInitials } from '@/utilities/users'
import type { Location } from '@/core/location'

const i18n = useI18n()

interface Props {
  loading?: boolean
  locations: Location[]
}

const props = defineProps<Props>()

const compute = reactive({
  filteredLocations: computed({
    get () {
      return state.filters.dashboardFilters?.locations ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'locations', value })
    }
  }),

  filterUser: computed({
    get () {
      return state.filters.dashboardFilters?.users
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'users', value })
    }
  })
})
</script>

<style lang="postcss" scoped>
.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}
</style>
