import yup from '@/hooks/useValidator.hook'

const resendUserInvitationSchema = yup.object({
  groupUid: yup.string().required(),
  lang: yup.string().required(),
  branding: yup.string().required(),
  invitationId: yup.number().required()
})

export type ResendUserInvitation = yup.InferType<typeof resendUserInvitationSchema>

export async function ensureResendUserInvitationIsValid (payload: any) {
  return await resendUserInvitationSchema.validate(payload, { abortEarly: false })
}
