<template>
  <wb-card
    :headline="i18n.t('mywb.common.power-limit')"
    :subhead="state.charger.getChargerCore.maxAvailableAmps + i18n.t('mywb.charger.amps')"
    :loading="props.loading"
    alignment="center"
    data-test-id="wb-card-power-limit"
  >
    <template #modal>
      <charger-power-limit-modal
        v-if="data.editingMaxCurrent"
        data-test-id="editMaxCurrentModal"
        @on-close="data.editingMaxCurrent = false"
      />
    </template>
    <template #actions>
      <wb-button
        icon="edit"
        :label="i18n.t('mywb.common.edit')"
        variant="white"
        outlined
        :disabled="props.loading"
        data-test-id="editMaxCurrentButton"
        @click="data.editingMaxCurrent = true"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerPowerLimitModal from '@/components/modals/ChargerPowerLimitModal.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive } from 'vue'
import state from '@/state'

const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  editingMaxCurrent: boolean
}

const data = reactive<Data>({
  editingMaxCurrent: false
})

</script>
