<template>
  <wb-card
    class="p-24"
    filled
    :headline="i18n.t('mywb.rates.hourly-rates-schedule')"
    :subhead="i18n.t('mywb.rates.hourly-rates-description')"
    data-test-id="hourlyRatesCard"
  >
    <template #place-upper-right>
      <wb-button
        variant="white"
        outlined
        data-test-id="editHourlyRates"
        @click="compute.addRateModalOpen = true"
      >
        {{ i18n.t('mywb.rates.edit-schedule') }}
      </wb-button>
    </template>
    <template #content>
      <charger-schedule
        class="charger-schedule-grid-override"
        :schedule="compute.schedule"
        :rates="data.selectedRates"
        :type="props.type"
      />

      <teleport to="body">
        <charger-add-rate-modal
          v-if="compute.addRateModalOpen && props.rates && props.charger"
          v-model:schedule="compute.schedule"
          v-model:selected-rates="data.selectedRates"
          :charger-schedule-id="props.chargerScheduleId"
          :rates="props.rates"
          :charger="props.charger"
          :type="props.type"
          @close="methods.close"
        />
      </teleport>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import type { Rate, RateSchedule } from '@/core/rate'

import state from '@/state'

import ChargerSchedule from '../charger/ChargerSchedule.vue'
import ChargerAddRateModal from '@/components/modals/ChargerAddRateModal.vue'
import { useI18n } from 'vue-i18n'
import type { Charger as ChargerCore } from '@/core/charger'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

const rateUseCases = injectStrict(RATE_USE_CASES)

const i18n = useI18n()

interface PropsType {
  rates?: Rate[]
  charger?: ChargerCore
  type: 'pay_per_charge' | 'pay_per_month'
  schedule?: RateSchedule
  isAddRateModalOpen: boolean
  chargerScheduleId?: string
}

const props = defineProps<PropsType>()

interface Data {
  selectedRates: Rate[],
  colors: Record<string, string>
  removeRate?: Rate
}

interface Events {
  (e: 'update:schedule', value?: RateSchedule): void,
  (e: 'close', value?: RateSchedule): void,
  (e: 'update:isAddRateModalOpen', isOpen: boolean): void
}
const emit = defineEmits<Events>()

const data: Data = reactive({
  selectedRates: [],
  colors: {},
  addRateModal: false
})

const compute = reactive({
  schedule: computed({
    get () {
      return props.schedule
    },

    set (value) {
      emit('update:schedule', value)
    }
  }),

  addRateModalOpen: computed({
    get () {
      return props.isAddRateModalOpen
    },

    set (value) {
      emit('update:isAddRateModalOpen', value)
    }
  })
})

const methods = {
  close () {
    emit('close', compute.schedule)
    compute.addRateModalOpen = false
  }
}

async function created () {
  if (!props.schedule) return

  const values = Object.values(props.schedule ?? {}).map(Object.values).flat().filter(Boolean)

  const ratesId = new Set<string>(values)
  const promises: Array<() => Promise<Rate | undefined>> = []

  ratesId.forEach((rateId: string) => promises.push(() => rateUseCases.getRate({
    groupUid: state.organizations.getCurrentOrganization.groupUid,
    rateId
  })))

  data.selectedRates = (await Promise.all(promises.map(call => call()))).filter(Boolean as unknown as ExcludesNullish)

  state.rates.addRates(data.selectedRates.map(rate => rate.id), props.type)
}

created()
</script>

<style lang="postcss" scoped>
.charger-schedule-grid-override {
  max-width: 800px;
  margin: 0 auto;
}
</style>
