import { KILOGRAMS_IN_ONE_POUND, LITERS_IN_ONE_GALLON } from './constants'
import type { IntlUnit } from './types'

export function getGallonsFromLiters (liters: number) {
  return liters / LITERS_IN_ONE_GALLON
}

export function getPoundsFromKilograms (kilograms: number) {
  return kilograms / KILOGRAMS_IN_ONE_POUND
}

export function toLocaleUnit (
  {
    locale = 'en-US',
    unit,
    value,
    maximumFractionDigits = 2
  }:
  {
    locale?: string
    unit: IntlUnit
    value: number
    maximumFractionDigits?: number
  }) {
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    maximumFractionDigits
  }).format(value)
}
