<template>
  <wb-select
    v-model="compute.locationId"
    option-label="name"
    uid="_group"
    :options="compute.locationsFiltered"
    :reduce="(location: Location) => props.reduce ? location[props.reduce]: location"
    data-test-id="locationSelect"
    :placeholder="i18n.t('mywb.common.select-location')"
    :loading="props.loading"
    :disabled="props.loading || props.disabled"
    :error="props.error"
    placeholder-icon="location"
    @change="$emit('on-change')"
  >
    <template #selected-option="{ option }">
      <span
        class="wb-icons mr-12"
      > location</span>
      {{ i18n.t(option.name) }}
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Location } from '@/core/location'

const i18n = useI18n()

interface Props {
  modelValue?: string | Location
  reduce?: 'id' | 'groupUid'
  loading?: boolean
  disabled?: boolean
  error?: string
  filterLocation?: string
  locations: Location[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', value?: string | Location): void,
  (e: 'on-change'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  locationsFiltered: computed((): Location[] => {
    return props.locations.filter(location => location.id !== props.filterLocation)
  }),

  locationId: computed({
    get () {
      return !props.loading ? props.modelValue : i18n.t('mywb.common.loading')
    },
    set (locationId) {
      emit('update:modelValue', locationId)
    }
  })
})
</script>

<style lang="postcss" scoped>
:deep(.wbSelect .v-select .vs__search) {
  padding-left: 34px;
}
</style>
