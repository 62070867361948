import type { LocationRepository } from '@/core/location'
import type { UseCase } from '@/core/shared'

export const getLocation: UseCase<LocationRepository, 'getLocation'> = repository => {
  return async (locationId) => {
    const location = await repository.getLocation(locationId)

    return location
  }
}
