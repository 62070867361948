<template>
  <div class="is-flex">
    <wb-dropdown v-if="!compute.lockUnlockButton.hidden" class="mr-8">
      <template #activator>
        <wb-button
          rounded
          variant="white"
          outlined
          data-test-id="lockUnlockButton"
          :loading="data.lockUnlockButtonLoading"
          :disabled="compute.lockUnlockButton.disabled"
          :label="compute.chargerUnlocked ? i18n.t('mywb.common.lock') : i18n.t('mywb.common.unlock')"
          @click="methods.lockUnlockCharger"
        />
      </template>
    </wb-dropdown>

    <wb-dropdown v-if="!compute.startStopButton.hidden">
      <template #activator>
        <wb-button
          rounded
          outlined
          variant="white"
          data-test-id="pausePlayChargeButton"
          :loading="data.startStopButtonLoading"
          :disabled="compute.startStopButton.disabled"
          :label="compute.isChargingOrDischarging ? i18n.t('mywb.common.stop') : i18n.t('mywb.common.start')"
          @click="methods.setRemoteAction"
        />
      </template>
    </wb-dropdown>

    <shared-confirmation-modal
      v-if="data.isModalMidErrorCommunication"
      :title="i18n.t('mywb.common.warning')"
      :subtitle="i18n.t('mywb.charger.non-mid-session')"
      :label-confirmation-button="i18n.t('mywb.common.initiate')"
      @on-confirm="methods.addChargerRemoteAction(1)"
      @on-close="data.isModalMidErrorCommunication = false"
    />
  </div>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import state from '@/state'
import { computed, reactive, watch } from 'vue'
import { STATUSES } from '@/utilities/charger/chargerStatuses'
import { REMOTE_ACTIONS } from '@/core/charger'
import { isOcpp } from '@/utilities/charger/chargerOcppSettings'
import { useI18n } from '@/hooks/useI18n.hook'
import { LOCK_STATUS } from '@/utilities/charger/chargerActions'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { hasMidErrorCommunication } from '@/utilities/charger/midInformation'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()

const data = reactive({
  lockUnlockButtonLoading: false,
  startStopButtonLoading: false,
  isModalMidErrorCommunication: false
})

const compute = reactive({
  chargerStatus: computed(() => {
    return state.charger.getChargerCore.status
  }),

  isChargerAbleToResumeCharge: computed((): boolean => {
    if (!state.charger.getChargerCore.status?.code) return false
    return [
      STATUSES.SCHEDULED,
      STATUSES.CHARGING,
      STATUSES.DISCHARGING,
      STATUSES.PAUSED
    ].includes(state.charger.getChargerCore.status.code)
  }),

  isChargerAbleToLock: computed((): boolean => {
    if (!state.charger.getChargerCore.status?.code) return false
    return [
      STATUSES.READY,
      STATUSES.LOCKED
    ].includes(state.charger.getChargerCore.status.code)
  }),

  isDisconnected: computed((): boolean => compute.chargerStatus.code === STATUSES.DISCONNECTED),

  isChargingOrDischarging: computed((): boolean => {
    return compute.chargerStatus.code === STATUSES.CHARGING || compute.chargerStatus.code === STATUSES.DISCHARGING
  }),

  chargerUnlocked: computed((): boolean => !state.charger.getChargerCore.locked),

  hasRemoteActionRunning: computed((): boolean =>
    state.charger.getChargerCore.remoteAction === REMOTE_ACTIONS.UPDATE ||
    state.charger.getChargerCore.remoteAction === REMOTE_ACTIONS.RESTART
  ),

  isOcpp: computed((): boolean => isOcpp(state.charger.getChargerCore)),

  lockUnlockButton: computed((): { disabled: boolean, hidden: boolean } => {
    const disabled = compute.hasRemoteActionRunning || data.lockUnlockButtonLoading

    const hidden = !compute.isChargerAbleToLock || compute.isDisconnected ||
      compute.isOcpp || !state.charger.getChargerCore.autoLock

    return {
      hidden,
      disabled
    }
  }),

  startStopButton: computed((): { disabled: boolean, hidden: boolean } => {
    const disabled = data.startStopButtonLoading || compute.hasRemoteActionRunning

    const hidden = !compute.isChargerAbleToResumeCharge || compute.isDisconnected ||
      compute.isOcpp ||
      compute.chargerStatus.code === STATUSES.LOCKED

    return {
      hidden,
      disabled
    }
  })
})

const methods = {
  async lockUnlockCharger () {
    data.lockUnlockButtonLoading = true
    const lockValue = compute.chargerUnlocked ? 'lock' : 'unlock'

    await chargerUseCases.updateCharger({
      uid: state.charger.getChargerCore.uid,
      locked: (lockValue === 'lock') ? LOCK_STATUS.LOCK : LOCK_STATUS.UNLOCK
    })

    const charger = await chargerUseCases.getCharger(
      state.charger.getChargerCore.uid,
      state.organizations.getCurrentOrganization.groupUid,
      {
        cache: 'network'
      }
    )
    charger && state.charger.setCharger(charger)

    trackDataAction(`${lockValue}-charger`)
  },

  async setRemoteAction () {
    data.startStopButtonLoading = true
    const type = compute.isChargingOrDischarging ? STATUSES.PAUSED : STATUSES.CHARGING

    const action = type === STATUSES.PAUSED ? REMOTE_ACTIONS.PAUSE : REMOTE_ACTIONS.CHARGE

    const idStatus = state.charger.getChargerCore.status.ids[0] ?? 0

    if (hasMidErrorCommunication(idStatus) && action === 1) {
      data.isModalMidErrorCommunication = true
      return
    }

    methods.addChargerRemoteAction(action)
    trackDataAction(`${type}-charger`)
  },

  async addChargerRemoteAction (action: REMOTE_ACTIONS.CHARGE | REMOTE_ACTIONS.PAUSE) {
    await chargerUseCases.sendStartOrPauseRemoteAction({
      chargerId: state.charger.getChargerCore.id,
      action
    })

    const charger = await chargerUseCases.getCharger(
      state.charger.getChargerCore.uid,
      state.organizations.getCurrentOrganization.groupUid,
      {
        cache: 'network'
      }
    )
    charger && state.charger.setCharger(charger)
    state.charger.set('charger.remoteAction', action)
  }
}

watch(() => state.charger.getChargerCore.status, (value, oldValue) => {
  if (value !== oldValue) {
    data.lockUnlockButtonLoading = false
    data.startStopButtonLoading = false
  }
})
</script>

<style lang="postcss" scoped>
.remote-action-icon {
  position: absolute;
  top: -3px;
  left: -3px;
  font-size: 14px !important;
}
</style>
