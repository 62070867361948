<template>
  <wb-modal
    :ref="setRef('modal')"
    width="60"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t(!props.locationId ? 'mywb.charger.add-location' : 'mywb.common.edit-location') }}
    </template>

    <edit-location-form
      :ref="setRef('editLocation')"
      :location-id="props.locationId"
    />

    <template #actions>
      <wb-button
        data-test-id="buttonSubmit"
        size="block"
        :label="i18n.t(!props.locationId ? 'mywb.charger.add-location' : 'mywb.common.save')"
        :loading="data.loading"
        :disabled="refs.editLocation?.isLoading()"
        @click="methods.handleSubmitButton"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import EditLocationForm from '@/components/forms/EditLocationForm.vue'

import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast, useTemplateRef } from '@wallbox/toolkit-ui'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()
const toast = useToast()

interface PropsType {
  locationId?: string
}

const props = defineProps<PropsType>()

type Events = {
  (e: 'on-close'): void,
  (e: 'on-edit-finish'): void,
}

const emit = defineEmits<Events>()

const data = reactive({
  location: {},
  loading: false
})

const methods = {
  async handleSubmitButton () {
    data.loading = true
    if (await refs.editLocation.validateForm()) {
      try {
        await refs.editLocation.submitForm()
        toast.success(i18n.t('mywb.common.changes-saved'))
        emit('on-edit-finish')
        emit('on-close')
      } catch (error) {
        toast.error(i18n.t('mywb.error.unexpected-error'))
        refs.modal.$el.getElementsByClassName('modal-content')?.[0]?.scrollTo?.({
          top: 0,
          behavior: 'smooth'
        })
      }
    } else {
      const errors = document.getElementsByClassName('input_error')
      const lastError = errors[errors.length - 1]
      lastError?.scrollIntoView({
        behavior: 'smooth'
      })
    }
    data.loading = false
  }
}
</script>
