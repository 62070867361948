<template>
  <div class="item">
    <p>{{ props.provider.partnerName }}</p>
    <wb-switch
      v-model="compute.isActive"
      :disabled="props.disabled"
    />
  </div>
</template>

<script setup lang="ts">
import type { Partner } from '@/core/charger'
import { reactive, computed } from 'vue'

interface Props {
  provider: Partner,
  disabled: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-update', value: boolean): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  isActive: computed({
    get () {
      return props.provider.status === 'accepted'
    },
    set (value) {
      return emit('on-update', value)
    }
  })
})

</script>

<style scoped lang="postcss">
.item {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  padding: 16px 0;
  border-bottom: 1px solid var(--grey-100);
}
</style>
