import {
  perseusChargerRepository,
  createChargers,
  getAllChargers,
  getAllChargersPaginated,
  getCharger,
  getChargerActiveSession,
  getAllChargersActiveSession,
  updateChargersLocation,
  getChargerEnergyCost,
  unlinkCharger,
  sendUpdateOrRestartRemoteAction,
  sendStartOrPauseRemoteAction,
  updateCharger,
  getOcppSettings,
  updateOcppSettings,
  getAmountOfChargersWithUpdates,
  getChargerTypeMetaData,
  getIsChargeCompatibleWithPayments,
  getChargerTypes,
  getIsChargerCompatibleWithAutoLock,
  getIsChargerCompatibleWithGunLock,
  getChargerServiceabilities,
  updateChargerRemoteAssistance,
  acceptServiceability
} from '@/core/charger'

export function provideChargersUsesCases () {
  const repo = perseusChargerRepository()

  return {
    getChargerActiveSession: getChargerActiveSession(repo),
    getAllChargersActiveSession: getAllChargersActiveSession(repo),
    getAllChargers: getAllChargers(repo),
    getAllChargersPaginated: getAllChargersPaginated(repo),
    getCharger: getCharger(repo),
    createChargers: createChargers(repo),
    updateChargersLocation: updateChargersLocation(repo),
    getChargerEnergyCost: getChargerEnergyCost(repo),
    unlinkCharger: unlinkCharger(repo),
    sendUpdateOrRestartRemoteAction: sendUpdateOrRestartRemoteAction(repo),
    sendStartOrPauseRemoteAction: sendStartOrPauseRemoteAction(repo),
    updateCharger: updateCharger(repo),
    getOcppSettings: getOcppSettings(repo),
    updateOcppSettings: updateOcppSettings(repo),
    getAmountOfChargersWithUpdates: getAmountOfChargersWithUpdates(repo),
    getChargerTypeMetaData: getChargerTypeMetaData(repo),
    getIsChargeCompatibleWithPayments: getIsChargeCompatibleWithPayments(repo),
    getChargerTypes: getChargerTypes(repo),
    getIsChargerCompatibleWithAutoLock: getIsChargerCompatibleWithAutoLock(repo),
    getIsChargerCompatibleWithGunLock: getIsChargerCompatibleWithGunLock(repo),
    getChargerServiceabilities: getChargerServiceabilities(repo),
    updateChargerRemoteAssistance: updateChargerRemoteAssistance(repo),
    acceptServiceability: acceptServiceability(repo)
  }
}
