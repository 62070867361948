import yup from '@/hooks/useValidator.hook'

const revokeUserInvitationSchema = yup.object({
  groupUid: yup.string().required(),
  invitationId: yup.number().required()
})

export type RevokeUserInvitation = yup.InferType<typeof revokeUserInvitationSchema>

export async function ensureRevokeUserInvitationIsValid (payload: any) {
  return await revokeUserInvitationSchema.validate(payload, { abortEarly: false })
}
