<template>
  <div>
    <wb-button-card
      v-if="props.location?.name"
      data-test-id="locationName"
      icon="location"
      :title="i18n.t('mywb.common.name')"
      :subtitle="props.location.name"
      filled
    />

    <wb-button-card
      v-if="props.location?.address"
      icon="change_location"
      :title="i18n.t('mywb.common.address')"
      force-open
      filled
    >
      <google-maps-and-address-input-form
        v-if="props.showMap"
        read-mode
        :latitude="props.location.latitude"
        :longitude="props.location.longitude"
      />
      <div class="is-size-300" data-test-id="locationAddress">
        {{ props.location.address }}
      </div>
    </wb-button-card>

    <wb-button-card
      v-if="compute.locationTypes.length"
      data-test-id="locationTypes"
      icon="ev_station"
      :title="i18n.t('mywb.location.location-type')"
      force-open
      filled
    >
      <wb-badge
        v-for="locationType in compute.locationTypes"
        :key="locationType"
        variant="blue"
        class="mr-4"
      >
        {{ locationType }}
      </wb-badge>
    </wb-button-card>

    <wb-button-card
      v-if="props.location?.energyType"
      data-test-id="locationEnergyType"
      :icon="compute.energyType.icon ? compute.energyType.icon : 'help'"
      :title="i18n.t('mywb.location.energy-type')"
      :subtitle="compute.energyType.text"
      filled
    />

    <wb-button-card
      v-if="props.location?.energyCost"
      data-test-id="locationEnergyCost"
      icon="charge_boost_filled"
      :title="i18n.t('mywb.common.energy-cost')"
      :subtitle="compute.energyCost"
      filled
    />
    <div v-if="!props.showMap" class="is-flex justify-content mt-24">
      <wb-button-groups>
        <wb-button
          v-if="!props.showMap"
          data-test-id="showGroupCta"
          :label="i18n.t('mywb.common.view')"
          variant="white"
          class="group-item"
          @mousedown="methods.goToLocation()"
        />
        <wb-button
          data-test-id="editGroupCta"
          :label="i18n.t('mywb.common.edit')"
          variant="white"
          class="group-item"
          @mousedown="emit('on-edit', props.location)"
        />
        <wb-button
          data-test-id="deleteGroupCta"
          icon="delete"
          variant="danger"
          inverted
          class="group-item"
          @mousedown="emit('on-delete', props.location)"
        />
      </wb-button-groups>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import GoogleMapsAndAddressInputForm from '@/components/forms/GoogleMapsAndAddressInputForm.vue'
import { locationTypesObject, type LocationTypesEnum } from '@/utilities/locationTypesEnum'
import { numbers } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import type { Location } from '@/core/location'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

type Events = {
  (e: 'on-edit', location?: Location): void,
  (e: 'on-delete', location?: Location): void,
}
const emit = defineEmits<Events>()

interface PropsType {
  location?: Location
  showMap?: boolean
}

const props = defineProps<PropsType>()

const compute = reactive({
  locationTypes: computed(() => {
    const types = (props.location?.locationTypes ?? []) as LocationTypesEnum[]
    return types.map(entry => locationTypesObject[entry])
  }),

  energyType: computed(() => {
    return {
      renewable: { icon: 'leaf', text: i18n.t('mywb.common.renewable') },
      nonrenewable: { icon: 'bolt', text: i18n.t('mywb.common.non-renewable') },
      unknown: { text: i18n.t('mywb.common.unknown') }
    }[props.location?.energyType ?? ''] ?? {}
  }),

  energyCost: computed((): string => {
    return numbers.toLocaleCurrencySymbol(
      props.location?.energyCost ?? 0,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  })
})

const methods = {
  goToLocation () {
    router.push({ name: ROUTES.LOCATION_DETAIL, params: { locationId: props.location?.id ?? '' } })
  },

  goToOrganization () {
    router.push({
      name: ROUTES.ORGANIZATION_DETAIL,
      params: { organizationUid: state.organizations.getCurrentOrganization.groupUid }
    })
  }
}
</script>

<style lang="postcss" scoped>
  .justify-content {
    justify-content: center;
  }
</style>
