import { object, number, array, type InferType, mixed } from 'yup'

export enum REMOTE_ACTIONS {
  UPDATE = 5,
  RESTART = 3,
  CHARGE = 1,
  PAUSE = 2
}

const remoteActionUpdateOrRestartSchema = object({
  chargers: array().of(number().required()).required(),
  action: mixed<REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART>().required()
})

const remoteActionChargeOrPauseSchema = object({
  chargerId: number().required(),
  action: mixed<REMOTE_ACTIONS.CHARGE | REMOTE_ACTIONS.PAUSE>().required()
})

export type remoteActionUpdateOrRestart = InferType<typeof remoteActionUpdateOrRestartSchema>
export type remoteActionChargeOrPause = InferType<typeof remoteActionChargeOrPauseSchema>
