import {
  getConfig
} from './application'
import { wapi } from './infrastructure'

export function provideConfigUseCases () {
  const repo = wapi()

  return {
    getConfig: getConfig(repo)
  }
}
