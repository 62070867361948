import yup from '@/hooks/useValidator.hook'

const activateContractSchema = yup.object({
  contractId: yup.number().required(),
  confirmToken: yup.string().required()
})

export type ActivateContract = yup.InferType<typeof activateContractSchema>

export async function ensureActivateContractSchemaIsValid (payload: any) {
  return await activateContractSchema.validate(payload, { abortEarly: false })
}
