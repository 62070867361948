<template>
  <wb-modal
    width="36"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', {element: props.user.name + ' ' + props.user.surname}) }}
    </template>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          size="block"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          size="block"
          variant="danger"
          :label="i18n.t('mywb.common.delete')"
          :loading="data.loading"
          @click="methods.removeUser"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import { reactive } from 'vue'
import { ACCESS_CONFIG_USE_CASES, USER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { UserList } from '@/core/user'

const i18n = useI18n()
const toast = useToast()
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)
const userUseCases = injectStrict(USER_USE_CASES)
interface Props {
  user: UserList
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const methods = {
  async removeUser () {
    try {
      const { user } = props
      data.loading = true

      await userUseCases.removeUser({
        groupUid: state.organizations.getCurrentOrganization.groupUid,
        user: user.id,
        accessConfig: user.accessGroup?.id
      })

      const accessConfigs = await accessConfigUseCases
        .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)

      state.user.setAccessConfigs(accessConfigs)

      trackDataEvent('delete-user', { user_id: user.id })
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('close')
      data.loading = false
    }
  }
}

function created () {
  trackDataScreen('user-list-user-delete')
}

created()
</script>
