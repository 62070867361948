<template>
  <div>
    <wb-form>
      <wb-input
        v-model="form.name"
        class="mb-24"
        name="name"
        type="text"
        data-test-id="userGroupNameInput"
        :label="i18n.t('mywb.common.access-group-name')"
        :placeholder="i18n.t('mywb.common.add-group-name')"
        :error="errors.name"
      />
    </wb-form>

    <p class="label">
      {{ i18n.t('mywb.common.select-chargers-to-access-group') }}
    </p>
    <locations-accordion
      v-model="form.chargers"
    />
  </div>
</template>

<script setup lang="ts">
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { ACCESS_CONFIG_USE_CASES, CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { type AccessConfigCreate, ensureCreateAccessConfigIsValid } from '@/core/accessConfig'
import { useValidator } from '@/hooks/useValidator.hook'

const chargerUsesCases = injectStrict(CHARGER_USE_CASES)
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

const i18n = useI18n()

interface Events {
  (e: 'update:accessConfigId', AccessConfigId: number | undefined): void
}
const emit = defineEmits<Events>()

interface Data {
  isValid: boolean
}

const data = reactive<Data>({
  isValid: false
})

const { errors, validate, validateTo } = useValidator()

const form = reactive<AccessConfigCreate>({
  chargers: [],
  group: state.organizations.getCurrentOrganization.groupUid,
  name: ''
})

validateTo(async () => !!await ensureCreateAccessConfigIsValid(form))

const methods = {
  async createAccessConfigGroup (): Promise<void> {
    try {
      const id = await accessConfigUseCases.createAccessConfig(form)

      const accessConfig = await accessConfigUseCases
        .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
      state.user.setAccessConfigs(accessConfig)

      emit('update:accessConfigId', id)

      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
      trackDataEvent('add-group')
      data.isValid = true
    } catch (error) {
      data.isValid = false
    }
  }
}

defineExpose({
  validateForm: async () => {
    await validate(methods.createAccessConfigGroup)
    return data.isValid
  }
})

async function created () {
  const chargers = await chargerUsesCases.getAllChargers(state.organizations.getCurrentOrganization.groupUid, {
    cache: 'network'
  })
  state.charger.set('chargers', chargers)
  trackDataScreen('users-add-group')
}

created()
</script>

<style lang="postcss" scoped>
.label {
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  margin-bottom: 6px;
}
</style>
