<template>
  <shared-teleport-header>
    <template #title>
      <span v-if="!data.loading">
        {{ data.location?.name }}
      </span>
      <wb-loading v-else variant="grey" style="margin: 0" />
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canAddCharger"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.add-charger') : ''"
        data-test-id="locationAddCharger"
        icon="add"
        class="ml-12"
        @click="compute.isLocationComplete
          ? send('addChargers')
          : router.push({
            name: ROUTES.CHARGERS,
            query: { 'add-chargers': '1', 'group-uid': data.location?.groupUid }
          })
        "
      />
    </template>

    <template #filters>
      <chargers-filters
        :loading="data.loading"
        :include-columns="['name', 'status', 'features', 'actions']"
        @on-reset-filters="state.filters.setFilterValue({
          filter: 'chargersFilters', key: 'locations', value: [props.locationId]
        })"
      />
    </template>
  </shared-teleport-header>

  <div class="location-layout g-16">
    <wb-card
      :headline="i18n.t('mywb.common.overview')"
      class="summary"
    >
      <template #place-upper-right>
        <wb-button-groups>
          <wb-button
            data-test-id="editGroupCta"
            icon="edit"
            :label="i18n.t('mywb.common.edit')"
            variant="white"
            class="group-item"
            size="small"
            @mousedown="methods.editLocation"
          />
          <wb-button
            data-test-id="deleteGroupCta"
            icon="delete"
            variant="danger"
            inverted
            class="group-item"
            size="small"
            @mousedown="methods.deleteGroup"
          />
        </wb-button-groups>
      </template>
      <template #content>
        <location-settings-overview
          data-test-id="summaryLocation"
          :location="data.location"
          show-map
        />
      </template>
    </wb-card>

    <charger-list
      :include-columns="['selectable', 'name', 'status', 'features', 'actions']"
      :update-chargers-notification="false"
    />
  </div>

  <shared-confirmation-modal
    v-if="isCurrentState('modalDeleteWithoutChargers')"
    data-test-id="chargersGroupDeleteModal"
    :title="i18n.t('mywb.common.delete-confirmation', { element: data.location?.name })"
    :label-confirmation-button="i18n.t('mywb.common.delete')"
    type="danger"
    @on-confirm="methods.handleLocationDelete"
    @on-close="send('close')"
  />

  <location-create-modal
    v-if="isCurrentState('modalEditLocation')"
    data-test-id="chargersGroupEditModal"
    :location-id="data.location?.id"
    @on-close="send('close')"
    @on-edit-finish="created()"
  />

  <location-assign-chargers-modal
    v-if="isCurrentState('modalAddChargers') && data.location?.groupUid"
    :location="data.location"
    @on-close="send('close')"
  />

  <location-with-chargers-delete-modal
    v-if="isCurrentState('modalDeleteWithChargers') && data.location"
    data-test-id="chargersGroupDeleteModalWithChargers"
    :location="data.location"
    :locations="data.locations"
    :chargers="state.charger.getChargers"
    @on-close="send('close')"
    @on-delete="router.push({ name: ROUTES.LOCATIONS })"
  />
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, watch, watchEffect, onUnmounted, computed } from 'vue'

import LocationWithChargersDeleteModal from '@/components/modals/LocationWithChargersDeleteModal.vue'
import LocationAssignChargersModal from '@/components/modals/LocationAssignChargersModal.vue'
import LocationSettingsOverview from '@/components/locations/LocationSettingsOverview.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import LocationCreateModal from '@/components/modals/LocationCreateModal.vue'
import ChargersFilters from '@/components/filters/ChargersFilters.vue'
import ChargerList from '@/components/charger/ChargerList.vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { useStateMachine } from '@wallbox/hooks'
import { useToast } from '@wallbox/toolkit-ui'
import type { Location } from '@/core/location'
import { LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import { useMq } from 'vue3-mq'
import ROUTES from '@/engine/router/routes'

const mq = useMq()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const toast = useToast()
const usesCases = injectStrict(LOCATION_USE_CASES)

interface Props {
  locationId: string
}
const props = defineProps<Props>()

const { isCurrentState, send } = useStateMachine({
  initial: 'layout',
  states: {
    layout: {
      transitions: {
        deleteWithChargers: 'modalDeleteWithChargers',
        deleteWithoutChargers: 'modalDeleteWithoutChargers',
        editLocation: 'modalEditLocation',
        addChargers: 'modalAddChargers'
      }
    },

    modalDeleteWithChargers: {
      transitions: {
        close: 'layout'
      }
    },

    modalDeleteWithoutChargers: {
      transitions: {
        close: 'layout'
      }
    },

    modalEditLocation: {
      transitions: {
        close: 'layout'
      }
    },

    modalAddChargers: {
      transitions: {
        close: 'layout'
      }
    }
  }
})

interface Data {
  loading: boolean
  location?: Location
  locations: Location[]
}

const data = reactive<Data>({
  loading: false,
  locations: []
})

const compute = reactive({
  isLocationComplete: computed(() => {
    if (!data.location) return false

    const { country, address, city, latitude, longitude, zipCode } = data.location
    return Boolean(country && address && city && latitude && longitude && zipCode)
  })
})

const methods = {
  editLocation () {
    send('editLocation')
  },

  deleteGroup () {
    if (state.charger.getChargers.length > 0) {
      send('deleteWithChargers')
    } else {
      send('deleteWithoutChargers')
    }
  },

  async handleLocationDelete () {
    try {
      router.push({ name: ROUTES.LOCATIONS })
      await usesCases.deleteLocation(data.location?.id ?? '-1')
      toast.success(i18n.t('mywb.common.location-removed-successfully'))
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

async function created () {
  data.loading = true
  state.filters.resetFilters({ filter: 'chargersFilters' })
  state.filters.setFilterValue({ filter: 'chargersFilters', key: 'locations', value: [props.locationId] })
  data.location = await usesCases.getLocation(props.locationId)

  if (!data.location) {
    router.push({ name: ROUTES.LOCATIONS })
  }

  if (!data.location?.longitude || !data.location.latitude) methods.editLocation()
  data.locations = await usesCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid)
  data.loading = false
}

created()

onUnmounted(() => {
  state.filters.resetFilters({ filter: 'chargersFilters' })
})

watchEffect(() => {
  if (route.query.edit) {
    setTimeout(() => {
      created()
      router.push({ query: {} }).catch(() => {})
    }, 1000)
  }
})

watch(() => data.location, () => state.routes.hydrateCurrentBreadCrumb(data.location?.name ?? ''))
</script>

<style lang="postcss" scoped>
.location-layout {
  display: grid;
  grid-auto-flow: dense;

  @media (--desktop) {
    grid-template-columns: 2fr 1fr;
  }
}

.summary {
  @media (--desktop) {
    position: sticky !important;
    top: var(--header-filters-height);
    grid-column: 2;
    grid-row: 1 / span 2;
    align-self: start;
  }
}
</style>
