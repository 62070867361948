import { STATUSES } from '@/utilities/charger/chargerStatuses'
import type { Charger as ChargerCore } from '@/core/charger'

export const isUpdatesAvailable = (charger: ChargerCore) => {
  const updateAvailable = charger.software.updateAvailable ?? false
  const status = charger.status.code

  return (
    updateAvailable &&
    status !== STATUSES.DISCONNECTED &&
    status !== STATUSES.CHARGING &&
    status !== STATUSES.DISCHARGING)
}
