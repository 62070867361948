
import { reactive, computed } from 'vue'
import { EnumRoles, getRoleById } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import { setRegionByCountry } from '@/engine/clients'
import { OrganizationTypeEnum } from '@/utilities/organizationTypes'
import type { Organization } from '@/core/organization'

interface OrganizationsState {
  organizations: Organization[]
  currentOrganizationId: string | null
}

const initialState = (): OrganizationsState => ({
  organizations: [],
  currentOrganizationId: null
})

const DEFAULT_ORGANIZATION: Organization = {
  id: '',
  name: '',
  role: getRoleById(EnumRoles['super-admin']),
  plan: {
    product_id: EnumPlans.BASIC,
    description: '',
    label: '',
    name: ''
  },
  groupUid: '',
  ownerId: -1,
  ownerEmail: '',
  paymentsAccount: '',
  countryCode: 'ES',
  currencyCode: 'EUR',
  createdBy: '',
  organizationType: OrganizationTypeEnum.HOME
}

const state = reactive(initialState())

const getters = {
  allOrganizations: computed(() => state.organizations),

  getCurrentOrganization: computed(() => {
    return state.organizations
      .find(organization => organization.id === state.currentOrganizationId) ?? DEFAULT_ORGANIZATION
  }),

  getOrganizationByUid (organizationUid: string) {
    return state.organizations.find(organization => organization.groupUid === organizationUid)
  },

  isPlan () {
    return (plans: EnumPlans[]) =>
      plans.includes(getters.getCurrentOrganization.value.plan.product_id)
  },

  isRole () {
    return (roles: EnumRoles[]) => roles.includes(getters.getCurrentOrganization.value.role.id)
  },

  isRegionUS: computed((): boolean => {
    return getters.getCurrentOrganization.value.countryCode === 'US'
  }),

  defaultHomePage: computed((): string => {
    return [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      .includes(getters.getCurrentOrganization.value.role.id)
      ? 'dashboard'
      : 'chargers'
  })
}

const setters = {
  setCurrentOrganization (organizationId: string) {
    state.currentOrganizationId = organizationId
    setWbStateProperty('currentOrganizationId')
    setRegionByCountry(getters.getCurrentOrganization.value.countryCode)
  },

  setOrganizations (organizations: Organization[]) {
    state.organizations = organizations
    setWbStateProperty('organizations')
  }
}

function setWbStateProperty (property: string) {
  const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}')

  if (wbState) {
    wbState[property] = state[property as keyof OrganizationsState]
    localStorage.setItem('wb-state', JSON.stringify(wbState))
  }
}

export function loadState () {
  const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}')
  if (wbState?.organizations && wbState?.currentOrganizationId) {
    setters.setOrganizations(wbState.organizations)
    setters.setCurrentOrganization(wbState.currentOrganizationId)
  }
}

export default reactive({
  ...getters,
  ...setters
})
