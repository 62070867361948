import type { CountryIso2 } from '@/core/international'
import { object, string, boolean, type InferType } from 'yup'

const billingInfoSchema = object({
  name: string().required(),
  region: string<'usa' | 'spain' | 'europeanUnion' | 'other'>().required(),
  address: string().required(),
  city: string().required(),
  zipCode: string().required(),
  company: boolean(),
  country: string<CountryIso2>().required(),
  state: string().optional(),
  cif: string().when('company', {
    is: true,
    then: (schema) => {
      return schema.when('region', {
        is: (region: string) => (region !== 'europeanUnion' && region !== 'spain'),
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required()
      })
    },
    otherwise: (schema) => schema.optional()
  }),
  surname: string().when('company', {
    is: true,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required()
  })
})

export type BillingInformationEdit = InferType<typeof billingInfoSchema>

export async function ensureEditBillingInformationIsValid (billingInfo: any) {
  return await billingInfoSchema.validate(billingInfo, { abortEarly: false })
}
