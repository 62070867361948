<template>
  <wb-card
    :headline="i18n.t('mywb.common.environment')"
    :subhead="i18n.t('mywb.common.environment.description')"
    class="dashboard-card"
  >
    <template #place-upper-right>
      <span class="wb-icons chart-icon" data-test-id="icon">
        leaf
      </span>
    </template>
    <template #content>
      <wb-skeleton-loader
        type="dots"
        :loading="data.loading"
        data-test-id="dashboard-card-content"
      >
        <div class="is-size-1000 is-font-weight-500 has-text-grey-700" data-test-id="co2Savings">
          {{ compute.co2SavingsFormatted }}
        </div>
        <p v-t="'mywb.dashboard.co2-saved'" class="is-size-300 has-text-grey-400" />

        <div class="is-size-1000 is-font-weight-500 has-text-grey-700 mt-24" data-test-id="fuelReplaced">
          {{ compute.fuelSavingsFormatted }}
        </div>
        <p v-t="'mywb.dashboard.fuel-replaced'" class="is-size-300 has-text-grey-400" />
      </wb-skeleton-loader>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { clientConfig } from '@/engine/clients'
import { reactive, computed, watch } from 'vue'
import type { ChartSeries } from '@/utilities/chartSessions/chart.types'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface PropsType {
  series?: ChartSeries
}

const props = defineProps<PropsType>()

type DataType = {
  loading: boolean
  energyUserConsumed: number
  co2EmmitedForGasolineCarIn1Km: number
  co2EmmitedForElectricCarIn1Km: number
  energyConsumedByKm: number
  AvergaeFuelConsumedIn1Km: number
}

const data: DataType = reactive({
  loading: false,
  energyUserConsumed: 0,
  co2EmmitedForGasolineCarIn1Km: 122.4,
  co2EmmitedForElectricCarIn1Km: 58,
  energyConsumedByKm: 0.15,
  AvergaeFuelConsumedIn1Km: 0.072
})

const compute = reactive({
  interval: computed(() => state.filters.dashboardFilters.calendar.interval),

  averageKmTravel: computed((): number => {
    return data.energyUserConsumed / data.energyConsumedByKm
  }),

  co2ByEnergyConsumed: computed((): number => {
    return (data.co2EmmitedForElectricCarIn1Km * data.energyUserConsumed) / data.energyConsumedByKm
  }),

  co2ConsumedByCar: computed((): number => {
    return (data.co2EmmitedForGasolineCarIn1Km * compute.averageKmTravel)
  }),

  co2Savings: computed((): number => {
    return compute.co2ConsumedByCar - compute.co2ByEnergyConsumed
  }),

  fuelSavings: computed((): number => {
    return data.AvergaeFuelConsumedIn1Km * compute.averageKmTravel
  }),

  co2SavingsFormatted: computed((): string => {
    return clientConfig.units.mass.formula(compute.co2Savings / 1000)
  }),

  fuelSavingsFormatted: computed((): string => {
    return clientConfig.units.volume.formula(compute.fuelSavings)
  })
})

watch(() => props.series, (range, oldRange) => {
  if (JSON.stringify(range) !== JSON.stringify(oldRange)) {
    methods.getEnergyConsumed()
  }
}, { deep: true })

const methods = {
  async getEnergyConsumed () {
    if (props.series?.energy) {
      data.energyUserConsumed = Object.values(props.series.energy[0].data)
        .reduce((a: number, b: number) => a + b, 0) || 0
    }
  },

  getCo2Info () {
    trackDataAction('dashboard-co2info')
  }
}

const created = async () => {
  data.loading = true
  await methods.getEnergyConsumed()
  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
.chart-icon {
  color: var(--grey-500);
  position: relative;
  font-size: var(--size-700);
  margin: 0.8rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: currentColor;
    opacity: 0.07;
    width: 36px;
    height: 36px;
    border-radius: 99px;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

:deep(svg) {
  height: 57px;
  width: 100%;
}
</style>
