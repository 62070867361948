<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.create-access-group') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.create-access-group.description') }}
    </div>

    <access-config-creation-form
      :ref="setRef('access-config-creation')"
      v-model:accessConfigId="data.accessConfigId"
    />
  </div>
</template>

<script setup lang="ts">
import AccessConfigCreationForm from '@/components/forms/AccessConfigCreationForm.vue'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, onMounted } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import state from '@/state'
import type { AccessConfig } from '@/core/accessConfig'

const i18n = useI18n()
const { refs, setRef } = useTemplateRef()
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

interface Events {
  (e: 'update:accessConfigId', id?: number): void
  (e: 'update:chargersSelected', chargers: number[]): void
}

const emit = defineEmits<Events>()

interface Data {
  accessConfigId?: number,
  accessConfigs: AccessConfig[]
}

const data = reactive<Data>({
  accessConfigId: -1,
  accessConfigs: []
})

const compute = reactive({
  getChargersFromAccessConfig: computed(() => {
    return data.accessConfigs
      .find((accessConfig: AccessConfig) => accessConfig.id === data.accessConfigId)?.chargers || []
  })
})

const methods = {
  async isValidateForm () {
    if (await refs['access-config-creation'].validateForm()) {
      emit('update:accessConfigId', data.accessConfigId)
      emit('update:chargersSelected', compute.getChargersFromAccessConfig)
      return true
    }
    return false
  }
}

onMounted(async () => {
  data.accessConfigs = await accessConfigUseCases
    .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
