<template>
  <wb-card
    :headline="i18n.t('mywb.user.personal-data')"
    data-test-id="personalDataSection"
  >
    <template #content>
      <wb-card filled alignment="center">
        <template #content>
          <div class="content-grid">
            <avatar-uploader-account
              data-test-id="avatarSection"
            />
            <div>
              <p>
                {{ state.user.userLogged.name }} {{ state.user.userLogged.surname }}
              </p>
              <p>
                {{ state.user.userLogged.email }}
              </p>
              <p data-test-id="countryAndTimeZone">
                {{ compute.countryName }}
              </p>
            </div>
          </div>
        </template>

        <template #actions>
          <wb-button
            variant="white"
            outlined
            :label="i18n.t('mywb.common.edit')"
            icon="edit"
            class="edit"
            data-test-id="editBtn"
            @click="data.openPersonalDataAccountModal = true"
          />
        </template>
      </wb-card>
    </template>

    <template #modal>
      <personal-data-account-modal
        v-if="data.openPersonalDataAccountModal"
        @close="data.openPersonalDataAccountModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import state from '@/state'
import PersonalDataAccountModal from '@/components/modals/PersonalDataAccountModal.vue'
import AvatarUploaderAccount from '@/components/account/AvatarUploaderAccount.vue'

const i18n = useI18n()

const data = reactive({
  openPersonalDataAccountModal: false
})

const compute = reactive({
  countryName: computed(() => {
    const country = state.global.getCountries.find(country => country.iso2 === state.user.userLogged.countryIso2)
    return country?.name
  })
})
</script>

<style lang="postcss" scoped>
.content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 18px;

  @media (--tablet) {
    grid-template-columns: min-content 1fr;
  }
}
</style>
