import { number, array, object, string, type InferType } from 'yup'

const accessConfigCreateSchema = object({
  group: string().required(),
  chargers: array().of(number().required()).required(),
  name: string().required()
})

export type AccessConfigCreate = InferType<typeof accessConfigCreateSchema>

export async function ensureCreateAccessConfigIsValid (payload: any) {
  return await accessConfigCreateSchema.validate(payload, { abortEarly: false })
}
