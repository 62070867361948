<template>
  <wb-modal width="60" @close="emit('on-close')">
    <role-users v-model="data.profile" />

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="methods.handleCancel"
        />
        <wb-button
          data-test-id="acceptBtnModal"
          size="block"
          variant="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="data.submitLoading"
          @click="methods.handleConfirm"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import RoleUsers from '@/components/invitations/RoleUsers.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive } from 'vue'
import { EnumRoles } from '@/utilities/user-roles'
import { useToast } from '@wallbox/toolkit-ui'
import type { UserDetail } from '@/core/user'
import type { AccessConfig } from '@/core/accessConfig'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'

const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)
const toast = useToast()
const i18n = useI18n()

interface Props {
  modelValue: number
  user: UserDetail
  accessConfig?: AccessConfig
}

const props = defineProps<Props>()

interface Data {
  profile: EnumRoles
  submitLoading: boolean
}

const data = reactive<Data>({
  submitLoading: false,
  profile: EnumRoles.user
})

interface Events {
  (e: 'update:modelValue', role: EnumRoles): void
  (e: 'on-close'): void,
  (e: 'on-confirm'): void
}
const emit = defineEmits<Events>()

const methods = {
  async handleConfirm () {
    if (!props.accessConfig?.id) return

    data.submitLoading = true
    try {
      await accessConfigUseCases.changeUserRole({
        accessConfigId: props.accessConfig.id,
        userId: props.user.id,
        role: data.profile
      })

      toast.success(i18n.t('mywb.common.changes-saved'))
      emit('update:modelValue', data.profile)
      emit('on-close')
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.submitLoading = false
    }
  },

  handleCancel () {
    emit('on-close')
  }
}

function created () {
  data.profile = props.modelValue
  trackDataScreen('user-type-modal')
}

created()
</script>
