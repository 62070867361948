<template>
  <div>
    <aside
      id="aside-menu"
      :class="compute.asideMenuClasses"
      data-test-id="sideMenu"
    >
      <wb-button
        variant="white"
        rounded
        :icon="data.isCollapsed ? 'chevron_right' : 'chevron_left'"
        class="button-collapse"
        @click="methods.handleCollapse"
      />

      <div class="top">
        <div class="logo" :class="{'is-collapse': data.isCollapsed, 'is-iberdrola': clientConfig.name === 'iberdrola'}">
          <img
            v-bind="!data.isCollapsed ? clientConfig.asideLogo : clientConfig.asideLogoCollpase"
            loading="eager"
            importance="high"
            alt="logo"
          >
        </div>

        <div class="title-section" :class="{'is-collapse': data.isCollapsed}">
          {{ i18n.t('mywb.common.organizations') }}
        </div>
        <aside-menu-organizations :is-collapsed="data.isCollapsed" />

        <wb-dropdown tooltip-position="right" :tooltip-distance-offset="16">
          <template #activator>
            <wb-button
              variant="white"
              inverted
              shape="squircle"
              size="block"
              :to="{ name: ROUTES.ORGANIZATIONS }"
              :label="!data.isCollapsed ? i18n.t('mywb.common.organizations') : ''"
              icon="business"
              data-test-id="organizationsOption"
              content-position="left"
              @click="methods.handleClickOption('organizations')"
            />
          </template>

          <template v-if="data.isCollapsed" #tooltip>
            {{ i18n.t('mywb.common.organizations') }}
          </template>
        </wb-dropdown>
      </div>

      <div class="content">
        <div class="title-section" :class="{'is-collapse': data.isCollapsed}">
          {{ i18n.t('mywb.common.sections') }}
        </div>
        <wb-dropdown
          v-for="option in compute.options"
          :key="option.name"
          tooltip-position="right"
          :tooltip-distance-offset="16"
        >
          <template #activator>
            <wb-button
              variant="white"
              shape="squircle"
              :to="{ name: option.name }"
              :label="!data.isCollapsed ? option.label : ''"
              inverted
              :icon="option.icon"
              :data-test-id="option.dataTestId"
              size="block"
              content-position="left"
              @click="methods.handleClickOption(option.name)"
            />
          </template>

          <template v-if="data.isCollapsed" #tooltip>
            {{ option.label }}
          </template>
        </wb-dropdown>
      </div>
      <div class="bottom">
        <wb-dropdown
          v-if="permissions.canSeeSupport"
          tooltip-position="right"
          :tooltip-distance-offset="16"
        >
          <template #activator>
            <wb-button
              variant="white"
              shape="squircle"
              :label="!data.isCollapsed ? i18n.t('mywb.common.help') : ''"
              inverted
              icon="help"
              data-test-id="helpOption"
              size="block"
              content-position="left"
              @click="methods.handleClickHelpOption"
            />
          </template>
          <template v-if="data.isCollapsed" #tooltip>
            {{ i18n.t('mywb.common.help') }}
          </template>
        </wb-dropdown>

        <wb-dropdown
          tooltip-position="right"
          :tooltip-distance-offset="16"
        >
          <template #activator>
            <wb-button
              variant="white"
              shape="squircle"
              :to="{ name: ROUTES.ACCOUNT }"
              :label="!data.isCollapsed ? i18n.t('mywb.common.settings') : ''"
              inverted
              icon="settings"
              data-test-id="settingsOption"
              size="block"
              content-position="left"
              @click="methods.handleClickOption('account')"
            />
          </template>

          <template v-if="data.isCollapsed" #tooltip>
            {{ i18n.t('mywb.common.settings') }}
          </template>
        </wb-dropdown>

        <aside-menu-account>
          <template #activator>
            <wb-button
              variant="white"
              inverted
              shape="squircle"
              data-test-id="avatarOption"
              size="block"
              content-position="left"
              class="btn-avatar"
            >
              <wb-user-avatar
                size="small"
                :src="state.user.userLogged.avatar"
                :initials="compute.initials"
                :class="{'is-collapse': data.isCollapsed}"
              />
              <div v-if="!data.isCollapsed" class="has-text-left ml-8 has-max-width">
                <p class="is-size-300 is-font-weight-500 has-text-overflow">
                  {{ state.user.userLogged.name }} {{ state.user.userLogged.surname }}
                </p>
                <p class="is-size-200 has-text-overflow">
                  {{ state.user.userLogged.email }}
                </p>
              </div>
            </wb-button>
          </template>
          <template v-if="data.isCollapsed" #tooltip>
            {{ state.user.userLogged.name }} {{ state.user.userLogged.surname }}
          </template>
        </aside-menu-account>
      </div>
    </aside>
    <div class="mobile-overlay" :class="{'is-visible': compute.isOpen}" @click="compute.isOpen = false" />
  </div>
</template>

<script setup lang="ts">
import state from '@/state'
import AsideMenuOrganizations from '@/components/asideMenu/AsideMenuOrganizations.vue'
import AsideMenuAccount from '@/components/asideMenu/AsideMenuAccount.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { watch, reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { userInitials } from '@/utilities/users'
import { view } from '@wallbox/toolkit-ui'
import { clientConfig, permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import ENV from '@/engine/env/web.env'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const mq = useMq()

interface Props {
  modelValue?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false
})

const data = reactive({
  isCollapsed: false
})

interface Events {
  (e: 'on-collapse', isCollapsed: boolean): void,
  (e: 'update:modelValue', isOpen: boolean): void
}
const emit = defineEmits<Events>()

watch(() => mq, value => {
  data.isCollapsed = value.current === 'touch'
  emit('on-collapse', data.isCollapsed)
}, { deep: true })

const compute = reactive({
  initials: computed(() => userInitials(state.user.userLogged)),

  isOpen: computed({
    get (): boolean {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  }),

  asideMenuClasses: computed((): Record<string, boolean> => {
    return {
      'aside-menu': true,
      'is-open': compute.isOpen,
      'is-collapse': data.isCollapsed
    }
  }),

  options: computed(() => {
    return [
      permissions.canSeeDashboard && {
        name: ROUTES.DASHBOARD,
        icon: 'dashboard',
        label: i18n.t('mywb.common.dashboard'),
        dataTestId: 'dashboardOption'
      },
      {
        name: ROUTES.CHARGERS,
        icon: 'charger',
        label: i18n.t('mywb.common.chargers'),
        dataTestId: 'chargersOption'
      },
      hasFeatureFlagActive('ocpp') && {
        name: ROUTES.CHARGE_POINTS,
        icon: 'ev_station',
        label: i18n.t('mywb.common.charge-points'),
        dataTestId: 'chargerPointsOption'
      },
      (permissions.canSeeLocations || permissions.showUpSellingToPlanBusiness) && {
        name: ROUTES.LOCATIONS,
        icon: 'location',
        label: i18n.t('mywb.common.locations'),
        dataTestId: 'locationsOption'
      },
      {
        name: ROUTES.SESSIONS,
        icon: 'sessions',
        label: i18n.t('mywb.common.sessions'),
        dataTestId: 'sessionsOption'
      },
      permissions.canSeeUsers && {
        name: ROUTES.USERS,
        icon: 'people',
        label: i18n.t('mywb.common.users'),
        dataTestId: 'usersOption'
      },
      (permissions.showPayments || permissions.showBusinessPage) && {
        name: permissions.showPayments ? ROUTES.PAYMENTS_INVOICES : ROUTES.PLAN_BUSINESS,
        icon: 'card',
        label: i18n.t('mywb.common.payments'),
        dataTestId: 'paymentsOption'
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

watchEffect(() => compute.isOpen ? view.blockScroll() : view.unblockScroll())

const methods = {
  handleClickOption (name: string) {
    trackDataAction(`account-menu-${name}`, { name })
  },

  handleCollapse () {
    data.isCollapsed = !data.isCollapsed
    localStorage.setItem('wb-aside-menu-collapse', data.isCollapsed.toString())
    emit('on-collapse', data.isCollapsed)
  },

  handleClickHelpOption () {
    window.mavenoid.push({
      event: 'troubleshooter-open',
      productId: ENV.mavenoid.productId,
      orgName: 'Wallbox',
      openByDefault: true,
      position: 'left',
      onClose: () => (window.mavenoid.push({ event: 'troubleshooter-unmount' }))
    })

    document.body.addEventListener('click', () => window.mavenoid.push({ event: 'troubleshooter-hide' }), true)
  }
}

const created = () => {
  data.isCollapsed = localStorage.getItem('wb-aside-menu-collapse') === 'true'
  emit('on-collapse', data.isCollapsed)
}
created()
</script>

<style lang="postcss" scoped>
.aside-menu {
  display: grid;
  grid-template-rows: max-content min-content max-content;
  width: 24rem;
  transform: translateX(-24rem);
  transition: width 150ms ease-out;
  background: var(--grey-900);
  position: sticky;
  top: var(--header-touch-size);
  height: calc(100vh - var(--header-touch-size));
  z-index: 500;

  @media (--tablet) {
    top: 0;
    height: 100vh;
    grid-template-rows: max-content auto max-content;
  }

  & .button-collapse {
    position: absolute;
    right: -14px;
    top: 16px;
    height: 24px !important;
    width: 24px !important;
    transition: all 250ms;
    box-shadow: 0 1px 2px var(--grey-400);
    visibility: hidden;
    transform: scale(0);

    &:hover {
      box-shadow: 0 1px 4px var(--grey-400);
    }

    @media (--tablet) {
      visibility: visible;
    }
  }

  &:hover {
    & .button-collapse {
      transform: scale(1);
    }
  }

  &.is-collapse {
    width: 7rem;
    transition: all 0;
  }

  &.is-open {
    transition: all 250ms;
    transform: translateX(0);
  }

  @media (--tablet) {
    top: 0;
    position: sticky;
    transform: translateX(0);
  }

  & .top,
  & .content,
  & .bottom {
    padding: 1.2rem;
  }

  & .top > *,
  & .content > *,
  & .bottom > * {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .top {
    & .logo {
      display: none;
      height: 30px;
      margin: 8px 8px 18px;

      &.is-collapse {
        width: 16px;
        margin-left: 16px;
      }

      &.is-iberdrola {
        height: 110px;
      }

      @media (--tablet) {
        display: flex;
        position: sticky;
        top: 0;
      }
    }
  }

  & .content {
    overflow: hidden auto;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-500) var(--grey-900);

    &::-webkit-scrollbar {
      width: 5px;
      background-color: var(--grey-900);
      border-left: none;
      padding-left: 5px;
      position: absolute;
      left: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
    }
  }
}

.title-section {
  color: var(--white);
  text-transform: uppercase;
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
  margin-left: 0.8rem;
  opacity: 0.6;
  display: none;
  transition: opacity 250ms;

  &:first-child {
    margin-top: 0.4rem;
  }

  @media (--tablet) {
    display: block;
  }

  &.is-collapse {
    opacity: 0;
    width: 0;
  }
}

:deep(.dropdown-wrapper) {
  width: 100%;
}

.mobile-overlay {
  opacity: 0;
  transform: translateX(-100%);
  display: none;

  &.is-visible {
    display: block;
    position: fixed;
    background-color: rgb(0 0 0 / 50%);
    height: 100%;
    width: 100%;
    z-index: 300;
    top: var(--header-touch-size);
    opacity: 1;
    transition: opacity 400ms ease-out;
    transform: translateX(0);

    @media (--tablet) {
      display: none;
    }
  }

  @media (--tablet) {
    display: none;
  }
}

:deep(.avatar) {
  border: 2px solid var(--primary-500);
}

.btn-avatar {
  padding: 4px !important;

  & .has-max-width {
    width: 160px;
  }
}

:deep(.router-link-exact-active) {
  background: var(--grey-700) !important;
}
</style>
