<template>
  <wb-card
    filled
  >
    <template #content>
      <wb-empty-state
        :title="i18n.t('mywb.user.add-user-into-group')"
        :subtitle="i18n.t('mywb.user.send-invitation')"
      >
        <template #image>
          <client-img-component
            inline
            src="users"
            class="has-offset"
            width="180"
          />
        </template>

        <wb-button
          v-if="permissions.canAddUser"
          :label="i18n.t('mywb.user.add-user')"
          data-test-id="addUserButtonEmptyState"
          icon="person_add"
          variant="primary"
          @click="methods.goToAddUser()"
        />
      </wb-empty-state>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import ClientImgComponent from '@/components/ClientImgComponent.vue'

const i18n = useI18n()

interface Events {
  (e: 'add-user'): void
}
const emit = defineEmits<Events>()

const methods = {
  goToAddUser () {
    emit('add-user')
  }
}
</script>

<style lang="postcss" scoped>
.has-offset {
  position: relative;
  left: 30px;
}
</style>
