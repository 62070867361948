<template>
  <wb-modal
    width="33"
    @close="emit('on-close')"
  >
    <wb-form>
      <wb-input
        v-model="field.name"
        type="text"
        name="name"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="data.loading"
          @click="validate(methods.editName)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useValidator } from '@/hooks/useValidator.hook'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { ensureUpdateChargerNameIsValid, type ChargerUpdate } from '@/core/charger'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const { errors, validateTo, validate } = useValidator()

interface Events {
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const field = reactive<ChargerUpdate>({
  uid: state.charger.getChargerCore.uid,
  name: state.charger.getChargerCore.name
})

interface DataType {
  loading: boolean
}

validateTo(async () => !!await ensureUpdateChargerNameIsValid(field))

const data: DataType = reactive({
  loading: false
})

const methods = {
  async editName () {
    data.loading = true
    try {
      await chargerUseCases.updateCharger(field)
      state.charger.set('charger.name', field.name)
      trackDataEvent('change-name-charger', { charger_id: state.charger.getChargerCore?.id })
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('on-close')
    }
  }
}
</script>
