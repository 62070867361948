import type { CountryIso2, CountryIso3, CurrencyCodes } from '@/core/international'
import type { EnumConnectivityType } from '@/utilities/charger/chargerConnectivity'
import type { FiltersQuery, SortQuery } from '@/core/shared'
import type { HttpService } from '@wallbox/http'

export interface ChargerInfo {
  id: string
  type: 'charger_info'
  attributes: {
    max_amps: number
    min_amps: number
    power_type: 'AC' | 'DC'
    software_current_version: string
    software_last_updated_at: number
    software_latest_version: string
  }
}

export interface ChargerConfig {
  id: string
  type: 'charger_config'
  attributes: {
    auto_lock: boolean
    auto_lock_time: number
    connectivity_type: EnumConnectivityType
    current_amps: number
    locked: 0 | 1
    mid_enabled: boolean
    ocpp_protocol: string | null
    operation_mode: 'wallbox' | 'ocpp'
    remote_action_id: number
    eco_smart_enabled: boolean
    power_boost_enabled: boolean
    dynamic_power_sharing_enabled: boolean
    software_auto_update_enabled: boolean
  }
}

export interface ChargerStatus {
  id: string
  type: 'charger_status'
  attributes: {
    connectivity_signal: number
    mid_status: number
    ocpp_status: number
    status: number
    user_socket_locking: boolean
    power_sharing_status: number
  }
}

export interface ChargerApi {
  meta: {
    count: number
  }
  data: Array<{
    id: string
    attributes: {
      image: string
      location_id: string
      location_name: string
      model: string
      model_name: string
      name: string
      organization_id: string
      part_number: string
      pay_per_charge_enabled: boolean
      pay_per_month_enabled: boolean
      puk: number
      serial_number: string
      status: number
      timezone: string
      unique_identifier: string
      connector_type: string
      connection_status: 'offline' | 'online'
      last_connection: number
      software_update_available: boolean
    }
    relationships: {
      charger_info: {
        data: {
          id: string
        }
      }

      charger_config: {
        data: {
          id: string
        }
      }

      charger_status: {
        data: {
          id: string
        }
      }
    }
  }>

  included: Array<ChargerInfo | ChargerConfig | ChargerStatus>
}

export interface ChargerConfigOld {
  charger_id: number
  uid: string
  serial_number: string
  name: string
  locked: number
  auto_lock: 0 | 1
  auto_lock_time: number
  multiuser: number
  max_charging_current: number
  energyCost: {
    value: number
    inheritedGroupId: string | null
  }
  currency: {
    id: number
    name: string
    symbol: string
    code: CurrencyCodes
  }
  charger_load_type: string
  country: {
    id: number
    code: CountryIso3
    iso2: CountryIso2
    name: string
    phone_code: string
  }
  state: {
    label: string
    name: string
    iso2: string
  }
  part_number: string
  software: {
    updateAvailable: boolean
    currentVersion: string
    latestVersion: string
  }
  operation_mode: 'wallbox' | 'ocpp'
  ocpp_ready: boolean | null
  mid_enabled: number
  mid_status: number
  group_id: number
  user_socket_locking: boolean
  ocpp_connection_status: number
  status: number
  power_sharing_config: number
  error: boolean
  remote_action: number
  contract_charging_available: boolean
  timezone: string
  home_sharing: boolean
  ecosmart: {
    enabled: boolean
  } | null
}

interface ActiveChargerSession {
  meta: {
    count: number
  }
  data: Array<{
    id: string
    attributes: {
      charging_power: number
      charging_energy: number
      discharging_energy: number
      green_energy: number
      grid_energy: number
      start_time: number
      charging_time: number
      has_user_charger_access: boolean
    }
    relationships?: {
      user: {
        data: {
          id: string
        }
      }
    }
  }>

  included: Array<{
    id: string
    type: 'user'
    attributes: {
      avatar: string
      name: string
      surname: string
    }
  }>
}

export default {
  async getChargers (
    api: HttpService,
    organizationId: string,
    offset = 0,
    limit = 5000,
    include = 'charger_info,charger_config,charger_status',
    filters?: FiltersQuery['filters'],
    sorts?: SortQuery['sort']
  ): Promise<ChargerApi | undefined> {
    const params: Record<string, number | string | undefined> = {
      limit,
      offset,
      include,
      filters: filters ? JSON.stringify(filters) : undefined
    }

    if (sorts && sorts.length > 0) {
      const sort = sorts.reduce((carry: string[], current) => {
        const operator = (current.sort === 'desc' ? '-' : '')

        if (current.key === 'status') {
          carry.push(`${operator}connection_status`)
        }
        carry.push(`${operator}${current.key}`)

        return carry
      }, [])

      params.sort = sort.join(',')
    }

    return await api.get(
      '/organizations/{organizationId}/chargers'.replace('{organizationId}', organizationId),
      {
        params
      }
    )
  },

  async getChargerActiveSessions (
    api: HttpService,
    organizationId: string,
    filters?: { chargerId?: string }
  ): Promise<ActiveChargerSession | undefined> {
    const filtersBackend = [
      filters?.chargerId && {
        field: 'charger_id',
        operator: 'in',
        value: filters.chargerId
      }
    ].filter(Boolean)

    return await api.get(
      '/organizations/{organizationId}/charger_active_sessions'.replace('{organizationId}', organizationId),
      {
        params: {
          include: 'user',
          filters: filtersBackend.length > 0 ? JSON.stringify(filtersBackend) : undefined
        }
      }
    )
  }
}

export interface ApiChargerCreateResponse {
  chargersAdded: number[]
  errors: Array<{
    serial: string
    code: number
    msg: string
  }>
}

export interface ApiGetLocationIdResponse {
  data: {
    attributes: {
      value: number
    }
  }
}

export interface OcppSettings {
  address: string
  chargePointIdentity: string
  connectionStatus: number
  hasSslCertificate: boolean
  password?: string
  type: 'wallbox' | 'ocpp'
}

export interface GetChargerInfo {
  data: ChargerInfo[]
  meta: {
    count: number
  }
}

export interface GetChargerServiceabilities {
  meta: {
    count: number
  }
  data: [
    {
      type: 'charger_serviceability'
      id: string
      attributes: {
        user_uid: string
        charger_uid: string
        partner_id: number
        partner_name: string
        status: 'pending' | 'accepted' | 'rejected'
        created_at: number
        updated_at: number
      }
    }
  ]
}
