<template>
  <wb-card
    data-test-id="charging-status-card"
    no-padding
  >
    <template #content>
      <div class="px-24 py-16 status-wrapper">
        <div>
          <p
            v-t="'mywb.common.now'"
            class="is-size-300 is-font-weight-500 now"
          />
          <p class="is-size-900 is-font-weight-500 label" data-test-id="status-title">
            {{ i18n.t(state.charger.getChargerCore.status.label) }}
          </p>
          <p
            class="is-size-400 is-font-weight-400 description"
            data-test-id="status-subtitle"
          >
            <template v-if="state.charger.getChargerCore.status.code === STATUSES.WAITING">
              {{ i18n.t(statusWaitingById(state.charger.getChargerCore.status.id)) }}
            </template>
            <template v-else>
              {{ i18n.t(state.charger.getChargerCore.status.description) }}
            </template>
          </p>
        </div>

        <charger-remote-actions class="mt-16" />
      </div>

      <wb-separator no-padding />

      <div class="charger-control-data px-24 pt-16" :class="{ 'is-charging' : compute.isChargerCharging }">
        <div class="energy-flow">
          <div class="energy-flow-sources">
            <charger-control-flow-template
              :is-charging="compute.isChargerCharging"
              :has-green-energy="compute.hasGreenEnergy"
            >
              <template #gridEnergy>
                <div class="energy-source-name">
                  <span class="wb-icons">transmission_tower</span>
                  <p v-t="'mywb.common.grid'" />
                </div>
              </template>

              <template #greenEnergy>
                <div
                  class="energy-source-name"
                  :class="{ 'has-green-empty': !compute.hasGreenEnergy }"
                >
                  <span class="wb-icons">leaf</span>
                  <p v-t="'mywb.common.green'" />
                </div>
              </template>

              <template #carPower>
                <div class="energy-flow-car">
                  <div class="energy-source">
                    <div class="energy-source-power">
                      <span data-test-id="power" class="is-font-weight-700">
                        {{ compute.power }}
                      </span>
                      <span class="is-size-300 ml-4">{{ i18n.t('mywb.common.kw') }}</span>
                    </div>
                  </div>
                </div>
              </template>

              <template #car>
                <div class="energy-source">
                  <div class="energy-source-name">
                    <span class="wb-icons car-icon">car</span>
                    <p v-t="'mywb.common.vehicle'" />
                  </div>
                </div>
              </template>
            </charger-control-flow-template>
          </div>
        </div>
      </div>

      <wb-separator v-if="compute.isChargerActive" no-padding />

      <transition-group name="smooth">
        <div
          v-if="compute.isChargerActive"
          class="has-background-blue-700 has-text-white px-24 charging-info is-size-300"
        >
          <p
            v-t="'mywb.common.since-you-plugged-in'"
            class="is-font-weight-500 mb-16 mt-16"
          />

          <div class="is-flex mb-12 mt-16">
            <p data-test-id="energy">
              {{ data.currentSession?.chargingEnergy ?? 0 }} {{ i18n.t('mywb.common.kwh') }}
            </p>
            <p
              class="wb-icons icon_expand"
              :class="{ rotate: data.showDetails }"
              @click="data.showDetails = !data.showDetails"
            />
          </div>
          <transition-group name="smooth">
            <template v-if="data.showDetails">
              <p
                v-t="'mywb.common.energy-from-grid'"
                class="bullet has-background-blue-700"
              />
              <p class="has-background-blue-700">
                {{ data.currentSession?.gridEnergy ?? 0 }} {{ i18n.t('mywb.common.kwh') }}
              </p>

              <p
                v-t="'mywb.common.green-energy-sef-generated'"
                class="bullet bullet_green has-background-blue-700"
                :class="{ 'has-green-empty': !compute.hasGreenEnergy }"
              />
              <p
                class="has-text-success-500 has-background-blue-700"
                :class="{ 'has-green-empty': !compute.hasGreenEnergy }"
              >
                {{ data.currentSession?.greenEnergy }} {{ i18n.t('mywb.common.kwh') }}
              </p>
            </template>
          </transition-group>

          <p v-t="'mywb.common.time'" />
          <p data-test-id="time">
            {{ time.getTimeDurationString(data.currentSession?.chargingTime ?? 0, ['h', 'm']) }}
          </p>

          <p class="mb-16">
            {{ i18n.t('mywb.common.cost') }} ({{ compute.energyCost }} / {{ i18n.t('mywb.common.kwh') }})
          </p>
          <p data-test-id="cost">
            {{ compute.costSincePlugIn }}
          </p>
        </div>

        <div v-if="compute.isChargerActive && data.currentSession?.user" class="px-24 pb-16">
          <div
            v-t="'mywb.common.user-charging'"
            class="is-size-300 is-font-weight-500 mt-16"
          />

          <wb-button-card
            data-test-id="user"
            :title="data.currentSession.user.name + ' ' + data.currentSession.user.surname"
            filled
            :action="data.currentSession.user.uid && data.currentSession.user.hasAccess ? 'emit' : undefined"
            @on-click="compute.isOwnerUser
              ? router.push({ name: ROUTES.ACCOUNT })
              : data.currentSession?.user && methods.handleClickUser(data.currentSession.user)"
          >
            <template #icon>
              <wb-user-avatar
                size="small"
                :src="data.currentSession.user.avatar"
                :initials="userInitials(data.currentSession.user)"
              />
            </template>
          </wb-button-card>
        </div>
      </transition-group>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive, computed, watch, onBeforeUnmount } from 'vue'
import ChargerControlFlowTemplate from '@/components/charger/ChargerControlFlowTemplate.vue'
import ChargerRemoteActions from '@/components/charger/ChargerRemoteActions.vue'

import { numbers, time } from '@wallbox/toolkit-ui'
import { STATUSES, getColorByStatus, statusWaitingById } from '@/utilities/charger/chargerStatuses'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { userInitials } from '@/utilities/users'
import { useRouter } from 'vue-router'
import type { ChargerActiveSession } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()
const router = useRouter()

const props = defineProps({
  hasRealTimeInformation: {
    type: Boolean,
    default: true
  }
})

interface DataType {
  currentSession?: ChargerActiveSession
  unmounted: boolean
  reloadChargersInterval?: ReturnType<typeof setInterval>
  showDetails: boolean
  energyCost: number
}

const data: DataType = reactive({
  currentSession: undefined,
  unmounted: false,
  reloadChargersInterval: undefined,
  showDetails: false,
  energyCost: 0
})

const compute = reactive({
  color: computed(() => {
    const colors = getColorByStatus(state.charger.getChargerCore.status.code)

    return colors.bg
  }),

  power: computed(() => {
    const energy = data.currentSession?.chargingPower ?? 0
    const decimals = energy % 1 !== 0 ? 3 : 0
    return numbers.toDecimal(energy, i18n.locale.value, decimals, decimals)
  }),

  isChargerActive: computed((): boolean => {
    if (!state.charger.getChargerCore.status?.code) return false
    return [
      STATUSES.WAITING,
      STATUSES.SCHEDULED,
      STATUSES.CHARGING,
      STATUSES.DISCHARGING,
      STATUSES.PAUSED
    ].includes(state.charger.getChargerCore.status.code)
  }),

  isChargerCharging: computed((): boolean => {
    if (!state.charger.getChargerCore.status?.code) return false
    return [
      STATUSES.CHARGING,
      STATUSES.DISCHARGING
    ].includes(state.charger.getChargerCore.status.code)
  }),

  energyCost: computed((): string => {
    return numbers.toLocaleCurrencySymbol(
      data.energyCost,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  }),

  costSincePlugIn: computed((): string => {
    return numbers.toLocaleCurrencySymbol(
      data.currentSession?.chargingEnergy
        ? data.currentSession.chargingEnergy * data.energyCost
        : 0,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  }),

  hasGreenEnergy: computed((): boolean => {
    return compute.isChargerCharging && (data.currentSession?.greenEnergy ?? 0) > 0
  }),

  isOwnerUser: computed(() => data.currentSession?.user?.uid === state.user.userLogged.id)
})

const methods = {
  async enableAutoRefresh () {
    if (compute.isChargerActive) {
      data.currentSession = await chargerUseCases.getChargerActiveSession(
        state.organizations.getCurrentOrganization.groupUid,
        state.charger.getChargerCore.uid
      )

      if (!data.unmounted) {
        data.reloadChargersInterval = setInterval(async () => {
          data.currentSession = await chargerUseCases.getChargerActiveSession(
            state.organizations.getCurrentOrganization.groupUid,
            state.charger.getChargerCore.uid
          )
        }, 5000)
      }
    }
  },

  async handleClickUser (user: ChargerActiveSession['user']) {
    if (user.uid && user.hasAccess) {
      router.push({ name: ROUTES.USER_DETAIL, params: { uid: user.uid } })
    }
  },

  cancelAutoRefresh () {
    clearInterval(data.reloadChargersInterval)
  }
}

onBeforeUnmount(() => {
  data.unmounted = true
  clearInterval(data.reloadChargersInterval)
})

watch(() => state.charger.getChargerCore.status?.code, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
}, { immediate: true })

watch(() => props.hasRealTimeInformation, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
})

async function created () {
  data.energyCost = (await chargerUseCases.getChargerEnergyCost(state.charger.getChargerCore.uid)) ?? 0
}

created()
</script>

<style lang="postcss" scoped>
.charger-control-data {
  position: relative;
  overflow: hidden;

  .energy-flow {
    align-items: center;
    margin: 1rem 0;
    gap: 0.5rem;
    transition: 0.45s ease-in-out;

    .energy-source-power {
      font-size: 2rem;

      .is-charging {
        background: var(--blue-900);
        color: white;
        border: 1px solid var(--blue-900);
      }
    }
  }

  .energy-source {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    .energy-source-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 40px;
    }

    .energy-source-power {
      border: 1px solid var(--grey-200);
      border-radius: 4rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      background: var(--white);
      color: var(--black);
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: var(--blue-700);
    border-radius: 99px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 300ms ease-in-out;
  }

  &.is-charging {
    color: var(--white);

    &::before {
      transform: translate(-50%, -50%) scale(999);
    }

    .energy-source-power {
      border: 1px solid var(--blue-900);
      background: var(--blue-900);
      color: var(--white);
    }
  }
}

.bullet {
  &::before {
    content: '';
    display: inline-block;
    position: relative;
    top: -2px;
    width: 6px;
    height: 6px;
    border-radius: 99px;
    background: var(--white);
    margin-right: 4px;
  }

  &.bullet_green {
    &::before {
      background: var(--success-500);
    }
  }
}

.icon_expand {
  cursor: pointer;
  transition: transform 200ms ease-in;

  &.rotate::after {
    transform: rotateX(180deg);
  }

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 24px;
    margin-left: 16px;
    opacity: 0.4;
    background: var(--white);
  }

  &::after {
    transition: transform 200ms ease-in;
    content: 'expand_more';
    display: inline-block;
    width: 100%;
    margin-left: 8px;
  }
}

.has-green-empty {
  opacity: 0.4;
}

.smooth-enter-active, .smooth-leave-active {
  transition: max-height 300ms ease-in-out;
}

.smooth-enter-from, .smooth-leave-to {
  max-height: 0;
}

.smooth-enter-to, .smooth-leave-from {
  max-height: 100px;
}

.description {
  grid-column: span 2;
}

.label {
  word-break: break-all;

  &::after {
    content: '';
    display: inline-block;
    position: static;
    width: 12px;
    height: 12px;
    border-radius: 99px;
    background: v-bind(compute.color);
    margin-left: 8px;
    margin-bottom: 3px;
  }
}

.charging-info {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 4px;

  > *:nth-child(even) {
    justify-self: flex-end;
  }
}

.status-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
