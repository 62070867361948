import user from './user.state'
import global, { loadState as loadGlobalState } from './global.state'
export type { GlobalState } from './global.state'
export type { FilterState } from './filters.state'

import plans from './plans.state'
import charger from './charger.state'
import organizations, { loadState as loadOrganizationsState } from './organizations.state'
import routes, { loadState as loadRoutesState } from './routes.state'
import filters from './filters.state'
import config from './config.state'
import rates from './rates.state'

export default {
  global,
  user,
  plans,
  charger,
  organizations,
  routes,
  filters,
  config,
  rates
}

export function loadStates () {
  loadGlobalState()
  loadOrganizationsState()
  loadRoutesState()
}
