<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500">
        <span
          v-t="'mywb.auth.welcome-back'"
          class="is-font-weight-700"
        />
        <br>
        <span v-t="'mywb.auth.password-to-continue'" />
      </p>
    </template>

    <wb-form class="auth-form">
      <wb-input
        ref="input"
        v-model="fields.password"
        :error="errors.password"
        type="password"
        :label="i18n.t('mywb.common.password')"
        data-test-id="passwordInput"
        :disabled="data.loading"
        @on-enter.prevent="validate(methods.login)"
      />

      <wb-button
        size="large"
        :label="i18n.t('mywb.common.continue')"
        data-test-id="loginCta"
        :disabled="data.loading"
        @click.prevent="validate(methods.login)"
      />

      <div class="is-flex justify-between">
        <wb-link
          data-test-id="backButton"
          class="is-size-400"
          type="text"
          @click="emit('back')"
        >
          <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
          <span>{{ i18n.t('mywb.common.back') }}</span>
        </wb-link>

        <wb-link
          class="is-size-400"
          data-test-id="forgotPasswordLink"
          @click="methods.handleForgotPassword"
        >
          {{ i18n.t('mywb.auth.forgotten-password') }}
        </wb-link>
      </div>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useWbInputAutofocus } from '@/hooks'
import { useToast } from '@wallbox/toolkit-ui'
import { trackAction, trackEvent } from '@wallbox/metrics'
import { getServerError } from '@/utilities/errorMessages'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { HttpError } from '@wallbox/http'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { ensureSignInIsValid, type SignIn } from '@/core/auth'
import { useValidator } from '@/hooks/useValidator.hook'
import ROUTES from '@/engine/router/routes'

const authUseCases = injectStrict(AUTH_USE_CASES)

const i18n = useI18n()
const toast = useToast()
const router = useRouter()
const { errors, validateTo, validate } = useValidator()

const props = defineProps({
  email: {
    type: String,
    required: true
  }
})

interface Events {
  (e: 'back'): void
}

const emit = defineEmits<Events>()

const fields = reactive<SignIn>({
  email: props.email,
  password: ''
})

validateTo(async () => !!await ensureSignInIsValid(fields))

const input = ref()
useWbInputAutofocus(input)

const data = reactive({
  loading: false
})

const methods = {
  async login () {
    data.loading = true
    trackAction('signin_submit', { provider: 'email', new_user: false })
    try {
      const userId = await authUseCases.doSignIn(fields)

      userId && state.global.setUserId(userId)
      trackEvent('signin_success', { user_id: userId, provider: 'email', new_user: false })
      methods.handleRedirect()
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }

      trackEvent('login-error', { type: 'email' })
    } finally {
      data.loading = false
    }
  },

  handleForgotPassword () {
    trackAction('forgot-password')
    router.push({ name: ROUTES.AUTH_FORGOT_PASSWORD, query: { email: props.email } })
  },

  handleRedirect () {
    const storage = localStorage.getItem('wb-redirection')
    const wbRedirection = storage && JSON.parse(storage)
    if (wbRedirection) {
      const route = { name: wbRedirection.name, query: wbRedirection.query }
      localStorage.removeItem('wb-redirection')
      router.push(route)
    } else {
      router.push({ name: ROUTES.DASHBOARD })
    }
  }
}
</script>

<style lang="postcss" scoped>
.justify-between {
  width: 100%;
  justify-content: space-between;
}
</style>
