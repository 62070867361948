import theseus from '@wallbox/theseus-ui'
import type { App } from 'vue'
import type { I18n } from '@/engine/lang'

export default function initToolkit (app: App, i18n: I18n) {
  app.use(theseus, {
    locale: {
      i18n
    }
  })
}
