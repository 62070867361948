<template>
  <div>
    <div
      class="grid"
      :class="{'grid-2-columns g-24': compute.hasStates && !props.isStateHidden}"
    >
      <wb-select
        v-model="compute.countryCode"
        name="country"
        uid="_country"
        data-test-id="countrySelect"
        :label="i18n.t('mywb.common.country')"
        option-label="name"
        :reduce="(country: Country) => country.iso3"
        :options="compute.countriesToShow"
        :disabled="props.loading || !state.global.getCountries.length || props.isCountryDisabled"
        :loading="!state.global.getCountries.length"
        :error="data.errors.country"
      >
        <template #label>
          <span>{{ i18n.t('mywb.common.country') }}</span>
        </template>
      </wb-select>

      <wb-select
        v-if="compute.hasStates && !props.isStateHidden"
        v-model="compute.stateCode"
        uid="_state"
        name="state"
        data-test-id="stateSelect"
        :label="i18n.t('mywb.common.state')"
        option-label="name"
        :reduce="(state: State) => state.iso2"
        :disabled="props.loading || !compute.hasStates || props.isStateDisabled"
        :options="data.optionsState"
        :error="data.errors.state || props.stateError"
        @change="methods.onChangeState"
      />
    </div>
    <p v-if="compute.disableReason" class="is-size-300 has-text-grey-500 mt-8">
      {{ compute.disableReason }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'

import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Country, CountryIso2, CountryIso3, State } from '@/core/international'
import { injectStrict, INTERNATIONAL_USE_CASES } from '@/engine/injectors'
import { useValidator } from '@/hooks/useValidator.hook'

const internationalUseCases = injectStrict(INTERNATIONAL_USE_CASES)
const i18n = useI18n()
const { yup } = useValidator()

interface PropsType {
  countryCode?: string
  stateCode?: string
  loading?: boolean
  isCountryDisabled?: boolean
  isStateHidden?: boolean
  isStateDisabled?: boolean
  stateName?: string
  countryKey?: keyof Country
  stateError?: string
  countriesToShow?: CountryIso2[]
}

const props = withDefaults(defineProps<PropsType>(), {
  countryCode: undefined,
  stateCode: undefined,
  stateName: undefined,
  countryKey: 'iso3',
  stateError: undefined,
  countriesToShow: undefined
})

interface Events {
  (e: 'update:countryCode', code: CountryIso3 | CountryIso2 | undefined): void,
  (e: 'update:stateCode', code: string | undefined): void,
  (e: 'update:stateName', name: string | undefined): void,
  (e: 'on-update', payload: { hasStates: boolean }): void
}

const emit = defineEmits<Events>()

interface Data {
  errors: {
    country?: string
    state?: string
  }
  optionsState: State[]
}

const data: Data = reactive({
  errors: {},
  optionsState: []
})

const compute = reactive({
  countriesToShow: computed(() => {
    if (props.countriesToShow) {
      return props.countriesToShow
        .map(iso2 => state.global.getCountries.find(country => country.iso2 === iso2)) as Country[]
    }

    return state.global.getCountries
  }),

  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.paymentsAccount),

  hasStates: computed(() => !!data.optionsState.length),

  countryCode: computed({
    get () {
      return methods.findCountryValue(props.countryCode, props.countryKey, 'iso3')
    },

    set (value: CountryIso3 | CountryIso2 | undefined) {
      if (value) {
        emit('update:countryCode', methods.findCountryValue(value, 'iso3', props.countryKey))
      }
    }
  }),

  stateCode: computed({
    get () {
      return props.stateCode
    },
    set (value: string | undefined) {
      if (value) {
        emit('update:stateCode', value)
        emit('update:stateName', methods.findState(value))
      }
    }
  }),

  disableReason: computed(() => {
    if (props.isCountryDisabled) {
      return i18n.t('mywb.common.country-field-cannot-be-change')
    }
    return ''
  })
})

const methods = {
  async isValidCountry () {
    let countryValidation = false
    try {
      await yup.mixed().required().validate(compute.countryCode)
      countryValidation = true
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        data.errors.country = error.errors[0]
      }
    }

    return countryValidation
  },

  async areValidCountryAndState () {
    const countryValidation = await methods.isValidCountry()
    let stateValidation = true

    try {
      if (compute.hasStates) {
        await yup.mixed().required().validate(compute.stateCode)
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        stateValidation = false
        data.errors.state = error.errors[0]
      }
    }
    return !!(countryValidation && stateValidation)
  },

  findState (iso2: string) {
    return data.optionsState.find(state => state.iso2 === iso2)?.name || ''
  },

  findCountryValue (
    value?: string | number,
    key: keyof Country = 'iso3',
    obtain: keyof Country = 'iso3'
  ) {
    const country = state.global.getCountries.find(country => country[key] === value)

    if (obtain === 'iso3') return country?.iso3

    return country?.iso2
  },

  async onChangeCountry (iso3: CountryIso3) {
    await methods.getStates(iso3)
    compute.stateCode = undefined
    data.errors.country = undefined
    emit('on-update', { hasStates: compute.hasStates })
  },

  onChangeState () {
    data.errors.state = undefined
    emit('on-update', { hasStates: compute.hasStates })
  },

  async getStates (iso3: CountryIso3) {
    data.optionsState = data.optionsState = await internationalUseCases.getAllStates(iso3)
  }
}

watch(() => compute.countryCode, async (value, oldValue) => {
  if (value) {
    await methods.getStates(value as CountryIso3)
    emit('on-update', { hasStates: compute.hasStates })
    if (oldValue && value !== oldValue) {
      compute.stateCode = undefined
    }
  }
}, { immediate: true })

defineExpose({
  isValidCountryState: methods.areValidCountryAndState,
  isValidCountry: methods.isValidCountry
})
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr auto;
  }
}

.grid-2-columns {
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.is-label-icon {
  font-size: 1.1rem;
  margin-left: 1rem;
  top: 2px;
  position: relative;
}
</style>
