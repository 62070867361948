<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500">
        <span
          v-t="'mywb.auth.login-signup'"
          class="is-font-weight-700"
        />
        <span>&nbsp;</span>
        <span v-t="'mywb.auth.login-signup-explain'" />
      </p>
    </template>

    <wb-form class="auth-form">
      <wb-input
        ref="input"
        v-model="fields.email"
        :label="i18n.t('mywb.common.email')"
        placeholder="example@email.com"
        :error="errors.email"
        data-test-id="emailInput"
        :disabled="data.loading"
        @on-enter="validate(methods.continueLogin)"
      />

      <wb-button
        size="large"
        :label="i18n.t('mywb.common.continue')"
        data-test-id="emailCtaButton"
        :disabled="data.loading"
        @click.prevent="validate(methods.continueLogin)"
      />

      <div
        class="is-flex"
        :class="permissions.hasSocialSingin ? 'justify-between': 'center'"
      >
        <wb-link
          v-if="permissions.hasSocialSingin"
          data-test-id="backButton"
          class="is-size-400"
          type="text"
          @click="emit('back')"
        >
          <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
          <span>{{ i18n.t('mywb.common.back') }}</span>
        </wb-link>

        <wb-link
          class="is-size-400"
          data-test-id="forgotPasswordLink"
          @click="methods.handleForgotPassword"
        >
          {{ i18n.t('mywb.auth.forgotten-password') }}
        </wb-link>
      </div>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useWbInputAutofocus } from '@/hooks'
import { trackAction, trackScreen } from '@wallbox/metrics'
import { useToast } from '@wallbox/toolkit-ui'
import { getServerError } from '@/utilities/errorMessages'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import { HttpError } from '@wallbox/http'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { USER_EMAIL_STATUS, ensureEmailCheckIsValid, type CheckUserEmail } from '@/core/auth'
import { useValidator } from '@/hooks/useValidator.hook'
import ROUTES from '@/engine/router/routes'

const authUseCases = injectStrict(AUTH_USE_CASES)
const i18n = useI18n()
const toast = useToast()
const router = useRouter()
const { errors, validateTo, validate } = useValidator()

interface Events {
  (e: 'continue-password', name: string): void,
  (e: 'back'): void,
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false
})

const fields = reactive<CheckUserEmail>({
  email: ''
})

validateTo(async () => !!await ensureEmailCheckIsValid(fields))

const input = ref()
useWbInputAutofocus(input)

const methods = {
  async continueLogin () {
    data.loading = true
    try {
      if (!fields.email) return

      const status = await authUseCases.getIsUserEmailRegistered(fields)

      if (status === USER_EMAIL_STATUS.CONFIRMED) {
        emit('continue-password', fields.email)
      } else if (status === USER_EMAIL_STATUS.NOT_REGISTERED) {
        trackScreen('register', { type: 'email' })
        router.push({ name: ROUTES.AUTH_REGISTER, query: { email: fields.email } })
      } else if (status === USER_EMAIL_STATUS.NOT_CONFIRMED) {
        toast.error(getServerError({ code: status }))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    } finally {
      data.loading = false
    }
  },

  handleForgotPassword () {
    trackAction('forgot-password')
    router.push({ name: ROUTES.AUTH_FORGOT_PASSWORD })
  }
}
</script>

<style lang="postcss" scoped>
.justify-between,
.center {
  width: 100%;
}

.justify-between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}
</style>
