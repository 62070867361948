<template>
  <wb-skeleton-loader
    :type="mq.current !== 'mobile' ? 'page' : 'dots'"
    :loading="data.loading"
  >
    <locations-empty-state
      v-if="!permissions.canSeeLocations || permissions.showUpSellingToPlanBusiness || !data.locations.length"
      @on-add-group="methods.addLocation"
    />

    <div v-else class="locations-view">
      <locations-header
        v-model:show-map="data.showMap"
        :locations="data.locations"
        @on-add-group="methods.addLocation"
      />

      <maps-location
        v-if="data.showMap"
        :locations="compute.locationsFiltered"
        @on-edit="methods.editLocation"
        @on-delete="methods.handleLocationDeleteConfirmation"
      />

      <locations-table
        :loading="data.loading"
        :locations="compute.locationsFiltered"
        @on-edit="methods.editLocation"
        @on-delete="methods.handleLocationDeleteConfirmation"
      />
    </div>

    <location-create-modal
      v-if="isCurrentState('modalCreateLocation')"
      data-test-id="chargersGroupEditModal"
      :location-id="data.currentLocation?.id"
      @on-close="send('close')"
      @on-edit-finish="created()"
    />

    <shared-confirmation-modal
      v-if="isCurrentState('modalDeleteWithoutChargers')"
      :title="i18n.t('mywb.common.delete-confirmation', { element: data.currentLocation?.name })"
      :label-confirmation-button="i18n.t('mywb.common.delete')"
      type="danger"
      @on-confirm="methods.handleLocationDelete"
      @on-close="send('close')"
    />

    <location-with-chargers-delete-modal
      v-if="isCurrentState('modalDeleteWithChargers') && data.currentLocation?.chargers"
      :location="data.currentLocation"
      :locations="data.locations"
      @on-confirm="created"
      @on-close="send('close')"
    />
  </wb-skeleton-loader>
</template>

<script setup lang="ts">
import LocationsEmptyState from '@/components/emptyStates/LocationsEmptyState.vue'
import LocationsHeader from '@/components/headers/LocationsHeader.vue'
import LocationCreateModal from '@/components/modals/LocationCreateModal.vue'
import LocationsTable from '@/components/tables/LocationsTable.vue'
import MapsLocation from '@/components/locations/MapsLocation.vue'
import LocationWithChargersDeleteModal from '@/components/modals/LocationWithChargersDeleteModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { computed, reactive } from 'vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useStateMachine } from '@wallbox/hooks'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Location } from '@/core/location'
import { useRoute, useRouter } from 'vue-router'
import { LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import { useMq } from 'vue3-mq'

const usesCases = injectStrict(LOCATION_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const mq = useMq()

interface DataType {
  loading: boolean
  showMap: boolean
  currentLocation?: Location
  search: string
  locations: Location[]
}
const data: DataType = reactive({
  loading: false,
  showMap: true,
  search: '',
  locations: []
})

const { isCurrentState, send } = useStateMachine({
  initial: 'list',
  states: {
    list: {
      transitions: {
        deleteWithChargers: 'modalDeleteWithChargers',
        deleteWithoutChargers: 'modalDeleteWithoutChargers',
        createLocation: 'modalCreateLocation'
      }
    },

    modalDeleteWithChargers: {
      transitions: {
        close: 'list'
      }
    },

    modalDeleteWithoutChargers: {
      transitions: {
        close: 'list'
      }
    },

    modalCreateLocation: {
      transitions: {
        close: 'list'
      }
    }
  }
})

const compute = reactive({
  locationsFiltered: computed((): Location[] => {
    const reducers = {
      locations: methods.filterByLocation,
      text: methods.filterByText
    }

    const entries = Object.entries(state.filters.locationsFilters) as Array<[keyof typeof reducers, string & number]>

    return entries.reduce((locationsFiltered, [type, value]) =>
      reducers[type](locationsFiltered, value), data.locations
    )
  })
})

const methods = {
  filterByText (locations: Location[], text: string) {
    if (!text) return locations

    return locations.filter(location => {
      return (
        location.name.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  filterByLocation (locations: Location[], locationsFiltered: string[]) {
    if (!locationsFiltered?.length) return locations

    return locations.filter(location => locationsFiltered.includes(location.id))
  },

  async addLocation () {
    data.currentLocation = undefined

    send('createLocation')
    trackDataAction('add-location')
  },

  async editLocation (location: Location) {
    send('createLocation')
    data.currentLocation = location
    trackDataAction('edit-location', { location_id: location.id })
  },

  handleLocationDeleteConfirmation (location: Location) {
    data.currentLocation = location

    if (location.chargers?.length) {
      send('deleteWithChargers')
    } else {
      send('deleteWithoutChargers')
    }
  },

  async handleLocationDelete () {
    if (!data.currentLocation?.id) return

    try {
      data.locations = data.locations.filter(location => location.id !== data.currentLocation?.id)
      toast.success(i18n.t('mywb.common.location-removed-successfully'))
      trackDataAction('delete-location', { location_id: data.currentLocation?.id })
      await usesCases.deleteLocation(data.currentLocation.id)
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

async function created () {
  if (!permissions.canSeeLocations) return

  if (route.query?.['add-location']) {
    methods.addLocation()
    router.push({ query: {} }).catch(() => {})
  }

  data.loading = true
  data.locations = await usesCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
    getChargers: true,
    cache: 'stale'
  })

  usesCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
    getChargers: true,
    cache: 'network'
  }).then(locations => {
    data.locations = locations
  })

  data.loading = false

  const showMapStoraged = localStorage.getItem('wb-show-locations-map')
  if (showMapStoraged) {
    data.showMap = localStorage.getItem('wb-show-locations-map') === 'true'
  } else {
    data.showMap = data.locations.some(location => (location.latitude && location.longitude))
  }
}

created()
</script>

<style lang="postcss">
.locations-view {
  display: grid;
  height: 100%;
}
</style>
