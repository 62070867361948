<template>
  <auth-terms-and-conditions
    data-test-id="AuthTermsAndConditions"
    @back="emit('back')"
    @terms-accept="methods.finishRegister"
  />
</template>

<script setup lang="ts">
import AuthTermsAndConditions from '@/components/auth/AuthTermsAndConditions.vue'
import { reactive } from 'vue'
import { trackAction, trackError, trackEvent } from '@wallbox/metrics'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from '@wallbox/toolkit-ui'
import { getServerError } from '@/utilities/errorMessages'
import { clientConfig } from '@/engine/clients'
import state from '@/state'
import lang from '@/engine/lang'
import type { Auth } from '@/types'
import { HttpError } from '@wallbox/http'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import type { CountryIso2 } from '@/core/international'
import { ensureSignUpActivatedIsValid, ensureSignUpIsValid, type SignUp } from '@/core/auth'
import ROUTES from '@/engine/router/routes'

const authUseCases = injectStrict(AUTH_USE_CASES)
const route = useRoute()
const router = useRouter()
const toast = useToast()

type Events = {
  (e: 'finish-register'): void,
  (e: 'back'): void
  (e: 'error', error: string): void
}

const emit = defineEmits<Events>()

interface PropsType {
  user: Auth.UserInRegister
}

const props = defineProps<PropsType>()

const data = reactive({
  loading: false
})

const methods = {
  async finishRegister ({ terms, countryCode }: { terms: SignUp['terms'], countryCode: CountryIso2 }) {
    try {
      if (route.query.confirmToken) {
        const user = {
          ...props.user,
          terms,
          countryIso2: countryCode,
          language: lang.__rootLanguage,
          clientType: clientConfig.brandingId,
          activationCode: route.query.confirmToken.toString()
        }

        if (!ensureSignUpActivatedIsValid(user)) return
        data.loading = true

        trackAction('signin_submit', { provider: 'email', new_user: false })

        const userId = await authUseCases.doSignUpActivated(user)

        trackEvent('signin_success', { user_id: userId, new_user: false })
        userId && state.global.setUserId(userId)

        router.push({ name: ROUTES.DASHBOARD })
      } else {
        const user = {
          ...props.user,
          terms,
          countryIso2: countryCode,
          language: lang.__rootLanguage,
          clientType: clientConfig.brandingId
        }

        if (!ensureSignUpIsValid(user)) return
        data.loading = true

        trackAction('signin_submit', { provider: 'email', new_user: true })

        const id = authUseCases.doSignUp(user)

        trackEvent('signin_success', {
          id,
          accept_marketing: terms.marketing,
          accept_policy: terms.policy,
          accept_profile: terms.profile,
          new_user: true
        })

        emit('finish-register')
      }
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handleError(error)
      }
      trackError('register-error', { error })
    }

    data.loading = false
  },

  async handleError (error: HttpError) {
    if (error.status === 409) {
      emit('error', 'user-already-registered')
    } else {
      toast.error(getServerError(error))
    }
  }
}
</script>
