import i18n from '@/engine/lang'
import { EnumRoles } from '@/utilities/user-roles'

import { and, hasRole, isOwner } from '../validators'
import type { Client } from './client'
import { toLocaleUnit } from '@/core/international'

const iberdrola: Client = {
  name: 'iberdrola',
  partner: 'iberdrola',
  brandingId: 'iberdrola',
  actions: {
    editRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showSelectedChargers: false,
    canChangePlan: false,
    canSeeSupport: true,
    showPayments: false,
    showBusinessPage: false,
    showBilling: false,
    showAutoReporting: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canEditChargerName: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasBulkActions: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasAuthToRestartAndUpdate: hasRole(EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator),
    canMarkAsPaid: false,
    canAssignedRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canSeeRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCharge: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeChargerDetail: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddChargerFromEmptyState: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddCharger: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canWriteEnergyCost: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeLocations: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canViewUpdateChargersPending: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCreateUserGroups: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    isDisabledSelectAccessGroup: hasRole(EnumRoles.user, EnumRoles.admin),
    showUserGroupOptions: true,
    canSeeDashboard: true,
    canSeeUsers: true,
    canAddUser: true,
    canEditAndDeleteUserGroupOption: true,
    canAddRfidInInvitations: true,
    canUserListRfidEdit: true,
    canUserListRfidActions: true,
    canRevokeOrResendUserInvitation: hasRole(EnumRoles.operator, EnumRoles['super-admin']),
    hasSocialSingin: false,
    showPaymentsEngage: false,
    showUpSellingToPlanBusiness: false,
    canConnectStripe: false,
    canOperateOrganizations: hasRole(EnumRoles.operator),
    showCarouselLogin: false,
    canAddOperators: and(
      isOwner(),
      hasRole(EnumRoles.operator)
    ),
    canSeeModalEditOrganization: false,
    canAddChargePoints: false
  },

  energyManagmentSupport: 'https://support.wallbox.com/en/knowledge-base/energy-management-solutions/',

  ecommerceUrl: '',

  docs: {
    terms: 'https://www.iberdrola.es/webclipb/gc/prod/es_ES/Wallbox.pdf'
  },

  logo: {
    login: {
      src: 'logos/logo-iberdrola.svg',
      style: 'width: 75%; max-width: 200px;',
      alt: 'myWallbox'
    },
    aside: { src: 'logos/logo-iberdrola-inverted.svg', style: 'height: 12rem;', alt: 'Wallbox' },
    asideCollapse: { src: 'logos/logo-iberdrola-inverted-collapse.svg', alt: 'Wallbox' },
    header: { src: 'logos/logo-wallbox-iberdrola.svg', style: 'height: 60px;', alt: 'iberdrola-logo' }
  },

  units: {
    volume: {
      unit: 'L',
      name: 'liters',
      formula: liters => toLocaleUnit({
        locale: i18n.locale,
        unit: 'liter',
        value: liters
      })
    },
    mass: {
      unit: 'Kg',
      name: 'Kilograms',
      formula: kilograms => toLocaleUnit({
        locale: i18n.locale,
        unit: 'kilogram',
        value: kilograms
      })
    }
  }
}

export default iberdrola
