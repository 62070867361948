<template>
  <wb-modal
    width="54"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.charger.ocpp-socket') }}
    </template>

    <wb-form>
      <wb-select
        v-model="data.ocppBackend"
        :options="knownOCPPBackendsOptions"
        :label="i18n.t('mywb.ocpp-platform')"
        @change="methods.handleOnOCPPBackendChange"
      >
        <template #option="{ option }">
          <div class="select-occp-option">
            <img v-if="option.imgSrc" :src="option.imgSrc" class="occp-option-img">
            <span>{{ option.label }}</span>
          </div>
        </template>

        <template #selected-option="{ option }">
          <div class="select-occp-option">
            <img v-if="option.imgSrc" :src="option.imgSrc" class="occp-option-img">
            <span>{{ option.label }}</span>
          </div>
        </template>
      </wb-select>

      <wb-input
        v-model="data.address"
        :label="i18n.t('mywb.common.url')"
        class="mb-16"
        :error="errors.address"
        name="address"
        :disabled="data.isUrlDisabled"
        data-test-id="urlInput"
      />
      <wb-input
        v-model="data.chargePointIdentity"
        :label="i18n.t('mywb.charger.ocpp.charge-point-identity')"
        class="mb-16"
        :error="errors.chargePointIdentity"
        name="user"
        data-test-id="chargePointInput"
      />
      <wb-input
        v-model="data.password"
        :label="i18n.t('mywb.common.password')"
        class="mb-16"
        name="user"
        data-test-id="passwordInput"
      />

      <wb-checkbox
        v-model="data.acceptedConditions"
        :error="!!errors.acceptedConditions"
        name="terms"
        data-test-id="termsAndConditionsCheckbox"
      >
        {{ i18n.t('mywb.common.accepted-privacy-policy') }}
        <wb-link
          target="_blank"
          :href="compute.termsAndConditions"
        >
          {{ i18n.t('mywb.common.terms-and-conditions') }}
        </wb-link>
      </wb-checkbox>
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          outlined
          size="block"
          :disabled="props.loading"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="props.loading"
          @click="validate(methods.saveOcpp)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import lang from '@/engine/lang'
import type { OcppSettings } from '@/core/charger'
import { useValidator } from '@/hooks/useValidator.hook'
import dynamicImg from '@/utilities/dynamicImg'

const { yup, errors, validate, defineSchema } = useValidator()
const i18n = useI18n()

interface Props {
  ocppSettings: Partial<OcppSettings>
  loading?: boolean
}
const props = defineProps<Props>()

type OCPPBackend = 'custom' | 'electromaps' | 'monta' | 'efuel' | 'evergo' | 'freshmile' | 'eflux'

interface OcppBackendOption {
  id: OCPPBackend
  label: string,
  imgSrc?: string,
  url?: string
}

const knownOCPPBackendsOptions: OcppBackendOption[] = [
  {
    id: 'custom',
    label: i18n.t('mywb.common.custom')
  },
  {
    id: 'electromaps',
    label: 'Electromaps',
    imgSrc: dynamicImg('logos/ocppBackends/electromaps.svg'),
    url: 'ws://manager.electromaps.com:8080/ocpp/websocket/CentralSystemService/'
  },
  {
    id: 'monta',
    label: 'Monta',
    imgSrc: dynamicImg('logos/ocppBackends/monta.svg'),
    url: 'wss://ocpp.monta.app'
  },
  {
    id: 'efuel',
    label: 'Efuel',
    imgSrc: dynamicImg('logos/ocppBackends/efuel.svg'),
    url: 'wss://ocpp.efuel.se'
  },
  {
    id: 'evergo',
    label: 'Evergo',
    imgSrc: dynamicImg('logos/ocppBackends/evergo.svg'),
    url: 'wss://apps.cepm.com.do/ocpp_gw/ocpp_ws'
  },
  {
    id: 'freshmile',
    label: 'Freshmile',
    imgSrc: dynamicImg('logos/ocppBackends/freshmile.svg'),
    url: 'ws://ocppj.freshmile.com'
  },
  {
    id: 'eflux',
    label: 'E-Flux',
    imgSrc: dynamicImg('logos/ocppBackends/eflux.svg'),
    url: 'ws://ocpp.e-flux.nl/1.6/e-flux'
  }
]

interface OcppData {
  type: 'wallbox' | 'ocpp'
  address?: string
  chargePointIdentity?: string
  acceptedConditions: boolean
  ocppBackend: OcppBackendOption
  isUrlDisabled: boolean
  password?: string
}

interface Events {
  (e: 'on-close'): void
  (e: 'on-save', ocpp: OcppData): void
}
const emit = defineEmits<Events>()

const data = reactive<OcppData>({
  type: 'wallbox',
  address: props.ocppSettings.address,
  chargePointIdentity: props.ocppSettings.chargePointIdentity,
  acceptedConditions: !!props.ocppSettings.address,
  ocppBackend: knownOCPPBackendsOptions.find(opt => opt.id === 'custom') as OcppBackendOption,
  isUrlDisabled: false,
  password: props.ocppSettings.password
})

defineSchema(data, {
  address: yup.string().required(),
  chargePointIdentity: yup.string().required(),
  acceptedConditions: yup.boolean().required().isTrue()
})

const compute = reactive({
  termsAndConditions: computed(() => {
    return lang.__rootLanguage === 'es'
      ? '/docs/terms_conditions_OCPP_es.pdf'
      : '/docs/terms_conditions_OCPP_en.pdf'
  })
})

const methods = {
  saveOcpp () {
    data.type = 'ocpp'
    emit('on-save', data)
  },

  handleOnOCPPBackendChange (ocppBackend: OcppBackendOption) {
    if (ocppBackend.id === 'custom') {
      data.isUrlDisabled = false
      data.address = ''
    } else {
      data.isUrlDisabled = true
      data.address = ocppBackend.url
    }
  },

  prepopulateOcppBackend () {
    if (props.ocppSettings.address == null || props.ocppSettings.address === '') return
    const matchingBackend = knownOCPPBackendsOptions.find(ocpp => ocpp.url === props.ocppSettings.address)
    if (matchingBackend == null) return
    data.ocppBackend = matchingBackend
    data.isUrlDisabled = true
  }
}

function created () {
  methods.prepopulateOcppBackend()
}

created()
</script>

<style scoped lang="postcss">
.select-occp-option {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.occp-option-img {
  width: 24px;
  height: auto;
}
</style>
