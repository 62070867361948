<template>
  <wb-dropdown
    v-if="compute.canDoAnyAction"
    menu-position="bottom-end"
    :distance-offset="16"
  >
    <template #activator>
      <wb-button
        icon="more_horiz"
        variant="white"
        outlined
        data-test-id="chargerActionMenu"
        size="small"
      />
    </template>
    <template #container="{ close }">
      <wb-button
        v-if="compute.showPaymentsConfiguration"
        data-test-id="chargerActionMenuPaymentsConfiguration"
        variant="white"
        icon="card"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.payments-settings')"
        @mousedown="methods.handlePaymentsSettings(close)"
      />

      <wb-button
        v-if="permissions.canSeeLocations"
        data-test-id="chargerActionMenuChangeLocation"
        variant="white"
        icon="change_location"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.change-location')"
        @mousedown="methods.handleChangeLocation(close)"
      />

      <wb-button
        v-if="compute.hasPermission && compute.softwareUpdate"
        variant="white"
        icon="download_circle"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.update')"
        @mousedown="methods.handleUpdateClick(close)"
      />

      <wb-button
        v-if="compute.hasPermission"
        variant="white"
        icon="refresh"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.restart')"
        @mousedown="methods.handleRestartClick(close)"
      />

      <wb-button
        v-if="permissions.canSeeChargerDetail"
        data-test-id="chargerActionMenuChargerSettings"
        variant="white"
        icon="settings"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.settings')"
        @mousedown="methods.handleChargerSettings(close)"
      />

      <wb-button
        v-if="permissions.canSeeChargerDetail"
        variant="danger"
        data-test-id="unlinkCharger"
        icon="delete"
        size="block"
        content-position="left"
        unrounded
        inverted
        :label="i18n.t('mywb.common.delete')"
        @mousedown="methods.handleRemoveClick(close)"
      />
    </template>
  </wb-dropdown>

  <location-assign-rate-charger-modal
    v-if="data.isModalAssignRatesSingleChargerOpen && props.charger"
    data-test-id="chargersAssignRatesSingleChargerModal"
    :charger="props.charger"
    @close="data.isModalAssignRatesSingleChargerOpen = false"
    @rates-assigned="emit('rates-changed')"
  />

  <location-change-location-modal
    v-if="data.isModalChangeLocation"
    :charger="props.charger"
    @close="data.isModalChangeLocation = false"
  />

  <shared-confirmation-modal
    v-if="data.isModalChargersUnlinkOpen"
    :title="i18n.t('mywb.common.delete-confirmation', {element: props.charger?.name})"
    :label-confirmation-button="i18n.t('mywb.common.delete')"
    type="danger"
    @on-confirm="methods.unlinkCharger"
    @on-close="data.isModalChargersUnlinkOpen = false"
  />
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'

import { STATUSES } from '@/utilities/charger/chargerStatuses'

import state from '@/state'
import { useToast } from '@wallbox/toolkit-ui'

import LocationAssignRateChargerModal from '@/components/modals/LocationAssignRateChargerModal.vue'
import LocationChangeLocationModal from '@/components/modals/LocationChangeLocationModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import { trackDataAction, trackDataEvent } from '@/engine/metrics/trackDataManager'
import type { Charger as ChargerCore } from '@/core/charger'
import { provideChargersUsesCases } from '@/core/charger'
import { useRouter } from 'vue-router'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const toast = useToast()
const chargersUsesCases = provideChargersUsesCases()
const router = useRouter()

interface Events {
  (e: 'rates-changed'): void,
  (e: 'on-update-action', charger: ChargerCore): ChargerCore,
  (e: 'on-restart-action', charger: ChargerCore): ChargerCore
}
const emit = defineEmits<Events>()

interface Props {
  charger: ChargerCore
}
const props = defineProps<Props>()

interface Data {
  isModalAssignRatesSingleChargerOpen: boolean
  isModalChangeLocation: boolean
  isModalChargersUnlinkOpen: boolean
}

const data = reactive<Data>({
  isModalAssignRatesSingleChargerOpen: false,
  isModalChangeLocation: false,
  isModalChargersUnlinkOpen: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.paymentsAccount),

  showRates: computed((): boolean => permissions.showPayments && compute.hasOrganizationPaymentsAccount),

  showPaymentsConfiguration: computed((): boolean => {
    return compute.showRates && !!props.charger
  }),

  hasPermission: computed((): boolean => {
    const status = props.charger?.status?.code
    return !!props.charger?.id &&
      permissions.hasAuthToRestartAndUpdate &&
      !(status === STATUSES.DISCONNECTED ||
      status === STATUSES.CHARGING ||
      status === STATUSES.DISCHARGING)
  }),

  softwareUpdate: computed(() => {
    return !!props.charger?.id && isUpdatesAvailable(props.charger)
  }),

  canDoAnyAction: computed((): boolean =>
    compute.showPaymentsConfiguration ||
    compute.hasPermission ||
    permissions.canSeeLocations ||
    permissions.canSeeChargerDetail
  )
})

const methods = {
  handlePaymentsSettings (close: () => void) {
    close()
    data.isModalAssignRatesSingleChargerOpen = true
  },

  handleChangeLocation (close: () => void) {
    close()
    data.isModalChangeLocation = true
  },

  handleChargerSettings (close: () => void) {
    close()
    router.push({ name: ROUTES.CHARGER_DETAIL_SETTINGS, params: { chargerUid: props.charger?.uid } })
  },

  handleRemoveClick (close: () => void) {
    close()
    trackDataAction('unlink-charger', { charger_id: props.charger?.id.toString() })
    data.isModalChargersUnlinkOpen = true
  },

  handleUpdateClick (close: () => void) {
    close()
    emit('on-update-action', props.charger)
  },

  handleRestartClick (close: () => void) {
    close()
    emit('on-restart-action', props.charger)
  },

  async unlinkCharger () {
    if (!props.charger) return
    try {
      toast.success(i18n.t('mywb.common.charger-removed-successfully'))
      trackDataEvent('unlink-charger', { charger_id: props.charger.id })

      await chargersUsesCases.unlinkCharger(props.charger.uid)

      state.charger.set(
        'chargers',
        state.charger.getChargers.filter(charger => charger.uid !== props.charger?.uid)
      )
    } catch (e) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
      throw e
    }
  }
}
</script>
