import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const acceptServiceability:
UseCase<ChargerRepository, 'acceptServiceability'> = repository => {
  return async (acceptLink: string) => {
    await repository.acceptServiceability(
      acceptLink
    )
  }
}
