<template>
  <wb-checkbox
    v-model="compute.model"
    class="location-charger-tree-item"
    :data-test-id="`charger-${charger.id}`"
    :disabled="props.disabled"
  >
    <div>
      <div data-test-id="chargerName" class="has-text-black has-text-overflow is-font-weight-500">
        {{ charger.name }}
      </div>
      <div data-test-id="chargerSn">
        SN {{ charger.id }}
      </div>
    </div>
  </wb-checkbox>
</template>

<script lang="ts">
export default {
  name: 'LocationChargerTreeItem'
}
</script>

<script setup lang="ts">
import type { Location } from '@/core/location'
import { reactive, computed } from 'vue'

interface Props {
  modelValue: boolean
  disabled?: boolean
  charger: NonNullable<Location['chargers']>[number]
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:modelValue', model: boolean): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})
</script>

<style lang="postcss" scoped>
.location-charger-tree-item {
  border: 1px solid var(--grey-200);
  border-bottom: none;
  padding: 6px 48px;
  gap: 6px;
}
</style>
