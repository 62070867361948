import type { SessionRepository } from '@/core/session'
import type { FiltersQuery, PaginatedQuery, CachedQuery } from '@/core/shared'

export function getAll (repository: SessionRepository) {
  return async (groupUid: string, params: FiltersQuery & PaginatedQuery & CachedQuery) => {
    const result = await repository.getAll(groupUid, params, 'json')

    return result
  }
}
