import type { UseCase } from '@/core/shared'
import type { SessionRepository } from '@/core/session'
import { download } from '@wallbox/toolkit-ui'
import { read, writeFileXLSX } from 'xlsx'

export const downloadCSV: UseCase<SessionRepository, 'getAll', Promise<boolean>> = repository => {
  return async (groupUid, params) => {
    try {
      const stringCsv = await repository.getAll(groupUid, params, 'csv')
      const blob = new Blob(
        ['\ufeff', stringCsv],
        { type: 'text/csv;charset=utf-8;' })
      download.downloadBlob(blob, 'SessionsReport.csv')

      return true
    } catch {
      return false
    }
  }
}

export const downloadXLSX: UseCase<SessionRepository, 'getAll', Promise<boolean>> = repository => {
  return async (groupUid, params) => {
    try {
      const stringCsv = await repository.getAll(groupUid, params, 'csv')
      const wb = read(stringCsv, { type: 'string', raw: true })
      writeFileXLSX(wb, 'SessionsReport.xlsx')

      return true
    } catch {
      return false
    }
  }
}
