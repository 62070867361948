import { computed, reactive, toRefs } from 'vue'
import i18n from '@/engine/lang'
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

interface Route extends Partial<RouteLocationNormalized> {
  breadcrumb?: {
    name: string
    icon: string
    text: string
    extraInfo: string
  }
}

interface State {
  routes: Route[]
}

const initialState = (): State => ({
  routes: []
})

let state = reactive(initialState())

const getters = reactive({
  getWbState: () => {
    return JSON.parse(sessionStorage.getItem('wb-state') ?? '{}') ?? initialState()
  },

  getBreadcrumb: computed(() => {
    let items = state.routes
      .map(item => {
        if (item.breadcrumb) {
          return {
            ...item.breadcrumb,
            text: [
              item.breadcrumb.text && i18n.global.t(item.breadcrumb.text),
              item.breadcrumb.extraInfo
            ].filter(Boolean).join(' '),
            exact: false,
            to: { name: item?.name ?? undefined, params: item.params, query: item.query }
          }
        }

        return undefined
      })
      .filter(Boolean as unknown as ExcludesNullish)
      .slice(0, -1)

    if (items.length > 0) {
      items[0].icon = 'arrow_back'
    }

    if (items.length > 3) {
      items = [
        items[0],
        ...items.slice(Math.max(items.length - 2, 1))
      ]
    }

    return items
  })
})

const setters = {
  setWbState () {
    const stateString = sessionStorage.getItem('wb-state')

    const wbState = stateString ? JSON.parse(stateString) as State : initialState()

    wbState.routes = state.routes
    sessionStorage.setItem('wb-state', JSON.stringify(wbState))
  },

  resetState () {
    sessionStorage.removeItem('wb-state')
    state = reactive(initialState())
  },

  pushRoute (route: Route) {
    const lastElement = state.routes[state.routes.length - 1]

    if (!lastElement || (lastElement?.name !== route?.name)) {
      let breadcrumb = route.meta?.breadcrumb as Route['breadcrumb']

      const routesWithBreadcrumb = state.routes.filter(route => route.breadcrumb)
      const lastItemWidthBreadcrumb = routesWithBreadcrumb[routesWithBreadcrumb.length - 1]

      breadcrumb = lastItemWidthBreadcrumb?.breadcrumb?.name !== breadcrumb?.name ? breadcrumb : undefined
      state.routes.push({
        name: route.name,
        params: route.params,
        query: route.query,
        fullPath: route.fullPath,
        breadcrumb
      })

      setters.setWbState()
    }
  },

  clearRoutes () {
    state.routes = []
  },

  getLastRoute () {
    state.routes.pop()
    const route = state.routes.pop()
    setters.setWbState()

    const routeRaw: RouteLocationRaw = { name: route?.name ?? '', params: route?.params, query: route?.query }
    return routeRaw
  },

  backToRoute ({ name }: Partial<RouteLocationNormalized>) {
    state.routes.splice(state.routes.findIndex((route) => route.name === name) + 1, state.routes.length)
    setters.setWbState()
  },

  hydrateCurrentBreadCrumb (text: string) {
    const breadcrumb = state.routes[state.routes.length - 1]?.breadcrumb

    if (breadcrumb) {
      breadcrumb.extraInfo = text
    }
  }
}

export function loadState () {
  const wbState = getters.getWbState()

  if (wbState) {
    state.routes = wbState.routes ?? []
  }
}

export default reactive({
  ...toRefs(state),
  ...toRefs(getters),
  ...setters
})
