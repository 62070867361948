import {
  perseusLocationRepository,
  getAllLocations,
  getLocation,
  getLocationByGroup,
  createLocation,
  editLocation,
  deleteLocation
} from '@/core/location'

export function provideLocationUseCases () {
  const repo = perseusLocationRepository()

  return {
    getAllLocations: getAllLocations(repo),
    getLocation: getLocation(repo),
    getLocationByGroup: getLocationByGroup(repo),
    createLocation: createLocation(repo),
    editLocation: editLocation(repo),
    deleteLocation: deleteLocation(repo)
  }
}
