<template>
  <wb-modal
    width="40"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.delete-confirmation', { element: props.accessConfig.name }) }}
    </template>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          size="block"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="confirmDeleteButton"
          variant="danger"
          :label="i18n.t('mywb.common.delete')"
          :loading="data.isLoading"
          size="block"
          @click="methods.deleteAccessConfigFromGroup"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import type { AccessConfig } from '@/core/accessConfig'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'

const i18n = useI18n()
const toast = useToast()
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

interface Props {
  accessConfig: AccessConfig
}
const props = defineProps<Props>()

const emit = defineEmits(['close'])

const data = reactive({
  isLoading: false
})

const methods = {
  async deleteAccessConfigFromGroup () {
    try {
      data.isLoading = true
      await accessConfigUseCases.removeAccessConfig(props.accessConfig.id)

      const accessConfigs = await accessConfigUseCases
        .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
      state.user.setAccessConfigs(accessConfigs)

      trackDataEvent('delete-group', { group_id: props.accessConfig.groupId })
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.isLoading = false
    emit('close')
  }
}

function created () {
  trackDataScreen('users-list-access-config-delete')
}

created()
</script>
