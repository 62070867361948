
<template>
  <wb-table-row
    :id="props.charger.uid"
    :track-by="props.charger.uid"
    class="charger-row"
    data-test-id="chargerItem"
    :is-row-clickable="permissions.canSeeChargerDetail"
    @on-checked-row="methods.handleChargerCheck"
    @click="methods.handleChargerClick"
  >
    <wb-table-row-item>
      <chargers-table-charger-name
        :id="props.charger.id"
        :image="props.charger.image"
        :name="props.charger.name"
        :uuid="props.charger.uid"
      />
    </wb-table-row-item>

    <wb-table-row-item @click.stop>
      <wb-link
        v-if="permissions.canSeeLocations"
        type="primary"
        data-test-id="locationName"
        :to="{ name: ROUTES.LOCATION_DETAIL, params: { locationId: props.charger.locationId } }"
      >
        {{ props.charger.locationName }}
      </wb-link>

      <p v-else data-test-id="locationName" class="has-text-overflow">
        {{ props.charger.locationName }}
      </p>
    </wb-table-row-item>

    <wb-table-row-item>
      <charger-status :charger="props.charger" />
      <!-- <wb-charger-status :status="props.charger.status.id" /> -->
    </wb-table-row-item>

    <wb-table-row-item>
      <charger-device-network :charger="props.charger" />
    </wb-table-row-item>

    <wb-table-row-item>
      <wb-badge variant="blue">
        {{ `${props.charger.software?.currentVersion} - ${i18n.t('mywb.common.update-to-version',
                                                                 [props.charger.software?.latestVersion])}` }}
      </wb-badge>
    </wb-table-row-item>
    <wb-table-row-item @click.stop>
      <charger-actions-table-row-item
        :charger="props.charger"
        @on-update-action="emit('on-update-action', $event)"
        @on-restart-action="emit('on-restart-action', $event)"
      />
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { permissions } from '@/engine/clients'
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import ChargerActionsTableRowItem from '@/components/tables/ChargerActionsTableRowItem.vue'
import ChargerDeviceNetwork from '@/components/charger/ChargerDeviceNetwork.vue'
import ChargersTableChargerName from './ChargersTableChargerName.vue'
import state from '@/state'
import type { Charger } from '@/core/charger'
import { useI18n } from '@/hooks/useI18n.hook'
import ROUTES from '@/engine/router/routes'

const router = useRouter()
const i18n = useI18n()
const emit = defineEmits(['on-update-action', 'on-restart-action'])

interface Props {
  charger: Charger
}
const props = withDefaults(defineProps<Props>(), {
  charger: undefined
})

const methods = {
  handleChargerCheck (value: boolean) {
    state.charger.setCheckCharger({
      ...props.charger
    }, value)
  },

  handleChargerClick () {
    if (permissions.canSeeChargerDetail) {
      router.push({ name: ROUTES.CHARGER, params: { chargerUid: props.charger.uid } })
    }
  }
}
</script>

<style lang="postcss" scoped>
.charger-row :deep(.wallbox-row-item-wrapper:first-child) {
  position: relative;
}

.charger-row:last-child :deep(.wallbox-row-item-wrapper:first-child)::before {
  border-radius: 0 0 0 0.8rem;
}

.charger-row:last-child :deep(.wallbox-row-item-wrapper:last-child) {
  border-radius: 0 0 0.8rem;
}

.charger-name {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
}
</style>
