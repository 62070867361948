<template>
  <section class="container">
    <div v-if="slots.header">
      <slot name="header" />
    </div>
    <div class="container-content">
      <transition>
        <slot />
      </transition>
    </div>
    <div class="container-content-list">
      <transition-group v-if="slots['content-list']" tag="div">
        <slot name="content-list" />
      </transition-group>
    </div>
    <div v-if="slots.actions" class="main-content-actions">
      <slot name="actions" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

const slots = useSlots()
</script>

<style lang="postcss" scoped>
.container {
  position: relative;
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: minmax(0, 1fr);

  & :deep() {
    .wbSelect,
    .wbInput {
      --background-color: var(--white);
    }
  }
}

.container-content {
  position: relative;
  height: auto;
}

.main-content-actions {
  position: static;
  border-top: 1px solid var(--grey-200);
  padding: 1.2rem 3.4rem;
  width: 100%;
  text-align: right;
  border-radius: 0 0 1rem 1rem;
  height: 6rem;
  z-index: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 100ms;
}

.fade-enter-active {
  transition-delay: 100ms;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
