import { object, string, number, array, type InferType } from 'yup'
import type { CountryIso2 } from '@/core/international'

const locationSchema = object({
  name: string().required(),
  energyType: string().required(),
  address: string().required(),
  locationTypes: array().of(string().required()),
  instructions: string(),
  city: string().required(),
  state: string(),
  zipCode: string().required(),
  country: string<CountryIso2>().required(),
  latitude: number().required(),
  longitude: number().required(),
  energyCost: number().required(),
  organizationId: string().required()
})

export type LocationCreate = InferType<typeof locationSchema>

export async function ensureCreateLocationIsValid (location: any) {
  return await locationSchema.validate(location, { abortEarly: false })
}
