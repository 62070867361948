<template>
  <wb-skeleton-loader
    :type="mq.current !== 'mobile' ? 'table' : 'dots'"
    :loading="data.loading"
    data-test-id="wb-table-loader"
  >
    <template v-if="state.charger.getChargers.length">
      <enable-auto-refresh-component
        v-if="!isInRealTime && props.autoRefreshNotification"
        class="mb-8"
        @enable-auto-refresh="startRealTime"
      />

      <update-chargers-notification
        v-if="props.updateChargersNotification"
      />

      <chargers-table
        :include-columns="props.includeColumns"
        data-test-id="groupList"
        :header-sticky="props.headerSticky"
        :loading="data.tableLoading"
        :pagination="data.pagination"
        @on-bulk-action="methods.handleBulkAction"
        @on-pagination-change="methods.paginationChange"
        @on-sort-change="methods.onSortChange"
        @on-update-action="methods.handleUpdateModal"
        @on-restart-action="methods.handleRestartModal"
      />
    </template>
    <chargers-empty-state v-else />
  </wb-skeleton-loader>

  <add-chargers-modal
    v-if="data.isModalAddChargersOpen"
    @on-update-chargers="methods.loadChargers(0)"
    @on-configure-payments="methods.handleConfigurePayments"
    @close="data.isModalAddChargersOpen = false"
  />

  <chargers-update-action-modal
    v-if="data.isModalUpdateActionsOpen"
    :chargers="data.chargersSelected"
    @close="data.isModalUpdateActionsOpen = false"
  />

  <chargers-restart-action-modal
    v-if="data.isModalRestartActionsOpen"
    :chargers="data.chargersSelected"
    @close="data.isModalRestartActionsOpen = false"
  />

  <chargers-assign-rates-modal
    v-if="data.isModalAssignRatesOpen"
    @close="data.isModalAssignRatesOpen = false"
  />
</template>

<script setup lang="ts">
import ChargersUpdateActionModal from '@/components/modals/ChargersUpdateActionModal.vue'
import ChargersRestartActionModal from '@/components/modals/ChargersRestartActionModal.vue'
import ChargersAssignRatesModal from '@/components/modals/ChargersAssignRatesModal.vue'
import UpdateChargersNotification from '@/components/UpdateChargersNotification.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import ChargersEmptyState from '@/components/emptyStates/ChargersEmptyState.vue'
import AddChargersModal from '@/components/modals/AddChargersModal.vue'
import ChargersTable from '@/components/tables/ChargersTable.vue'

import { reactive, watchEffect, watch } from 'vue'
import state from '@/state'
import { trackDataAction, trackDataTime } from '@/engine/metrics/trackDataManager'
import { provideChargersUsesCases } from '@/core/charger'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { TableData } from '@wallbox/toolkit-ui'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from 'vue-i18n'
import { useRealTimeFetcher } from '@wallbox/hooks'
import { useRoute, useRouter } from 'vue-router'
import type { ChargerColumns } from '@/types/data/chargerColumns'
import type { Charger as ChargerCore } from '@/core/charger'
import { useMq } from 'vue3-mq'

const toast = useToast()
const i18n = useI18n()
const chargersUsesCases = provideChargersUsesCases()
const route = useRoute()
const router = useRouter()
const mq = useMq()

const {
  isInRealTime,
  provideCallback,
  startRealTime
} = useRealTimeFetcher('chargers-view')

interface Props {
  includeColumns?: ChargerColumns[],
  updateChargersNotification?: boolean,
  autoRefreshNotification?: boolean,
  headerSticky?: string
}

const props = withDefaults(defineProps<Props>(), {
  includeColumns: () => ['selectable', 'name', 'location', 'status', 'features', 'actions'],
  updateChargersNotification: true,
  autoRefreshNotification: true,
  headerSticky: undefined
})

interface Data {
  loading: boolean
  tableLoading: boolean
  isModalUpdateActionsOpen: boolean
  isModalRestartActionsOpen: boolean
  isModalAssignRatesOpen: boolean
  isModalAddChargersOpen: boolean
  reloadChargersInterval?: ReturnType<typeof setInterval>
  pagination: {
    offset: number
    limit: number
    total: number
    multiPage: boolean
  }
  sorts?: TableData['currentSorts']
  chargersSelected: ChargerCore[]
}

const data = reactive<Data>({
  loading: false,
  tableLoading: false,
  isModalUpdateActionsOpen: false,
  isModalRestartActionsOpen: false,
  isModalAssignRatesOpen: false,
  isModalAddChargersOpen: false,
  reloadChargersInterval: undefined,
  pagination: {
    offset: 0,
    limit: 50,
    total: 5000,
    multiPage: true
  },
  chargersSelected: []
})

type action = REMOTE_ACTIONS.UPDATE | REMOTE_ACTIONS.RESTART
const methods = {
  handleBulkAction (action: action) {
    switch (action) {
      case REMOTE_ACTIONS.RESTART:
        data.isModalRestartActionsOpen = true
        break
      case REMOTE_ACTIONS.UPDATE:
        data.isModalUpdateActionsOpen = true
        break
    }
  },

  handleAddCharger () {
    trackDataAction('add-charger', { location_id: state.organizations.getCurrentOrganization.groupUid })
    data.isModalAddChargersOpen = true
  },

  handleUpdateModal (charger: ChargerCore) {
    data.chargersSelected = [charger]
    data.isModalUpdateActionsOpen = true
  },

  handleRestartModal (charger: ChargerCore) {
    data.chargersSelected = [charger]
    data.isModalRestartActionsOpen = true
  },

  handleConfigurePayments () {
    data.isModalAddChargersOpen = false
    data.isModalAssignRatesOpen = true
  },

  async paginationChange (offset: number) {
    data.tableLoading = true

    await methods.loadChargers(offset)
    data.tableLoading = false
  },

  async onSortChange (sort: TableData['currentSorts']) {
    data.tableLoading = true
    data.sorts = sort
    await methods.loadChargers(0)
    data.tableLoading = false
  },

  async loadChargers (offset = 0) {
    data.pagination.offset = offset
    try {
      const chargers = await chargersUsesCases.getAllChargersPaginated(
        state.organizations.getCurrentOrganization.groupUid,
        {
          limit: data.pagination.limit,
          offset: data.pagination.offset,
          filters: state.filters.backendChargerFilters,
          sort: data.sorts ?? []
        }
      )
      state.charger.set('chargers', chargers.data)
      data.pagination.total = chargers.total
    } catch {
      toast.error(i18n.t('mywb.error.fetching-chargers'))
    }
  }
}

watchEffect(() => {
  if (route.query['add-chargers']) {
    setTimeout(() => {
      methods.handleAddCharger()
      router.push({ query: {} }).catch(() => {})
    }, 1000)
  }
})

watch(() => state.filters.chargersFilters, async () => {
  data.tableLoading = true
  await methods.loadChargers(0)
  data.tableLoading = false
  state.charger.resetCheckedChargers()
}, { deep: true })

watch(() => state.charger.getCheckedChargers, () => {
  data.chargersSelected = state.charger.getCheckedChargers
}, { deep: true })

async function created () {
  data.loading = true

  provideCallback(async () => await methods.loadChargers(data.pagination.offset))
  await startRealTime()

  data.loading = false

  !state.global.get('chargersLoaded') && trackDataTime('charge-list-complete')
  state.global.set('chargersLoaded', true)
}

created()

defineExpose({
  handleAddCharger: () => methods.handleAddCharger()
})
</script>
