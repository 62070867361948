<template>
  <wb-modal width="80" @close="emit('on-close')">
    <Transition :name="data.stepDirection">
      <div v-if="isCurrentState('usersAccess')" class="content-step">
        <access-users
          v-model="data.toAccessGroup"
        />

        <template v-if="data.toAccessGroup">
          <wb-select
            v-model="data.accessGroupId"
            data-test-id="accessGroupSelect"
            uid="_groups"
            class="is-capitalized mt-16"
            :label="i18n.t('mywb.common.access-group')"
            option-label="name"
            :reduce="(option: any) => option.id"
            :placeholder="i18n.t('mywb.common.add-existing-access-group')"
            :options="data.accessConfigs || []"
            :loading="data.loading"
            :error="data.errors.accessGroupId"
          />

          <wb-button
            variant="primary"
            inverted
            icon="create_new_folder"
            class="mt-24"
            :label="i18n.t('mywb.common.create-access-group')"
            data-test-id="create-access-config"
            @click="methods.handleCustomButton('createAccessGroup')"
          />
        </template>

        <div class="mt-16">
          <locations-accordion
            v-if="!data.toAccessGroup"
            v-model="data.customChargersAccessConfig"
          />
        </div>
      </div>

      <div v-else-if="isCurrentState('createAccessGroup')" class="content-step">
        <create-access-group
          :ref="setRef('createAccessGroup')"
          v-model:accessConfigId="data.accessGroupId"
        />
      </div>
    </Transition>

    <template #actions>
      <div v-if="!isCurrentState('finish')" class="button-actions">
        <wb-button
          v-if="isCurrentState('usersAccess')"
          data-test-id="cancelButton"
          variant="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          v-if="!isCurrentState('usersAccess')"
          data-test-id="backButton"
          icon="arrow_back"
          variant="white"
          outlined
          class="mr-24"
          :label="i18n.t('mywb.common.back')"
          @click="methods.handleBackButton"
        />

        <wb-button
          data-test-id="acceptBtnModal"
          :label="i18n.t('mywb.common.save')"
          :loading="data.submitLoading"
          @click="methods.handleSubmitButton"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import AccessUsers from '@/components/invitations/AccessUsers.vue'
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import CreateAccessGroup from '@/components/invitations/CreateAccessGroup.vue'
import { useStateMachine } from '@wallbox/hooks'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, onMounted } from 'vue'
import { useToast, useTemplateRef } from '@wallbox/toolkit-ui'
import state from '@/state'
import type { UserDetail } from '@/core/user'
import type { AccessConfig, AccessConfigForUser } from '@/core/accessConfig'

import { ACCESS_CONFIG_USE_CASES, USER_USE_CASES, injectStrict } from '@/engine/injectors'

const toast = useToast()
const i18n = useI18n()
const { refs, setRef } = useTemplateRef()
const accessConfigUseCaes = injectStrict(ACCESS_CONFIG_USE_CASES)
const userUseCases = injectStrict(USER_USE_CASES)

interface Props {
  accessConfig: AccessConfigForUser
  user: UserDetail
}

const props = defineProps<Props>()

interface Data {
  submitLoading: boolean
  stepDirection: 'slide-left' | 'slide-right'
  toAccessGroup: number
  accessGroupId: number | null
  customChargersAccessConfig: number[]
  errors: any
  accessConfigs: AccessConfig[]
  loading: boolean
}

const data = reactive<Data>({
  submitLoading: false,
  stepDirection: 'slide-left',
  toAccessGroup: 0,
  accessGroupId: null,
  customChargersAccessConfig: [],
  errors: {},
  accessConfigs: [],
  loading: false
})

interface Events {
  (e: 'update:accessConfig', accessConfig: AccessConfig): void
  (e: 'on-close'): void
}
const emit = defineEmits<Events>()

const { isCurrentState, send, currentStateKey } = useStateMachine({
  initial: 'usersAccess',
  states: {
    usersAccess: {
      transitions: {
        createAccessGroup: 'createAccessGroup'
      }
    },

    createAccessGroup: {
      transitions: {
        back: 'usersAccess'
      }
    }
  }
})

const methods = {
  handleBackButton () {
    data.stepDirection = 'slide-left'
    send('back')
  },

  async isValidateForm () {
    data.errors = {}
    if (data.toAccessGroup && currentStateKey.value === 'usersAccess') {
      if (!data.accessGroupId) {
        data.errors.accessGroupId = i18n.t('mywb.error.field-required')
        return false
      }
    }

    if (data.toAccessGroup && currentStateKey.value === 'createAccessGroup') {
      return await refs.createAccessGroup.validateForm()
    }
    return true
  },

  async handleSubmitButton () {
    data.submitLoading = true
    if (data.toAccessGroup) {
      if (!await methods.isValidateForm()) {
        data.submitLoading = false
        return
      }
      await methods.addAccessConfig()
    } else {
      if (!props.accessConfig.hasCustomAccess) {
        await methods.deleteAccessConfig()
      }

      await methods.addCustomAccess()
    }

    emit('on-close')

    data.submitLoading = false
  },

  handleCustomButton (stateName: 'back' | 'createAccessGroup') {
    data.stepDirection = 'slide-right'
    send(stateName)
  },

  async addCustomAccess () {
    try {
      await userUseCases.addChargersToUser({
        userId: props.user.id,
        groupUid: state.organizations.getCurrentOrganization.groupUid,
        chargers: data.customChargersAccessConfig,
        hasAccessConfig: !props.accessConfig.hasCustomAccess
      })
      emit('update:accessConfig', {
        ...props.accessConfig,
        name: undefined,
        chargers: data.customChargersAccessConfig
      })
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async deleteAccessConfig () {
    try {
      await accessConfigUseCaes.removeUserFromAccessConfig({
        accessConfigId: props.accessConfig.id,
        userId: props.user.id
      })
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async addAccessConfig () {
    if (!data.accessGroupId) return

    try {
      await accessConfigUseCaes.addUserToAccessConfig({
        accessConfigId: data.accessGroupId,
        userId: props.user.id,
        role: props.accessConfig.profile
      })

      const accessConfig =
      data.accessConfigs.find(accessConfig => accessConfig.id === data.accessGroupId)

      if (accessConfig) {
        emit('update:accessConfig', accessConfig)
      }
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  }
}

onMounted(async () => {
  data.loading = true
  data.accessConfigs = await accessConfigUseCaes.getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
  data.loading = false
})

function created () {
  data.toAccessGroup = Number(!!props.accessConfig.name)
  if (!data.toAccessGroup) {
    data.customChargersAccessConfig = props.accessConfig.chargers
  } else {
    data.accessGroupId = props.accessConfig.id
  }

  trackDataScreen('user-charger-access-modal')
}

created()
</script>

<style lang="postcss" scoped>
:deep(.button-actions) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.modal-grid) {
  min-height: calc(100vh - 8rem);
}

:deep(.modal-content) {
  overflow-x: hidden;
  max-height: calc(100vh - 18rem) !important;
}

.content-step {
  @media (--tablet) {
    width: calc(80rem - 8rem);
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 360ms ease-out;
  position: absolute;
}

.slide-right-enter-from {
  transform: translateX(80rem);
}

.slide-right-leave-to {
  transform: translateX(-80rem);
}

.slide-left-enter-from {
  transform: translateX(-80rem);
}

.slide-left-leave-to {
  transform: translateX(80rem);
}
</style>
