<template>
  <wb-alert
    v-if="compute.haveIncompatibilities"
    class="mb-16"
    variant="info"
    icon="info_filled"
  >
    {{ i18n.t("mywb.charger.rates-incompatible") }}
  </wb-alert>

  <wb-table
    :options="data.options"
    :loading="props.loading"
    v-bind="$attrs"
  >
    <wb-table-row
      v-for="charger in compute.chargerWithRates"
      :key="charger.charger.id"
    >
      <wb-table-row-item>
        <p class="is-font-weight-500">
          {{ charger.charger.name }}
        </p>
      </wb-table-row-item>

      <wb-table-row-item v-if="payPerChargeRate || unassignPayPerCharge">
        <div
          v-if="charger.paymentsInfo.isPayPerChargeCompatible.isCompatible && charger.canAddEnergyRateOnPayPerCharge"
          class="rates-wrapper"
          data-test-id="newPayPerChargeRate"
        >
          <span v-if="unassignPayPerCharge" v-t="'mywb.charger.no-rate-assigned'" class="new-rate" />
          <span v-else class="new-rate">{{ payPerChargeRate }}</span>
        </div>

        <span
          v-else-if="!charger.paymentsInfo.isPayPerChargeCompatible.isCompatible
            && charger.paymentsInfo.isPayPerChargeCompatible.reason"
          class="has-text-danger-500"
          data-test-id="notCompatible"
        >
          {{ getPaymentsTranslations(charger.paymentsInfo.isPayPerChargeCompatible.reason) }}
        </span>

        <span
          v-else
          v-t="'mywb.error.mid-required'"
          class="has-text-danger-500"
        />
      </wb-table-row-item>

      <wb-table-row-item v-if="payPerMonthRate || unassignPayPerMonth">
        <div
          v-if="charger.paymentsInfo.isPayPerMonthCompatible.isCompatible && charger.canAddEnergyRateOnPayPerMonth"
          class="rates-wrapper"
          data-test-id="newPayPerMonthRate"
        >
          <span v-if="unassignPayPerMonth" v-t="'mywb.charger.no-rate-assigned'" class="new-rate" />
          <span v-else class="new-rate">{{ payPerMonthRate }}</span>
        </div>

        <span
          v-else-if="!charger.paymentsInfo.isPayPerMonthCompatible.isCompatible &&
            charger.paymentsInfo.isPayPerMonthCompatible.reason"
          class="has-text-danger-500"
          data-test-id="notCompatible"
        >
          {{ getPaymentsTranslations(charger.paymentsInfo.isPayPerMonthCompatible.reason) }}
        </span>

        <span
          v-else
          v-t="'mywb.error.mid-required'"
          class="has-text-danger-500"
        />
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-button
          variant="white"
          outlined
          icon="delete"
          :data-test-id="`remove-charger-${charger.charger.id}`"
          @click="methods.removeCharger(charger)"
        />
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'

import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { canAddEnergyRate } from '@/utilities/charger/midInformation'
import type { TableProps, ExcludesNullish } from '@wallbox/toolkit-ui'
import type { ChargerWithRates } from '@/utilities/charger/assignedRates'
import { getPaymentsTranslations } from '@/utilities/translations'

const i18n = useI18n()

const emit = defineEmits(['on-update'])

interface Props {
  payPerChargeRate?: string
  payPerMonthRate?: string
  payPerMonthVariableType?: 'energy' | 'time' | 'access'
  payPerChargeVariableType?: 'energy' | 'time' | 'access'
  unassignPayPerCharge?: boolean
  unassignPayPerMonth?: boolean
  loading?: boolean
  chargersWithRates: ChargerWithRates[]
}

const props = defineProps<Props>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: i18n.t('mywb.common.charger'), align: 'left', minWidth: 170, width: 170 },
      (props.payPerChargeRate || props.unassignPayPerCharge) && {
        name: i18n.t('mywb.charger.pay-per-charge-rate'),
        align: 'left',
        minWidth: 150
      },
      (props.payPerMonthRate || props.unassignPayPerMonth) && {
        name: i18n.t('mywb.charger.pay-per-month-rate'),
        align: 'left',
        minWidth: 150
      },
      { name: '', align: 'left', minWidth: 70, width: 70 }
    ].filter(Boolean as unknown as ExcludesNullish) as TableProps['options']['columns'],
    resizable: true,
    empty: {
      icon: '',
      title: i18n.t('mywb.charger.no-chargers-selected')
    },
    stickyHead: '-3.8rem'
  }
})

const compute = reactive({
  chargerWithRates: computed(() => props.chargersWithRates.map(charger => ({
    ...charger,
    canAddEnergyRateOnPayPerMonth: (props.payPerMonthVariableType !== 'energy' ||
      canAddEnergyRate(charger.charger, state.organizations.getCurrentOrganization.countryCode)),
    canAddEnergyRateOnPayPerCharge: (props.payPerChargeVariableType !== 'energy' ||
      canAddEnergyRate(charger.charger, state.organizations.getCurrentOrganization.countryCode))
  }))),

  haveIncompatibilities: computed((): boolean => compute.chargerWithRates.some(
    charger =>
      !charger.paymentsInfo.isPayPerChargeCompatible.isCompatible ||
      !charger.paymentsInfo.isPayPerMonthCompatible.isCompatible ||
      !charger.canAddEnergyRateOnPayPerMonth ||
      !charger.canAddEnergyRateOnPayPerCharge
  ))
})

const methods = {
  removeCharger (charger: ChargerWithRates) {
    state.charger.setCheckCharger(charger.charger, false)
    emit('on-update')
  }
}

</script>

<style lang="postcss" scoped>
.rates-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.old-rate,
.new-rate {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.old-rate {
  color: var(--danger-500);
  max-width: 50%;
  min-width: 40px;
}

:deep(.wallbox-row-item) {
  overflow: visible;
  white-space: initial;
}
</style>
