<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.payments-configuration') }}
    </template>
  </shared-teleport-header>

  <wb-skeleton-loader
    :loading="data.loadingComponent"
    :type="mq.current !== 'mobile' ? 'page' : 'dots'"
  >
    <wb-alert
      v-if="!permissions.showBilling"
      icon="info"
      variant="info"
    >
      <h4
        v-t="'mywb.payments.conuntry-not-supported-title'"
        class="is-size-500 is-font-weight-500"
      />
      <p>
        <span v-t="'mywb.payments.conuntry-not-supported-desc-1'" /><br><br>
        <span>
          <strong v-t="'mywb.payments.conuntry-not-supported-desc-2'" />
          {{ state.config.countriesWithPayments.map(country => country.name).join(', ') }}
        </span>
      </p>
    </wb-alert>

    <container-component class="mt-32">
      <div class="grid-layout g-3">
        <div>
          <h3
            v-t="'mywb.payments.ready-monetise-chargers'"
            class="is-size-700 is-font-weight-500 mb-16 has-text-grey-900"
          />
          <wb-carousel :options="data.options" class="carousel-info">
            <wb-slide class="carousel-slide">
              <wb-card
                class="is-fullheight revenue-card"
                :headline="i18n.t('mywb.payments.card-1-title')"
                :subhead="i18n.t('mywb.payments.card-1-description')"
              >
                <template #place-upper-right>
                  <span class="wb-icons icon">dollar</span>
                </template>
              </wb-card>
            </wb-slide>

            <wb-slide class="carousel-slide">
              <wb-card
                class="is-fullheight revenue-card"
                :headline="i18n.t('mywb.payments.card-2-title')"
                :subhead="i18n.t('mywb.payments.card-2-description')"
              >
                <template #place-upper-right>
                  <span class="wb-icons icon">calendar</span>
                </template>
              </wb-card>
            </wb-slide>

            <wb-slide class="carousel-slide">
              <wb-card
                class="is-fullheight revenue-card"
                :headline="i18n.t('mywb.payments.card-3-title')"
                :subhead="i18n.t('mywb.payments.card-3-description')"
              >
                <template #place-upper-right>
                  <span class="wb-icons icon">ev_station</span>
                </template>
              </wb-card>
            </wb-slide>
          </wb-carousel>
        </div>

        <stripe-connection show-title internal-link />
      </div>
    </container-component>
  </wb-skeleton-loader>
</template>

<script setup lang="ts">
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'

import state from '@/state'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast, promises } from '@wallbox/toolkit-ui'
import { HttpError } from '@wallbox/http'
import { permissions } from '@/engine/clients'
import StripeConnection from '@/components/payments/StripeConnection.vue'
import { injectStrict, ORGANIZATION_USE_CASES } from '@/engine/injectors'
import { useMq } from 'vue3-mq'

const organizationUsesCases = injectStrict(ORGANIZATION_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const mq = useMq()

interface Data {
  connectionError: boolean
  countryError: boolean
  currencyError: boolean
  countryExpressError: boolean
  loadingComponent: boolean,
  loadingExpressButton: boolean
  accountLinked: 'unlinked' | 'pending' | 'error' | 'confirmed' | 'unlink',
  openResetAccountModal: boolean
  loadingContinueLinking: boolean
  loadingResetLinking: boolean
  pollingFinish: boolean
  hasBillingInfo: boolean
  options: any
}

const data = reactive<Data>({
  connectionError: false,
  countryError: false,
  currencyError: false,
  countryExpressError: false,
  loadingComponent: true,
  loadingExpressButton: false,
  accountLinked: 'unlinked',
  openResetAccountModal: false,
  loadingContinueLinking: false,
  loadingResetLinking: false,
  pollingFinish: false,
  hasBillingInfo: false,
  options: {
    pagination: false,
    slidesPerView: 1.1,
    spaceBetween: 8,
    loop: false,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        enabled: false,
        noSwiping: true,
        allowSlidePrev: false,
        allowSlideNext: false,
        loop: false,
        direction: 'vertical'
      }

    }
  }
})

const methods = {
  async confirmStripeLink () {
    if (route.query.accountLinked) {
      router.push({ query: {} }).catch(() => {})
      data.accountLinked = 'pending'
      data.pollingFinish = false

      try {
        await promises.polling(async () => {
          try {
            const organizations = await organizationUsesCases.getAllOrganizations()
            const currentGroupUid = state.organizations.getCurrentOrganization.groupUid
            const organization = organizations.find(organization => organization.groupUid === currentGroupUid)
            data.pollingFinish = organization?.paymentsAccount !== null
          } catch (error) {
            if (error instanceof HttpError && error.code === 6018) {
              data.accountLinked = 'error'
            }
          }
        }, { validate: () => data.pollingFinish || data.accountLinked === 'error', max: 20, delta: 2, timeout: 5000 })

        await organizationUsesCases.getAllOrganizations()
          .then(state.organizations.setOrganizations)

        if (state.organizations.getCurrentOrganization.paymentsAccount) {
          data.accountLinked = 'confirmed'
        } else {
          data.accountLinked = 'unlinked'
        }
      } catch (e) {
        data.accountLinked = 'unlink'
        toast.error(i18n.t('mywb.error.unexpected-error'))
      }
    }
  },

  async loadInitialData () {
    data.loadingComponent = true

    trackDataScreen('paymentslanding-stripe')
    data.loadingComponent = false

    await methods.confirmStripeLink()
  }
}

async function created () {
  await methods.loadInitialData()
}

created()
</script>

<style lang="postcss" scoped>
.grid-layout {
  @media (--desktop) {
    gap: 24px;
    display: grid;
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
  }
}

.carousel-info {
  width: 100%;
  margin-bottom: 24px;

  @media (--desktop) {
    margin-bottom: 0;
  }
}

.carousel-slide {
  height: auto!important;
}

.icon {
  border-radius: 999px;
  background: var(--grey-50);
  padding: 8px;
}

.revenue-card :deep(.headline) {
  font-size: var(--size-500)!important;
}

.revenue-card :deep(.subhead) {
  font-size: var(--size-400)!important;
  margin-top: 8px !important;
}
</style>
