import { reactive, toRefs, computed } from 'vue'
import { calendarOptions } from '@/utilities/chartSessions/chartOptions'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { PeriodicityEnum, AnalysisEnum } from '@/utilities/chartSessions/chartTypeEnums'
import type { VariableFeeType } from '@/core/rate'
import type { Location } from '@/core/location'
import { STATUSES, getIdsByStatuses } from '@/utilities/charger/chargerStatuses'
import type { ChargerType } from '@/core/charger'

export interface FilterState {
  chargersFilters: {
    serialNumber?: string
    query?: string
    chargerTypes?: ChargerType[]
    locations?: string[]
    statuses?: STATUSES
    firmwareAvailable?: boolean
  }
  locationsFilters: {
    text?: string
    locations?: Location[]
  }
  dashboardFilters: {
    dates: Date[]
    calendar: typeof calendarOptions[0]
    periodicity: PeriodicityEnum
    analysis: AnalysisEnum
    locations: Location[]
    users: Array<{ id: number, uid: string }>
  }
  accessConfigFilters: {
    text?: string
    users?: number[]
    groups?: number[]
    role?: string
    status?: string
  }
  sessionsFilters: {
    dates: Date[]
    location?: number
    charger?: number
    user?: number
    type?: number
  }
  ratesFilters: {
    text?: string
    fixedFee?: boolean[]
    variableType?: VariableFeeType[]
    assigned?: boolean[]
  }
  organizationsFilters: {
    profile: string[]
  }
  invoiceFilters: {
    status?: string
    type?: string
  }
}

const initialState = (): FilterState => ({
  chargersFilters: {},
  locationsFilters: {},
  dashboardFilters: {
    dates: [],
    calendar: calendarOptions[4],
    periodicity: PeriodicityEnum.MONTH,
    analysis: AnalysisEnum.LINEAR,
    locations: [],
    users: []
  },
  accessConfigFilters: {},
  sessionsFilters: {
    dates: []
  },
  ratesFilters: {},
  organizationsFilters: {
    profile: []
  },
  invoiceFilters: {}
})

const state = reactive(initialState())

const getters = {
  backendOrganizationsFilters: computed(() => [
    !!state.organizationsFilters.profile.length && {
      field: 'profile_id',
      operator: 'in',
      value: (state.organizationsFilters).profile.join('|')
    }
  ].filter(Boolean as unknown as ExcludesNullish)),

  backendChargerFilters: computed(() => {
    type ExcludesBooleans = <T>(x: T | false | null | undefined | '' | true) => x is T

    let hasToFilterByStatus
    let hasToConnectionStatus

    if (state.chargersFilters.statuses) {
      hasToFilterByStatus = true
      hasToConnectionStatus = 'online'
    }

    if (state.chargersFilters.statuses === STATUSES.DISCONNECTED) {
      hasToConnectionStatus = 'offline'
      hasToFilterByStatus = false
    }

    return [
      state.chargersFilters.serialNumber && {
        field: 'serial_number',
        operator: 'in',
        value: state.chargersFilters.serialNumber
      },

      state.chargersFilters.query && {
        field: 'query',
        operator: 'match',
        value: state.chargersFilters.query
      },

      state.chargersFilters.locations && state.chargersFilters.locations?.length > 0 && {
        field: 'location_id',
        operator: 'in',
        value: state.chargersFilters.locations.join(',')
      },

      hasToFilterByStatus && {
        field: 'status',
        operator: 'in',
        value: getIdsByStatuses([state.chargersFilters.statuses as STATUSES]).join(',')
      },

      hasToConnectionStatus && {
        field: 'connection_status',
        operator: 'eq',
        value: hasToConnectionStatus
      },

      state.chargersFilters.chargerTypes && state.chargersFilters.chargerTypes?.length > 0 && {
        field: 'model',
        operator: 'in',
        value: state.chargersFilters.chargerTypes.flatMap(type => type.chargers).join(',')
      },

      state.chargersFilters.firmwareAvailable && {
        field: 'software_update_available',
        operator: 'eq',
        value: state.chargersFilters.firmwareAvailable
      },

      state.chargersFilters.firmwareAvailable && {
        field: 'status',
        operator: 'not_in',
        value: getIdsByStatuses([
          STATUSES.CHARGING,
          STATUSES.DISCHARGING,
          STATUSES.UPDATING
        ]).join(',')
      },

      state.chargersFilters.firmwareAvailable && { field: 'connection_status', operator: 'eq', value: 'online' }
    ].filter(Boolean as unknown as ExcludesBooleans)
  }),

  backendSessionsFilters: computed(() => {
    const { startDate, endDate } = formatDates(state.sessionsFilters)

    return [
      !!startDate && {
        field: 'start_time',
        operator: 'gte',
        value: startDate
      },
      !!endDate && {
        field: 'start_time',
        operator: 'lte',
        value: endDate
      },
      !!state.sessionsFilters.location && {
        field: 'location_id',
        operator: 'eq',
        value: state.sessionsFilters.location
      },
      !!state.sessionsFilters.charger && {
        field: 'charger_id',
        operator: 'eq',
        value: state.sessionsFilters.charger
      },
      !!state.sessionsFilters.user && {
        field: 'user_id',
        operator: 'eq',
        value: state.sessionsFilters.user
      },
      !!state.sessionsFilters.type && {
        field: 'type',
        operator: 'eq',
        value: state.sessionsFilters.type
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  }),

  invoicesBackendFilters: computed(() => {
    const filters = []
    if ((state.invoiceFilters.type?.length ?? 0) > 0) {
      state.invoiceFilters.type && filters.push({
        field: 'type',
        operator: 'in',
        value: state.invoiceFilters.type
      })
    } else {
      filters.push({
        field: 'type',
        operator: 'in',
        value: 'post-payment|pay_per_charge'
      })
    }
    return [
      ...filters,
      state.invoiceFilters.status && {
        field: 'status',
        operator: 'eq',
        value: state.invoiceFilters.status
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
}

const setters = {
  setFilterValue<
    S extends keyof FilterState,
    F extends keyof FilterState[S]
  > ({ filter, key, value }: { filter: S, key: F, value: FilterState[S][F] }): void {
    state[filter][key] = value
  },

  resetFilters<S extends keyof FilterState> ({ filter }: { filter: S }) {
    state[filter] = initialState()[filter]
  },

  resetFilterValue<
    S extends keyof FilterState,
    F extends keyof FilterState[S]
  > ({ filter, key }: { filter: S, key: F }) {
    state[filter][key] = initialState()[filter][key]
  }
}

function formatDates (filters: FilterState['sessionsFilters']): { startDate: number, endDate: number } {
  const [startDate, endDate] = (filters.dates || []) as [Date, Date]

  let startDateFormatted = 0
  let endDateFormatted = 0

  if (startDate) {
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDateFormatted = Math.trunc(startDate.getTime() / 1000)
  }

  if (endDate) {
    const secondsDay = 86400
    endDate.setHours(0)
    endDate.setMinutes(0)
    endDateFormatted = Math.trunc(endDate.getTime() / 1000) + secondsDay
  }

  return {
    startDate: startDateFormatted,
    endDate: endDateFormatted
  }
}

export default reactive({
  ...toRefs(state),
  ...getters,
  ...setters
})
