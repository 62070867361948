import type { CountryIso2 } from '@/core/international'

export interface ChargerCreate {
  serial: number | string
  puk: string
  country: CountryIso2
  timezone: string
}

export interface ChargerCreateWithGroup extends ChargerCreate {
  group: number
}

export enum CREATE_CHARGER_ERROR_CODE {
  CHARGER_WITH_OWNER = 2304,
  INVALID_SERIAL = 2307
}

export interface ChargerCreateResponse {
  chargersAdded: number[]
  errors: Array<{
    serial: string
    code: CREATE_CHARGER_ERROR_CODE
    msg: string
  }>
}
