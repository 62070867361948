import UsersListView from '@/views/UsersListView.vue'
import UsersView from '@/views/UsersView.vue'
import UserView from '@/views/UserView.vue'
import AccessConfigsGroupsView from '@/views/AccessConfigsGroupsView.vue'
import type { RouteRecordRaw } from 'vue-router'
import ROUTES from './routes'

export default [
  {
    path: 'users',
    component: UsersView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.USERS,
        text: 'mywb.common.users'
      }
    },
    children: [
      {
        path: '',
        name: ROUTES.USERS,
        component: UsersListView,
        props: true
      },
      {
        path: 'groups',
        name: ROUTES.USERS_ACCESS_GROUPS,
        component: AccessConfigsGroupsView,
        props: true
      }
    ]
  },
  {
    path: 'user/:uid',
    name: ROUTES.USER_DETAIL,
    component: UserView,
    props: true,
    meta: {
      breadcrumb: {
        name: ROUTES.USER_DETAIL
      }
    }
  }
] as RouteRecordRaw[]
