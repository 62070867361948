<template>
  <dashboard-card
    title="mywb.common.organizations"
    subtitle="mywb.common.total-organizations-created"
    icon="business"
    :number="compute.totalOrganizations"
    :loading="props.loading"
    data-test-id="dashboardCard"
  >
    <template #dashboard-actions>
      <div />
      <wb-button
        data-test-id="viewAllOrganizations"
        :label="t('mywb.common.view-all')"
        :to="{ name: ROUTES.ORGANIZATIONS }"
        variant="white"
        icon="chevron_right"
        icon-position="right"
        size="small"
        content-position="right"
      />
    </template>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'
import ROUTES from '@/engine/router/routes'

const { t } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

const compute = reactive({
  totalOrganizations: computed((): number => {
    return state.organizations.allOrganizations.length
  })
})
</script>
