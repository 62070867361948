<template>
  <div class="wrapper-grid g-32">
    <wb-card class="menu-sticky">
      <template #content>
        <wb-menu
          v-model="data.selected"
          :items="compute.menu"
        />
      </template>
    </wb-card>

    <section class="content-grid g-24">
      <subscription-plan-account
        v-if="permissions.canChangePlan"
        id="plan"
        data-test-id="subscriptionPlanSection"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'plan'}"
      />

      <personal-data-account
        id="personal"
        :class="{ 'selected-blink': data.selected === 'personal'}"
        class="tablet-span-2"
      />

      <billing-information
        v-if="permissions.showBilling"
        id="billing"
        v-model:has-billing-info="data.hasBillingInfo"
        data-test-id="billingInformationSection"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'billing'}"
        :group-uid="state.organizations.getCurrentOrganization.groupUid"
      />

      <rfid-group-account
        id="rfid"
        data-test-id="rfidSection"
        :class="{ 'selected-blink': data.selected === 'rfid'}"
        class="tablet-span-2"
      />

      <password-account
        id="password"
        :class="{ 'selected-blink': data.selected === 'password'}"
        class="tablet-span-2"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import SubscriptionPlanAccount from '@/components/account/SubscriptionPlanAccount.vue'
import PersonalDataAccount from '@/components/account/PersonalDataAccount.vue'
import RfidGroupAccount from '@/components/account/RfidGroupAccount.vue'
import PasswordAccount from '@/components/account/PasswordAccount.vue'
import BillingInformation from '@/components/payments/BillingInformation.vue'

import { permissions } from '@/engine/clients'
import { computed, reactive, onMounted, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRoute } from 'vue-router'
import state from '@/state'
import type { ExcludesNullish, MenuItem } from '@wallbox/toolkit-ui'

const i18n = useI18n()
const route = useRoute()

interface Data {
  selected: string,
  hasBillingInfo: boolean
}
const data = reactive<Data>({
  selected: '',
  hasBillingInfo: false
})

const compute = reactive({
  menu: computed((): MenuItem[] => {
    return [
      permissions.canChangePlan && {
        name: 'plan',
        icon: 'assignment',
        label: i18n.t('mywb.common.plan')
      },
      {
        name: 'personal',
        icon: 'account_circle',
        label: i18n.t('mywb.user.personal-data')
      },
      permissions.showBilling && {
        name: 'billing',
        icon: 'business',
        label: i18n.t('mywb.common.billing-information')
      },
      {
        name: 'rfid',
        icon: 'rfid_card',
        label: i18n.t('mywb.common.rfid')
      },
      {
        name: 'password',
        icon: 'lock',
        label: i18n.t('mywb.common.password')
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
})

onMounted(() => {
  data.selected = route.hash?.replace('#', '')
})
</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 2fr 1fr;
  }
}

.tablet-span-2 {
  @media (--tablet) {
    grid-column: span 2;
  }
}

.menu-sticky {
  height: fit-content;
  display: none;
  position: sticky !important;
  top: 157px;

  @media (--tablet) {
    display: block;
  }
}
</style>
