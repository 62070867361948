import { inject, type App, type InjectionKey } from 'vue'
import { provideOrganizationUseCases } from '@/core/organization'
import { provideRateUseCases } from '@/core/rate'
import { provideUserUseCases } from '@/core/user'
import { provideSessionUseCases } from '@/core/session'
import { provideChargersUsesCases } from '@/core/charger'
import { provideLocationUseCases } from '@/core/location'
import { provideInternationalUseCases } from '@/core/international'
import { provideChargerPointUseCases } from '@/core/chargePoint'
import { provideAuthUseCases } from '@/core/auth'
import { provideInvoicesUseCases } from '@/core/invoices/InvoicesUseCaseProvider'
import { provideStripeUseCases } from '@/core/stripe'
import { provideAccessConfigUseCases } from '@/core/accessConfig'
import { provideConfigUseCases } from '@/core/config/ConfigUseCaseProvider'

type UsesCases<T extends () => any> = InjectionKey<ReturnType<T>>

export const CHARGER_USE_CASES: UsesCases<typeof provideChargersUsesCases> = Symbol('ChargerUseCases')
export const LOCATION_USE_CASES: UsesCases<typeof provideLocationUseCases> = Symbol('LocationUseCases')
export const ORGANIZATION_USE_CASES: UsesCases<typeof provideOrganizationUseCases> = Symbol('OrganizationUseCases')
export const RATE_USE_CASES: UsesCases<typeof provideRateUseCases> = Symbol('RateUseCase')
export const SESSION_USE_CASES: UsesCases<typeof provideSessionUseCases> = Symbol('SessionUseCase')
export const USER_USE_CASES: UsesCases<typeof provideUserUseCases> = Symbol('UserUseCases')
export const INTERNATIONAL_USE_CASES: UsesCases<typeof provideInternationalUseCases> = Symbol('InternationalUseCases')
export const CHARGE_POINT_USE_CASES: UsesCases<typeof provideChargerPointUseCases> = Symbol('ChargePointUseCases')
export const AUTH_USE_CASES: UsesCases<typeof provideAuthUseCases> = Symbol('AuthUsesCases')
export const INVOICES_USE_CASES: UsesCases<typeof provideInvoicesUseCases> = Symbol('InvoicesUseCases')
export const STRIPE_USE_CASES: UsesCases<typeof provideStripeUseCases> = Symbol('StripeUseCases')
export const ACCESS_CONFIG_USE_CASES: UsesCases<typeof provideAccessConfigUseCases> = Symbol('AccessConfigUseCases')
export const CONFIG_USE_CASES: UsesCases<typeof provideConfigUseCases> = Symbol('ConfigUseCases')

export default function initInjects (app: App) {
  const chargerUseCases = provideChargersUsesCases()
  const locationUseCases = provideLocationUseCases()
  const organizationUsesCase = provideOrganizationUseCases()
  const rateUsesCases = provideRateUseCases()
  const userUsesCase = provideUserUseCases()
  const sessionUsesCases = provideSessionUseCases()
  const internationalUsesCases = provideInternationalUseCases()
  const chargerPointUseCases = provideChargerPointUseCases()
  const authUseCases = provideAuthUseCases()
  const invoicesUseCases = provideInvoicesUseCases()
  const stripeUseCases = provideStripeUseCases()
  const accessConfigUseCases = provideAccessConfigUseCases()
  const configUseCases = provideConfigUseCases()

  app.provide(CHARGER_USE_CASES, chargerUseCases)
  app.provide(LOCATION_USE_CASES, locationUseCases)
  app.provide(ORGANIZATION_USE_CASES, organizationUsesCase)
  app.provide(RATE_USE_CASES, rateUsesCases)
  app.provide(USER_USE_CASES, userUsesCase)
  app.provide(SESSION_USE_CASES, sessionUsesCases)
  app.provide(INTERNATIONAL_USE_CASES, internationalUsesCases)
  app.provide(CHARGE_POINT_USE_CASES, chargerPointUseCases)
  app.provide(AUTH_USE_CASES, authUseCases)
  app.provide(INVOICES_USE_CASES, invoicesUseCases)
  app.provide(STRIPE_USE_CASES, stripeUseCases)
  app.provide(ACCESS_CONFIG_USE_CASES, accessConfigUseCases)
  app.provide(CONFIG_USE_CASES, configUseCases)
}

export function injectStrict<T> (key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback)

  if (!resolved) {
    throw new Error(`Could not resolve ${key.description ?? ''}`)
  }

  return resolved
}
