<template>
  <wb-empty-state
    icon="wallbox_logo_filled"
    :title="i18n.t('mywb.error.not-page-found.title')"
    :subtitle="i18n.t('mywb.error.not-page-found.description')"
    class="mt-64"
  >
    <wb-button
      icon="home"
      :label="i18n.t('mywb.common.home-page')"
      :to="{ name:'home'}"
      variant="white"
      outlined
    />
  </wb-empty-state>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
</script>
