<template>
  <shared-teleport-header>
    <template v-if="data.rateId" #title>
      {{ i18n.t('mywb.common.edit-rate') }}
    </template>

    <template v-else #title>
      {{ i18n.t('mywb.common.create-rate') }}
    </template>
  </shared-teleport-header>

  <section class="create-rate g-32" data-test-id="createRateSection">
    <wb-card class="menu-sticky">
      <template #content>
        <wb-menu
          v-model="data.selectedMenu"
          :items="data.menu"
        />
      </template>
    </wb-card>

    <div class="form g-24">
      <wb-card
        id="details"
        :class="{ 'selected-blink': data.selectedMenu === 'details' }"
      >
        <template #content>
          <wb-input
            v-model="data.rate.name"
            data-test-id="createRateNameInput"
            :error="errors.name"
            :label="i18n.t('mywb.common.name')"
            :placeholder="i18n.t('mywb.rates.rate-name')"
          />
        </template>
      </wb-card>

      <wb-alert
        variant="info"
        icon="info_filled"
      >
        <p v-t="'mywb.common.regulations-text'" />
      </wb-alert>

      <wb-alert
        v-if="compute.minimumChargeAmount && data.paymentType === 'pay_per_charge'"
        variant="info"
        icon="info_filled"
      >
        <!-- eslint-disable-next-line -->
        <p v-html="i18n.t('mywb.currencies.minimum-charge-amount.html', { amount: compute.minimumChargeAmount })" />
      </wb-alert>

      <wb-skeleton-loader :loading="data.loadingEntry" type="card">
        <variable-fee-card
          id="settings"
          v-model:isActivated="data.isVariableFeeActivated"
          data-test-id="createRateVariableFeeToogle"
          :class="{ 'selected-blink': data.selectedMenu === 'settings' }"
        />
      </wb-skeleton-loader>

      <wb-skeleton-loader :loading="data.loadingEntry" type="card">
        <fixed-fee-card
          v-model:isActivated="data.isFixedFeeActivated"
          data-test-id="createRateFixedFeeToogle"
          :class="{ 'selected-blink': data.selectedMenu === 'settings' }"
        />
      </wb-skeleton-loader>
      <div class="actions">
        <wb-button
          variant="white"
          outlined
          data-test-id="createRateCancelButton"
          :label="i18n.t('mywb.common.cancel')"
          :to="{ name: ROUTES.PAYMENTS_RATES }"
        />

        <wb-button
          data-test-id="createRateSaveButton"
          :loading="data.loading"
          :disabled="compute.isDisabled"
          :label="i18n.t('mywb.common.save')"
          @click="validate(methods.upsertRate)"
        />
      </div>
    </div>

    <estimation-revenue
      class="is-sticky"
      :rate="data.rate"
      :errors="errors"
      :is-variable-fee-activated="data.isVariableFeeActivated"
      :is-fixed-fee-activated="data.isFixedFeeActivated"
    />
  </section>
</template>

<script setup lang="ts">
import state from '@/state'
import EstimationRevenue from '@/components/rates/EstimationRevenue.vue'
import VariableFeeCard from '@/components/rates/VariableFeeCard.vue'
import FixedFeeCard from '@/components/rates/FixedFeeCard.vue'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'

import { useFormValidator, useValidatedRate } from '@/utilities/rates/useFormValidation'
import { trackDataEvent, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import { useRoute, useRouter } from 'vue-router'
import { reactive, computed, ref, watchEffect, type Ref } from 'vue'
import { getMinimumChargeAmount } from '@/utilities/rates/ratesPriceLimit'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import type { VariableFeeType } from '@/core/rate'
import type { MenuItem } from '@wallbox/toolkit-ui'
import ROUTES from '@/engine/router/routes'

const rateUseCases = injectStrict(RATE_USE_CASES)

const i18n = useI18n()
const router = useRouter()
const route = useRoute()

interface Props {
  rateId?: string
}
const props = defineProps<Props>()

const isVariableFeeActivated = ref(false)
const isFixedFeeActivated = ref(false)

const { errors, validate } = useFormValidator(
  isVariableFeeActivated,
  isFixedFeeActivated
)

interface Data {
  rate: ReturnType<typeof useValidatedRate>['field']
  selectedMenu?: string
  menu: MenuItem[],
  loading: boolean
  loadingEntry: boolean
  isVariableFeeActivated: Ref<boolean>
  isFixedFeeActivated: Ref<boolean>
  chargers?: string[]
  paymentType?: 'pay_per_charge' | 'pay_per_month'
  rateId?: string
}

const data = reactive<Data>({
  rate: useValidatedRate().field,
  selectedMenu: undefined,
  menu: [
    {
      name: 'details',
      icon: 'assignment',
      label: i18n.t('mywb.rates.rate-details')
    },
    {
      name: 'settings',
      icon: 'dollar',
      label: i18n.t('mywb.rates.fees-settings')
    }
  ],

  loading: false,
  loadingEntry: false,
  isVariableFeeActivated,
  isFixedFeeActivated,
  chargers: undefined,
  paymentType: undefined,
  rateId: props.rateId
})

const compute = reactive({
  isCreating: computed(() => !data.rateId),

  isDisabled: computed(() => !data.isVariableFeeActivated && !data.isFixedFeeActivated),

  minimumChargeAmount: computed(() => {
    return numbers.toLocaleCurrencySymbol(
      getMinimumChargeAmount(state.organizations.getCurrentOrganization.currencyCode),
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  })
})

const methods = {
  async upsertRate () {
    data.loading = true

    const payload = {
      groupUid: state.organizations.getCurrentOrganization.groupUid,
      rate: {
        name: data.rate.name as string,
        fixedFee: (data.isFixedFeeActivated ? data.rate.fixedFee : 0) as number,
        variableFeePrice: (data.isVariableFeeActivated ? data.rate.variablePrice : 0) as number,
        variableFeeType: (data.isVariableFeeActivated ? data.rate.variableType : 'access') as VariableFeeType
      }
    }

    const rateTrack = {
      rate_name: data.rate.name,
      rate_fixed_access_fee: data.isFixedFeeActivated ? data.rate.fixedFee : 0,
      rate_variable_fee: data.isVariableFeeActivated ? data.rate.variablePrice : 0,
      rate_variable_type: data.isVariableFeeActivated ? data.rate.variableType : 'access'
    }

    if (compute.isCreating || !data.rateId) {
      data.rateId = await rateUseCases.createRate(payload)

      trackDataEvent('update-rate', rateTrack)
    } else {
      await rateUseCases.editRate({ ...payload, rateId: data.rateId })
      trackDataEvent('update-rate', rateTrack)
    }

    const route = state.routes.getLastRoute()

    router.push(route)
    data.loading = false
  }
}

async function created () {
  trackDataScreen(compute.isCreating ? 'create-rate' : 'edit-rate')

  if (!compute.isCreating && data.rateId) {
    data.loadingEntry = true

    const attributes = await rateUseCases.getRate({
      groupUid: state.organizations.getCurrentOrganization.groupUid,
      rateId: data.rateId
    })

    data.loadingEntry = false
    if (!attributes) return

    data.rate.name = attributes.name
    data.rate.fixedFee = attributes.fixedFee
    data.rate.variableType = attributes.variableFeeType !== 'access' ? attributes.variableFeeType : undefined
    data.rate.variablePrice = attributes.variableFeeType !== 'access' ? attributes.variableFeePrice : undefined

    data.isVariableFeeActivated = attributes.variableFeeType !== 'access'
    data.isFixedFeeActivated = attributes.fixedFee > 0
  } else {
    errors.variablePrice = ''
    errors.fixedFee = ''
  }

  data.chargers = route.query.chargers as string[]
  data.paymentType = route.query.paymentType as 'pay_per_charge' | 'pay_per_month'
}

watchEffect(() => {
  data.selectedMenu && document.getElementById(data.selectedMenu)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
})

created()
</script>

<style lang="postcss" scoped>
.create-rate {
  display: grid;
  grid-template-columns: auto;

  @media (--tablet) {
    grid-template-columns: max-content auto 20%;
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.is-sticky {
  @media (--tablet) {
    position: sticky;
    top: 10rem;
    height: 4.6rem;
  }
}

.menu-sticky {
  display: none;

  @media (--tablet) {
    display: block;
    position: sticky!important;
    top: 105px!important;
    height: fit-content;
  }
}
</style>
