import yup from '@/hooks/useValidator.hook'

const passwordUpdateSchema = yup.object({
  userId: yup.string().required(),
  password: yup.string()
    .passwordValidFormat()
    .required()
    .notOneOf([yup.ref('currentPassword')]),
  currentPassword: yup.string().required()
})

export type PasswordUpdate = yup.InferType<typeof passwordUpdateSchema>

export async function ensurePasswordUpdateIsValid (passwordUpdate: any) {
  return await passwordUpdateSchema.validate(passwordUpdate, { abortEarly: false })
}
