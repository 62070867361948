import {
  wapiOrganizationRepository,
  getAll,
  create,
  edit,
  getBillingInfo,
  editBillingInfo
} from '@/core/organization'

export function provideOrganizationUseCases () {
  const repo = wapiOrganizationRepository()

  return {
    getAllOrganizations: getAll(repo),
    createOrganization: create(repo),
    editOrganization: edit(repo),
    getBillingInfo: getBillingInfo(repo),
    editBillingInfo: editBillingInfo(repo)
  }
}
