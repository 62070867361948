import yup from '@/hooks/useValidator.hook'

const rfidDeleteSchema = yup.object({
  userId: yup.lazy((value) => typeof value === 'number'
    ? yup.number().required()
    : yup.string().required()
  ),
  groupUid: yup.string().required()
})

export type RfidDelete = yup.InferType<typeof rfidDeleteSchema>

export async function ensureDeleteRfidIsValid (rfidDelete: any) {
  return await rfidDeleteSchema.validate(rfidDelete, { abortEarly: false })
}
