<template>
  <wb-skeleton-loader type="dots" :loading="data.loading">
    <wb-form class="form mt-24">
      <wb-input
        v-model="data.location.name"
        name="location"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="data.errors.name"
      />

      <wb-alert
        icon="info_filled"
        :variant="data.onLocationError ? 'danger' : 'info'"
        class="is-fullwidth"
      >
        {{ data.onLocationError
          ? i18n.t('mywb.error.invalid-address', { country: data.organizationCountryName })
          : i18n.t('mywb.location.address-info', { country: data.organizationCountryName })
        }}
      </wb-alert>

      <google-maps-and-address-input-form
        v-model:address="data.location.address"
        v-model:latitude="data.location.latitude"
        v-model:longitude="data.location.longitude"
        :component-restrictions="{ country: data.location.country ?? null }"
        :address-error="data.errors.address"
        data-test-id="googleMapsAddressInput"
        @on-place-update="methods.saveAddress"
      />

      <country-and-state-select-form
        :ref="setRef('countryState')"
        v-model:countryCode="data.location.country"
        v-model:stateCode="data.location.state"
        is-country-disabled
        country-key="iso2"
        class="is-fullwidth"
      />

      <wb-input
        v-model="data.location.city"
        :error="data.errors.city"
        data-test-id="locationCity"
        :label="i18n.t('mywb.common.city')"
      />

      <wb-input
        v-model="data.location.zipCode"
        data-test-id="locationZipCode"
        :error="data.errors.zipCode"
        :label="i18n.t('mywb.common.postal-code')"
      />

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.energy-type'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.energy-type-description'"
          class="is-size-400 mb-16"
        />
        <div class="energy-grid g-12">
          <checkbox-card-form
            v-model="data.location.energyType"
            value="renewable"
            icon="leaf"
            data-test-id="renewableOption"
            :title="i18n.t('mywb.common.renewable')"
          />
          <checkbox-card-form
            v-model="data.location.energyType"
            value="nonrenewable"
            icon="bolt"
            data-test-id="nonRenewableOption"
            :title="i18n.t('mywb.common.non-renewable')"
          />
          <checkbox-card-form
            v-model="data.location.energyType"
            value="unknown"
            data-test-id="unknownOption"
            :title="i18n.t('mywb.common.unknown')"
          />
        </div>
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.common.energy-cost'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.location-cost-description'"
          class="is-size-400 mb-16"
        />
        <wb-input
          v-model="data.location.energyCost"
          name="energyCost"
          data-test-id="energyCostInput"
          :label-right="`${state.organizations.getCurrentOrganization.currencyCode} / ${i18n.t('mywb.common.kwh')}`"
          type="number"
          min="0"
          step="0.01"
          :error="data.errors.energyCost"
        />
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.location-type'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.location-type-description'"
          class="is-size-400 mb-16"
        />
        <wb-select
          v-model="data.location.locationTypes"
          uid="_location_types"
          data-test-id="locationTypesSelector"
          :options="data.options"
          :close-on-select="false"
          option-label="value"
          :reduce="(item: LocationTypeArray) => item.key"
          clearable
          multiple
        />
      </div>

      <div class="is-fullwidth">
        <h3
          v-t="'mywb.location.instructions'"
          class="is-size-400 is-font-weight-500"
        />
        <p
          v-t="'mywb.location.instructions-description'"
          class="is-size-400 mb-16"
        />
        <wb-input
          v-model="data.location.instructions"
          is-textarea
          :rows="5"
          data-test-id="locationInstructions"
        />
      </div>
    </wb-form>
  </wb-skeleton-loader>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import GoogleMapsAndAddressInputForm from '@/components/forms/GoogleMapsAndAddressInputForm.vue'
import CheckboxCardForm from '@/components/forms/CheckboxCardForm.vue'
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'

import state from '@/state'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { locationTypesArray, type LocationTypeArray } from '@/utilities/locationTypesEnum'
import type { Locations } from '@/types'
import { injectStrict, LOCATION_USE_CASES } from '@/engine/injectors'
import { ensureCreateLocationIsValid, type Location } from '@/core/location'
import { useValidator } from '@/hooks/useValidator.hook'

const locationUseCases = injectStrict(LOCATION_USE_CASES)
const { refs, setRef } = useTemplateRef()
const i18n = useI18n()
const { errors, validate, validateTo } = useValidator()

interface PropsType {
  locationId?: string
}

const props = defineProps<PropsType>()

validateTo(async () => !!await ensureCreateLocationIsValid(data.location))

interface DataType {
  location: Partial<Location>
  options: LocationTypeArray[]
  errors: Record<string, string | undefined>
  onLocationError: boolean
  organizationCountryName: string
  loading: boolean
}

const data: DataType = reactive({
  location: {},
  options: locationTypesArray,
  errors,
  onLocationError: false,
  loading: false,
  organizationCountryName: ''
})

type Events = {
  (e: 'on-edit', id: string): void
}

const emit = defineEmits<Events>()

const methods = {
  saveAddress (place: Locations.Place) {
    data.location.address = place.address
    data.location.latitude = place.latitude
    data.location.longitude = place.longitude
    data.location.city = place.city
    data.location.country = place.country

    if (place.zipCode) {
      data.location.zipCode = place.zipCode
    }
  },

  async isValidateForm () {
    data.onLocationError = false
    const validCountryState = await refs.countryState.isValidCountryState()
    const isValid = await validate()
    return (validCountryState && isValid)
  },

  async isSubmitForm () {
    try {
      const location = {
        id: props.locationId ?? '',
        organizationId: state.organizations.getCurrentOrganization.groupUid,
        name: data.location.name ?? '',
        address: data.location.address ?? '',
        energyType: data.location.energyType ?? 'unknown',
        locationTypes: data.location.locationTypes,
        instructions: data.location.instructions ?? '',
        city: data.location.city ?? '',
        state: data.location.state ?? '',
        zipCode: data.location.zipCode ?? '',
        country: data.location.country ?? 'ES' as const,
        latitude: data.location.latitude ?? 1,
        longitude: data.location.longitude ?? 1,
        energyCost: data.location.energyCost ?? 0
      }

      if (!props.locationId) {
        const id = await locationUseCases.createLocation(location)
        emit('on-edit', id)
      } else {
        await locationUseCases.editLocation(location)
        emit('on-edit', location.id)
      }
      return location
    } catch (error) {
      data.onLocationError = true
      throw new Error(error as string)
    }
  }
}

async function created () {
  data.loading = true
  trackDataScreen(!props.locationId ? 'create-location' : 'edit-location')

  if (props.locationId) {
    const location = await locationUseCases.getLocation(props.locationId)
    if (location) {
      data.location = location
    }
  } else {
    data.location.country = state.organizations.getCurrentOrganization.countryCode
    data.location.organizationId = state.organizations.getCurrentOrganization.groupUid
  }

  data.location.energyType = data.location.energyType ?? 'unknown'

  data.organizationCountryName = state.global.getCountries.find(
    country => country.iso2 === data.location.country)?.name || ''

  data.loading = false
}

created()

defineExpose({
  validateForm: () => methods.isValidateForm(),
  submitForm: () => methods.isSubmitForm(),
  isLoading: () => data.loading
})
</script>

<style lang="postcss" scoped>
.form {
  grid-template-columns: 100%;
}

.energy-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
