<template>
  <wb-modal
    width="80"
    @close="methods.close"
  >
    <template #title>
      {{ i18n.t('mywb.common.payments-settings') }}
    </template>

    <charger-settings-assign-rate
      v-if="chargers?.[0]"
      class="mb-24"
      :charger="props.charger"
      :schedule="data.schedules[props.charger.uid]?.find(schedule => schedule.paymentType === 'pay_per_charge')"
      :rates="data.rates"
      :loading="data.loading || data.loadingAssignedRates"
      data-test-id="chargerSettingsAssignRatePayPerCharge"
      type="payPerCharge"
      :compatible="chargers[0].paymentsInfo.isPayPerChargeCompatible"
      allow-hourly-selection
    />

    <charger-settings-assign-rate
      v-if="chargers?.[0]"
      :charger="props.charger"
      :rates="data.rates"
      :schedule="data.schedules[props.charger.uid]?.find(schedule => schedule.paymentType === 'pay_per_month')"
      :loading="data.loading || data.loadingAssignedRates"
      :compatible="chargers[0].paymentsInfo.isPayPerMonthCompatible"
      data-test-id="chargerSettingsAssignRatePayPerMonth"
      type="payPerMonth"
      allow-hourly-selection
    />

    <template #actions>
      <wb-button
        data-test-id="locationAssignRateChargerBtn"
        :label="i18n.t('mywb.common.done')"
        :loading="data.saveLoading"
        @click="methods.close"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargerSettingsAssignRate
  from '@/components/charger/ChargerSettingsAssignRate.vue'
import state from '@/state'
import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'
import type { Rate } from '@/core/rate'
import type { Charger as ChargerCore } from '@/core/charger'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'

const rateUseCases = injectStrict(RATE_USE_CASES)

const i18n = useI18n()

const emit = defineEmits(['close', 'rates-assigned'])

interface Props {
  charger: ChargerCore
}
const props = defineProps<Props>()

const {
  chargers,
  getData: getAssignedRates,
  loading: loadingAssignedRates,
  schedules
} = useAssignedRatesApi()

interface Data {
  rates: Rate[],
  loadingAssignedRates: typeof loadingAssignedRates
  loading: boolean
  saveLoading: boolean
  schedules: typeof schedules
}
const data = reactive<Data>({
  rates: [],
  loadingAssignedRates,
  loading: false,
  saveLoading: false,
  schedules
})

const methods = {
  close () {
    emit('close')
    emit('rates-assigned')
  }
}

async function created () {
  data.loading = true

  const { data: rates } = await rateUseCases.getAllRates({
    groupUid: state.organizations.getCurrentOrganization.groupUid
  })
  data.rates = rates

  data.loading = false

  getAssignedRates([props.charger])
}

created()
</script>
