import type { EnumRoles } from '@/utilities/user-roles'
import { number, array, object, string, type InferType, boolean, mixed } from 'yup'

const inviteUsersToAccessConfigSchema = object({
  role: mixed<EnumRoles>().required(),
  users: array().of(object({
    email: string().email().required(),
    rfid: string()
  })).required(),
  hasPayPerMonth: boolean().required(),
  accessConfig: number().required(),
  lang: string().required(),
  branding: string().required()
})

export type InviteUsersToAccessConfig = InferType<typeof inviteUsersToAccessConfigSchema>

export async function ensureInviteUsersToAccessConfigSchemaIsValid (payload: any) {
  return await inviteUsersToAccessConfigSchema.validate(payload, { abortEarly: false })
}
