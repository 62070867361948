<template>
  <div class="charger-schedule">
    <div class="schedule-wrapper">
      <div class="days">
        <div v-for="day in data.days" :key="day">
          {{ day }}
        </div>
      </div>

      <div class="hours">
        <div v-for="(hour, index) in data.hours" :key="index">
          {{ hour }}
        </div>
      </div>

      <div class="schedule">
        <template v-for="(day, index) in props.schedule" :key="index">
          <wb-dropdown
            v-for="(rate, rateIndex) in day"
            :key="rateIndex"
            tooltip-position="right"
            :tooltip-distance-offset="16"
            class="dropdown"
          >
            <template #activator>
              <div
                class="rate rate-cell"
                data-test-id="rate-cell"
                :data-test-value="rate"
                :class="{
                  decolorize: props.rateBeingAdded && rateBeingAdded?.id !== rate
                }"
                :style="compute.rateParsed.find(rateObject => rateObject.id === rate)?.style"
              />
            </template>

            <template #tooltip>
              {{ `${rateIndex}`.padStart(2, '0') }}:00-{{ `${rateIndex}`.padStart(2, '0') }}:59
            </template>
          </wb-dropdown>
        </template>
      </div>
    </div>

    <div class="rates" data-test-id="assignedRates">
      <h4
        v-t="'mywb.rates.assigned-rates'"
        class="is-size-400 is-font-weight-500 has-text-grey-800 mb-12"
      />

      <div
        v-for="(rate, index) in compute.rateParsed"
        :key="index"
        class="rate-item"
        data-test-id="rate-item"
        :data-test-value="rate.id"
        :class="{
          decolorize: props.rateBeingAdded && rateBeingAdded?.id !== rate.id
        }"
      >
        <div
          class="rate-list-color rate"
          :style="rate.style"
        />

        <div>
          <p class="is-size-300 is-font-weight-500">
            {{ rate.name }}
          </p>
          <p class="is-size-300">
            <span v-if="rate.fixedFee"> {{ rate.fixedFeePriceParsed }}</span>
            <span v-if="rate.fixedFee && rate.variableFeePriceParsed">&nbsp;+&nbsp;</span>
            <span v-if="rate.variableFeePriceParsed">{{ rate.variableFeePriceParsed }} {{ rate.variableLabel }} </span>
          </p>
        </div>

        <wb-button
          v-if="props.hasActions && props.rates.length > 1"
          variant="danger"
          inverted
          icon="delete"
          @click="emit('delete-rate', rate)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import type { Rate, RateSchedule } from '@/core/rate'
import { useI18n } from '@/hooks/useI18n.hook'
import { numbers } from '@wallbox/toolkit-ui'
import state from '@/state'

const i18n = useI18n()

interface PropsType {
  schedule?: RateSchedule
  rates: Rate[]
  hasActions?: boolean,
  rateBeingAdded?: Rate
  type: 'pay_per_charge' | 'pay_per_month'
}

const props = withDefaults(defineProps<PropsType>(), {
  schedule: () => ({
    monday: Array.from(Array(24).keys()).map(() => ''),
    tuesday: Array.from(Array(24).keys()).map(() => ''),
    wednesday: Array.from(Array(24).keys()).map(() => ''),
    thursday: Array.from(Array(24).keys()).map(() => ''),
    friday: Array.from(Array(24).keys()).map(() => ''),
    saturday: Array.from(Array(24).keys()).map(() => ''),
    sunday: Array.from(Array(24).keys()).map(() => '')
  }),

  rateBeingAdded: undefined
})

interface Events {
  (e: 'edit-rate', rate: Rate): void
  (e: 'delete-rate', rate: Rate): void
}

const emit = defineEmits<Events>()

const data = reactive({
  days: [
    i18n.t('mywb.days.monday-short'),
    i18n.t('mywb.days.tuesday-short'),
    i18n.t('mywb.days.wednesday-short'),
    i18n.t('mywb.days.thursday-short'),
    i18n.t('mywb.days.friday-short'),
    i18n.t('mywb.days.saturday-short'),
    i18n.t('mywb.days.sunday-short')
  ],
  hours: Array.from({ length: 24 }, (_, i) => `${i}:00h`)
})

const compute = reactive({
  hasToShowRateBeingAdded: computed((): boolean => {
    const rates: string[] = Object.values(props.schedule).flat()
    return rates.some(rate => rate === props.rateBeingAdded?.id)
  }),

  rateParsed: computed(():
    Array<Rate & {
      fixedFeePriceParsed: string,
      variableFeePriceParsed: string,
      variableLabel: string,
      style: Record<string, string>
    }> => {
    return props.rates
      .filter(rate => (rate.id !== props.rateBeingAdded?.id) || compute.hasToShowRateBeingAdded)
      .map(rate => ({
        ...rate,
        style: state.rates.getStyleByRate(rate.id, props.type),
        fixedFeePriceParsed: numbers
          .toLocaleCurrencySymbol(
            rate.fixedFee,
            state.organizations.getCurrentOrganization.currencyCode,
            i18n.locale.value
          ),
        variableFeePriceParsed: numbers
          .toLocaleCurrencySymbol(
            rate.variableFeePrice,
            state.organizations.getCurrentOrganization.currencyCode,
            i18n.locale.value
          ),
        variableLabel: methods.getLabel(rate)
      }))
  })
})

const methods = {
  getLabel (rate: Rate) {
    if (rate.variableFeeType === 'energy') {
      return i18n.t('mywb.common.cost-per-kwh')
    } else if (rate.variableFeeType === 'time') {
      return i18n.t('mywb.common.cost-per-hour')
    }
    return ''
  }
}
</script>

<style lang="postcss">
  :root {
    --color-1-500: #009b86;
    --color-2-500: #518df7;
    --color-3-500: #fac54f;
    --color-4-500: #c55c5d;
    --color-5-500: #8665a9;
  }
</style>

<style lang="postcss" scoped>
.charger-schedule {
  display: grid;
  gap: 32px;

  @media (--tablet) {
    grid-template-columns: 2fr 1fr;
  }
}

.schedule-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 50px auto;
  grid-template-rows: 20px auto;
  grid-template-areas:
    ". days"
    "hours schedule";
  font-size: 10px;
  color: var(--grey-500)
}

.days {
  grid-area: days;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.hours {
  grid-area: hours;
  display: grid;
  grid-template-rows: repeat(24, minmax(15px, min-content));
  text-align: right;
  padding-right: 5px;
  gap: 1px;
}

.schedule {
  grid-area: schedule;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(24, minmax(15px, min-content));
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  border-radius: 8px;
  overflow: hidden;
}

.rate {
  background: var(--primary-500);
}

.rate-cell,
.dropdown :deep(> div:first-of-type) {
  height: 100%;
  width: 100%;
}

.rate-list-color {
  width: 24px;
  height: 24px;
  border-radius: 20px;
}

.rate-item {
  display: grid;
  grid-template-columns: auto 1fr max-content;
  gap: 0  8px;
  border-bottom: 1px solid var(--grey-100);
}

.rates {
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;
}

.decolorize {
  opacity: 0.3;
}
</style>
