<template>
  <teleport to="#teleport-header">
    <shared-header v-bind="{ ...props, ...$attrs }">
      <template v-if="$slots.title" #title>
        <slot name="title" />
      </template>

      <template v-if="$slots['custom-title']" #custom-title>
        <slot name="custom-title" />
      </template>

      <template v-if="$slots.actions" #actions>
        <slot name="actions" />
      </template>
      <template v-if="$slots.filters" #filters>
        <slot name="filters" />
      </template>

      <template v-if="$slots['secondary-actions']" #secondary-actions>
        <slot name="secondary-actions" />
      </template>

      <template v-if="$slots.tabs" #tabs>
        <slot name="tabs" />
      </template>
    </shared-header>
  </teleport>
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'

interface Props {
  hideBreadcrumb?: boolean
  stickyTop?: string
}

const props = withDefaults(defineProps<Props>(), {
  hideBreadcrumb: false,
  stickyTop: '0px'
})
</script>
