import ChargePointsView from '@/views/ChargePointsView.vue'
import type { RouteRecordRaw } from 'vue-router'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import ROUTES from './routes'

function routes () {
  let routes: RouteRecordRaw[] = []

  if (hasFeatureFlagActive('ocpp')) {
    routes = [
      {
        path: 'charge-points',
        name: ROUTES.CHARGE_POINTS,
        component: ChargePointsView,
        props: true,
        meta: {
          resetBreadcrumb: true,
          breadcrumb: {
            name: ROUTES.CHARGE_POINTS,
            text: 'mywb.common.charge-points'
          }
        }
      }
    ]
  }

  return routes
}

export default routes
