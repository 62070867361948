<template>
  <wb-form class="justify-end">
    <wb-input
      v-model="compute.nameModel"
      :label="t('mywb.charge-point.charger-name')"
    />

    <wb-select
      v-model="compute.organizationSelected"
      :options="compute.organizations"
      :label="t('mywb.common.organization')"
      option-label="name"
    />

    <location-layout
      id="locationSelector"
      v-model="compute.locationModel"
      :locations="data.locations"
      :loading="data.loading"
      @on-create-location="emit('add-location')"
    />

    <wb-button
      :disabled="!compute.nameModel || !compute.locationModel"
      @click="emit('click')"
    >
      {{ t('mywb.common.continue' ) }}
    </wb-button>
  </wb-form>
</template>

<script setup lang="ts">
import { computed, reactive, watchEffect } from 'vue'
import state from '@/state'
import { EnumRoles } from '@/utilities/user-roles'
import type { Organization } from '@/core/organization'
import LocationLayout from '@/components/locations/LocationLayout.vue'
import type { Location } from '@/core/location'
import { LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const locationUseCases = injectStrict(LOCATION_USE_CASES)

interface Props {
  name?: string
  location?: Location
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:name', name?: string): void,
  (e: 'update:location', location?: Location): void,
  (e: 'click'): void,
  (e: 'add-location'): void
}

const emit = defineEmits<Events>()

interface Data {
  locations: Location[]
  loading: boolean
}

const data = reactive<Data>({
  locations: [],
  loading: false
})

const compute = reactive({
  nameModel: computed({
    get () {
      return props.name
    },

    set (value) {
      emit('update:name', value)
    }
  }),

  locationModel: computed({
    get () {
      return props.location
    },

    set (value) {
      emit('update:location', value)
    }
  }),

  organizations: computed(() => {
    return state.organizations.allOrganizations.filter(
      organization => organization.role.id === EnumRoles['super-admin']
    )
  }),

  organizationSelected: computed({
    get: (): Organization => compute.organizations
      .find(organization => organization.id === state.organizations.getCurrentOrganization.id) as Organization,

    set: organization => {
      state.organizations.setCurrentOrganization(organization.id)
    }
  })
})

watchEffect(async () => {
  data.loading = true
  data.locations = await locationUseCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
    cache: 'network',
    getChargers: false
  })

  data.loading = false
})
</script>

<style lang="postcss" scoped>
.justify-end {
  justify-items: end!important;
}
</style>
