<template>
  <div
    class="auth-layout g-64 tablet:g-16"
    :class="{ 'auth-layout-carousel': permissions.showCarouselLogin }"
  >
    <wb-carousel
      v-if="permissions.showCarouselLogin"
      class="carousel is-font-weight-700"
      :options="data.options"
      dots-classes="has-text-left ml-64 mb-64"
    >
      <auth-slide-component
        :title="i18n.t('mywb.auth.carousel-slide-2-title')"
        :subtitle="i18n.t('mywb.auth.carousel-slide-2-subtitle')"
      >
        <source srcset="/src/assets/images/map.webp" type="image/webp">
        <source srcset="/src/assets/images/map.jpg" type="image/jpeg">
        <img src="/src/assets/images/map.jpg" alt="slide">
      </auth-slide-component>

      <auth-slide-component
        :title="i18n.t('mywb.auth.carousel-slide-1-title')"
        :subtitle="i18n.t('mywb.auth.carousel-slide-1-subtitle')"
      >
        <source srcset="/src/assets/images/ppc.webp" type="image/webp">
        <source srcset="/src/assets/images/ppc.jpg" type="image/jpeg">
        <img src="/src/assets/images/ppc.jpg" alt="slide">
      </auth-slide-component>

      <auth-slide-component
        :title="i18n.t('mywb.auth.carousel-slide-3-title')"
        :subtitle="i18n.t('mywb.auth.carousel-slide-3-subtitle')"
      >
        <source srcset="/src/assets/images/ppm.webp" type="image/webp">
        <source srcset="/src/assets/images/ppm.jpg" type="image/jpeg">
        <img src="/src/assets/images/ppm.jpg" alt="slide">
      </auth-slide-component>
    </wb-carousel>

    <div class="auth-view">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive } from 'vue'
import { permissions } from '@/engine/clients'
import AuthSlideComponent from '@/components/auth/AuthSlideComponent.vue'

const i18n = useI18n()

interface DataType {
  options: {
    allowTouchMove: boolean
    grabCursor: boolean
    pagination: {
      el: string,
      clickable: boolean
    },
    autoplay: {
      delay: number
    }
  }
}

const data: DataType = reactive({
  options: {
    allowTouchMove: true,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 6000
    }
  }
})
</script>

<style lang="postcss" scoped>
.auth-layout {
  min-height: 100vh;

  &.auth-layout-carousel {
    @media (--desktop) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.carousel {
  display: none!important;
  width: 100%;

  @media (--desktop) {
    display: block!important;
  }
}

.auth-view {
  display: flex;
  place-items: center;
  width: 90%;
  max-width: 360px;
  min-height: 100vh;
  margin: 0 auto;
}

.notification {
  position: absolute;
  top: 2rem;
  z-index: 10;
}
</style>
