import { object, string, type InferType } from 'yup'

export const enum USER_EMAIL_STATUS {
  CONFIRMED = 'confirmed',
  NOT_REGISTERED = 'not_registered',
  NOT_CONFIRMED = 'not_confirmed'
}

const checkUserEmailSchema = object({
  email: string().email().required()
})

export type CheckUserEmail = InferType<typeof checkUserEmailSchema>

export async function ensureEmailCheckIsValid (email: any) {
  return await checkUserEmailSchema.validate(email, { abortEarly: false })
}
