<template>
  <shared-header sticky-top="100px" hide-breadcrumb class="date-interval-header">
    <template #title>
      <span class="is-size-600">
        {{ i18n.t('mywb.common.infrastructure-performance') }}
      </span>
    </template>
    <template #filters>
      <wb-button-groups shape="squircle">
        <div class="group-item">
          <wb-date-picker
            v-model="compute.filterDates"
            data-test-id="calendar"
            :min-date="data.dashboardOptions.dates.minDate"
            :max-date="data.dashboardOptions.dates.today"
            :year-range="[2015, data.dashboardOptions.dates.today.getFullYear()]"
            :multi-calendars="mq.current !== 'mobile'"
            range
            :max-range="364"
            :locale="lang.__rootLanguage"
            :clearable="false"
            :auto-apply="false"
            :cancel-text="i18n.t('mywb.common.cancel')"
            :apply-text="i18n.t('mywb.common.apply')"
          >
            <template #input>
              <wb-dropdown tooltip-position="top">
                <template #activator>
                  <wb-button
                    :active="!!compute.filterDates.length"
                    icon="calendar"
                    :label="!!compute.filterDates.length
                      ? getDatesLabel(compute.filterDates)
                      : mq.current !== 'mobile' ? i18n.t('mywb.common.custom') : ''"
                    variant="white"
                    :size="mq.current !== 'mobile' ? 'normal' : 'small'"
                  />
                </template>
                <template v-if="compute.filterDates.length" #tooltip>
                  {{ i18n.t('mywb.common.num-days', [daysDiff(compute.filterDates[0], compute.filterDates[1])]) }}
                </template>
              </wb-dropdown>
            </template>
          </wb-date-picker>
        </div>

        <wb-dropdown
          v-for="option in data.dashboardOptions.calendar"
          :key="option.id"
          class="group-item"
          tooltip-position="top"
        >
          <template #activator>
            <wb-button
              :label="option.label"
              variant="white"
              :active="option.id === compute.calendar.id && !compute.filterDates.length"
              :size="mq.current !== 'mobile' ? 'normal' : 'small'"
              @click="methods.handleIntervalOption(option)"
            />
          </template>

          <template #tooltip>
            {{ getIntervalLabel(option) }}
          </template>
        </wb-dropdown>
      </wb-button-groups>
    </template>

    <template #secondary-actions>
      <shared-filter
        v-model="compute.periodicity"
        option-key="value"
        :options="data.dashboardOptions.periodicity"
        :reduce="(item: Record<string, string>) => item.value"
        non-clearable
        non-active-status
        non-sorted-options
        class="ml-12"
        :size="mq.current !== 'mobile' ? 'normal' : 'small'"
        auto-selected
      />
    </template>
  </shared-header>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import SharedHeader from '@/components/headers/SharedHeader.vue'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import state from '@/state'
import lang from '@/engine/lang'
import { useMq } from 'vue3-mq'
import { reactive, computed } from 'vue'
import {
  calendarOptions,
  periodicityOptions,
  analysisOptions
} from '@/utilities/chartSessions/chartOptions'
import { daysDiff, getDatesLabel, getIntervalLabel } from '@/utilities/chartSessions/dateIntervals'

const i18n = useI18n()
const mq = useMq()

interface Data {
  dashboardOptions: {
    dates: {
      minDate: Date,
      today: Date
    }
    calendar: typeof calendarOptions
    periodicity: typeof periodicityOptions
    analysis: typeof analysisOptions
  }
}

const oneYearBefore = new Date()
oneYearBefore.setFullYear(new Date().getFullYear() - 1)

const data = reactive<Data>({
  dashboardOptions: {
    dates: {
      minDate: oneYearBefore,
      today: new Date()
    },
    calendar: calendarOptions,
    periodicity: periodicityOptions,
    analysis: analysisOptions
  }
})

const compute = reactive({
  filterDates: computed({
    get () {
      return state.filters.dashboardFilters?.dates
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'dates', value })
    }
  }),

  calendar: computed({
    get () { return state.filters.dashboardFilters?.calendar },
    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'calendar', value })
    }
  }),

  periodicity: computed({
    get () { return state.filters.dashboardFilters?.periodicity },
    set (value) {
      state.filters.setFilterValue({ filter: 'dashboardFilters', key: 'periodicity', value })
    }
  })
})

const methods = {
  handleIntervalOption (option: typeof calendarOptions[0]) {
    compute.calendar = option
    compute.filterDates = []
  }
}
</script>

<style lang="postcss" scoped>
.is-grid-option {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
}

.date-interval-header {
  background-clip: content-box, padding-box;
  background-image: linear-gradient(
    to bottom, var(--grey-50) 0%, var(--grey-50) 100%);
}
</style>
