<template>
  <wb-modal
    width="70"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.add-chargers') }}
    </template>

    <i18n-t
      tag="p"
      class="is-size-400 mb-24"
      keypath="mywb.location.select-existing-chargers"
    >
      <wb-link
        :to="{ name: ROUTES.CHARGERS, query: { 'add-chargers': '1', 'group-uid': props.location.groupUid } }"
        @click="methods.trackAddCharger"
      >
        {{ i18n.t("mywb.location.add-new-charger") }}
      </wb-link>
    </i18n-t>

    <locations-accordion
      v-model="data.chargers"
      data-test-id="sharedLocationChargerTree"
      :filter-location="props.location.id"
      chargers-key="uid"
    />

    <template #actions>
      <wb-button
        data-test-id="acceptBtnModal"
        :label="i18n.t('mywb.common.add-chargers')"
        size="block"
        :loading="data.loading"
        :disabled="!data.chargers.length"
        @click="methods.updateChargers"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Location } from '@/core/location'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const chargerUsesCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()

interface Props {
  location: Location
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-close'): void
  (e: 'chargers-updated'): void
}

const emit = defineEmits<Events>()

interface Data {
  loading: boolean
  chargers: string[]
}
const data = reactive<Data>({
  loading: false,
  chargers: []
})

const methods = {
  trackAddCharger () {
    trackDataAction('add-charger', { location_id: props.location.groupUid })
  },

  async updateChargers () {
    data.loading = true

    await chargerUsesCases.updateChargersLocation(data.chargers, props.location.groupUid)

    data.loading = false
    emit('on-close')
    emit('chargers-updated')
  }
}
</script>
