import type { InvoicesRepository } from '@/core/invoices'
import type { UseCase } from '@/core/shared'

export const getAllPaymentsInvoices: UseCase<InvoicesRepository, 'getAllPaymentsInvoices'> = repository => {
  return async (groupUid, filters) => {
    const invoices = await repository.getAllPaymentsInvoices(groupUid, filters)

    return invoices
  }
}
