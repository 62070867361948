<template>
  <div>
    <div class="is-size-1000 mb-24">
      {{ i18n.t('mywb.common.where-are-the-chargers-installed') }}
    </div>

    <div class="grid g-24 mt-24">
      <wb-select
        v-model="compute.organizationSelected"
        :searchable="false"
        :label="i18n.t('mywb.common.organization')"
        :options="compute.organizationsParsed"
        data-test-id="organizationSelect"
      >
        <template #selected-option="{ option }">
          <div>
            <p class="is-size-400 is-font-weight-500">
              {{ option.name }}
            </p>
          </div>
        </template>
        <template #option="{ option }">
          <div>
            <p class="is-size-300 has-text-grey-700 is-font-weight-500">
              {{ option.name }}
            </p>
          </div>
        </template>
      </wb-select>

      <location-layout
        id="locationSelector"
        :ref="setRef('locationSelector')"
        v-model="data.groupUid"
        :locations="data.locations"
        reduce="groupUid"
        :error="errors.groupUid"
        :loading="props.loading"
        @on-change="methods.handleChangeLocation"
        @on-create-location="emit('on-create-location')"
      />
    </div>

    <div v-if="!permissions.canSeeLocations" class="grid g-24 mt-24">
      <country-and-state-select-form
        :ref="setRef('country')"
        v-model:countryCode="compute.countryCode"
        country-key="iso2"
        is-state-hidden
      />
    </div>
    <div v-if="!permissions.canSeeLocations" class="timezone mt-24">
      <timezone-select-form
        :ref="setRef('timezone')"
        v-model:timezoneId="compute.timezoneId"
        :error="errors.timezoneId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import LocationLayout from '@/components/locations/LocationLayout.vue'
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'
import TimezoneSelectForm from '@/components/forms/TimezoneSelectForm.vue'

import state from '@/state'
import { computed, reactive, onMounted, watch } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import { permissions } from '@/engine/clients'
import type { Location } from '@/core/location'
import type { CountryIso2 } from '@/core/international'
import { useValidator } from '@/hooks/useValidator.hook'
import { INTERNATIONAL_USE_CASES, LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'

const { yup, errors, validate, defineSchema } = useValidator()

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()

const locationUsesCases = injectStrict(LOCATION_USE_CASES)
const internationalUseCases = injectStrict(INTERNATIONAL_USE_CASES)

interface Props {
  groupUid?: string,
  country?: CountryIso2
  loading?: boolean,
  timezoneId?: string
}

const props = defineProps<Props>()

interface Events {
  (e: 'update:groupUid', groupUid: string): void,
  (e: 'update:location', location: Location): void,
  (e: 'update:country', countryCode?: CountryIso2): void,
  (e: 'update:timezoneId', timezoneId?: string): void,
  (e: 'update:loading', loading: boolean): void,
  (e: 'on-create-location'): void
}
const emit = defineEmits<Events>()

interface Data {
  isBusiness: boolean
  groupUid: string
  countryCode?: string
  timezoneId?: string
  locations: Location[]
}

const data = reactive<Data>({
  isBusiness: !state.organizations.isPlan()([EnumPlans.BASIC]),
  groupUid: '',
  locations: []
})

defineSchema(data, {
  isBusiness: yup.boolean(),
  groupUid: yup.string().when('isBusiness', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional()
  })
})

interface Organization {
  id: string,
  name: string,
  role: string,
  plan: string,
  groupUid: string
}

const compute = reactive({
  countryCode: computed({
    get (): CountryIso2 | undefined {
      return props.country
    },
    set (country) {
      emit('update:country', country)
    }
  }),
  timezoneId: computed({
    get (): string | undefined {
      return props.timezoneId
    },
    set (timezoneId) {
      emit('update:timezoneId', timezoneId)
    }
  }),

  hasOperatorPlanInRootGroup: computed((): boolean => state.organizations.allOrganizations
    .find(organization => organization.role.id === EnumRoles['super-admin'])?.plan.product_id === EnumPlans.OPERATOR),

  organizationsParsed: computed((): Organization[] => {
    return state.organizations.allOrganizations
      .filter(organization => {
        return (
          (!compute.hasOperatorPlanInRootGroup
            ? organization.role.id !== EnumRoles.operator
            : true) &&
          organization.role.id !== EnumRoles.user
        )
      })
      .map(organization => ({
        id: organization.id,
        name: organization.name,
        role: i18n.t(`mywb.common.${organization.role.name}`),
        plan: i18n.t(organization.plan.label),
        groupUid: organization.groupUid
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }),

  organizationSelected: computed({
    get: (): Organization => compute.organizationsParsed
      .find(organization => organization.id === state.organizations.getCurrentOrganization.id) as Organization,

    set: organization => {
      state.organizations.setCurrentOrganization(organization.id)
    }
  })
})

watch(() => state.organizations.getCurrentOrganization.groupUid, (newOrganization, oldOrganizacion) => {
  if (newOrganization !== oldOrganizacion) {
    data.groupUid = newOrganization
    methods.handleChangeLocation()
  }
})

const methods = {
  async handleChangeLocation () {
    emit('update:loading', true)
    data.locations = await locationUsesCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
      getChargers: false,
      cache: 'network'
    })

    emit('update:groupUid', data.groupUid)

    const location = data.locations.find(location => location.groupUid === data.groupUid)
    if (location) {
      emit('update:location', location)
    }

    if (!state.organizations.isPlan()([EnumPlans.BASIC])) {
      const country = await methods.getCountryFromLocation()
      const timezoneId = await methods.getTimezoneIdFromLocation()

      emit('update:country', country)
      emit('update:timezoneId', timezoneId ?? undefined)
    }

    emit('update:loading', false)
  },

  async getCountryFromLocation () {
    const location = data.locations.find(location => location.groupUid === data.groupUid)

    return state.global.getCountries.find(country => country.iso2 === location?.country)?.iso2 || undefined
  },

  async getTimezoneIdFromLocation () {
    const location = data.locations.find(location => location.groupUid === data.groupUid)
    if (!location || !location.latitude || !location.longitude) return

    const timezone = await internationalUseCases.getTimezoneByCoords({
      lat: location.latitude,
      lng: location.longitude
    })

    if (!timezone) return
    return timezone
  },

  async getLocation (groupUid: string) {
    return data.locations.find(location => location.groupUid === groupUid)
  },

  async isValidateForm () {
    emit('update:loading', true)
    const valid = await validate()
    const isBasicPlan = state.organizations.isPlan()([EnumPlans.BASIC])
    const validCountry = isBasicPlan
      ? await refs.country.isValidCountry()
      : true

    const validTimezone = isBasicPlan
      ? await refs.timezone.isValidTimezone()
      : true

    if (isBasicPlan && !validTimezone) errors.timezoneId = i18n.t('mywb.error.invalid-timezone')

    emit('update:loading', false)
    return valid && validCountry && validTimezone
  }
}

onMounted(async () => {
  data.groupUid = props.groupUid || state.organizations.getCurrentOrganization.groupUid
  methods.handleChangeLocation()
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 520px;
  }
}

.timezone {
  @media (--tablet) {
    max-width: 520px;
  }
}

.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 500;
  pointer-events: none;
}
</style>
