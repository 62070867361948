<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-assign-group.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-assign-group.description') }}
    </div>

    <wb-select
      v-if="data.accessConfigs.length"
      v-model="compute.accessConfigIdSelected"
      data-test-id="groupSelect"
      uid="_groups"
      class="is-capitalized"
      :label="i18n.t('mywb.common.access-group')"
      option-label="name"
      :reduce="(option: any) => option.id"
      name="subgroup"
      :placeholder="i18n.t('mywb.common.add-existing-access-group')"
      :options="data.accessConfigs || []"
      :error="data.errors[0]"
      @change="data.errors = []"
    />

    <wb-button
      variant="primary"
      inverted
      icon="create_new_folder"
      class="mt-24"
      :label="i18n.t('mywb.common.create-access-group')"
      data-test-id="create-access-config"
      @click="emit('on-create-access-group')"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, onMounted } from 'vue'
import { ACCESS_CONFIG_USE_CASES, injectStrict } from '@/engine/injectors'
import state from '@/state'
import type { AccessConfig } from '@/core/accessConfig'
import { useValidator } from '@/hooks/useValidator.hook'

const i18n = useI18n()
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)
const { yup } = useValidator()

interface Props {
  accessConfigId?: number
  chargersSelected: number[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:accessConfigId', accessConfigId?: number): void
  (e: 'update:chargersSelected', chargers: number[]): void
  (e: 'on-create-access-group'): void
}
const emit = defineEmits<Events>()

interface Data {
  errors: string[]
  accessConfigs: AccessConfig[]
}
const data = reactive<Data>({
  errors: [],
  accessConfigs: []
})

const compute = reactive({
  getChargersFromAccessConfig: computed(() => {
    return data.accessConfigs
      .find((accessConfig: AccessConfig) => accessConfig.id === props.accessConfigId)?.chargers || []
  }),

  accessConfigIdSelected: computed({
    get () {
      return props.accessConfigId
    },
    set (accessConfigId) {
      emit('update:accessConfigId', accessConfigId)
      setTimeout(() => { emit('update:chargersSelected', compute.getChargersFromAccessConfig) }, 150)
    }
  })
})

const methods = {
  async isValidateForm () {
    let isValid = false
    try {
      await yup.number().required().validate(props.accessConfigId)
      isValid = true
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        data.errors = error.errors
      }
    }
    return isValid
  }
}

onMounted(async () => {
  data.accessConfigs = await accessConfigUseCases
    .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
  emit('update:chargersSelected', compute.getChargersFromAccessConfig)
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
