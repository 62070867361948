<template>
  <auth-layout>
    <template
      v-if="!data.passwordResetFinished"
      #title
    >
      <p class="is-size-900 is-font-weight-700 has-text-black mb-12">
        {{ i18n.t('mywb.auth.forgot-your-password') }}
      </p>
      <p class="is-size-400">
        {{ i18n.t('mywb.auth.add-email-to-reset-password') }}
      </p>

      <wb-alert
        v-if="data.tokenExpired"
        variant="danger"
        class="mt-16 mb-16"
        icon="info_filled"
      >
        {{ i18n.t('mywb.error.password-token-expired') }}
      </wb-alert>
    </template>

    <template
      v-else
      #title
    >
      <img
        class="icon"
        alt=""
        :src="mailCheckIcon"
      >
      <p class="is-size-900 is-font-weight-700 has-text-black mb-12">
        {{ i18n.t('mywb.auth.check-your-mail') }}
      </p>
      <p class="is-size-400">
        {{ i18n.t('mywb.auth.reset-password-confirm') }}
      </p>
    </template>

    <wb-form v-if="!data.passwordResetFinished">
      <wb-input
        ref="input"
        v-model="form.email"
        :label="i18n.t('mywb.common.email')"
        :error="errors.email"
        data-test-id="emailInput"
        @on-enter.prevent="validate(methods.resetPassword)"
      />
      <div class="is-fullwidth has-text-centered">
        <wb-button
          class="mb-24"
          :label="i18n.t('mywb.auth.reset-password')"
          data-test-id="sendEmailBtnModal"
          size="large"
          :loading="data.loading"
          @click="validate(methods.resetPassword)"
        />
        <wb-link
          class="is-size-400 is-link"
          data-test-id="back"
          type="text"
          :to="{ name: ROUTES.AUTH_LOGIN }"
        >
          <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
          <span>{{ i18n.t('mywb.common.back') }}</span>
        </wb-link>
      </div>
    </wb-form>

    <div v-else>
      <wb-button
        class="mb-24"
        :label="i18n.t('mywb.common.done')"
        data-test-id="loginBtn"
        size="large"
        :to="{ name: ROUTES.AUTH_LOGIN }"
      />

      <div class="is-size-300">
        {{ i18n.t('mywb.auth.did-not-receive-email') }}
        <span
          class="is-primary-link"
          @click="data.passwordResetFinished = false"
        >
          {{ i18n.t('mywb.auth.try-another-email') }}
        </span>.
      </div>
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { reactive, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useWbInputAutofocus } from '@/hooks'
import { trackScreen, trackAction } from '@wallbox/metrics'
import { getServerError } from '@/utilities/errorMessages'
import { clientConfig } from '@/engine/clients'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import lang from '@/engine/lang'
import mailCheckIcon from '@/assets/inline/common/mail_check.svg'
import { HttpError } from '@wallbox/http'
import { useValidator } from '@/hooks/useValidator.hook'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { type PasswordResetRequest, ensurePasswordResetRequestIsValid } from '@/core/auth'
import ROUTES from '@/engine/router/routes'

const authUsesCases = injectStrict(AUTH_USE_CASES)
const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const toast = useToast()
const { errors, validate, validateTo } = useValidator()

const input = ref()
useWbInputAutofocus(input)

const data = reactive({
  loading: false,
  passwordResetFinished: false,
  tokenExpired: false
})

const form = reactive<PasswordResetRequest>({
  email: route.query.email as string ?? '',
  language: lang.__rootLanguage,
  branding: clientConfig.brandingId
})

validateTo(async () => !!await ensurePasswordResetRequestIsValid(form))

const methods = {
  async resetPassword () {
    trackAction('password-reset-send-email')
    try {
      await authUsesCases.doPasswordResetRequest(form)
      data.passwordResetFinished = true
      trackScreen('password-reset-send-email-success')
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  }
}

onMounted(() => {
  if (route.query['token-expired']) {
    data.tokenExpired = true
    router.push({ query: {} }).catch(() => {})
  }
  trackScreen('password-reset')
})
</script>

<style lang="postcss"  scoped>
.is-primary-link {
  color: var(--primary-500);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.icon {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 12px;
}
</style>
