<template>
  <wb-card no-padding>
    <template #content>
      <div class="table-wrapper">
        <chargers-table-header
          v-bind="attrs"
          :chargers="compute.chargersArray"
          @on-bulk-action="methods.handleBulkAction"
          @deselect-all="methods.deselectAll"
        />

        <wb-table
          :ref="setRef('table')"
          v-model:selected="data.checkedChargers"
          :options="compute.options"
          :loading="props.loading"
          @on-checked-all-rows="methods.selectAllChargers"
          @on-pagination-change="methods.handlePaginationChange"
          @on-sort-change="methods.sortTable"
        >
          <chargers-table-row
            v-for="charger in compute.chargersArray"
            :key="charger.uid"
            :track-by="charger.uid"
            :charger="charger"
            data-test-id="chargersTableRow"
            @on-update-action="emit('on-update-action', $event)"
            @on-restart-action="emit('on-restart-action', $event)"
          />
        </wb-table>
      </div>
    </template>
  </wb-card>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import ChargersTableHeader from '@/components/tables/ChargersTableHeader.vue'
import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed, watch, useAttrs } from 'vue'
import ChargersTableRow from '@/components/tables/ChargersTableRow.vue'
import type { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { TableProps, TableData } from '@wallbox/toolkit-ui'
import type { ChargerColumns } from '@/types/data/chargerColumns'
import { useMq } from 'vue3-mq'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import type { Charger as ChargerCore } from '@/core/charger'

const { refs, setRef } = useTemplateRef()
const attrs = useAttrs()
const i18n = useI18n()
const mq = useMq()

interface Events {
  (e: 'on-bulk-action', action: REMOTE_ACTIONS): void
  (e: 'remove-charger'): void,
  (e: 'on-pagination-change', offset: number): void,
  (e: 'on-sort-change', sort: TableData['currentSorts']): void,
  (e: 'on-update-action', charger: ChargerCore): ChargerCore,
  (e: 'on-restart-action', charger: ChargerCore): ChargerCore
}
const emit = defineEmits<Events>()

interface Props {
  includeColumns: ChargerColumns[]
  headerSticky?: string
  loading: boolean
  pagination?: {
    offset: number
    total: number
    limit: number
    multiPage: boolean
  }
}

const props = defineProps<Props>()

interface Data {
  checkedChargers: Record<number, boolean>
}
const data = reactive<Data>({
  checkedChargers: {}
})

type ChargerParsed = typeof state.charger.getChargers[number] & {
  checked: boolean
}

const compute = reactive({
  chargersArray: computed((): ChargerParsed[] => {
    const chargers = state.charger.getChargers.map(charger => ({
      ...charger,
      checked: false
    }))

    return chargers
  }),

  options: computed((): (TableProps['options']) => ({
    selectable: props.includeColumns.includes('selectable'),
    offset: '0 8%',
    stickyFirstColumn: true,
    pagination: props.pagination,
    sortableMultiple: true,
    resizable: true,
    columns: [
      {
        key: 'name',
        name: i18n.t('mywb.common.name'),
        minWidth: 250,
        sortable: true,
        hidden: !props.includeColumns.includes('name')
      },
      {
        key: 'location_name',
        name: i18n.t('mywb.common.location'),
        sortable: true,
        minWidth: 150,
        hidden: !props.includeColumns.includes('location')
      },
      {
        key: 'status',
        name: i18n.t('mywb.common.status'),
        sortable: true,
        width: 'fit',
        hidden: !props.includeColumns.includes('status')
      },
      {
        key: 'features',
        name: '',
        width: 200,
        hidden: !props.includeColumns.includes('features')
      },
      {
        key: 'firmwareAvailable',
        name: i18n.t('mywb.charger.software-version'),
        width: 'fit',
        hidden: !props.includeColumns.includes('firmwareAvailable')
      },
      {
        key: 'actions',
        name: i18n.t('mywb.common.actions'),
        width: 'fit',
        hidden: !props.includeColumns.includes('actions')
      }
    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    },
    stickyHead: props.headerSticky
      ? props.headerSticky
      : mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)'
  }))
})

const methods = {
  filterByText (chargers: ChargerParsed[], text: string) {
    if (!text) return chargers

    return chargers.filter(charger => {
      return (
        charger.id.toString().includes(text.toLowerCase()) ||
        charger.name.toLowerCase().includes(text.toLowerCase()) ||
        charger.locationName.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  selectAllChargers (checked: boolean) {
    state.charger.setCheckChargersGroup({
      status: checked,
      chargers: compute.chargersArray
    })
  },

  deselectAll () {
    data.checkedChargers = {}
    state.charger.resetCheckedChargers()
  },

  handleBulkAction (action: REMOTE_ACTIONS) {
    emit('on-bulk-action', action)
  },

  handlePaginationChange (offset: number) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    emit('on-pagination-change', offset)
  },

  sortTable (sort: TableData['currentSorts']) {
    emit('on-sort-change', sort)
  }
}

watch(() => state.charger.getCheckedChargers, () => {
  const checkedChargers = Object.values(data.checkedChargers ?? {}).filter(Boolean)

  if (state.charger.getCheckedChargers.length !== checkedChargers.length) {
    data.checkedChargers = {}
  }
})

watch(() => state.charger.getChargers, () => {
  setTimeout(() => {
    refs.table?.resetWidths?.()
  })
})

function created () {
  state.charger.resetCheckedChargers()
}

created()
</script>

<style lang="postcss" scoped>
.table-wrapper {
  position: relative;
}
</style>
