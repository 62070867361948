import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { permissions } from '@/engine/clients'
import state from '@/state'
import ROUTES from '../routes'

interface routeAccess {
  name: string
  redirect: Partial<NavigationGuardNext>
  permission: () => boolean
}

const protectedRoutes: routeAccess[] = [
  {
    name: ROUTES.DASHBOARD,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeDashboard
  },
  {
    name: ROUTES.USERS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeUsers
  },
  {
    name: ROUTES.PAYMENTS_CONFIGURATION,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.showPayments
  },
  {
    name: ROUTES.PAYMENTS_RATES,
    redirect: { name: ROUTES.PAYMENTS_CONFIGURATION },
    permission: () => permissions.showPayments && !!state.organizations.getCurrentOrganization.paymentsAccount
  },
  {
    name: ROUTES.PAYMENTS_INVOICES,
    redirect: { name: ROUTES.PAYMENTS_CONFIGURATION },
    permission: () => permissions.showPayments && !!state.organizations.getCurrentOrganization.paymentsAccount
  },
  {
    name: ROUTES.ACCOUNT_PAYMENT_HISTORY,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.showBilling
  },
  {
    name: ROUTES.PLAN_BUSINESS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.showBusinessPage
  },
  {
    name: ROUTES.LOCATIONS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeLocations || permissions.showUpSellingToPlanBusiness
  },
  {
    name: ROUTES.CHARGER,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeChargerDetail
  },
  {
    name: ROUTES.CHARGER_DETAIL_USERS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeChargerDetail
  },
  {
    name: ROUTES.CHARGER_DETAIL_SESSIONS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeChargerDetail
  },
  {
    name: ROUTES.CHARGER_DETAIL_SETTINGS,
    redirect: { name: ROUTES.CHARGERS },
    permission: () => permissions.canSeeChargerDetail
  }
]

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const protectedRoute = protectedRoutes.find(route => {
    return route.name === to.name
  })

  if (protectedRoute != null && !protectedRoute.permission()) {
    next(protectedRoute.redirect)
  }

  next()
}
