import { object, string, type InferType } from 'yup'

const passwordResetRequestSchema = object({
  email: string().required(),
  language: string().required(),
  branding: string().required()
})

export type PasswordResetRequest = InferType<typeof passwordResetRequestSchema>

export async function ensurePasswordResetRequestIsValid (passwordReset: any) {
  return await passwordResetRequestSchema.validate(passwordReset, { abortEarly: false })
}
