import type { ChargerRepository } from '@/core/charger'

export function getChargerActiveSession (repository: ChargerRepository) {
  return async (organizationId: string, chargerUid: string) => {
    const result = await repository.getChargersActiveSession(organizationId, {
      chargerId: chargerUid
    })

    return result[0]
  }
}
