<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-assign-chargers.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-assign-chargers.description') }}
    </div>

    <locations-accordion
      v-model="compute.chargersSelected"
    />
  </div>
</template>

<script setup lang="ts">
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'

import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'

const i18n = useI18n()

interface Props {
  chargersSelected: number[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:chargersSelected', chargers: number[]): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  chargersSelected: computed({
    get () {
      return props.chargersSelected
    },
    set (chargers) {
      emit('update:chargersSelected', chargers)
    }
  })
})

const methods = {
  isValidateForm () {
    return true
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>
