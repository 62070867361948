<template>
  <div class="rate-schedule-form">
    <h5
      v-if="props.label"
      class="is-size-300 is-font-weight-500 has-text-black"
    >
      {{ props.label }}
    </h5>
    <p
      v-if="props.label"
      class="is-size-200 has-text-grey-500"
    >
      {{ compute.getLabelHours }}
    </p>

    <wb-button
      v-if="props.label"
      class="cta"
      variant="white"
      data-test-id="activate-arrow"
      :icon="compute.activated ? 'expand_less': 'expand_more'"
      @click="compute.activated = !compute.activated"
    />

    <template v-if="compute.activated">
      <wb-select
        v-model="compute.from"
        :options="methods.createHoursArray('from')"
        option-label="label"
        :reduce="(item: any) => item.value"
        :label="i18n.t('mywb.common.from')"
        data-test-id="from-input"
      />

      <wb-select
        v-model="compute.to"
        :options="methods.createHoursArray('to')"
        option-label="label"
        :reduce="(item: any) => item.value"
        :label="i18n.t('mywb.common.to')"
        data-test-id="to-input"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'

const i18n = useI18n()

interface Props {
  label?: string,
  from?: number,
  to?: number,
  activated?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  from: 0,
  to: 23
})

interface Events {
  (e: 'update:from', from?: number): void,
  (e: 'update:to', to?: number): void,
  (e: 'update:activated', activated?: boolean): void
}

const emit = defineEmits<Events>()
const compute = reactive({
  from: computed({
    get () {
      return { hours: props.from, minutes: 0 }
    },

    set (value) {
      emit('update:from', value.hours)
    }
  }),

  to: computed({
    get () {
      return { hours: props.to, minutes: 59 }
    },

    set (value) {
      emit('update:to', value.hours)
    }
  }),

  activated: computed({
    get () {
      return props.activated
    },

    set (value) {
      emit('update:activated', value)
    }
  }),

  getLabelHours: computed(() => {
    if ((!props.from && props.from !== 0) || !props.to) return ''
    const fromString = methods.createHoursArray('from').find(elem => elem.value.hours === props.from)?.label || ''
    const toString = methods.createHoursArray('to').find(elem => elem.value.hours === props.to)?.label || ''

    return `${fromString} - ${toString}`
  })
})

const methods = {
  createHoursArray (hourType: string) {
    const hoursArray = []
    const isToHourType = hourType === 'to'
    for (let i = 0; i < 24; i++) {
      const hour = i
      const hourLabel = `${hour}:00h`
      const adjustedHour = isToHourType ? (hour === 0 ? 23 : hour - 1) : hour
      const hourValue = {
        hours: adjustedHour,
        minutes: isToHourType ? 59 : 0
      }
      const hourObject = { label: hourLabel, value: hourValue }
      hoursArray.push(hourObject)
    }

    if (hourType === 'to') {
      const zeroHourIndex = hoursArray.findIndex(hour => hour.label === '0:00h')
      hoursArray.push(hoursArray.splice(zeroHourIndex, 1)[0])
    }

    return hoursArray
  }
}
</script>

<style lang="postcss">
.rate-schedule-form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 0 8px;
}

.cta {
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: end;
}
</style>
