import type { EnumRoles } from '@/utilities/user-roles'

export interface AccessConfig {
  id: number
  groupId: number
  name?: string
  chargers: number[]
  users: number[]
}

export const enum AccessConfigForUserContractStatus {
  UNPAID = 'unpaid',
  ACTIVE = 'active'
}

export interface AccessConfigContract {
  createdAt: number
  id: number
  signed: boolean
  status?: AccessConfigForUserContractStatus
  updatedAt: number
}

export type AccessConfigForUser = AccessConfig & {
  profile: EnumRoles
  hasCustomAccess: boolean
  contract?: AccessConfigContract
}
