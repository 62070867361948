import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const sendUpdateOrRestartRemoteAction:
UseCase<ChargerRepository, 'sendUpdateOrRestartRemoteAction'> = repository => {
  return async (remoteActions) => {
    await repository.sendUpdateOrRestartRemoteAction(remoteActions)
  }
}

export const sendStartOrPauseRemoteAction:
UseCase<ChargerRepository, 'sendStartOrPauseRemoteAction'> = repository => {
  return async remoteActions => {
    await repository.sendStartOrPauseRemoteAction(remoteActions)
    await Promise.resolve()
  }
}
