<template>
  <shared-teleport-header
    id="dashboard"
    class="header"
  >
    <template #title>
      {{ i18n.t('mywb.common.dashboard') }}
    </template>

    <template #filters>
      <dashboard-filters
        :loading="props.loading"
        :locations="props.locations"
      />
    </template>
  </shared-teleport-header>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import DashboardFilters from '@/components/filters/DashboardFilters.vue'
import type { Location } from '@/core/location'

const i18n = useI18n()

interface Props {
  loading?: boolean,
  locations: Location[]
}

const props = defineProps<Props>()
</script>

<style lang="postcss" scoped>
.header {
  z-index: 130;
}
</style>
