import { CHARGER_TYPES, FEATURES, type ChargerType } from '../../domain'

export const chargerTypes: ChargerType[] = [
  {
    name: 'Pulsar',
    code: CHARGER_TYPES.PULSAR,
    chargers: ['WBPLSR', 'WBPL', 'PLC1'],
    features: [
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING
    ]
  },
  {
    name: 'PulsarPlus',
    code: CHARGER_TYPES.PULSAR_PLUS,
    chargers: ['PLP1', 'PSP1', 'PUP1', 'PUP2'],
    actions: {
      minVersion: '4.4.0'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.10'
      }
    }
  },
  {
    name: 'Pulsar Max',
    code: CHARGER_TYPES.PULSAR_MAX,
    chargers: ['PLP2'],
    actions: {
      minVersion: '0.0.1'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.10'
      }
    }
  },
  {
    name: 'Pulsar Max ABL',
    code: CHARGER_TYPES.PULSAR_MAX_ABL,
    chargers: ['PLP3'],
    actions: {
      minVersion: '0.0.1'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ]
  },
  {
    name: 'Pulsar Pro',
    code: CHARGER_TYPES.PULSAR_PRO,
    chargers: ['PPR1', 'PUP3'],
    actions: {
      minVersion: '0.0.1'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '6.0.0'
      }
    }
  },
  {
    name: 'Commander',
    code: CHARGER_TYPES.COMMANDER,
    chargers: ['WBCOMM', 'WBCM'],
    actions: {
      minVersion: '2.8.0'
    },
    features: [],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    }
  },
  {
    name: 'Commander 2',
    code: CHARGER_TYPES.COMMANDER_2,
    chargers: ['CMX2'],
    actions: {
      minVersion: '0.0.1'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    }
  },
  {
    name: 'Copper',
    code: CHARGER_TYPES.COPPER,
    chargers: ['WBCP', 'CPB1'],
    actions: {
      minVersion: '3.6.0'
    },
    features: [
      FEATURES.ECO_SMART,
      FEATURES.POWER_SHARING,
      FEATURES.DYNAMIC_POWER_SHARING,
      FEATURES.POWER_BOOST
    ],
    payments: {
      ppm: {
        minVersion: '0.0.1'
      },
      ppc: {
        minVersion: '5.5.3'
      }
    },

    gunLock: {
      minVersion: '5.13.0'
    }
  },
  {
    name: 'Quasar',
    code: CHARGER_TYPES.QUASAR,
    chargers: ['QSX1', 'QSA1', 'QSU2', 'QSX2'],
    features: [
      FEATURES.POWER_BOOST
    ],
    actions: {
      minVersion: '0.0.1'
    }
  },

  {
    name: 'Supernova',
    code: CHARGER_TYPES.SUPERNOVA,
    chargers: ['DCF1', 'DCF2', 'DCF3'],
    features: []
  }
]
