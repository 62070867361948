import i18n from '@/engine/lang'
import type { Charger } from '@/core/charger'

export enum POWER_SHARING {
  DISABLED = 'disabled',
  SLAVE = 'slave',
  SLAVE_UNPAIRED = 'slaveUnpaired',
  MASTER = 'master',
  MASTER_INCOMPLETE = 'masterIncomplete'
}

export const powerSharingStatus = () => [
  {
    code: POWER_SHARING.DISABLED,
    status: 'disabled',
    label: i18n.global.t('mywb.common.disabled'),
    ids: [0]
  },
  {
    code: POWER_SHARING.SLAVE,
    status: 'enabled',
    label: i18n.global.t('mywb.common.secondary'),
    ids: [1]
  },
  {
    code: POWER_SHARING.SLAVE_UNPAIRED,
    status: 'error',
    label: i18n.global.t('mywb.common.secondary-unpaired'),
    ids: [2]
  },
  {
    code: POWER_SHARING.MASTER,
    status: 'enabled',
    label: i18n.global.t('mywb.common.primary'),
    ids: [3]
  },
  {
    code: POWER_SHARING.MASTER_INCOMPLETE,
    status: 'error',
    label: i18n.global.t('mywb.common.primary-incomplete'),
    ids: [4]
  }
]

export const getPowerSharingStatus = (charger: Charger) => {
  const chargerStatus = charger.powerSharingStatus

  return powerSharingStatus().find(status => status.ids.includes(chargerStatus)) ??
  { code: POWER_SHARING.DISABLED, status: 'disabled', label: i18n.global.t('mywb.common.disabled') }
}
