<template>
  <wb-card
    :headline="i18n.t('mywb.common.rfid')"
    :subhead="i18n.t('mywb.account.rfid-description')"
    alignment="center"
  >
    <template #content>
      <div class="is-flex">
        <p class="is-size-500" data-test-id="rfidView">
          <span v-if="permissions.canSeeRfid">
            {{ compute.rfidProxy }}
          </span>
          <span v-else>
            * * * * * * * *
          </span>
        </p>

        <wb-button
          v-if="compute.rfidProxy && permissions.canSeeRfid && permissions.editRfid"
          variant="danger"
          outlined
          size="small"
          icon="delete"
          class="ml-8"
          data-test-id="deleteBtn"
          @click="data.showRfidConfirmModal = true"
        />
      </div>
    </template>

    <template v-if="permissions.editRfid" #actions>
      <wb-button
        variant="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="data.editRfid = true"
      />
    </template>

    <template #modal>
      <wb-modal
        v-if="data.editRfid"
        width="50"
        @close="data.editRfid = false"
      >
        <template #title>
          {{ i18n.t('mywb.common.rfid') }}
        </template>

        <wb-input
          v-model="compute.rfidProxy"
          data-test-id="rfidInput"
          class="card-rfid-input mb-24"
          name="rfidName"
          type="text"
          :error="errors.rfid || data.errorApiMessage"
          autocomplete="__away"
          :placeholder="i18n.t('mywb.common.add-rfid-code')"
          @keyup="data.errorApiMessage = ''"
        />

        <template #actions>
          <div class="button-actions">
            <wb-button
              data-test-id="cancelButton"
              variant="white"
              outlined
              :label="i18n.t('mywb.common.cancel')"
              size="block"
              @click="data.editRfid = false"
            />

            <wb-button
              variant="primary"
              :label="!data.saving ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
              :loading="data.saving"
              data-test-id="saveBtn"
              size="block"
              @click.stop="validate(methods.saveRfid)"
            />
          </div>
        </template>
      </wb-modal>

      <shared-confirmation-modal
        v-if="data.showRfidConfirmModal"
        :title="i18n.t('mywb.common.rfid-card.delete-confirm')"
        :label-confirmation-button="i18n.t('mywb.common.delete')"
        type="danger"
        @on-confirm="methods.deleteRfid"
        @on-close="data.showRfidConfirmModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { useValidator } from '@/hooks/useValidator.hook'
import { hasRoleGreaterThan, checkIfUserIsSameAs } from '@/utilities/users'
import { permissions } from '@/engine/clients'
import { computed, reactive } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { HttpError } from '@wallbox/http'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'
import { type RfidUpdate, ensureUpdateRfidIsValid } from '@/core/user'

const userUseCases = injectStrict(USER_USE_CASES)
const i18n = useI18n()
const toast = useToast()
const { errors, validateTo, validate } = useValidator()

interface Data {
  saving: boolean,
  showRfidConfirmModal: boolean,
  editRfid: boolean,
  errorApiMessage?: string
}
const data = reactive<Data>({
  saving: false,
  showRfidConfirmModal: false,
  editRfid: false
})

const form = reactive<RfidUpdate>({
  groupUid: state.organizations.getCurrentOrganization.groupUid,
  userId: state.user.userLogged.id,
  rfid: ''
})

validateTo(async () => !!await ensureUpdateRfidIsValid(form))

const compute = reactive({
  hasPermissions: computed((): boolean => (
    hasRoleGreaterThan(state.user.userLogged.email) ||
    checkIfUserIsSameAs(state.user.userLogged.email) ||
    permissions.canAssignedRfid
  )),

  rfidProxy: computed({
    get (): string {
      return compute.hasPermissions ? form.rfid : '*****************'
    },
    set (value) {
      form.rfid = value
    }
  })
})

const methods = {
  async deleteRfid () {
    await userUseCases.deleteUserRfid(form)
    compute.rfidProxy = ''
  },

  async saveRfid () {
    try {
      data.saving = true
      data.errorApiMessage = undefined
      await userUseCases.updateUserRfid(form)
      toast.success(i18n.t('mywb.common.rfid-saved'))
      data.editRfid = false
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 2418) {
          data.errorApiMessage = i18n.t('mywb.error.duplicated-rfid')
        } else {
          toast.error(i18n.t('mywb.error.unexpected-error'))
        }
      } else {
        throw error
      }
    } finally {
      data.saving = false
    }
  }
}

async function created () {
  form.rfid = (await userUseCases.getRfidByUser(
    state.organizations.getCurrentOrganization.groupUid,
    state.user.userLogged.id
  )) ?? ''
}

created()
</script>
