<template>
  <wb-card
    :headline="i18n.t('mywb.user.personal-data')"
    data-test-id="userDetailPersonalData"
  >
    <template #content>
      <wb-card filled>
        <template #content>
          <div class="grid tablet:g-24">
            <wb-user-avatar
              size="large"
              data-test-id="userAvatarLarge"
              :src="props.user.avatar"
              :initials="compute.initials"
              type="square"
            />
            <div>
              <p data-test-id="userName">
                {{ `${props.user.name} ${props.user.surname}` }}
              </p>
              <p data-test-id="userEmail">
                {{ props.user.email }}
              </p>
              <p data-test-id="userRegisterDate">
                {{ i18n.t('mywb.user.register-date') }} {{ compute.registerDate }}
              </p>
            </div>
          </div>
        </template>
      </wb-card>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { dates } from '@wallbox/toolkit-ui'
import { userInitials } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { UserDetail } from '@/core/user'
import state from '@/state'

const i18n = useI18n()

interface Props {
  user: UserDetail
}
const props = defineProps<Props>()

const compute = reactive({
  registerDate: computed(() => dates.toDate(props.user.registerDate, i18n.locale.value)),

  initials: computed(() => userInitials(props.user)),

  country: computed(() => state.global.getCountries.find((country) => country.iso3 === props.user.countryIso3))
})
</script>

<style lang="postcss" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: repeat(2, max-content);
  }
}
</style>
