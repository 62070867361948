<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.auth.register') }}
      </p>
    </template>

    <wb-form>
      <wb-select
        v-model="data.selectedCountry"
        uid="_country"
        class="country-select"
        name="country"
        option-label="name"
        data-test-id="countrySelect"
        :label="i18n.t('mywb.common.country')"
        :options="state.global.getCountries"
        :error="errors.selectedCountry"
        :disabled="data.loading"
        @update:model-value="methods.selectCountry"
      />

      <div>
        <div class="term g-16">
          <p :class="{ 'has-text-danger-500': !!errors.accept_policy }">
            {{ i18n.t('mywb.common.accepted-privacy-policy') }}
            <wb-link
              v-if="clientConfig.docs.privacy"
              target="_blank"
              :href="clientConfig.docs.privacy"
            >
              {{ i18n.t('mywb.common.data-privacy-policy') }}
            </wb-link>
            <wb-link
              v-if="clientConfig.docs.terms"
              target="_blank"
              :href="clientConfig.docs.terms"
            >
              {{ i18n.t('mywb.common.terms-and-conditions') }}
            </wb-link>
          </p>

          <wb-switch
            v-model="data.accept_policy"
            data-test-id="privacyCheckboxModal"
            name="privacy"
          />
        </div>

        <wb-separator />

        <div class="term g-16">
          <p>
            {{ i18n.t('mywb.common.privacy-policy-1') }}
          </p>
          <wb-switch
            v-model="data.accept_marketing"
            data-test-id="marketingCheckboxModal"
            name="accept_marketing"
          />
        </div>

        <wb-separator />

        <div class="term g-16">
          <p>
            {{ i18n.t('mywb.common.privacy-policy-2') }}
          </p>

          <wb-switch
            v-model="data.accept_profile"
            data-test-id="profileCheckboxModal"
            name="accept_profile"
          />
        </div>
      </div>

      <wb-button
        :label="i18n.t('mywb.auth.register')"
        size="large"
        data-test-id="registerBtn"
        :disabled="data.loading"
        @click="methods.finishRegister"
      />

      <wb-link
        class="is-size-400 has-margin-auto"
        type="text"
        @click="emit('back')"
      >
        <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
        <span>{{ i18n.t('mywb.common.back') }}</span>
      </wb-link>
    </wb-form>
  </auth-layout>
</template>

<script lang="ts">
export default {
  name: 'AuthTermsAndConditions'
}
</script>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { reactive } from 'vue'
import { trackError } from '@wallbox/metrics'
import { useI18n } from '@/hooks/useI18n.hook'
import {
  clientConfig,
  setRegionByCountry
} from '@/engine/clients'
import state from '@/state'
import { injectStrict, INTERNATIONAL_USE_CASES } from '@/engine/injectors'
import type { Country, CountryIso2 } from '@/core/international'
import type { SignUp } from '@/core/auth'
import { useValidator } from '@/hooks/useValidator.hook'

const internationalUseCases = injectStrict(INTERNATIONAL_USE_CASES)

const i18n = useI18n()

interface Events {
  (e: 'terms-accept', { terms, countryCode }: { terms: SignUp['terms'], countryCode: CountryIso2 }): void,
  (e: 'back'): void
}

const emit = defineEmits<Events>()

const { yup, errors, validate, defineSchema } = useValidator()

interface Data {
  accept_policy: boolean
  accept_marketing: boolean
  accept_profile: boolean
  loading: boolean
  selectedCountry?: Country,
}

const data = reactive<Data>({
  accept_policy: false,
  accept_marketing: false,
  accept_profile: false,
  loading: false
})

defineSchema(data, {
  accept_policy: yup.boolean().required().isTrue(),
  selectedCountry: yup.mixed().required()
})

const methods = {
  selectCountry (country: Country) {
    data.selectedCountry = country
    data.accept_policy = false
    setRegionByCountry(country.iso2)
  },

  async finishRegister () {
    const valid = await validate()

    if (valid && data.selectedCountry) {
      emit('terms-accept', {
        terms: {
          policy: data.accept_policy,
          marketing: data.accept_marketing,
          profile: data.accept_profile
        },
        countryCode: data.selectedCountry.iso2
      })
    } else {
      trackError('terms-register-form', {
        nameError: !!errors.selectedCountry,
        emailError: errors.accept_policy
      })
    }
  }
}

async function created () {
  const countries = await internationalUseCases.getAllCountries()
  state.global.setCountries(countries)
}

created()
</script>

<style lang="postcss" scoped>
.term {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
