<template>
  <wb-card
    no-padding
    class="ordered-list"
  >
    <template #content>
      <wb-card
        :no-padding="!permissions.showBilling"
        :headline="!props.showTitle ? i18n.t('mywb.common.payments-configuration') : ''"
      >
        <template #content>
          <h3
            v-if="props.showTitle"
            v-t="'mywb.payments.connect-to-stripe'"
            class="is-size-700 is-font-weight-500"
            :class="{ 'mb-16': !permissions.showBilling }"
          />
          <template v-if="permissions.showBilling">
            <p
              v-t="'mywb.payments.billing-description'"
              class="is-size-300 has-text-grey-500 my-16"
            />
            <h4
              v-t="'mywb.common.add-billing-information'"
              class="is-size-300 my-12 ordered-item"
            />

            <billing-information
              v-model:has-billing-info="data.hasBillingInfo"
              filled
              :has-title="false"
              data-test-id="billingInformationSection"
              class="mb-24"
              :group-uid="state.organizations.getCurrentOrganization.groupUid"
            />
          </template>
        </template>
      </wb-card>

      <wb-card
        class="stripe-card"
        :class="{ 'border-less': permissions.showBilling }"
      >
        <template #content>
          <h4
            v-t="'mywb.common.connect-stripe'"
            class="is-size-300 ordered-item"
          />
          <client-img-component
            inline
            class="stripe-img"
            src="stripe"
            viewbox="24 0 200 100"
          />

          <wb-alert
            v-if="compute.hasAnyError"
            variant="danger"
            icon="info_filled"
            class="mb-16"
          >
            <p
              v-if="data.connectionError"
              v-t="'mywb.payments.stripe-connection-error-detail'"
              data-test-id="stripeConnectionError"
            />
            <p
              v-else-if="data.currencyError"
              v-t="'mywb.payments.stripe-connection-currency-error'"
              data-test-id="stripeConnectionCurrency"
            />
            <p
              v-else-if="data.countryError"
              v-t="'mywb.payments.stripe-connection-country-error'"
              data-test-id="stripeConnectionCountry"
            />
            <p
              v-else-if="data.countryExpressError"
              v-t="'mywb.payments.stripe-connection-country-express-error'"
              data-test-id="countryExpressError"
            />

            <i18n-t
              v-else-if="data.accountLinked === 'error'"
              tag="p"
              keypath="mywb.payments.error-stripe"
            >
              <wb-link
                href="https://dashboard.stripe.com/login"
                type="text"
                target="_blank"
              >
                Stripe
              </wb-link>
            </i18n-t>
          </wb-alert>

          <div v-if="data.accountLinked === 'confirmed'" class="is-flex is-align-center g-16 justify">
            <wb-badge
              data-test-id="accountLinkedSuccessfully"
              icon="lens_filled"
              variant="green"
              class="label-stripe-connected"
            >
              {{ i18n.t('mywb.payments.stripe-connected') }}
            </wb-badge>

            <wb-button
              v-if="props.internalLink"
              variant="white"
              outlined
              :label="i18n.t('mywb.common.go-to-payments')"
              :to="{ name: ROUTES.PAYMENTS_INVOICES }"
              data-test-id="goToPaymentsBtn"
            />

            <wb-button
              v-else
              variant="white"
              outlined
              icon="open_in_new"
              :label="i18n.t('mywb.organizations.go-to-stripe')"
              href="https://dashboard.stripe.com/"
              target="_blank"
              data-test-id="goToPaymentsBtn"
            />
          </div>

          <div v-else class="is-flex is-align-center g-16">
            <p>
              <strong v-t="'mywb.payments.stripe-desc-1'" />
              &nbsp;
              <span v-t="'mywb.payments.stripe-desc-2'" />
            </p>
            <wb-button
              variant="white"
              :outlined="data.hasBillingInfo"
              :disabled="!data.hasBillingInfo ||!permissions.showBilling"
              :loading="data.loadingExpressButton || data.accountLinked ==='pending'"
              data-test-id="linkStripeAccountBtn"
              :label="data.accountLinked ==='pending'
                ? i18n.t('mywb.common.linking-account'): i18n.t('mywb.common.connect-stripe')"
              @click="methods.linkPaymentsAccount"
            />
          </div>
        </template>
      </wb-card>
    </template>
  </wb-card>

  <payments-configuration-reset-modal
    v-if="data.openResetAccountModal"
    :loading-continue-linking="data.loadingContinueLinking"
    :loading-reset-linking="data.loadingResetLinking"
    @continue-linking="methods.continueLinking"
    @delete-account="methods.deleteAccount"
    @on-close="methods.closeModal"
  />
</template>

<script setup lang="ts">

import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { trackDataAction, trackDataError } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { HttpError } from '@wallbox/http'
import { useRouter } from 'vue-router'
import PaymentsConfigurationResetModal from '@/components/modals/PaymentsConfigurationResetModal.vue'
import BillingInformation from '@/components/payments/BillingInformation.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { STRIPE_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const stripeUseCases = injectStrict(STRIPE_USE_CASES)
const router = useRouter()
const i18n = useI18n()

const props = defineProps<{
  showTitle?: boolean
  internalLink?: boolean
}>()

interface Data {
  connectionError: boolean
  countryError: boolean
  currencyError: boolean
  countryExpressError: boolean
  loadingExpressButton: boolean
  accountLinked: 'unlinked' | 'pending' | 'error' | 'confirmed' | 'unlink',
  openResetAccountModal: boolean
  loadingContinueLinking: boolean
  loadingResetLinking: boolean
  hasBillingInfo: boolean
}

const data = reactive<Data>({
  connectionError: false,
  countryError: false,
  currencyError: false,
  countryExpressError: false,
  loadingExpressButton: false,
  accountLinked: 'unlinked',
  openResetAccountModal: false,
  loadingContinueLinking: false,
  loadingResetLinking: false,
  hasBillingInfo: false
})

const compute = reactive({
  hasAnyError: computed(() => {
    return data.connectionError ||
      data.currencyError ||
      data.countryError ||
      data.countryExpressError ||
      data.accountLinked === 'error'
  })
})

const methods = {
  handlePromiseErrors (error: HttpError) {
    if (error.code === 6015) {
      data.countryError = true
    } else if (error.code === 6017) {
      data.currencyError = true
    } else if (error.code === 403) {
      data.countryExpressError = true
    } else {
      data.connectionError = true
    }

    trackDataError('connect-stripe-error', {
      error
    })

    data.loadingContinueLinking = false
    data.loadingResetLinking = false
    data.loadingExpressButton = false
  },

  async linkPaymentsAccount () {
    data.connectionError = false
    data.countryError = false

    data.loadingExpressButton = true
    trackDataAction('connect-stripe-express-account')

    const hasPaymentsAccount = await stripeUseCases
      .hasPaymentsAccount(state.organizations.getCurrentOrganization.groupUid)

    data.openResetAccountModal = hasPaymentsAccount

    if (!data.openResetAccountModal) {
      try {
        await methods.setupPayments()
        await methods.continueLinking()
      } catch (error) {
        if (error instanceof HttpError) {
          methods.handlePromiseErrors(error)
        } else {
          trackDataError('connect-stripe-unknown-error', {
            error
          })
          throw error
        }
      }
    }

    data.loadingExpressButton = false
  },

  async continueLinking (loading = true) {
    try {
      if (loading) data.loadingContinueLinking = true
      await methods.getLinkPaymentsAccount()
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handlePromiseErrors(error)
      } else {
        trackDataError('connect-stripe-unknown-error', {
          error
        })
        throw error
      }
    }
  },

  setupPayments () {
    return stripeUseCases.createPaymentsAccount(state.organizations.getCurrentOrganization.groupUid)
  },

  async getLinkPaymentsAccount () {
    const { protocol, host, pathname } = location

    const paymentsRoute = router.resolve({
      name: ROUTES.PAYMENTS_CONFIGURATION,
      query: {
        accountLinked: 'true'
      }
    }).href

    const refreshUrl = `${protocol}//${host}${pathname}`
    const redirectUrl = `${protocol}//${host}${paymentsRoute}`

    const linkStripeOnboarding = await stripeUseCases.getLinkPaymentsAccount({
      groupUid: state.organizations.getCurrentOrganization.groupUid,
      refreshUrl,
      redirectUrl
    })

    data.loadingContinueLinking = false
    data.loadingResetLinking = false
    data.loadingExpressButton = false
    data.openResetAccountModal = false

    window.location.href = linkStripeOnboarding
  },

  async deleteAccount () {
    data.loadingResetLinking = true

    try {
      await stripeUseCases.deletePaymentsAccount(state.organizations.getCurrentOrganization.groupUid)
      await methods.setupPayments()
      await methods.continueLinking(false)
    } catch (error) {
      if (error instanceof HttpError) {
        methods.handlePromiseErrors(error)
      } else {
        trackDataError('connect-stripe-unknown-error', {
          error
        })
        throw error
      }
    }
  },

  closeModal () {
    data.openResetAccountModal = false
    data.loadingExpressButton = false
  }
}

function created () {
  if (state.organizations.getCurrentOrganization.paymentsAccount) {
    data.accountLinked = 'confirmed'
  }
}
created()
</script>

<style lang="postcss">
.stripe-card {
  background: #635BFF!important;
  color: var(--white);

  &.border-less {
    border-top-right-radius: 0!important;
    border-top-left-radius: 0!important;
  }
}

.stripe-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content min-content min-content;
  justify-items: center;
  border-radius: 1rem;
  margin-top: 8px;
  border: 1px solid var(--grey-200);
}

.stripe-img {
  max-width: 160px;
  fill: var(--white);
}

.spinner {
  margin: 32px auto;
}

.ordered-list {
  counter-reset: list-number;
}

.ordered-item::before {
  counter-increment: list-number;
  content: counter(list-number)'.\00a0';
  display: inline-block;
}

.justify {
  justify-content: space-between;
}

.label-stripe-connected {
  background-color: rgb(255 255 255 / 40%)!important;
  color: var(--white)!important;
  opacity: 1!important;

  & :deep(.icon) {
    color: var(--success-500);
  }
}
</style>
