export enum PAYMENTS_ERROR {
  OCPP = 'OCPP',
  MODEL_NOT_COMPATIBLE = 'MODEL_NOT_COMPATIBLE',
  NEEDS_UPDATE = 'NEEDS_UPDATE',
  LOCATION_NOT_COMPLETE = 'LOCATION_NOT_COMPLETE'
}

export interface ChargerPaymentsCompatible {
  payPerCharge: {
    isCompatible: boolean
    reason?: PAYMENTS_ERROR
  }

  payPerMonth: {
    isCompatible: boolean
    reason?: PAYMENTS_ERROR
  }
}
