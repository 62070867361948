import type { AuthRepository, SignUpActivated } from '../domain'

export function doSignUpActivated (repository: AuthRepository) {
  return async (signUp: SignUpActivated) => {
    await repository.signUp(signUp)

    return await repository.signIn({
      email: signUp.email,
      password: signUp.password
    })
  }
}
