import { endpoints } from '@/core/shared/endpoints.config'
import type { AuthRepository } from '../../domain'
import ENV from '@/engine/env/web.env'
import { clientConfig } from '@/engine/clients'
import type {
  GetMeResponse,
  GetUserDetail,
  IsUserEmailRegisteredResponse,
  PasswordResetRequestPayload,
  SignInResponse
} from './types'
import { USER_EMAIL_STATUS } from '../../domain/UserEmailCheck'
import { ulid } from 'ulid'
import { cookiePersistance, localPersistance, tokenPersistance } from '@wallbox/http'
import type { RepositoryHttpBuild } from '@/core/shared'

export const userApiRepositoryBuilder: RepositoryHttpBuild<AuthRepository> = ({ httpService }) => {
  const persistance = ENV.api.authSystem === 'cookie' ? cookiePersistance : localPersistance
  async function refreshToken () {
    const token = tokenPersistance(persistance).getRefreshToken()
    if (!token) {
      throw new Error()
    }

    const response = await instance.get<SignInResponse>(endpoints.userApi.refreshToken, {
      headers: {
        Partner: clientConfig.partner,
        Authorization: `Bearer ${token}`
      }
    })

    if (!response) throw new Error('error refresh token')

    return {
      id: response.data.attributes.user_id,
      token: response.data.attributes.token,
      refreshToken: response.data.attributes.refresh_token
    }
  }

  const loguedUserAPI = httpService.create({
    baseURL: ENV.api.userApiBaseUrl,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await refreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const instance = httpService.create({
    baseURL: ENV.api.userApiBaseUrl,
    options: {
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      }
    }
  })

  const loguedWAPI = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await refreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getIsUserRegistered (payload) {
      const result = await instance.get<IsUserEmailRegisteredResponse>(
        endpoints.userApi.userEmail.replace('{email}', payload.email),
        {
          headers: {
            Partner: clientConfig.partner
          },
          signal: new AbortController().signal
        }
      )

      if (!result) {
        return USER_EMAIL_STATUS.NOT_REGISTERED
      }

      return {
        confirmed: USER_EMAIL_STATUS.CONFIRMED,
        not_confirmed: USER_EMAIL_STATUS.NOT_CONFIRMED,
        not_registered: USER_EMAIL_STATUS.NOT_REGISTERED
      }[result.data.attributes.status]
    },

    async signIn (payload) {
      const info = await instance.get<SignInResponse>(endpoints.userApi.signIn, {
        auth: {
          username: payload.email,
          password: payload.password
        },
        headers: {
          Partner: clientConfig.partner,
          'X-Request-ID': `Portal-${ulid()}`
        }
      })

      if (info) {
        tokenPersistance(persistance).setToken(info.data.attributes.token)

        tokenPersistance(persistance).setRefreshToken(info.data.attributes.refresh_token)

        return info.data.attributes.user_id
      }

      return undefined
    },

    async signUp (body) {
      const id = ulid()

      await instance.post(endpoints.userApi.create, {
        data: {
          type: 'user',
          id,
          attributes: {
            name: body.name,
            email: body.email,
            password: body.password,
            terms: body.terms,
            country_code: body.countryIso2,
            language: body.language,
            client_type: body.clientType,
            activation_code: body.activationCode
          }
        }
      }, {
        headers: {
          Partner: clientConfig.partner
        }
      })

      return id
    },

    async oauthSignIn (body) {
      const response = await instance.post<SignInResponse>(
        endpoints.userApi.oauthSignin.replace('{provider}', body.oauthProvider),
        {
          data: {
            type: 'social-google-signin',
            attributes: {
              id_token: body.idToken
            }
          }
        }, {
          headers: {
            Partner: clientConfig.partner
          }
        }
      )

      if (!response.data?.data) throw new Error('empty response')

      tokenPersistance(persistance).setToken(response.data.data.attributes.token)
      tokenPersistance(persistance).setRefreshToken(response.data.data.attributes.refresh_token)

      return response.data.data.attributes.user_id
    },

    async oauthSignUp (body) {
      const id = ulid()

      await instance.post(
        endpoints.userApi.oauthSignup.replace('{provider}', body.oauthProvider),
        {
          data: {
            type: 'user',
            id,
            attributes: {
              id_token: body.idToken,
              name: body.name,
              surname: body.surname,
              country_code: body.countryIso2,
              language: body.language,
              terms: body.terms
            }
          }
        }, {
          headers: {
            Partner: clientConfig.partner
          }
        }
      )
    },

    async passwordResetRequest (body: PasswordResetRequestPayload): Promise<void> {
      await instance.post(endpoints.userApi.passwordResetRequest, {
        data: {
          type: 'user',
          id: ulid(),
          attributes: {
            ...body
          }
        }
      }, {
        headers: {
          Partner: clientConfig.partner
        }
      })
    },

    async passwordReset (body) {
      await instance.post(endpoints.userApi.passwordReset, {
        data: {
          type: 'reset_password',
          attributes: {
            password: body.password,
            confirm_password: body.password
          }
        }
      }, {
        headers: {
          Partner: clientConfig.partner,
          Authorization: `Bearer ${body.token}`
        }
      })
    },

    async activateUser (body) {
      await instance.post(endpoints.userApi.activateUser, {
        data: {
          type: 'user_activation',
          attributes: {
            email: body.email,
            activation_code: body.activationCode
          }
        }
      }, {
        headers: {
          Partner: clientConfig.partner
        }
      })
    },

    async updatePassword (passwordUpdate) {
      await loguedUserAPI.put(endpoints.userApi.usersUserIdPassword.replace('{userId}', passwordUpdate.userId), {
        data: {
          type: 'update_user_password',
          attributes: {
            current_password: passwordUpdate.currentPassword,
            new_password: passwordUpdate.password
          }
        }
      }, {
        Partner: clientConfig.partner
      })
    },

    async getMe () {
      const me = await loguedUserAPI.get<GetMeResponse>(endpoints.userApi.usersMe, {
        headers: {
          Partner: clientConfig.partner
        }
      })

      if (!me) return

      return {
        id: me.data.id,
        name: me.data.attributes.name,
        surname: me.data.attributes.surname,
        email: me.data.attributes.email,
        phone: me.data.attributes.phone,
        avatar: me.data.attributes.avatar,
        countryIso2: me.data.attributes.country,
        pin: +me.data.attributes.pin
      }
    },

    async updateMe (updateMe) {
      const info: any = (await loguedWAPI.get(endpoints.v2.countries, {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      }))

      const countryId = info.countries.find((country: any) => country.iso2 === updateMe.countryIso2)?.id ?? 1

      const userOldId: any = await loguedWAPI.get(endpoints.v4.user_ulid_id.replace('{ulid}', updateMe.id), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })
      const userId = userOldId.data.attributes.value

      await loguedWAPI.put(endpoints.v1.users_data_userId.replace('{userId}', userId.toString()), {
        name: updateMe.name,
        surname: updateMe.surname,
        phone_code: updateMe.phoneCode,
        phone: updateMe.phone,
        country_id: countryId
      })
    },

    async updateAutoReporting (updateAutoReporting) {
      const userOldId: any = await loguedWAPI
        .get(endpoints.v4.user_ulid_id.replace('{ulid}', updateAutoReporting.userId), {
          headers: {
            'workbox-cache-type': 'cache-first'
          }
        })

      const userId = userOldId.data.attributes.value

      await loguedWAPI.put(endpoints.v1.users_data_userId.replace('{userId}', userId.toString()), {
        reporting: updateAutoReporting.autoReporting
      })
    },

    async getUserReportingSettings (userUid) {
      const userOldId: any = await loguedWAPI.get(endpoints.v4.user_ulid_id.replace('{ulid}', userUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      const userId = userOldId.data.attributes.value

      const userDetail = await loguedWAPI.get<GetUserDetail>(
        endpoints.v1.users_data_userId.replace('{userId}', userId?.toString())
      )

      return {
        hasAutoReporting: userDetail?.reporting ?? false,
        systemFormat: userDetail?.system_format ?? 'eu'
      }
    },

    async getUserPhoneCode (userUid) {
      const userOldId: any = await loguedWAPI.get(endpoints.v4.user_ulid_id.replace('{ulid}', userUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      const userId = userOldId.data.attributes.value

      const userDetail = await loguedWAPI.get<GetUserDetail>(
        endpoints.v1.users_data_userId.replace('{userId}', userId?.toString())
      )

      return userDetail?.phone_code
    },

    async updateSystemFormat (updateSystemFormat) {
      const userOldId: any = await loguedWAPI
        .get(endpoints.v4.user_ulid_id.replace('{ulid}', updateSystemFormat.userUid), {
          headers: {
            'workbox-cache-type': 'cache-first'
          }
        })

      const userId = userOldId.data.attributes.value

      await loguedWAPI.put(endpoints.v1.users_data_userId.replace('{userId}', userId.toString()), {
        system_format: updateSystemFormat.systemFormat
      })
    },

    async logout () {
      await loguedUserAPI.post(endpoints.userApi.signout)

      tokenPersistance(persistance).remove()
    },

    refreshToken
  }
}
