import { number, object, type InferType } from 'yup'

const removeUserFromAccessConfigSchema = object({
  userId: number().required(),
  accessConfigId: number().required()
})

export type RemoveUserFromAccessConfig = InferType<typeof removeUserFromAccessConfigSchema>

export async function ensureRemoveUserFromAccessConfigIsValid (payload: any) {
  return await removeUserFromAccessConfigSchema.validate(payload, { abortEarly: false })
}
