<template>
  <div>
    <users-charger-table
      :loading="data.loading"
      :users="data.users"
    />
  </div>
</template>

<script setup lang="ts">
import usersChargerTable from '@/components/tables/UsersChargerTable.vue'
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import { injectStrict, USER_USE_CASES } from '@/engine/injectors'
import type { User } from '@/core/user'

const route = useRoute()
const userUsesCases = injectStrict(USER_USE_CASES)

interface Data {
  loading: boolean
  users: User[]
}
const data = reactive<Data>({
  loading: true,
  users: []
})

async function created () {
  data.loading = true

  const chargerUid = route.params.chargerUid.toString()
  data.users = await userUsesCases.getAllUsersByCharger(chargerUid)

  data.loading = false
}
created()
</script>
