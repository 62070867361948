export enum GUNLOCK_ERROR {
  OCPP = 'OCPP',
  MODEL_NOT_COMPATIBLE = 'MODEL_NOT_COMPATIBLE',
  NEEDS_UPDATE = 'NEEDS_UPDATE'
}

export interface ChargerGunLockCompatible {
  isCompatible: boolean
  reason?: GUNLOCK_ERROR
}
