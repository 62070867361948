<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="data.options"
        :loading="props.loading"
        data-test-id="tableGroupsUsers"
      >
        <access-configs-groups-table-row
          v-for="accessConfig in props.accessConfigs"
          :key="accessConfig.groupId"
          :access-config="accessConfig"
          :data-test-id="`usersGroup${accessConfig.name}`"
          @on-edit="emit('on-edit', $event)"
          @on-delete="emit('on-delete', $event)"
        />
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import AccessConfigsGroupsTableRow from '@/components/tables/AccessConfigsGroupsTableRow.vue'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { AccessConfig } from '@/core/accessConfig'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()

interface Props {
  loading?: boolean
  accessConfigs: AccessConfig[]
}
const props = defineProps<Props>()

interface Data {
  options: TableProps['options']
}
const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: i18n.t('mywb.common.name'), align: 'left', minWidth: 200 },
      { name: i18n.t('mywb.common.users'), align: 'center', minWidth: 200 },
      { name: i18n.t('mywb.common.chargers'), align: 'center', minWidth: 200 },
      { name: i18n.t('mywb.common.actions'), align: 'right', minWidth: 200 }
    ],
    stickyHead: mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

interface Events {
  (e: 'on-edit', accessConfig: AccessConfig): void
  (e: 'on-delete', accessConfig: AccessConfig): void
}
const emit = defineEmits<Events>()
</script>
