import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const updateChargersLocation: UseCase<ChargerRepository, 'updateChargersLocation'> = repository => {
  return async (chargersUid, groupUid) => {
    await repository.updateChargersLocation(
      chargersUid,
      groupUid
    )
  }
}
