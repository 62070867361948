<template>
  <wb-card
    :headline="i18n.t('mywb.common.remote-assistance')"
    :subhead="i18n.t('mywb.common.remote-assistance.description')"
    alignment="center"
  >
    <template #actions>
      <wb-button
        icon="edit"
        data-test-id="charger-edit-remote-assistance-cta"
        :label="i18n.t('mywb.common.edit')"
        variant="white"
        outlined
        :disabled="props.loading"
        @click="data.showEditRemoteAssistanceModal = true"
      />
    </template>

    <template #modal>
      <charger-edit-remote-assistance-modal
        v-if="data.showEditRemoteAssistanceModal"
        @on-close="methods.handleClose"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerEditRemoteAssistanceModal from '@/components/modals/ChargerEditRemoteAssistanceModal.vue'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  showEditRemoteAssistanceModal: boolean}

const data = reactive<Data>({
  showEditRemoteAssistanceModal: false
})

const methods = {
  handleClose () {
    data.showEditRemoteAssistanceModal = false
  }
}
</script>

<style lang="postcss" scoped></style>
