<template>
  <footer id="footer" class="footer">
    <div class="footer-width-constrain">
      <div v-if="compute.terms" class="terms">
        {{ clientConfig.footerRights }}
        <a
          v-for="term in compute.terms"
          :key="term.url"
          :href="term.url"
          class="footer-policy-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ term.text }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { clientConfig } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'

const i18n = useI18n()

interface DataType {
  privacy: string
  terms: string
}

interface ItemType {
  url: string
  icon: string
  text?: string
}

const data: DataType = reactive({
  privacy: i18n.t('mywb.common.data-privacy-detail'),
  terms: i18n.t('mywb.common.use-terms')
})

const compute = reactive({
  terms: computed((): ItemType[] => {
    if (!clientConfig.docs) return []

    return Object.entries(clientConfig.docs).reduce((objCarry, [key, entry]) => ({
      ...objCarry,
      [key]: {
        url: entry,
        text: data[key as keyof DataType]
      }
    }), [])
  })
})
</script>

<style lang="postcss" scoped>
.footer {
  display: flex;
  place-items: center;
  backface-visibility: hidden;
  margin: 8px 0;
}

.footer-width-constrain {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas: "terms";
  align-items: center;
  max-width: var(--max-width-content);
  justify-content: center;

  @media (--tablet) {
    grid-template-columns: max-content auto min-content;
    grid-template-areas: "terms";
    justify-content: space-between;
  }
}

.terms {
  grid-area: terms;
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}

.footer-policy-link {
  color: var(--black);
  text-decoration: none;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 0;

  &:hover {
    text-decoration: underline;
  }
}
</style>
