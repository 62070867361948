import OrganizationsView from '@/views/OrganizationsView.vue'
import OrganizationDetailView from '@/views/OrganizationDetailView.vue'
import type { RouteRecordRaw } from 'vue-router'
import ROUTES from './routes'

export default [
  {
    path: 'organizations',
    name: ROUTES.ORGANIZATIONS,
    component: OrganizationsView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.ORGANIZATIONS,
        text: 'mywb.common.organizations'
      }
    }
  },
  {
    path: 'organizations/:organizationUid',
    name: ROUTES.ORGANIZATION_DETAIL,
    component: OrganizationDetailView,
    meta: {
      breadcrumb: {
        name: ROUTES.ORGANIZATIONS
      }
    }
  }
] as RouteRecordRaw[]
