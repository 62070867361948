<template>
  <wb-input
    v-model.trim="compute.filteredQuery"
    data-test-id="searchButton"
    :disabled="props.loading ||
      state.charger.getChargers.length === 0 && state.filters.backendChargerFilters.length === 0"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
    icon="search"
    type="search"
  />

  <shared-filter
    v-if="props.includeColumns?.includes('location')"
    v-model="compute.filteredLocations"
    multiple
    with-search
    label="name"
    icon="locations"
    :name="i18n.t('mywb.common.locations')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    data-test-id="groupsFilter"
    :options="data.locations"
    :reduce="(item: Location) => item.id"
    :loading="props.loading"
    :disabled="state.charger.getChargers.length === 0 && state.filters.backendChargerFilters.length === 0"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'locations' })"
  >
    <template #option="{ option }">
      <div class="grid g-8">
        {{ option.name }}
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-if="props.includeColumns?.includes('status')"
    v-model="compute.filteredStatuses"
    option-key="code"
    :name="i18n.t('mywb.common.status')"
    :options="compute.statuses"
    :reduce="(item: typeof compute.statuses[number]) => item.code"
    :loading="props.loading"
    data-test-id="statusFilter"
    :disabled="state.charger.getChargers.length === 0 && state.filters.backendChargerFilters.length === 0"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'statuses' })"
  >
    <template #input-text="{ option, label }">
      <div class="grid g-8">
        <span
          v-if="option?.code"
          :style="option.color"
          class="bullet wb-icons is-size-300"
        >lens_filled</span>
        {{ label }}
      </div>
    </template>

    <template #option="{ option }">
      <div class="grid g-8">
        <span
          v-if="option.code"
          :style="option.color"
          class="bullet wb-icons is-size-300"
        >lens_filled</span>
        {{ option.label }}
      </div>
    </template>
  </shared-filter>

  <shared-filter
    v-model="compute.filteredChargerTypes"
    multiple
    label="name"
    :name="i18n.t('mywb.charger.type')"
    data-test-id="chargersTypeFilter"
    :options="data.chargerTypes"
    option-key="code"
    :loading="props.loading"
    :disabled="state.charger.getChargers.length === 0 && state.filters.backendChargerFilters.length === 0"
    @on-error="state.filters.resetFilterValue({ filter: 'chargersFilters', key: 'chargerTypes' })"
  >
    <template #input-text="{ option, label }">
      <div class="grid g-8">
        <img
          v-if="option?.image"
          :src="option.image"
          class="charger-img"
          :alt="label"
        >
        {{ label }}
      </div>
    </template>
  </shared-filter>

  <wb-button
    variant="white"
    size="small"
    :label="i18n.t('mywb.common.clear-all')"
    @click="methods.onResetFilterValue"
  />
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import state from '@/state'
import { chargerStatuses, getColorByStatus } from '@/utilities/charger/chargerStatuses'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { dom } from '@wallbox/toolkit-ui'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { CHARGER_TYPES, type ChargerType } from '@/core/charger'
import { provideLocationUseCases, type Location } from '@/core/location'
import type { ChargerColumns } from '@/types/data/chargerColumns'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()
const locationUsesCases = provideLocationUseCases()

const emit = defineEmits(['on-reset-filters'])

interface Props {
  loading?: boolean
  includeColumns?: ChargerColumns[]
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  includeColumns: () => ['name', 'location', 'status', 'features', 'actions']
})

interface Data {
  chargerTypes: ChargerType[],
  locations: Location[]
}

const data = reactive<Data>({
  chargerTypes: [],
  locations: []
})

const compute = reactive({
  filteredQuery: computed({
    get () {
      return state.filters.chargersFilters?.query
    },

    set: dom.debounce((value: string) => {
      if (value.length === 0 || value.length >= 3) {
        state.filters.setFilterValue({ filter: 'chargersFilters', key: 'query', value })
      }
    }, 500)
  }),

  filteredChargerTypes: computed({
    get () {
      return state.filters.chargersFilters?.chargerTypes ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'chargerTypes', value })
    }
  }),

  filteredLocations: computed({
    get () {
      return state.filters.chargersFilters?.locations ?? []
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'locations', value })
    }
  }),

  filteredStatuses: computed({
    get () {
      return state.filters.chargersFilters?.statuses
    },
    set (value) {
      state.filters.setFilterValue({ filter: 'chargersFilters', key: 'statuses', value })
    }
  }),

  statuses: computed(() => {
    return chargerStatuses
      .filter(status => status.filter)
      .map(status => ({
        ...status,
        color: { '--bullet-color': getColorByStatus(status.code).bg },
        label: i18n.t(status.label)
      }))
  })
})

const methods = {
  onResetFilterValue () {
    state.filters.resetFilters({ filter: 'chargersFilters' })
    emit('on-reset-filters')
  }
}

async function created () {
  data.chargerTypes = (await chargerUseCases.getChargerTypes())
    .filter(charger => charger.code !== CHARGER_TYPES.SUPERNOVA)

  data.locations = await locationUsesCases
    .getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
      getChargers: false,
      cache: 'stale'
    })

  locationUsesCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid, {
    getChargers: false,
    cache: 'network'
  }).then(locations => {
    data.locations = locations
  })
}
created()
</script>

<style lang="postcss" scoped>
.charger-img {
  width: 32px;
  object-fit: cover;
}

.bullet {
  color: var(--bullet-color, var(--white));
  font-size: 12px;
}

.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}
</style>
