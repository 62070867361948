<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.plan.payments-title') }}
    </template>
  </shared-teleport-header>

  <container-component>
    <div data-test-id="businessSection" class="plan-layout g-64">
      <div>
        <div class="grid-plan g-64">
          <article class="item g-32">
            <client-img-component class="img" inline src="monetize" />
            <!-- eslint-disable-next-line -->
            <p class="is-size-500" v-html="i18n.t('mywb.plan.payments-description-1.html')"/>
          </article>
          <article class="item g-32">
            <client-img-component class="img" inline src="monthly_subscription" />
            <!-- eslint-disable-next-line -->
            <p class="is-size-500" v-html="i18n.t('mywb.plan.payments-description-2.html')" />
          </article>
          <article class="item g-32">
            <client-img-component class="img" inline src="semipublic_charge" />
            <!-- eslint-disable-next-line -->
            <p class="is-size-500" v-html="i18n.t('mywb.plan.payments-description-3.html')" />
          </article>
          <article v-if="!state.organizations.isRegionUS" class="item g-32">
            <client-img-component class="img" inline src="cpo" />
            <p class="is-size-500">
              <!-- eslint-disable-next-line -->
              <span v-html="i18n.t('mywb.plan.payments-description-4.html')" />
            </p>
          </article>
        </div>
      </div>

      <plan-upgrade-card
        class="business"
        data-test-id="businessPlanSection"
        :product-id="EnumPlans.BUSINESS"
      />
    </div>
  </container-component>
</template>

<script setup lang="ts">
import PlanUpgradeCard from '@/components/plans/PlanUpgradeCard.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'

import state from '@/state'
import { EnumPlans } from '@/utilities/plans'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()
</script>

<style lang="postcss" scoped>
.title {
  font-size: 3.6rem;
}

.plan-layout {
  display: grid;
  grid-template-columns: 1fr;

  @media (--widescreen) {
    grid-template-columns: 1fr 1fr;
  }
}

.item {
  display: grid;
  grid-template-columns: auto auto;

  & .img {
    flex: 1 0 auto;
    height: 70px;
    width: 70px;
    fill: var(--grey-700);
  }
}

.grid-plan {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 8rem;
}
</style>
