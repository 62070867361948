import yup from '@/hooks/useValidator.hook'

const rfidUpdateSchema = yup.object({
  userId: yup.lazy((value) => typeof value === 'number'
    ? yup.number().required()
    : yup.string().required()
  ),
  groupUid: yup.string().required(),
  rfid: yup.string()
    .rfidValidFormat()
    .required()
})

export type RfidUpdate = yup.InferType<typeof rfidUpdateSchema>

export async function ensureUpdateRfidIsValid (rfidUpdate: any) {
  return await rfidUpdateSchema.validate(rfidUpdate, { abortEarly: false })
}
