import { object, string, type InferType } from 'yup'

export enum OAUTH_PROVIDER {
  GOOGLE = 'google',
  APPLE = 'apple'
}

const oauthSignInSchema = object({
  oauthProvider: string<OAUTH_PROVIDER>().required(),
  idToken: string().required()
})

export type OauthSignIn = InferType<typeof oauthSignInSchema>

export async function ensureOauthSignInIsValid (oauthSignIn: any) {
  return await oauthSignInSchema.validate(oauthSignIn, { abortEarly: false })
}
