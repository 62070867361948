<template>
  <wb-modal
    width="125"
    @close="methods.closeWithoutSave"
  >
    <template #title>
      {{ i18n.t('mywb.rates.edit-schedule') }}
    </template>

    <div
      class="add-rate-content"
    >
      <wb-form class="rate-form">
        <div class="is-fullwidth">
          <charger-rates-select
            v-model="data.rate"
            data-test-id="selectRateInput"
            append-to-body
            class="mt-8"
            :rates="props.rates"
            :type="props.type"
            :charger="props.charger"
          />
        </div>

        <template v-if="data.firstRateAdded">
          <div class="is-fullwidth">
            <h3
              v-t="'mywb.rates.select-days'"
              class="is-size-400 is-font-weight-500 has-text-grey-800"
            />

            <div class="days-grid g-8 mt-16">
              <wb-button
                v-for="day in data.days"
                :key="day.label"
                :variant="day.selected ? 'primary' : 'white'"
                :outlined="day.selected ? false : true"
                shape="squircle"
                size="small"
                :label="day.label"
                :data-test-id="day.label"
                @click="day.selected = !day.selected"
              />
            </div>
          </div>

          <div>
            <h3
              v-t="'mywb.rates.select-hours'"
              class="is-size-400 is-font-weight-500 has-text-grey-800"
            />

            <div class="is-flex g-8 mt-16 mb-8">
              <wb-switch
                v-model="data.sameHours"
                data-test-id="sameHoursSwitch"
              />
              <p
                v-t="'mywb.rates.apply-to-all'"
                class="is-size-200 is-font-weight-500"
              />
            </div>
          </div>

          <rate-schedule-form
            v-if="data.sameHours"
            v-model:from="data.fromTo.from"
            v-model:to="data.fromTo.to"
            class="form-item"
            activated
            data-test-id="sameHoursRateScheduleForm"
          />

          <rate-schedule-form
            v-for="(day, index) in data.days.filter(day => day.selected)"
            v-else
            :key="index"
            v-model:from="data.times[day.key].from"
            v-model:to="data.times[day.key].to"
            :data-test-id="day.label + 'HoursRateScheduleForm'"
            class="form-item"
            :label="day.labelLong"
            :activated="data.isActivated === index"
            @update:activated="value => value ? data.isActivated = index : data.isActivated = undefined"
          />
        </template>

        <wb-alert
          v-else
          variant="info"
          icon="info_filled"
        >
          <p v-t="'mywb.rates.first-rate-notification'" />
        </wb-alert>

        <wb-button
          size="block"
          :disabled="!data.rate || (!data.days.some(day => day.selected) && data.firstRateAdded)"
          data-test-id="addRateButton"
          @click="methods.saveAndContinue"
        >
          {{ i18n.t('mywb.rates.add-rate-to-schedule') }}
        </wb-button>
      </wb-form>

      <div class="has-background-grey-50 p-32 has-border-radius">
        <charger-schedule
          :schedule="data.schedule"
          :rates="Array.from(data.selectedRates.values())"
          :rate-being-added="data.rate"
          :type="props.type"
          has-actions
          @delete-rate="methods.deleteRate"
        />
      </div>
    </div>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="methods.closeWithoutSave"
        />

        <wb-button
          :disabled="!data.dirty || data.rate && (data.days.some(day => day.selected))"
          data-test-id="saveButton"
          :loading="data.loading"
          @click="methods.saveSchedule"
        >
          {{ i18n.t('mywb.common.save') }}
        </wb-button>
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang=ts>
import type { Rate, RateSchedule } from '@/core/rate'
import { reactive, watchEffect, toRaw, watch } from 'vue'
import ChargerRatesSelect from '@/components/charger/ChargerRatesSelect.vue'
import ChargerSchedule from '../charger/ChargerSchedule.vue'
import RateScheduleForm from '../forms/RateScheduleForm.vue'
import { useI18n } from 'vue-i18n'
import state from '@/state'
import { moveScheduleFromLocalToUTC } from '@/utilities/rates/normalizeScheduleInTimezone'
import { trackDataAction, trackDataError, trackDataScreen } from '@/engine/metrics/trackDataManager'
import type { Charger as ChargerCore } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import { HttpError } from '@wallbox/http'

const rateUseCases = injectStrict(RATE_USE_CASES)
const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const i18n = useI18n()

type daysType = 'monday' | 'tuesday'| 'wednesday'| 'thursday'| 'friday'| 'saturday'| 'sunday'
type fromTo = { from?: number, to?: number }

const createDefaultTime = () => ([
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'] as daysType[])
  .reduce<Record<daysType, fromTo>>((obj, key) => {
    obj[key] = { from: undefined, to: undefined }

    return obj
  }, {} as Record<daysType, fromTo>)

interface PropsType {
  schedule?: RateSchedule,
  selectedRates?: Rate[]
  rates: Rate[]
  charger: ChargerCore
  type: 'pay_per_charge' | 'pay_per_month'
  chargerScheduleId?: string
}

const props = defineProps<PropsType>()

interface Events {
  (e: 'close'): void,
  (e: 'cancel', schedule: RateSchedule): void,
  (e: 'update:schedule', schedule: RateSchedule): void,
  (e: 'update:selected-rates', rates: Rate[]): void
}

const emit = defineEmits<Events>()

const methods = {
  createDayArray (rate?: string) {
    return Array.from(Array(24).keys()).map(() => rate ?? '')
  },

  validateSchedule () {
    const ratesSelected = Array.from(data.selectedRates.values()).map(rate => rate.id)
    const toDelete = methods.checkIfRemoveRatesFromSelected(new Set(ratesSelected))

    toDelete.forEach(rate => {
      data.selectedRates.delete(rate)
    })

    state.rates.removeUnusedRates(Array.from(data.selectedRates.keys()), props.type)

    return true
  },

  async saveSchedule () {
    data.loading = true
    if (!methods.validateSchedule()) return

    try {
      const payload = {
        chargerId: props.charger.uid,
        scheduleId: props.chargerScheduleId,
        payload: {
          status: 'active',
          type: props.type,
          schedule: moveScheduleFromLocalToUTC(data.schedule, props.charger.timezone)
        }
      }

      await rateUseCases.saveChargerRateSchedule(payload)

      await chargerUseCases.updateCharger({
        uid: props.charger.uid,
        autoLock: true,
        autoLockTime: 60
      })

      trackDataAction('charger-schedule-updated', payload)
    } catch (error) {
      const errorObj = {
        savingSchedule: true,
        responseError: error,
        responseErrorString: JSON.stringify(error),
        errorMessage: '',
        errorMsg: '',
        errorCause: '',
        errorStatus: 0,
        errorCode: 0 as number | string
      }

      if (error instanceof HttpError) {
        errorObj.errorMessage = error.message
        errorObj.errorMsg = error.msg
        errorObj.errorCause = error.cause as string
        errorObj.errorCode = error.code
        errorObj.errorStatus = error.status
      }

      trackDataError('charger-schedule-error', errorObj)

      throw error
    } finally {
      data.loading = false
      emit('close')
      emit('update:schedule', data.schedule)

      emit('update:selected-rates', Array.from(data.selectedRates.values()))
    }
  },

  saveAndContinue () {
    if (!methods.validateSchedule()) return

    data.dirty = true
    data.firstRateAdded = true
    data.rate = undefined
    data.days.forEach(item => (item.selected = false))
    data.times = createDefaultTime()
    data.fromTo = {
      from: 0,
      to: 0
    }

    data.prevSchedule = structuredClone(toRaw(data.schedule))
    data.prevSelected = structuredClone(toRaw(data.prevSelected))
  },

  closeWithoutSave () {
    if (data.initialSchedule) {
      state.rates.addRates(Array.from(data.initialSelected.values()).map(rate => rate.id), props.type)

      emit('close')
      emit('cancel', data.initialSchedule)
      emit('update:schedule', data.initialSchedule)
      emit('update:selected-rates', Array.from(data.initialSelected.values()))
    }
  },

  editDayArray (
    day: string[],
    prevDay: string[],
    select: boolean,
    rateId: string,
    from: number,
    to: number
  ) {
    const ratesDeleted = new Set<string>()

    const dayEdited = Object.entries(day).reduce((acum, [hour]) => {
      if (select && parseInt(hour) >= from && parseInt(hour) <= to) {
        acum[+hour] = rateId
        ratesDeleted.add(prevDay?.[+hour])
      } else {
        acum[+hour] = prevDay?.[+hour]

        const rate = props.rates.find(rate => rate.id === prevDay?.[+hour])
        if (rate) {
          data.selectedRates.set(prevDay?.[+hour], rate)
        }
      }

      return acum
    }, day)

    return { dayEdited, ratesDeleted }
  },

  checkIfRemoveRatesFromSelected (ratesToCheck: Set<string>) {
    let ratesToMaintain = new Set()

    const days = Object.values(data.schedule) as string[]
    days.forEach(currentDay => {
      const rates = new Set(Object.values(currentDay))
      const rateInUse = new Set([...ratesToCheck].filter(i => rates.has(i)))

      ratesToMaintain = new Set([...rateInUse, ...ratesToMaintain])
    })

    const toDelete = new Set([...ratesToCheck].filter(x => !ratesToMaintain.has(x)))

    return toDelete
  },

  deleteRate (rate: Rate) {
    const days = Object.values(data.schedule) as string[][]

    const countOfRates = days.reduce<Map<string, number>>((countOfRates, day) => {
      return Object.values(day).reduce<Map<string, number>>((acc, val) => {
        acc.set(val, (acc.get(val) ?? 0) + 1)
        return acc
      }, countOfRates)
    }, new Map())

    countOfRates.delete(rate.id)

    const rateToReplace = Array.from(countOfRates.entries())
      .reduce<{ count: number, rate: string}>((rateToUse, [key, value]) => {
        if (rateToUse.count < value) {
          return {
            count: value,
            rate: key
          }
        }
        return rateToUse
      }, { count: 0, rate: '' })

    days.forEach(day => {
      Object.entries(day).reduce((acum, [hour, currentRate]) => {
        if (currentRate === rate.id) {
          acum[+hour] = rateToReplace.rate
        }

        return acum
      }, day)
    })

    data.selectedRates.delete(rate.id)

    state.rates.removeRate(rate.id, props.type)
    methods.saveAndContinue()
  }
}

interface Data {
  days: Array<{ key: daysType, label: string, labelLong: string, selected: boolean, from: number, to: number}>
  selectedRates: Map<string, Rate>
  rate?: Rate
  sameHours: boolean
  fromTo: fromTo
  times: Record<daysType, fromTo>
  schedule: RateSchedule
  firstRateAdded?: boolean
  prevSchedule?: RateSchedule
  prevSelected: Map<string, Rate>
  initialSelected: Map<string, Rate>
  initialSchedule?: RateSchedule
  isActivated?: number
  dirty: boolean
  loading: boolean
}

const data: Data = reactive({
  days: [
    {
      key: 'monday',
      label: i18n.t('mywb.days.monday-short'),
      labelLong: i18n.t('mywb.days.monday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'tuesday',
      label: i18n.t('mywb.days.tuesday-short'),
      labelLong: i18n.t('mywb.days.tuesday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'wednesday',
      label: i18n.t('mywb.days.wednesday-short'),
      labelLong: i18n.t('mywb.days.wednesday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'thursday',
      label: i18n.t('mywb.days.thursday-short'),
      labelLong: i18n.t('mywb.days.thursday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'friday',
      label: i18n.t('mywb.days.friday-short'),
      labelLong: i18n.t('mywb.days.friday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'saturday',
      label: i18n.t('mywb.days.saturday-short'),
      labelLong: i18n.t('mywb.days.saturday'),
      selected: false,
      from: 0,
      to: 23
    },
    {
      key: 'sunday',
      label: i18n.t('mywb.days.sunday-short'),
      labelLong: i18n.t('mywb.days.sunday'),
      selected: false,
      from: 0,
      to: 23
    }
  ],
  selectedRates: new Map(),
  prevSelected: new Map(),
  initialSelected: new Map(),
  firstRateAdded: (props.selectedRates?.length ?? 0) > 0,
  sameHours: true,
  isActivated: 0,
  fromTo: {
    from: 0,
    to: 23
  },
  times: createDefaultTime(),
  schedule: {
    monday: methods.createDayArray(),
    tuesday: methods.createDayArray(),
    wednesday: methods.createDayArray(),
    thursday: methods.createDayArray(),
    friday: methods.createDayArray(),
    saturday: methods.createDayArray(),
    sunday: methods.createDayArray()
  },
  dirty: false,
  loading: false
})

watch(() => data.rate, () => {
  if (data.rate) {
    data.selectedRates.set(data.rate.id, data.rate)
    state.rates.addRate(data.rate.id, props.type)
  }
})

watchEffect(() => {
  if (data.rate && !data.firstRateAdded) {
    data.selectedRates.clear()
    state.rates.removeRates(props.type)

    data.selectedRates.set(data.rate.id, data.rate)
    state.rates.addRate(data.rate.id, props.type)
    data.schedule = Object.entries(data.schedule)
      .reduce((acum, [key]) => ({
        ...acum,
        [key]: methods.createDayArray(data.rate?.id)
      }), {}) as RateSchedule
  }
})

watchEffect(() => {
  if (!data.sameHours) return

  const entries = Object.entries(data.times) as [daysType, fromTo][]

  data.times = entries.reduce<Record<daysType, fromTo>>((times, [time]) => {
    if (times[time].from && times[time].to) return times

    times[time] = { ...data.fromTo }
    return times
  }, data.times)
})

watchEffect(() => {
  if (!data.firstRateAdded) return

  let ratesToCheckIfDelete = new Set<string>()

  data.days.forEach(day => {
    let selectedTime = data.fromTo

    if (!data.sameHours) {
      selectedTime = data.times[day.key]
    }

    if (data.rate && (selectedTime.from != null) && (selectedTime.to != null)) {
      const info = methods.editDayArray(
        data.schedule[day.key],
        data.prevSchedule?.[day.key] ?? [],
        day.selected,
        data.rate.id,
        selectedTime.from,
        selectedTime.to
      )

      data.schedule[day.key] = info.dayEdited

      ratesToCheckIfDelete = new Set([...ratesToCheckIfDelete, ...info.ratesDeleted])
    }
  })

  const toDelete = methods.checkIfRemoveRatesFromSelected(ratesToCheckIfDelete)

  toDelete.forEach(rate => {
    data.selectedRates.delete(rate)
  })
})

function created () {
  props.schedule && (data.schedule = props.schedule)
  data.prevSchedule = structuredClone(toRaw(data.schedule))
  data.initialSchedule = structuredClone(toRaw(data.schedule))

  props.selectedRates?.forEach(selectedRate => {
    data.selectedRates.set(selectedRate.id, selectedRate)
    data.prevSelected?.set(selectedRate.id, selectedRate)
    data.initialSelected?.set(selectedRate.id, selectedRate)
  })

  trackDataScreen('update-schedule')
}

created()
</script>

<style lang="postcss" scoped>
.add-rate-content {
  display: grid;
  gap: 64px;

  @media (--desktop) {
    grid-template-columns: 1fr 3fr;
  }
}

.rate-form {
  grid-auto-rows: max-content;
  --vertical-gap: 24px !important;
}

.days-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
}

.day-label {
  width: auto;

  @media (--tablet) {
    min-width: 50px;
    padding: 12px !important;
  }
}

.text-center {
  text-align: center;
}

h3,
p {
  color: var(--black);
}

.form-item {
  margin-top: -24px;

  & ~ .form-item {
    margin-top: 0;
  }
}

.button-actions {
  justify-content: space-between;

  & button {
    width: 100%;
    max-width: 250px;
  }
}

.has-border-radius {
  border-radius: 8px;
}
</style>
