import type { EnumRoles } from '@/utilities/user-roles'
import { number, array, object, string, type InferType, boolean, mixed } from 'yup'

const inviteUsersWithChargersSchema = object({
  groupUid: string().required(),
  chargers: array().of(number().required()).required(),
  role: mixed<EnumRoles>().required(),
  users: array().of(object({
    email: string().email().required(),
    rfid: string()
  })).required(),
  hasPayPerMonth: boolean().required(),
  lang: string().required(),
  branding: string().required()
})

export type InviteUsersWithChargers = InferType<typeof inviteUsersWithChargersSchema>

export async function ensureInviteUsersWithChargersSchemaIsValid (payload: any) {
  return await inviteUsersWithChargersSchema.validate(payload, { abortEarly: false })
}
