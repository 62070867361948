<template>
  <wb-table-row
    :id="props.accessConfig.groupId"
    @click="router.push({ name: ROUTES.USERS, query: { group: props.accessConfig.id.toString() } })"
  >
    <wb-table-row-item data-test-id="name">
      <p class="is-font-weight-500">
        {{ props.accessConfig.name }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="totalUsers">
      <wb-badge variant="green">
        {{ props.accessConfig.users.length }}
      </wb-badge>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="totalChargers">
      <wb-badge variant="green">
        {{ props.accessConfig.chargers.length || 0 }}
      </wb-badge>
    </wb-table-row-item>
    <wb-table-row-item @click.stop>
      <wb-button-groups>
        <wb-dropdown
          v-if="permissions.canCreateUserGroups"
          class="group-item"
        >
          <template #activator>
            <wb-button
              v-if="permissions.canCreateUserGroups"
              id="group-edit"
              icon="edit"
              data-test-id="editOption"
              variant="white"
              size="small"
              outlined
              class="group-item"
              @mousedown="emit('on-edit', props.accessConfig)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.edit') }}
          </template>
        </wb-dropdown>
        <wb-dropdown
          v-if="permissions.canCreateUserGroups"
          class="group-item"
        >
          <template #activator>
            <wb-button
              v-if="permissions.canCreateUserGroups"
              id="group-delete"
              icon="delete"
              data-test-id="deleteOption"
              variant="danger"
              size="small"
              outlined
              class="group-item"
              @mousedown="emit('on-delete', props.accessConfig)"
            />
          </template>
          <template #tooltip>
            {{ i18n.t('mywb.common.delete') }}
          </template>
        </wb-dropdown>
      </wb-button-groups>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import type { AccessConfig } from '@/core/accessConfig'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

interface Props {
  accessConfig: AccessConfig
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-edit', accessConfig: AccessConfig): void
  (e: 'on-delete', accessConfig: AccessConfig): void
}
const emit = defineEmits<Events>()
</script>
