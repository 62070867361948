<template>
  <div
    class="charger-add-row pt-8 g-24"
    :data-test-id="`addChargerRow-${props.rowIndex}`"
  >
    <wb-input
      :model-value="props.row.serial.value"
      data-test-id="chargerSNModal"
      :label="mq.current === 'mobile' ? i18n.t('mywb.charger.serial-number') : ''"
      placeholder="000000"
      :error="row.serial.error"
      :disabled="allDisabled || props.loading || row.added"
      @update:model-value="methods.updateColumn('serial', $event)"
    />

    <wb-input
      :model-value="row.puk.value"
      data-test-id="chargerUIDModal"
      placeholder="00AA00AA"
      :label="mq.current === 'mobile' ? i18n.t('mywb.charger.uid-or-puk') : ''"
      :error="row.puk.error"
      :disabled="allDisabled || props.loading || row.added"
      @update:model-value="methods.updateColumn('puk', $event)"
    />

    <div v-if="compute.showDeleteRowButton" class="charger-add-row__delete-button">
      <wb-button
        :loading="props.loading"
        data-test-id="chargerDeleteRow"
        variant="white"
        outlined
        icon="delete"
        @click="emit('delete-row', props.rowIndex)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import type { ChargerToAdd } from '@/core/charger'
import { toRaw, computed, reactive } from 'vue'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()

interface Props {
  allDisabled?: boolean
  row: ChargerToAdd
  rowIndex: number
  rowTotal: number
  loading?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:row', row: Props['row']): void
  (e: 'delete-row', index: number): void
  (e: 'update-column', payload: { property: 'serial' | 'puk', charger: Props['row'], index: number }): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  showDeleteRowButton: computed(() =>
    !props.allDisabled && !props.row.added && ((props.rowIndex !== 0 && props.rowTotal === 1) || props.rowTotal > 1))
})

const methods = {
  updateColumn (property: 'serial' | 'puk', value: string | number) {
    const row = structuredClone(toRaw(props.row)) as Props['row']
    row[property].value = value.toString()
    emit('update:row', row)
    emit('update-column', { property, charger: row, index: props.rowIndex })
  }
}
</script>

<style lang="postcss" scoped>
.charger-add-row {
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;
  }
}

.charger-add-row__delete-button {
  display: flex;
  place-items: top;
  margin-top: 0.5rem;
}

.is-flex {
  display: flex;
  padding: 14px 0;
}
</style>
