<template>
  <wb-table-row
    class="grid-table-row"
    :data-test-id="`user-${props.user.id}`"
    @click="emit('on-click')"
  >
    <wb-table-row-item>
      <div class="is-flex is-align-center">
        <wb-user-avatar
          class="mr-16"
          data-test-id="userPicture"
          size="small"
          :src="props.user.avatar"
          :initials="props.user.initials"
        />
        <div>
          <p
            v-if="compute.userName"
            data-test-id="userName"
            class="is-font-weight-500"
          >
            {{ compute.userName }}
          </p>
          <p data-test-id="userEmail" class="has-text-grey-500">
            {{ props.user.email }}
          </p>
        </div>
      </div>
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userGroup">
      {{ props.user.accessGroup?.name || '' }}
    </wb-table-row-item>

    <wb-table-row-item data-test-id="userProfile">
      <user-role-item :profile="props.user.role" />
    </wb-table-row-item>

    <wb-table-row-item
      v-if="permissions.showPayments"
    >
      <wb-dropdown>
        <template #activator>
          <div :class="compute.contractStatus.class" data-test-id="stateSubscription">
            {{ compute.contractStatus.label }}
            <span v-if="compute.contractStatus.status === 'pending'" class="contractInfoIcon wb-icons">
              info_filled
            </span>
          </div>
        </template>
        <template v-if="compute.contractStatus.details" #tooltip>
          <p
            v-for="(detail, index) in compute.contractStatus.details"
            :key="index"
            class="contract-detail"
          >
            {{ detail.label }}
            <span
              class="item wb-icons"
              data-test-id="chargerTypeIcon"
            >
              {{ detail.status ? 'check' : 'close' }}
            </span>
          </p>
        </template>
      </wb-dropdown>
    </wb-table-row-item>

    <wb-table-row-item class="has-text-centered">
      <wb-badge
        variant="green"
        data-test-id="userActivationStatus"
      >
        {{ i18n.t('mywb.common.active') }}
      </wb-badge>
    </wb-table-row-item>

    <wb-table-row-item @click.stop>
      <wb-dropdown
        v-if="props.hasPermisionToActions"
        data-test-id="userActionsDropdown"
      >
        <template #activator>
          <wb-button
            data-test-id="userRemove"
            variant="danger"
            size="small"
            icon="delete"
            outlined
            @click="methods.handleRemove"
          />
        </template>
        <template #tooltip>
          {{ i18n.t('mywb.common.delete') }}
        </template>
      </wb-dropdown>
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import { getContractStatus } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { UserList } from '@/core/user'
import UserRoleItem from '@/components/users/UserRoleItem.vue'

const i18n = useI18n()

interface Props {
  user: UserList
  hasPermisionToActions?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-click'): void,
  (e: 'on-remove'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  contractStatus: computed(() => getContractStatus(props.user)),

  userName: computed(() => {
    return props.user?.name
      ? `${props.user.name} ${props.user.surname}`
      : ''
  })
})

const methods = {
  handleRemove () {
    emit('on-remove')
  }
}
</script>

<style lang="postcss" scoped>
.contract-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.contractInfoIcon {
  font-size: 14px !important;
  position: relative;
  top: 3px;
}
</style>
