import type { LocationRepository } from '@/core/location'

export const getLocationByGroup = (repository: LocationRepository) => {
  return async (organizationUid: string, groupUid: string) => {
    const filters = [
      {
        field: 'group_uid',
        operator: 'eq',
        value: groupUid
      }
    ]
    const locations = await repository.getAllLocations(organizationUid, {
      cache: 'network',
      getChargers: false,
      filters
    })

    return locations[0]
  }
}
