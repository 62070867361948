import { permissions } from '@/engine/clients'
import i18n from '@/engine/lang'
import type { CalendarType, PeriodicityOption } from '@/utilities/chartSessions/chart.types'
import {
  PeriodicityEnum,
  IntervalEnum,
  ChartTypesEnum,
  AnalysisEnum,
  VisualizationEnum
} from '@/utilities/chartSessions/chartTypeEnums'
import {
  getIntervalDateTime
} from '@/utilities/chartSessions/dateIntervals'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'

export const chartTypes = {
  energy: {
    colors: ['#009B86'],
    label: i18n.global.t('mywb.common.energy-supplied')
  },
  time: {
    colors: ['#009B86'],
    label: i18n.global.t('mywb.charger.charging-time')
  },
  sessions: {
    colors: ['#009B86'],
    label: i18n.global.t('mywb.common.sessions')
  },
  cost: {
    colors: ['#009B86'],
    label: i18n.global.t('mywb.common.energy-cost')
  },
  income: {
    colors: ['#009B86', '#1E437B'],
    label: i18n.global.t('mywb.common.income')
  }
}

export const calendarOptions: CalendarType[] = [
  {
    id: 'interval_0',
    label: i18n.global.t('mywb.common.today'),
    interval: { value: 1, type: IntervalEnum.DAYS },
    ...getIntervalDateTime(new Date(), { value: 1, type: IntervalEnum.DAYS })
  },
  {
    id: 'interval_1',
    label: i18n.global.t('mywb.common.n-days', { num: 7 }),
    interval: { value: 7, type: IntervalEnum.DAYS },
    ...getIntervalDateTime(new Date(), { value: 12, type: IntervalEnum.DAYS })
  },
  {
    id: 'interval_2',
    label: i18n.global.t('mywb.common.n-month', { num: 1 }),
    interval: { value: 1, type: IntervalEnum.MONTHS },
    ...getIntervalDateTime(new Date(), { value: 1, type: IntervalEnum.MONTHS })
  },
  {
    id: 'interval_3',
    label: i18n.global.t('mywb.common.n-month', { num: 3 }),
    interval: { value: 3, type: IntervalEnum.MONTHS },
    ...getIntervalDateTime(new Date(), { value: 3, type: IntervalEnum.MONTHS })
  },
  {
    id: 'interval_4',
    label: i18n.global.t('mywb.common.n-month', { num: 6 }),
    interval: { value: 6, type: IntervalEnum.MONTHS },
    ...getIntervalDateTime(new Date(), { value: 6, type: IntervalEnum.MONTHS })
  },
  {
    id: 'interval_5',
    label: i18n.global.t('mywb.common.n-month', { num: 12 }),
    interval: { value: 12, type: IntervalEnum.MONTHS },
    ...getIntervalDateTime(new Date(), { value: 12, type: IntervalEnum.MONTHS })
  }
]

export const periodicityOptions: PeriodicityOption[] = [
  {
    label: i18n.global.t('mywb.common.day'),
    value: PeriodicityEnum.DAY
  },
  {
    label: i18n.global.t('mywb.common.week'),
    value: PeriodicityEnum.WEEK
  },
  {
    label: i18n.global.t('mywb.common.month'),
    value: PeriodicityEnum.MONTH
  }
]

export const analysisOptions: Array<{ label: string, value: AnalysisEnum, icon: string }> = [
  {
    label: i18n.global.t('mywb.common.linear'),
    value: AnalysisEnum.LINEAR,
    icon: 'chart_linear'
  },
  {
    label: i18n.global.t('mywb.common.cumulative'),
    value: AnalysisEnum.CUMULATIVE,
    icon: 'chart_cumulative'
  }
]

export const categoriesOptions = (): Array<{ label: string, value: ChartTypesEnum }> => {
  return [
    {
      label: chartTypes.energy.label,
      value: ChartTypesEnum.ENERGY
    },
    {
      label: chartTypes.time.label,
      value: ChartTypesEnum.TIME
    },
    {
      label: chartTypes.sessions.label,
      value: ChartTypesEnum.SESSIONS
    },
    {
      label: chartTypes.cost.label,
      value: ChartTypesEnum.COST
    },
    permissions.showPayments && {
      label: chartTypes.income.label,
      value: ChartTypesEnum.INCOME
    }
  ].filter(Boolean as unknown as ExcludesNullish)
}

export const visualizationOptions: Array<{ label: string, value: VisualizationEnum, icon: string }> = [
  {
    label: i18n.global.t('mywb.common.line'),
    value: VisualizationEnum.AREA,
    icon: 'chart_line'
  },
  {
    label: i18n.global.t('mywb.common.bar'),
    value: VisualizationEnum.BAR,
    icon: 'chart_bar'
  }
]
