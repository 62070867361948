<template>
  <wb-modal
    width="45"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.organizations-edit-organization') }}
    </template>

    <wb-input
      v-model="field.name"
      data-test-id="inputName"
      name="name"
      type="text"
      :label="i18n.t('mywb.common.name')"
      :error="errors.name"
    />

    <organization-type-select
      v-model="field.organizationType"
      data-test-id="selectInputType"
      :error="errors.organizationType"
    />

    <wb-alert
      v-if="props.showNotification"
      variant="info"
      icon="info"
      class="mt-16"
    >
      <p v-t="'mywb.organizations-type.why'" />
    </wb-alert>

    <currency-select-form
      v-if="!compute.hasCountryPaymentsSupport"
      v-model="field.currency"
      :reduce="value => value.code"
      class="mt-16"
    />

    <template #actions>
      <wb-button
        class="has-margin-auto"
        data-test-id="acceptBtnModal"
        size="block"
        :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.loading"
        @click="validate(methods.updateOrganization)"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import OrganizationTypeSelect from '@/components/forms/OrganizationTypeSelect.vue'
import CurrencySelectForm from '@/components/forms/CurrencySelectForm.vue'
import { HttpError } from '@wallbox/http'
import type { OrganizationTypeEnum } from '@/utilities/organizationTypes'
import { hasCountryPaymentsSupport } from '@/engine/clients/validators'
import { ensureEditOrganizationIsValid, type Organization, type OrganizationEdit } from '@/core/organization'
import { injectStrict, ORGANIZATION_USE_CASES } from '@/engine/injectors'

const organizationUsesCases = injectStrict(ORGANIZATION_USE_CASES)

const toast = useToast()
const i18n = useI18n()
const { errors, validateTo, validate } = useValidator()

interface Events {
  (e: 'on-update', payload: { name: string, type: OrganizationTypeEnum}): void,
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const props = defineProps<{
  organization: Organization
  showNotification?: boolean
}>()

interface DataType {
  loading: boolean
  organizations: Organization[]
}

const data: DataType = reactive({
  loading: false,
  organizations: []
})

const field = reactive<OrganizationEdit>({
  name: props.organization?.name || '',
  organizationType: props.organization.organizationType || '',
  groupUid: props.organization.groupUid,
  currency: props.organization.currencyCode
})

validateTo(async () => !!await ensureEditOrganizationIsValid(field))

const compute = reactive({
  hasCountryPaymentsSupport: computed(hasCountryPaymentsSupport())
})

const methods = {
  async updateOrganization () {
    if (!field.organizationType || !field.name) return
    data.loading = true

    try {
      await organizationUsesCases.editOrganization({
        groupUid: field.groupUid ?? '-1',
        name: field.name,
        organizationType: field.organizationType,
        currency: !compute.hasCountryPaymentsSupport ? field.currency : undefined
      })

      trackDataAction('organizations-edited', { ...field })

      toast.success(i18n.t('mywb.common.changes-saved'))
      emit('on-update', { name: field.name, type: field.organizationType })
      emit('on-close')
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }
    } finally {
      data.loading = false
      emit('on-close')
    }
  }
}

async function created () {
  trackDataScreen('organizations-edit-organization')
}

created()
</script>
