<template>
  <section>
    <wb-input
      v-model="data.searchTerm"
      data-test-id="searchButton"
      :placeholder="props.selectedBrand
        ? t('mywb.charge-point.search-model-placeholder')
        : t('mywb.charge-point.search-brand-placeholder')"
      icon="search"
      type="search"
      class="search-input"
    />

    <div class="brand-grid mt-24">
      <wb-card
        v-for="element in compute.dataSetToShow"
        :key="element.slug"
        class="brand"
        :class="{ active: props.modelValue?.name === element.name }"
        @click="methods.onClick($event, element)"
      >
        <template #content>
          <img
            v-if="element.image"
            class="img"
            :alt="element.slug"
            :src="element.image"
          >

          {{ element.name }}
        </template>
      </wb-card>

      <i18n-t
        v-if="compute.dataSetToShow.length === 0"
        :keypath="props.selectedBrand
          ? 'mywb.charge-point.search-model-empty'
          : 'mywb.charge-point.search-brand-empty'"
        tag="p"
        class="is-size-400 empty-desc"
      >
        <wb-link
          href=""
          target="_blank"
        >
          {{ t('mywb.common.contact-us') }}
        </wb-link>
      </i18n-t>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Brand, Model } from '@/core/chargePoint'
import { useI18n } from '@/hooks/useI18n.hook'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { computed, reactive } from 'vue'

const { t } = useI18n()

interface Props {
  brands: Brand[]
  modelValue?: Brand | Model
  selectedBrand?: Brand
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', Brand: Brand | Model): void
  (e: 'click', Brand: Brand | Model): void
}
const emit = defineEmits<Emits>()

interface Data {
  searchTerm?: string,
}

const data = reactive<Data>({
  searchTerm: undefined
})

const compute = reactive({
  dataSetToShow: computed((): Model[] | Brand[] => {
    return props.selectedBrand ? compute.modelsFiltered : compute.brandsFiltered
  }),

  modelsFiltered: computed((): Model[] => {
    if (!props.selectedBrand) return []

    return props.selectedBrand.models.filter(model => {
      if (!model.active) return false

      if (data.searchTerm) {
        return model.name.toLocaleLowerCase().includes(data.searchTerm) ||
          model.slug.toLocaleLowerCase().includes(data.searchTerm)
      }

      return model
    })
  }),

  brandsFiltered: computed((): Brand[] => {
    const brands = props.brands.filter(brand => {
      if (!brand.active) return false

      if (data.searchTerm) {
        return brand.name.toLocaleLowerCase().includes(data.searchTerm) ||
          brand.slug.toLocaleLowerCase().includes(data.searchTerm)
      }

      return brand
    })

    return [
      brands.find(brand => brand.slug === 'wallbox') as Brand,
      ...brands.filter(brand => brand.slug !== 'wallbox')
    ].filter(Boolean as unknown as ExcludesNullish)
  })
})

const methods = {
  async onClick ($event: Event, element: Brand | Model) {
    const htmlElement = $event.target as HTMLElement
    htmlElement.style.viewTransitionName = 'push-left'

    emit('update:modelValue', element)
    emit('click', element)
  }
}
</script>

<style lang="postcss" scoped>
.brand-grid {
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.img {
  width: 40px;
  margin: 0 auto;
}

.brand {
  aspect-ratio: 1 / 1;
  display: flex;
  place-content: center;
  place-items: center;
  transition: all 100ms ease-in;
  cursor: pointer;
  text-align: center;

  &.active,
  &:hover {
    background: #232931 !important;
    color: white;

    & .img {
      filter: brightness(0) invert(1);
    }
  }
}

.empty-desc {
  grid-column: 1 / -1;
}

</style>

<style lang="postcss">
@keyframes fade-in {
  from { opacity: 0; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes slide-from-right {
  from { transform: translateX(300px); }
}

@keyframes slide-to-left {
  to { transform: translateX(-300px); }
}

::view-transition-old(push-left) {
  animation: 200ms ease-out both fade-out,
     300ms ease-out both slide-to-left ;
}

::view-transition-new(push-left) {
  animation: 200ms ease-out both fade-in,
     300ms ease-out both slide-from-right;
}
</style>
