<template>
  <wb-card
    :headline="i18n.t('mywb.common.timezone')"
    :subhead="compute.timezoneIdSubhead"
    :loading="props.loading"
    alignment="center"
    data-test-id="chargerEditTimezoneCard"
  >
    <template #modal>
      <charger-edit-timezone-modal
        v-if="data.showModal"
        data-test-id="editTimezoneModal"
        @close="data.showModal = false"
      />
    </template>

    <template #actions>
      <wb-button
        icon="edit"
        :label="i18n.t('mywb.common.edit')"
        variant="white"
        outlined
        :disabled="props.loading"
        data-test-id="editTimezoneButton"
        @click="data.showModal = true"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { computed, reactive } from 'vue'
import ChargerEditTimezoneModal from '@/components/modals/ChargerEditTimezoneModal.vue'

const i18n = useI18n()

interface PropsType {
  loading?: boolean
}

interface Data {
  showModal: boolean
}

const props = defineProps<PropsType>()

const data: Data = reactive({
  showModal: false
})

const compute = reactive({
  timezoneIdSubhead: computed(() => state.charger.getChargerCore.timezone
    ? state.charger.getChargerCore.timezone.replaceAll('_', ' ')
    : '-')
})

</script>
