<template>
  <wb-card
    :headline="i18n.t('mywb.common.password')"
    :subhead="i18n.t('mywb.account.password-description')"
    data-test-id="securityInformation"
  >
    <template #actions>
      <wb-button
        variant="white"
        outlined
        :label="i18n.t('mywb.common.edit')"
        icon="edit"
        class="edit"
        data-test-id="editBtn"
        @click="data.editPassword = true"
      />
    </template>

    <template #modal>
      <wb-modal
        v-if="data.editPassword"
        width="50"
        @close="methods.onClose()"
      >
        <template #title>
          {{ i18n.t('mywb.account.password-description') }}
        </template>

        <wb-form>
          <wb-input
            v-model="form.currentPassword"
            type="password"
            name="oldPassword"
            :label="i18n.t('mywb.user.current-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="errors.currentPassword || data.errorApiOldPassword"
            data-test-id="inputPassword"
            @keyup="data.errorApiOldPassword = ''"
          />

          <wb-input
            v-model="form.password"
            type="password"
            name="newPassword"
            :label="i18n.t('mywb.user.new-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="errors.password"
            data-test-id="inputNewPassword"
          />

          <wb-input
            v-model="field.passwordConfirm"
            type="password"
            name="confirmNewPassword"
            :label="i18n.t('mywb.user.confirm-password')"
            :placeholder="i18n.t('mywb.user.enter-password')"
            :error="confirmErrors.passwordConfirm"
            data-test-id="inputNewPasswordConfirm"
          />

          <auth-password-hint :value="form.password || ''" />
        </wb-form>

        <template #actions>
          <div class="button-actions">
            <wb-button
              data-test-id="cancelButton"
              variant="white"
              outlined
              :label="i18n.t('mywb.common.cancel')"
              size="block"
              @click="methods.onClose()"
            />
            <wb-button
              variant="primary"
              size="block"
              :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
              :loading="data.loading"
              data-test-id="buttonConfirm"
              @click.prevent="validate(methods.updatePassword)"
            />
          </div>
        </template>
      </wb-modal>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import { reactive } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { HttpError } from '@wallbox/http'
import { type PasswordUpdate, ensurePasswordUpdateIsValid } from '@/core/auth'
import state from '@/state'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'

const authUseCases = injectStrict(AUTH_USE_CASES)
const i18n = useI18n()
const toast = useToast()
const { errors, validate, validateTo } = useValidator()
const {
  yup,
  errors: confirmErrors,
  validate: validateConfirm,
  defineSchema
} = useValidator()

const data = reactive({
  loading: false,
  editPassword: false,
  errorApiOldPassword: ''
})

const field = reactive({
  passwordConfirm: ''
})

const form = reactive<PasswordUpdate>({
  userId: state.global.getUserId,
  currentPassword: '',
  password: ''
})

defineSchema(field, {
  passwordConfirm: yup
    .string()
    .test(
      'confirm-password',
      i18n.t('mywb.error.passwords-must-match'),
      (value) => value === form.password
    )
    .required()
})

validateTo(async () => {
  const confirmIsValid = !!await validateConfirm()
  const domainIsValid = !!await ensurePasswordUpdateIsValid(form)

  return domainIsValid && confirmIsValid
})

const methods = {
  trackDataScreen,

  onClose () {
    data.editPassword = false
    methods.resetForm()
  },

  resetForm () {
    data.editPassword = false
    form.currentPassword = ''
    form.password = ''
    field.passwordConfirm = ''
    data.errorApiOldPassword = ''
  },

  async updatePassword () {
    data.loading = true
    data.errorApiOldPassword = ''
    try {
      await authUseCases.updatePassword(form)

      methods.resetForm()
      toast.success(i18n.t('mywb.user.changed-password'))
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 'forbidden') {
          data.errorApiOldPassword = i18n.t('mywb.error.invalid-password')
        }
        toast.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }
    }
    data.loading = false
  }
}
</script>
