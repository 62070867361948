import { object, string, type InferType } from 'yup'

const passwordResetSchema = object({
  password: string().required(),
  token: string().required()
})

export type PasswordReset = InferType<typeof passwordResetSchema>

export async function ensurePasswordResetIsValid (passwordReset: any) {
  return await passwordResetSchema.validate(passwordReset, { abortEarly: false })
}
