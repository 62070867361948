import { number, boolean, array, object, type InferType, string } from 'yup'

const addChargersToUserSchema = object({
  userId: number().required(),
  groupUid: string().required(),
  chargers: array().of(number().required()).required(),
  hasAccessConfig: boolean().required()
})

export type AddChargersToUser = InferType<typeof addChargersToUserSchema>

export async function ensureAddChargersToUserSchemaIsValid (payload: any) {
  return await addChargersToUserSchema.validate(payload, { abortEarly: false })
}
