import type { InternationalRepository } from '@/core/international'
import type { UseCase } from '@/core/shared'

export const getAllCountries: UseCase<InternationalRepository, 'getCountries'> = repository => {
  return async () => {
    const chargers = await repository.getCountries()

    return chargers
  }
}
