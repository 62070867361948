import type { CurrencyCodes } from '@/core/international'
import limitsAndRules from '@/utilities/prices/limitsAndRules.json'

export function getMinimumChargeAmount (currencyCode: CurrencyCodes) {
  return limitsAndRules[currencyCode as keyof typeof limitsAndRules]?.minimum_charge_amount
}

export function getPriceAmountUpperLimit (currencyCode: CurrencyCodes) {
  return limitsAndRules[currencyCode as keyof typeof limitsAndRules]?.rate_amount_upper_limit ?? 9999.99
}
