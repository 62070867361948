<template>
  <div
    :class="[
      'widget-icon',
      'is-font-weight-500',
      { with_bullet: widget.bullet }
    ]"
    :data-test-id="widget.dataTestId"
  >
    <slot>
      <span class="wb-icons icon">{{ widget.icon }}</span>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export interface Props {
  widget: {
    show: boolean
    name: string
    icon?: string
    title: string
    subtitle: any
    bullet: boolean
    bulletInside: boolean
    bulletStatus?: 'SUCCESS' |'INFO' | 'WARNING' | 'ERROR'
    dataTestId: string
  }
}

const props = defineProps<Props>()

const color = computed(() => {
  return {
    SUCCESS: 'var(--success-700)',
    INFO: 'var(--blue-300)',
    WARNING: 'var(--warning-500)',
    ERROR: 'var(--danger-700)'
  }[props.widget.bulletStatus ?? 'SUCCESS']
})
</script>

<style lang="postcss" scoped>
.widget-icon {
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  padding: 3px;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  margin: 2px;
  border: 1px solid var(--grey-100);
  cursor: pointer;
  background-color: var(--grey-50);
  color: var(--grey-700);

  & .icon {
    font-size: 18px !important;
  }

  &.with_bullet::before {
    content: "";
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 10px;
    height: 10px;
    border: 2px solid white;
    border-radius: 50%;
    background: v-bind(color);
  }
}
</style>
