<template>
  <wb-filter
    v-model="compute.model"
    :clear-text="i18n.t('mywb.common.clear-all')"
    :apply-text="i18n.t('mywb.common.apply')"
    :all-text="i18n.t('mywb.common.all')"
    :options="props.options"
    :name="props.name"
    v-bind="attrs"
  >
    <template v-if="$slots['input-text']" #input-text="{ option, label }">
      <slot name="input-text" :option="option" :label="label" />
    </template>

    <template v-if="$slots.option" #option="{ option }">
      <slot name="option" :option="option" />
    </template>
  </wb-filter>
</template>

<script setup lang="ts">
import { computed, reactive, useAttrs } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

export type ModelValueSingleType = Record<string, unknown> | number | string | boolean
export type FilterProps = {
  name?: string,
  modelValue?: ModelValueSingleType[] | ModelValueSingleType | null
  options: Record<string, any>[]
}

const i18n = useI18n()
const attrs = useAttrs()

const props = withDefaults(defineProps<FilterProps>(), {
  name: '',
  modelValue: null
})

const emit = defineEmits<{(
  e: 'update:modelValue', value: FilterProps['modelValue']): void
}>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },

    set (value) {
      emit('update:modelValue', value)
    }
  })
})
</script>
