<template>
  <div>
    <template v-if="mq.current === 'desktop'">
      <div v-if="props.rowIndex === 0" class="is-grid-row g-12 mt-24">
        <p class="is-size-400">
          {{ i18n.t('mywb.common.email') }}
        </p>
        <p v-if="permissions.canAddRfidInInvitations" class="is-size-400 mb-8">
          {{ i18n.t('mywb.common.rfid') }} ({{ i18n.t('mywb.common.optional') }})
        </p>
      </div>
    </template>

    <div
      class="is-grid-row g-12 mb-12"
      :data-test-id="`invitation-row-${props.rowIndex}`"
    >
      <wb-input
        :model-value="row.email.value"
        icon="email"
        data-test-id="invitationUserEmail"
        :label="mq.current !== 'desktop' ? i18n.t('mywb.common.email') : ''"
        :error="row.email.error?.text"
        :disabled="props.loading"
        :placeholder="i18n.t('mywb.common.add-email')"
        @update:model-value="methods.updateColumn('email', $event)"
      />

      <wb-input
        v-if="permissions.canAddRfidInInvitations"
        :model-value="row.rfid.value"
        data-test-id="invitationUserRfid"
        :label="mq.current !== 'desktop' ? `${i18n.t('mywb.common.rfid')} (${i18n.t('mywb.common.optional')})` : ''"
        :error="row.rfid.error?.text"
        :disabled="props.loading"
        :placeholder="i18n.t('mywb.common.add-rfid-code')"
        @update:model-value="methods.updateColumn('rfid', $event)"
      />

      <div class="is-grid-row__delete-button">
        <wb-button
          v-if="(props.rowIndex !== 0 && props.rowTotal === 1) || props.rowTotal > 1"
          :loading="props.loading"
          data-test-id="invitation-row-delete"
          variant="white"
          icon="delete"
          outlined
          @click="$emit('delete-row', props.rowIndex)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import { toRaw } from 'vue'
import type { UserInvitation } from '@/types/data/accessConfig.data.types'

const i18n = useI18n()
const mq = useMq()

interface Props {
  row: UserInvitation
  rowIndex: number
  rowTotal: number
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  rowIndex: 0,
  rowTotal: 0,
  loading: false
})

interface Events {
  (e: 'update:row', row: UserInvitation): void,
  (e: 'delete-row', index: number): void
}

const emit = defineEmits<Events>()

const methods = {
  updateColumn (property: 'email' | 'rfid', value: string | number) {
    const row = structuredClone(toRaw(props.row)) as Props['row']
    row[property].value = value.toString()
    emit('update:row', row)
  }
}
</script>

<style lang="postcss" scoped>
.is-grid-row {
  display: grid;
  grid-template-columns: 1fr;
  align-items: top;

  @media (--tablet) {
    grid-template-columns: 300px 300px auto;
  }
}

.is-grid-row__delete-button {
  display: flex;
  place-items: top;
  margin-top: 0.5rem;
}
</style>
