import { object, string, number, array, type InferType } from 'yup'
import type { CountryIso2 } from '@/core/international'

const locationSchema = object({
  id: string().required(),
  name: string().optional(),
  energyType: string().optional(),
  address: string().optional(),
  locationTypes: array().of(string().required()).optional(),
  instructions: string().optional(),
  city: string().optional(),
  state: string().optional(),
  zipCode: string().optional(),
  country: string<CountryIso2>().optional(),
  latitude: number().optional(),
  longitude: number().optional(),
  energyCost: number().optional()
})

export type LocationEdit = InferType<typeof locationSchema>
