<template>
  <div>
    <input
      :id="props.value"
      v-model="compute.model"
      data-test-id="input"
      :value="props.value"
      class="input"
      type="radio"
    >
    <label
      class="label"
      :class="{ error: props.error, disabled: props.disabled }"
      :for="props.value"
    >
      <span class="wb-icons" data-test-id="icon">{{ props.icon }}</span>
      <p class="title" data-test-id="title">{{ props.title }}</p>
      <p v-if="props.subtitle" class="subtitle" data-test-id="subtitle">{{ props.subtitle }}</p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

interface PropsType {
  modelValue?: string
  value: string
  icon?: string
  title: string
  subtitle?: string
  error?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<PropsType>(), {
  modelValue: undefined,
  icon: undefined,
  subtitle: undefined,
  error: undefined
})

interface Events {
  (e: 'update:modelValue', param?: string): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  model: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})
</script>

<style lang="postcss" scoped>
.label {
  position: relative;
  border: 1px solid var(--grey-300);
  padding: 24px 16px;
  cursor: pointer;
  border-radius: 0.4rem;
  height: 100%;
  display: grid;
  grid-template-rows: 24px auto;

  &::after {
    content: "";
    position: absolute;
    font-family: "Wallbox Icons", sans-serif;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid var(--grey-300);
    color: var(--white);
    display: flex;
    place-items: center;
    place-content: center;
  }

  & .title {
    font-size: var(--size-400);
    font-weight: 500;
    grid-row: 2;
  }

  & .subtitle {
    font-size: var(--size-300);
    color: var(--grey-500);
    grid-row: 3;
  }

  &.error {
    border-color: var(--danger-500);
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.input {
  display: none;

  &:checked + .label {
    border-color: var(--primary-500);
    background: var(--primary-200);

    &::after {
      content: "check";
      background: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
}
</style>
