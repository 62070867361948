<template>
  <dashboard-card
    title="mywb.common.users"
    subtitle="mywb.common.manage-users"
    icon="person"
    :number="compute.totalUsers"
    :loading="props.loading || data.loading"
    data-test-id="dashboardCard"
  >
    <template #dashboard-actions>
      <wb-button
        v-if="permissions.canAddUser"
        data-test-id="addUsersBtn"
        :label="t('mywb.common.add-user')"
        outlined
        :to="{
          name: ROUTES.USERS,
          query: {
            'add-users': '1'
          }
        }"
        variant="white"
        size="small"
        icon="add"
      />

      <wb-button
        data-test-id="viewAllSettings"
        :to="{ name: ROUTES.USERS }"
        :label="t('mywb.common.view-all')"
        variant="white"
        icon="chevron_right"
        icon-position="right"
        size="small"
      />
    </template>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'
import { numbers } from '@wallbox/toolkit-ui'
import { permissions } from '@/engine/clients'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { UserList } from '@/core/user'
import type { AccessConfig } from '@/core/accessConfig'
import ROUTES from '@/engine/router/routes'

const userUseCases = injectStrict(USER_USE_CASES)

const { t, locale } = useI18n()

interface Props {
  loading: boolean,
}

const props = defineProps<Props>()

interface Data {
  loading: boolean
  users: UserList[]
  accessConfig: AccessConfig[]
}

const data = reactive<Data>({
  loading: false,
  users: [],
  accessConfig: []
})

const compute = reactive({
  totalUsers: computed((): string => {
    return numbers.toLocaleNumber(compute.users, locale.value)
  }),

  users: computed(() => {
    if (state.filters.dashboardFilters.users.length > 0) {
      return new Set(state.filters.dashboardFilters.users).size
    }

    let chargers = state.charger.getChargers

    if (state.filters.dashboardFilters.locations.length > 0) {
      chargers = chargers.filter(charger => state.filters
        .dashboardFilters.locations.some(location => location.id === charger.locationId))
    }

    const chargersSet = new Set(chargers.map(charger => charger.id))

    const users = data.users.map(user => {
      if (state.filters.dashboardFilters.locations.length === 0) {
        return user.id
      }

      if (user.accessGroup?.chargers.some(charger => chargersSet.has(charger))) {
        return user.id
      }

      if (user.hasCustomAccess) return user.id

      return null
    }).filter(Boolean)

    const uniqUsers = new Set(users).size

    return uniqUsers
  })
})

async function created () {
  data.loading = true

  data.users = await userUseCases.getAllUsersByOrganization(state.organizations.getCurrentOrganization.groupUid)
  state.user.setUsers(data.users)

  data.loading = false
}

created()
</script>
