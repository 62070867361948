import { object, string, type InferType } from 'yup'
import type { Organization } from '@/core/organization'
import type { CurrencyCodes } from '@/core/international'

const organizationSchema = object({
  name: string().required(),
  groupUid: string().required(),
  organizationType: string<Organization['organizationType']>().required(),
  currency: string<CurrencyCodes>().optional()
})

export type OrganizationEdit = InferType<typeof organizationSchema>

export async function ensureEditOrganizationIsValid (organization: any) {
  return await organizationSchema.validate(organization, { abortEarly: false })
}
