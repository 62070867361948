<template>
  <div>
    <div
      id="animation"
      class="anim"
    />

    <div class="has-center-content is-size-1000">
      {{ chargers.length }} {{ i18n.t('mywb.add-chargers.chargers-added') }}
    </div>

    <div class="is-size-600 mt-32 mb-24">
      {{ i18n.t('mywb.common.do-you-want-configure-payemnts') }}
    </div>

    <wb-radio-card
      v-model="data.optionSelected"
      value="payments"
      class="mb-16"
      :error="data.error"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="userTypeUser">
        {{ i18n.t('mywb.common.yes-activate-payments') }}
      </p>
    </wb-radio-card>

    <wb-radio-card
      v-model="data.optionSelected"
      value="withoutPayments"
      :error="data.error"
    >
      <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="userTypeAdmin">
        {{ i18n.t('mywb.common.no-activate-payments') }}
      </p>
    </wb-radio-card>
  </div>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import state from '@/state'
import { useRouter } from 'vue-router'
import { reactive, computed, onMounted, onUnmounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import lottie from 'lottie-web'
import lottieInfo from '@/assets/animations/wbx_illust_charger_added.json'
import { provideChargersUsesCases, type Charger as ChargerCore, type ChargerToAdd } from '@/core/charger'
import ROUTES from '@/engine/router/routes'

const router = useRouter()
const i18n = useI18n()
const chargerUsesCases = provideChargersUsesCases()

const emit = defineEmits(['on-configure-payments', 'on-continue-without-payments'])

interface Props {
  chargers: ChargerToAdd[]
}

const props = defineProps<Props>()

const data = reactive({
  optionSelected: undefined,
  error: false
})

const compute = reactive({
  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.paymentsAccount)
})

const methods = {
  async configurePayments () {
    if (permissions.showPayments) {
      if (compute.hasOrganizationPaymentsAccount) {
        let chargers = await chargerUsesCases.getAllChargers(state.organizations.getCurrentOrganization.groupUid, {
          cache: 'network'
        })

        chargers = chargers.reduce<ChargerCore[]>((chargers, current) => {
          if (props.chargers.some(chargerAdded => chargerAdded.serial.value === current.serialNumber)) {
            chargers.push(current)
          }

          return chargers
        }, [])

        chargers.forEach(charger => state.charger.setCheckCharger({ ...charger }, true))

        emit('on-configure-payments')
      } else {
        router.push({ name: ROUTES.PAYMENTS_RATES })
      }
    } else {
      router.push({ name: ROUTES.PLAN_BUSINESS })
    }
  },

  async isValidateForm () {
    data.error = !data.optionSelected
    if (data.error) return false

    if (data.optionSelected === 'payments') {
      await methods.configurePayments()
    } else {
      emit('on-continue-without-payments')
    }
    return true
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })

onMounted(() => {
  const svgContainer = document.getElementById('animation')

  svgContainer && lottie.loadAnimation({
    container: svgContainer,
    renderer: 'svg',
    loop: false,
    animationData: lottieInfo
  })
})

onUnmounted(() => {
  lottie.destroy()
})
</script>

<style lang="postcss" scoped>
.anim {
  width: 28rem;
  margin: 0 auto;
}

.has-center-content {
  padding-bottom: 6rem;
  text-align: center;
}
</style>
