<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.invite-user.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.invite-user.description') }}
    </div>

    <div v-if="permissions.hasBulkActions" class="mb-32">
      <div class="label">
        {{ i18n.t('mywb.common.import-users-using-csv-file') }}
        <wb-link
          target="_blank"
          :href="compute.linkToDownloadCsvTemplate"
        >
          {{ i18n.t('mywb.common.download-csv-template') }}
        </wb-link>
      </div>
      <wb-file-uploader
        v-model="data.csvFile"
        v-model:errors="data.filesErrors"
        :title-label="i18n.t('mywb.common.browse-or-drag-and-drop-files')"
        :description-label="i18n.t('mywb.common.only-accept-csv-file')"
        format="file"
        accept="
          text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :error="data.csvFilesError"
        :show-previews="false"
      />
    </div>

    <add-users-invitations-form
      v-for="(user, index) in data.users"
      :key="index"
      v-model:row="data.users[index]"
      :row-index="index"
      :row-total="data.users.length || 0"
      :loading="data.loading"
      @delete-row="methods.deleteRow"
      @update-column="emit('update:modelValue', data.users)"
    />

    <wb-button
      v-if="permissions.hasBulkActions"
      :label="i18n.t('mywb.common.add-one-more-user')"
      data-test-id="addInvitationRow"
      inverted
      variant="primary"
      icon="add"
      class="mt-16"
      @click="methods.addRow"
    />
  </div>
</template>

<script setup lang="ts">
import AddUsersInvitationsForm from '@/components/forms/AddUsersInvitationsForm.vue'

import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, onMounted, watch, computed } from 'vue'
import { permissions } from '@/engine/clients'
import type { UserInvitation } from '@/types/data/accessConfig.data.types'
import { csvToArray } from '@/utilities/csv'
import state from '@/state'
import { injectStrict, USER_USE_CASES } from '@/engine/injectors'
import { useValidator } from '@/hooks/useValidator.hook'

const { yup } = useValidator()
const i18n = useI18n()
const userUsesCases = injectStrict(USER_USE_CASES)

interface Events {
  (e: 'update:modelValue', params: UserInvitation[]): void
}
const emit = defineEmits<Events>()

interface Props {
  modelValue?: UserInvitation[]
}
const props = defineProps<Props>()

interface Data {
  users: UserInvitation[],
  csvFile: File[],
  filesErrors: Array<{ code: string }>,
  csvFilesError: string
  loading: boolean
  existingEmails: string[]
}

const data = reactive<Data>({
  users: [
    {
      email: {
        value: ''
      },
      rfid: {
        value: ''
      }
    }
  ],
  csvFile: [],
  filesErrors: [],
  csvFilesError: '',
  loading: false,
  existingEmails: []
})

watch(() => data.csvFile, (csvFile) => {
  if (csvFile[0]) {
    data.csvFilesError = ''
    const reader = new FileReader()

    reader.onloadend = e => {
      const csvParsed = csvToArray(e.target?.result as string)
      methods.loadUserInvitations(csvParsed)
    }

    reader.onerror = function () {
      data.csvFilesError = i18n.t('mywb.error.unexpected-error')
    }

    reader.readAsText(csvFile[0])
  }
}, { deep: true })

watch(() => data.filesErrors, (error, oldError) => {
  if (JSON.stringify(error[0]) !== JSON.stringify(oldError[0])) {
    if (error[0]?.code === 'type-format-error') data.csvFilesError = i18n.t('mywb.errors.files-not-accepted-format')
  }
}, { deep: true })

const compute = reactive({
  linkToDownloadCsvTemplate: computed(() => permissions.canAddRfidInInvitations
    ? 'files/template_invite_users_rfid.csv'
    : 'files/template_invite_users.csv'
  )
})

const methods = {
  scrollToBottom () {
    const objDiv = document.getElementsByClassName('modal-content')
    objDiv[0].scrollTo({
      top: objDiv[0].scrollHeight,
      behavior: 'smooth'
    })
  },

  addRow () {
    const templateRow = {
      email: {
        value: ''
      },
      rfid: {
        value: ''
      }
    }
    data.users.push(templateRow)

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)
  },

  deleteRow (index: number) {
    data.users.splice(index, 1)
  },

  async setEmailErrors (users: UserInvitation[]) {
    await Promise.all(users.map(async (user, index) => {
      try {
        if (index === 0) {
          await yup.string().required().email().validate(user.email.value)
        } else {
          await yup.string().email().validate(user.email.value)
        }
        users[index].email.error = undefined
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          users[index].email.error = {
            code: 'invalidEmail',
            text: error.errors[0]
          }
        }
      }

      if (data.existingEmails.includes(user.email.value)) {
        users[index].email.error = {
          code: 'duplicateEmail',
          text: i18n.t('mywb.error.duplicate-email')
        }
      }
    }))
    return users
  },

  async setRfidErrors (users: UserInvitation[]) {
    await Promise.all(users.map(async (user, index) => {
      try {
        users[index].rfid.error = undefined
        if (user.email.value && user.rfid.value) {
          await yup.string().rfidValidFormat().validate(user.rfid.value)
        }
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          users[index].rfid.error = {
            code: 'invalidRfid',
            text: error.errors[0]
          }
        }
      }
    }))
    return users
  },

  async isValidateForm () {
    data.loading = true
    data.users = await methods.setEmailErrors(data.users)
    if (permissions.canAddRfidInInvitations) data.users = await methods.setRfidErrors(data.users)

    const isValid = !data.users.some(user => user.email.error?.code || user.rfid.error?.code)

    emit('update:modelValue', isValid ? data.users.filter(user => user.email.value !== '') : [])
    data.loading = false

    return isValid
  },

  loadUserInvitations (csvParsed: Record<string, string>[]) {
    data.users = data.users.filter(user => user.email.value !== '')

    csvParsed.forEach(userCsv => {
      if (!data.users.some(user => user.email.value === userCsv.email)) {
        data.users.push({
          email: {
            value: userCsv.email
          },
          rfid: {
            value: (permissions.canAddRfidInInvitations && userCsv.rfid) || ''
          }
        })
      }
    })

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)
  }
}

onMounted(async () => {
  if (props.modelValue?.length) {
    data.users = props.modelValue.filter((user) => user.email)
  }

  data.existingEmails = await userUsesCases.getAllEmails(state.organizations.getCurrentOrganization.groupUid)
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
:deep(.box) {
  margin-bottom: 10px !important;
}

.label {
  display: block;
  color: var(--grey-900);
  font-size: var(--size-400);
  font-weight: 400;
  margin-bottom: 8px;
}
</style>
