export enum EnumRoles {
  user = 3,
  agent = 2,
  admin = 1,
  'super-admin' = 4,
  operator = 5
}

export type RolesName = keyof typeof EnumRoles
export interface Role {
  id: EnumRoles
  name: RolesName
  key: string
  keyDescription: string
}
type Roles = { [key in RolesName]: Role }
type UserRolesNames = { [key in EnumRoles]?: RolesName }
type UserRoles = { [key in RolesName]?: EnumRoles }

const roles: Roles = {
  admin: {
    id: EnumRoles.admin,
    name: 'admin',
    key: 'mywb.common.admin',
    keyDescription: 'mywb.profile.admin.description'
  },
  agent: {
    id: EnumRoles.agent,
    name: 'agent',
    key: 'mywb.common.agent',
    keyDescription: ''
  },
  user: {
    id: EnumRoles.user,
    name: 'user',
    key: 'mywb.common.user',
    keyDescription: 'mywb.profile.user.description'
  },
  'super-admin': {
    id: EnumRoles['super-admin'],
    name: 'super-admin',
    key: 'mywb.common.super-admin',
    keyDescription: ''
  },
  operator: {
    id: EnumRoles.operator,
    name: 'operator',
    key: 'mywb.common.operator',
    keyDescription: 'mywb.profile.operator.description'
  }
}

const userRolesNames: UserRolesNames = {}
Object.keys(roles).forEach(key => {
  const currentRole = roles[key as keyof Roles]
  userRolesNames[currentRole.id] = currentRole.name
})

const userRoles: UserRoles = {}
Object.keys(roles).forEach(key => {
  userRoles[key as RolesName] = roles[key as keyof Roles].id
})

export { userRolesNames }
export default userRoles

export const getRoleById = (id: EnumRoles) => Object.values(roles).find(role => role.id === id) as Role
