import { reactive, computed, toRefs, toRef, type Ref, ref } from 'vue'
import { numbers } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'

import state from '@/state'
import { provideChargersUsesCases, type Charger as ChargerCore } from '@/core/charger'
import type { Rate, ChargerRateWeekSchedule } from '@/core/rate'
import { provideRateUseCases } from '@/core/rate'
import type { ChargerPaymentsCompatible } from '@/core/charger/domain/ChargerPaymentsCompatible'

const rateUseCases = provideRateUseCases()
const chargerUseCases = provideChargersUsesCases()
export interface ChargerWithRates {
  charger: ChargerCore
  paymentsInfo: {
    payPerChargeSchedule?: {
      status?: 'active' | 'frozen'
      rate?: Rate
      isSchedule: boolean
    }
    payPerMonthSchedule?: {
      status?: 'active' | 'frozen'
      rate?: Rate
      isSchedule: boolean
    }
    isPayPerChargeCompatible: ChargerPaymentsCompatible['payPerCharge']
    isPayPerMonthCompatible: ChargerPaymentsCompatible['payPerMonth']
  }
}

export const useAssignedRatesApi = () => {
  interface Data {
    loading: boolean
    rates: Record<string, Rate>
    chargers?: ChargerWithRates[]
    schedules: Record<string, ChargerRateWeekSchedule[]>
  }

  const data = reactive<Data>({
    loading: false,
    chargers: [],
    schedules: {},
    rates: {}
  })

  const methods = {
    getScheduleInfo (charger: ChargerCore, type: 'pay_per_charge' | 'pay_per_month'):
    { status?: 'active' | 'frozen', rate?: Rate, isSchedule: boolean } {
      const schedule = data.schedules[charger.uid]?.find(schedule => schedule.paymentType === type)
      if (!schedule) {
        return {
          status: undefined,
          rate: undefined,
          isSchedule: false
        }
      }

      const rates = new Set<string>(Object.values(schedule.schedule).flat())

      if (rates.size === 1) {
        const rateId = schedule.schedule.monday[0]

        return {
          status: schedule.status,
          isSchedule: false,
          rate: data.rates[rateId]
        }
      } else {
        return {
          status: schedule.status,
          isSchedule: true,
          rate: undefined
        }
      }
    },

    setChargers (chargers: ChargerCore[]) {
      data.chargers = chargers.map((charger) => {
        return {
          charger,
          paymentsInfo: {
            isPayPerChargeCompatible: {
              isCompatible: false
            },
            isPayPerMonthCompatible: {
              isCompatible: false
            }
          }
        }
      })
    },

    async getData (chargers: ChargerCore[]) {
      data.loading = true

      data.chargers = await Promise.all(chargers.map(async (charger) => {
        const info = await chargerUseCases.getIsChargeCompatibleWithPayments(charger)

        return {
          charger,
          paymentsInfo: {
            isPayPerChargeCompatible: info.payPerCharge,
            isPayPerMonthCompatible: info.payPerMonth
          }
        }
      }))

      const apiCalls = data.chargers.map(async (charger) => {
        if (
          (charger.paymentsInfo.isPayPerChargeCompatible.isCompatible ||
          charger.paymentsInfo.isPayPerMonthCompatible.isCompatible) && (
            charger.charger.isPayPerChargeEnabled ||
            charger.charger.isPayPerMonthEnabled
          )
        ) {
          return await rateUseCases.getChargerRatesWeekSchedules(charger.charger.uid)
        }
      }).filter(Boolean)

      try {
        data.schedules = await Promise.all(apiCalls).then(results => results.reduce((acum, result, index) => ({
          ...acum,
          [chargers[index].uid]: result
        }), {}))

        const rates = new Set<string>(Object.values(data.schedules).filter(Boolean)
          .flat().map(schedule => Object.values(schedule.schedule).flat()).flat())

        const apiRatesCalls = [...rates].map(async rateId =>
          await rateUseCases.getRate({ groupUid: state.organizations.getCurrentOrganization.groupUid, rateId }))

        data.rates = await Promise.all(apiRatesCalls).then(results => results.reduce((acum, result) => ({
          ...acum,
          [result?.id ?? '']: result
        }), {}))
      } catch (error) {
        return []
      } finally {
        data.chargers = data.chargers.map(info => {
          return {
            charger: info.charger,
            paymentsInfo: {
              ...info.paymentsInfo,
              payPerChargeSchedule: methods.getScheduleInfo(info.charger, 'pay_per_charge'),
              payPerMonthSchedule: methods.getScheduleInfo(info.charger, 'pay_per_month')
            }
          }
        })

        data.loading = false
      }
    }
  }

  return {
    ...toRefs(data),
    chargersWithRates: toRef(data, 'chargers'),
    getData: methods.getData,
    setChargers: methods.setChargers
  }
}

export const useRateInfoParser = () => {
  const i18n = useI18n()

  let rate: Ref<Rate> | undefined

  function setRate (rateIncoming: Rate) {
    if (!rate) {
      rate = ref(rateIncoming)
    } else {
      rate.value = rateIncoming
    }
  }

  const methods = {
    getLabel (rate?: Rate) {
      if (rate?.variableFeeType === 'energy') {
        return i18n.t('mywb.common.cost-per-kwh')
      } else if (rate?.variableFeeType === 'time') {
        return i18n.t('mywb.common.cost-per-hour')
      }
      return ''
    }
  }

  const fixedFeePrice = computed(() => numbers
    .toLocaleCurrencySymbol(rate?.value?.fixedFee, rate?.value?.currencyCode, i18n.locale.value))
  const variableFeePrice = computed(() => numbers
    .toLocaleCurrencySymbol(rate?.value?.variableFeePrice, rate?.value?.currencyCode, i18n.locale.value))
  const variableLabel = computed(() => {
    return methods.getLabel(rate?.value)
  })

  return {
    setRate,
    info: reactive({
      fixedFeePrice,
      variableFeePrice,
      variableLabel
    })
  }
}
