<template>
  <auth-register-first-step
    v-if="isCurrentState('firstForm')"
    data-test-id="authFirstStep"
    :email-error="data.emailError"
    @continue-register="methods.toSecondStep"
  />

  <auth-register-second-step
    v-else-if="isCurrentState('secondForm') && data.user"
    :user="data.user"
    data-test-id="authSecondStep"
    @finish-register="send('next')"
    @back="methods.toFirstStep"
    @error="methods.onError"
  />

  <auth-register-third-step
    v-else
    data-test-id="authThirdStep"
    :user="data.user"
  />
</template>

<script setup lang="ts">
import AuthRegisterFirstStep from '@/components/auth/AuthRegisterFirstStep.vue'
import AuthRegisterSecondStep from '@/components/auth/AuthRegisterSecondStep.vue'
import AuthRegisterThirdStep from '@/components/auth/AuthRegisterThirdStep.vue'
import { reactive } from 'vue'
import { useStateMachine } from '@wallbox/hooks'
import { trackAction, trackScreen } from '@wallbox/metrics'
import type { Auth } from '@/types'

interface DataType {
  step: number,
  user?: Auth.UserInRegister
  emailError: boolean
}

const data: DataType = reactive({
  step: 0,
  emailError: false
})

const { isCurrentState, send } = useStateMachine({
  initial: 'firstForm',
  states: {
    firstForm: {
      transitions: {
        next: 'secondForm'
      }
    },
    secondForm: {
      transitions: {
        back: 'firstForm',
        next: 'finishStep'
      }
    },
    finishStep: {
      transitions: {
        back: 'firstForm'
      }
    }
  }
})

const methods = {
  toSecondStep (user: Auth.UserInRegister) {
    trackAction('register-continue')
    trackScreen('terms', { type: 'email' })
    send('next')
    data.user = user
  },

  toFirstStep () {
    send('back')
    trackAction('register-back')
  },

  onError (error: string) {
    send('back')
    data.emailError = error === 'user-already-registered'
  }
}
</script>
