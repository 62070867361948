import { useRegisterSW } from 'virtual:pwa-register/vue'
import { watch } from 'vue'
import ENV from '@/engine/env/web.env'
import { dom } from '@wallbox/toolkit-ui'
import { provideAuthUseCases } from '@/core/auth'

export default function initPwa () {
  if (!ENV.pwa.active || !navigator.serviceWorker) {
    return
  }

  const intervalMS = 60 * 1000

  const {
    needRefresh,
    updateServiceWorker
  } = useRegisterSW({
    onRegistered (r) {
      r && setInterval(() => {
        try {
          const ping = !r.installing && navigator && 'connection' in navigator ? navigator.onLine : true
          if (ping) {
            void fetch('/sw.js', {
              cache: 'no-store',
              'cache-control': 'no-cache'
            } as any).then((response) => {
              if (response && response.status === 200) {
                void r.update()
              }
            }).catch(() => ({}))
          }
        } catch {}
      }, intervalMS)
    }
  })

  watch(needRefresh, () => {
    if (needRefresh) {
      void updateServiceWorker()
    }
  })

  const db = dom.debounce(async (event: MessageEvent<any>) => {
    if (event.data.type === 'SW-401-API-ERROR') {
      await provideAuthUseCases().doRefreshToken()
    }
  }, 500)

  navigator.serviceWorker?.addEventListener?.('message', db)
}
