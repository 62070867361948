<template>
  <wb-card
    :headline="i18n.t('mywb.charger.gun-lock-title')"
  >
    <template #content>
      <wb-alert
        variant="info"
        class="mt-16 mb-16"
        icon="info_filled"
      >
        <p
          v-if="data.supportGunLock?.isCompatible"
          v-t="'mywb.charger.gun-lock-notification'"
        />
      </wb-alert>

      <div v-t="'mywb.charger.gun-lock-subtitle'" />
    </template>

    <template #place-upper-right>
      <wb-switch
        v-model="compute.lockGun"
        data-test-id="switch"
        :disabled="props.loading || data.saving || !data.supportGunLock?.isCompatible"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import state from '@/state'
import { reactive, computed, watchEffect } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { ChargerGunLockCompatible } from '@/core/charger'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()
const i18n = useI18n()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  saving: boolean
  supportGunLock?: ChargerGunLockCompatible
}

const data = reactive<Data>({
  saving: false
})

const compute = reactive({
  lockGun: computed({
    get () {
      return state.charger.getChargerCore.userSocketLocking
    },

    set (value) {
      methods.changeLockGun(value)
    }
  })
})

const methods = {
  async changeLockGun (value: boolean) {
    data.saving = true
    try {
      await chargerUseCases.updateCharger({
        uid: state.charger.getChargerCore.uid,
        userSocketLocking: value
      })

      state.charger.set('charger.userSocketLocking', value)

      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.saving = false
    }
  }
}

watchEffect(async () => {
  data.supportGunLock = await chargerUseCases.getIsChargerCompatibleWithGunLock(state.charger.getChargerCore)
})
</script>
