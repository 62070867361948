<template>
  <wb-card
    filled
  >
    <template #content>
      <wb-empty-state
        :title="compute.title"
        :subtitle="compute.subtitle"
      >
        <template #image>
          <client-img-component
            inline
            src="chargers"
            class="has-offset"
            width="180"
          />
        </template>
        <template #subheading>
          <wb-badge
            v-if="!permissions.canCreateUserGroups"
            data-test-id="labelLock"
            inverted
            variant="grey"
            icon="lock"
            class="mb-8"
          >
            {{ i18n.t('mywb.plans.business') }}
          </wb-badge>
        </template>

        <wb-button
          v-if="permissions.canCreateUserGroups"
          id="add-group"
          icon="create_new_folder"
          data-test-id="createGroupBtn"
          :label="i18n.t('mywb.common.create-access-group')"
          variant="primary"
          @click="methods.goToCreateGroup"
        />

        <wb-link
          v-if="permissions.showUpSellingToPlanBusiness
            && permissions.canChangePlan && !permissions.canCreateUserGroups"
          class="is-size-400 u-block mb-24"
          @click="methods.goToPlans"
        >
          {{ i18n.t('mywb.plan.upgrade') }}
        </wb-link>
      </wb-empty-state>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { computed, reactive } from 'vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useRouter } from 'vue-router'
import ROUTES from '@/engine/router/routes'

const router = useRouter()

const i18n = useI18n()

interface Events {
  (e: 'on-create-group'): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  title: computed(() => permissions.canCreateUserGroups
    ? i18n.t('mywb.common.no-access-groups-found')
    : i18n.t('mywb.common.assign-access-group')),
  subtitle: computed(() => !permissions.canCreateUserGroups
    ? i18n.t('mywb.common.add-users-access-group')
    : undefined)
})
const methods = {
  goToCreateGroup () {
    emit('on-create-group')
  },
  goToPlans () {
    router.push({ name: ROUTES.PLAN_BUSINESS })
    trackDataAction('upgrade_plan', {
      source: 'add_chargers_locations'
    })
  }
}
</script>

<style lang="postcss" scoped>
.has-offset {
  position: relative;
  left: 30px;
}
</style>
