<template>
  <wb-select
    v-model="compute.organizationSelected"
    uid="_asideMenuOrganizations"
    :disabled="!compute.organizationSelected"
    :searchable="false"
    :options="compute.organizationsParsed"
    is-outlined
    data-test-id="organizationSelect"
    class="mb-8"
    :class="{'is-collapse': props.isCollapsed}"
  >
    <template #selected-option="{ option }">
      <div>
        <p class="is-size-400 is-font-weight-500">
          <template v-if="!props.isCollapsed">
            {{ option.name }}
          </template>
          <template v-else>
            {{ (option.name.charAt(0) + option.name.charAt(1)).toUpperCase() }}
          </template>
        </p>
      </div>
    </template>
    <template #option="{ option }">
      <div>
        <p class="is-size-300 is-font-weight-500">
          {{ option.name }}
        </p>
        <span
          v-if="option.id === compute.organizationSelected.id"
          class="check-selected wb-icons"
        >check</span>
      </div>
    </template>
    <template #list-footer="{ close }">
      <wb-button
        variant="white"
        icon="business"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.view-all-organizations')"
        :to="{ name: ROUTES.ORGANIZATIONS }"
        @click="methods.clickAllOrganizations(close)"
      />
      <wb-separator v-if="permissions.canOperateOrganizations" />
      <wb-button
        v-if="permissions.canOperateOrganizations"
        variant="white"
        icon="add"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.common.create-organization')"
        :to="{ name: ROUTES.ORGANIZATIONS, query: { 'create-organization': 'true' } }"
        @click="close"
      />
    </template>
  </wb-select>
</template>

<script setup lang="ts">
import state from '@/state'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { EnumRoles } from '@/utilities/user-roles'
import { useI18n } from '@/hooks/useI18n.hook'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { permissions } from '@/engine/clients'
import { EnumPlans } from '@/utilities/plans'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

const props = defineProps({
  isCollapsed: {
    type: Boolean,
    default: false
  }
})

interface Organization {
  id: string,
  name: string,
  role: string,
  plan: string,
  groupUid: string
}

const compute = reactive({
  organizationsParsed: computed((): Organization[] => {
    return state.organizations.allOrganizations
      .map(organization => ({
        id: organization.id,
        name: organization.name,
        role: i18n.t(`mywb.common.${organization.role.name}`),
        plan: i18n.t(organization.plan.label),
        groupUid: organization.groupUid
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }),

  organizationSelected: computed({
    get: (): Organization => (compute.organizationsParsed
      .find(organization => organization.id === state.organizations.getCurrentOrganization.id)) as Organization,
    set: organization => {
      state.organizations.setCurrentOrganization(organization.id)
      router.push({ name: state.organizations.defaultHomePage })
    }
  }),

  hasOperatorPlanInRootGroup: computed(() => state.organizations.allOrganizations
    .find(organization => organization.role.id === EnumRoles['super-admin'])?.plan.product_id === EnumPlans.OPERATOR)
})

const methods = {
  clickAllOrganizations (close: () => void) {
    close()
    trackDataAction('account-menu-organizations', { name: ROUTES.ORGANIZATIONS })
  }
}
</script>

<style lang="postcss" scoped>
.is-collapse {
  width: 46px !important;
  transition: width 150ms ease-out;

  &:deep(.vs__actions) {
    display: none !important;
  }

  &:deep(.vs__open-indicator) {
    display: none !important;
  }
}

:deep(.vs__search) {
  position: absolute !important;
}

:global(#vs_asideMenuOrganizations__listbox) { /* stylelint-disable-line */
  min-width: 280px !important;
  max-height: 400px !important;
  margin-left: 10px !important;
}

.check-selected {
  position: absolute;
  right: 16px;
  top: 6px;
  color: #52b89e;
  font-size: 18px;
}
</style>
