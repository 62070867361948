import {
  getIsUserEmailRegistered,
  doSignIn,
  doSignUp,
  doSignUpActivated,
  doOauthSignIn,
  doOauthSignUp,
  doPasswordResetRequest,
  doPasswordReset,
  doActivateUser,
  doRefreshToken,
  updatePassword,
  getMe,
  updateMe,
  updateAutoReporting,
  updateUserSystemFormat,
  getUserReportingSettings,
  getUserPhoneCode,
  doLogout
} from './application'
import { userApiRepository } from './infrastructure'

export function provideAuthUseCases () {
  const repo = userApiRepository()

  return {
    getIsUserEmailRegistered: getIsUserEmailRegistered(repo),
    doSignIn: doSignIn(repo),
    doSignUp: doSignUp(repo),
    doSignUpActivated: doSignUpActivated(repo),
    doOauthSignIn: doOauthSignIn(repo),
    doOauthSignUp: doOauthSignUp(repo),
    doPasswordResetRequest: doPasswordResetRequest(repo),
    doPasswordReset: doPasswordReset(repo),
    doActivateUser: doActivateUser(repo),
    doRefreshToken: doRefreshToken(repo),
    doLogout: doLogout(repo),
    updatePassword: updatePassword(repo),
    getMe: getMe(repo),
    updateMe: updateMe(repo),
    updateAutoReporting: updateAutoReporting(repo),
    updateUserSystemFormat: updateUserSystemFormat(repo),
    getUserReportingSettings: getUserReportingSettings(repo),
    getUserPhoneCode: getUserPhoneCode(repo)
  }
}
