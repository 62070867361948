import i18n from '@/engine/lang'

const { global: { t } } = i18n

export enum OrganizationTypeEnum {
  HOME = 'home',
  CONDOMINIUM = 'condominium',
  WORKPLACE = 'workplace',
  FLEET = 'fleet',
  COMMERCIAL = 'commercial',
  PARKING = 'parking',
  ON_THE_GO_CHARGING = 'on-the-go-charging',
}

export const organizationText = {
  [OrganizationTypeEnum.HOME]: {
    name: t('mywb.organizations-type.home-title'),
    desc: t('mywb.organizations-type.home-desc')
  },
  [OrganizationTypeEnum.CONDOMINIUM]: {
    name: t('mywb.organizations-type.condominium-title'),
    desc: t('mywb.organizations-type.condominium-desc')
  },
  [OrganizationTypeEnum.WORKPLACE]: {
    name: t('mywb.organizations-type.workplace-title'),
    desc: t('mywb.organizations-type.workplace-desc')
  },
  [OrganizationTypeEnum.FLEET]: {
    name: t('mywb.organizations-type.fleet-title'),
    desc: t('mywb.organizations-type.fleet-desc')
  },
  [OrganizationTypeEnum.COMMERCIAL]: {
    name: t('mywb.organizations-type.commercial-title'),
    desc: t('mywb.organizations-type.commercial-desc')
  },
  [OrganizationTypeEnum.PARKING]: {
    name: t('mywb.organizations-type.parking-title'),
    desc: t('mywb.organizations-type.parking-desc')
  },
  [OrganizationTypeEnum.ON_THE_GO_CHARGING]: {
    name: t('mywb.organizations-type.on-the-go-charging-title'),
    desc: t('mywb.organizations-type.on-the-go-charging-desc')
  }
}

export interface OrganizationType {
  key: OrganizationTypeEnum
  title: string
  desc: string
}

const entries = Object.entries(organizationText) as Array<[OrganizationTypeEnum, { name: string, desc: string }]>

export const organizationTypes =
  entries.reduce<OrganizationType[]>((arr, [key, value]) => {
    arr.push({ key, title: value.name, desc: value.desc })
    return arr
  }, [])
