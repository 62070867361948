import type { EnumRoles } from '@/utilities/user-roles'

interface UserGroup {
  id: number
  name?: string
  lastname: string
  email: string
  avatar?: string
  accessConfig: number
  contract?: {
    createdAt: number
    id: number
    signed: boolean
    status?: string
    updatedAt: number
  }
  contractStatus?: {
    canCharge: boolean
    user: {
      billingInfo: boolean
      paidInvoices: boolean
      paymentMethod: boolean
      signed: boolean
    }
  }
  profile: EnumRoles
  registerDate: number
}

interface AccessConfigGroup {
  id: number
  name: string
  group: number
  chargers: number[]
  users: number[] | Record<number, number>
  invited?: [
    {
      createdAt: {
        date: string
        timezone: string
        timezone_type: number
      }
      email: string
      expirationDate: {
        date: string
        timezone: string
        timezone_type: number
      }
    }
  ]
}

interface UsersGroups {
  id: number
  users: UserGroup[]
  accessConfigs: AccessConfigGroup[]
}

export interface ApiGetUserGroupsResponse {
  result: {
    groups: UsersGroups[]
  }
}

export interface ApiGetUserAccessConfigContract {
  createdAt: number
  id: number
  signed: boolean
  status?: ApiGetAccessConfigForUserContractStatus
  updatedAt: number
}

export const enum ApiGetAccessConfigForUserContractStatus {
  UNPAID = 'unpaid',
  ACTIVE = 'active'
}

export interface ApiGetUserResponse {
  data: {
    id: number
    name: string
    email: string
    surname: string
    avatar: string
    access: Array<{
      authentication: string
      group: number
      type: string
      user: number
    }>
    accessConfigs: Array<{
      chargers: number[]
      contract?: ApiGetUserAccessConfigContract
      group: number
      id: number
      name: string
      profile: number
      servicePrice: null
    }>
  }
}

export interface ApiGetAccessConfig {
  result: {
    chargers: Array<{
      group: number
      id: number
      longitude: number | null
      name: string
      uid: string
    }>
    group: {
      id: number
      name: string
    }
    users: Array<{
      users: number[]
    }>
    id: number
    name: string
  }
}
