import type { InternationalRepository } from '@/core/international'
import type { UseCase } from '@/core/shared'

export const getAllCurrencies: UseCase<InternationalRepository, 'getCurrencies'> = repository => {
  return async () => {
    const currencies = await repository.getCurrencies()

    return currencies
  }
}
