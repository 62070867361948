<template>
  <wb-modal
    width="33"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.energy-cost') }}
    </template>
    <wb-form>
      <wb-checkbox
        v-if="!state.organizations.isPlan()([EnumPlans.BASIC])"
        v-model="data.keepEnergyCostFromGroup"
        @update:model-value="methods.switchKeepEnergyCost"
      >
        {{ i18n.t('mywb.common.apply-energy-of-location', {
          name: props.location.name,
          price: `${props.location.energyCost} ${i18n.t('mywb.common.kwh')}`
        }) }}
      </wb-checkbox>

      <wb-input
        :ref="setRef('input')"
        v-model="compute.computedPrice"
        data-test-id="energyCostInput"
        :error="data.errors.energyCost"
        :label-right="`${state.organizations.getCurrentOrganization.currencyCode} / ${i18n.t('mywb.common.kwh')}`"
        :hint="i18n.t('mywb.common.energy-cost.placeholder')"
        persistent-hint
        :disabled="data.keepEnergyCostFromGroup"
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="i18n.t('mywb.common.save')"
          :loading="data.saving"
          @click="validate(methods.editName)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { useToast, useTemplateRef } from '@wallbox/toolkit-ui'
import { EnumPlans } from '@/utilities/plans'
import { usePriceValueProxy } from '@/hooks'
import type { Location } from '@/core/location'
import { useValidator } from '@/hooks/useValidator.hook'
import { ensureUpdateEnergyCostIsValid, type ChargerUpdate } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const { refs, setRef } = useTemplateRef()
const { errors, validate, validateTo } = useValidator()

interface Events {
  (e: 'close'): void
  (e: 'on-energy-cost-updated'): void
}

const emit = defineEmits<Events>()

interface Props {
  location: Location
  energyCost: number
}
const props = defineProps<Props>()

const form = reactive<ChargerUpdate>({
  uid: state.charger.getChargerCore.uid,
  energyCost: props.energyCost === props.location.energyCost &&
    !state.organizations.isPlan()([EnumPlans.BASIC])
    ? props.location.energyCost
    : props.energyCost
})

validateTo(async () => !!await ensureUpdateEnergyCostIsValid(form))

const data = reactive({
  keepEnergyCostFromGroup: props.energyCost === props.location.energyCost &&
    !state.organizations.isPlan()([EnumPlans.BASIC]),
  saving: false,
  errors
})

const { computedPrice, onModelChange } = usePriceValueProxy(form.energyCost)

onModelChange(({ parsedValue, rawInput }) => {
  form.energyCost = parsedValue
  if (refs.input) refs.input.$el.getElementsByTagName('input')[0].value = rawInput
})

const compute = reactive({
  computedPrice
})

const methods = {
  async editName () {
    data.saving = true

    try {
      await chargerUseCases.updateCharger({
        uid: form.uid,
        energyCost: data.keepEnergyCostFromGroup ? null : (form.energyCost ?? 0)
      })

      emit('on-energy-cost-updated')
      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.saving = false
    emit('close')
  },

  switchKeepEnergyCost (checked: boolean) {
    if (checked) {
      compute.computedPrice = props.location?.energyCost?.toString?.() ?? '0'
    }
  }
}
</script>
