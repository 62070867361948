import { localPersistance, cookiePersistance, tokenPersistance } from '@wallbox/http'
import state from '@/state'
import AuthActions from '@/utilities/authActions'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import ROUTES from '../routes'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  const valuesAuthActions = Object.values(AuthActions)
  const authAction = valuesAuthActions.find(value => value.action === to.query.action)?.hasToLogout

  const hasToLogout = to.query.forgot ?? authAction
  const persistance = ENV.api.authSystem === 'cookie' ? cookiePersistance : localPersistance

  if (hasToLogout) {
    void provideAuthUseCases().doLogout()
    tokenPersistance(persistance).remove()
    state.global.removeLocalStorageSensitiveInfo()
    state.user.setInitialState()
  }

  const token = tokenPersistance(persistance).getToken()

  let route: Partial<NavigationGuardNext> | null = null

  if (requiresAuth && !token) {
    route = { name: ROUTES.AUTH_LOGIN, query: to.query }
    next(route)
  } else if (token && to.name === ROUTES.AUTH_LOGIN) {
    route = { name: ROUTES.CHARGERS, query: to.query }
    next(route)
  }

  next()
}
