<template>
  <div v-if="mq.current !== 'mobile'" class="label g-24">
    <div> {{ i18n.t('mywb.charger.serial-number') }}</div>
    <div>{{ i18n.t('mywb.charger.uid-or-puk') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()
</script>

<style lang="postcss" scoped>
.label {
  color: var(--grey-900);
  font-size: var(--size-400);
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
