import i18n from '@/engine/lang'
import type { Charger as ChargerCore } from '@/core/charger'
import { STATUSES } from '@/utilities/charger/chargerStatuses'

export enum EnumConnectivityType {
  WIFI = 'wifi',
  MOBILE = 'mobile',
  ETHERNET = 'ethernet'
}

export const connectivitySignal = (charger: ChargerCore) => {
  const chargerStatus = charger.status.code
  return !chargerStatus || (chargerStatus === STATUSES.DISCONNECTED)
    ? i18n.global.t('mywb.common.disconnected')
    : i18n.global.t('mywb.common.connected')
}

const wifiSignal = (charger: ChargerCore) => {
  const chargerStatus = charger.status.code

  if (!chargerStatus || chargerStatus === STATUSES.DISCONNECTED) return i18n.global.t('mywb.common.disconnected')
  if (charger.wifiSignal > 0) {
    return `${i18n.global.t('mywb.common.signal')}: ${charger.wifiSignal}%`
  }
  return i18n.global.t('mywb.common.disconnected')
}

export const connectivityByConnectionType = (charger: ChargerCore) => {
  const connectionType = charger?.connectionType
  return connectionType === EnumConnectivityType.WIFI ? wifiSignal(charger) : connectivitySignal(charger)
}

export const connectivityTypeIcon = (charger: ChargerCore) => {
  const connectionType = charger?.connectionType

  switch (connectionType) {
    case (EnumConnectivityType.WIFI): return undefined
    case (EnumConnectivityType.MOBILE): return 'signal_cellular_4_bar'
    case (EnumConnectivityType.ETHERNET): return 'ethernet'
    default: return 'bluetooth'
  }
}

export const connectivityTypeLabel = (charger: ChargerCore) => {
  const connectionType = charger?.connectionType

  switch (connectionType) {
    case (EnumConnectivityType.WIFI): return i18n.global.t('mywb.common.wifi')
    case (EnumConnectivityType.MOBILE): return i18n.global.t('mywb.common.mobile')
    case (EnumConnectivityType.ETHERNET): return i18n.global.t('mywb.common.ethernet')
    default: return i18n.global.t('mywb.common.bluetooth')
  }
}
