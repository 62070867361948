<template>
  <wb-modal
    width="45"
    @close="methods.close"
  >
    <template #title>
      {{ i18n.t('mywb.common.change-location') }}
    </template>

    <wb-select
      v-model="data.locationSelected"
      uid="_group"
      option-label="name"
      :options="data.locations"
      data-test-id="locationSelect"
      :placeholder="i18n.t('mywb.common.select-location')"
      :loading="data.loading"
      :disabled="data.loading || data.submitLoading"
      placeholder-icon="location"
    >
      <template #selected-option="{ option }">
        <span class="wb-icons mr-12"> location</span>
        {{ i18n.t(option.name) }}
      </template>
      <template #option="{ option }">
        <p class="is-size-300 has-text-grey-700 is-font-weight-500">
          {{ option.name }}
          <wb-badge
            v-if="!methods.canSelectLocation(option)"
            icon="blue"
            variant="warning"
            class="ml-8"
          >
            {{ i18n.t('mywb.common.incomplete') }}
          </wb-badge>
        </p>
        <p class="is-size-300 has-text-grey-700">
          {{ option.address }}
        </p>
      </template>
    </wb-select>

    <wb-alert
      v-if="data.locationSelected && !methods.canSelectLocation(data.locationSelected)"
      variant="warning"
      icon="info"
      class="mt-16"
      :action="i18n.t('mywb.common.edit-location')"
      @on-action-click="methods.handleGoToLocation"
    >
      <p>{{ i18n.t('mywb.common.complete-address-to-assign-chargers') }}</p>
    </wb-alert>

    <template #actions>
      <wb-button
        data-test-id="locationChangeLocationBtn"
        :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.submitLoading"
        size="block"
        :disabled="data.locationSelected && !methods.canSelectLocation(data.locationSelected) || data.loading"
        @click="methods.saveLocation"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import type { Charger } from '@/core/charger'
import type { Location } from '@/core/location'
import { LOCATION_USE_CASES, CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'
import { useRouter } from 'vue-router'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const locationUseCases = injectStrict(LOCATION_USE_CASES)
const router = useRouter()
const i18n = useI18n()
const toast = useToast()

interface Emits {
  (e: 'close'): void
  (e: 'on-change-location', groupUid: string): void
}
const emit = defineEmits<Emits>()

interface Props {
  charger: Charger
}
const props = defineProps<Props>()

interface Data {
  submitLoading?: boolean
  loading?: boolean
  locations: Location[]
  locationSelected: Location | undefined
}
const data = reactive<Data>({
  submitLoading: false,
  loading: false,
  locations: [],
  locationSelected: undefined
})

const methods = {
  canSelectLocation (location: Location) {
    const { country, address, city, latitude, longitude, zipCode } = location
    return Boolean(country && address && city && latitude && longitude && zipCode)
  },

  handleGoToLocation () {
    methods.close()
    router.push({
      name: ROUTES.LOCATION_DETAIL,
      params: { locationId: data.locationSelected?.id ?? '' },
      query: { edit: 1 }
    })
  },

  async saveLocation () {
    try {
      if (!data.locationSelected) return
      data.submitLoading = true
      await chargerUseCases.updateChargersLocation([props.charger.uid], data.locationSelected.groupUid)
      const charger = await chargerUseCases.getCharger(
        props.charger.uid,
        state.organizations.getCurrentOrganization.groupUid,
        {
          cache: 'network'
        }
      )

      charger && state.charger.setCharger(charger)
      toast.success(i18n.t('mywb.common.changes-saved'))
      emit('on-change-location', data.locationSelected.groupUid)
    } catch (e) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.submitLoading = false
      methods.close()
    }
  },

  close () {
    emit('close')
  }
}

async function created () {
  data.loading = true
  data.locations = await locationUseCases.getAllLocations(state.organizations.getCurrentOrganization.groupUid)
  data.locationSelected = data.locations.find(location => location.id === props.charger.locationId)
  data.loading = false
}

created()
</script>

<style lang="postcss" scoped>
  :deep(.modal-container) {
    white-space: normal !important;
  }

  :deep(.wbSelect .v-select .vs__search) {
    padding-left: 34px;
  }
</style>
