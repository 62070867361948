<template>
  <div>
    <wb-card data-test-id="userSettingsChargerAccess">
      <template #content>
        <div class="is-grid mb-16">
          <div class="align-items-center">
            <p class="has-text-grey-800 is-size-400 is-font-weight-500">
              <wb-link
                v-if="data.accessConfigEditing.name"
                type="text"
                :to="{ name: ROUTES.USERS, query: { group: data.accessConfigEditing.id.toString() } }"
              >
                {{ data.accessConfigEditing.name }}
              </wb-link>
              <template v-else>
                {{ i18n.t('mywb.common.custom-access') }}
              </template>
            </p>

            <p class="is-size-300 has-text-grey-500">
              {{ i18n.t('mywb.dashboard.nth-chargers', [pagination.total]) }}
            </p>
          </div>
          <wb-button
            v-if="compute.canUserAssignChargers"
            variant="white"
            outlined
            :label="i18n.t('mywb.common.change')"
            data-test-id="changeUserChargerAccessBtn"
            @click="data.isModalUserChargerAccessOpen = true"
          />
        </div>

        <wb-table
          :options="data.options"
          :loading="data.loading"
          @on-pagination-change="(offset: any) => methods.handleOnPaginationChange(offset)"
        >
          <wb-table-row
            v-for="charger in data.chargersList"
            :key="charger.id"
            :data-test-id="`charger-${charger.id}`"
          >
            <wb-table-row-item>
              <chargers-table-charger-name
                :id="charger.id"
                :is-charger-link-allowed="permissions.canSeeChargerDetail"
                :image="charger.image"
                :name="charger.name"
                :uuid="charger.uid"
              />
            </wb-table-row-item>

            <wb-table-row-item>
              <wb-badge
                v-if="charger.isPayPerMonthEnabled
                  && data.accessConfigEditing.contract?.status === AccessConfigForUserContractStatus.ACTIVE"
                variant="blue"
              >
                {{ i18n.t('mywb.common.charge-with-contract-subscription') }}
              </wb-badge>

              <wb-badge v-else variant="grey">
                {{ i18n.t('mywb.common.free-access') }}
              </wb-badge>
            </wb-table-row-item>
          </wb-table-row>
        </wb-table>

        <wb-alert
          v-if="data.accessConfigEditing.name"
          variant="info"
          icon="bulb"
          class="my-24"
        >
          <p class="is-font-weight-500 mb-8">
            {{ i18n.t('mywb.common.what-is-access-group') }}
          </p>
          <p>{{ i18n.t('mywb.common.what-is-access-group.description') }}</p>
        </wb-alert>
      </template>
    </wb-card>

    <user-chargers-access-modal
      v-if="data.isModalUserChargerAccessOpen"
      v-model:access-config="data.accessConfigEditing"
      :user="props.user"
      @on-close="data.isModalUserChargerAccessOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import ChargersTableChargerName from '../tables/ChargersTableChargerName.vue'
import UserChargersAccessModal from '@/components/modals/UserChargersAccessModal.vue'
import { ref, reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { hasRoleGreaterThan } from '@/utilities/users'
import { permissions } from '@/engine/clients'
import { provideChargersUsesCases, type Charger } from '@/core/charger'
import type { UserDetail } from '@/core/user'
import { type AccessConfigForUser, AccessConfigForUserContractStatus } from '@/core/accessConfig'
import type { TableProps } from '@wallbox/toolkit-ui'
import ROUTES from '@/engine/router/routes'

const chargerUsesCases = provideChargersUsesCases()
const i18n = useI18n()

const props = defineProps<{
  user: UserDetail
  accessConfig: AccessConfigForUser
}>()

interface DataType {
  loading: boolean
  chargersList: Charger[]
  accessConfigEditing: AccessConfigForUser
  isModalUserChargerAccessOpen: boolean
  options: TableProps['options']
}

type Pagination = {
  limit: number
  offset: number
  total: number
}

const pagination = ref<Pagination>({
  limit: 10,
  offset: 0,
  total: 0
})

const data: DataType = reactive({
  loading: false,
  chargersList: [],
  accessConfigEditing: props.accessConfig,
  isModalUserChargerAccessOpen: false,
  options: {
    pagination: pagination.value,
    columns: [
      {
        key: 'name',
        name: i18n.t('mywb.common.name'),
        align: 'left',
        minWidth: 250
      },
      {
        key: 'status',
        name: '',
        align: 'left',
        minWidth: 150,
        width: 'fit'
      }
    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.no-chargers-assigned')
    }
  }
})

const compute = reactive({
  canUserAssignChargers: computed((): boolean => {
    return hasRoleGreaterThan(props.user?.email)
  })
})

const methods = {
  async handleOnPaginationChange (offset: Pagination['offset']) {
    await methods.loadChargers(offset)
  },

  async loadChargers (offset: Pagination['offset']) {
    data.loading = true
    pagination.value.offset = offset

    const chargers = await chargerUsesCases
      .getAllChargersPaginated(
        state.organizations.getCurrentOrganization.groupUid,
        {
          limit: pagination.value.limit,
          offset: pagination.value.offset,
          filters: [{
            field: 'serial_number',
            operator: 'in',
            value: data.accessConfigEditing.chargers.join(',')
          }],
          sort: []
        }
      )

    data.chargersList = chargers.data
    pagination.value.total = chargers.total
    data.loading = false
  }
}

async function created () {
  await methods.loadChargers(pagination.value.offset)
}

created()
</script>

<style lang="postcss" scoped>
  .is-grid {
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
    gap: 16px;
  }
</style>
