import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const getAllChargers: UseCase<ChargerRepository, 'getAllChargers'> = repository => {
  return async (groupUid, params) => {
    const chargers = await repository.getAllChargers(groupUid, params)

    return chargers
  }
}
