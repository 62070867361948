<template>
  <wb-card
    :headline="t(props.title)"
    :subhead="t(props.subtitle)"
    class="dashboard-card"
    actions-position="bottom"
  >
    <template #place-upper-right>
      <span
        class="wb-icons chart-icon"
        data-test-id="icon"
      >
        {{ props.icon }}
      </span>
    </template>
    <template #content>
      <wb-skeleton-loader
        type="dots"
        :loading="props.loading"
        data-test-id="dashboard-card-content"
      >
        <p
          v-if="props.number"
          class="is-size-1000 is-font-weight-500 has-text-black"
          data-test-id="number"
        >
          {{ props.number }}
        </p>
      </wb-skeleton-loader>
    </template>
    <template #actions>
      <slot name="dashboard-actions" />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  title: string
  subtitle: string
  icon: string
  loading: boolean
  number?: number | string
}

const props = defineProps<Props>()
</script>

<style lang="postcss" scoped>
.chart-icon {
  color: var(--grey-500);
  position: relative;
  font-size: var(--size-700);
  margin: 0.8rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: currentColor;
    opacity: 0.07;
    width: 36px;
    height: 36px;
    border-radius: 99px;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

:deep(svg) {
  height: 57px;
  width: 100%;
}

.dashboard-card > :deep(div) {
  height: 100%;
}

:deep(.actions) {
  justify-content: space-between !important;
  align-self: end;
  z-index: 1;
}

:deep(.is-grid-card) {
  height: 100%;
  gap: 0 !important;
}

:deep(.content) {
  height: 60px;
  margin: 0 !important;

  > div {
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    margin: 0 !important;
    padding-top: 30px;
    padding-bottom: 15px;
    z-index: 0;

    > p {
      align-self: center;
    }
  }
}
</style>
