<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.organizations') }}
    </template>

    <template v-if="compute.hasOperatorPlanInRootGroup" #actions>
      <wb-button
        class="ml-8"
        icon="add"
        :label="mq.current === 'mobile' ? '': i18n.t('mywb.common.create-organization')"
        data-test-id="createOrganizationBtn"
        @click="emit('on-create-organization')"
      />
    </template>
  </shared-teleport-header>
</template>

<script setup lang="ts">
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'

import { useMq } from 'vue3-mq'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import state from '@/state'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'

const mq = useMq()

const compute = reactive({
  hasOperatorPlanInRootGroup: computed(() => state.organizations.allOrganizations
    .find(organization => organization.role.id === EnumRoles['super-admin'])?.plan.product_id === EnumPlans.OPERATOR)
})

const i18n = useI18n()

interface Events {
  (e: 'on-create-organization'): void
}
const emit = defineEmits<Events>()
</script>
