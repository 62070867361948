import { reactive } from 'vue'
import i18n from '@/engine/lang'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import { EnumPlans } from '@/utilities/plans'
import { trackError } from '@wallbox/metrics'

interface PlansState {
  plans: { [key in EnumPlans]: {
    product_id: EnumPlans
    name: string
    label: string
    description: string
  } }
}

const initialState = (): PlansState => ({
  plans: {
    [EnumPlans.BASIC]: {
      product_id: EnumPlans.BASIC,
      name: 'basic',
      label: 'mywb.plans.basic',
      description: i18n.global.t('mywb.plan.basic_US.description.html')
    },

    [EnumPlans.BUSINESS]: {
      product_id: EnumPlans.BUSINESS,
      name: 'business',
      label: 'mywb.plans.business',
      description: i18n.global.t('mywb.plan.business_US.description.html')
    },

    [EnumPlans.OPERATOR]: {
      product_id: EnumPlans.OPERATOR,
      name: 'operator',
      label: 'mywb.plans.operator',
      description: i18n.global.t('mywb.plan.operator.description.html')
    }
  }
})

const state = reactive(initialState())

const getters = {
  get (path: Paths<PlansState>) {
    return objects.getProperty(state, path)
  },

  getPlan (productId: EnumPlans) {
    if (!productId) trackError('getPlanWithoutProductID')
    return state.plans[productId]
  }
}

export default reactive({
  ...getters,
  ...state
})
