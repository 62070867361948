<template>
  <dashboard-card
    title="mywb.common.locations"
    subtitle="mywb.common.organise-chargers-locations"
    icon="location"
    :number="compute.locationsLength"
    :loading="props.loading"
    data-test-id="dashboardCard"
  >
    <template #dashboard-actions>
      <wb-button
        data-test-id="addLocationBtn"
        :label="t('mywb.charger.add-location')"
        variant="white"
        icon="add"
        outlined
        :to="{
          name: ROUTES.LOCATIONS,
          query: {
            'add-location': '1'
          }
        }"
        size="small"
      />

      <wb-button
        data-test-id="viewAllSettings"
        :to="{ name: ROUTES.LOCATIONS }"
        :label="t('mywb.common.view-all')"
        variant="white"
        icon="chevron_right"
        icon-position="right"
        size="small"
      />
    </template>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import { numbers } from '@wallbox/toolkit-ui'
import state from '@/state'
import { filterChargerByUsers } from '@/utilities/users'
import type { Location } from '@/core/location'
import ROUTES from '@/engine/router/routes'

const { t, locale } = useI18n()

interface Props {
  loading: boolean,
  locations: Location[]
}

const props = defineProps<Props>()

const compute = reactive({
  locationsFiltered: computed((): Location[] => {
    let locations = props.locations ?? []

    if (state.filters.dashboardFilters.locations.length > 0) {
      locations = locations.filter(group => state.filters
        .dashboardFilters.locations.some(location => location.id === group.id))
    }

    if (state.filters.dashboardFilters.users.length > 0) {
      const chargers = state.charger.getChargers

      locations = locations.filter(location => {
        const chargersInLocations = chargers.filter(charger => charger.locationId === location.id) ?? []

        if (!chargersInLocations?.length) return false

        return filterChargerByUsers(
          chargersInLocations,
          state.filters.dashboardFilters.users.map(user => user.id)
        ).length > 0
      })
    }

    return locations
  }),

  locationsLength: computed((): string => {
    return numbers.toLocaleNumber(compute.locationsFiltered.length, locale.value)
  })
})
</script>
