<template>
  <wb-card
    :headline="i18n.t('mywb.active-chargers')"
  >
    <template #content>
      <wb-table
        :options="data.options"
        :loading="data.loading"
      >
        <wb-table-row
          v-for="charger in data.chargers"
          :key="charger.chargerData.id"
          data-test-id="activeSession"
          @click="methods.handleClickCharger(charger.chargerData)"
        >
          <wb-table-row-item>
            <img :src="charger.chargerData.image" :alt="charger.chargerData.name" width="38">
          </wb-table-row-item>

          <wb-table-row-item>
            <div>
              <p data-test-id="chargerName" class="is-font-weight-500">
                {{ charger.chargerData.name }}
              </p>
              <p data-test-id="chargerSerialNumber" class="is-size-200">
                SN {{ charger.chargerData.id }}
              </p>
            </div>
          </wb-table-row-item>

          <wb-table-row-item @click.stop>
            <wb-link
              type="primary"
              data-test-id="chargerLocation"
              :to="{ name: ROUTES.LOCATION_DETAIL, params: { locationId: charger.chargerData.locationId } }"
            >
              {{ charger.chargerData.locationName }}
            </wb-link>
          </wb-table-row-item>

          <wb-table-row-item>
            <charger-status data-test-id="chargerStatus" :charger="charger.chargerData" />
            <!-- <wb-charger-status data-test-id="chargerStatus" :status="charger.chargerData.status.id" /> -->
          </wb-table-row-item>

          <wb-table-row-item>
            <div class="grid g-8">
              <wb-user-avatar
                size="small"
                :src="charger.user.avatar"
                :initials="charger.user.initials"
                class="mr-8 no-flex-shrink"
              />
              <wb-link
                v-if="charger.user.uid && charger.user.hasAccess"
                type="primary"
                data-test-id="chargerUser"
                @click.self.stop="methods.handleClickUser(charger.user)"
              >
                {{ charger.user.name }} {{ charger.user.surname }}
              </wb-link>
              <p v-else data-test-id="chargerUser" class="has-text-overflow">
                {{ charger.user.name }}
              </p>
            </div>
          </wb-table-row-item>

          <wb-table-row-item data-test-id="sessionDuration">
            {{ charger.duration }}
          </wb-table-row-item>

          <wb-table-row-item data-test-id="sessionEnergy">
            {{ charger.energy }}
          </wb-table-row-item>
        </wb-table-row>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerStatus from '@/components/charger/ChargerStatus.vue'
import { numbers, time, useToast, type TableProps } from '@wallbox/toolkit-ui'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { reactive, watch, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import {
  STATUSES
} from '@/utilities/charger/chargerStatuses'
import { HttpError } from '@wallbox/http'
import type { Charger as ChargerCore, ChargerActiveSession } from '@/core/charger'
import type { Organization } from '@/core/organization'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const router = useRouter()

interface Props {
  hasRealTimeInformation?: boolean
  organization?: Organization
}
const props = withDefaults(defineProps<Props>(), {
  hasRealTimeInformation: true,
  organization: undefined
})

interface SessionsParsed {
  chargerData: ChargerCore
  user: ChargerActiveSession['user']
  duration: string
  energy: string
}

interface Data {
  loading: boolean,
  unmounted: boolean
  options: TableProps['options']
  reloadChargersInterval?: ReturnType<typeof setInterval>
  chargers: SessionsParsed[]
}

const data = reactive<Data>({
  loading: false,
  unmounted: false,
  options: {
    columns: [
      { name: '', align: 'left', width: 75 },
      {
        name: i18n.t('mywb.common.chargers'),
        align: 'left',
        minWidth: 150,
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.common.location'), align: 'left', minWidth: 150 },
      { name: i18n.t('mywb.common.status'), align: 'left', minWidth: 150 },
      {
        name: i18n.t('mywb.common.user'),
        align: 'left',
        minWidth: 150,
        lineBehaviour: 'multiline'
      },
      { name: i18n.t('mywb.charger.charging-time'), align: 'left', minWidth: 100 },
      { name: i18n.t('mywb.common.energy'), align: 'left', minWidth: 100 }

    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  },
  reloadChargersInterval: undefined,
  chargers: []
})

const methods = {
  async enableAutoRefresh () {
    await methods.loadChargers(true)

    if (!data.unmounted) {
      data.reloadChargersInterval = setInterval(() => {
        methods.loadChargers(false)
      }, 10000)
    }
  },

  cancelAutoRefresh () {
    clearInterval(data.reloadChargersInterval)
  },

  async getChargers () {
    const chargers = await chargerUseCases.getAllChargers(state.organizations.getCurrentOrganization.groupUid, {
      cache: 'network'
    })
    state.charger.set('chargers', chargers)
  },

  async loadChargers (withLoading = true) {
    data.loading = withLoading
    await methods.getChargers()
    data.chargers = await methods.getActiveChargers()
    data.loading = false
  },

  async getActiveChargers () {
    if (!props.organization?.groupUid) return []
    try {
      const info = await chargerUseCases.getAllChargersActiveSession(props.organization.groupUid)

      return methods.getActiveChargersParsed(info)
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(i18n.t('mywb.error.unexpected-error'))
      } else {
        throw error
      }

      return []
    }
  },

  getActiveChargersParsed (sessions: ChargerActiveSession[]): SessionsParsed[] {
    const activeChargersParsed = sessions
      .filter(session => {
        const charger = methods.getChargerData(session)

        if (!charger) return false

        if (state.filters.dashboardFilters.locations.length > 0 &&
          state.filters.dashboardFilters.locations.every(location => location.id !== charger.locationId)) {
          return false
        }

        if (state.filters.dashboardFilters.users.length > 0 &&
          state.filters.dashboardFilters.users.every(user => user.uid !== session.user.uid)) {
          return false
        }

        return [
          STATUSES.WAITING,
          STATUSES.SCHEDULED,
          STATUSES.CHARGING,
          STATUSES.DISCHARGING,
          STATUSES.PAUSED
        ].includes(charger.status.code)
      })

    return activeChargersParsed.map(session => {
      const chargerData = methods.getChargerData(session)
      return {
        chargerData,
        user: session.user,
        duration: methods.getDurationActiveSession(session),
        energy: methods.getEnergyActiveSession(session)
      }
    })
  },

  getChargerData (session: ChargerActiveSession) {
    const chargers = [...state.charger.getChargers]
    const chargerParsed = chargers.find(charger => charger.uid === session.chargerUid)

    return chargerParsed as NonNullable<typeof chargerParsed>
  },

  getDurationActiveSession (session: ChargerActiveSession) {
    if (session.chargingTime && session.chargingTime > 0) {
      return time.getTimeDurationString(session.chargingTime, ['h', 'm'])
    }
    return '-'
  },

  getEnergyActiveSession (session: ChargerActiveSession) {
    if (session.chargingTime && session.chargingTime > 0) {
      return `${numbers.toDecimal(
        session.chargingEnergy, i18n.locale.value, 3, 3)} ${i18n.t('mywb.common.kwh')
      }`
    }
    return '-'
  },

  handleClickCharger (charger: ChargerCore) {
    trackDataAction('dashboard-charger', { widget: 'activeSessionsTable', type: 'goToChargers' })
    router.push({ name: ROUTES.CHARGER, params: { chargerUid: charger.uid } })
  },

  async handleClickUser (user: ChargerActiveSession['user']) {
    trackDataAction('dashboard-user', { widget: 'activeSessionsTable', type: 'goToUsers' })

    if (user.uid) {
      if (user?.uid === state.user.userLogged.id) {
        router.push({ name: ROUTES.ACCOUNT })
      } else {
        router.push({ name: ROUTES.USER_DETAIL, params: { uid: user.uid } })
      }
    }
  }
}

onBeforeUnmount(() => {
  data.unmounted = true
  clearInterval(data.reloadChargersInterval)
})

watch(() => props.hasRealTimeInformation, value => {
  return value ? methods.enableAutoRefresh() : methods.cancelAutoRefresh()
}, { immediate: true })

watch(() => props.organization, (value, oldvalue) => {
  if (value?.id !== oldvalue?.id) {
    methods.loadChargers()
  }
})

watch(() => [
  state.filters.dashboardFilters.locations,
  state.filters.dashboardFilters.users
], () => {
  methods.loadChargers()
})

</script>

<style lang="postcss" scoped>
.no-flex-shrink {
  flex-shrink: 0;
}

.grid {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
}

:deep(.table-wrapper) {
  position: relative !important;
  left: -18px;
  width: calc(100% + 36px);
  border: 0 !important;
  border-radius: 0 !important;
}

:deep(.head) {
  border-radius: 0 !important;
}
</style>
