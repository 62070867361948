import type { LocationRepository } from '@/core/location'
import type { CachedQuery } from '@/core/shared'

type params = CachedQuery & { getChargers: boolean }

export function getAllLocations (repository: LocationRepository) {
  return async (groupUid: string, params: params = { cache: 'network', getChargers: true }) => {
    const chargers = await repository.getAllLocations(groupUid, params)

    return chargers
  }
}
