import { object, string, type InferType, array, number } from 'yup'
import type { CONNECTORS } from './Connectors'

const chargePointSchema = object({
  name: string().required(),
  vendor: string().required(),
  model: string().required(),
  ocppIdentifier: string().required(),
  locationId: string().required(),
  connectors: array().of(object({
    name: string().required(),
    type: string<CONNECTORS>().required(),
    format: string<'CABLE' | 'SOCKET'>().required(),
    powerType: string<'AC_1_PHASE' | 'AC_3_PHASE' | 'DC'>().required(),
    maxPower: number().required(),
    amps: number().required(),
    voltage: number().required()
  })).required()
})

export type ChargePointCreate = InferType<typeof chargePointSchema>

export async function ensureCreateChargePointIsValid (ChargePoint: any) {
  return await chargePointSchema.validate(ChargePoint, { abortEarly: false })
}
