import yup from '@/hooks/useValidator.hook'

const imageUpdateSchema = yup.object({
  user: yup.string().required(),
  image: yup.mixed<File>().validateFileSize(2 * 1024).required()
})

export type ImageUpdate = yup.InferType<typeof imageUpdateSchema>

export async function ensureUpdateImageIsValid (payload: any) {
  return await imageUpdateSchema.validate(payload, { abortEarly: false })
}
