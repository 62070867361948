import type { InternationalRepository } from '@/core/international'
import type { UseCase } from '@/core/shared'

export const getTimezoneByCoords: UseCase<InternationalRepository, 'getTimezoneByCoords'> = repository => {
  return async (payload) => {
    const timezone = await repository.getTimezoneByCoords(payload)

    return timezone
  }
}
