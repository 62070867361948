<template>
  <header class="header g-12">
    <wb-button
      icon="menu"
      class="menu"
      variant="white"
      rounded
      inverted
      @click="emit('on-handle-menu')"
    />

    <img
      v-bind="clientConfig.asideLogo"
      loading="eager"
      importance="high"
      class="logo"
      alt="logo"
    >
  </header>
</template>

<script setup lang="ts">
import { clientConfig } from '@/engine/clients'

interface Events {
  (e: 'on-handle-menu'): void
}
const emit = defineEmits<Events>()
</script>

<style lang="postcss" scoped>
.header {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas: "menu logo";
  align-items: center;
  align-content: center;
  height: 6rem;
  background: var(--grey-900);
  position: sticky;
  top: 0;
  z-index: 1001;
  padding: 1rem;

  @media (--tablet) {
    overflow: hidden;
    height: 0;
    padding: 0;
  }
}

.menu {
  grid-area: menu;
}

.logo {
  grid-area: logo;
  max-width: 12rem !important;
}

.settings {
  grid-area: settings;
}
</style>
