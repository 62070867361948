<template>
  <div class="has-center-content">
    <div v-if="data.loading">
      <wb-loading class="ring" type="Ring" width="34px" />
      <p
        v-t="'mywb.common.users-invitations-sending'"
        class="is-size-700 mb-32"
      />
      <p
        v-t="'mywb.common.users-invitations-sending.description'"
        class="is-size-400 mb-32 has-text-grey-600"
      />
    </div>
    <div v-else-if="!data.error">
      <wb-check-icon />
      <p
        v-t="'mywb.common.users-invitations-sent'"
        class="is-size-700 has-text-primary-500 mb-16"
      />
      <p
        v-t="'mywb.common.users-invitations-sent.description'"
        class="is-size-400 mb-32 has-text-grey-600"
      />
    </div>
    <div v-else>
      <p
        v-t="'mywb.error.send-invitations'"
        class="is-size-700 has-text-danger-500 mb-16"
      />
    </div>
    <wb-button
      v-if="!data.error"
      data-test-id="finishButton"
      :label="data.loading ? i18n.t('mywb.common.sending') : i18n.t('mywb.common.done')"
      :loading="data.loading"
      :variant="data.loading ? 'white' : 'primary'"
      @click="emit('on-finish')"
    />
    <wb-button
      v-else
      data-test-id="finishErrorButton"
      :label="i18n.t('mywb.common.cancel')"
      variant="white"
      outlined
      @click="emit('on-finish')"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, onMounted } from 'vue'
import { clientConfig } from '@/engine/clients'
import { getServerError } from '@/utilities/errorMessages'
import { getRoleById, EnumRoles } from '@/utilities/user-roles'
import lang from '@/engine/lang'
import { useToast } from '@wallbox/toolkit-ui'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { HttpError } from '@wallbox/http'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'
import state from '@/state'

const i18n = useI18n()
const toast = useToast()
const userUseCases = injectStrict(USER_USE_CASES)

interface Events {
  (e: 'on-finish'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false,
  error: false
})

const props = defineProps<{
  invitation: {
    profile: EnumRoles
    users: {
      email: {
        value: string
      }
      rfid: {
        value: string
      }
    }[]
    hasSubscription: number
    accessConfigId?: number
    chargers: any[],
    invitationToAccessGroup: number
  }
}>()

const methods = {
  async addUsersToAccessConfig () {
    if (!props.invitation.accessConfigId) return

    const payload = {
      role: props.invitation.profile,
      users: props.invitation.users.map(user => ({ email: user.email.value, rfid: user.rfid?.value })),
      hasPayPerMonth: !!props.invitation.hasSubscription,
      accessConfig: props.invitation.accessConfigId,
      lang: lang.__rootLanguage,
      branding: clientConfig.brandingId
    }

    await userUseCases.inviteUsersToAccessConfig(payload)
  },

  async addToCustomAccessConfig () {
    userUseCases.inviteUsersWithChargers({
      groupUid: state.organizations.getCurrentOrganization.groupUid,
      chargers: props.invitation.chargers,
      role: props.invitation.profile,
      users: props.invitation.users.map(user => {
        return {
          email: user.email.value,
          rfid: user.rfid.value
        }
      }),
      hasPayPerMonth: !!props.invitation.hasSubscription,
      lang: lang.__rootLanguage,
      branding: clientConfig.brandingId
    })
  },

  async sendInvitations () {
    data.loading = true
    data.error = false

    try {
      props.invitation.invitationToAccessGroup
        ? await methods.addUsersToAccessConfig()
        : await methods.addToCustomAccessConfig()

      trackDataEvent('user-subscription', {
        pay_per_month: props.invitation.hasSubscription,
        chargers: props.invitation.chargers,
        user_type: getRoleById(props.invitation.profile)?.name,
        emails_count: props.invitation.users.length
      })
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
      data.error = true
    } finally {
      data.loading = false
    }
  }
}

onMounted(async () => {
  methods.sendInvitations()
})
</script>

<style lang="postcss" scoped>
.ring {
  margin: 0 auto;
  margin-top: 42px;
  margin-bottom: 20px;
}

.has-center-content {
  padding-top: 10rem;
  text-align: center;
}
</style>
