<template>
  <wb-card
    :headline="i18n.t('mywb.common.rfid')"
    :subhead="i18n.t('mywb.common.rfid-card.description')"
    actions-position="bottom"
  >
    <template #content>
      <wb-alert
        v-if="data.rfidActivated"
        variant="info"
        icon="info_filled"
      >
        <p v-t="'mywb.user.rfid-detail'" />
      </wb-alert>

      <wb-form v-if="data.rfidActivated" class="mt-8">
        <wb-input
          v-model="form.rfid"
          data-test-id="rfidInput"
          :disabled="!compute.hasPermissions || !permissions.canUserListRfidEdit || data.saving"
          type="text"
          :error="errors.rfid || data.errorApiMessage"
          autocomplete="__away"
          :label="i18n.t('mywb.common.code')"
          :placeholder="i18n.t('mywb.common.add-rfid-code')"
          :hint="i18n.t('mywb.rfid.only-visible-to-users')"
          persistent-hint
          @keyup="data.errorApiMessage = ''"
        />
      </wb-form>
    </template>

    <template #actions>
      <wb-button
        v-if="compute.hasPermissions && permissions.canUserListRfidActions && data.rfidActivated"
        data-test-id="saveBtn"
        :loading="data.saving"
        :disabled="!form.rfid"
        :label="i18n.t('mywb.common.save')"
        @click.stop="validate(methods.saveRfid)"
      />
    </template>

    <template #place-upper-right>
      <wb-switch
        id="type"
        v-model="data.rfidActivated"
        data-test-id="switchRfid"
        name="type"
        :disabled="!compute.hasPermissions || !permissions.canUserListRfidEdit"
        @input="methods.switchRfid"
      />
    </template>

    <template #modal>
      <shared-confirmation-modal
        v-if="data.showRfidConfirmModal"
        :title="i18n.t('mywb.common.rfid-card.delete-confirm')"
        :label-confirmation-button="i18n.t('mywb.common.delete')"
        type="danger"
        @on-confirm="methods.handleConfirm"
        @on-close="methods.handleCancel"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import { hasRoleGreaterThan, checkIfUserIsSameAs } from '@/utilities/users'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { HttpError } from '@wallbox/http'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'
import { ensureUpdateRfidIsValid, type RfidUpdate, type UserDetail } from '@/core/user'

const userUseCases = injectStrict(USER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const { errors, validate, validateTo } = useValidator()

interface Props {
  user: UserDetail
}
const props = defineProps<Props>()

interface Data {
  saving: boolean
  showRfidConfirmModal: boolean
  rfidActivated: boolean
  errorApiMessage?: string
}

const data = reactive<Data>({
  saving: false,
  showRfidConfirmModal: false,
  rfidActivated: false
})

const form = reactive<RfidUpdate>({
  groupUid: state.organizations.getCurrentOrganization.groupUid,
  userId: props.user.id,
  rfid: ''
})

validateTo(async () => !!await ensureUpdateRfidIsValid(form))

const compute = reactive({
  hasPermissions: computed(() => {
    return (
      hasRoleGreaterThan(props.user.email) ||
      checkIfUserIsSameAs(props.user.email) ||
      permissions.canAssignedRfid
    )
  })
})

const methods = {
  getRfidAssigned () {
    if (!compute.hasPermissions) return '*****************'

    return userUseCases.getRfidByUser(
      state.organizations.getCurrentOrganization.groupUid,
      props.user.id
    )
  },

  async switchRfid (checked: boolean) {
    if (!checked) {
      data.showRfidConfirmModal = true
    } else {
      data.rfidActivated = checked
    }
  },

  handleCancel () {
    data.showRfidConfirmModal = false
    data.rfidActivated = true
  },

  async saveRfid () {
    if (!form.rfid) return

    try {
      data.saving = true
      data.errorApiMessage = undefined

      await userUseCases.updateUserRfid(form)
      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      if (error instanceof HttpError && error?.code === 409) {
        data.errorApiMessage = i18n.t('mywb.error.duplicated-rfid')
      } else {
        toast.error(i18n.t('mywb.error.unexpected-error'))
      }
    } finally {
      data.saving = false
    }
  },

  async handleConfirm () {
    await userUseCases.deleteUserRfid(form)
    form.rfid = ''
    data.rfidActivated = false
  }
}

async function created () {
  form.rfid = await methods.getRfidAssigned() ?? ''
  data.rfidActivated = !!form.rfid
}

created()
</script>
