import { endpoints } from '@/core/shared/endpoints.config'
import { ulid } from 'ulid'
import type { RateRepository, Rate, ChargerRateWeekSchedule } from '@/core/rate'
import type { PaginatedResponse, RepositoryHttpBuild } from '@/core/shared'
import type { ApiChargerRateWeekSchedule, ApiRateAssignedResponse, ApiRateResponse, ApiRatesResponse } from './api'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

export const wapiRateRepositoryBuilder: RepositoryHttpBuild<RateRepository> = ({ httpService }) => {
  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  async function getOldGroupId (groupUid: string): Promise<number> {
    const groupId = await wapiInstance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
      headers: {
        'workbox-cache-type': 'cache-first'
      }
    })

    return groupId?.data?.attributes?.value
  }

  return {
    async getAllRates ({ groupUid }): Promise<PaginatedResponse<Rate>> {
      const groupId = await getOldGroupId(groupUid)

      if (!groupId) {
        return {
          total: 0,
          data: []
        }
      }

      const rates = await wapiInstance.get<ApiRatesResponse>(
        endpoints.v4.group_rates.replace('{groupId}', groupId.toString() ?? -1)
      )

      if (!rates) {
        return {
          total: 0,
          data: []
        }
      }

      return {
        total: rates.data.length,
        data: rates.data.map(rate => {
          return {
            currencyCode: rate.attributes.currency_code,
            fixedFee: rate.attributes.fixed_fee,
            id: rate.id,
            name: rate.attributes.name,
            type: rate.type,
            variableFeePrice: rate.attributes.variable_fee_price,
            variableFeeType: rate.attributes.variable_fee_type
          }
        })
      }
    },

    async createRate ({ groupUid, rate }): Promise<string> {
      const groupId = await getOldGroupId(groupUid)

      const id = ulid()

      return await wapiInstance.post(
        endpoints.v4.group_rates.replace('{groupId}', groupId.toString()),
        {
          data: {
            id,
            type: 'rate',
            attributes: {
              fixed_fee: rate.fixedFee,
              name: rate.name,
              variable_fee_price: rate.variableFeePrice,
              variable_fee_type: rate.variableFeeType
            }
          }
        }
      ).then(() => id)
    },

    async editRate ({ groupUid, rateId, rate }): Promise<void> {
      const groupId = await getOldGroupId(groupUid)

      await wapiInstance.patch(
        endpoints.v4.group_rate
          .replace('{groupId}', groupId.toString())
          .replace('{rateId}', rateId.toString()),
        {
          data: {
            type: 'rate',
            attributes: {
              fixed_fee: rate.fixedFee,
              name: rate.name,
              variable_fee_price: rate.variableFeePrice,
              variable_fee_type: rate.variableFeeType
            }
          }
        }
      )
    },

    async getRate ({ groupUid, rateId }) {
      const groupId = await getOldGroupId(groupUid)
      if (!groupId) return

      const result = await wapiInstance.get<ApiRateResponse>(
        endpoints.v4.group_rate
          .replace('{groupId}', groupId.toString())
          .replace('{rateId}', rateId.toString())
      )

      if (!result) return undefined

      return {
        currencyCode: result.data.attributes.currency_code,
        fixedFee: result.data.attributes.fixed_fee,
        id: result.data.id,
        name: result.data.attributes.name,
        type: result.data.type,
        variableFeePrice: result.data.attributes.variable_fee_price,
        variableFeeType: result.data.attributes.variable_fee_type
      }
    },

    async deleteRate ({ groupUid, rateId }): Promise<void> {
      const groupId = await getOldGroupId(groupUid)

      await wapiInstance.delete(
        endpoints.v4.group_rate
          .replace('{groupId}', groupId.toString())
          .replace('{rateId}', rateId.toString())
      )
    },

    async getChargerRatesWeekSchedules (chargerUid: string): Promise<ChargerRateWeekSchedule[]> {
      const chargerRatesSchedules = await wapiInstance.get<ApiChargerRateWeekSchedule>(
        endpoints.v4.chargers_charger_rate_schedules
          .replace('{chargerUid}', chargerUid.toString())
      )

      if (!chargerRatesSchedules) {
        return []
      }

      return chargerRatesSchedules.data.map(schedule => {
        return {
          id: schedule.id,
          paymentType: schedule.attributes.paymentType,
          schedule: schedule.attributes.schedule,
          status: schedule.attributes.status
        }
      })
    },

    async saveChargerRateSchedule ({ chargerId, scheduleId = ulid(), payload }) {
      await wapiInstance.put(
        endpoints.v4.chargers_charger_rate_schedule_id
          .replace('{chargerUid}', chargerId)
          .replace('{scheduleId}', scheduleId),
        {
          data: {
            type: 'charger_rate_schedule',
            attributes: payload
          }
        })
    },

    async getIsRateAssigned (groupUid: string, rateUid: string) {
      const isRateAssignedResult = await wapiInstance
        .get<ApiRateAssignedResponse>(
        endpoints.v4.organizations_assigned_rates
          .replace('{groupUid}', groupUid)
          .replace('{rateUid}', rateUid)
      )

      return Object.keys(isRateAssignedResult ?? {}).length > 0
    }
  }
}
