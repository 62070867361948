<template>
  <ul class="list">
    <li class="item" data-test-id="lengthCheck" :class="{ success: compute.betweenSuccess }">
      <div class="check">
        <span class="wb-icons">check</span>
      </div>
      <span v-t="'mywb.auth.password-hint-length'" />
    </li>
    <li class="item" data-test-id="uppercaseCheck" :class="{ success: compute.oneUppercaseAndLowercaseSuccess }">
      <div class="check">
        <span class="wb-icons">check</span>
      </div>
      <span v-t="'mywb.auth.password-hint-uppercase-lowercase'" />
    </li>
    <li class="item" data-test-id="onedigitCheckCheck" :class="{ success: compute.oneDigitRegexSuccess }">
      <div class="check">
        <span class="wb-icons">check</span>
      </div>
      <span v-t="'mywb.auth.password-hint-digit'" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

const props = defineProps({
  value: {
    type: String,
    required: true
  }
})

const data = reactive({
  oneUppercaseAndLowercaseRegex: /(?=.*[A-Z].*)(.*[a-z].*)/,
  oneDigitRegex: /(.*\d.*)/,
  validChars: /[a-zA-Z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,30}$/
})

const compute = reactive({
  betweenSuccess: computed(() =>
    data.validChars.test(props.value) && props.value.length >= 6 && props.value.length <= 30
  ),

  oneUppercaseAndLowercaseSuccess: computed(() => data.oneUppercaseAndLowercaseRegex.test(props.value)),

  oneDigitRegexSuccess: computed(() => data.oneDigitRegex.test(props.value))
})
</script>

<style lang="postcss" scoped>
.list > * + * {
  margin-top: 8px;
}

.item {
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  & > * + * {
    margin-left: 8px;
  }
}

.check {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  border: 2px solid var(--grey-300);
  color: var(--grey-400);
  display: inline-block;
  position: relative;

  & .wb-icons {
    position: absolute;
    font-size: 1rem !important;
    font-weight: 700 !important;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    display: none;
  }
}

.item.success {
  color: var(--primary-500);

  & .check {
    border-color: var(--primary-500);
    background: var(--primary-500);
    color: var(--white);

    & .wb-icons {
      display: block;
    }
  }
}
</style>
