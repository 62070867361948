<template>
  <wb-modal
    width="60"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.user.personal-data') }}
    </template>

    <wb-form data-test-id="accountPersonalDataForm">
      <wb-input
        v-model="form.name"
        type="text"
        data-test-id="nameInput"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
      />
      <wb-input
        v-model="form.surname"
        type="text"
        data-test-id="surnameInput"
        :label="i18n.t('mywb.user.surname')"
        :error="errors.surname"
      />
      <wb-input
        :model-value="state.user.userLogged.email"
        type="text"
        data-test-id="emailInput"
        disabled
        :label="i18n.t('mywb.common.email')"
      />
      <wb-select
        v-model="form.countryIso2"
        uid="_country"
        :reduce="(country: any) => country.iso2"
        option-label="name"
        data-test-id="countrySelect"
        :label="i18n.t('mywb.common.country')"
        :options="state.global.getCountries"
        :error="errors.countryIso2"
      />

      <div class="is-fullwidth">
        <p class="phone-label">
          {{ i18n.t('mywb.user.phone') }}
        </p>
        <div class="phone-input">
          <wb-select
            v-model="form.phoneCode"
            uid="_phone_code"
            :reduce="(phoneOptions: any) => phoneOptions.prefixCode"
            option-label="prefixLabel"
            data-test-id="prefixPhoneNumber"
            :options="compute.prefixPhoneOptions"
            :filter-by="methods.filterByPrefix"
            :error="errors.phoneCode"
          />
          <wb-input
            v-model="form.phone"
            name="phone"
            type="tel"
            data-test-id="phoneInput"
            :error="errors.phone"
          />
        </div>
      </div>
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
          :loading="data.loading"
          @click="validate(methods.updatePersonalData)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import type { CountryIso2 } from '@/core/international'
import { ensureUpdateMeIsValid, type UpdateMe } from '@/core/auth'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'

const authUseCases = injectStrict(AUTH_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const { errors, validate, validateTo } = useValidator()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

interface PrefixCountry {
  countryIso2: CountryIso2
  prefixCode: string
  prefixLabel: string
  prefix: string
}

const compute = reactive({
  prefixPhoneOptions: computed((): PrefixCountry[] => {
    return state.global.getCountries
      .filter(country => !!country.phoneCode)
      .map(country => {
        return {
          countryIso2: country.iso2,
          prefixCode: country.phoneCode,
          prefixLabel: `${country.name} (+${country.phoneCode})`,
          prefix: `+${country.phoneCode}`
        }
      })
  }),

  formattedTimezones: computed(() => {
    return state.global.getTimezones.map(timezone => (
      {
        id: timezone,
        name: timezone.toString().replaceAll('_', ' ')
      }))
  }),

  prefixPhoneCode: computed((): PrefixCountry | undefined => {
    return compute.prefixPhoneOptions.find(prefix => prefix.countryIso2 === state.user.userLogged.countryIso2)
  })
})

interface Data {
  loading: boolean
}

const data = reactive<Data>({
  loading: false
})

const form = reactive<UpdateMe>({
  id: state.user.userLogged.id,
  name: state.user.userLogged.name ?? '',
  surname: state.user.userLogged.surname ?? '',
  countryIso2: state.global.getCountries
    .find(country => country.iso2 === state.user.userLogged.countryIso2)?.iso2 as CountryIso2,
  phone: state.user.userLogged.phone ?? '',
  phoneCode: ''
})

validateTo(async () => !!await ensureUpdateMeIsValid(form))

const methods = {
  filterByPrefix (option: PrefixCountry, label: string, search: string) {
    return (option.prefixLabel || '').toLowerCase().indexOf(search.toLowerCase()) > -1
  },

  async updatePersonalData () {
    try {
      data.loading = true
      await authUseCases.updateMe(form)

      state.user.set('userLogged.name', form.name)
      state.user.set('userLogged.surname', form.surname)
      state.user.set('userLogged.phone', form.phone)
      state.user.set('userLogged.countryIso2', form.countryIso2)

      toast.success(i18n.t('mywb.common.changes-saved'))
      emit('close')
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  }
}

async function created () {
  form.phoneCode =
    await authUseCases.getUserPhoneCode(state.user.userLogged.id) || (compute.prefixPhoneCode?.prefixCode ?? '')
}
created()
</script>

<style lang="postcss" scoped>
.phone-label {
  color: var(--grey-900);
  margin-bottom: 6px;
  font-weight: 500;
  font-size: var(--size-400);
}

.phone-input {
  display: flex;
  align-items: flex-start;

  &:deep(.vs__dropdown-toggle) {
    border-radius: 0.4rem 0 0 0.4rem !important;
  }

  &:deep(.input_element) {
    border-left: 0 !important;
    border-radius: 0 0.4rem 0.4rem 0 !important;
  }
}
</style>
