import i18n from '@/engine/lang'

const dictionary: Record<string | number, string> = {
  // backend code translations
  default: 'mywb.error.unexpected-error',
  403: 'mywb.error.charger-not-exist',
  2401: 'mywb.error.user-already-registered',
  2411: 'mywb.error.missing-field',
  2412: 'mywb.auth.unconfirmed-account',
  2413: 'mywb.error.invalid-account',
  2414: 'mywb.error.email-not-verified',
  2415: 'mywb.error.invalid-email',
  2416: 'mywb.error.invalid-email',
  2304: 'mywb.error.charger-already-associated',
  2200: 'mywb.error.without-permission',
  2307: 'mywb.error.charger-invalid-serial-number',
  2305: 'mywb.error.charger-wrong-uid',
  2306: 'mywb.add-charger.empty-field-error',
  2405: 'mywb.error.user-already-in-group',
  2201: 'mywb.error.rate-limit-exceeded',
  not_confirmed: 'mywb.error.email-not-verified',
  unauthorized: 'mywb.error.login-credentials',
  too_many_requests: 'mywb.error.rate-limit-exceeded'
}

export const getServerError = <T extends { code: string | number }> (error: T): string => {
  let serverError = i18n.global.t(dictionary.default)
  Object.keys(dictionary).forEach(property => {
    if (String(property) === String(error?.code)) {
      serverError = i18n.global.t(dictionary[property])
    }
  })

  return serverError
}
