import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const updateChargerRemoteAssistance:
UseCase<ChargerRepository, 'updateChargerRemoteAssistance'> = repository => {
  return async (chargerServiceabilitiesId, status) => {
    await repository.updateChargerRemoteAssistance(
      chargerServiceabilitiesId,
      status
    )
  }
}
