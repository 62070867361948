<template>
  <div class="svg-container">
    <svg
      class="svg-width"
      viewBox="0 0 100 60"
      preserveAspectRatio="xMaxYMid meet"
    >
      <defs>
        <filter id="blur" x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="0.8" />
          <feColorMatrix
            values="
         1 0 0 0 0
         0 1 0 0 0
         0 0 1 0 0
         0 0 0 50 -20
       "
          />
        </filter>

        <path
          id="topPathInvisible"
          d="M8 7H8C16 8 24 6 35 10V13C35 17 35 18 38 19h63"
        />
      </defs>

      <g filter="url(#blur)">
        <path
          id="topPath"
          class="path-stroke scale topPath"
          d="M8 6H8C16 8 24 6 35 10V13C35 17 35 18 38 19h60"
          :stroke="topPathColor"
        />

        <path
          id="downPath"
          class="path-stroke scale rotate"
          d="M8 7H8C16 8 24 6 35 10V13C35 17 35 18 38 19"
          :stroke="downPathColor"
        />
        <g v-show="isCharging" id="outer-circles">
          <circle r="1.8" fill="#192538" class="bubble">
            <animateMotion dur="2s" fill="freeze">
              <mpath xlink:href="#topPathInvisible" />
            </animateMotion>
          </circle>
        </g>

        <g v-show="props.isCharging && props.hasGreenEnergy" id="outer-circles-rotated" class="rotate">
          <circle r="1.8" fill="#192538" class="bubble">
            <animateMotion dur="2s" fill="freeze">
              <mpath xlink:href="#topPathInvisible" />
            </animateMotion>
          </circle>
        </g>

        <g v-show="props.isCharging">
          <circle
            class="car-bubble-animation"
            :class="{ 'animate-start': animateCarCircle }"
            r="6"
            fill="#192538"
            cx="101.5"
            cy="18.5"
          />
        </g>
      </g>

      <g v-show="props.isCharging" id="inner-circles">
        <circle r="1" fill="#518DF7" class="bubble">
          <animateMotion dur="2s" fill="freeze">
            <mpath xlink:href="#topPathInvisible" />
          </animateMotion>
        </circle>
      </g>

      <g v-show="props.isCharging && props.hasGreenEnergy" id="inner-circles-rotated" class="rotate">
        <circle r="1" fill="#61D1C0" class="bubble">
          <animateMotion dur="2s" fill="freeze">
            <mpath xlink:href="#topPathInvisible" />
          </animateMotion>
        </circle>
      </g>

      <g v-show="props.isCharging">
        <circle
          class="car-bubble-animation"
          :class="{ 'animate-start': animateCarCircle }"
          r="4"
          :fill="carCircleColor"
          cx="101.5"
          cy="18.5"
        />
      </g>
    </svg>

    <div class="gridEnergy">
      <slot name="gridEnergy" />
    </div>

    <div class="greenEnergy">
      <slot name="greenEnergy" />
    </div>

    <div class="carPower">
      <slot name="carPower" />
    </div>

    <div class="car">
      <slot name="car" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

interface Props {
  isCharging: boolean
  hasGreenEnergy: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isCharging: false
})

const animateCarCircle = ref(false)
const topPathColor = computed(() => props.isCharging ? '#518DF7' : '#ADADAD')
const downPathColor = computed(() => props.isCharging && props.hasGreenEnergy ? '#61D1C0' : '#ADADAD')
const carCircleColor = computed(() => props.hasGreenEnergy ? '#61D1C0' : '#518DF7')

onMounted(() => {
  const items = document.querySelectorAll('animateMotion')
  const delay = 1500
  const animation = 1000

  function animate () {
    setTimeout(() => {
      animateCarCircle.value = true
      setTimeout(() => {
        items.forEach(item => item.beginElement())
        animateCarCircle.value = false
        requestAnimationFrame(animate)
      }, animation)
    }, delay)
  }
  animate()
})
</script>

<style scoped lang="postcss">
.path-stroke {
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 2 , 3;
  stroke-width: 1.1;
  stroke-dashoffset: 16;
}

.topPath {
  stroke-dashoffset: 18;
}

.bubble {
  animation: bubble 2s infinite ease-in forwards;
}

@-moz-document url-prefix(){
  .bubble {
    animation: none;
  }
}

.car-bubble-animation {
  transform-origin: center;
  transform-box: fill-box;
  transform: scale(0);
  opacity: 0;

  &.animate-start {
    opacity: 1;
    animation: appear 1000ms infinite ease-in forwards;
  }
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

svg {
  overflow: visible;
  margin-bottom: -55px;
  width: 300px;
  height: 180px;
}

.invisible {
  stroke-width: 0;
  stroke: transparent;
}

.rotate {
  transform: rotateX(180deg);
  transform-origin: 50% 32%;
}

.svg-container {
  position: relative;
  height: 100%;

  @media (--tablet) {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  @media (--desktop) {
    transform: none;
    margin-left: 0;
  }
}

.gridEnergy, .greenEnergy, .carPower, .car {
  position: absolute;
  display: flex;
}

.gridEnergy {
  top: 0;
  left: 0;
}

.greenEnergy {
  top: 53%;
  left: 0;
}

.carPower {
  top: 34%;
  left: 135px;
}

.car {
  top: 33%;
  left: 280px;
}
</style>
