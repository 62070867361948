<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="compute.options"
        :loading="props.loading"
        data-test-id="tableOrganizations"
      >
        <organizations-table-row
          v-for="organization in compute.organizationsParsed"
          :key="organization.id"
          :organization="organization"
          :data-test-id="`organizationElement${organization.name}`"
          @on-change-organization="methods.handleRedirectToDefaultRoute"
        />
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import OrganizationsTableRow from '@/components/tables/OrganizationsTableRow.vue'

import state from '@/state'
import { useI18n } from '@/hooks/useI18n.hook'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { Organization } from '@/core/organization'

const i18n = useI18n()
const router = useRouter()

interface PropsType {
  organizations: Organization[]
  loading: boolean,
  optionsTable: {
    stickyHead?: string
  }
}

const props = withDefaults(defineProps<PropsType>(), {
  organizations: () => [],
  loading: false,
  optionsTable: () => ({})
})

interface Data {
  options: TableProps['options']
}
const data = reactive<Data>({
  options: {
    offset: '0 4%',
    columns: [
      { name: i18n.t('mywb.common.organization'), align: 'left', minWidth: 300 },
      { name: i18n.t('mywb.common.owner'), align: 'left', minWidth: 200 },
      { name: i18n.t('mywb.common.role'), align: 'left', minWidth: 140 },
      { name: i18n.t('mywb.common.plan'), align: 'left', minWidth: 120 },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: 200, width: 300 }
    ]
  }
})

const compute = reactive({
  options: computed(() => ({ ...data.options, ...props.optionsTable })),

  organizationsParsed: computed(() => {
    return props.organizations
      .map(organization => ({
        ...organization
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  })
})

const methods = {
  async handleRedirectToDefaultRoute (organizationSelected: Organization) {
    state.organizations.setCurrentOrganization(organizationSelected.id)
    router.push({ name: state.organizations.defaultHomePage })
  }
}
</script>
