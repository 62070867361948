export function csvToArray (str: string, delimiter = /[,?;]+/g) {
  const strParsed = str.split(/[\n?\r]+/g)
  const headers = strParsed.shift()?.split(delimiter) ?? []
  const rows = strParsed

  return rows.map((row) => {
    const values = row.split(delimiter)
    const el = headers.reduce<Record<string, string>>((object, header, index) => {
      object[header] = values[index]
      return object
    }, {})
    return el
  })
}

export function arrayToCSV (arr: Array<Record<string, string>>) {
  const array = [Object.keys(arr[0]), ...arr]

  return array.map(item => {
    return Object.values(item).map(item => `"${item.toString()}"`).toString()
  }).join('\n')
}
