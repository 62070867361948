import { string, object, type InferType } from 'yup'

const ocppSettingsUpdateSchema = object({
  uid: string().required(),
  address: string().required(),
  chargePointIdentity: string().required(),
  password: string().required(),
  type: string<'wallbox' | 'ocpp'>().required()
})

export type OcppSettingsUpdate = InferType<typeof ocppSettingsUpdateSchema>
