<template>
  <dashboard-header
    :loading="data.loading"
    :locations="data.locations"
  />

  <enable-auto-refresh-component
    v-if="data.showActivateInterval"
    data-test-id="sharedEnableAutoRefresh"
    class="mb-16"
    @enable-auto-refresh="methods.enableAutoRefresh"
  />

  <update-chargers-notification />

  <div class="is-grid-3 g-16 mb-16">
    <chargers-overview-dashboard
      :loading="data.loading"
    />
  </div>

  <div class="is-grid-4 g-16 mb-16">
    <organization-card :loading="data.loading" />

    <charger-card :loading="data.loading" />

    <locations-card
      :loading="data.loading"
      :locations="data.locations"
    />

    <users-card :loading="data.loading" />
  </div>

  <active-chargers-table
    class="table"
    data-test-id="dashboardChargersActiveSessions"
    :organization="state.organizations.getCurrentOrganization"
    :has-real-time-information="!data.showActivateInterval"
  />

  <dashboard-header-date-intervals class="mt-24" />

  <infrastucture-performance-charts />
</template>

<script setup lang="ts">
import ChargersOverviewDashboard from '@/components/dashboard/ChargersOverviewDashboard.vue'
import ActiveChargersTable from '@/components/tables/ActiveChargersTable.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import UpdateChargersNotification from '@/components/UpdateChargersNotification.vue'
import DashboardHeader from '@/components/headers/DashboardHeader.vue'
import DashboardHeaderDateIntervals from '@/components/headers/DashboardHeaderDateIntervals.vue'
import InfrastucturePerformanceCharts from '@/components/dashboard/InfrastucturePerformanceCharts.vue'
import state from '@/state'
import { reactive, watch } from 'vue'
import ChargerCard from '@/components/dashboard/ChargerCard.vue'
import OrganizationCard from '@/components/dashboard/OrganizationCard.vue'
import LocationsCard from '@/components/dashboard/LocationsCard.vue'
import UsersCard from '@/components/dashboard/UsersCard.vue'
import { permissions } from '@/engine/clients'

import { provideChargersUsesCases } from '@/core/charger'
import { provideLocationUseCases, type Location } from '@/core/location'

const chargerUsesCases = provideChargersUsesCases()
const locationsUsesCases = provideLocationUseCases()

interface Data {
  locations: Location[]
  showActivateInterval: boolean
  loading: boolean
}

const data = reactive<Data>({
  showActivateInterval: false,
  loading: false,
  locations: []
})

const methods = {
  enableAutoRefresh () {
    data.showActivateInterval = false
  },

  async setGroup () {
    data.loading = true

    const chargers = await chargerUsesCases
      .getAllChargers(state.organizations.getCurrentOrganization.groupUid, { cache: 'stale' })
    state.charger.set('chargers', chargers)

    data.loading = false
  },

  async getLocations () {
    if (permissions.canSeeLocations) {
      data.locations = await locationsUsesCases
        .getAllLocations(state.organizations.getCurrentOrganization.groupUid, { getChargers: false, cache: 'network' })
    }
  }
}

const created = async () => {
  await Promise.all([
    methods.getLocations(),
    methods.setGroup()
  ])

  setTimeout(() => {
    data.showActivateInterval = true
  }, 300000)
}

created()

watch(() => state.organizations.getCurrentOrganization, () => {
  methods.getLocations()
})
</script>

<style lang="postcss" scoped>
.is-grid-3 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (--desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.is-grid-4 {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media (min-width: 700px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1350px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.table {
  grid-column: 1/ span 1;

  @media (--tablet) {
    grid-column: 1 / span 2;
  }

  @media (--widescreen) {
    grid-column: 1 / span 3;
  }
}
</style>
