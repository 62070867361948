<template>
  <div>
    <sessions-table
      :sessions="data.sessions"
      :loading="data.loading"
    />
  </div>
</template>

<script setup lang="ts">
import SessionsTable from '@/components/tables/SessionsTable.vue'
import state from '@/state'
import { reactive, watchEffect } from 'vue'
import { injectStrict, SESSION_USE_CASES } from '@/engine/injectors'
import type { Session } from '@/core/session'

const sessionUsesCases = injectStrict(SESSION_USE_CASES)

interface DataType {
  sessions: Session[],
  loading: boolean,
  sessionTime: string
}

const data: DataType = reactive({
  sessions: [],
  loading: true,
  sessionTime: ''
})

const clear = watchEffect(async () => {
  if (!state.charger.getChargerCore.id) return

  data.loading = true
  const aYearFromNow = new Date()

  aYearFromNow.setFullYear(aYearFromNow.getFullYear() - 1)

  const filters = [
    {
      field: 'start_time',
      operator: 'gte',
      value: Math.trunc(aYearFromNow.getTime() / 1000)
    },
    {
      field: 'charger_id',
      operator: 'eq',
      value: state.charger.getChargerCore.id
    }
  ]

  const result = await sessionUsesCases.getAllSessions(
    state.organizations.getCurrentOrganization.groupUid,
    {
      filters,
      limit: 9999,
      offset: 0,
      cache: 'network'
    }
  )

  data.sessions = result.data
  data.loading = false

  clear()
}, { flush: 'post' })
</script>
