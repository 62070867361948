<template>
  <wb-date-picker
    v-model="compute.filterDates"
    data-test-id="calendar"
    :label="i18n.t('mywb.common.dates')"
    :max-date="data.today"
    :year-range="[2015, data.today.getFullYear()]"
    :multi-calendars="mq.current !== 'mobile'"
    range
    :text-input="true"
    :locale="lang.__rootLanguage"
    :clearable="false"
    :auto-apply="false"
    :cancel-text="i18n.t('mywb.common.cancel')"
    :apply-text="i18n.t('mywb.common.apply')"
    @internal-model-change="methods.onInternalChange"
  >
    <template #input="{ value }">
      <shared-filter
        :model-value="value ? { label: value, id: 1 } : undefined"
        icon="calendar"
        :name="i18n.t('mywb.common.dates')"
        read-only
        :options="[{ label: value, id: 1 }]"
        @update:model-value="methods.resetDates"
        @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'dates' })"
      />
    </template>

    <template
      v-if="data.showNotification"
      #action-extra
    >
      <wb-alert icon="info" variant="info">
        {{ i18n.t('mywb.sessions.sessions-year-limit', [compute.minDateToNotificationParsed]) }}
      </wb-alert>
    </template>
  </wb-date-picker>

  <shared-filter
    v-model="compute.filterUser"
    with-search
    icon="person"
    :name="i18n.t('mywb.common.user')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.users') })"
    :empty-text="i18n.t('mywb.users.empty')"
    option-key="value"
    :options="data.option.users"
    :reduce="(item: SessionFilters['type'][0]) => item.value"
    data-test-id="usersFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'user' })"
  />

  <shared-filter
    v-model="compute.filterCharger"
    with-search
    icon="charger"
    :name="i18n.t('mywb.common.charger')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.chargers') })"
    :empty-text="i18n.t('mywb.chargers.empty')"
    option-key="value"
    :options="data.option.chargers"
    :reduce="(item: SessionFilters['type'][0]) => item.value"
    data-test-id="chargerFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'charger' })"
  />

  <shared-filter
    v-model="compute.filterLocation"
    with-search
    icon="location"
    :name="i18n.t('mywb.common.location')"
    :placeholder="i18n.t('mywb.common.search-by', { topic: i18n.t('mywb.common.locations') })"
    :empty-text="i18n.t('mywb.locations.empty')"
    option-key="value"
    :options="data.option.groups"
    :reduce="(item: SessionFilters['type'][0]) => item.value"
    data-test-id="locationFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'location' })"
  />

  <shared-filter
    v-if="permissions.showPayments"
    v-model="compute.filterType"
    icon="sessions"
    :name="i18n.t('mywb.common.type')"
    option-key="value"
    :options="data.option.type"
    :reduce="(item: SessionFilters['type'][0]) => item.value"
    data-test-id="paymentsFilter"
    @on-error="state.filters.resetFilterValue({ filter: 'sessionsFilters', key: 'type' })"
  />

  <wb-button
    variant="white"
    size="small"
    :label="i18n.t('mywb.common.clear-all')"
    @click="state.filters.resetFilters({ filter: 'sessionsFilters' })"
  />
</template>

<script setup lang="ts">
import state from '@/state'
import lang from '@/engine/lang'
import { dates } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { useMq } from 'vue3-mq'
import SharedFilter from '@/components/filters/SharedFilter.vue'
import { SESSION_USE_CASES, injectStrict } from '@/engine/injectors'
import type { SessionFilters } from '@/core/session'
import { permissions } from '@/engine/clients'

const sessionUsesCases = injectStrict(SESSION_USE_CASES)

const minDateToNotification = new Date('2/11/2022')

type DataType = {
  minDateToNotification: Date,
  showNotification: boolean,
  option: SessionFilters
  today: Date
}

const i18n = useI18n()
const mq = useMq()

const data: DataType = reactive({
  minDateToNotification,
  showNotification: false,
  option: {
    groups: [],
    chargers: [],
    users: [],
    type: []
  },
  today: new Date()
})

const compute = reactive({
  firstDatOfWeek: computed(() => dates.getFirstDayOfTheWeek(lang.__rootLanguage)),
  minDateToNotificationParsed: computed(() => {
    return data.minDateToNotification.toLocaleDateString(i18n.locale.value, {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    })
  }),

  filterDates: computed({
    get () {
      return state.filters.sessionsFilters?.dates
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'dates', value })
    }
  }),

  filterUser: computed({
    get () {
      return state.filters.sessionsFilters?.user
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'user', value })
    }
  }),

  filterCharger: computed({
    get () {
      return state.filters.sessionsFilters?.charger
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'charger', value })
    }
  }),

  filterLocation: computed({
    get () {
      return state.filters.sessionsFilters?.location
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'location', value })
    }
  }),

  filterType: computed({
    get () {
      return state.filters.sessionsFilters?.type
    },

    set (value) {
      state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'type', value })
    }
  })
})

const methods = {
  onInternalChange (dates: Array<Date | undefined> | null) {
    if (dates && dates[0]) {
      data.showNotification = minDateToNotification > dates[0]
    } else {
      data.showNotification = false
    }
  },

  async getSessionFilters () {
    data.option = await sessionUsesCases.getAllFilters(state.organizations.getCurrentOrganization.groupUid)
  },

  resetDates () {
    state.filters.setFilterValue({ filter: 'sessionsFilters', key: 'dates', value: [] })
  }
}

function created () {
  methods.getSessionFilters()
}

created()
</script>
