<template>
  <wb-modal
    width="48"
    class="charger-remote-actions"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.restart') }}

      <wb-badge
        v-if="props.chargers.length > 1"
        variant="green"
      >
        <span class="is-font-weight-500 mx-4">
          {{ props.chargers.length }}
        </span>
        {{ i18n.t('mywb.charger.charger-selected', props.chargers.length) }}
      </wb-badge>
    </template>

    <p class="is-size-400 has-white-space-normal">
      {{ i18n.t('mywb.common.restart.description') }}
    </p>

    <template #actions>
      <div class="actions">
        <div class="button-actions">
          <wb-button
            data-test-id="cancelButton"
            variant="white"
            outlined
            size="block"
            :label="i18n.t('mywb.common.cancel')"
            @click="emit('close')"
          />
          <wb-button
            data-test-id="confirmRestartButton"
            size="block"
            variant="primary"
            :label="i18n.t('mywb.common.restart')"
            :loading="data.loading"
            @click="methods.handleClickRemoteActions"
          />
        </div>
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useToast } from '@wallbox/toolkit-ui'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { Charger as ChargerCore } from '@/core/charger'

const i18n = useI18n()
const toast = useToast()
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

interface Props {
  chargers: ChargerCore[]
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

interface Data {
  loading: boolean
}

const data = reactive<Data>({
  loading: false
})

const methods = {
  async handleClickRemoteActions () {
    trackDataAction('restart-charger', { chargers: props.chargers })

    try {
      data.loading = true
      await chargerUseCases.sendUpdateOrRestartRemoteAction({
        chargers: props.chargers.map(charger => charger.id),
        action: REMOTE_ACTIONS.RESTART
      })
    } catch { toast.error(i18n.t('mywb.error.unexpected-error')) }

    data.loading = false
    emit('close')
  }
}
</script>
