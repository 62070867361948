<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="data.options"
        :loading="props.loading"
        data-test-id="billing-section"
      >
        <wb-table-row
          v-for="invoice in compute.invoicesAndFees"
          :key="invoice.id"
          :data-test-id="invoice.type === INVOICE_TYPE.FEE ? 'fee': 'invoice'"
        >
          <wb-table-row-item data-test-id="type">
            {{ invoice.typeText }}
          </wb-table-row-item>
          <wb-table-row-item data-test-id="amount">
            {{ invoice.amount }}
          </wb-table-row-item>
          <wb-table-row-item>
            <wb-badge
              data-test-id="label"
              :variant="invoice.retryPayment ? 'yellow': 'green'"
            >
              {{ invoice.retryPayment ? i18n.t('mywb.common.pending') : i18n.t('mywb.payments.paid') }}
            </wb-badge>
          </wb-table-row-item>
          <wb-table-row-item data-test-id="paidAt">
            {{ invoice.date }}
          </wb-table-row-item>
          <wb-table-row-item>
            <wb-button-groups>
              <wb-dropdown v-if="invoice.attachment" class="group-item">
                <template #activator>
                  <wb-button
                    variant="white"
                    outlined
                    size="small"
                    icon="visibility"
                    data-test-id="invoiceBtn"
                    @click="methods.openUrl(invoice.attachment)"
                  />
                </template>
                <template #tooltip>
                  {{ i18n.t('mywb.common.view') }}
                </template>
              </wb-dropdown>
              <wb-dropdown v-if="invoice.retryPayment" class="group-item">
                <template #activator>
                  <wb-button
                    variant="white"
                    outlined
                    size="small"
                    data-test-id="payBtn"
                    icon="refresh"
                    @click="methods.payInvoice(invoice.id)"
                  />
                </template>
                <template #tooltip>
                  {{ i18n.t('mywb.payments.retry-pay') }}
                </template>
              </wb-dropdown>
            </wb-button-groups>
          </wb-table-row-item>
        </wb-table-row>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { dates, numbers, useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TableProps } from '@wallbox/toolkit-ui'
import { INVOICE_STATUS, type FeeInvoice, type PlanInvoice, INVOICE_TYPE } from '@/core/invoices'
import { INVOICES_USE_CASES, injectStrict } from '@/engine/injectors'

const invoiceUseCases = injectStrict(INVOICES_USE_CASES)
const i18n = useI18n()
const toast = useToast()

interface Props {
  fees: FeeInvoice[]
  invoices: PlanInvoice[]
  loading?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'on-update-data'): void
}
const emit = defineEmits<Events>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: i18n.t('mywb.common.type'), align: 'left', minWidth: 200 },
      { name: i18n.t('mywb.common.total-amount'), align: 'left', minWidth: 200 },
      { name: i18n.t('mywb.common.status'), align: 'center', minWidth: 200 },
      { name: i18n.t('mywb.common.date'), align: 'left', minWidth: 200 },
      { name: i18n.t('mywb.common.actions'), align: 'left', minWidth: 200 }
    ],
    stickyHead: 'var(--header-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

interface TableData {
  id: number,
  type: INVOICE_TYPE
  typeText: string
  amount: string
  retryPayment: boolean
  date?: string
  attachment: string
  issueDate: number
}

const compute = reactive({
  feesParsed: computed((): TableData[] => {
    return props.fees.map(fee => {
      return {
        id: fee.id,
        type: fee.type,
        typeText: i18n.t('mywb.common.wallbox-fee'),
        amount: numbers.toLocaleCurrencySymbol(fee.total, fee.currencyCode, i18n.locale.value),
        date: dates.toDate(fee.issueDate, i18n.locale.value),
        attachment: fee.attachment,
        issueDate: fee.issueDate,
        retryPayment: false
      }
    })
  }),
  invoicesParsed: computed((): TableData[] => {
    return props.invoices.map(invoice => {
      const isNotPaid = invoice.status === INVOICE_STATUS.UNCOLLECTIBLE || invoice.status === INVOICE_STATUS.OPEN

      return {
        id: invoice.id,
        type: invoice.type,
        typeText: i18n.t(`mywb.common.plan-${invoice?.planName?.toLowerCase()}`),
        amount: numbers.toLocaleCurrencySymbol(invoice.total, invoice.currencyCode, i18n.locale.value),
        retryPayment: isNotPaid,
        date: dates.toDate(invoice.issueDate, i18n.locale.value),
        attachment: invoice.attachment,
        issueDate: invoice.issueDate
      }
    })
  }),

  invoicesAndFees: computed((): TableData[] => {
    return [...compute.feesParsed, ...compute.invoicesParsed]
      .sort((firstItem, secondItem) => (secondItem.issueDate - firstItem.issueDate))
  })
})

const methods = {
  async payInvoice (invoiceId: number) {
    try {
      await invoiceUseCases.payPlanInvoice(invoiceId)
      toast.success(i18n.t('mywb.payments.paid'))
      emit('on-update-data')
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  openUrl (url: string) {
    window.open(url, '_blank', 'noopener')
  }
}
</script>
