import yup from '@/hooks/useValidator.hook'

const updateSystemFormatSchema = yup.object({
  userUid: yup.string().required(),
  systemFormat: yup.string<'eu' | 'us'>().required()
})

export type UpdateSystemFormat = yup.InferType<typeof updateSystemFormatSchema>

export async function ensureUpdateSystemFormatIsValid (payload: any) {
  return await updateSystemFormatSchema.validate(payload, { abortEarly: false })
}
