import type { CurrencyCodes } from '@/core/international'

export function toTwoDecimalNumber (value: string) {
  const valueParsed = value.match(/(\d+([.,]\d{0,2})?|[.,]\d{0,2})/)?.[0] ?? ''
  return valueParsed.replace(/,/g, '.')
}

export function getNumberPart (partType = 'decimal', locale: string, currency = 'USD') {
  const value = 10000.01
  const valueParts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).formatToParts(value)

  const part = valueParts.find((part) => {
    return part.type === partType
  })?.value

  return part ?? '.'
}

export function toCurrencyWithUnits (number: number, currency: CurrencyCodes, locale: string) {
  const units: string[] = ['', 'K', 'M', 'B', 'T']
  const index = Math.floor(Math.log10(number) / 3)

  const unit = units[index] ?? ''

  const currencyFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const numberParsed = index <= 0 ? number : number / Math.pow(10, index * 3)

  const formattedNumber = currencyFormat.format(numberParsed)

  const symbol = getNumberPart('currency', locale, currency)

  return `${symbol}${formattedNumber}${unit}`
}
