import { createRouter, createWebHistory } from 'vue-router'

import hasAuth from './guards/hasAuth'
import hasAccess from './guards/hasAccess'

import LayoutView from '@/views/LayoutView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import DashboardView from '@/views/DashboardView.vue'
import SessionsView from '@/views/SessionsView.vue'
import UserSubscriptionConfirmView from '@/views/UserSubscriptionConfirmView.vue'
import AuthLoginView from '@/views/AuthLoginView.vue'
import AuthRegisterView from '@/views/AuthRegisterView.vue'
import AuthPasswordChangeView from '@/views/AuthPasswordChangeView.vue'
import AuthPasswordResetView from '@/views/AuthPasswordResetView.vue'
import AuthView from '@/views/AuthView.vue'

import ChargerRoutes from './charger.routes'
import OrganizationsRoutes from './organizations.routes'
import PaymentsRoutes from './payments.routes'
import UserRoutes from './user.routes'
import AccountRoutes from './account.routes'
import PlanRoutes from './plan.routes'
import locationRoutes from './location.routes'
import chargePointsRoutes from './chargePoints.routes'
import cancelPendingRequest from './guards/cancelPendingRequest'
import ROUTES from './routes'

export default function () {
  const router = createRouter({
    history: createWebHistory('/'),
    routes: [
      { path: '/', name: ROUTES.HOME, redirect: { name: ROUTES.CHARGERS } },
      {
        path: '/auth',
        name: ROUTES.AUTH,
        redirect: { name: ROUTES.AUTH_LOGIN },
        component: AuthView,
        children: [
          {
            path: 'login',
            name: ROUTES.AUTH_LOGIN,
            alias: '/login',
            component: AuthLoginView
          },
          {
            path: 'register',
            name: ROUTES.AUTH_REGISTER,
            alias: '/register',
            component: AuthRegisterView
          },
          {
            path: 'forgot-password',
            name: ROUTES.AUTH_FORGOT_PASSWORD,
            alias: '/forgot-password',
            component: AuthPasswordResetView
          },
          {
            path: 'password-change',
            name: ROUTES.AUTH_CHANGE_PASSWORD,
            alias: '/password-change',
            component: AuthPasswordChangeView
          }
        ]
      },

      { path: '/subscription', name: ROUTES.SUBSCRIPTION, component: UserSubscriptionConfirmView },
      {
        path: '/',
        component: LayoutView,
        meta: { requiresAuth: true },
        children: [
          ...OrganizationsRoutes,
          {
            path: 'dashboard',
            name: ROUTES.DASHBOARD,
            component: DashboardView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: ROUTES.DASHBOARD,
                text: 'mywb.common.dashboard'
              }
            }
          },
          ...ChargerRoutes,
          ...AccountRoutes,
          ...UserRoutes,
          ...PaymentsRoutes,
          ...PlanRoutes,
          ...locationRoutes,
          ...chargePointsRoutes(),
          {
            path: 'sessions',
            name: ROUTES.SESSIONS,
            component: SessionsView,
            meta: {
              resetBreadcrumb: true,
              breadcrumb: {
                name: ROUTES.SESSIONS,
                text: 'mywb.common.sessions'
              }
            }
          },
          { path: 'not-found', name: ROUTES.NOT_FOUND, component: NotFoundView },
          { path: '/:pathMatch(.*)*', redirect: ROUTES.NOT_FOUND }
        ]
      }
    ],

    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return { ...savedPosition }
      } else {
        return { top: 0, left: 0 }
      }
    }
  })

  router.beforeEach(cancelPendingRequest)
  router.beforeEach(hasAuth)
  router.beforeEach(hasAccess)

  return router
}
