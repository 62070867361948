<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="compute.options"
        :loading="props.loading"
        data-test-id="chargerSessionsTable"
        @on-pagination-change="emit('update:offset', $event)"
      >
        <sessions-table-row
          v-for="session in props.sessions"
          :key="session.id"
          :show-charger-column="props.showChargerColumn"
          :session="session"
        />
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import SessionsTableRow from '@/components/tables/SessionsTableRow.vue'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { Session } from '@/core/session'

const i18n = useI18n()

interface PropsType {
  offset?: number,
  sessions?: Session[],
  pagination?: {
    limit: number,
    total: number,
    offset: number,
    multiPage: boolean
  }
  loading?: boolean,
  showChargerColumn?: boolean,
  stickyHeaderPosition?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  offset: 0,
  sessions: undefined,
  pagination: undefined,
  stickyHeaderPosition: 'var(--header-height)'
})

type Events = {
  (e: 'update:offset', offset: number): void
}
const emit = defineEmits<Events>()

const compute = reactive({
  options: computed(() => {
    return {
      offset: '0 16px',
      columns: ([
        {
          name: i18n.t('mywb.common.id'),
          align: 'left',
          minWidth: 110,
          width: 110
        },
        {
          name: i18n.t('mywb.common.date'),
          align: 'left',
          minWidth: 150,
          width: 'fit'
        },
        {
          name: i18n.t('mywb.common.user'),
          align: 'left',
          minWidth: 200,
          width: 'fit'
        },
        {
          name: i18n.t('mywb.common.charger'),
          align: 'left',
          hidden: !props.showChargerColumn
        },
        {
          name: i18n.t('mywb.common.energy'),
          align: 'left',
          minWidth: 150
        },
        {
          name: i18n.t('mywb.common.type'),
          align: 'left',
          width: 'fit',
          hidden: !permissions.showPayments
        },
        {
          name: i18n.t('mywb.common.total-cost'),
          align: 'left',
          minWidth: 100,
          width: 'fit'
        },
        {
          name: i18n.t('mywb.common.payments'),
          align: 'left',
          width: 'fit',
          hidden: !permissions.showPayments
        }
      ] as TableProps['options']['columns']),
      stickyHead: props.stickyHeaderPosition,
      resizable: true,
      pagination: props.pagination,
      empty: {
        icon: 'table_rows',
        title: i18n.t('mywb.common.table-empty')
      }
    }
  })
})
</script>
