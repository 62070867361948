<template>
  <wb-card
    :headline="i18n.t('mywb.common.users-role.title')"
    :loading="data.loading"
    v-bind="$attrs"
  >
    <template #content>
      <wb-card
        :headline="i18n.t(getRoleById(data.profile).key)"
        :subhead="i18n.t(getRoleById(data.profile).keyDescription)"
        filled
      >
        <template #place-upper-right>
          <wb-button
            v-if="!compute.isDisabled"
            variant="white"
            outlined
            class="mt-16"
            :label="i18n.t('mywb.common.change')"
            data-test-id="changeUserTypeBtn"
            @click="data.isModalUserTypeOpen = true"
          />
        </template>
      </wb-card>
    </template>
  </wb-card>

  <user-type-modal
    v-if="data.isModalUserTypeOpen"
    v-model="data.profile"
    :user="props.user"
    :access-config="props.accessConfig"
    @on-close="data.isModalUserTypeOpen = false"
  />
</template>

<script setup lang="ts">
import UserTypeModal from '@/components/modals/UserTypeModal.vue'
import state from '@/state'
import { hasRoleGreaterThan } from '@/utilities/users'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { getRoleById, EnumRoles } from '@/utilities/user-roles'
import type { UserDetail } from '@/core/user'
import type { AccessConfigForUser } from '@/core/accessConfig'

const i18n = useI18n()

interface Props {
  user: UserDetail
  accessConfig: AccessConfigForUser
}
const props = defineProps<Props>()

interface Data {
  profile: EnumRoles
  loading: boolean
  isModalUserTypeOpen: boolean
}

const data = reactive<Data>({
  profile: EnumRoles.user,
  loading: false,
  isModalUserTypeOpen: false
})

const compute = reactive({
  isDisabled: computed(() => !(hasRoleGreaterThan(props.user.email) ||
    state.user.userLogged.email === state.organizations.getCurrentOrganization.ownerEmail))
})

async function created () {
  data.profile = props.accessConfig.profile
}

created()
</script>
