<template>
  <wb-modal
    width="33"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.power-limit') }}
    </template>

    <p class="is-size-400 mb-24">
      {{ i18n.t('mywb.common.power-limit.description') }}
    </p>

    <p
      v-t="i18n.t('mywb.common.current-per-phase', [field.maxAmps])"
      class="is-size-400 mb-32"
    />

    <wb-range-slider
      v-model="field.maxAmps"
      name="currentAmps"
      :min="state.charger.getChargerCore.minAmps"
      :max="state.charger.getChargerCore.maxAmps"
      :tooltip-formatter="methods.ampsParsed"
    />

    <div class="is-flex-elements mt-24">
      <div class="is-size-300 is-font-weight-500">
        {{ state.charger.getChargerCore.minAmps }}{{ i18n.t('mywb.charger.amps') }}
      </div>
      <div class="is-size-300 is-font-weight-500">
        {{ state.charger.getChargerCore.maxAmps }}{{ i18n.t('mywb.charger.amps') }}
      </div>
    </div>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          size="block"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />

        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="i18n.t('mywb.common.set-limit')"
          :loading="data.loading"
          @click="validate(methods.editMaxCurrent)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useValidator } from '@/hooks/useValidator.hook'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { ensureUpdateChargerMaxAmpsIsValid, type ChargerUpdate } from '@/core/charger'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const { validateTo, validate } = useValidator()

interface Events {
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const field = reactive<ChargerUpdate>({
  uid: state.charger.getChargerCore.uid,
  maxAmps: state.charger.getChargerCore.maxAvailableAmps
})

validateTo(async () => !!await ensureUpdateChargerMaxAmpsIsValid(field, {
  min: state.charger.getChargerCore.minAmps,
  max: state.charger.getChargerCore.maxAmps
}))

interface DataType {
  loading: boolean
}

const data: DataType = reactive({
  loading: false
})

const methods = {
  ampsParsed (value: string | number) {
    return value + i18n.t('mywb.charger.amps')
  },

  async editMaxCurrent  () {
    data.loading = true
    try {
      chargerUseCases.updateCharger(field)

      const charger = await chargerUseCases.getCharger(
        state.charger.getChargerCore.uid,
        state.organizations.getCurrentOrganization.groupUid,
        {
          cache: 'network'
        }
      )
      charger && state.charger.setCharger(charger)

      trackDataEvent('update-amp-charger', { 'max-charging-current': field.maxAmps })
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      emit('on-close')
    }
  }
}
</script>

<style lang="postcss" scoped>
.is-flex-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
