<template>
  <shared-teleport-header class="location-header">
    <template #title>
      {{ i18n.t('mywb.common.locations') }}
    </template>

    <template #actions>
      <wb-button
        v-if="permissions.canSeeLocations"
        id="add-location"
        icon="add"
        data-test-id="addLocationBtn"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.charger.add-location') : ''"
        variant="primary"
        @click="emit('on-add-group')"
      />
    </template>

    <template #subheader />

    <template #filters>
      <locations-filters
        :locations="props.locations"
      />
    </template>

    <template #secondary-actions>
      <wb-button
        variant="white"
        :label="!compute.showMap ? i18n.t('mywb.locations.show-map') : i18n.t('mywb.locations.hide-map')"
        size="small"
        :icon="!compute.showMap ? 'visibility' : 'visibility_off'"
        outlined
        @click="compute.showMap = !compute.showMap"
      />
    </template>
  </shared-teleport-header>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import LocationsFilters from '@/components/filters/LocationsFilters.vue'
import type { Location } from '@/core/location'

const i18n = useI18n()
const mq = useMq()

interface Events {
  (e: 'on-add-group'): void
  (e :'update:showMap', show: boolean): void
}

const emit = defineEmits<Events>()

interface PropsType {
  showMap: boolean
  locations: Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  showMap: true,
  locations: () => []
})

const compute = reactive({
  showMap: computed({
    get () {
      return props.showMap
    },
    set (value) {
      localStorage.setItem('wb-show-locations-map', value.toString())
      emit('update:showMap', value)
    }
  })
})
</script>
