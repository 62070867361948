<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.users-access.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.users-access.description') }}
    </div>

    <div class="is-grid">
      <wb-radio-card
        v-model="compute.invitationToAccessGroup"
        :value="1"
        class="mb-16"
        :disabled="permissions.isDisabledSelectAccessGroup"
        :error="data.error"
        @click="data.error = false"
      >
        <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="accessExistingAccess">
          {{ i18n.t('mywb.common.access-group') }}
          <wb-badge
            v-if="permissions.showUpSellingToPlanBusiness"
            inverted
            variant="grey"
            icon="lock"
            class="has-position-relative"
          >
            {{ i18n.t('mywb.plans.business') }}
          </wb-badge>
        </p>
        <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
          {{ i18n.t('mywb.common.what-is-access-group.description') }}
        </p>
      </wb-radio-card>

      <wb-radio-card
        v-model="compute.invitationToAccessGroup"
        :value="0"
        :error="data.error"
        @click="data.error = false"
      >
        <p class="is-size-400 has-text-grey-900 is-font-weight-500" data-test-id="assignNewAccess">
          {{ i18n.t('mywb.common.custom-access') }}
        </p>
        <p class="is-size-300 has-text-grey-900" data-test-id="commercialYesRadioBtn">
          {{ i18n.t('mywb.common.users-access.assign-new-access-config.subtitle') }}
        </p>
      </wb-radio-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Events {
  (e: 'update:modelValue', model: number): void
}
const emit = defineEmits<Events>()

const data = reactive({
  error: false
})

interface Props {
  modelValue: number
}

const props = defineProps<Props>()

const compute = reactive({
  invitationToAccessGroup: computed({
    get () {
      return props.modelValue
    },
    set (value) {
      emit('update:modelValue', value)
    }
  })
})

const methods = {
  isValidateForm () {
    data.error = props.modelValue === -1
    return !data.error
  }
}

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style lang="postcss" scoped>
.is-grid {
  display: grid;
  grid-template-columns: auto;
  gap: 16px;

  @media (--desktop) {
    grid-template-columns: auto auto;
  }

  & .radio:first-child {
    height: 100%;
  }
}
</style>
