<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        data-test-id="ratesSection"
        :options="data.options"
        :loading="props.loading"
      >
        <wb-table-row
          v-for="rate in compute.ratesParsed"
          :key="rate.id"
        >
          <wb-table-row-item data-test-id="rateName">
            <p class="is-font-weight-500" data-test-id="rateNameContent">
              {{ rate.name }}
            </p>
          </wb-table-row-item>

          <wb-table-row-item data-test-id="fixedFee">
            {{ rate.fixedFeeParsed }}
          </wb-table-row-item>

          <wb-table-row-item data-test-id="variableType">
            <div class="is-flex">
              <span v-if="rate.iconVariableType" class="wb-icons is-size-500 mr-4">
                {{ rate.iconVariableType }}
              </span>
              {{ rate.variableTypeParsed }}
            </div>
          </wb-table-row-item>

          <wb-table-row-item data-test-id="variablePrice">
            {{ rate.variableFeePriceParsed }}
          </wb-table-row-item>

          <wb-table-row-item>
            <wb-badge
              data-test-id="assigned"
              :variant="rate.assigned ? 'green' : 'yellow'"
            >
              {{ rate.assigned ? i18n.t('mywb.common.yes') : i18n.t('mywb.common.no') }}
            </wb-badge>
          </wb-table-row-item>

          <wb-table-row-item>
            <wb-button-groups>
              <wb-dropdown class="group-item">
                <template #activator>
                  <wb-button
                    data-test-id="editRateBtn"
                    variant="white"
                    outlined
                    icon="edit"
                    size="small"
                    :to="{ name: ROUTES.PAYMENTS_EDIT_RATE, params: { rateId: rate.id }}"
                    @click="methods.updateRate(rate)"
                  />
                </template>
                <template #tooltip>
                  {{ i18n.t('mywb.common.edit') }}
                </template>
              </wb-dropdown>
              <wb-dropdown class="group-item">
                <template #activator>
                  <wb-button
                    data-test-id="deleteRateBtn"
                    variant="danger"
                    icon="delete"
                    outlined
                    size="small"
                    class="group-item"
                    @click="methods.deleteRate(rate)"
                  />
                </template>
                <template #tooltip>
                  {{ i18n.t('mywb.common.delete') }}
                </template>
              </wb-dropdown>
            </wb-button-groups>
          </wb-table-row-item>
        </wb-table-row>

        <template #empty-table-action>
          <wb-button
            :label="i18n.t('mywb.common.create-rate')"
            variant="white"
            outlined
            :to="{ name: ROUTES.PAYMENTS_CREATE_RATE }"
          />
        </template>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { numbers, type TableProps } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import type { Rate, RateWithAssigned } from '@/core/rate'
import state from '@/state'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

interface Events {
  (e: 'on-delete-rate', rate: Rate): void
}

const emit = defineEmits<Events>()

interface Props {
  rates: RateWithAssigned[]
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  rates: () => []
})

const data = reactive({
  options: {
    offset: '0 9%',
    stickyHead: 'var(--header-height)',
    columns: [
      {
        key: 'name',
        name: i18n.t('mywb.common.name'),
        align: 'left',
        minWidth: 200
      },
      {
        key: 'fixed-fee',
        name: i18n.t('mywb.common.fixed-fee'),
        minWidth: 200,
        align: 'left'
      },
      {
        key: 'variable-fee-type',
        name: i18n.t('mywb.rates.variable-fee-type'),
        minWidth: 150,
        align: 'left'
      },
      {
        key: 'variable-fee-price',
        name: i18n.t('mywb.rates.variable-fee-price'),
        minWidth: 150,
        align: 'left'
      },
      {
        key: 'assgined',
        name: i18n.t('mywb.common.assigned'),
        align: 'left'
      },
      {
        key: '',
        name: i18n.t('mywb.common.actions'),
        align: 'left',
        minWidth: 240
      }
    ] as TableProps['options']['columns'],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

const compute = reactive({
  ratesParsed: computed(() => {
    return props.rates.map(rate => ({
      ...rate,
      fixedFeeParsed: methods.getFixedFee(rate),
      variableTypeParsed: methods.getVariableType(rate),
      iconVariableType: methods.getIconVariableType(rate),
      variableFeePriceParsed: methods.getVariableFee(rate)
    }))
  })
})

const methods = {
  updateRate (rate: RateWithAssigned) {
    trackDataAction('edit-rate', {
      rate_name: rate.name,
      rate_fixed_access_fee: rate.fixedFee,
      rate_variable_fee: rate.variableFeePrice,
      rate_variable_type: rate.variableFeeType
    })
  },

  deleteRate (rate: RateWithAssigned) {
    emit('on-delete-rate', rate)
  },

  getFixedFee (rate: RateWithAssigned) {
    if (rate.fixedFee === 0) return '--'
    return numbers.toLocaleCurrencySymbol(
      rate.fixedFee,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
  },

  getVariableType (rate: RateWithAssigned) {
    if (rate.variableFeeType === 'access') return '--'

    const types = {
      time: i18n.t('mywb.common.charging-time.fee'),
      energy: i18n.t('mywb.common.consumed-energy.fee')
    }
    return types[rate.variableFeeType]
  },

  getIconVariableType (rate: RateWithAssigned) {
    if (rate.variableFeeType === 'access') return ''

    const types = {
      time: 'clock',
      energy: 'bolt_circle'
    }
    return types[rate.variableFeeType]
  },

  getVariableFee (rate: RateWithAssigned) {
    if (rate.variableFeeType === 'access') return '--'

    const feeAmount = rate.variableFeePrice ?? 0
    let postfix = ''
    if (rate.variableFeeType === 'time') {
      postfix = ` / ${i18n.t('mywb.common.hour')}`
    } else if (rate.variableFeeType === 'energy') {
      postfix = ` / ${i18n.t('mywb.common.kwh')}`
    }

    const fee = numbers.toLocaleCurrencySymbol(
      feeAmount,
      state.organizations.getCurrentOrganization.currencyCode,
      i18n.locale.value
    )
    return fee + postfix
  }
}
</script>
