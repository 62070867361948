<template>
  <wb-slide>
    <div class="slide">
      <p
        class="title pl-64 mb-16"
        data-swiper-parallax="-600"
      >
        {{ props.title }}
      </p>
      <p
        class="subtitle is-size-900 pl-64 is-font-weight-400"
        data-swiper-parallax="-900"
      >
        {{ props.subtitle }}
      </p>

      <picture class="img">
        <slot />
      </picture>
    </div>
  </wb-slide>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string
  subtitle: string
}>()
</script>

<style lang="postcss" scoped>
.slide {
  width: 100%;
  height: 100%;
  position: relative;
  color: var(--white);
  display: grid;
  grid-template-rows: auto min-content min-content 150px;
  grid-template-columns: 2fr 1fr;
  overflow: hidden;

  & .title {
    font-size: 5.6rem;
    line-height: 1.2em;
    grid-column: 1;
    grid-row: 2;
  }

  & .subtitle {
    grid-column: 1;
    grid-row: 3;
  }

  & .img {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    grid-column: 1 / span 2;
    grid-row: 1 / span 4;
    filter: brightness(70%);
    z-index: -1;
  }

  & :slotted(img) {
    object-position: left bottom;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
