<template>
  <div>
    <rates-header
      :loading="data.loading"
    />

    <rates-table
      :rates="compute.ratesFiltered"
      :loading="data.loading"
      @on-delete-rate="methods.handleDeleteRate"
    />

    <rate-delete-modal
      v-if="data.isModalPaymentsDeleteRateOpen && data.deleteRateCandidate"
      data-test-id="deleteRateModal"
      :rate="data.deleteRateCandidate"
      @close="data.isModalPaymentsDeleteRateOpen = false"
      @on-delete-rate="methods.removeRateFromList"
    />
  </div>
</template>

<script setup lang="ts">
import state from '@/state'
import RateDeleteModal from '@/components/modals/RateDeleteModal.vue'
import RatesTable from '@/components/tables/RatesTable.vue'
import RatesHeader from '@/components/headers/RatesHeader.vue'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { reactive, computed } from 'vue'
import type { Rate, RateWithAssigned } from '@/core/rate'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'

const rateUseCases = injectStrict(RATE_USE_CASES)

interface Data {
  loading: boolean
  rates: Rate[]
  assignedRates: RateWithAssigned[]
  deleteRateCandidate?: RateWithAssigned
  isModalPaymentsDeleteRateOpen: boolean
}
const data = reactive<Data>({
  loading: false,
  rates: [],
  assignedRates: [],
  isModalPaymentsDeleteRateOpen: false
})

const compute = reactive({
  ratesWithAssignation: computed((): RateWithAssigned[] => data.assignedRates),

  ratesFiltered: computed((): RateWithAssigned[] => {
    const reducers = {
      text: methods.filterByText,
      fixedFee: methods.filterByFixedFee,
      variableType: methods.filterByVariableType,
      assigned: methods.filterByAssigned
    }
    const entries = Object.entries(state.filters.ratesFilters) as
      Array<[keyof typeof reducers, string & string[] & boolean[]]>

    return entries.reduce((ratesFiltered, [type, value]) =>
      reducers[type](ratesFiltered, value), compute.ratesWithAssignation
    )
  })
})

const methods = {
  filterByText (rates: RateWithAssigned[], text: string) {
    if (!text) return rates

    return rates.filter(rate => {
      return (
        rate.name.toLowerCase().includes(text.toLowerCase())
      )
    })
  },

  filterByFixedFee (rates: RateWithAssigned[], fixedFee: boolean[]) {
    if (!fixedFee?.length) return rates

    return rates.filter(rate => fixedFee.includes(rate.fixedFee > 0))
  },

  filterByVariableType (rates: RateWithAssigned[], variableTypes: string[]) {
    if (!variableTypes?.length) return rates

    return rates.filter(rate => variableTypes.includes(rate.variableFeeType))
  },

  filterByAssigned (rates: RateWithAssigned[], assigned: boolean[]) {
    if (!assigned?.length) return rates

    return rates.filter(rate => assigned.includes(rate.assigned))
  },

  removeRateFromList (rate: RateWithAssigned) {
    data.rates = data.rates.filter(({ id }) => id !== rate.id)
    data.assignedRates = data.assignedRates.filter(({ id }) => id !== rate.id)
    data.isModalPaymentsDeleteRateOpen = false
  },

  handleDeleteRate (rate: RateWithAssigned) {
    data.deleteRateCandidate = rate
    data.isModalPaymentsDeleteRateOpen = true
  }
}

async function created () {
  data.loading = true
  const result = await rateUseCases.getAllRates({
    groupUid: state.organizations.getCurrentOrganization.groupUid
  })

  data.rates = result.data

  const assigned = await Promise.all(
    data.rates.map(async (rate) => {
      return {
        ...rate,
        assigned: await rateUseCases.getIsRateAssigned(state.organizations.getCurrentOrganization.groupUid, rate.id)
      }
    })
  )

  data.assignedRates = assigned
  data.loading = false

  trackDataScreen('rates')
}

created()
</script>
