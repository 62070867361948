import {
  wapiSessionRepository,
  getAll,
  downloadCSV,
  downloadXLSX,
  getAllFilters
} from '@/core/session'

export function provideSessionUseCases () {
  const repo = wapiSessionRepository()

  return {
    getAllSessions: getAll(repo),
    downloadCSV: downloadCSV(repo),
    downloadXLSX: downloadXLSX(repo),
    getAllFilters: getAllFilters(repo)
  }
}
