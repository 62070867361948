<template>
  <wb-modal
    width="45"
    @close="emit('on-close')"
  >
    <template v-if="isCurrentState('createOrganization')" #title>
      {{ i18n.t('mywb.common.create-organization') }}
    </template>

    <template v-if="isCurrentState('createOrganization')">
      <p
        v-t="'mywb.common.create-organization-description'"
        class="is-size-300 mb-16"
      />

      <wb-form>
        <wb-input
          v-model="field.name"
          data-test-id="inputName"
          name="name"
          type="text"
          :label="i18n.t('mywb.common.name')"
          :error="errors.name"
        />

        <wb-select
          v-model="field.countryCode"
          name="country"
          uid="_country"
          data-test-id="countrySelect"
          :label="i18n.t('mywb.common.country')"
          option-label="name"
          :reduce="(country: Country) => country.iso2"
          :options="state.global.getCountries"
          :error="errors.countryCode"
        >
          <template #label>
            <span>{{ i18n.t('mywb.common.country') }}</span>
          </template>
        </wb-select>

        <organization-type-select
          v-model="field.organizationType"
          data-test-id="organizationType"
          :error="errors.organizationType"
        />
      </wb-form>
    </template>

    <div
      v-if="isCurrentState('addChargersOrganization')"
      class="is-centered"
    >
      <client-img-component
        inline
        src="car_charging"
        class="has-margin-auto has-offset"
        width="140"
      />

      <div class="is-size-700 is-font-weight-500">
        {{ i18n.t('mywb.common.start-adding-chargers') }}
      </div>
      <div class="is-size-400">
        {{ i18n.t('mywb.common.add-chargers-into-new-organization') }}
        <div class="mt-16 is-font-weight-500">
          {{ field.name }}
        </div>
      </div>
    </div>

    <template #actions>
      <wb-button
        v-if="isCurrentState('createOrganization')"
        data-test-id="createOrganizationBtn"
        size="block"
        :loading="data.loading"
        :label="i18n.t('mywb.common.create-organization')"
        @click="validate(methods.createOrganization)"
      />

      <wb-button
        v-if="isCurrentState('addChargersOrganization')"
        data-test-id="addChargersOrganizationBtn"
        size="block"
        :label="i18n.t('mywb.common.add-chargers')"
        @click="methods.handleRedirectToAddChargersRoute"
      />

      <wb-button
        v-if="isCurrentState('addChargersOrganization')"
        data-test-id="addChargersLaterOrganizationBtn"
        size="block"
        class="mt-16"
        :label="i18n.t('mywb.common.maybe-later')"
        variant="white"
        @click="methods.handleRedirectToDefaultRoute"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import ClientImgComponent from '@/components/ClientImgComponent.vue'

import state from '@/state'
import { reactive } from 'vue'
import { useStateMachine } from '@wallbox/hooks'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useToast, promises } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRouter } from 'vue-router'
import { useValidator } from '@/hooks/useValidator.hook'
import OrganizationTypeSelect from '@/components/forms/OrganizationTypeSelect.vue'
import { ensureCreateOrganizationIsValid, type Organization, type OrganizationCreate } from '@/core/organization'
import { injectStrict, ORGANIZATION_USE_CASES } from '@/engine/injectors'
import type { Country } from '@/core/international'
import ROUTES from '@/engine/router/routes'

const toast = useToast()
const i18n = useI18n()
const router = useRouter()
const { errors, validate, validateTo } = useValidator()

const organizationUsesCases = injectStrict(ORGANIZATION_USE_CASES)

interface PropsType {
  organizationsCount: number
}

const props = withDefaults(defineProps<PropsType>(), {
  organizationsCount: 0
})

const { isCurrentState, send } = useStateMachine({
  initial: 'createOrganization',
  states: {
    createOrganization: {
      transitions: {
        addChargersOrganization: 'addChargersOrganization'
      }
    },
    addChargersOrganization: {
      transitions: {
        back: 'createOrganization'
      }
    }
  }
})

interface Data {
  loading: boolean
  organizations: Organization[]
}

const data: Data = reactive({
  loading: false,
  organizations: []
})

const field = reactive<Partial<OrganizationCreate>>({})

validateTo(async () => !!await ensureCreateOrganizationIsValid(field))

interface Events {
  (e: 'on-create'): void,
  (e: 'on-close'): void,
}
const emit = defineEmits<Events>()

const methods = {
  async createOrganization () {
    if (!field.organizationType || !field.countryCode || !field.name) return

    data.loading = true

    try {
      await organizationUsesCases.createOrganization({
        name: field.name,
        organizationType: field.organizationType,
        countryCode: field.countryCode
      })

      trackDataAction('organizations-created', {
        name: field.name,
        type: field.organizationType
      })

      await promises.polling(async () => {
        data.organizations = await organizationUsesCases.getAllOrganizations()
      }, {
        validate: () =>
          data.organizations.length > props.organizationsCount,
        max: 10,
        delta: 2,
        timeout: 1000
      })

      state.organizations.setOrganizations(data.organizations)
      toast.success(i18n.t('mywb.common.new-organization-created'))
      send('addChargersOrganization')
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  },

  async setGroupRelatedToOrganization () {
    const organizationSelected = data.organizations.at(data.organizations.length - 1) as Organization
    state.organizations.setCurrentOrganization(organizationSelected.id)
  },

  handleRedirectToAddChargersRoute () {
    methods.setGroupRelatedToOrganization()
    router.push({ name: ROUTES.CHARGERS, query: { 'add-chargers': 1 } })
  },

  handleRedirectToDefaultRoute () {
    methods.setGroupRelatedToOrganization()
    router.push({ name: state.organizations.defaultHomePage })
  }
}

function created () {
  trackDataScreen('organizations-create-organization')
}

created()
</script>

<style lang="postcss" scoped>
.is-centered {
  text-align: center;
}
</style>
