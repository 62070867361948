enum ROUTES {
  HOME = '/',
  AUTH = 'auth',
  AUTH_LOGIN = 'login',
  AUTH_REGISTER = 'register',
  AUTH_FORGOT_PASSWORD = 'forgot-password',
  AUTH_CHANGE_PASSWORD = 'change-password',

  ORGANIZATIONS = 'organizations',
  ORGANIZATION_DETAIL = 'organizationDetail',

  DASHBOARD = 'dashboard',

  CHARGERS = 'chargers',

  CHARGER = 'charger',
  CHARGER_DETAIL_USERS = 'charger-users',
  CHARGER_DETAIL_SESSIONS = 'charger-sessions',
  CHARGER_DETAIL_SETTINGS = 'charger-settings',

  CHARGE_POINTS = 'charge-points',

  LOCATIONS = 'locations',
  LOCATION_DETAIL = 'location-detail',

  SESSIONS = 'sessions',

  USERS = 'users',
  USER_DETAIL = 'user',

  USERS_ACCESS_GROUPS = 'access-configs-groups',

  PAYMENTS = 'payments',
  PAYMENTS_RATES = 'payments-rates',
  PAYMENTS_INVOICES = 'payments-invoices',
  PAYMENTS_CONFIGURATION = 'payments-configuration',
  PAYMENTS_CREATE_RATE = 'payments-new-rates-create',
  PAYMENTS_EDIT_RATE = 'payments-new-rates-edit',

  PLAN_BUSINESS = 'plan-business',

  ACCOUNT = 'account',
  ACCOUNT_PAYMENT_HISTORY = 'payment-history',

  SUBSCRIPTION = 'subscription',

  NOT_FOUND = 'not-found'
}

export default ROUTES
