<template>
  <wb-modal
    v-if="data.activationSuccess"
    data-test-id="confirmModal"
    class="has-text-centered"
    width="33"
    @close="emit('close')"
  >
    <wb-check-icon />
    <p class="is-size-700 has-text-primary-500 mb-32">
      {{ i18n.t('mywb.auth.active-account') }}
    </p>

    <template #actions>
      <wb-button
        size="block"
        data-test-id="confirmBtnModal"
        :label="i18n.t('mywb.common.done')"
        @click="emit('close')"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import { trackEvent } from '@wallbox/metrics'
import { getServerError } from '@/utilities/errorMessages'
import { HttpError } from '@wallbox/http'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'

const authUseCases = injectStrict(AUTH_USE_CASES)
const route = useRoute()
const toast = useToast()
const i18n = useI18n()

interface Events {
  (e: 'close'): void
}

const emit = defineEmits<Events>()

const data = reactive({
  activationSuccess: false
})

onMounted(async () => {
  try {
    await authUseCases.doActivateUser({
      email: route.query.email?.toString() ?? '',
      activationCode: route.query.confirmToken?.toString() ?? ''
    })
    data.activationSuccess = true
    trackEvent('activation-user')
  } catch (error) {
    if (error instanceof HttpError) {
      if (error.status === 400) {
        toast.error(i18n.t('mywb.error.activation-code-expired'))
      } else {
        toast.error(getServerError(error))
      }
    } else {
      throw error
    }
  }
})
</script>
