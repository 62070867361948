interface env {
  name: string
  client: string
  api: string
  authSystem: 'local' | 'cookie'
  userApi: string
  ocppPocApi: string
  perseusApi: string
  timezoneApi: string
  env: 'production' | 'preproduction' | 'preview'
  mixpanel: {
    token?: string
    active: boolean
  }
  web: string[]
  featureFlags: {
    baseURL?: string
    active: boolean
  }
  oauth: {
    google?: string
    apple?: string
  }
  datadog: {
    active: boolean
    applicationId?: string
    clientToken?: string
    env?: 'development' | 'production'
  }

  pwa: {
    active: boolean
  }

  deepLinks: Record<string, string>

  mavenoid: {
    clientId: string
    productId: number
  }
}

const envs: Record<string, env> = {
  default: {
    name: 'default',
    client: 'wallbox',
    authSystem: 'local',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api-pre.wall-box.com',
    ocppPocApi: 'api-proxy.preprod.wall-box.com/perseus',
    perseusApi: 'api-pre.wall-box.com/perseus',
    timezoneApi: 'my.wallbox.com',
    env: 'preview',
    mixpanel: {
      token: 'bddf6d1925ad08fd68bf304f97e6761d',
      active: false
    },
    web: [],
    featureFlags: {
      baseURL: 'https://wallbox-portal.pages.dev/feature-flag',
      active: true
    },
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },
    datadog: {
      active: false
    },
    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },
    mavenoid: {
      clientId: '26012d988dee2116f068',
      productId: 3608150
    }
  },

  'wallbox-iberdrola-pre': {
    name: 'Iberdrola pre',
    client: 'iberdrola',
    authSystem: 'cookie',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api-pre.wall-box.com',
    ocppPocApi: 'api-proxy.preprod.wall-box.com/perseus',
    perseusApi: 'api-pre.wall-box.com/perseus',
    timezoneApi: 'iberdrola-pre.wallbox.com',
    env: 'preproduction',
    web: [
      'iberdrola-pre.wallbox.com'
    ],
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },
    datadog: {
      active: false
    },
    pwa: {
      active: false
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },
    mixpanel: {
      token: '',
      active: false
    },
    featureFlags: {
      baseURL: 'https://my.wallbox.com/feature-flag',
      active: false
    },
    mavenoid: {
      clientId: '26012d988dee2116f068',
      productId: 3608150
    }
  },

  'wallbox-production': {
    name: 'Wallbox production',
    client: 'wallbox',
    authSystem: 'cookie',
    api: 'api.wall-box.com',
    userApi: 'user-api.wall-box.com',
    ocppPocApi: 'api-proxy.preprod.wall-box.com/perseus',
    perseusApi: 'api.wall-box.com/perseus',
    timezoneApi: 'my.wallbox.com',
    env: 'production',
    web: [
      'my.wallbox.com',
      'portal.wallbox.com'
    ],
    mixpanel: {
      active: true,
      token: 'cbda0f738b2f35be8d06fe7170a60503'
    },
    oauth: {
      google: '324006994476-pr4jjbohohcm536944lkk0117peqjvou.apps.googleusercontent.com',
      apple: 'com.wallbox.prod'
    },
    featureFlags: {
      baseURL: 'https://my.wallbox.com/feature-flag',
      active: true
    },
    datadog: {
      active: true,
      applicationId: '92d8bedc-e714-450b-bee7-9bac0fe83b1d',
      clientToken: 'pubec814f6568c94e0d35c51dcf18b45423',
      env: 'production'
    },
    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.app.link/public-charge'
    },
    mavenoid: {
      clientId: '26012d988dee2116f068',
      productId: 3608150
    }
  },

  'wallbox-pre': {
    name: 'Wallbox pre',
    client: 'wallbox',
    authSystem: 'cookie',
    api: 'api-pre.wall-box.com',
    userApi: 'user-api-pre.wall-box.com',
    ocppPocApi: 'api-proxy.preprod.wall-box.com/perseus',
    perseusApi: 'api-pre.wall-box.com/perseus',
    timezoneApi: 'portal-pre.wallbox.com',
    env: 'preproduction',
    web: [
      'portal-pre.wallbox.com'
    ],
    oauth: {
      google: '324006994476-p3rugq5s2qaq7mpjlvparac7nlp0n73l.apps.googleusercontent.com',
      apple: 'com.wallbox.dev'
    },
    featureFlags: {
      baseURL: 'https://my.wallbox.com/feature-flag',
      active: true
    },
    datadog: {
      active: false
    },
    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.test-app.link/public-charge'
    },
    mixpanel: {
      token: '',
      active: false
    },
    mavenoid: {
      clientId: '26012d988dee2116f068',
      productId: 3608150
    }
  },

  'wallbox-iberdrola-production': {
    name: 'Iberdrola production',
    client: 'iberdrola',
    authSystem: 'cookie',
    api: 'api.wall-box.com',
    userApi: 'user-api.wall-box.com',
    ocppPocApi: 'api-proxy.preprod.wall-box.com/perseus',
    perseusApi: 'api.wall-box.com/perseus',
    timezoneApi: 'iberdrola.wallbox.com',
    env: 'production',
    web: [
      'iberdrola.wallbox.com'
    ],
    mixpanel: {
      token: '7934a65accf89301282f8e97112c3b98',
      active: true
    },
    oauth: {
      google: '324006994476-pr4jjbohohcm536944lkk0117peqjvou.apps.googleusercontent.com',
      apple: 'com.wallbox.prod'
    },
    datadog: {
      active: true,
      applicationId: '92d8bedc-e714-450b-bee7-9bac0fe83b1d',
      clientToken: 'pubec814f6568c94e0d35c51dcf18b45423'
    },
    pwa: {
      active: true
    },
    deepLinks: {
      publicCharge: 'https://mywallbox.app.link/public-charge'
    },
    featureFlags: {
      baseURL: 'https://my.wallbox.com/feature-flag',
      active: false
    },
    mavenoid: {
      clientId: '26012d988dee2116f068',
      productId: 3608150
    }
  }
}

export default envs
