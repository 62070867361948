import { reactive, computed } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import { OCPP_CONNECTION_STATUS, type Charger as ChargerCore } from '@/core/charger'
import { EnumConnectivityType } from '@/utilities/charger/chargerConnectivity'
import { chargerStatuses } from '@/utilities/charger/chargerStatuses'
import { midStatuses } from '@/utilities/charger/midInformation'

const DEFAULT_CHARGER: ChargerCore = {
  dtoVersion: 1 as const,
  uid: '',
  id: 0,
  serialNumber: '0',
  uniqueIdentifier: '0',
  name: '',
  status: { id: 0, ...chargerStatuses?.[0] },
  image: '',
  model: 'PPR1',
  modelName: '',
  partNumber: '',
  organizationId: '',
  oldGroupId: 0,
  locationId: '',
  locationName: '',
  mid: midStatuses?.[0],
  lastConnection: 0,
  locked: false,
  connectionType: EnumConnectivityType.ETHERNET,
  wifiSignal: 0,
  ocppConnectionStatus: OCPP_CONNECTION_STATUS.DISCONNECTED,
  software: {
    updateAvailable: false,
    currentVersion: '',
    latestVersion: '',
    softwareLastUpdatedAt: 0
  },
  ocppProtocol: '',
  maxAmps: 0,
  minAmps: 0,
  maxAvailableAmps: 0,
  remoteAction: null,
  operationMode: 'wallbox',
  autoLock: false,
  autoLockTime: 0,
  powerType: 'AC',
  powerSharingStatus: 0,
  homeSharing: false,
  powerSharingEnabled: false,
  userSocketLocking: false,
  ecosmartEnabled: false,
  connectorType: '',
  timezone: 'UTC',
  isPayPerChargeEnabled: false,
  isPayPerMonthEnabled: false,
  softwareAutoUpdateEnabled: false
}

interface State {
  charger: ChargerCore
  checkedChargers: ChargerCore[]
  chargers: ChargerCore[]
}

const initialState = (): State => ({
  charger: DEFAULT_CHARGER,
  checkedChargers: [],
  chargers: []
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<State>) {
    return objects.getProperty(state, path)
  },

  getChargerCore: computed(() => state.charger),

  getCheckedChargers: computed(() => state.checkedChargers),

  getChargers: computed(() => state.chargers)
}

const setters = {
  setCheckCharger (charger: ChargerCore, checked: boolean) {
    state.checkedChargers = state.checkedChargers.filter(checkedCharger => checkedCharger.id !== charger.id)
    checked && state.checkedChargers.push(charger)
  },

  setCheckChargersGroup ({ status, chargers }:
  { status: boolean, chargers: ChargerCore[] }) {
    chargers.forEach((charger) => {
      setters.setCheckCharger(charger, status)
    })
  },

  resetCheckedChargers () {
    state.checkedChargers.forEach((charger) => {
      setters.setCheckCharger(charger, false)
    })
  },

  setInitialState () {
    state = reactive(initialState())
  },

  setCharger (charger: ChargerCore) {
    state.charger = charger
  },

  resetCharger () {
    state.charger = DEFAULT_CHARGER
  },

  set<T> (path: Paths<State>, value: T) {
    objects.setProperty(state, path, value)
  }
}

export default reactive({
  ...getters,
  ...setters
})
