import type { CountryIso2 } from '@/core/international'
import type { OAUTH_PROVIDER } from './OauthSignIn'

import { object, string, type InferType, boolean } from 'yup'

const oauthSignUpSchema = object({
  oauthProvider: string<OAUTH_PROVIDER>().required(),
  idToken: string().required(),
  name: string().required(),
  surname: string().required(),
  countryIso2: string<CountryIso2>().required(),
  language: string().required(),
  terms: object({
    policy: boolean().required(),
    marketing: boolean().optional(),
    profile: boolean().optional()
  })
})

export type OauthSignUp = InferType<typeof oauthSignUpSchema>

export async function ensureOauthSignUpIsValid (oauthSignUp: any) {
  return await oauthSignUpSchema.validate(oauthSignUp, { abortEarly: false })
}
