<template>
  <wb-card
    :headline="i18n.t('mywb.common.variable-fee')"
    :subhead="i18n.t('mywb.rates.variable-fee-description')"
  >
    <template #place-upper-right>
      <wb-switch
        v-model="compute.isActiveProxy"
        data-test-id="switch"
      />
    </template>

    <template #content>
      <div v-if="compute.isActiveProxy" class="variable-fee g-8 mt-16">
        <checkbox-card-form
          v-model="data.rate.field.variableType"
          data-test-id="variableFeeByTime"
          class="checkbox-grid"
          :error="data.rate.errors.variableType"
          value="time"
          icon="clock"
          :title="i18n.t('mywb.common.charging-time.fee')"
          :subtitle="`${i18n.t('mywb.common.price')} - ${data.hourLabel}`"
        />

        <checkbox-card-form
          v-model="data.rate.field.variableType"
          data-test-id="variableFeeByEnergy"
          class="checkbox-grid"
          :error="data.rate.errors.variableType"
          value="energy"
          icon="bolt_circle"
          :title="i18n.t('mywb.common.consumed-energy.fee')"
          :subtitle="`${i18n.t('mywb.common.price')} - ${data.kwhLabel}`"
        />

        <wb-input
          v-if="data.rate.field.variableType"
          :ref="setRef('input')"
          v-model="compute.computedPrice"
          data-test-id="variableFeeInput"
          :error="data.rate.errors.variablePrice"
          class="input-grid"
          :label-right="compute.variableFeeRightLabel"
          :hint="i18n.t('mywb.common.tax-not-included')"
          persistent-hint
          :label="compute.variableFeeLabel"
        />
      </div>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import CheckboxCardForm from '@/components/forms/CheckboxCardForm.vue'
import { useValidatedRate } from '@/utilities/rates/useFormValidation'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive, computed } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { usePriceValueProxy } from '@/hooks'
import state from '@/state'

const i18n = useI18n()
const { refs, setRef } = useTemplateRef()

interface Props {
  isActivated: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'update:isActivated', isActivated: boolean): void
}
const emit = defineEmits<Events>()

const data = reactive({
  rate: useValidatedRate(),
  hourLabel: `${state.organizations.getCurrentOrganization.currencyCode} / ${i18n.t('mywb.common.hour')}`,
  kwhLabel: `${state.organizations.getCurrentOrganization.currencyCode} / ${i18n.t('mywb.common.kwh')}`
})

const { onModelChange, computedPrice } = usePriceValueProxy(data.rate.field.variablePrice)

onModelChange(({ parsedValue, rawInput }) => {
  if (parsedValue) data.rate.field.variablePrice = parsedValue
  if (refs.input) refs.input.$el.getElementsByTagName('input')[0].value = rawInput
})

const compute = reactive({
  variableFeeRightLabel: computed(() => {
    if (data.rate.field.variableType === 'energy') {
      return data.kwhLabel
    } else if (data.rate.field.variableType === 'time') {
      return data.hourLabel
    }
    return ''
  }),

  variableFeeLabel: computed(() => {
    if (data.rate.field.variableType === 'energy') {
      return i18n.t('mywb.common.cost-per-kwh')
    } else if (data.rate.field.variableType === 'time') {
      return i18n.t('mywb.common.cost-per-hour')
    }
    return ''
  }),

  isActiveProxy: computed({
    get () {
      return props.isActivated
    },
    set (value) {
      compute.computedPrice = undefined
      return emit('update:isActivated', value)
    }
  }),

  computedPrice
})
</script>

<style lang="postcss" scoped>
.variable-fee {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input-grid {
  grid-column: span 2;
}

.checkbox-grid {
  grid-column: span 2;

  @media (--tablet) {
    grid-column: span 1;
  }
}

.self-end {
  justify-self: end;
}
</style>
