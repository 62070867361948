import type { CountryIso2 } from '@/core/international'
import { object, string, type InferType } from 'yup'

const updateMeSchema = object({
  id: string().required(),
  name: string().required(),
  surname: string().required(),
  phoneCode: string().required(),
  phone: string().required(),
  countryIso2: string<CountryIso2>().required()
})

export type UpdateMe = InferType<typeof updateMeSchema>

export async function ensureUpdateMeIsValid (payload: any) {
  return await updateMeSchema.validate(payload, { abortEarly: false })
}
