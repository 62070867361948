<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.auth.register') }}
      </p>
    </template>

    <wb-form>
      <wb-input
        ref="input"
        v-model="data.name"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
        data-test-id="nameInputModal"
        name="name"
        type="text"
      />

      <wb-button
        :label="i18n.t('mywb.common.continue')"
        size="large"
        data-test-id="firstStepBtn"
        @click="validate(methods.continueRegister)"
      />

      <wb-link
        class="is-size-400 has-margin-auto"
        type="text"
        data-test-id="backLink"
        @click="emit('back')"
      >
        <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
        <span class="has-text-black">{{ i18n.t('mywb.common.back') }}</span>
      </wb-link>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { reactive, ref } from 'vue'
import { useWbInputAutofocus } from '@/hooks'
import { trackScreen, trackAction } from '@wallbox/metrics'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'

const i18n = useI18n()
const { yup, errors, validate, defineSchema } = useValidator()

interface Events {
  (e: 'continue-register', { name }: { name: string}): void
  (e: 'back'): void
}

const emit = defineEmits<Events>()

const data = reactive({
  name: ''
})

defineSchema(data, {
  name: yup
    .string()
    .required()
})

const input = ref()
useWbInputAutofocus(input)

const methods = {
  continueRegister () {
    trackAction('register-continue')
    trackScreen('terms')

    emit('continue-register', {
      name: data.name
    })
  }
}
</script>
