import yup from '@/hooks/useValidator.hook'

const unsubscribeContractSchema = yup.object({
  contractId: yup.number().required()
})

export type UnsubscribeContract = yup.InferType<typeof unsubscribeContractSchema>

export async function ensureUnsubscribeContractSchemaIsValid (payload: any) {
  return await unsubscribeContractSchema.validate(payload, { abortEarly: false })
}
