import {
  userApiRepository,
  getAllAccessConfig,
  getUserAccessConfigByGroup,
  getAccessConfig,
  editAccessConfig,
  createAccessConfig,
  removeAccessConfig,
  changeUserRole,
  subscribeUserToPayPerMonth,
  addUserToAccessConfig,
  removeUserFromAccessConfig
} from '@/core/accessConfig'

export function provideAccessConfigUseCases () {
  const repo = userApiRepository()

  return {
    getAllAccessConfig: getAllAccessConfig(repo),
    getUserAccessConfigByGroup: getUserAccessConfigByGroup(repo),
    getAccessConfig: getAccessConfig(repo),
    editAccessConfig: editAccessConfig(repo),
    createAccessConfig: createAccessConfig(repo),
    removeAccessConfig: removeAccessConfig(repo),
    changeUserRole: changeUserRole(repo),
    subscribeUserToPayPerMonth: subscribeUserToPayPerMonth(repo),
    addUserToAccessConfig: addUserToAccessConfig(repo),
    removeUserFromAccessConfig: removeUserFromAccessConfig(repo)
  }
}
