import type { LOCK_STATUS } from '@/utilities/charger/chargerActions'
import { string, object, type InferType, mixed, number, boolean } from 'yup'
import i18n from '@/engine/lang'

let min = 0
let max = 32

const chargerUpdateSchema = object({
  uid: string().required(),
  locked: mixed<LOCK_STATUS>(),
  maxAmps: number()
    .min(min, i18n.global.t('mywb.error.min.max-current', [min]))
    .max(max, i18n.global.t('mywb.error.max.max-current', [max])),
  name: string(),
  timezone: string(),
  energyCost: number().nullable(),
  userSocketLocking: boolean(),
  autoLock: boolean(),
  autoLockTime: number(),
  softwareAutoUpdateEnabled: boolean()
})

const chargerUpdateNameSchema = chargerUpdateSchema.shape({
  name: string().required()
})

const chargerUpdateLockSchema = chargerUpdateSchema.shape({
  name: mixed<LOCK_STATUS>().required()
})

const chargerUpdateMaxAmpsSchema = chargerUpdateSchema.shape({
  maxAmps: number()
    .min(min, i18n.global.t('mywb.error.min.max-current', [min]))
    .max(max, i18n.global.t('mywb.error.max.max-current', [max]))
    .required()
})

const chargerUpdateEnergyCostSchema = chargerUpdateSchema.shape({
  energyCost: number().required().nullable()
})

export type ChargerUpdate = InferType<typeof chargerUpdateSchema>
export type ChargerUpdateName = InferType<typeof chargerUpdateNameSchema>
export type ChargerUpdateLock = InferType<typeof chargerUpdateLockSchema>
export type ChargerUpdateMaxAmps = InferType<typeof chargerUpdateMaxAmpsSchema>
export type ChargerUpdateEnergyCost = InferType<typeof chargerUpdateEnergyCostSchema>

export async function ensureUpdateChargerNameIsValid (payload: any) {
  return await chargerUpdateNameSchema.validate(payload, { abortEarly: false })
}

export async function ensureUpdateChargerLockIsValid (payload: any) {
  return await chargerUpdateLockSchema.validate(payload, { abortEarly: false })
}

export async function ensureUpdateEnergyCostIsValid (payload: any) {
  return await chargerUpdateEnergyCostSchema.validate(payload, { abortEarly: false })
}

export async function ensureUpdateChargerMaxAmpsIsValid (payload: any, business: { min: number, max: number }) {
  min = business.min
  max = business.max

  return await chargerUpdateMaxAmpsSchema.validate(payload, { abortEarly: false })
}
