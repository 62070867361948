<template>
  <wb-card
    id="access"
    :headline="i18n.t('mywb.common.open-access')"
    :subhead="i18n.t('mywb.common.open-access.description')"
    data-test-id="securityOpenAccess"
    :class="{ 'selected-blink': props.selected === 'access'}"
  >
    <template #place-upper-right>
      <div class="is-flex">
        <wb-badge
          v-if="!data.chargerConfig.autoLock"
          inverted
          variant="green"
          class="label"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-badge>
        <wb-radio
          v-model="data.chargerConfig.autoLock"
          :value="false"
          position="right"
          :disabled="data.loading"
          @update:model-value="methods.checkUnassignRates"
        />
      </div>
    </template>
  </wb-card>

  <wb-card
    id="lock"
    class="my-8"
    :headline="i18n.t('mywb.common.restricted-or-paid-access')"
    :subhead="i18n.t('mywb.common.restricted-or-paid-access.description')"
    data-test-id="securityRestrictedOrPaidAccess"
    :class="{ 'selected-blink': props.selected === 'lock'}"
    actions-position="bottom"
  >
    <template v-if="data.chargerConfig.autoLock" #actions>
      <wb-button
        class="mt-16"
        :label="i18n.t('mywb.charger.view-assigned-users')"
        variant="primary"
        inverted
        :to="{ name: ROUTES.CHARGER_DETAIL_USERS }"
        data-test-id="editMaxCurrentButton"
      />
    </template>

    <template v-if="data.autolockCompatible && data.chargerConfig.autoLock" #content>
      <wb-card
        filled
        alignment="center"
      >
        <template #content>
          <p class="is-size-300 is-font-weight-500 has-text-black">
            {{ i18n.t('mywb.common.auto-lock') }}
          </p>

          <span class="is-size-300">
            {{ i18n.t('mywb.common.auto-lock.description') }}
          </span>
          <i18n-t
            tag="p"
            keypath="mywb.charger.protection.charger-locks-after"
            class="is-size-300 mt-8"
          >
            <span
              class="is-font-weight-500"
              data-test-id="autoLockTime"
            >
              {{ Math.round(data.chargerConfig.autoLockTime / data.autolock.interval) }} {{ i18n.t('mywb.common.min') }}
            </span>
          </i18n-t>

          <wb-range-slider
            v-model="data.chargerConfig.autoLockTime"
            data-test-id="autoLockSliderBar"
            :disabled="data.loading"
            :interval="data.autolock.interval"
            :min="data.autolock.min"
            :max="data.autolock.max"
            :tooltip-formatter="(tooltip: any) => `${tooltip}${i18n.t('mywb.common.min')}`"
            @on-mouseup="methods.saveChargerConfig"
          />
        </template>
      </wb-card>
    </template>

    <template #place-upper-right>
      <div class="is-flex">
        <wb-badge
          v-if="data.chargerConfig.autoLock"
          variant="green"
          class="label"
        >
          {{ i18n.t('mywb.common.enabled') }}
        </wb-badge>

        <wb-radio
          v-model="data.chargerConfig.autoLock"
          :value="true"
          class="radio"
          position="right"
          :disabled="data.loading"
          @update:model-value="methods.saveChargerConfig"
        />
      </div>
    </template>
  </wb-card>

  <shared-confirmation-modal
    v-if="data.frozeSchedules"
    :title="i18n.t('mywb.common.change-mode-charging-confirmation')"
    :subtitle="i18n.t('mywb.common.free-charging.description')"
    type="danger"
    :label-confirmation-button="i18n.t('mywb.common.disable-payments')"
    blocked
    hide-close-button
    @on-confirm="methods.deleteRatesAndSave"
    @on-cancel="methods.cancelDeleteRates"
  >
    <wb-alert
      variant="info"
      icon="info_filled"
      class="mb-12"
    >
      <p v-t="'mywb.common.payments-configuration-will-be-disable'" />
    </wb-alert>
    <p class="is-size-400 mb-12">
      {{ i18n.t('mywb.common.change-mode-charging-confirmation.description') }}
    </p>
  </shared-confirmation-modal>
</template>

<script setup lang="ts">
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'

import state from '@/state'
import { reactive, watch, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import { useToast } from '@wallbox/toolkit-ui'
import { CHARGER_USE_CASES, injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import { LOCK_STATUS } from '@/utilities/charger/chargerActions'
import ROUTES from '@/engine/router/routes'
import { moveScheduleFromLocalToUTC } from '@/utilities/rates/normalizeScheduleInTimezone'

const rateUseCases = injectStrict(RATE_USE_CASES)
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

const toast = useToast()
const i18n = useI18n()

interface Props {
  loading?: boolean,
  selected?: string
}
const props = defineProps<Props>()

interface Data {
  frozeSchedules: boolean,
  autolock: {
    min: 1,
    max: 60,
    interval: 60
  },
  chargerConfig: {
    autoLock: boolean
    autoLockTime: number
  },
  loading: boolean,
  schedules: Awaited<ReturnType<typeof rateUseCases.getChargerRatesWeekSchedules>>,
  autolockCompatible: boolean
}
const data = reactive<Data>({
  frozeSchedules: false,
  autolock: {
    min: 1,
    max: 60,
    interval: 60
  },
  chargerConfig: {
    autoLock: false,
    autoLockTime: 60
  },
  loading: false,
  schedules: [],
  autolockCompatible: false
})

watch([() => props.loading, () => state.charger.getChargerCore.autoLock], () => {
  if (!props.loading) {
    data.chargerConfig.autoLock = state.charger.getChargerCore.autoLock
    data.chargerConfig.autoLockTime = state.charger.getChargerCore.autoLockTime
  }
}, { immediate: true })

const methods = {
  cancelDeleteRates () {
    data.chargerConfig.autoLock = true
    data.frozeSchedules = false
  },

  async deleteRatesAndSave () {
    const apiCalls = data.schedules.map(schedule => {
      return () => rateUseCases.saveChargerRateSchedule({
        chargerId: state.charger.getChargerCore.uid,
        scheduleId: schedule.id,
        payload: {
          type: schedule.paymentType,
          status: 'frozen',
          schedule: moveScheduleFromLocalToUTC(schedule.schedule, state.charger.getChargerCore.timezone)

        }
      })
    })

    await Promise.all(apiCalls.map(call => call()))
    data.frozeSchedules = false
    await methods.saveChargerConfig()
  },

  async checkUnassignRates () {
    data.schedules =
      (await rateUseCases.getChargerRatesWeekSchedules(state.charger.getChargerCore.uid ?? ''))

    if (data.schedules.length && data.schedules.some(schedule => schedule.status !== 'frozen')) {
      data.frozeSchedules = true
    } else {
      methods.saveChargerConfig()
    }
  },

  async saveChargerConfig () {
    data.loading = true
    try {
      await chargerUseCases.updateCharger({
        uid: state.charger.getChargerCore.uid,
        autoLock: data.chargerConfig.autoLock,
        locked: data.chargerConfig.autoLock ? LOCK_STATUS.LOCK : LOCK_STATUS.UNLOCK,
        autoLockTime: data.chargerConfig.autoLockTime
      })

      state.charger.set('charger.autoLock', data.chargerConfig.autoLock)
      state.charger.set('charger.autoLockTime', data.chargerConfig.autoLockTime)

      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
    }
  }
}

watchEffect(async () => {
  const info = await chargerUseCases.getIsChargerCompatibleWithAutoLock(state.charger.getChargerCore)

  data.autolockCompatible = info.isCompatible
})
</script>

<style lang="postcss" scoped>
.is-flex {
  display: flex;
  align-items: center;

  & .label {
    padding: 0 8px;
    margin-right: 8px;
  }
}
</style>
