export default {
  changePassword: {
    action: '0',
    hasToLogout: true
  },
  validateUserAccount: {
    action: '1',
    hasToLogout: true
  },
  inviteNewUser: {
    action: '2',
    hasToLogout: true
  },
  validateTemporalUserInvitation: {
    action: '3',
    hasToLogout: true
  },
  validateUserInvitation: {
    action: '4',
    hasToLogout: true
  },
  acceptOperatorRequest: {
    action: '7',
    hasToLogout: false
  },
  acceptServiceabilityRequest: {
    action: '8',
    hasToLogout: false
  }
}
