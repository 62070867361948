import { ChargePointStatus } from '@/core/chargePoint'

export interface Status {
  code: ChargePointStatus
  label?: string
  description?: string
  ids?: Array<number | null>
  remote?: boolean
  filter?: boolean
  dataTestId?: string
}

export const chargePointStatuses: readonly Status[] = [
  {
    code: ChargePointStatus.AVAILABLE,
    label: 'mywb.chargePoint.status.available'
  },
  {
    code: ChargePointStatus.FAULTED,
    label: 'mywb.chargePoint.status.faulted'
  },
  {
    code: ChargePointStatus.UNAVAILABLE,
    label: 'mywb.chargePoint.status.unavailable'
  }
]

export const getColorByStatus = (statusCode: ChargePointStatus) => {
  const colors = {
    available: {
      bg: 'var(--green-500)',
      text: 'var(--white)'
    },
    unavailable: {
      bg: 'var(--grey-500)',
      text: 'var(--black)'
    },
    faulted: {
      bg: 'var(--red-500)',
      text: 'var(--white)'
    }
  }
  return colors[statusCode] ?? colors.unavailable
}
