<template>
  <shared-teleport-header>
    <template #title>
      {{ compute.organizationName }}
    </template>
  </shared-teleport-header>

  <div class="wrapper-grid g-32">
    <wb-card class="menu-sticky">
      <template #content>
        <wb-menu
          v-model="data.selected"
          :items="compute.menu"
        />
      </template>
    </wb-card>

    <section class="content-grid g-24">
      <wb-card
        id="name"
        :headline="compute.organizationName"
        :subhead="compute.organizationType"
        class="tablet-span-2"
        :class="{ 'selected-blink': data.selected === 'name'}"
        alignment="center"
      >
        <template #actions>
          <wb-button
            variant="white"
            outlined
            data-test-id="editNameOrganization"
            icon="edit"
            :label="i18n.t('mywb.common.edit')"
            @click="data.isModalEditOrganizationOpen = true"
          />
        </template>
      </wb-card>

      <stripe-connection v-if="permissions.showBilling" />
    </section>
  </div>

  <organization-edit-modal
    v-if="data.isModalEditOrganizationOpen && data.organization"
    :organization="data.organization"
    @on-update="methods.handleUpdateName"
    @on-close="data.isModalEditOrganizationOpen = false"
  />
</template>

<script setup lang="ts">
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import OrganizationEditModal from '@/components/modals/OrganizationEditModal.vue'
import StripeConnection from '@/components/payments/StripeConnection.vue'
import state from '@/state'
import { useRoute } from 'vue-router'
import { reactive, computed, watchEffect } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { organizationText, OrganizationTypeEnum } from '@/utilities/organizationTypes'
import { permissions } from '@/engine/clients'
import type { ExcludesNullish, MenuItem } from '@wallbox/toolkit-ui'
import type { Organization } from '@/core/organization'

const route = useRoute()
const i18n = useI18n()

interface Data {
  organization?: Organization
  isModalEditOrganizationOpen: boolean
  hasBillingInfo: boolean
  selected?: string
}

const data = reactive<Data>({
  organization: undefined,
  isModalEditOrganizationOpen: false,
  hasBillingInfo: false,
  selected: undefined
})

const compute = reactive({
  menu: computed((): MenuItem[] => {
    return [
      {
        name: 'name',
        icon: 'business',
        label: i18n.t('mywb.common.name')
      },
      permissions.showBilling && {
        name: 'billing',
        icon: 'card',
        label: i18n.t('mywb.common.payments-configuration')
      }
    ].filter(Boolean as unknown as ExcludesNullish)
  }),

  organizationName: computed({
    get () {
      return data.organization?.name || ''
    },
    set (name) {
      data.organization = { ...data.organization, name } as Organization
    }
  }),

  organizationType: computed({
    get () {
      if (!data.organization?.organizationType) return ''
      return organizationText[data.organization?.organizationType]?.name
    },
    set (type) {
      data.organization = {
        ...data.organization,
        organizationType: type as OrganizationTypeEnum
      } as Organization
    }
  })
})

const methods = {
  handleUpdateName (payload: { name: string, type: OrganizationTypeEnum }) {
    compute.organizationName = payload.name
    compute.organizationType = payload.type
  }
}

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
})

async function created () {
  data.organization = state.organizations.getOrganizationByUid(route.params.organizationUid?.toString())
}

created()
</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.menu-sticky {
  display: none;

  @media (--tablet) {
    display: block;
    position: sticky!important;
    top: 105px!important;
    height: fit-content;
  }
}
</style>
