<template>
  <div>
    <shared-header hide-breadcrumb>
      <template #filters>
        <access-config-filters
          :loading="data.loading"
        />
      </template>
    </shared-header>

    <users-list
      id="user-list"
      data-test-id="userList"
      :users="data.users"
      :users-invitation="data.usersInvitation"
      :loading="data.loading"
      @add-user="methods.addUser"
      @remove-user="methods.openRemoveUser"
      @on-click="methods.openUserProfile"
      @revoke-invitation="methods.revokeInvitation"
      @resend-invitation="methods.resendInvitation"
    />

    <invite-users-modal
      v-if="data.isModalUserListAddUserOpen"
      :user-invitations="data.usersInvitation"
      @on-users-invited="methods.getUserInvitations"
      @close="data.isModalUserListAddUserOpen = false"
    />

    <user-delete-modal
      v-if="data.isModalUserListUserDeleteOpen && data.selectedUser"
      :user="data.selectedUser"
      @close="methods.onDeleteUser"
    />

    <shared-confirmation-modal
      v-if="data.isModalSettingsUnpaidBillsOpen"
      :title="i18n.t('mywb.user.has-unpaid-bills', { username: data.selectedUser?.name })"
      :label-confirmation-button="i18n.t('mywb.common.show-pending-invoices')"
      @on-confirm="methods.showPendingBills"
      @on-close="data.isModalSettingsUnpaidBillsOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import SharedHeader from '@/components/headers/SharedHeader.vue'
import accessConfigFilters from '@/components/filters/accessConfigFilters.vue'
import UsersList from '@/components/users/UserList.vue'
import InviteUsersModal from '@/components/modals/InviteUsersModal.vue'
import UserDeleteModal from '@/components/modals/UserDeleteModal.vue'
import SharedConfirmationModal from '@/components/modals/SharedConfirmationModal.vue'
import { clientConfig } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import { useRouter } from 'vue-router'
import state from '@/state'
import lang from '@/engine/lang'
import { HttpError } from '@wallbox/http'
import type { UserList } from '@/core/user'
import { ACCESS_CONFIG_USE_CASES, injectStrict, USER_USE_CASES } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const router = useRouter()
const toast = useToast()
const i18n = useI18n()
const userUsesCases = injectStrict(USER_USE_CASES)
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

interface Data {
  selectedUser?: UserList
  isModalUserListUserDeleteOpen: boolean
  isModalSettingsUnpaidBillsOpen: boolean
  isModalUserListAddUserOpen: boolean
  loading: boolean
  users: UserList[]
  usersInvitation: UserList[]
}

const data = reactive<Data>({
  isModalUserListUserDeleteOpen: false,
  isModalSettingsUnpaidBillsOpen: false,
  isModalUserListAddUserOpen: false,
  loading: false,
  usersInvitation: [],
  users: []
})

const methods = {
  async getAllUsers (withLoading = true) {
    data.loading = withLoading

    data.users = await userUsesCases.getAllUsersByOrganization(state.organizations.getCurrentOrganization.groupUid)
    state.user.setUsers(data.users)

    data.users = data.users.filter(user => user.id !== state.organizations.getCurrentOrganization.ownerId)

    data.usersInvitation = await userUsesCases.getAllInvitations(state.organizations.getCurrentOrganization.groupUid)

    const accessConfig = await accessConfigUseCases
      .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
    state.user.setAccessConfigs(accessConfig)
    data.loading = false
  },

  async getUserInvitations () {
    data.isModalUserListAddUserOpen = false
    data.usersInvitation = await userUsesCases.getAllInvitations(state.organizations.getCurrentOrganization.groupUid)
  },

  openUserProfile (uid: string) {
    trackDataAction('user', { user_id: uid })
    router.push({ name: ROUTES.USER_DETAIL, params: { uid } })
  },

  hasUnpaidBills (user: UserList) {
    const { contract } = user
    return contract?.status === 'unpaid'
  },

  openRemoveUser (user: UserList) {
    data.selectedUser = user

    if (!methods.hasUnpaidBills(user)) {
      trackDataAction('delete-user', { user_id: user.id })
      data.isModalUserListUserDeleteOpen = true
    } else {
      data.isModalSettingsUnpaidBillsOpen = true
    }
  },

  showPendingBills () {
    state.filters.setFilterValue({ filter: 'invoiceFilters', key: 'status', value: 'pending' })
    router.push({ name: ROUTES.PAYMENTS_INVOICES })
  },

  addUser (groupUid: string) {
    trackDataAction('add-user', { group_id: groupUid })
    data.isModalUserListAddUserOpen = true
  },

  async revokeInvitation ({ invitationId }: { invitationId: number }) {
    try {
      await userUsesCases.revokeUserInvitation({
        groupUid: state.organizations.getCurrentOrganization.groupUid,
        invitationId
      })
      toast.success(i18n.t('mywb.common.invitation-has-been-revoked'))
      setTimeout(() => { methods.getUserInvitations() }, 300)
      trackDataAction('revoke-invitation', { group_id: state.organizations.getCurrentOrganization.groupUid })
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }
  },

  async resendInvitation ({ invitationId }: { invitationId: number }) {
    try {
      await userUsesCases.resendUserInvitation({
        groupUid: state.organizations.getCurrentOrganization.groupUid,
        invitationId,
        lang: lang.__rootLanguage,
        branding: clientConfig.brandingId
      })

      toast.success(i18n.t('mywb.common.invitation-sent'))
      trackDataAction('resend-invitation', { group_id: state.organizations.getCurrentOrganization.groupUid })
    } catch (error) {
      if (error instanceof HttpError && error?.code === 403) {
        toast.error(i18n.t('mywb.error.group-invitation-limit-exceeded'))
      } else {
        toast.error(i18n.t('mywb.error.unexpected-error'))
      }
    }
  },

  resetFilters () {
    state.filters.resetFilters({ filter: 'accessConfigFilters' })
  },

  onDeleteUser () {
    data.isModalUserListUserDeleteOpen = false
    methods.resetFilters()
    methods.getAllUsers(false)
  }
}

function created () {
  methods.getAllUsers()
}

created()

defineExpose({ inviteUser: () => methods.addUser(state.organizations.getCurrentOrganization.groupUid) })
</script>
