import countries from 'i18n-iso-countries'
import { computed, reactive, toRaw } from 'vue'
import { objects, type Paths } from '@wallbox/toolkit-ui'
import i18n from '@/engine/lang'
import type { Country, Currency } from '@/core/international'

const WB_STATE_VERSION = 6

export interface GlobalState {
  version: typeof WB_STATE_VERSION
  timezones: string[]
  currencies: Currency[]
  countries: Country[]
  userId: string
  session: {
    start: number
    end: number
  }
  chargersLoaded: boolean
  organizationsWithModalViewed: Set<string>
}

const initialState = (): GlobalState => ({
  version: WB_STATE_VERSION,
  timezones: [],
  currencies: [],
  countries: [],
  userId: '-1',
  session: {
    start: 0,
    end: 0
  },

  organizationsWithModalViewed: new Set(),
  chargersLoaded: false
})

let state = reactive(initialState())

const getters = {
  get (path: Paths<GlobalState>) {
    return objects.getProperty(state, path)
  },

  getWbState: () => {
    const state = localStorage.getItem('wb-state')

    return state ? JSON.parse(state) : initialState()
  },

  getUserId: computed(() => state.userId),

  getTimezones: computed(() => state.timezones),

  getCurrencies: computed(() => state.currencies),

  getCountries: computed(() => {
    return state.countries.map(country => ({
      ...country,
      name: countries.getName(country.iso3, i18n.__rootLanguage, { select: 'official' }) ?? country.name
    })).sort((a, b) => a.name.localeCompare(b.name))
  }),

  getSessionDurationInMinutes: computed(() => (state.session.start - state.session.end) / 1000 / 60)
}

const setters = {
  set (path: Paths<GlobalState>, value: unknown) {
    objects.setProperty(state, path, value)
  },

  setTimezones (timezones: string[]) {
    state.timezones = timezones
  },

  setCurrencies (currencies: Currency[]) {
    state.currencies = currencies
  },

  setCountries (countries: Country[]) {
    state.countries = countries
  },

  setUserId (value: string) {
    state.userId = value
    setters.setWbState('userId', state.userId)
  },

  setSession (type: keyof GlobalState['session'] = 'start') {
    state.session[type] = new Date().getTime()
    setters.setWbState('session', state.session)
  },

  setAllSession (session = initialState().session) {
    state.session = session
    setters.setWbState('session', state.session)
  },

  setOrganizationsWithModalViewed (organizationsWithModalViewed = initialState().organizationsWithModalViewed) {
    state.organizationsWithModalViewed = organizationsWithModalViewed
    setters.setWbState('organizationsWithModalViewed', Array.from(state.organizationsWithModalViewed))
  },

  setWbState<S extends keyof GlobalState>(key: S, value: any) {
    const wbState = JSON.parse(localStorage.getItem('wb-state') ?? '{}') ?? initialState()
    wbState[key] = value
    localStorage.setItem('wb-state', JSON.stringify(wbState))
  },

  hasViewedModal (id: string) {
    return state.organizationsWithModalViewed.has(id)
  },

  removeLocalStorageSensitiveInfo () {
    localStorage.removeItem('wb-state')
    localStorage.removeItem('wb-auth')

    const {
      organizationsWithModalViewed
    } = toRaw(state)

    state = reactive(initialState())

    this.setOrganizationsWithModalViewed(organizationsWithModalViewed)
  },

  setOrganizationTypeModalViewed (id: string) {
    state.organizationsWithModalViewed.add(id)
    this.setOrganizationsWithModalViewed(state.organizationsWithModalViewed)
  }
}

export function loadState () {
  const wbState = getters.getWbState()

  if (wbState && wbState.version === WB_STATE_VERSION) {
    setters.setUserId(wbState?.userId ?? '-1')
    setters.setAllSession(wbState.session)
    setters.setOrganizationsWithModalViewed(new Set(wbState.organizationsWithModalViewed ?? []))
  }

  setters.setWbState('version', WB_STATE_VERSION)
}

export default reactive({
  ...getters,
  ...setters
})
