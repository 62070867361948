import PlanBusinessView from '@/views/PlanBusinessView.vue'
import type { RouteRecordRaw } from 'vue-router'
import ROUTES from './routes'

export default [
  {
    path: 'plan-business',
    name: ROUTES.PLAN_BUSINESS,
    component: PlanBusinessView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.PLAN_BUSINESS,
        text: 'mywb.common.plan-business'
      }
    }
  }
] as RouteRecordRaw[]
