<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="data.options"
        :loading="props.loading"
        data-test-id="tableChargersGroups"
      >
        <locations-table-row
          v-for="location in props.locations"
          :key="location.id"
          :location="location"
          :data-test-id="`chargersGroup${location.name}`"
          @on-edit="emit('on-edit', $event)"
          @on-delete="emit('on-delete', $event)"
        />
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import LocationsTableRow from '@/components/tables/LocationsTableRow.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useMq } from 'vue3-mq'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { Location } from '@/core/location'

const mq = useMq()
const i18n = useI18n()

interface PropsType {
  loading?: boolean
  locations: Location[]
}

const props = withDefaults(defineProps<PropsType>(), {
  loading: false,
  locations: () => []
})

type Events = {
  (e: 'on-edit', location?: Location): void,
  (e: 'on-delete', location?: Location): void,
}

const emit = defineEmits<Events>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 8%',
    columns: [
      { name: '', align: 'left', width: 50 },
      { name: i18n.t('mywb.common.name'), align: 'left', width: 'auto' },
      { name: i18n.t('mywb.common.address'), align: 'left', width: 'auto' },
      { name: i18n.t('mywb.common.chargers'), align: 'center', width: 'auto' },
      { name: i18n.t('mywb.common.actions'), align: 'right', width: 100, minWidth: 100 }
    ],
    stickyHead: mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)',
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})
</script>
