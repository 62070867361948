import { object, string, type InferType } from 'yup'

const activateUser = object({
  email: string().email().required(),
  activationCode: string().required()
})

export type ActivateUser = InferType<typeof activateUser>

export async function ensureActivateUserIsValid (payload: any) {
  return await activateUser.validate(payload, { abortEarly: false })
}
