import { endpoints } from '@/core/shared/endpoints.config'
import type { StripeRepository } from '@/core/stripe'
import type { GetPaymentsAccount } from './types'
import type { RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

export const wapiStripeRepositoryBuilder: RepositoryHttpBuild<StripeRepository> = ({ httpService }) => {
  const instance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async createPaymentsAccount (groupUid: string) {
      const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      await instance.post(endpoints.v4.groups_groupId_payment_account
        .replace('{groupId}', groupId.data.attributes.value?.toString()), {
        data: {
          type: 'group_payment_account',
          attributes: {
            type: 'express'
          }
        }
      })
    },

    async hasPaymentsAccount (groupUid: string) {
      const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      let paymentsAccount = false

      try {
        const info = await instance.get<GetPaymentsAccount>(
          endpoints.v4.groups_groupId_payment_account
            .replace('{groupId}', groupId.data.attributes.value?.toString()), {
          }
        )

        if (info) {
          paymentsAccount = (
            info.data.attributes.status === 'linked' ||
            info.data.attributes.status === 'pending'
          )
        }
      } catch {}

      return paymentsAccount
    },

    async deletePaymentsAccount (groupUid) {
      const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      await instance.delete(endpoints.v4.groups_groupId_payment_account
        .replace('{groupId}', groupId.data.attributes.value?.toString()), {})
    },

    async getLinkPaymentsAccount (payload) {
      const groupId = await instance.get<any>(endpoints.v4.group_id.replace('{groupUid}', payload.groupUid), {
        headers: {
          'workbox-cache-type': 'cache-first'
        }
      })

      const info = await instance.get<any>(endpoints.v4.groups_groupId_payment_account_link
        .replace('{groupId}', groupId.data.attributes.value?.toString()), {
        params: {
          refresh_url: payload.refreshUrl,
          redirect_url: payload.redirectUrl
        }
      })

      return info.data.attributes.url
    }
  }
}
