<template>
  <wb-modal
    v-if="data.acceptServiceabilitySuccess"
    data-test-id="acceptServiceabilityModal"
    class="has-text-centered"
    width="33"
    @close="emit('close')"
  >
    <wb-check-icon />
    <p class="is-size-700 has-text-primary-500 mb-32">
      Remote assistance accepted
    </p>

    <template #actions>
      <wb-button
        size="block"
        data-test-id="confirmBtnModal"
        :label="i18n.t('mywb.common.done')"
        @click="emit('close')"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import { trackEvent } from '@wallbox/metrics'
import { getServerError } from '@/utilities/errorMessages'
import { HttpError } from '@wallbox/http'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const route = useRoute()
const toast = useToast()
const i18n = useI18n()

interface Events {
  (e: 'close'): void
}

const emit = defineEmits<Events>()

const data = reactive({
  acceptServiceabilitySuccess: false
})

onMounted(async () => {
  try {
    await chargerUseCases.acceptServiceability(route.query.accept_link?.toString() ?? '')
    data.acceptServiceabilitySuccess = true
    trackEvent('accept-serviceability')
  } catch (error) {
    if (error instanceof HttpError) {
      toast.error(getServerError(error))
    } else {
      throw error
    }
  }
})
</script>
