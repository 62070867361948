<template>
  <div>
    <wb-card
      v-for="(connector, index) in data.connectors"
      :key="index"
      class="mb-24"
    >
      <template #content>
        <div class="grid-content">
          <wb-connector
            class="charge-point-icon mb-8"
            :type="connector.type"
            size="sm"
          />
          <h3
            class="is-size-500 is-font-weight-500"
          >
            {{ connector.name }}
          </h3>
          <h4
            class="charge-point-description is-size-300 is-font-weight-400"
          >
            {{ connector.description }}
          </h4>
          <wb-button
            v-if="!connector.beingEdited"
            :label="t('mywb.common.edit')"
            type="white"
            outlined
            class="charge-point-edit"
            icon="edit"
            @click="connector.beingEdited = true"
          />
        </div>

        <wb-card
          v-if="connector.beingEdited"
          class="mt-24"
          filled
        >
          <template #content>
            <wb-form>
              <wb-input
                v-model="connector.name"
                :label="t('mywb.charge-point.connector-name')"
                :error="data.errors[index]?.name"
              />

              <div class="is-fullwidth">
                <p
                  v-t="'mywb.charger.connection-type'"
                  class="is-font-weight-500 mb-8"
                />
                <p
                  v-if="data.errors[index]?.format"
                  class="has-text-danger-500 mb-12"
                >
                  {{ data.errors[index]?.type }}
                </p>
                <div class="type-card-grid">
                  <wb-card
                    v-for="connectorForm in compute.connectorsList"
                    :key="connectorForm.key"
                    class="brand"
                    :class="{ active: connector.type === connectorForm.key }"
                    @click="connector.type = connectorForm.key"
                  >
                    <template #content>
                      <div class="card-inside">
                        <wb-connector
                          class="charge-point-icon mb-8"
                          :type="connectorForm.key"
                          size="sm"
                        />

                        {{ connectorForm.label }}
                      </div>
                    </template>
                  </wb-card>
                </div>
              </div>

              <wb-select
                v-if="!connector.justCable && !connector.justSocket"
                v-model="connector.format"
                :options="data.formats"
                :placeholder="t('mywb.charge-point.connector-name')"
                :label="t('mywb.charge-point.connector-format')"
                :error="data.errors[index]?.format"
                :reduce="(item: any) => item.key"
              />

              <wb-select
                v-if="!connector.isDC && !connector.justMonoPhase"
                v-model="connector.powerType"
                :options="data.phases"
                :placeholder="t('mywb.charge-point.select-connector-phases')"
                :label="t('mywb.charge-point.connector-phases')"
                :error="data.errors[index]?.powerType"
                :reduce="(item: any) => item.key"
              />

              <div class="type-card-grid">
                <wb-input
                  v-model="connector.maxPower"
                  :label="t('mywb.charge-point.max-power')"
                  placeholder="0"
                  :label-right="t('mywb.common.kw')"
                  :error="data.errors[index]?.maxPower"
                />

                <wb-input
                  v-model="connector.ampsPreset"
                  :label="t('mywb.charge-point.amperage')"
                  placeholder="0"
                  :label-right="t('mywb.common.amperage-unit')"
                  :error="data.errors[index]?.amps"
                />

                <wb-input
                  v-model="connector.voltagePreset"
                  :label="t('mywb.charge-point.voltage')"
                  placeholder="0"
                  :label-right="t('mywb.common.voltage-unit')"
                  :error="data.errors[index]?.voltage"
                />
              </div>
            </wb-form>
          </template>
        </wb-card>

        <wb-button
          v-if="connector.beingEdited"
          class="mt-16"
          :label="t('mywb.common.continue')"
          @click="methods.saveConnector(connector, index)"
        />
      </template>
    </wb-card>

    <wb-button
      class="mt-16"
      :disabled="data.connectors.some(connector => connector.beingEdited)"
      :label="t('mywb.common.continue')"
      @click="methods.next"
    />
  </div>
</template>

<script lang="ts" setup>
import { CONNECTORS, type Model } from '@/core/chargePoint'
import { useI18n } from '@/hooks/useI18n.hook'
import { computed, reactive, type ComputedRef } from 'vue'
import { useValidator } from '@/hooks/useValidator.hook'
import type { InferType } from 'yup'

const { t } = useI18n()

const {
  yup,
  errors,
  validateTo,
  validate
} = useValidator()

const connectorSchema = yup.object({
  name: yup.string().required(),
  type: yup.string<CONNECTORS>().required(),
  format: yup.string<'CABLE' | 'SOCKET'>().required(),
  powerType: yup.string<'DC' | 'AC_1_PHASE' | 'AC_3_PHASE'>().required(),
  maxPower: yup.number().required(),
  amps: yup.number().required(),
  voltage: yup.number().required(),
  ampsPreset: yup.mixed<ComputedRef>().required(),
  voltagePreset: yup.mixed<ComputedRef>().required(),
  beingEdited: yup.boolean(),
  description: yup.mixed<ComputedRef>(),
  isDC: yup.mixed<ComputedRef>(),
  justCable: yup.mixed<ComputedRef>(),
  justSocket: yup.mixed<ComputedRef>(),
  justMonoPhase: yup.mixed<ComputedRef>()
})
type Connector = InferType<typeof connectorSchema>

interface Props {
  model: Model
  modelValue: Array<Partial<Connector>>
}

const props = defineProps<Props>()

interface Emits {
  (e: 'on-click'): void
  (e: 'update:modelValue', connector: Props['modelValue']): void
}

const emit = defineEmits<Emits>()

interface Data {
  connectors: Array<Partial<Connector>>
  formats: Array<{ label: string, key: string }>
  phases: Array<{ label: string, key: string }>
  errors: Array<Record<keyof Connector, string>>
}

const data = reactive<Data>({
  connectors: [],
  formats: [
    {
      label: t('mywb.charge-point.socket'),
      key: 'SOCKET'
    },
    {
      label: t('mywb.charge-point.cable'),
      key: 'CABLE'
    }
  ],
  phases: [
    {
      label: t('mywb.charge-point.one-phase'),
      key: 'AC_1_PHASE'
    },
    {
      label: t('mywb.charge-point.three-phases'),
      key: 'AC_3_PHASE'
    }
  ],
  errors: []
})

const compute = reactive({
  connectorsList: computed(() => {
    const list = {
      [CONNECTORS.CCS1]: t('mywb.connectors.CCS1'),
      [CONNECTORS.CCS2]: t('mywb.connectors.CCS2'),
      [CONNECTORS.CHADEMO]: t('mywb.connectors.CHADEMO'),
      [CONNECTORS.DOMESTIC_G]: t('mywb.connectors.DOMESTIC_G'),
      [CONNECTORS.GBT_DC]: t('mywb.connectors.GBT_DC'),
      [CONNECTORS.SCHUKO]: t('mywb.connectors.DOMESTIC_F'),
      [CONNECTORS.TYPE_1]: t('mywb.connectors.IEC_62196_T1'),
      [CONNECTORS.TYPE_2]: t('mywb.connectors.IEC_62196_T2')
    }
    return Object.keys(list).map((key) => {
      return {
        key: key as CONNECTORS,
        label: list[key as CONNECTORS]
      }
    })
  })
})

const methods = {
  next () {
    data.connectors.forEach(connector => {
      connector.powerType = connector.isDC ? 'DC' : connector.powerType
      connector.format = connector.justCable ? 'CABLE' : connector.justSocket ? 'SOCKET' : connector.format
    })

    emit('update:modelValue', data.connectors)
    emit('on-click')
  },

  async saveConnector (connector: Partial<Connector>, index: number) {
    validateTo(async () => {
      return !!await connectorSchema.validate(connector, { abortEarly: false })
    })

    const isValid = await validate()

    data.errors[index] = { ...errors } as typeof data.errors[number]

    if (isValid) {
      connector.beingEdited = false
    }
  }
}

function created () {
  if (props.modelValue.length > 0) {
    data.connectors = props.modelValue
    return
  }

  data.connectors = props.model.connectors.map((connector, index) => {
    return {
      name: `Connector ${index + 1}`,
      maxPower: props.model.maxKw,
      beingEdited: true,
      type: connector,
      isDC: computed(() => {
        const result = [
          CONNECTORS.CHADEMO,
          CONNECTORS.CCS1,
          CONNECTORS.CCS2
        ].includes(data.connectors[index].type ?? CONNECTORS.TYPE_2)

        if (result) {
          // eslint-disable-next-line
          data.connectors[index].powerType = 'DC'
        }

        return result
      }),
      ampsPreset: computed({
        get () {
          const connector = data.connectors[index]

          if (!connector.maxPower || !connector.voltage) return undefined

          if (connector.isDC || connector.justMonoPhase ||
            connector.powerType === 'AC_1_PHASE' ||
            connector.powerType === 'DC') {
            const value = Math.floor(+(connector.maxPower * 1000 / connector.voltage).toFixed(2))
            connector.amps = value

            return value
          }

          const value = Math.floor(+(connector.maxPower * 1000 / connector.voltage * Math.sqrt(3)).toFixed(2))
          connector.amps = value

          return value
        },

        set (value) {
          const connector = data.connectors[index]
          connector.amps = value ? Math.floor(+value) : undefined
        }
      }),
      voltagePreset: computed({
        get (): number {
          const connector = data.connectors[index]
          if (
            (!connector.isDC && connector.justMonoPhase) ||
            (connector.powerType === 'AC_1_PHASE')) {
            connector.voltage = 230
            return 230
          }

          connector.voltage = 400
          return 400
        },
        set (value) {
          const connector = data.connectors[index]

          connector.voltage = value ? +value : undefined
        }
      }),
      justCable: computed(() => {
        const result = [
          CONNECTORS.CHADEMO,
          CONNECTORS.CCS1,
          CONNECTORS.CCS2,
          CONNECTORS.TYPE_1
        ].includes(data.connectors[index].type ?? CONNECTORS.TYPE_2)

        if (result) {
          // eslint-disable-next-line
          data.connectors[index].format = 'CABLE'
        }
        return result
      }),
      justSocket: computed(() => {
        const result = [
          CONNECTORS.SCHUKO,
          CONNECTORS.DOMESTIC_G,
          CONNECTORS.GBT_DC,
          CONNECTORS.TYPE_2
        ].includes(data.connectors[index].type ?? CONNECTORS.TYPE_2)

        if (result) {
          // eslint-disable-next-line
          data.connectors[index].format = 'SOCKET'
        }

        return result
      }),
      justMonoPhase: computed(() => {
        const result = [
          CONNECTORS.TYPE_1,
          CONNECTORS.SCHUKO,
          CONNECTORS.DOMESTIC_G,
          CONNECTORS.GBT_DC
        ].includes(data.connectors[index].type ?? CONNECTORS.TYPE_2)

        if (result) {
          // eslint-disable-next-line
          data.connectors[index].powerType = 'AC_1_PHASE'
        }

        return result
      }),
      description: computed(() => {
        return [
          compute.connectorsList.find(item => item.key === connector)?.label,
          data.connectors[index].maxPower && `${data.connectors[index].maxPower} ${t('mywb.common.kw')}`,
          data.connectors[index].amps && `${data.connectors[index].amps} ${t('mywb.common.amperage-unit')}`,
          data.connectors[index].voltage && `${data.connectors[index].voltage} ${t('mywb.common.voltage-unit')}`
        ].filter(Boolean).join(' . ')
      })
    }
  })
}

created()
</script>

<style lang="postcss" scoped>
.grid-content {
  display: grid;
  grid-template-columns: 44px auto min-content;
  grid-template-rows: 22px 22px;
  align-items: center;
}

.charge-point-icon {
  grid-column: 1;
  grid-row: 1 / span 2;
}

.charge-point-description {
  grid-row: 2;
}

.charge-point-edit {
  grid-row: 1 / span 2;
}

.type-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 16px;
  width: 100%;;
}

.brand {
  aspect-ratio: 1 / 1;
  display: flex;
  place-content: center;
  place-items: center;
  transition: all 100ms ease-in;
  cursor: pointer;
  text-align: center;

  &.active,
  &:hover {
    background: #232931 !important;
    color: white;
  }

  & ::deep(.content) {
    display: flex;
    flex-direction: column;
  }

  & .card-inside {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
}

</style>
