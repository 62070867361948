<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.sessions') }}
    </template>

    <template #actions>
      <wb-button
        :label="mq.current !== 'mobile' ? i18n.t('mywb.common.export') : ''"
        data-test-id="reportCta"
        icon="download"
        outlined
        @click="emit('click-download-report')"
      />

      <wb-button
        v-if="permissions.showAutoReporting"
        data-test-id="autoReportingCta"
        class="buttonReporting"
        icon="assignment"
        :label="mq.current !== 'mobile' ? i18n.t('mywb.sessions.reporting') : ''"
        @click="emit('show-autoreporting')"
      />
    </template>

    <template #filters>
      <sessions-filter />
    </template>
  </shared-teleport-header>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { useMq } from 'vue3-mq'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import SessionsFilter from '@/components/filters/SessionsFilter.vue'

const i18n = useI18n()
const mq = useMq()

interface Events {
  (e: 'show-autoreporting'): void,
  (e: 'click-download-report'): void
}
const emit = defineEmits<Events>()
</script>
