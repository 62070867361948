import i18n from '@/engine/lang'
import { PAYMENTS_ERROR } from '@/core/charger'

export function getPaymentsTranslations (paymentsError: PAYMENTS_ERROR) {
  return {
    [PAYMENTS_ERROR.OCPP]: i18n.global.t('mywb.error.ocpp-configured'),
    [PAYMENTS_ERROR.LOCATION_NOT_COMPLETE]: i18n.global.t('mywb.error.location-not-complete'),
    [PAYMENTS_ERROR.MODEL_NOT_COMPATIBLE]: i18n.global.t('mywb.error.charger-model-not-compatible'),
    [PAYMENTS_ERROR.NEEDS_UPDATE]: i18n.global.t('mywb.error.charger-needs-update')
  }[paymentsError]
}
