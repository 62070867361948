import {
  createPaymentsAccount,
  hasPaymentsAccount,
  deletePaymentsAccount,
  getLinkPaymentsAccount,
  wapiStripeRepository
} from '@/core/stripe'

export function provideStripeUseCases () {
  const repo = wapiStripeRepository()

  return {
    createPaymentsAccount: createPaymentsAccount(repo),
    hasPaymentsAccount: hasPaymentsAccount(repo),
    deletePaymentsAccount: deletePaymentsAccount(repo),
    getLinkPaymentsAccount: getLinkPaymentsAccount(repo)
  }
}
