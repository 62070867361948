import { object, string, type InferType } from 'yup'

const signInSchema = object({
  email: string().email().required(),
  password: string().required()
})

export type SignIn = InferType<typeof signInSchema>

export async function ensureSignInIsValid (payload: any) {
  return await signInSchema.validate(payload, { abortEarly: false })
}
