import type { Filter } from '@/core/shared'

export enum RateVariableTypeEnum {
  ACCESS = 'access',
  TIME = 'time',
  ENERGY = 'energy',
  RESERVATION_TIME = 'reservation_time'
}

export enum SessionType {
  FREE = 'free',
  PAY_PER_MONTH = 'pay_per_month',
  PAY_PER_CHARGE = 'pay_per_charge',
}

export interface Session {
  id: string
  userId: number
  userUid: string
  userName: string
  userSurname: string
  userEmail: string
  userAvatar: string
  startTime: number
  endTime: number
  chargingTime: number
  totalCost: number
  currencyCode: string
  taxAmount: number
  applicationFeePercentage: number
  taxPercentage: number
  sessionType: SessionType
  ratePrice: number
  accessPrice: number
  chargerUid: string
  chargerName: string
  locationName: string
  locationId: string
  organizationId: string
  energy: number
  midEnergy: number
  total: number
  subtotal: number
  rateVariableType: RateVariableTypeEnum
}

export interface SessionFilters {
  groups: Filter[]
  users: Filter[]
  chargers: Filter[]
  type: Filter[]
}
