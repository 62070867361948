<template>
  <div>
    <user-list-table
      v-if="compute.filteredUsers.length || compute.filteredUsersInvitations?.length || props.loading"
      :users="compute.filteredUsers"
      :users-invitation="compute.filteredUsersInvitations"
      :loading="props.loading"
      @remove-user="emit('remove-user', $event)"
      @on-click="emit('on-click', $event)"
      @on-resend-invitation="emit('resend-invitation', $event)"
      @on-revoke-invitation="emit('revoke-invitation', $event)"
    />
    <user-empty-state v-else @add-user="emit('add-user')" />
  </div>
</template>

<script setup lang="ts">
import UserListTable from '@/components/tables/UserListTable.vue'
import UserEmptyState from '@/components/emptyStates/UserEmptyState.vue'
import { reactive, computed } from 'vue'

import state from '@/state'
import type { EnumRoles } from '@/utilities/user-roles'
import type { UserList } from '@/core/user'

interface Props {
  users: UserList[]
  usersInvitation?: UserList[]
  loading?: boolean
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-edit-group'): void,
  (e: 'on-delete-group'): void,
  (e: 'remove-user', user: UserList): void,
  (e: 'on-click', uid: string): void,
  (e: 'resend-invitation', payload: { invitationId: number }): void,
  (e: 'revoke-invitation', payload: { invitationId: number }): void,
  (e: 'add-user'): void,
}
const emit = defineEmits<Events>()

const compute = reactive({
  filteredUsers: computed(() => {
    const reducers = {
      groups: methods.filterByGroup,
      role: methods.filterByProfile,
      users: methods.filterByUsers,
      text: methods.filterByText,
      status: methods.filterByStatus
    }

    const entries = Object.entries(state.filters.accessConfigFilters) as
      Array<[keyof typeof reducers, never]>

    return entries.reduce((accessConfigFilters, [type, value]) =>
      reducers[type](accessConfigFilters, value), props.users
    )
  }),

  filteredUsersInvitations: computed(() => {
    const reducers = {
      groups: methods.filterByGroup,
      role: methods.filterByProfile,
      users: methods.filterByUsers,
      text: methods.filterByText,
      status: methods.filterByStatus
    }

    const entries = Object.entries(state.filters.accessConfigFilters) as
      Array<[keyof typeof reducers, never]>

    return entries.reduce((accessConfigFilters, [type, value]) =>
      reducers[type](accessConfigFilters || [], value), props.usersInvitation
    )
  })
})

const methods = {
  filterByProfile (users: UserList[], userRole: EnumRoles) {
    if (!userRole) return users

    return users.filter(user => user.role === userRole)
  },

  filterByUsers (users: UserList[], usersId: number[]) {
    if (!usersId.length) return users

    return users.filter(user => usersId.includes(user.id ?? -1))
  },

  filterByStatus (users: UserList[], value: string) {
    if (!value) return users

    return users.filter(
      user => user.status === value
    )
  },

  filterByGroup (users: UserList[], filterGroups: number[]) {
    if (!filterGroups.length) return users
    return users.filter(user => filterGroups.includes(user.accessGroup?.id ?? -1))
  },

  filterByText (users: UserList[], text: string) {
    if (!text) return users

    return users.filter(user => {
      return (
        user.name?.toLowerCase().includes(text.toLowerCase()) ||
        user.surname?.toLowerCase().includes(text.toLowerCase()) ||
        user.email.toLowerCase().includes(text.toLowerCase())
      )
    })
  }
}
</script>
