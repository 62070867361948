<template>
  <wb-card
    :headline="props.hasTitle ? i18n.t('mywb.common.billing-information') : undefined"
    alignment="center"
    :filled="props.cardFilled"
    :loading="data.firstLoading"
  >
    <template v-if="data.billingInfo" #content>
      <wb-card filled>
        <template #content>
          <template v-if="!data.billingInfo?.company">
            {{ data.billingInfo?.name }} {{ data.billingInfo?.surname }}
          </template>
          <template v-else>
            {{ data.billingInfo?.name }} - {{ data.billingInfo?.cif }}
          </template>
          <br>
          {{ data.billingInfo?.address }}, {{ data.billingInfo?.city }}, {{ data.billingInfo?.zipCode }},
          <span v-if="data.billingInfo?.state">{{ data.stateName }}, </span>
          <span v-if="compute.countryParsed" class="is-capitalized">
            {{ compute.countryParsed }}
          </span>
        </template>

        <template #actions>
          <wb-button
            variant="white"
            outlined
            data-test-id="editBtn"
            icon="edit"
            class="edit"
            :label="i18n.t('mywb.common.edit')"
            @click="data.openBillingInformationModal = true"
          />
        </template>
      </wb-card>
    </template>

    <template v-else #[compute.dynamicSlotButton]>
      <wb-button
        variant="white"
        outlined
        :label="i18n.t('mywb.common.add-billing-information')"
        icon="add"
        data-test-id="editBtn"
        :class="{ center: !props.hasTitle }"
        @click="data.openBillingInformationModal = true"
      />
    </template>

    <template #modal>
      <billing-information-modal
        v-if="data.openBillingInformationModal"
        :billing-info="data.billingInfo"
        :group-uid="props.groupUid"
        @on-updated="methods.handleUpdate"
        @on-close="data.openBillingInformationModal = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import BillingInformationModal from '@/components/modals/BillingInformationModal.vue'

import { reactive, onMounted, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { injectStrict, INTERNATIONAL_USE_CASES, ORGANIZATION_USE_CASES } from '@/engine/injectors'
import type { CountryIso3 } from '@/core/international'
import type { BillingInfo } from '@/core/organization'

const internationalUseCases = injectStrict(INTERNATIONAL_USE_CASES)
const organizationUseCases = injectStrict(ORGANIZATION_USE_CASES)

const i18n = useI18n()

interface Events {
  (e: 'update:has-billing-info', hasBillingInfo: boolean): void,
  (e: 'on-updated'): void,
}
const emit = defineEmits<Events>()

interface Props {
  hasTitle?: boolean,
  cardFilled?: boolean,
  groupUid: string
}
const props = withDefaults(defineProps<Props>(), {
  cardFilled: false,
  hasTitle: true
})

interface DataType {
  openBillingInformationModal: boolean
  loading: boolean
  firstLoading: boolean
  billingInfo?: BillingInfo,
  stateName: string,
  optionsState: Array<{ name: string, iso2: string }>
}

const data: DataType = reactive({
  openBillingInformationModal: false,
  loading: false,
  firstLoading: false,
  hasBillingInfo: false,
  stateName: '',
  optionsState: []
})

const compute = reactive({
  countryParsed: computed(() => {
    if (!data.billingInfo?.country) return ''
    return state.global.getCountries.find(country => country.iso2 === data.billingInfo?.country.iso2)?.name || null
  }),

  dynamicSlotButton: computed(() => {
    return props.hasTitle ? 'actions' : 'content'
  })
})

const methods = {
  handleUpdate () {
    methods.loadComponent()
    emit('on-updated')
  },

  async getStates (iso3: CountryIso3) {
    data.optionsState = await internationalUseCases.getAllStates(iso3)
  },

  findState (iso2: string) {
    return data.optionsState.find(state => state.iso2 === iso2)?.name || ''
  },

  async loadComponent () {
    data.firstLoading = true

    const billingInfo = await organizationUseCases.getBillingInfo(props.groupUid)

    emit('update:has-billing-info', !!billingInfo)

    if (billingInfo) {
      data.billingInfo = billingInfo
    }

    if (data.billingInfo && billingInfo?.country) {
      await methods.getStates(billingInfo.country.iso3)
      data.stateName = methods.findState(billingInfo.state)
    }

    data.firstLoading = false
  }
}

onMounted(async () => {
  await methods.loadComponent()
})
</script>

<style lang="postcss" scoped>
.center {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
