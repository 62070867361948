<template>
  <div v-if="permissions.hasAuthToRestartAndUpdate" class="charger-actions g-8">
    <wb-button
      variant="white"
      outlined
      data-test-id="updateChargerButton"
      icon="download_circle"
      :loading="!!compute.isUpdating"
      :disabled="props.loading || compute.isRemoteActionDisabled || compute.isUpdating || !compute.softwareUpdate"
      :label="mq.current === 'desktop' ? i18n.t('mywb.common.update') : ''"
      @click="methods.handleRemoteActions()"
    />
    <wb-button
      variant="white"
      outlined
      icon="refresh"
      :label="mq.current === 'desktop' ? i18n.t('mywb.common.restart') : ''"
      :loading="!!compute.isRestarting"
      :disabled="props.loading || compute.isRemoteActionDisabled || compute.isRestarting"
      data-test-id="restartChargerButton"
      @click="data.isModalRestartActionsOpen = true"
    />

    <teleport to="body">
      <chargers-update-action-modal
        v-if="data.isModalUpdateActionsOpen"
        :chargers="[state.charger.getChargerCore]"
        @close="data.isModalUpdateActionsOpen = false"
      />

      <chargers-restart-action-modal
        v-if="data.isModalRestartActionsOpen"
        :chargers="[state.charger.getChargerCore]"
        @close="data.isModalRestartActionsOpen = false"
      />
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { STATUSES } from '@/utilities/charger/chargerStatuses'

import { isChargerRemoteAction, REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import { permissions } from '@/engine/clients'
import { computed, reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import ChargersUpdateActionModal from '@/components/modals/ChargersUpdateActionModal.vue'
import ChargersRestartActionModal from '@/components/modals/ChargersRestartActionModal.vue'
import { useMq } from 'vue3-mq'
import state from '@/state'

const i18n = useI18n()
const mq = useMq()

interface Props {
  loading?: boolean
}
const props = defineProps<Props>()

interface Data {
  isModalUpdateActionsOpen: boolean,
  isModalRestartActionsOpen: boolean
}
const data = reactive<Data>({
  isModalUpdateActionsOpen: false,
  isModalRestartActionsOpen: false
})

const compute = reactive({
  isUpdating: computed(() => isChargerRemoteAction(state.charger.getChargerCore, REMOTE_ACTIONS.UPDATE)),

  isRestarting: computed(() => isChargerRemoteAction(state.charger.getChargerCore, REMOTE_ACTIONS.RESTART)),

  isRemoteActionDisabled: computed(() => {
    if (!state.charger.getChargerCore) return true

    return state.charger.getChargerCore.status?.code === STATUSES.DISCONNECTED ||
    state.charger.getChargerCore.status?.code === STATUSES.CHARGING ||
    state.charger.getChargerCore.status?.code === STATUSES.DISCHARGING
  }),

  softwareUpdate: computed(() => isUpdatesAvailable(state.charger.getChargerCore))
})

const methods = {
  handleRemoteActions () {
    data.isModalUpdateActionsOpen = true
  }
}
</script>

<style lang="postcss" scoped>
.charger-actions {
  justify-self: end;
  display: grid;
  grid-template-columns: min-content min-content;
  max-height: 42px;
}
</style>
