import type { UseCase } from '@/core/shared'
import type { UserRepository } from '@/core/user'

export const getAllEmails: UseCase<UserRepository, 'getAllByOrganization', Promise<string[]>> = repository => {
  return async (organizationUid) => {
    const invitations = await repository.getAllInvitations(organizationUid)
    const users = await repository.getAllByOrganization(organizationUid)

    return [...users, ...invitations].map(obj => obj.email)
  }
}
