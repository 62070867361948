<template>
  <wb-card
    :headline="props.title ? props.title : i18n.t('mywb.common.report')"
    :subhead="props.subtitle"
  >
    <template #place-upper-right>
      <div class="is-flex is-align-center g-8">
        <wb-badge variant="blue">
          {{ i18n.t('mywb.common.data-in-utc', [compute.timezoneOffset]) }}
        </wb-badge>
        <span class="wb-icons chart-icon">
          {{ props.icon }}
        </span>
      </div>
    </template>
    <template #content>
      <div class="is-flex">
        <wb-button-groups v-if="!props.calendar && !props.dates" shape="squircle">
          <div class="group-item">
            <wb-date-picker
              v-model="data.activeDates"
              data-test-id="calendar"
              :min-date="data.datesOptions.minDate"
              :max-date="data.datesOptions.today"
              :year-range="[2015, data.datesOptions.today.getFullYear()]"
              :multi-calendars="mq.current !== 'mobile'"
              range
              :max-range="364"
              :locale="lang.__rootLanguage"
              :clearable="false"
              :auto-apply="false"
              :cancel-text="i18n.t('mywb.common.cancel')"
              :apply-text="i18n.t('mywb.common.apply')"
            >
              <template #input="{ value }">
                <wb-dropdown tooltip-position="top">
                  <template #activator>
                    <wb-button
                      :active="!!data.activeDates.length"
                      icon="calendar"
                      :size="props.buttonSize"
                      :label="value ? value : i18n.t('mywb.common.custom')"
                      variant="white"
                    />
                  </template>
                  <template v-if="data.activeDates.length" #tooltip>
                    {{ i18n.t('mywb.common.num-days', [daysDiff(data.activeDates[0], data.activeDates[1])]) }}
                  </template>
                </wb-dropdown>
              </template>
            </wb-date-picker>
          </div>

          <wb-dropdown
            v-for="option in data.chartOptions.calendar"
            :key="option.id"
            class="group-item"
            tooltip-position="top"
          >
            <template #activator>
              <wb-button
                :label="option.label"
                :size="props.buttonSize"
                variant="white"
                :active="option.id === data.activeCalendar.id && !data.activeDates.length"
                @click="methods.handleIntervalOption(option)"
              />
            </template>
            <template #tooltip>
              {{ getIntervalLabel(option) }}
            </template>
          </wb-dropdown>
        </wb-button-groups>
      </div>
      <div v-if="!props.hideOptions" class="is-grid-header-top mb-8 mt-8">
        <wb-button-groups v-if="compute.categoriesParsed.length > 1" class="mb-8" shape="squircle">
          <wb-button
            v-for="(category, key) in compute.categoriesParsed"
            :key="key"
            class="group-item"
            :active="data.activeCategory === category.value"
            :size="props.buttonSize"
            :label="category.label"
            variant="white"
            @click="data.activeCategory = category.value"
          />
        </wb-button-groups>
        <div class="is-flex g-8 mb-8">
          <wb-button-groups v-if="!props.visualization || !props.analysis" shape="squircle">
            <shared-filter
              v-if="!props.visualization"
              v-model="data.activeVisualization"
              :size="props.buttonSize"
              option-key="value"
              :options="data.chartOptions.visualization"
              :reduce="(item: typeof data.chartOptions.type[number]) => item.value"
              non-clearable
              non-active-status
              non-sorted-options
              auto-selected
              class="group-item"
            >
              <template #option="{ option }">
                <div class="is-grid-option g-8">
                  <span class="wb-icons is-size-500">{{ option.icon }}</span>
                  {{ option.label }}
                </div>
              </template>
            </shared-filter>

            <shared-filter
              v-if="!props.analysis"
              v-model="data.activeAnalysis"
              :size="props.buttonSize"
              option-key="value"
              :options="data.chartOptions.analysis"
              :reduce="(item: typeof data.chartOptions.analysis[number]) => item.value"
              non-clearable
              non-active-status
              non-sorted-options
              auto-selected
              class="group-item"
            >
              <template #option="{ option }">
                <div class="is-grid-option g-8">
                  <span class="wb-icons is-size-500">{{ option.icon }}</span>
                  {{ option.label }}
                </div>
              </template>
            </shared-filter>
          </wb-button-groups>

          <shared-filter
            v-if="!props.periodicity"
            v-model="data.activePeriodicity"
            :size="props.buttonSize"
            option-key="value"
            :options="data.chartOptions.periodicity"
            :reduce="(item: Record<string, string>) => item.value"
            non-clearable
            non-active-status
            non-sorted-options
            auto-selected
          />

          <wb-dropdown tooltip-position="top">
            <template #activator>
              <wb-button
                icon="download"
                variant="white"
                :size="props.buttonSize"
                outlined
                shape="squircle"
                @click="methods.saveToArchive()"
              />
            </template>
            <template #tooltip>
              {{ i18n.t('mywb.common.export') }}
            </template>
          </wb-dropdown>
        </div>
      </div>

      <slot name="content" />

      <div v-if="props.showHeader">
        <div
          class="is-size-900 has-line-height"
          :class="{'mb-16': data.activeCategory === ChartTypesEnum.SESSIONS}"
        >
          {{ compute.accumData.sum }}
        </div>

        <div v-if="data.activeCategory !== ChartTypesEnum.SESSIONS" class="is-size-300 has-text-grey-400">
          <span>
            {{ i18n.t('mywb.common.avg-per-session') }}
          </span>
          {{ compute.accumData.avg }}
        </div>
      </div>

      <wb-chart
        :labels="data.labels"
        :colors="data.chartTypes[data.activeCategory as keyof typeof chartTypes].colors || []"
        :options="chart.options"
        :series="compute.series"
        :y-formatter="(info: any) => methods.dataFormatter(info, true)"
        :custom-tooltip="methods.customTooltip"
        :empty-state-label="props.loading ? i18n.t('mywb.common.loading') : ''"
      />

      <div v-if="props.showFooter" class="is-grid-footer">
        <div>
          <div class="has-text-grey-900 is-font-weight-500">
            {{ compute.accumData.sum }}
          </div>
          <span class="is-size-300">
            {{ i18n.t('mywb.common.in-n-sessions', { num: compute.numSessions }) }}
          </span>
        </div>
        <div class="right">
          <div class="has-text-grey-900 is-font-weight-500">
            {{ compute.accumData.avg }}
          </div>
          <span class="is-size-300">
            {{ i18n.t('mywb.common.avg-per-session') }}
          </span>
        </div>
      </div>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { numbers, time, download, type TooltipCustom } from '@wallbox/toolkit-ui'
import { reactive, computed, watch, onMounted } from 'vue'
import { useMq } from 'vue3-mq'
import lang from '@/engine/lang'
import { useI18n } from '@/hooks/useI18n.hook'
import { toCurrencyWithUnits } from '@/utilities/currency'
import { getEnergyFromKiloWatts } from '@/utilities/energy'
import { getData } from '@/utilities/chartSessions/chart'
import {
  calendarOptions,
  periodicityOptions,
  chartTypes,
  visualizationOptions,
  analysisOptions,
  categoriesOptions
} from '@/utilities/chartSessions/chartOptions'
import {
  ChartTypesEnum,
  PeriodicityEnum,
  AnalysisEnum,
  VisualizationEnum
} from '@/utilities/chartSessions/chartTypeEnums'
import type { ChartSeries, CalendarType } from '@/utilities/chartSessions/chart.types'

import SharedFilter from '@/components/filters/SharedFilter.vue'
import {
  getIncrement,
  getIncrementPercentage,
  getIncrementColorClass,
  getIncrementSuffix,
  getTooltipDate
} from '@/utilities/chartSessions/tooltip/chartTooltip'
import { daysDiff, getIntervalLabel } from '@/utilities/chartSessions/dateIntervals'
import ChartTooltip from '@/utilities/chartSessions/tooltip/ChartTooltip.html?raw'
import ChartSerieTooltip from '@/utilities/chartSessions/tooltip/ChartSerieTooltip.html?raw'
import { arrayToCSV } from '@/utilities/csv'
import state from '@/state'

const i18n = useI18n()
const mq = useMq()

interface PropsType {
  title?: string
  subtitle?: string
  icon?: string
  iconColor?: string
  labels?: Date[]
  series?: ChartSeries
  chargerId?: number
  dates?: Date[]
  calendar?: CalendarType
  categories?: ChartTypesEnum[]
  analysis?: AnalysisEnum
  visualization?: VisualizationEnum
  periodicity?: PeriodicityEnum
  showFooter?: boolean
  showHeader?: boolean
  loading?: boolean
  buttonSize?: 'small' | 'normal'
  hideOptions?: boolean
  chartHeight?: number
  defaultVisualization?: VisualizationEnum
}

const props = withDefaults(defineProps<PropsType>(), {
  title: '',
  subtitle: '',
  labels: undefined,
  icon: 'dashboard',
  iconColor: 'var(--grey-400)',
  series: undefined,
  chargerId: undefined,
  dates: undefined,
  calendar: undefined,
  analysis: undefined,
  categories: () => [],
  visualization: undefined,
  periodicity: undefined,
  showFooter: false,
  showHeader: false,
  loading: false,
  buttonSize: 'small',
  hideOptions: false,
  chartHeight: 300,
  defaultVisualization: VisualizationEnum.AREA
})

interface Data {
  activeDates: Date[]
  activeCalendar: CalendarType
  activeCategory: ChartTypesEnum
  activeAnalysis: AnalysisEnum
  activePeriodicity: PeriodicityEnum
  activeVisualization: VisualizationEnum
  series: ChartSeries
  labels: Date[]
  chartTypes: typeof chartTypes
  chartOptions: {
    categories: ReturnType<typeof categoriesOptions>
    visualization: typeof visualizationOptions
    analysis: typeof analysisOptions
    periodicity: typeof periodicityOptions
    calendar: typeof calendarOptions
  },
  datesOptions: {
    minDate: Date,
    today: Date
  }
}

const oneYearBefore = new Date()
oneYearBefore.setFullYear(new Date().getFullYear() - 1)

const data = reactive<Data>({
  activeDates: props.dates || [],
  activeCalendar: props.calendar || calendarOptions[2],
  activeCategory: props.categories[0] || ChartTypesEnum.ENERGY,
  activeAnalysis: props.analysis || AnalysisEnum.LINEAR,
  activePeriodicity: props.periodicity || PeriodicityEnum.DAY,
  activeVisualization: props.visualization || props.defaultVisualization,
  series: {},
  labels: [],
  chartTypes,
  chartOptions: {
    categories: categoriesOptions(),
    visualization: visualizationOptions,
    analysis: analysisOptions,
    periodicity: periodicityOptions,
    calendar: calendarOptions
  },
  datesOptions: {
    minDate: oneYearBefore,
    today: new Date()
  }
})

const chart = reactive<any>({
  options: {
    stroke: { curve: 'straight', width: 2.4 },
    markers: { size: 1, strokeWidth: 0, hover: { sizeOffset: 4 } },
    chart: {
      type: data.activeVisualization,
      height: props.chartHeight,
      animations: { enabled: false },
      offsetX: -30
    },
    fill: {
      type: 'gradient',
      gradient: { shade: 'light', type: 'vertical', opacityFrom: 0.6, opacityTo: 0.1, stops: [0, 90, 100] }
    },
    yaxis: { tickAmount: 4, logBase: 10, labels: { minWidth: 50 }, forceNiceScale: true },
    xaxis: {
      tickAmount: 12,
      axisTicks: { show: true },
      crosshairs: {
        show: true,
        stroke: {
          color: 'var(--grey-200)',
          dashArray: 0
        }
      },
      labels: {
        formatter: (date: Date) => methods.labelFormatter(date),
        offsetY: 1,
        datetimeUTC: true
      }
    },
    plotOptions: { bar: { columnWidth: '80%' } },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 5,
      offsetX: -16,
      itemMargin: { horizontal: 16, vertical: 6 }
    }
  }
})

const compute = reactive({
  categoriesParsed: computed(() => {
    if (!props.categories.length) return data.chartOptions.categories
    return data.chartOptions.categories.filter(category => {
      return props.categories && props.categories.indexOf(category.value) > -1
    })
  }),

  timezoneOffset: computed(() => {
    const offset = new Date().getTimezoneOffset()
    return Math.sign(offset) > 0 ? '+' : '-' + Math.abs(offset / 60) + 'h'
  }),

  numSessions: computed((): number => {
    if (!data.series.sessions) return 0
    return data.series.sessions[0]
      ? Object.values(data.series.sessions[0].data).reduce((a: number, b: number) => a + b, 0)
      : 0
  }),

  accumData: computed((): any => {
    if (data.series[data.activeCategory]) {
      let sum = 0
      data.series[data.activeCategory].forEach(serie => {
        sum += Object.values(serie.data).reduce((a: number, b: number) => a + b, 0)
      })

      const avg = (sum / compute.numSessions) || 0
      return {
        sum: methods.dataFormatter(sum),
        avg: methods.dataFormatter(avg)
      }
    }
    return { sum: '-', avg: '-' }
  }),

  series: computed(() => {
    if (data.activeAnalysis === AnalysisEnum.CUMULATIVE) {
      return data.series[data.activeCategory].map(serie => {
        return {
          name: serie.name,
          data: Object.values(serie.data).map((sum => (value: number) => (sum += value))(0))
        }
      })
    }

    return data.series[data.activeCategory] || [{ name: '', data: [] }]
  })
})

const methods = {
  labelFormatter (date: Date) {
    let label = ''

    if (date instanceof Date && !isNaN(date.getTime())) {
      const monthShort = new Intl.DateTimeFormat(i18n.locale.value, { month: 'short' }).format(date)
      switch (data.activePeriodicity) {
        case PeriodicityEnum.DAY:
        case PeriodicityEnum.WEEK:
          label = `${date.getDate()} ${monthShort}`
          break
        case PeriodicityEnum.HOUR:
          label = `${date.getDate()} ${monthShort} ${date.getHours()}:00h`
          break
        default:
          label = monthShort
      }
    }
    return label
  },

  dataFormatter (value: number, simpleInfo = false) {
    if (!value) return ''
    const aDayInSeconds = 24 * 60 * 60
    const anHourInSeconds = 60 * 60
    const maxValue = Math.max(...compute.series.flatMap(item => item.data))

    switch (data.activeCategory) {
      case ChartTypesEnum.ENERGY:
        return getEnergyFromKiloWatts(value)
      case ChartTypesEnum.TIME:
        if (!simpleInfo) {
          return time.getTimeDurationString(value, ['d', 'h', 'm'])
        }

        if (maxValue > aDayInSeconds) {
          return time.getTimeDurationString(value, ['d'])
        }

        if (maxValue > anHourInSeconds) {
          return time.getTimeDurationString(value, ['h'])
        }

        return time.getTimeDurationString(value, ['m'])
      case ChartTypesEnum.COST:
      case ChartTypesEnum.INCOME:
        return toCurrencyWithUnits(
          +value,
          state.organizations.getCurrentOrganization.currencyCode,
          i18n.locale.value
        )
      default:
        return value.toString()
    }
  },

  dataUnit () {
    switch (data.activeCategory) {
      case ChartTypesEnum.ENERGY:
        return i18n.t('mywb.common.kwh')
      case ChartTypesEnum.TIME:
        return i18n.t('mywb.common.seconds')
      case ChartTypesEnum.COST:
      case ChartTypesEnum.INCOME:
        return state.organizations.getCurrentOrganization.currencyCode
      default:
        return ''
    }
  },
  customTooltip ({ series, dataPointIndex }: Parameters<TooltipCustom>[0]) {
    const date = data.labels[dataPointIndex]
    let str = ''
    series.forEach((serie, key) => {
      if (!serie) return

      const increment = getIncrement(serie[dataPointIndex - 1] as any, serie[dataPointIndex] as any)
      str += ChartSerieTooltip
        .replace('{color}', data.chartTypes[data.activeCategory as keyof typeof chartTypes].colors[key])
        .replace('{label}', data.chartTypes[data.activeCategory as keyof typeof chartTypes].label)
        .replace('{value}', methods.dataFormatter(serie[dataPointIndex] as any ?? 0))
        .replace('{increment}', getIncrementPercentage(increment) as string)
        .replace('{incrementColor}', getIncrementColorClass(increment))
        .replace('{incrementSuffix}', getIncrementSuffix(data.activePeriodicity))
    })

    return ChartTooltip
      .replace('{label}', data.chartTypes[data.activeCategory as keyof typeof chartTypes].label)
      .replace('{date}', getTooltipDate(date, data.activePeriodicity))
      .replace('{serie}', str)
  },

  async getData () {
    const { labels, series } = await getData(
      data.activePeriodicity,
      data.activeCalendar.interval,
      data.activeDates,
      props.chargerId
    )
    data.labels = labels
    data.series = series
  },

  saveToArchive () {
    const arr = data.labels.map((date: Date, key: number) => {
      let obj = {
        [`${i18n.t('mywb.common.date')} (UTC)`]:
          data.activePeriodicity === PeriodicityEnum.MONTH
            ? new Intl.DateTimeFormat(i18n.locale.value, { month: '2-digit', year: 'numeric' }).format(date)
            : new Intl.DateTimeFormat(i18n.locale.value, { dateStyle: 'short' }).format(date)
      }

      data.series[data.activeCategory].forEach(serie => {
        const decimals = serie.data[key] as number % 1 !== 0 ? 3 : 0
        obj = {
          ...obj,
          [`${serie.name} (${methods.dataUnit()})`]:
            numbers.toDecimal(serie.data[key] as number, i18n.locale.value, decimals, decimals)
        }
      })
      return obj
    })

    const blob = new Blob([arrayToCSV(arr)], { type: 'text/csv;charset=utf-8;' })
    download.downloadBlob(blob, `${chartTypes[data.activeCategory].label}.csv`)
  },

  handleIntervalOption (option: typeof calendarOptions[0]) {
    data.activeCalendar = option
    data.activeDates = []
  }
}

watch(() => data.activeVisualization, async () => {
  chart.options.chart.type = data.activeVisualization
  if (data.activeVisualization === VisualizationEnum.AREA) {
    chart.options.fill.type = 'gradient'
    chart.options.stroke.width = 2.5
  } else if (data.activeVisualization === VisualizationEnum.LINE) {
    chart.options.fill.type = 'solid'
    chart.options.stroke.width = 2.5
  } else {
    chart.options.fill.type = 'solid'
    chart.options.stroke.width = 0
  }
}, { immediate: true })

watch(() => [data.activePeriodicity, data.activeCalendar, data.activeDates], async () => {
  if (!props.labels && !props.series) {
    methods.getData()
  }
}, { deep: true })

watch(() => props.periodicity, async () => {
  if (props.periodicity) {
    data.activePeriodicity = props.periodicity
  }

  chart.options.xaxis.labels.formatter = (date: Date) => methods.labelFormatter(date)
})

watch(() => props.calendar, async () => {
  if (!props.calendar) return
  data.activeCalendar = props.calendar
})

watch(() => props.dates, async () => {
  if (!props.dates) return
  data.activeDates = props.dates
})

watch(() => props.analysis, async () => {
  if (!props.analysis) return
  data.activeAnalysis = props.analysis
})

watch(() => [props.labels, props.series], async () => {
  if (props.labels) {
    data.labels = props.labels
  }

  if (props.series) {
    data.series = props.series
  }
})

onMounted(async () => {
  if (!props.labels && !props.series) {
    methods.getData()
  }
})
</script>

<style lang="postcss" scoped>
.is-grid-header-top {
  display: grid;
  grid-template-columns: auto;
  align-content: space-around;
  justify-content: space-between;

  @media (min-width: 1300px) {
    grid-template-columns: min-content min-content;
  }
}

.is-grid-option {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
}

.is-grid-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;

  & .right {
    justify-self: end;
    text-align: right;
  }
}

.chart-icon {
  color: v-bind(props.iconColor);
  position: relative;
  font-size: var(--size-700);
  margin: 0.8rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: currentColor;
    opacity: 0.07;
    width: 36px;
    height: 36px;
    border-radius: 99px;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

.has-line-height {
  line-height: 28px;
}

:deep(.filter-item) {
  font-size: 10px !important;
  padding: 1px 6px !important;
}
</style>
