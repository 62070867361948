<template>
  <div>
    <charger-settings-assign-rate
      v-if="props.paymentsCompatible"
      id="payPerCharge"
      class="my-8"
      :class="{ 'selected-blink': props.selected === 'payPerCharge'}"
      :charger="state.charger.getChargerCore"
      :has-autolock="state.charger.getChargerCore.autoLock"
      :schedule="data.schedules.find(schedule => schedule.paymentType === 'pay_per_charge')"
      :rates="data.rates"
      type="payPerCharge"
      data-test-id="payPerChargeSection"
      :compatible="props.paymentsCompatible?.payPerCharge"
      allow-hourly-selection
    >
      <charger-pay-per-charge-resources />
    </charger-settings-assign-rate>

    <charger-settings-assign-rate
      v-if="props.paymentsCompatible"
      id="payPerMonth"
      :charger="state.charger.getChargerCore"
      :class="{ 'selected-blink': props.selected === 'payPerMonth'}"
      :has-autolock="state.charger.getChargerCore.autoLock"
      :rates="data.rates"
      :schedule="data.schedules.find(schedule => schedule.paymentType === 'pay_per_month')"
      :compatible="props.paymentsCompatible?.payPerMonth"
      allow-hourly-selection
      type="payPerMonth"
      data-test-id="payPerMonthSection"
    />
  </div>
</template>

<script setup lang="ts">
import ChargerSettingsAssignRate from '@/components/charger/ChargerSettingsAssignRate.vue'
import ChargerPayPerChargeResources from '@/components/charger/ChargerPayPerChargeResources.vue'
import state from '@/state'
import { reactive, watch } from 'vue'
import type { Rate, ChargerRateWeekSchedule } from '@/core/rate'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import type { ChargerPaymentsCompatible } from '@/core/charger/domain/ChargerPaymentsCompatible'

const rateUseCases = injectStrict(RATE_USE_CASES)

interface PropsType {
  paymentsCompatible: ChargerPaymentsCompatible
  loading?: boolean
  selected: string
}

const props = defineProps<PropsType>()

interface Data {
  rates: Rate[]
  schedules: ChargerRateWeekSchedule[]
  loading: boolean
}

const data: Data = reactive({
  rates: [],
  schedules: [],
  loading: false
})

watch([() => props.loading, () => state.charger.getChargerCore.autoLock === false], async () => {
  if (props.loading) return

  if (props.paymentsCompatible.payPerCharge.isCompatible || props.paymentsCompatible.payPerMonth.isCompatible) {
    const { data: rates } = await rateUseCases.getAllRates({
      groupUid: state.organizations.getCurrentOrganization.groupUid
    })
    data.rates = rates
    data.schedules = await rateUseCases.getChargerRatesWeekSchedules(state.charger.getChargerCore.uid ?? '')
  }
}, { immediate: true })
</script>
