<template>
  <div>
    <payments-history-table
      :invoices="data.invoices"
      :fees="data.fees"
      :loading="data.loading"
      @on-update-data="methods.loadData"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import PaymentsHistoryTable from '@/components/tables/PaymentsHistoryTable.vue'
import state from '@/state'
import { INVOICES_USE_CASES, injectStrict } from '@/engine/injectors'
import type { FeeInvoice, PlanInvoice } from '@/core/invoices'

const invoiceUseCases = injectStrict(INVOICES_USE_CASES)

interface Data {
  invoices: PlanInvoice[]
  fees: FeeInvoice[]
  loading: boolean
}
const data = reactive<Data>({
  invoices: [],
  fees: [],
  loading: false
})

const methods = {
  async loadData () {
    data.loading = true

    data.fees = (await invoiceUseCases.getAllFeeInvoices(
      state.organizations.getCurrentOrganization.groupUid,
      {
        limit: 9999,
        offset: 0
      }
    )).data
    data.invoices = await invoiceUseCases.getAllPlanInvoices(state.organizations.getCurrentOrganization.groupUid)
    data.loading = false
  }
}

function created () {
  methods.loadData()
}

created()
</script>
