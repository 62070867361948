import type { ApiErrorResponse } from '@wallbox/http'
import {
  trackScreen,
  trackAction,
  trackError,
  trackEvent,
  trackTime
} from '@wallbox/metrics'

import state from '@/state'
import type { AxiosError } from 'axios'

const getCurrentUserLogged = () => {
  const userId = state.global.getUserId
  const user = state.user.userLogged

  if (user.id) {
    return {
      user_uid: userId,
      user_id: user.id,
      user_type: state.organizations.getCurrentOrganization.role.name
    }
  }
}

const getCharger = () => {
  const charger = state.charger.getChargerCore

  if (charger.name) {
    return {
      charger_id: charger.id,
      charger_name: charger.name,
      charger_serial_number: charger.serialNumber,
      charger_model: charger.modelName,
      charger_connector_type: charger.connectorType,
      charger_software_version: charger.software?.currentVersion,
      charger_software_update_available: charger.software?.updateAvailable,
      charger_mid: charger.mid.code,
      charger_power_sharing: charger.powerSharingStatus,
      charger_max_available_current: charger.maxAvailableAmps,
      charger_max_charging_current: charger.maxAmps,
      charger_autolock: charger.autoLock,
      charger_autolock_time: charger.autoLockTime,
      charger_ocpp: charger.ocppProtocol
    }
  }
}

const getPreviousRoute = () => {
  return {
    previousRoute: state.routes.routes[state.routes.routes.length - 2]?.fullPath
  }
}

const getCurrentOrganization = () => {
  const organization = state.organizations.getCurrentOrganization

  if (organization) {
    return {
      organization_id: organization.id,
      organization_name: organization.name,
      organization_type: organization.organizationType ?? '-',
      plan: organization.plan.name || ''
    }
  }
}

export const trackDataScreen = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackScreen(eventName, params)
}

export const trackDataAction = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackAction(eventName, params)
}

export const trackDataEvent = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    ...params
  }
  trackEvent(eventName, params)
}

export const trackDataError = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    ...params
  }

  trackError(eventName, params)
}

export const trackDataApiErrorResponse = (error: AxiosError<ApiErrorResponse>) => {
  const params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    url: error.response?.config?.url,
    data: error.response?.data,
    status: error.response?.status
  }
  trackError('api', params)
}

export const trackDataTime = (eventName: string, params = {}) => {
  params = {
    ...getCurrentOrganization(),
    ...getCurrentUserLogged(),
    ...getCharger(),
    ...getPreviousRoute(),
    ...params
  }

  trackTime(eventName, params)
}
