import {
  perseusChargePointRepository,
  getAllBrandingInfo,
  createChargePoint,
  getAllChargePoint
} from '@/core/chargePoint'

export function provideChargerPointUseCases () {
  const repo = perseusChargePointRepository()

  return {
    getAllBrandingInfo: getAllBrandingInfo(repo),
    createChargePoint: createChargePoint(repo),
    getAllChargePoint: getAllChargePoint(repo)
  }
}
