<template>
  <div>
    <shared-teleport-header>
      <template #custom-title>
        <charger-header
          class="header"
        />
      </template>

      <template #actions>
        <charger-header-actions
          :loading="data.loading"
        />
      </template>

      <template #tabs>
        <wb-tabs
          v-if="!data.loading"
          v-model="data.tabSelected"
          :tabs="compute.sections"
          @tab-change="methods.changeTab"
        />
      </template>
    </shared-teleport-header>

    <enable-auto-refresh-component
      v-if="!isInRealTime"
      class="mb-16"
      @enable-auto-refresh="startRealTime"
    />

    <router-view v-slot="{ Component }">
      <container-component>
        <component
          :is="Component"
          :charger="state.charger.getChargerCore"
          :loading="data.loading"
          :has-real-time-information="isInRealTime"
          @on-wait-for-action="stopRealTime"
          @on-update="startRealTime"
        />
      </container-component>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import ChargerHeader from '@/components/headers/ChargerHeader.vue'
import ChargerHeaderActions from '@/components/headers/ChargerHeaderActions.vue'
import ContainerComponent from '@/components/ContainerComponent.vue'
import EnableAutoRefreshComponent from '@/components/EnableAutoRefreshComponent.vue'
import { isOcpp, supportsOcpp } from '@/utilities/charger/chargerOcppSettings'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import state from '@/state'
import { computed, reactive, onBeforeUnmount, watchEffect, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import { HttpError } from '@wallbox/http'
import { useRealTimeFetcher } from '@wallbox/hooks'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const chargersUseCases = injectStrict(CHARGER_USE_CASES)

const {
  isInRealTime,
  provideCallback,
  startRealTime,
  stopRealTime
} = useRealTimeFetcher('charger-detail')

interface Tab {
  key: string
  value: string
  permissions: {
    plans: EnumPlans[]
    roles: EnumRoles[]
    ocpp?: boolean
  }
  dataTestId: string
}

interface Data {
  tabs: Tab[]
  tabSelected?: Tab
  loading: boolean
  chargerUid?: string
  withLoading: boolean
}

const data: Data = reactive({
  tabs: [
    {
      key: 'charger',
      value: i18n.t('mywb.common.overview'),
      permissions: {
        plans: [EnumPlans.BASIC, EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerTabLabel'
    },
    {
      key: 'charger-users',
      value: i18n.t('mywb.common.users'),
      permissions: {
        ocpp: false,
        plans: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerUsersTabLabel'
    },
    {
      key: 'charger-sessions',
      value: i18n.t('mywb.common.sessions'),
      permissions: {
        plans: [EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },
      dataTestId: 'chargerSessionsTabLabel'
    },
    {
      key: 'charger-settings',
      value: i18n.t('mywb.common.settings'),
      permissions: {
        plans: [EnumPlans.BASIC, EnumPlans.BUSINESS, EnumPlans.OPERATOR],
        roles: [EnumRoles.admin, EnumRoles['super-admin'], EnumRoles.operator]
      },

      dataTestId: 'chargerSettingsTabLabel'
    }
  ],
  loading: false,
  withLoading: true
})

const compute = reactive({
  isPlan: computed(state.organizations.isPlan),

  isRole: computed(state.organizations.isRole),

  sections: computed((): Tab[] => {
    return data.tabs.filter(tab =>
      compute.isRole(tab.permissions.roles) &&
        compute.isPlan(tab.permissions.plans) && (
        (tab.permissions.ocpp && supportsOcpp(state.charger.getChargerCore)) ||
        (!tab.permissions.ocpp && !isOcpp(state.charger.getChargerCore)) ||
        (typeof tab.permissions.ocpp === 'undefined')
      )
    )
  })
})

watchEffect(() => {
  data.tabSelected = data.tabs.find(tab => tab.key === route.name?.toString())
})

onBeforeUnmount(() => {
  state.charger.resetCharger()
})

const methods = {
  async loadData (chargerUid: string) {
    data.loading = data.withLoading
    try {
      const charger = await chargersUseCases.getCharger(
        chargerUid,
        state.organizations.getCurrentOrganization.groupUid,
        {
          cache: 'stale'
        }
      )

      if (!charger) {
        router.push({ name: ROUTES.CHARGERS })
        return
      }

      state.charger.setCharger(charger)

      if (charger.organizationId !== state.organizations.getCurrentOrganization.groupUid) {
        router.push({ name: ROUTES.CHARGERS })
      }
    } catch (error) {
      if (error instanceof HttpError && (error.status === 403 || error.status === 404)) {
        router.push({ name: ROUTES.NOT_FOUND }).catch(() => {})
      } else {
        throw error
      }
    }

    data.loading = false
  },

  changeTab (tab: Tab) {
    router.push({ name: tab.key })
  }
}

const created = async () => {
  data.chargerUid = route.params.chargerUid.toString()

  provideCallback(async () => {
    if (!data.chargerUid) return
    await methods.loadData(data.chargerUid)
  })

  await startRealTime()
  data.withLoading = false
}

created()

watch(
  () => state.charger.getChargerCore,
  () => state.routes.hydrateCurrentBreadCrumb(state.charger.getChargerCore.name ?? '')
)
</script>
