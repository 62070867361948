import type { UseCase } from '@/core/shared'
import type { AccessConfigRepository } from '../domain'

export const getAllAccessConfig: UseCase<AccessConfigRepository, 'getAllAccessConfigs'> = (repository) => {
  return async (organizationUid) => {
    const accessConfig = await repository.getAllAccessConfigs(organizationUid)

    return accessConfig
  }
}
