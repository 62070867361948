<template>
  <rate-type-component
    v-model:activated="data.isActivated"
    v-model:schedule="data.schedule"
    :charger-schedule-id="data.chargerScheduleId"
    data-test-id="rateTypeCard"
    :rates="props.rates"
    :loading="props.loading"
    v-bind="compute.rateInfo"
    :charger="props.charger"
    :compatible="props.compatible"
    choose-rate
    :allow-hourly-selection="props.allowHourlySelection"
    @update:activated="methods.checkRateAssignActive"
    @update:schedule="() => data.isActivated = true"
  >
    <slot v-if="data.isActivated && (data.schedule)" />
  </rate-type-component>
</template>

<script setup lang="ts">
import { reactive, watch, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'

import state from '@/state'
import RateTypeComponent from '@/components/RateTypeComponent.vue'
import type { Rate, RateSchedule, ChargerRateWeekSchedule } from '@/core/rate'
import { moveScheduleFromLocalToUTC, moveScheduleFromUTCToLocal } from '@/utilities/rates/normalizeScheduleInTimezone'
import { trackDataAction, trackDataError } from '@/engine/metrics/trackDataManager'
import type { Charger as ChargerCore } from '@/core/charger'
import {
  CHARGER_USE_CASES,
  injectStrict,
  RATE_USE_CASES
} from '@/engine/injectors'
import type { ChargerPaymentsCompatible } from '@/core/charger/domain/ChargerPaymentsCompatible'
import { HttpError } from '@wallbox/http'

const rateUseCases = injectStrict(RATE_USE_CASES)
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

const i18n = useI18n()

interface PropsType {
  charger: ChargerCore
  rates: Rate[]
  type: 'payPerCharge' | 'payPerMonth'
  schedule?: ChargerRateWeekSchedule
  loading?: boolean
  hasAutolock?: boolean
  activated?: boolean
  title?: string
  subtitle?: string
  error?: string
  chooseRate?: boolean
  compatible: ChargerPaymentsCompatible['payPerCharge']
  allowHourlySelection?: boolean
}

const props = defineProps<PropsType>()

const emit = defineEmits([
  'schedule-updated'
])

interface Data {
  guestAccessModal: boolean
  isActivated: boolean
  types: {
    payPerCharge: {
      title: string
      subtitle: string
      type: 'pay_per_charge'
    },

    payPerMonth: {
      title: string
      subtitle: string
      type: 'pay_per_month'
    }
  },

  schedule?: RateSchedule
  rate?: Rate
  chargerScheduleId?: string
}

const data: Data = reactive({
  guestAccessModal: false,
  isActivated: false,
  types: {
    payPerCharge: {
      title: i18n.t('mywb.charger.pay-per-charge'),
      subtitle: i18n.t('mywb.charger.pay-per-charge-assign-description'),
      type: 'pay_per_charge'
    },

    payPerMonth: {
      title: i18n.t('mywb.charger.pay-per-month'),
      subtitle: i18n.t('mywb.charger.pay-per-month-assign-description'),
      type: 'pay_per_month'
    }
  }
})

const compute = reactive({
  rateInfo: computed(() => data.types[props.type])
})

const methods = {
  async checkRateAssignActive () {
    if (props.schedule && data.schedule) {
      const payload = {
        chargerId: props.charger.uid,
        scheduleId: data.chargerScheduleId,
        payload: {
          status: data.isActivated ? 'active' : 'frozen',
          type: data.types[props.type].type,
          schedule: moveScheduleFromLocalToUTC(data.schedule, props.charger.timezone)
        }
      }
      try {
        rateUseCases.saveChargerRateSchedule(payload)

        trackDataAction('charger-schedule-updated', payload)

        if (data.isActivated) {
          await chargerUseCases.updateCharger({
            uid: props.charger.uid,
            autoLock: true,
            autoLockTime: 60
          })

          state.charger.set('charger.autoLock', true)
          state.charger.set('charger.autoLockTime', 60)
        }
      } catch (error) {
        const errorObj = {
          activateSwitch: true,
          responseError: error,
          responseErrorString: JSON.stringify(error),
          errorMessage: '',
          errorMsg: '',
          errorCause: '',
          errorStatus: 0,
          errorCode: 0 as number | string
        }

        if (error instanceof HttpError) {
          errorObj.errorMessage = error.message
          errorObj.errorMsg = error.msg
          errorObj.errorCause = error.cause as string
          errorObj.errorCode = error.code
          errorObj.errorStatus = error.status
        }

        trackDataError('charger-schedule-error', errorObj)
        throw error
      }

      methods.scheduleUpdated(data.isActivated)
    }
  },

  async updateCharger () {
    const charger = await chargerUseCases.getCharger(
      props.charger.uid,
      state.organizations.getCurrentOrganization.groupUid,
      { cache: 'network' }
    )

    charger && state.charger.setCharger(charger)
  },

  scheduleUpdated (isActive = true) {
    data.isActivated = isActive

    emit('schedule-updated', {
      paymentType: data.types[props.type].type,
      status: data.isActivated ? 'active' : 'frozen',
      schedule: data.schedule
    })
  },

  loadSchedule () {
    data.isActivated = props.schedule?.status === 'active'
    data.schedule = props.schedule?.schedule
      ? moveScheduleFromUTCToLocal(props.schedule?.schedule, props.charger.timezone)
      : undefined

    data.chargerScheduleId = props.schedule?.id
  }
}

watch(() => [props.schedule, props.rates], async () => {
  methods.loadSchedule()
})

watch(() => props.charger.timezone, async (value, oldValue) => {
  if (oldValue && value !== oldValue) {
    methods.loadSchedule()
  }
})
</script>
