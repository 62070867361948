import { endpoints } from '@/core/shared/endpoints.config'
import type { ConfigRepository } from '../../domain'
import type { ConfigResponse } from './types'
import type { RepositoryHttpBuild } from '@/core/shared'
import ENV from '@/engine/env/web.env'
import { provideAuthUseCases } from '@/core/auth'

export const wapiBuilder: RepositoryHttpBuild<ConfigRepository> = ({ httpService }) => {
  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })
  return {
    async getConfig () {
      const result = await wapiInstance.get<ConfigResponse>(endpoints.v4.config)

      if (!result) {
        return {
          countriesWithPayments: [],
          midCountries: []
        }
      }

      return {
        countriesWithPayments: result.data.attributes.countries_with_payments,
        midCountries: result.data.attributes.mid_countries
      }
    }
  }
}
