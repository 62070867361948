<template>
  <auth-layout>
    <template #title>
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.auth.register') }}
      </p>
    </template>

    <wb-form>
      <wb-input
        ref="input"
        v-model="user.name"
        :label="i18n.t('mywb.common.name')"
        :error="errors.name"
        data-test-id="nameInput"
        name="name"
        type="text"
      />

      <wb-input
        v-model="user.email"
        :label="i18n.t('mywb.common.email')"
        :error="errors.email"
        data-test-id="emailInput"
        name="email"
        type="text"
      />

      <wb-input
        v-model="user.password"
        name="password"
        type="password"
        data-test-id="passwordInput"
        :label="i18n.t('mywb.common.password')"
        :error="errors.password"
        @on-enter.prevent="methods.continueRegister"
      />
      <auth-password-hint :value="user.password" />
      <wb-button
        :label="i18n.t('mywb.common.continue')"
        size="large"
        data-test-id="firstStepBtn"
        @click="validate(methods.continueRegister)"
      />

      <wb-link
        class="is-size-400 has-text-centered has-margin-auto"
        type="text"
        :to="{ name: ROUTES.AUTH_LOGIN }"
      >
        <span class="is-size-300 wb-icons mr-8 has-text-primary-500">arrow_back</span>
        <span>{{ i18n.t('mywb.common.back') }}</span>
      </wb-link>
    </wb-form>
  </auth-layout>
</template>

<script setup lang="ts">
import AuthLayout from '@/components/auth/AuthLayout.vue'
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import { ref, onMounted, reactive, watch } from 'vue'
import { useWbInputAutofocus } from '@/hooks'
import { trackScreen, trackAction } from '@wallbox/metrics'
import { useRoute } from 'vue-router'
import { useI18n } from '@/hooks/useI18n.hook'
import type { Auth } from '@/types'
import { useValidator } from '@/hooks/useValidator.hook'
import ROUTES from '@/engine/router/routes'

const route = useRoute()
const i18n = useI18n()
const { yup, errors, validate, defineSchema } = useValidator()

interface Events {
  (e: 'continue-register', partialUser: Auth.UserInRegister): void
}

const emit = defineEmits<Events>()

const props = defineProps({
  emailError: {
    type: Boolean,
    default: false
  }
})

const input = ref()
useWbInputAutofocus(input)

const user = reactive({
  name: '',
  email: route.query.email?.toString() ?? '',
  password: ''
})

defineSchema(user, {
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().passwordValidFormat().required()
})

onMounted(() => {
  trackScreen('register')
})

const methods = {
  async continueRegister () {
    trackAction('register-continue')
    trackScreen('terms')

    emit('continue-register', {
      name: user.name,
      email: user.email,
      password: user.password
    })
  }
}

watch(() => props.emailError, value => {
  value && (errors.email = i18n.t('mywb.error.user-already-registered'))
}, { immediate: true })

</script>
