<template>
  <wb-modal
    width="50"
    @close="emit('close')"
  >
    <template #title>
      <i18n-t keypath="mywb.common.delete-confirmation" tag="p" class="is-size-500">
        <template #element>
          <span class="is-font-weight-500">"{{ props.rate.name }}"</span>
        </template>
      </i18n-t>
    </template>

    <template #actions>
      <div class="button-actions">
        <wb-button
          variant="white"
          outlined
          data-test-id="closeBtn"
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('close')"
        />
        <wb-button
          variant="danger"
          data-test-id="deleteTariff"
          size="block"
          :label="i18n.t('mywb.common.delete')"
          :loading="data.loading"
          @click="methods.handleDeleteRate"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script lang="ts">
export default {
  name: 'DeleteRateModal'
}
</script>

<script setup lang="ts">

import state from '@/state'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { reactive } from 'vue'
import type { RateWithAssigned } from '@/core/rate'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'

const rateUseCases = injectStrict(RATE_USE_CASES)

const toast = useToast()
const i18n = useI18n()

interface Props {
  rate: RateWithAssigned
}
const props = defineProps<Props>()

const emit = defineEmits(['close', 'on-delete-rate'])

const data = reactive({
  loading: false,
  rateTrack: {
    rate_name: props.rate.name,
    rate_fixed_access_fee: props.rate.fixedFee,
    rate_variable_fee: props.rate.variableFeePrice,
    rate_variable_type: props.rate.variableFeeType
  }
})

const methods = {
  async handleDeleteRate () {
    data.loading = true
    try {
      await rateUseCases.deleteRate({
        groupUid: state.organizations.getCurrentOrganization.groupUid,
        rateId: props.rate.id
      })

      emit('on-delete-rate', props.rate)
      toast.success(i18n.t('mywb.tariff.deletion-successfully'))
      trackDataEvent('delete-rate', data.rateTrack)
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.loading = false
      emit('close')
    }
  }
}

trackDataScreen('payments-delete-rate', data.rateTrack)
</script>

<style lang="postcss" scoped>
.reason-list {
  list-style-type: "- ";
  list-style-position: inside;
}
</style>
