<template>
  <auth-layout>
    <template
      v-if="!data.passwordChangeFinished"
      #title
    >
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.account.password-description') }}
      </p>
    </template>

    <template
      v-else
      #title
    >
      <wb-check-icon />
      <p class="is-size-500 is-font-weight-700 has-text-black mb-32">
        {{ i18n.t('mywb.user.changed-password') }}
      </p>
    </template>
    <wb-form v-if="!data.passwordChangeFinished">
      <wb-input
        ref="input"
        v-model="form.password"
        name="password"
        autocomplete="new-password"
        data-test-id="newPasswordInputModal"
        :label="i18n.t('mywb.user.new-password')"
        :placeholder="i18n.t('mywb.user.enter-password')"
        not-error-message
        type="password"
        :error="errors.password"
      />
      <auth-password-hint :value="form.password" />
      <wb-input
        v-model="field.passwordConfirm"
        name="passwordConfirm"
        data-test-id="confirmNewPasswordInputModal"
        :label="i18n.t('mywb.user.confirm-password')"
        :placeholder="i18n.t('mywb.user.enter-password')"
        type="password"
        :error="confirmErrors.passwordConfirm"
        @on-enter.prevent="validate(methods.updatePassword)"
      />

      <wb-button
        data-test-id="confirmBtnModal"
        size="large"
        :loading="data.loading"
        :label="i18n.t('mywb.common.accept')"
        @click="validate(methods.updatePassword)"
      />
    </wb-form>

    <div v-else>
      <wb-button
        class="mb-24"
        :label="i18n.t('mywb.common.done')"
        data-test-id="loginBtn"
        size="large"
        :to="{ name: ROUTES.AUTH_LOGIN }"
      />
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useWbInputAutofocus } from '@/hooks'
import { useI18n } from '@/hooks/useI18n.hook'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from '@wallbox/toolkit-ui'
import { trackScreen, trackAction, trackEvent } from '@wallbox/metrics'
import { getServerError } from '@/utilities/errorMessages'
import AuthPasswordHint from '@/components/auth/AuthPasswordHint.vue'
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { parseJwt } from '@/utilities/jwt'
import { HttpError } from '@wallbox/http'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { useValidator } from '@/hooks/useValidator.hook'
import { ensurePasswordResetIsValid, type PasswordReset } from '@/core/auth'
import ROUTES from '@/engine/router/routes'

const router = useRouter()
const route = useRoute()
const toast = useToast()
const i18n = useI18n()
const authUsesCases = injectStrict(AUTH_USE_CASES)

const { errors, validate, validateTo } = useValidator()
const {
  yup,
  errors: confirmErrors,
  validate: validateConfirm,
  defineSchema
} = useValidator()

const field = reactive({
  passwordConfirm: ''
})

defineSchema(field, {
  passwordConfirm: yup
    .string()
    .test(
      'confirm-password',
      i18n.t('mywb.error.values-not-match'),
      (value) => value === form.password
    )
    .required()
})

const form = reactive<PasswordReset>({
  token: route.query.forgot?.toString() ?? '',
  password: ''
})

validateTo(async () => {
  const confirmIsValid = !!await validateConfirm()
  const domainIsValid = !!await ensurePasswordResetIsValid(form)

  return domainIsValid && confirmIsValid
})

const input = ref()
useWbInputAutofocus(input)

const data = reactive({
  passwordChangeFinished: false,
  loading: false
})

onMounted(() => {
  const token = form.token ? parseJwt(form.token) as { username: string, exp: number } : null
  if (!form.token || (token && token.exp < Math.floor(Date.now() / 1000))) {
    router.push({ name: ROUTES.AUTH_FORGOT_PASSWORD, query: { 'token-expired': 'true', email: token?.username || '' } })
    trackEvent('password-change-error', { error: 'token-expired' })
  } else {
    trackScreen('password-change')
  }
})

const methods = {
  async updatePassword () {
    data.loading = true
    trackAction('password-change')
    try {
      await authUsesCases.doPasswordReset(form)
      trackEvent('password-change-success')
      data.passwordChangeFinished = true
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
        trackEvent('password-change-error', { error })
      } else {
        throw error
      }
    }

    data.loading = false
  }
}
</script>
