<template>
  <wb-table
    :options="options"
    :loading="loading"
    @on-pagination-change="methods.handlePaginationChange"
  >
    <wb-table-row
      v-for="chargePoint in chargePoints"
      :key="chargePoint.id"
    >
      <wb-table-row-item>
        <chargers-table-charger-name
          :id="0"
          :image="placeholderChargerImg"
          :name="chargePoint.name"
          :uuid="chargePoint.id"
        />
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-link
          v-if="permissions.canSeeLocations"
          type="primary"
          data-test-id="locationName"
          :to="{ name: ROUTES.LOCATION_DETAIL, params: { locationId: chargePoint.locationId } }"
        >
          {{ chargePoint.locationName }}
        </wb-link>

        <p v-else data-test-id="locationName" class="has-text-overflow">
          {{ chargePoint.locationName }}
        </p>
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-connector
          v-for="(connector, idx) in chargePoint.connectors"
          :key="idx"
          :type="connector.type"
          :status="connector.status"
          size="sm"
          class="chargepoint-connector-icon"
        />
      </wb-table-row-item>

      <wb-table-row-item>
        <ChargePointStatus v-if="chargePoint.id" :charge-point-status="chargePoint.status" />
      </wb-table-row-item>

      <wb-table-row-item>
        <wb-button-groups>
          <wb-button
            :label="i18n.t('mywb.common.edit')"
            icon="edit_filled"
            variant="white"
            size="small"
          />
        </wb-button-groups>
      </wb-table-row-item>
    </wb-table-row>
  </wb-table>
</template>

<script setup lang="ts">
import ChargersTableChargerName from '@/components/tables/ChargersTableChargerName.vue'
import ChargePointStatus from '@/components/chargePoint/ChargePointStatus.vue'

import { permissions } from '@/engine/clients'
import { useI18n } from 'vue-i18n'
import type { TableProps } from '@wallbox/toolkit-ui'
import type { ChargePoint } from '@/core/chargePoint'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

const placeholderChargerImg = new URL('@/assets/images/placeholder-pulsar-plus.svg', import.meta.url).toString()

interface Events {
  (e: 'on-pagination-change', offset: number): void,
}
const emit = defineEmits<Events>()

interface Props {
  chargePoints: ChargePoint[]
  loading: boolean
  options: TableProps['options']
}

withDefaults(defineProps<Props>(), {
  loading: false
})

const methods = {
  handlePaginationChange (offset: number) {
    emit('on-pagination-change', offset)
  }
}
</script>
