import type { InvoicesRepository } from '@/core/invoices'
import type { UseCase } from '@/core/shared'

export const getAllPlanInvoices: UseCase<InvoicesRepository, 'getAllPlanInvoices'> = repository => {
  return async (groupUid) => {
    const invoices = await repository.getAllPlanInvoices(groupUid)

    return invoices
  }
}
