<template>
  <div class="header-content-card">
    <transition name="header">
      <div
        v-if="permissions.showSelectedChargers && compute.countCheckedChargers"
        class="header-chargers-selected pr-24 g-16"
      >
        <div>
          <div v-if="compute.countCheckedChargers" class="is-flex is-size-300">
            <span class="is-font-weight-500 mx-4">
              {{ compute.countCheckedChargers }}
            </span>
            {{ i18n.t('mywb.charger.charger-selected', compute.countCheckedChargers) }}
            <wb-link
              class="ml-12"
              @click="emit('deselect-all')"
            >
              {{ i18n.t('mywb.common.deselect-all') }}
            </wb-link>
          </div>

          <div v-if="!compute.countCheckedChargers" class="has-text-grey-500 is-size-500">
            <span class="is-font-weight-500 mx-4">
              {{ props.chargers.length }}
            </span>
            <span class="has-text-grey-500">
              {{ i18n.t('mywb.common.chargers') }}
            </span>
          </div>
        </div>

        <div class="header-chargers-selected-buttons">
          <wb-button
            v-if="permissions.hasBulkActions && compute.chargersToUpdate && compute.countCheckedChargers"
            :label="mq.current !== 'mobile' ? i18n.t('mywb.common.update') : ''"
            size="small"
            data-test-id="updateChargerButton"
            icon="download_circle"
            variant="white"
            outlined
            @click="emit('on-bulk-action', REMOTE_ACTIONS.UPDATE)"
          />
          <wb-button
            v-if="permissions.hasBulkActions && compute.chargersToRestart && compute.countCheckedChargers"
            data-test-id="restartChargerButton"
            :label="mq.current !== 'mobile' ? i18n.t('mywb.common.restart') : ''"
            icon="refresh"
            variant="white"
            outlined
            size="small"
            @click="emit('on-bulk-action', REMOTE_ACTIONS.RESTART)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { reactive, computed } from 'vue'
import { useMq } from 'vue3-mq'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS, hasActionsAvailable } from '@/utilities/charger/chargerActions'
import type { Charger } from '@/core/charger'

const mq = useMq()
const i18n = useI18n()

interface Props {
  chargers: Charger[]
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-bulk-action', action: REMOTE_ACTIONS): void
  (e: 'deselect-all'): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  hasOrganizationPaymentsAccount: computed((): boolean =>
    !!state.organizations.getCurrentOrganization.paymentsAccount),

  selectedChargersByGroup: computed((): Charger[] => {
    return state.charger.getCheckedChargers
  }),

  countCheckedChargers: computed((): number => compute.selectedChargersByGroup.length),

  chargersToUpdate: computed((): number => {
    const chargers = compute.selectedChargersByGroup.filter(charger => {
      return isUpdatesAvailable(charger) && hasActionsAvailable(charger)
    })
    return chargers.length
  }),

  chargersToRestart: computed((): number => {
    const chargers = compute.selectedChargersByGroup.filter(hasActionsAvailable)
    return chargers.length
  })
})
</script>

<style lang="postcss" scoped>
.header-content-card {
  position: absolute;
  top: 0;
  left: 42px;
  width: calc(100% - 43px);
  height: 100%;
}

.header-chargers-selected {
  width: 100%;
  height: 38px;
  margin: 2px 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: sticky;
  top: var(--header-height);
  border-radius: 0.8rem;

  @media (--desktop) {
    top: 143px;
  }
}

.header-chargers-selected-buttons {
  & * + * {
    margin-left: 1rem;
  }
}

.header-enter-active,
.header-leave-active {
  transition: all 250ms ease;
}

.header-enter-from,
.header-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
