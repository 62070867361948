import type { InternationalRepository } from '@/core/international'
import type { UseCase } from '@/core/shared'

export const getAllTimezones: UseCase<InternationalRepository, 'getTimezones'> = repository => {
  return async () => {
    const currencies = await repository.getTimezones()

    return currencies
  }
}
