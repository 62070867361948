<template>
  <div class="has-position-relative">
    <wb-user-avatar
      class="has-cursor-pointer"
      type="square"
      size="large"
      data-test-id="avatarExtraLarge"
      :initials="compute.initials"
      :src="data.avatar"
      @on-click="methods.openInput"
    />
    <input
      id="file-input"
      ref="fileInput"
      data-test-id="file-input"
      type="file"
      accept="image/*"
      class="is-hidden"
      @change="methods.validateImage"
    >
    <wb-button
      variant="white"
      outlined
      icon="edit"
      size="small"
      class="edit"
      data-test-id="editAvatarBtn"
      @click="methods.openInput"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'AccountPersonalDataAvatar'
}
</script>

<script setup lang="ts">
import { getServerError } from '@/utilities/errorMessages'
import state from '@/state'
import { userInitials } from '@/utilities/users'
import { computed, reactive, ref } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { AUTH_USE_CASES, USER_USE_CASES, injectStrict } from '@/engine/injectors'
import { ensureUpdateImageIsValid } from '@/core/user'
import { HttpError } from '@wallbox/http'

const authUseCases = injectStrict(AUTH_USE_CASES)
const userUseCases = injectStrict(USER_USE_CASES)
const toast = useToast()
const i18n = useI18n()
const fileInput = ref<HTMLElement>()
const { errors, validate, validateTo } = useValidator()
const data = reactive({
  avatar: '',
  fileSize: 0
})

const compute = reactive({
  initials: computed(() => userInitials(state.user.userLogged))
})

const methods = {
  async validateImage (event: Event) {
    const target = event.target as HTMLInputElement
    const image = target.files?.[0]

    validateTo(async () => !!await ensureUpdateImageIsValid({
      user: state.user.userLogged.id,
      image
    }))

    validate(() => target.files?.[0] && methods.onSelectFile(target.files[0]))
  },

  openInput () {
    fileInput.value?.click()
  },

  async onSelectFile (file: File) {
    try {
      if (file && !errors.fileSize) {
        await userUseCases.updateUserImage({
          user: state.user.userLogged.id,
          image: file
        })

        const user = await authUseCases.getMe()
        if (user) {
          state.user.setUserLogged(user)
          data.avatar = user.avatar
        }
        toast.success(i18n.t('mywb.common.changes-saved'))
      }
    } catch (error) {
      if (error instanceof HttpError) {
        toast.error(getServerError(error))
      } else {
        throw error
      }
    }
  }
}

function created () {
  data.avatar = state.user.userLogged.avatar
}

created()
</script>
<style lang="postcss" scoped>
  .has-position-relative {
    position: relative;
  }

  .edit {
    position: absolute;
    bottom: -12px;
    left: -12px;
  }
</style>
