<template>
  <div :id="id" class="stickiness">
    <div class="header" :class="{ 'with-padding': !props.hideBreadcrumb, 'filled': props.filled}">
      <breadcrumb-header
        v-if="!props.hideBreadcrumb"
        class="breadcrumb"
      />

      <div class="is-grid">
        <slot v-if="$slots['custom-title']" name="custom-title" />
        <div v-else-if="$slots['title']">
          <h2
            class="is-size-700 is-font-weight-500"
            :class="{'is-size-900': mq.current !== 'mobile'}"
            data-test-id="header-title"
          >
            <slot name="title" />
          </h2>
        </div>

        <div v-if="$slots['actions']" class="actions g-8">
          <slot name="actions" />
        </div>
      </div>

      <div v-if="$slots.filters || $slots['secondary-actions']" class="is-flex filters-wrapper g-16">
        <div class="filters g-12">
          <slot name="filters" />
        </div>

        <div v-if="$slots['secondary-actions']" class="actions pl-8">
          <slot name="secondary-actions" />
        </div>
      </div>
    </div>
    <slot name="tabs" />
  </div>
</template>

<script setup lang="ts">
import BreadcrumbHeader from '@/components/headers/BreadcrumbHeader.vue'
import { useMq } from 'vue3-mq'

const mq = useMq()

interface Props {
  hideBreadcrumb?: boolean
  stickyTop?: string
  filled?: boolean
  id?: string
}

const props = withDefaults(defineProps<Props>(), {
  hideBreadcrumb: false,
  stickyTop: '0px',
  filled: false,
  id: undefined
})
</script>

<style lang="postcss" scoped>
.header {
  display: grid;
  color: var(--black);

  &.with-padding {
    padding: 12px 0;
  }

  &.filled {
    color: var(--white);
    background: #232931;
  }
}

.stickiness {
  position: sticky;
  top: calc(var(--header-touch-size) + v-bind(props.stickyTop));
  z-index: 110;

  @media (--tablet) {
    top: v-bind(props.stickyTop);
  }
}

.actions {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  position: sticky;
  right: 0;
}

.filters {
  padding: 7px 0;
  height: auto;
  display: flex;
  margin-left: 1px;
  align-items: center;

  @media (--tablet) {
    height: var(--filters-size);
  }
}

.filters-wrapper {
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--grey-300) var(--white);

  &::-webkit-scrollbar {
    height: 5px;
    background-color: var(--white);
    border-left: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-300);
  }
}

.breadcrumb {
  height: 2.1rem;
}

.is-grid {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
}

:deep(.input_element) {
  width: auto !important;
  grid-template-rows: 38px !important;
  min-width: 150px !important;
}
</style>
