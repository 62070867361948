import toolkit from '@wallbox/toolkit-ui'
import type { App } from 'vue'

export default function initToolkit (app: App) {
  app.use(toolkit, {
    toast: {
      timeout: 4000,
      transition: 'slide'
    } as any
  })
}
