<template>
  <wb-modal
    width="60"
    @close="emit('on-close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.edit-billing-information') }}
    </template>

    <wb-form>
      <div class="is-grid-center g-16">
        <p class="is-size-400">
          {{ i18n.t('mywb.user.is-business-purchase') }}
        </p>
        <wb-radio-card
          v-model="billingPayload.company"
          :value="true"
        >
          <p class="is-size-400 has-text-grey-900" data-test-id="commercialYesRadioBtn">
            {{ i18n.t('mywb.common.yes') }}
          </p>
        </wb-radio-card>
        <wb-radio-card
          v-model="billingPayload.company"
          :value="false"
        >
          <p class="is-size-400 has-text-grey-900" data-test-id="commercialNoRadioBtn">
            {{ i18n.t('mywb.common.no') }}
          </p>
        </wb-radio-card>
      </div>

      <wb-alert
        v-if="data.vatError && billingPayload.company"
        variant="info"
        icon="info_filled"
      >
        {{ i18n.t('mywb.error.company-number-not-verified') }}
      </wb-alert>

      <template v-if="billingPayload.company">
        <wb-input
          v-model="billingPayload.name"
          type="text"
          data-test-id="companyNameInput"
          :label="i18n.t('mywb.common.enterprise')"
          :error="errors.name"
        />
        <wb-input
          v-model="billingPayload.cif"
          type="text"
          data-test-id="cifInput"
          :label="!state.organizations.isRegionUS ? i18n.t('mywb.common.vat-number') : i18n.t('mywb.common.tax-id')"
          :hint="!state.organizations.isRegionUS
            ? i18n.t('mywb.common.vat-number-hint') : i18n.t('mywb.common.tax-id-number-hint')"
          persistent-hint
          :error="errors.cif || data.errors.cif"
        />
      </template>
      <template v-else>
        <wb-input
          v-model="billingPayload.name"
          type="text"
          data-test-id="nameInput"
          :label="i18n.t('mywb.common.name')"
          :error="errors.name"
        />
        <wb-input
          v-model="billingPayload.surname"
          type="text"
          data-test-id="surnameInput"
          :label="i18n.t('mywb.user.surname')"
          :error="errors.surname"
        />
      </template>
      <wb-input
        v-model="billingPayload.address"
        type="text"
        data-test-id="addressInput"
        :label="i18n.t('mywb.common.address')"
        :error="errors.address"
      />
      <wb-input
        v-model="billingPayload.city"
        type="text"
        class="span-2"
        data-test-id="cityInput"
        :label="i18n.t('mywb.common.city')"
        :error="errors.city"
      />
      <wb-input
        v-model="billingPayload.zipCode"
        type="text"
        class="span-2"
        data-test-id="zipCodeInput"
        :label="i18n.t('mywb.common.postal-code')"
        :error="(errors.zipCode || data.errors.zipCode)"
      />

      <country-and-state-select-form
        :ref="setRef('countryState')"
        v-model:countryCode="billingPayload.country"
        v-model:stateCode="billingPayload.state"
        is-country-disabled
        class="is-fullwidth"
        country-key="iso2"
        :state-error="(errors.state || data.errors.state)"
      />

      <currency-select-form
        :model-value="state.organizations.getCurrentOrganization.currencyCode"
        :reduce="currency => currency.code"
        data-test-id="currency"
        is-disabled
      />
    </wb-form>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelBtn"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="emit('on-close')"
        />
        <wb-button
          data-test-id="saveBtn"
          size="block"
          variant="primary"
          :label="!data.loading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
          :loading="data.loading"
          @click="validate(methods.clickSaveBillingInfo)"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import CountryAndStateSelectForm from '@/components/forms/CountryAndStateSelectForm.vue'
import CurrencySelectForm from '@/components/forms/CurrencySelectForm.vue'

import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useValidator } from '@/hooks/useValidator.hook'
import { useToast, useTemplateRef } from '@wallbox/toolkit-ui'
import { getRegionByCountry } from '@/utilities/regions'
import state from '@/state'
import { HttpError } from '@wallbox/http'
import { type BillingInfo, type BillingInformationEdit, ensureEditBillingInformationIsValid } from '@/core/organization'
import { ORGANIZATION_USE_CASES, injectStrict } from '@/engine/injectors'
import type { Country } from '@/core/international'

const organizationUseCases = injectStrict(ORGANIZATION_USE_CASES)
const i18n = useI18n()
const toast = useToast()
const { refs, setRef } = useTemplateRef()
const { errors, validate, validateTo } = useValidator()

interface Props {
  billingInfo?: BillingInfo
  groupUid: string
}
const props = defineProps<Props>()

interface Events {
  (e: 'on-updated'): void,
  (e: 'on-close'): void
}
const emit = defineEmits<Events>()

const data = reactive({
  loading: false,
  vatError: false,
  errors: {
    cif: '',
    state: '',
    zipCode: ''
  }
})

const methods = {
  getCountryFromOrganization () {
    const countryCode = state.organizations.getCurrentOrganization.countryCode
    return state.global.getCountries.find(country => country.iso2 === countryCode) as Country
  },

  async clickSaveBillingInfo () {
    data.loading = true
    try {
      await methods.saveBillingInfo()
    } catch (error) {
      if (error instanceof HttpError) {
        if (error?.code === 1001) {
          data.vatError = true
          data.errors.cif = i18n.t('mywb.error.invalid-company-number')
        } else if (error?.code === 6014) {
          data.errors.state = i18n.t('mywb.error.invalid-state')
          data.errors.zipCode = i18n.t('mywb.error.invalid-postal-code')
        } else {
          toast.error(i18n.t('mywb.error.unexpected-error'))
        }
      } else {
        throw error
      }
    }
    data.loading = false
  },

  async saveBillingInfo () {
    if (await refs.countryState.isValidCountryState()) {
      await organizationUseCases.editBillingInfo(props.groupUid, billingPayload)

      data.vatError = false
      toast.success(i18n.t('mywb.common.changes-saved'))
      emit('on-close')
      emit('on-updated')
    }
  }
}

const billingPayload = reactive<BillingInformationEdit>({
  address: props.billingInfo?.address ?? '',
  cif: props.billingInfo?.cif ?? '',
  company: !!props.billingInfo?.company,
  name: props.billingInfo?.name ?? '',
  surname: props.billingInfo?.surname ?? '',
  city: props.billingInfo?.city ?? '',
  zipCode: props.billingInfo?.zipCode ?? '',
  country: methods.getCountryFromOrganization().iso2,
  state: props.billingInfo?.state ?? '',
  region: getRegionByCountry(methods.getCountryFromOrganization().iso2)?.key ?? 'other' as const
})

validateTo(async () => !!await ensureEditBillingInformationIsValid(billingPayload))

</script>

<style lang="postcss" scoped>
.is-grid-center {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: max-content min-content min-content;
  }
}

</style>
