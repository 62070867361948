import type { UseCase } from '@/core/shared'
import type { AccessConfigRepository } from '../domain'

export const getAccessConfig: UseCase<AccessConfigRepository, 'getAccessConfig'> = (repository) => {
  return async (accessConfigId: number) => {
    const accessConfig = await repository.getAccessConfig(accessConfigId)

    return accessConfig
  }
}
