import type { EnumRoles } from '@/utilities/user-roles'
import { number, object, type InferType, mixed } from 'yup'

const addUserToAccessConfigSchema = object({
  accessConfigId: number().required(),
  userId: number().required(),
  role: mixed<EnumRoles>().required()
})

export type AddUserToAccessConfig = InferType<typeof addUserToAccessConfigSchema>

export async function ensureAddUserToAccessConfigIsValid (payload: any) {
  return await addUserToAccessConfigSchema.validate(payload, { abortEarly: false })
}
