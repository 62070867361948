<template>
  <div data-test-id="chargerDeviceTabLabel">
    <wb-skeleton-loader
      class="charger-detail-grid g-16"
      :type="mq.current !== 'mobile' ? 'page' : 'dots'"
      :loading="props.loading"
    >
      <div class="summary">
        <charger-monthly-insights :charger-id="state.charger.getChargerCore.id" class="mb-16" />
        <chart-report :charger-id="state.charger.getChargerCore.id" />
      </div>
      <charger-control
        class="charger-control"
        :has-real-time-information="props.hasRealTimeInformation"
      />
      <charger-settings-overview class="data" />
    </wb-skeleton-loader>
  </div>
</template>

<script setup lang="ts">
import state from '@/state'
import ChargerControl from '@/components/charger/ChargerControl.vue'
import ChargerMonthlyInsights from '@/components/charger/ChargerMonthlyInsights.vue'
import ChartReport from '@/components/charger/ChartReport.vue'
import ChargerSettingsOverview from '@/components/charger/ChargerSettingsOverview.vue'
import { useMq } from 'vue3-mq'

const mq = useMq()

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },

  hasRealTimeInformation: {
    type: Boolean,
    default: true
  }
})
</script>

<style lang="postcss" scoped>
.charger-detail-grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    "control"
    "summary"
    "data";

  @media (--desktop) {
    grid-template-columns: minmax(0, 1fr) 370px;
    grid-template-rows: min-content max-content;
    grid-template-areas:
      "summary control"
      "summary data"
  }

  @media (--widescreen) {
    grid-template-columns: minmax(0, 1fr) 370px;
  }
}

.widget {
  grid-area: widget;
}

.summary {
  grid-area: summary;
}

.charger-control {
  grid-area: control;
}

.data {
  grid-area: data;
}
</style>
