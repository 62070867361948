import type { ChargerRepository } from '@/core/charger'
import type { UseCase } from '@/core/shared'

export const getCharger: UseCase<ChargerRepository, 'get'> = repository => {
  return async (chargerUid, groupUid, params) => {
    const charger = await repository.get(chargerUid, groupUid, params)

    return charger
  }
}
