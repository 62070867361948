<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="data.options"
        :loading="props.loading"
      >
        <wb-table-row
          v-for="user in compute.users"
          :key="user.uid"
          @click="router.push({ name: ROUTES.USER_DETAIL, params: { uid: user.uid } })"
        >
          <wb-table-row-item>
            <div class="row-content">
              <wb-user-avatar
                size="small"
                :src="user.avatar"
                :initials="user.initials"
                class="mr-8"
              />
              <p class="is-font-weight-500">
                {{ user.name }} {{ user.surname }}
              </p>
            </div>
          </wb-table-row-item>
          <wb-table-row-item>
            {{ user.email }}
          </wb-table-row-item>
          <wb-table-row-item>
            <user-role-item :profile="user.role" />
          </wb-table-row-item>
        </wb-table-row>
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import UserRoleItem from '@/components/users/UserRoleItem.vue'
import { EnumRoles } from '@/utilities/user-roles'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import type { TableProps } from '@wallbox/toolkit-ui'
import { useRouter } from 'vue-router'
import type { User } from '@/core/user'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

interface Props {
  loading?: boolean
  users: User[]
}

const props = defineProps<Props>()

interface Data {
  options: TableProps['options']
}

const data = reactive<Data>({
  options: {
    offset: '0 4%',
    stickyHead: 'var(--header-height)',
    columns: [
      { name: i18n.t('mywb.common.name'), align: 'left', minWidth: 300 },
      { name: i18n.t('mywb.common.email'), align: 'left', minWidth: 300 },
      { name: i18n.t('mywb.common.users-role.title'), align: 'center', minWidth: 300 }
    ],
    empty: {
      icon: 'table_rows',
      title: i18n.t('mywb.common.table-empty')
    }
  }
})

const compute = reactive({
  users: computed(() => {
    return (props.users ?? [])
      .filter(user => user.assigned)
      .filter(user => user.role !== EnumRoles['super-admin'])
  })
})
</script>

<style lang="postcss" scoped>
.row-content {
  display: flex;
  align-items: center;
}
</style>
