<template>
  <wb-dropdown
    tooltip-position="right"
    menu-position="right"
    :distance-offset="24"
    :tooltip-distance-offset="16"
    @click="methods.handleDropdown"
    @change="data.isActive = !data.isActive"
  >
    <template #activator>
      <slot name="activator" />
    </template>
    <template v-if="slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
    <template #container="{ close }">
      <wb-button
        variant="white"
        data-test-id="logoutMenu"
        icon="logout"
        size="block"
        content-position="left"
        unrounded
        :label="i18n.t('mywb.user.logout')"
        @mousedown="methods.logout(close)"
      />
    </template>
  </wb-dropdown>
</template>

<script setup lang="ts">
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'
import { trackDataAction, trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import state, { loadStates } from '@/state'
import { reactive, useSlots, watchEffect } from 'vue'

const i18n = useI18n()
const slots = useSlots()
const authUseCases = injectStrict(AUTH_USE_CASES)

interface DataType {
  isActive: boolean
}

const data: DataType = reactive({
  isActive: false
})

watchEffect(() => {
  document.body.style.overflowY = data.isActive ? 'auto' : 'scroll'
})

const methods = {
  handleDropdown (isActive: boolean) {
    trackDataAction('account-menu', { is_active: isActive })
  },

  logout (close: () => void) {
    close()
    trackDataAction('account-menu-option', { option: 'logout' })
    trackDataEvent('logout')

    authUseCases.doLogout().then(() => {
      sessionStorage.removeItem('wb-state')
      state.global.removeLocalStorageSensitiveInfo()
      window.location.href = '/login'
      loadStates()
    })
  }
}
</script>
