<template>
  <div v-if="props.widget.show">
    <p class="title">
      {{ props.widget.title }}
    </p>
    <p class="subtitle">
      <span
        v-if="widget.bulletInside"
        class="wb-icons status-bullet"
      >
        lens_filled
      </span>

      <span>
        {{ props.widget.subtitle }}
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export interface Props {
  widget: {
    show: boolean
    name: string
    icon?: string
    title: string
    subtitle: any
    bullet: boolean
    bulletInside: boolean
    bulletStatus?: 'SUCCESS' |'INFO' | 'WARNING' | 'ERROR'
    dataTestId: string
  }
}

const props = defineProps<Props>()

const color = computed(() => {
  return {
    SUCCESS: 'var(--success-700)',
    INFO: 'var(--blue-300)',
    WARNING: 'var(--warning-500)',
    ERROR: 'var(--danger-700)'
  }[props.widget.bulletStatus ?? 'SUCCESS']
})
</script>

<style lang="postcss" scoped>
.title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.subtitle {
  font-size: 1.2rem;
  color: var(--grey-500);
}

.status-bullet {
  font-size: 10px !important;
  margin-right: 5px;
  margin-top: 4px;
  color: v-bind(color);
}

:deep(.button) {
  width: 100%;
}
</style>
