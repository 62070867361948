<template>
  <wb-modal
    width="90"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.software-update') }}
    </template>

    <wb-alert
      icon="info_filled"
      class="mb-24"
      variant="warning"
    >
      {{ i18n.t('mywb.charger.touch-warning') }}
      <dl>
        <dt class="reason-title mt-4" @click="data.showExplanation = !data.showExplanation">
          <span
            class="arrow wb-icons mr-8"
            :class="{ rotate: data.showExplanation }"
          >
            expand_more
          </span>

          <span>
            {{ i18n.t('mywb.charger.unsuported-action-why') }}
          </span>
        </dt>
        <dd v-if="data.showExplanation" class="ml-32">
          <ul class="reason-list">
            <li v-t="'mywb.charger.unsuported-action-explain-1'" />
            <li v-t="'mywb.charger.unsuported-action-explain-2'" />
            <li v-t="'mywb.charger.unsuported-action-explain-3'" />
          </ul>
        </dd>
      </dl>
    </wb-alert>

    <charger-list
      :include-columns="compute.includeColumns"
      :update-chargers-notification="false"
      :auto-refresh-notification="false"
      :header-sticky="'-35px'"
    />

    <template #actions>
      <div class="actions">
        <div class="button-actions">
          <wb-button
            data-test-id="cancelButton"
            variant="white"
            outlined
            size="block"
            :label="i18n.t('mywb.common.cancel')"
            @click="emit('close')"
          />
          <wb-button
            data-test-id="confirmUpdateButton"
            size="block"
            icon="download_circle"
            variant="primary"
            :label="i18n.t('mywb.common.update')"
            :loading="data.loading"
            @click="methods.handleClickRemoteActions"
          />
        </div>
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import ChargerList from '@/components/charger/ChargerList.vue'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useToast } from '@wallbox/toolkit-ui'
import state from '@/state'
import { computed, reactive, onUnmounted } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { REMOTE_ACTIONS } from '@/utilities/charger/chargerActions'
import type { Charger as ChargerCore } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { ChargerColumns } from '@/types/data/chargerColumns'

const i18n = useI18n()
const toast = useToast()
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

interface Props {
  chargers?: ChargerCore[]
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

interface Data {
  loading: boolean,
  chargerList: ChargerCore[]
  showExplanation: boolean
  storedFilters: any
}

const data = reactive<Data>({
  loading: false,
  chargerList: [],
  showExplanation: false,
  storedFilters: {}
})

const compute = reactive({
  includeColumns: computed((): ChargerColumns[] => ['name', 'location', 'firmwareAvailable'])
})

const methods = {
  handleClickRemoteActions () {
    trackDataAction('update-charger', { chargers: data.chargerList })

    try {
      chargerUseCases.sendUpdateOrRestartRemoteAction({
        chargers: data.chargerList.map(charger => charger.id),
        action: REMOTE_ACTIONS.UPDATE
      })
    } catch { toast.error(i18n.t('mywb.error.unexpected-error')) }

    emit('close')
  }
}

async function created () {
  data.storedFilters = state.filters.chargersFilters
  state.filters.resetFilters({ filter: 'chargersFilters' })
  state.filters.setFilterValue({ filter: 'chargersFilters', key: 'firmwareAvailable', value: true })

  if (props.chargers) {
    state.filters.setFilterValue({
      filter: 'chargersFilters',
      key: 'serialNumber',
      value: props.chargers.map(charger => charger.serialNumber).join(',')
    })
  }

  data.chargerList = props.chargers || state.charger.getChargers
}

created()

onUnmounted(() => {
  state.filters.resetFilters({ filter: 'chargersFilters' })
  state.filters.chargersFilters = data.storedFilters
})
</script>

<style lang="postcss" scoped>
.reason-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.reason-list {
  list-style-type: "- ";
  list-style-position: inside;
}

.arrow {
  transition: transform 300ms;

  &.rotate {
    transform: rotateX(0.5turn);
  }
}
</style>
