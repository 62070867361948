<template>
  <div>
    <div class="is-size-900 mb-12">
      {{ i18n.t('mywb.common.add-charger-by-sn.title') }}
    </div>
    <div class="is-size-400 mb-32 pb-8">
      {{ i18n.t('mywb.common.add-charger-by-sn.description') }}
    </div>

    <template v-if="permissions.hasBulkActions && !data.isFormValidated">
      <div class="label">
        {{ i18n.t('mywb.common.import-chargers-using-csv-file') }}
        <wb-link
          target="_blank"
          href="files/template_add_chargers.csv"
        >
          {{ i18n.t('mywb.common.download-csv-template') }}
        </wb-link>
      </div>
      <wb-file-uploader
        v-model="data.csvFile"
        v-model:errors="data.filesErrors"
        :title-label="i18n.t('mywb.common.browse-or-drag-and-drop-files')"
        :description-label="i18n.t('mywb.common.only-accept-csv-file')"
        format="file"
        accept="
          text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :error="data.csvFilesError"
        :show-previews="false"
      />
    </template>

    <add-chargers-summary
      :loading="data.loading"
      :total-chargers-added="data.chargersAdded.length"
      :total-chargers-error="data.chargers.length"
      :is-summary-mode="!data.isFormValidated"
    >
      <template v-if="data.chargersAdded.length" #chargersWithSuccess>
        <add-chargers-form-label />
        <add-chargers-form
          v-for="(charger, index) in data.chargersAdded"
          :key="index"
          v-model:row="data.chargersAdded[index]"
          :row-index="index"
          :row-total="data.chargersAdded.length || 0"
          :loading="data.loading"
          all-disabled
        />
      </template>

      <wb-button
        v-if="data.chargersAdded.length && data.isFormValidated"
        class="mt-12 mb-24 discard-all-button"
        :label="i18n.t('mywb.common.discard-chargers-with-errors')"
        icon="delete"
        variant="white"
        @click="methods.deleteRowsWithErrors"
      />

      <add-chargers-form-label />

      <add-chargers-form
        v-for="(charger, index) in data.chargers"
        :key="index"
        v-model:row="data.chargers[index]"
        :row-index="index"
        :row-total="data.chargers.length || 0"
        :loading="data.loading"
        @delete-row="methods.deleteRow"
        @update-column="methods.handleOnUpdateColumn"
      />

      <wb-button
        v-if="permissions.hasBulkActions && !data.isFormValidated"
        variant="primary"
        inverted
        :label="i18n.t('mywb.common.add-one-more-charger')"
        icon="add"
        :loading="data.loading"
        data-test-id="addChargerRow"
        class="mt-16"
        @click="methods.addRow"
      />
    </add-chargers-summary>
  </div>
</template>

<script setup lang="ts">
import AddChargersForm from '@/components/forms/AddChargersForm.vue'
import AddChargersSummary from '@/components/addChargers/AddChargersSummary.vue'
import AddChargersFormLabel from '@/components/addChargers/AddChargersFormLabel.vue'

import { computed, reactive, onMounted, watch } from 'vue'
import { permissions } from '@/engine/clients'
import { useI18n } from '@/hooks/useI18n.hook'

import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { CREATE_CHARGER_ERROR_CODE, type ChargerToAdd, type ChargerCreateResponse } from '@/core/charger'
import type { LocationAddingChargers } from '@/core/location'
import { csvToArray } from '@/utilities/csv'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { useValidator } from '@/hooks/useValidator.hook'

const { yup } = useValidator()
const i18n = useI18n()

const chargerUseCases = injectStrict(CHARGER_USE_CASES)

const emit = defineEmits(['update:modelValue', 'update:disabled'])

interface Props {
  disabled: boolean
  modelValue: ChargerToAdd[]
  location: LocationAddingChargers
}

const props = defineProps<Props>()

interface Data {
  chargers: ChargerToAdd[],
  chargersAdded: ChargerToAdd[],
  isFormValidated: boolean
  loading: boolean,
  chargerErrors: boolean,
  csvFile: File[],
  filesErrors: string[],
  csvFilesError: string
}
const data = reactive<Data>({
  chargers: [],
  chargersAdded: [],
  isFormValidated: false,
  loading: false,
  chargerErrors: false,
  csvFile: [],
  filesErrors: [],
  csvFilesError: ''
})

const compute = reactive({
  chargersToBeAdded: computed(() => data.chargers
    .filter(charger => !charger.added)
    .filter(charger => charger.serial.value !== undefined && charger.puk.value !== '')
    .map(charger => ({
      serial: charger.serial.value ?? 0,
      puk: charger.puk.value,
      country: props.location.country ?? 'ES',
      timezone: props.location.timezoneId ?? '1'
    })))
})

watch(() => data.csvFile, (csvFile) => {
  if (csvFile[0]) {
    data.csvFilesError = ''
    const reader = new FileReader()

    reader.onloadend = e => {
      const csvParsed = csvToArray(e.target?.result as string)
      methods.loadChargers(csvParsed)
    }

    reader.onerror = () => {
      data.csvFilesError = i18n.t('mywb.error.unexpected-error')
    }

    reader.readAsText(csvFile[0])
  }
}, { deep: true })

const methods = {
  handleOnUpdateColumn () {
    if (props.disabled) {
      emit('update:disabled', false)
    }
  },

  async addRow () {
    const templateRow = {
      serial: {
        value: undefined
      },
      puk: {
        value: ''
      },
      added: false
    }
    data.chargers.push(templateRow)

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)
  },

  scrollToBottom () {
    const objDiv = document.getElementsByClassName('modal-content')
    objDiv[0].scrollTo({
      top: objDiv[0].scrollHeight,
      behavior: 'smooth'
    })
  },

  deleteRow (index: number) {
    data.chargers.splice(index, 1)
  },

  deleteRowsWithErrors () {
    data.chargers = data.chargers.filter(charger => !charger.serial.error && !charger.puk.error)
    emit('update:disabled', false)
  },

  async setChargerErrors (chargers: ChargerToAdd[]) {
    await Promise.all(chargers.map(async (charger, index) => {
      try {
        if (index === 0) {
          await yup.number().required().validate(charger.serial.value || undefined)
        } else {
          await yup.number().validate(charger.serial.value || undefined)
        }
        chargers[index].serial.error = undefined
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          chargers[index].serial.error = error.errors[0]
        }
      }
    }))

    await Promise.all(chargers.map(async (charger, index) => {
      try {
        if (index === 0) {
          await yup.mixed().required().validate(charger.puk.value || undefined)
        }
        chargers[index].puk.error = undefined
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          chargers[index].puk.error = error.errors[0]
        }
      }
    }))

    return chargers
  },

  handleResponse (chargers: ChargerCreateResponse) {
    if (chargers.chargersAdded.length) {
      chargers.chargersAdded.forEach(chargerAdded => {
        const indexCharger = data.chargers.findIndex(charger => chargerAdded === +(charger.serial?.value ?? -1))
        data.chargers[indexCharger].added = true
        data.chargersAdded.push(JSON.parse(JSON.stringify(data.chargers[indexCharger])))
      })

      trackDataEvent('add-charger', {
        chargers: chargers.chargersAdded,
        chargers_unsuccessful: chargers.errors.map(({ serial }) => serial)
      })

      emit('update:modelValue', data.chargersAdded)
    }

    if (chargers.errors.length) {
      chargers.errors.forEach(error => {
        const index = data.chargers.findIndex(charger => (charger.serial.value === error.serial && !charger.added))
        if (index === -1) return

        let serialErrorMsg = ''
        let pukErrorMsg = i18n.t('mywb.error.invalid-puk-number')

        if (error.code === CREATE_CHARGER_ERROR_CODE.CHARGER_WITH_OWNER) {
          serialErrorMsg = i18n.t('mywb.error.charger-already-has-owner')
        } else if (error.code === CREATE_CHARGER_ERROR_CODE.INVALID_SERIAL) {
          serialErrorMsg = i18n.t('mywb.error.invalid-charger-serial')
          pukErrorMsg = ''
        } else {
          serialErrorMsg = i18n.t('mywb.error.invalid-charger')
        }

        data.chargers[index].serial.error = serialErrorMsg
        data.chargers[index].puk.error = pukErrorMsg
      })
    }
  },

  loadChargers (csvParsed: Record<string, string>[]) {
    csvParsed.forEach(chargerCsv => {
      if (!data.chargers.some(charger => charger.serial.value === chargerCsv.serial)) {
        data.chargers.push({
          added: false,
          serial: {
            value: chargerCsv.serial
          },
          puk: {
            value: chargerCsv.puk
          }
        })
      }
    })

    data.chargers = data.chargers.filter(charger => charger.serial.value && charger.puk.value)

    setTimeout(() => {
      methods.scrollToBottom()
    }, 50)
  },

  async addChargers () {
    data.chargerErrors = false

    try {
      const chargersResponse =
        await chargerUseCases.createChargers(props.location.groupUid ?? '', compute.chargersToBeAdded)

      data.chargerErrors = !!chargersResponse.errors.length

      methods.handleResponse(chargersResponse)
    } catch {
      data.chargerErrors = true
    } finally {
      data.loading = false
    }
  },

  async isValidateForm () {
    data.loading = true
    data.isFormValidated = true

    data.chargers = await methods.setChargerErrors(data.chargers)

    const isValid = !data.chargers.some(charger => charger.serial.error || charger.puk.error)

    if (isValid) {
      await methods.addChargers()
      data.chargers = data.chargers.filter(charger => !charger.added)
    }
    data.loading = false

    if (data.chargerErrors) {
      emit('update:disabled', true)
    }
    return isValid && !data.chargerErrors
  }
}

onMounted(async () => {
  data.chargers = props.modelValue
})

defineExpose({ validateForm: () => methods.isValidateForm() })
</script>

<style scoped lang="postcss">
.discard-all-button {
  border: 1px solid var(--grey-700);
}
</style>
