<template>
  <wb-card
    v-if="permissions.canAddChargerFromEmptyState"
  >
    <template #content>
      <wb-empty-state
        :title="i18n.t('mywb.charger.add-chargers-into-location')"
        :subtitle="i18n.t('mywb.charger.add-chargers-into-location-detail')"
      >
        <template #image>
          <client-img-component
            inline
            src="chargers"
            class="has-offset"
            width="180"
          />
        </template>

        <wb-button-groups variant="primary">
          <wb-button
            :label="i18n.t('mywb.common.add-charger')"
            data-test-id="addChargerButton"
            icon="add"
            variant="primary"
            :to="{ name: ROUTES.CHARGERS, query: { 'add-chargers': '1' } }"
          />
          <wb-button
            v-if="clientConfig.ecommerceUrl"
            :label="i18n.t('mywb.common.discover-our-chargers')"
            icon="open_in_new"
            variant="primary"
            outlined
            @click="methods.goToWallboxEcommerce()"
          />
        </wb-button-groups>
      </wb-empty-state>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { clientConfig, permissions } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

const methods = {
  goToWallboxEcommerce () {
    trackDataAction('link_ecommerce', { source: 'charger-list' })
    window.open(clientConfig.ecommerceUrl, '_blank')
  }
}
</script>

<style lang="postcss" scoped>
.has-offset {
  position: relative;
  left: 30px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
