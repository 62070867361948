import PaymentsView from '@/views/PaymentsView.vue'
import PaymentsConfigurationView from '@/views/PaymentsConfigurationView.vue'
import PaymentsInvoicesView from '@/views/PaymentsInvoicesView.vue'
import RateCreateView from '@/views/RateCreateView.vue'
import RatesView from '@/views/RatesView.vue'
import type { RouteRecordRaw } from 'vue-router'
import ROUTES from './routes'

export default [
  {
    path: 'payments',
    component: PaymentsView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.PAYMENTS,
        text: 'mywb.common.payments'
      }
    },
    children: [
      {
        path: 'rates',
        name: ROUTES.PAYMENTS_RATES,
        component: RatesView
      },
      {
        path: 'invoices',
        name: ROUTES.PAYMENTS_INVOICES,
        component: PaymentsInvoicesView
      }
    ]
  },
  {
    path: 'payments-configuration',
    name: ROUTES.PAYMENTS_CONFIGURATION,
    component: PaymentsConfigurationView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.PAYMENTS_CONFIGURATION,
        text: 'mywb.common.payments-configuration'
      }
    }
  },
  {
    path: 'create-rate',
    name: ROUTES.PAYMENTS_CREATE_RATE,
    component: RateCreateView,
    props: true,
    meta: {
      breadcrumb: {
        name: ROUTES.PAYMENTS_CREATE_RATE,
        text: 'mywb.common.create-rate'
      }
    }
  },
  {
    path: 'edit-rate/:rateId',
    name: ROUTES.PAYMENTS_EDIT_RATE,
    component: RateCreateView,
    props: true,
    meta: {
      breadcrumb: {
        name: ROUTES.PAYMENTS_EDIT_RATE,
        text: 'mywb.common.edit-rate'
      }
    }
  }
] as RouteRecordRaw[]
