<template>
  <wb-modal
    width="40"
    @close="methods.handleCancel"
  >
    <template v-if="props.title" #title>
      {{ props.title }}
    </template>

    <slot />

    <p v-if="props.subtitle" class="is-size-400">
      {{ props.subtitle }}
    </p>

    <template #actions>
      <div class="button-actions">
        <wb-button
          data-test-id="cancelButton"
          variant="white"
          outlined
          size="block"
          :label="i18n.t('mywb.common.cancel')"
          @click="methods.handleCancel"
        />
        <wb-button
          data-test-id="confirmButton"
          size="block"
          :variant="props.type"
          :label="props.labelConfirmationButton"
          @click="methods.handleConfirm"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'

const i18n = useI18n()

interface Props {
  title?: string
  subtitle?: string
  labelConfirmationButton?: string
  type?: 'primary' | 'danger'
}
const props = withDefaults(defineProps<Props>(), {
  title: '',
  subtitle: '',
  labelConfirmationButton: '',
  type: 'primary'
})

interface Events {
  (e: 'on-open'): void,
  (e: 'on-close'): void,
  (e: 'on-confirm'): void,
  (e: 'on-cancel'): void
}
const emit = defineEmits<Events>()

const methods = {
  handleConfirm () {
    emit('on-confirm')
    emit('on-close')
  },

  handleCancel () {
    emit('on-cancel')
    emit('on-close')
  }
}

function created () {
  emit('on-open')
  trackDataScreen('confirmation-modal')
}

created()
</script>
