<template>
  <wb-modal
    width="58"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.common.edit-access-group') }}
    </template>

    <wb-skeleton-loader
      type="dots"
      :loading="data.loading"
    >
      <wb-form>
        <wb-input
          v-model="form.name"
          data-test-id="userGroupNameInput"
          class="mb-24"
          name="name"
          type="text"
          :label="i18n.t('mywb.common.name')"
          :error="errors.name"
        />
      </wb-form>

      <locations-accordion
        v-model="form.chargers"
      />
    </wb-skeleton-loader>

    <template #actions>
      <wb-button
        data-test-id="acceptBtnModal"
        size="block"
        :label="!data.btnLoading ? i18n.t('mywb.common.save') : i18n.t('mywb.common.saving') "
        :loading="data.btnLoading"
        :disabled="data.loading"
        @click="validate(methods.updateAccessConfigGroup)"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import LocationsAccordion from '@/components/locations/LocationsAccordion.vue'
import { trackDataScreen, trackDataEvent } from '@/engine/metrics/trackDataManager'
import state from '@/state'
import { reactive } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { useToast } from '@wallbox/toolkit-ui'
import { ACCESS_CONFIG_USE_CASES, CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { type AccessConfigEdit, ensureEditAccessConfigIsValid } from '@/core/accessConfig'
import { useValidator } from '@/hooks/useValidator.hook'

const chargerUsesCases = injectStrict(CHARGER_USE_CASES)
const accessConfigUseCases = injectStrict(ACCESS_CONFIG_USE_CASES)

const i18n = useI18n()
const toast = useToast()

interface Props {
  accessConfigId: number
}
const props = defineProps<Props>()

interface Events {
  (e: 'close'): void
}
const emit = defineEmits<Events>()

interface Data {
  loading: boolean
  btnLoading: boolean
}
const data = reactive<Data>({
  loading: true,
  btnLoading: false
})

const { errors, validate, validateTo } = useValidator()

const form = reactive<AccessConfigEdit>({
  id: -1,
  name: '',
  chargers: []
})

validateTo(async () => !!await ensureEditAccessConfigIsValid(form))

const methods = {
  async updateAccessConfigGroup () {
    try {
      data.btnLoading = true
      await accessConfigUseCases.editAccessConfig(form)

      trackDataEvent('edit-group', { group_id: props.accessConfigId })

      const accessConfigs = await accessConfigUseCases
        .getAllAccessConfig(state.organizations.getCurrentOrganization.groupUid)
      state.user.setAccessConfigs(accessConfigs)

      toast.success(i18n.t('mywb.common.changes-saved'))
    } catch (error) {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    } finally {
      data.btnLoading = false
      emit('close')
    }
  }
}

async function created () {
  data.loading = true
  const chargers = await chargerUsesCases
    .getAllChargers(state.organizations.getCurrentOrganization.groupUid, { cache: 'network' })
  state.charger.set('chargers', chargers)
  trackDataScreen('users-edit-group')

  const accessConfig = await accessConfigUseCases.getAccessConfig(props.accessConfigId)

  if (accessConfig) {
    form.id = accessConfig.id
    form.name = accessConfig.name ?? ''
    form.chargers = accessConfig.chargers
  }

  data.loading = false
}

created()
</script>
