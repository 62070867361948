import {
  ensureCreateOrganizationIsValid,
  type OrganizationRepository
} from '@/core/organization'
import type { UseCase } from '@/core/shared'

export const create: UseCase<OrganizationRepository, 'create'> = repository => {
  return async (organization) => {
    await ensureCreateOrganizationIsValid(organization)
    await repository.create(organization)
  }
}
