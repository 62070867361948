import type { RouteRecordRaw } from 'vue-router'
import AccountView from '@/views/AccountView.vue'
import AccountPersonalDataView from '@/views/AccountPersonalDataView.vue'
import AccountPaymentHistoryView from '@/views/AccountPaymentHistoryView.vue'
import ROUTES from './routes'

export default [
  {
    path: 'account',
    component: AccountView,
    props: true,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.ACCOUNT,
        text: 'mywb.common.settings'
      }
    },
    children: [
      {
        path: '',
        name: ROUTES.ACCOUNT,
        component: AccountPersonalDataView,
        props: true
      },
      {
        path: 'payment-history',
        name: ROUTES.ACCOUNT_PAYMENT_HISTORY,
        component: AccountPaymentHistoryView,
        props: true
      }
    ]
  }
] as RouteRecordRaw[]
