<template>
  <shared-teleport-header>
    <template v-if="data.user" #title>
      {{ `${data.user?.name} ${data.user?.surname}` }}
    </template>
  </shared-teleport-header>
  <container-component>
    <wb-skeleton-loader :loading="data.loading" :type="mq.current !== 'mobile' ? 'page' : 'dots'">
      <user-settings-view
        v-if="data.user"
        :user="data.user"
        @on-user-update="created"
      />
    </wb-skeleton-loader>
  </container-component>
</template>

<script setup lang="ts">
import UserSettingsView from '@/views/UserSettingsView.vue'
import ContainerComponent from '@/components/ContainerComponent.vue'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'

import { reactive } from 'vue'
import state from '@/state'
import { USER_USE_CASES, injectStrict } from '@/engine/injectors'
import type { UserDetail } from '@/core/user'
import { useMq } from 'vue3-mq'
import { useRouter } from 'vue-router'
import ROUTES from '@/engine/router/routes'

const mq = useMq()
const router = useRouter()
const userUseCases = injectStrict(USER_USE_CASES)

const props = defineProps<{
  uid: string
}>()

interface Data {
  user?: UserDetail,
  loading: boolean
}
const data = reactive<Data>({
  loading: false
})

async function created () {
  data.loading = true

  data.user = await userUseCases.getUserDetail(props.uid)

  if (!data.user) {
    router.push({ name: ROUTES.USERS })
    return
  }

  state.routes.hydrateCurrentBreadCrumb(`${data.user.name} ${data.user.surname}`)
  data.loading = false
}

created()
</script>
