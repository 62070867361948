<template>
  <wb-card
    :headline="i18n.t('mywb.common.location')"
    data-test-id="wb-card-location"
  >
    <template #content>
      <wb-card
        filled
        :loading="props.loading"
        alignment="center"
      >
        <template #content>
          <div v-if="permissions.canSeeLocations && !props.loading">
            <wb-link
              class="is-size-400 has-text-black is-font-weight-500"
              type="text"
              :to="{ name: ROUTES.LOCATION_DETAIL, params: {
                locationId: props.location?.id ?? '1'
              }}"
              data-test-id="locationNameLink"
            >
              {{ props.location?.name }}
            </wb-link>
            <p class="is-size-300 has-text-black">
              {{ props.location?.address }}
            </p>
            <div
              v-if="compute.locationTypes"
              class="mt-8 is-fullwidth is-font-weight-500 has-text-black is-flex flex-wrap"
            >
              <div v-for="locationType in compute.locationTypes" :key="locationType">
                <wb-badge
                  variant="blue"
                  class="mr-8 remove-cursor-pointer"
                >
                  {{ locationType }}
                </wb-badge>
              </div>
            </div>
            <location-change-location-modal
              v-if="data.showModal"
              data-test-id="changeLocationModal"
              :charger="state.charger.getChargerCore"
              @on-change-location="emit('on-change-location')"
              @close="data.showModal = false"
            />
          </div>
          <div v-else>
            <p
              data-test-id="upsellingText"
              class="is-size-300 is-font-weight-400 has-text-grey-500"
            >
              {{ i18n.t('mywb.common.locations-empty-state') }}
            </p>
          </div>
        </template>

        <template #actions>
          <wb-button
            v-if="permissions.canSeeLocations"
            icon="change_location"
            :label="i18n.t('mywb.common.change-location')"
            variant="white"
            outlined
            :disabled="props.loading"
            data-test-id="changeLocationButton"
            @click="data.showModal = true"
          />
          <wb-button
            v-if="permissions.canChangePlan && !permissions.canSeeLocations"
            icon="lock_open"
            data-test-id="upgradePlanBtn"
            :label="i18n.t('mywb.plan.upgrade')"
            variant="white"
            outlined
            :to="{ name: ROUTES.PLAN_BUSINESS }"
            @click="methods.goToPlans"
          />
        </template>
      </wb-card>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { computed, reactive } from 'vue'
import { locationTypesArray } from '@/utilities/locationTypesEnum'
import LocationChangeLocationModal from '@/components/modals/LocationChangeLocationModal.vue'
import { permissions } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import type { Location } from '@/core/location'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()

const data = reactive({
  loading: false,
  showModal: false
})

interface Props {
  loading?: boolean,
  location?: Location
}
const props = defineProps<Props>()

interface Emits {
  (e: 'on-change-location'): void
}

const emit = defineEmits<Emits>()

const compute = reactive({
  locationTypes: computed((): Array<string | undefined> => locationTypesArray
    .filter(location => compute.location_types.includes(location.key))
    .map(location => location ? location.value : undefined)),
  location_types: computed((): string[] => props.location?.locationTypes ?? [])
})

const methods = {
  goToPlans () {
    trackDataAction('upgrade_plan', {
      source: 'locations'
    })
  }
}

</script>

<style lang="postcss" scoped>
:deep(.remove-cursor-pointer) {
  cursor: default !important;
}

.flex-wrap {
  flex-wrap: wrap;
}
</style>
