<template>
  <div class="is-flex justify-end">
    <wb-alert icon="info" variant="info" class="mt-24">
      <span v-t="'mywb.charge-point.ocpp-notification'" />
      <wb-link
        href=""
        target="_blank"
        type="text"
      >
        {{ t('mywb.charge-point.ocpp-notification-link') }}
      </wb-link>
    </wb-alert>

    <wb-card class="mt-24">
      <template #content>
        <wb-card
          v-if="!data.showEdit"
          filled
          :headline="t('mywb.charge-point.charge-point-identifier')"
        >
          <template #content>
            {{ compute.modelProxy }}
          </template>
          <template #actions>
            <wb-button
              v-if="props.model.provideOcppId"
              variant="white"
              icon="edit"
              :label="t('mywb.common.edit')"
              @click="data.showEdit = true"
            />
            <div class="copy-feedback-wrapper">
              <wb-button
                variant="white"
                icon="copy"
                :label="t('mywb.common.copy')"
                @click="methods.copy(compute.modelProxy ?? '', 'copySuccess')"
              />

              <transition name="appear-right">
                <div
                  v-if="data.copySuccess"
                  class="feedback"
                >
                  <wb-check-icon class="feedback-icon" />
                </div>
              </transition>
            </div>
          </template>
        </wb-card>

        <wb-card
          v-if="!data.showEdit"
          class="mt-16"
          filled
          :headline="t('mywb.charge-point.ocpp-json-endpoint')"
        >
          <template #content>
            {{ data.url }}
          </template>
          <template #actions>
            <div class="copy-feedback-wrapper">
              <wb-button
                variant="white"
                icon="copy"
                :label="t('mywb.common.copy')"
                @click="methods.copy(data.url, 'urlSuccess')"
              />

              <transition name="appear-right">
                <div
                  v-if="data.urlSuccess"
                  class="feedback"
                >
                  <wb-check-icon class="feedback-icon" />
                </div>
              </transition>
            </div>
          </template>
        </wb-card>

        <wb-card
          v-if="data.showEdit"
          filled
        >
          <template #content>
            <wb-input
              v-model="data.providedId"
              :label="t('mywb.charge-point.charge-point-identifier')"
              :placeholder="t('mywb.common.example', [12345])"
            />
            <wb-button
              class="mt-24"
              :disabled="!data.providedId"
              :label="t('mywb.charge-point.generate-url')"
              @click="methods.updateId"
            />
          </template>
        </wb-card>
      </template>
    </wb-card>

    <wb-button
      class="mt-24"
      :disabled="!compute.modelProxy"
      :loading="props.loading"
      @click="emit('click')"
    >
      {{ t('mywb.common.add-charge-point' ) }}
    </wb-button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { reactive, computed } from 'vue'
import type { Model } from '@/core/chargePoint'
import { ulid } from 'ulid'

const { t } = useI18n()

interface Props {
  modelValue?: string
  model: Model
  loading: boolean
}

const props = defineProps<Props>()

interface Data {
  url: string
  copySuccess: boolean
  urlSuccess: boolean
  providedId?: string
  showEdit: boolean
}

const data = reactive<Data>({
  url: 'http://evectrum.com:8081/esteve/services/CentralSystemService',
  copySuccess: false,
  urlSuccess: false,
  showEdit: false
})

interface Events {
  (e: 'click'): void
  (e: 'update:modelValue', id?: string): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  modelProxy: computed({
    get () {
      return props.modelValue
    },

    set (value: string | undefined) {
      emit('update:modelValue', value)
    }
  })
})

const methods = {
  async copy (textToCopy: string, feedback: 'copySuccess' | 'urlSuccess') {
    await navigator.clipboard.writeText(textToCopy)

    data[feedback] = true

    setTimeout(() => {
      data[feedback] = false
    }, 1500)
  },

  updateId () {
    compute.modelProxy = data.providedId
    data.showEdit = false
  }
}

function created () {
  if (!props.model.provideOcppId) {
    compute.modelProxy = ulid()
  } else {
    data.showEdit = true
  }
}

created()
</script>

<style lang="postcss" scoped>
.copy-feedback-wrapper {
  position: relative;
  overflow: hidden;
}

.feedback {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 100%;
  background: var(--success-500);
  color: var(--white);
  border-radius: 99px;
  display: flex!important;
  place-items: center;
  place-content: center;

  & :deep(.checkmark) {
    height: 2rem !important;
    margin-top: 8px;
  }

  & :deep(.checkmark-check) {
    stroke: var(--white) !important;
  }
}

.appear-right-enter-active,
.appear-right-leave-active {
  transition: all 0.3s ease-in-out;
}

.appear-right-enter-from,
.appear-right-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.justify-end {
  flex-direction: column;
  align-items: end;
}

</style>
