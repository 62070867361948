<template>
  <wb-modal
    class="charger-remote-actions"
    width="80"
    @close="$emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.charger.charger-rate-assignment', [state.charger.getCheckedChargers.length]) }}
    </template>

    <div v-if="isCurrentState('settings')">
      <p
        v-t="'mywb.charger.assign-charger-title'"
        class="is-font-weight-500 mb-24 is-size-400"
      />
      <rate-type-component
        v-model:activated="data.payPerChargeActivated"
        v-model:rate="data.payPerChargeRate"
        data-test-id="payPerMonthCard"
        :error="errors.payPerChargeRate"
        class="mb-24"
        v-bind="data.payPerCharge"
        :rates="data.rates"
        :compatible="{ isCompatible: true }"
        type="pay_per_charge"
        choose-rate
      />

      <rate-type-component
        v-model:activated="data.payPerMonthActivated"
        v-model:rate="data.payPerMonthRate"
        :error="errors.payPerMonthRate"
        v-bind="data.payPerMonth"
        :rates="data.rates"
        type="pay_per_month"
        :compatible="{ isCompatible: true }"
        energy-rate-selectable
        choose-rate
      />
    </div>

    <div v-else-if="isCurrentState('resume')">
      <p
        v-t="'mywb.charger.assign-charger-resume'"
        class="is-font-weight-500 mb-24 is-size-400"
      />

      <chargers-rates-table
        data-test-id="tableSummary"
        :loading="data.loadingAssignedRates"
        :pay-per-charge-rate="data.payPerChargeActivated ? data.payPerChargeRate?.name : ''"
        :pay-per-month-rate="data.payPerMonthActivated ? data.payPerMonthRate?.name : ''"
        :pay-per-month-variable-type="data.payPerMonthRate?.variableFeeType"
        :pay-per-charge-variable-type="data.payPerChargeRate?.variableFeeType"
        :chargers-with-rates="data.chargersWithRates ?? []"
        @on-update="methods.getSelectedChargers"
      />
    </div>

    <template #actions>
      <div class="button-actions">
        <wb-button
          :disabled="data.loading"
          data-test-id="cancelButton"
          variant="white"
          outlined
          :label="i18n.t('mywb.common.cancel')"
          @click="send('back')"
        />

        <wb-button
          :loading="data.loading"
          data-test-id="nextButton"
          :disabled="!data.payPerChargeActivated && !data.payPerMonthActivated"
          :label="i18n.t('mywb.common.continue')"
          icon="arrow_forward"
          icon-position="right"
          @click="methods.next"
        />
      </div>
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'

import RateTypeComponent from '@/components/RateTypeComponent.vue'
import ChargersRatesTable from '@/components/tables/ChargersRatesTable.vue'
import state from '@/state'

import { useStateMachine } from '@wallbox/hooks'
import { useAssignedRatesApi } from '@/utilities/charger/assignedRates'
import { canAddEnergyRate } from '@/utilities/charger/midInformation'
import { trackDataAction, trackDataScreen } from '@/engine/metrics/trackDataManager'
import type { Rate } from '@/core/rate'
import { injectStrict, RATE_USE_CASES } from '@/engine/injectors'
import { useValidator } from '@/hooks/useValidator.hook'

const rateUseCases = injectStrict(RATE_USE_CASES)

const toast = useToast()
const i18n = useI18n()

const emit = defineEmits(['close', 'rates-updated', 'restore-chargers'])

const { yup, errors, validate, defineSchema } = useValidator()

const {
  getData: getAssignedRates,
  loading: loadingAssignedRates,
  chargersWithRates
} = useAssignedRatesApi()

interface Data {
  payPerCharge: {
    title: string
    subtitle: string
  },

  payPerMonth: {
    title: string
    subtitle: string
  },

  rates: Rate[],
  loading: boolean,
  payPerChargeActivated: boolean
  payPerMonthActivated: boolean
  payPerChargeRate?: Rate
  payPerMonthRate?: Rate
  loadingAssignedRates: typeof loadingAssignedRates
  chargersWithRates: typeof chargersWithRates
}

const data = reactive<Data>({
  payPerCharge: {
    title: i18n.t('mywb.charger.pay-per-charge'),
    subtitle: i18n.t('mywb.charger.pay-per-charge-assign-description')
  },

  payPerMonth: {
    title: i18n.t('mywb.charger.pay-per-month'),
    subtitle: i18n.t('mywb.charger.pay-per-month-assign-description')
  },

  rates: [],
  loading: false,
  payPerChargeActivated: false,
  payPerMonthActivated: false,
  loadingAssignedRates,
  chargersWithRates
})

defineSchema(data, {
  payPerChargeRate: yup.lazy(() => data.payPerChargeActivated
    ? yup.mixed().required()
    : yup.mixed().optional()),
  payPerMonthRate: yup.lazy(() => data.payPerMonthActivated
    ? yup.mixed().required()
    : yup.mixed().optional())
})

const methods = {
  async assignRates () {
    let apiCalls: Array<() => Promise<unknown>> = []

    data.loading = true

    if (data.payPerChargeActivated) {
      const paymentsCalls = state.charger.getCheckedChargers
        .filter(charger =>
          data.payPerChargeRate?.variableFeeType !== 'energy' ||
          canAddEnergyRate(
            charger,
            state.organizations.getCurrentOrganization.countryCode
          )
        )
        .map((charger) => {
          return () => {
            return rateUseCases.getChargerRatesWeekSchedules(charger.uid).then(schedules => {
              const scheduleId = schedules.find(schedule => schedule.paymentType === 'pay_per_charge')?.id

              return rateUseCases.saveChargerRateSchedule({
                chargerId: charger.uid,
                scheduleId,
                payload: {
                  type: 'pay_per_charge',
                  status: 'active',
                  schedule: methods.createSchedule(data.payPerChargeRate?.id ?? '1')

                }
              })
            })
          }
        })

      apiCalls = paymentsCalls
    }

    if (data.payPerMonthActivated) {
      const paymentsCalls = state.charger.getCheckedChargers
        .filter(charger =>
          data.payPerMonthRate?.variableFeeType !== 'energy' ||
          canAddEnergyRate(
            charger,
            state.organizations.getCurrentOrganization.countryCode
          )
        )
        .map((charger) => {
          return () => {
            return rateUseCases.getChargerRatesWeekSchedules(charger.uid).then(schedules => {
              const scheduleId = schedules.find(schedule => schedule.paymentType === 'pay_per_month')?.id

              return rateUseCases.saveChargerRateSchedule({
                chargerId: charger.uid,
                scheduleId,
                payload: {
                  type: 'pay_per_month',
                  status: 'active',
                  schedule: methods.createSchedule(data.payPerMonthRate?.id ?? '1')

                }
              })
            })
          }
        })

      apiCalls = [...apiCalls, ...paymentsCalls]
    }

    try {
      trackDataAction('bulk-assign-rates')
      await Promise.all(apiCalls.map(call => call()))
    } finally {
      data.loading = false
      toast.success(i18n.t('mywb.common.changes-saved'))

      emit('close')
      emit('rates-updated')
    }
  },

  createDayArray (rate: string) {
    return Array.from(Array(24).keys()).reduce((acum, current) => ({ ...acum, [current]: rate }), {})
  },

  createSchedule (rate: string) {
    return {
      monday: methods.createDayArray(rate),
      tuesday: methods.createDayArray(rate),
      wednesday: methods.createDayArray(rate),
      thursday: methods.createDayArray(rate),
      friday: methods.createDayArray(rate),
      saturday: methods.createDayArray(rate),
      sunday: methods.createDayArray(rate)
    }
  },

  async getSelectedChargers () {
    const { data: rates } = await rateUseCases.getAllRates({
      groupUid: state.organizations.getCurrentOrganization.groupUid
    })

    data.rates = rates

    getAssignedRates(state.charger.getCheckedChargers as any)
  },

  next: () => validate(() => {
    send('next')
  })
}

const { isCurrentState, send } = useStateMachine({
  initial: 'settings',
  states: {
    settings: {
      transitions: {
        next: 'resume',
        back: 'settings'
      },
      on: {
        back: () => emit('close')
      }
    },

    resume: {
      transitions: {
        back: 'settings',
        next: 'resume'
      },

      on: {
        back: () => emit('restore-chargers'),
        next: methods.assignRates
      }
    }
  }
})

async function created () {
  trackDataScreen('bulk-assign-rates')
  await methods.getSelectedChargers()
}

created()
</script>

<style lang="postcss" scoped>
:deep(.button-actions) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.modal-grid) {
  min-height: calc(100vh - 8rem);
}

:deep(.modal-content) {
  overflow-x: hidden;
  max-height: calc(100vh - 18rem) !important;
}
</style>
