export enum CONNECTORS {
  CCS1 = 'CCS1',
  CCS2 = 'CCS2',
  TYPE_1 = 'IEC_62196_T1',
  TYPE_2 = 'IEC_62196_T2',
  SCHUKO = 'DOMESTIC_F',
  CHADEMO = 'CHADEMO',
  GBT_DC = 'GBT_DC',
  DOMESTIC_G = 'DOMESTIC_G',
}
