<template>
  <wb-card
    :headline="i18n.t('mywb.charger.charger-name')"
    :subhead="state.charger.getChargerCore.name"
    :loading="props.loading"
    alignment="center"
  >
    <template #actions>
      <wb-button
        icon="edit"
        data-test-id="charger-edit-mame-cta"
        :label="i18n.t('mywb.common.edit')"
        variant="white"
        outlined
        :disabled="props.loading"
        @click="data.editingName = true"
      />
    </template>

    <template #modal>
      <charger-edit-name-modal
        v-if="data.editingName"
        @on-close="data.editingName = false"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import ChargerEditNameModal from '@/components/modals/ChargerEditNameModal.vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive } from 'vue'
import state from '@/state'

const i18n = useI18n()

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})

const data = reactive({
  editingName: false
})

</script>
