import {
  wapiRepository,
  getAllUsersByOrganization,
  getAllUsersByCharger,
  getAllInvitations,
  getAllEmails,
  getRfidByUser,
  updateUserRfid,
  deleteUserRfid,
  updateUserImage,
  getUserDetail,
  activatePayPerMonthContract,
  unsubscribeUserFromPayPerMonth,
  inviteUsersWithChargers,
  addChargersToUser,
  inviteUsersToAccessConfig,
  removeUser,
  revokeUserInvitation,
  resendUserInvitation
} from '@/core/user'

export function provideUserUseCases () {
  const repo = wapiRepository()

  return {
    getAllUsersByCharger: getAllUsersByCharger(repo),
    getAllUsersByOrganization: getAllUsersByOrganization(repo),
    getAllInvitations: getAllInvitations(repo),
    getAllEmails: getAllEmails(repo),
    getRfidByUser: getRfidByUser(repo),
    updateUserRfid: updateUserRfid(repo),
    deleteUserRfid: deleteUserRfid(repo),
    updateUserImage: updateUserImage(repo),
    getUserDetail: getUserDetail(repo),
    activatePayPerMonthContract: activatePayPerMonthContract(repo),
    unsubscribeUserFromPayPerMonth: unsubscribeUserFromPayPerMonth(repo),
    inviteUsersWithChargers: inviteUsersWithChargers(repo),
    addChargersToUser: addChargersToUser(repo),
    inviteUsersToAccessConfig: inviteUsersToAccessConfig(repo),
    removeUser: removeUser(repo),
    revokeUserInvitation: revokeUserInvitation(repo),
    resendUserInvitation: resendUserInvitation(repo)
  }
}
