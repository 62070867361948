<template>
  <wb-card
    :headline="t('mywb.charger.auto-update')"
    :subhead="t('mywb.charger.auto-update-description')"
    :loading="props.loading"
    alignment="center"
  >
    <template #content>
      <wb-button-card
        icon="download_circle"
        :title="t('mywb.charger.software-version')"
        :subtitle="compute.subtitle"
        :bullet-status="compute.isUpdateAvailable || compute.isUpdating ? 'INFO' : 'DISABLED'"
        :bullet="compute.isUpdateAvailable || compute.isUpdating"
        filled
      />
    </template>
    <template #place-upper-right>
      <wb-switch
        id="type"
        v-model="data.isActivated"
        data-test-id="switchAutoUpdate"
        name="type"
        @input="methods.handleActivated"
      />
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { REMOTE_ACTIONS } from '@/core/charger'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import { useI18n } from '@/hooks/useI18n.hook'
import state from '@/state'
import { isChargerRemoteAction } from '@/utilities/charger/chargerActions'
import { isUpdatesAvailable } from '@/utilities/charger/chargerSoftware'
import { dates } from '@wallbox/toolkit-ui'
import { computed, reactive, watchEffect } from 'vue'

const { t, locale } = useI18n()
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

const props = defineProps<{
  loading?: boolean
}>()

const data = reactive({
  isActivated: false
})

const compute = reactive({
  isUpdating: computed((): boolean => isChargerRemoteAction(state.charger.getChargerCore, REMOTE_ACTIONS.UPDATE)),

  isUpdateAvailable: computed((): boolean => isUpdatesAvailable(state.charger.getChargerCore)),

  subtitle: computed((): string => {
    const currentVersion = state.charger.getChargerCore.software.currentVersion
    const newVersion = state.charger.getChargerCore.software.latestVersion
    const when = dates
      .toDateWithHoursAndMinutes(state.charger.getChargerCore.software.softwareLastUpdatedAt, locale.value)

    const updateToLabel = compute.isUpdateAvailable
      ? ` - ${t('mywb.common.update-to-version', [newVersion])}`
      : ` ${t('mywb.common.installed', [when])}`

    return !compute.isUpdating
      ? currentVersion + updateToLabel
      : t('mywb.charger.status.updating')
  })
})

watchEffect(() => {
  data.isActivated = state.charger.getChargerCore.softwareAutoUpdateEnabled
})

const methods = {
  handleActivated () {
    trackDataAction('software-auto-update', {
      activated: data.isActivated
    })

    chargerUseCases.updateCharger({
      uid: state.charger.getChargerCore.uid,
      softwareAutoUpdateEnabled: data.isActivated
    })
  }
}
</script>
