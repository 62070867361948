<template>
  <svg
    aria-hidden="true"
    v-bind="attrs"
    data-test-id="clientImg"
    :viewBox="props.viewbox"
  >
    <use :href="compute.symbolId" />
  </svg>
</template>

<script setup lang="ts">
import ENV from '@/engine/env/web.env'
import { computed, reactive, useAttrs } from 'vue'

const attrs = useAttrs()

interface PropsType {
  src: string
  branded?: boolean
  viewbox?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  src: '',
  branded: false,
  viewbox: '0 0 100 100'
})

const client = ENV.portal.client

const compute = reactive({
  symbolId: computed(() => `#${props.branded ? client : 'common'}-${props.src}`)
})
</script>
