import { number, object, type InferType } from 'yup'

const subscribeUserToPayPerMonthSchema = object({
  accessConfigId: number().required(),
  userId: number().required()
})

export type SubscribeUserToPayPerMonth = InferType<typeof subscribeUserToPayPerMonthSchema>

export async function ensureSubscribeUserToPayPerMonthIsValid (payload: any) {
  return await subscribeUserToPayPerMonthSchema.validate(payload, { abortEarly: false })
}
