import { createApp } from 'vue'
import initApp from '@/engine'

import 'virtual:svg-icons-register'
import './styles/styles.css'
import { trackTime } from '@wallbox/metrics'
import appVue from './app.vue'

void (async () => {
  const app = createApp(appVue)

  const { i18n, router } = await initApp(app)

  app
    .use(i18n)
    .use(router)
    .mount('#app')

  trackTime('bootstrapping-complete')
})()
