<template>
  <wb-input
    v-if="!props.readMode"
    :ref="setRef('input')"
    v-model="compute.address"
    v-bind="$attrs"
    data-test-id="googleMapsAndAddressInput"
    :placeholder="i18n.t('mywb.location.address-chargers')"
    :label="i18n.t('mywb.common.address')"
    :error="props.addressError"
    @on-enter.prevent="() => ({})"
  />
  <div :ref="setRef('googleMap')" class="google-map" />
</template>

<script setup lang="ts">
import { reactive, onMounted, computed, watchEffect } from 'vue'
import { useTemplateRef } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { useLoadGoogleMaps } from '@/hooks/useLoadGoogleMaps'
import type { Locations } from '@/types'
import type { CountryIso2 } from '@/core/international'

const { refs, setRef } = useTemplateRef()
const i18n = useI18n()

const { map, initMap, createCustomMarkerIcon } = useLoadGoogleMaps({
  withInitialGeolocation: true
})

type AddressComponents =
  'street_number' | 'route' | 'locality' | 'administrative_area_level_1' | 'country' | 'postal_code'

type AddressTypes = 'short_name' | 'long_name'

interface PropsType {
  address?: string
  latitude?: number
  longitude?: number
  addressError?: string
  readMode?: boolean,
  componentRestrictions?: google.maps.places.ComponentRestrictions
}

const props = defineProps<PropsType>()

interface Events {
  (e: 'on-place-update', place: Locations.Place): void,
  (e: 'update:address', address?: string): void,
  (e: 'update:latitude', latitude?: number): void,
  (e: 'update:longitude', longitude?: number): void,
}

const emit = defineEmits<Events>()

interface Data {
  map?: google.maps.Map,
  marker?: google.maps.Marker
  address?: string,
  placeSearched?: boolean
}

const data: Data = reactive({
  map,
  placeSearched: false
})

const compute = reactive({
  address: computed<string | undefined>({
    get () {
      return props.address
    },

    set (value) {
      emit('update:address', value)
    }
  }),

  latitude: computed({
    get () {
      return props.latitude
    },

    set (value) {
      emit('update:latitude', value)
    }
  }),

  longitude: computed({
    get () {
      return props.longitude
    },

    set (value) {
      emit('update:longitude', value)
    }
  })
})

const methods = {
  createMarker () {
    data.marker = new window.google.maps.Marker({
      map: data.map,
      draggable: false,
      icon: createCustomMarkerIcon()
    })
  },

  createPlacesForm () {
    const autocompleteInput = refs.input.$el.querySelector('input')
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteInput,
      {
        fields: ['address_components', 'geometry', 'name'],
        componentRestrictions: props.componentRestrictions
      }
    )

    autocomplete.addListener('place_changed', () => {
      data.marker?.setVisible(false)
      const place = autocomplete.getPlace()

      if (!place.geometry) {
        compute.address = undefined
        return false
      }

      place.geometry.location && data.map?.setCenter(place.geometry.location)
      data.marker?.setPosition(place.geometry.location)
      data.marker?.setVisible(true)
      data.map?.setZoom(14)

      methods.publishAddress(place)
    })
  },

  getAddressComponent (place: google.maps.places.PlaceResult, type: AddressComponents, format?: AddressTypes) {
    const addressNameFormat = {
      street_number: format ?? 'short_name',
      route: format ?? 'long_name',
      locality: format ?? 'long_name',
      administrative_area_level_1: format ?? 'long_name',
      country: format ?? 'long_name',
      postal_code: format ?? 'short_name'
    }

    if (place.address_components) {
      for (const component of place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]]
        }
      }
    }

    return ''
  },

  publishAddress (place: google.maps.places.PlaceResult) {
    const city = methods.getAddressComponent(place, 'locality')
    const zipCode = methods.getAddressComponent(place, 'postal_code')

    const address = [
      methods.getAddressComponent(place, 'route'),
      methods.getAddressComponent(place, 'street_number'),
      city,
      methods.getAddressComponent(place, 'administrative_area_level_1'),
      methods.getAddressComponent(place, 'country'),
      zipCode
    ]
      .filter(Boolean)
      .join(', ')

    compute.address = address

    const lat = place.geometry?.location?.lat()
    const lng = place.geometry?.location?.lng()

    if (lat && lng) {
      compute.latitude = lat
      compute.longitude = lng

      emit('on-place-update', {
        address,
        latitude: lat,
        longitude: lng,
        country: methods.getAddressComponent(place, 'country', 'short_name') as CountryIso2,
        state: methods.getAddressComponent(
          place,
          'administrative_area_level_1',
          'short_name'
        ),
        city,
        zipCode
      })
    }
  }
}

watchEffect(() => {
  if (compute.latitude && compute.longitude && data.map && data.marker) {
    data.map.setCenter({ lat: compute.latitude, lng: compute.longitude })
    data.map.setZoom(14)
    data.marker.setPosition({ lat: compute.latitude, lng: compute.longitude })
    data.marker.setVisible(true)
  }
})

onMounted(async () => {
  await initMap({ el: refs.googleMap })
  methods.createMarker()

  if (!props.readMode) {
    methods.createPlacesForm()
  }
})
</script>

<style lang="postcss" scoped>
.google-map {
  width: 100%;
  height: 240px;
  border-radius: 8px;
}

:global(.pac-container) {
  z-index: 9996;
}

:global(.gm-svpc) {
  display: none;
}

:global(.gm-fullscreen-control) {
  display: none;
}

:global(div[role="menubar"]) {
  display: none;
}
</style>
