import type { ChargePointStatus } from '@/core/chargePoint'
import type { ConnectorType } from '@wallbox/toolkit-ui'

export const enum PowerType {
  AC_1_PHASE,
  AC_3_PHASE,
  DC
}

export const enum ChargePointConnectorStatus {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  ERROR = 'error',
  OCCUPIED = 'occupied',
  PENDING = 'pending'
}

export interface ChargePointConnector {
  type: ConnectorType
  amperage: number
  power: number
  status: ChargePointConnectorStatus
}
export interface ChargePoint {
  id: string
  name: string
  status: ChargePointStatus
  locationId: string
  locationName: string
  connectors: ChargePointConnector[]
}
