<template>
  <dashboard-card
    title="mywb.common.chargers"
    subtitle="mywb.common.total-installed-chargers"
    icon="charger"
    :number="compute.totalChargersLocale"
    :loading="props.loading"
    data-test-id="dashboardChargersStatus"
  >
    <template #dashboard-actions>
      <wb-button
        data-test-id="addChargerBtn"
        :label="t('mywb.common.add-charger')"
        variant="white"
        outlined
        :to="{
          name: ROUTES.CHARGERS,
          query: {
            'add-chargers': '1',
            'group-uid': state.organizations.getCurrentOrganization.groupUid
          }
        }"
        size="small"
        icon="add"
      />

      <wb-button
        data-test-id="viewAllChargers"
        :label="t('mywb.common.view-all')"
        :to=" { name: ROUTES.CHARGERS }"
        variant="white"
        icon="chevron_right"
        icon-position="right"
        size="small"
      />
    </template>
  </dashboard-card>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import DashboardCard from '@/components/dashboard/DashboardCard.vue'
import { reactive, computed } from 'vue'
import state from '@/state'
import { numbers } from '@wallbox/toolkit-ui'
import { filterChargerByUsers } from '@/utilities/users'
import type { Charger } from '@/core/charger'
import ROUTES from '@/engine/router/routes'

const { t, locale } = useI18n()

interface Props {
  loading: boolean
}

const props = defineProps<Props>()

const compute = reactive({
  totalChargersLocale: computed((): string => {
    return numbers.toLocaleNumber(compute.totalChargers, locale.value)
  }),

  chargersFilterByLocations: computed((): Charger[] => {
    let chargers = state.charger.getChargers
    if (state.filters.dashboardFilters.locations.length > 0) {
      chargers = chargers.filter(chargers => state.filters
        .dashboardFilters.locations.some(location => location.id === chargers.locationId))
    }

    return chargers
  }),

  totalChargers: computed((): number => {
    return filterChargerByUsers(
      compute.chargersFilterByLocations,
      state.filters.dashboardFilters.users.map(user => user.id)
    ).length
  })
})
</script>
