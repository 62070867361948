<template>
  <wb-alert
    v-if="data.chargersCount && permissions.canViewUpdateChargersPending && permissions.hasBulkActions"
    icon="download_circle"
    variant="info"
    :action="t('mywb.common.update-number', {num: data.chargersCount})"
    class="mb-12"
    @on-action-click="methods.openModal"
  >
    {{ t('mywb.charger.num-chargers-with-update-pending') }}
  </wb-alert>

  <chargers-update-action-modal
    v-if="data.isModalUpdateActionsOpen"
    @close="data.isModalUpdateActionsOpen = false"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { reactive } from 'vue'
import state from '@/state'
import { permissions } from '@/engine/clients'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import ChargersUpdateActionModal from '@/components/modals/ChargersUpdateActionModal.vue'
import { CHARGER_USE_CASES, injectStrict } from '@/engine/injectors'

const chargerUseCases = injectStrict(CHARGER_USE_CASES)
const { t } = useI18n()

const data = reactive({
  isModalUpdateActionsOpen: false,
  chargersCount: 0
})

const methods = {
  openModal () {
    trackDataEvent('dashboard-updates', { widget: 'chargerStatus', type: 'updateAll' })
    data.isModalUpdateActionsOpen = true
  }
}

async function created () {
  data.chargersCount =
    await chargerUseCases.getAmountOfChargersWithUpdates(state.organizations.getCurrentOrganization.groupUid)
}
created()
</script>
