<template>
  <wb-card no-padding>
    <template #content>
      <wb-table
        :options="compute.options"
        :loading="props.loading"
      >
        <user-invitation-table-row
          v-for="(userInvitation, key) in props.usersInvitation"
          :key="`invitation-${key}`"
          :user-invitation="userInvitation"
          :has-permision-to-actions="permissions.canRevokeOrResendUserInvitation"
          :data-test-id="`invitation-${userInvitation.id}`"
          @on-resend="emit('on-resend-invitation', { invitationId: userInvitation.id })"
          @on-revoke="emit('on-revoke-invitation', { invitationId: userInvitation.id })"
        />

        <user-list-table-row
          v-for="(user, key) in props.users"
          :key="`access-config-${key}`"
          :user="user"
          :has-permision-to-actions="methods.hasPermisionToActions(user.email)"
          :data-test-id="`user-${user.id}`"
          @on-click="emit('on-click', user.uid)"
          @on-remove="emit('remove-user', user)"
        />
      </wb-table>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import UserListTableRow from '@/components/tables/UserListTableRow.vue'
import UserInvitationTableRow from '@/components/tables/UserInvitationTableRow.vue'
import { permissions } from '@/engine/clients'
import { reactive, computed } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { hasRoleGreaterThan } from '@/utilities/users'
import state from '@/state'
import type { TableProps, ExcludesNullish } from '@wallbox/toolkit-ui'
import type { UserList } from '@/core/user'
import { useMq } from 'vue3-mq'

const i18n = useI18n()
const mq = useMq()

interface Props {
  users?: UserList[]
  usersInvitation?: UserList[]
  loading?: boolean
}

const props = defineProps<Props>()

interface Events {
  (e: 'remove-user', user: UserList): void,
  (e: 'on-click', uid: string): void,
  (e: 'on-resend-invitation', payload: { invitationId: number }): void,
  (e: 'on-revoke-invitation', payload: { invitationId: number }): void
}

const emit = defineEmits<Events>()

const compute = reactive({
  options: computed((): TableProps['options'] => {
    return {
      offset: '0 8px',
      columns: [
        { name: i18n.t('mywb.common.user'), align: 'left', minWidth: 380 },
        { name: i18n.t('mywb.common.access-group'), align: 'left', minWidth: 180 },
        { name: i18n.t('mywb.common.type'), align: 'left', minWidth: 110 },
        permissions.showPayments && {
          name: i18n.t('mywb.charger.pay-per-month'),
          align: 'center',
          width: 100
        },
        { name: i18n.t('mywb.common.status'), align: 'center', minWidth: 100 },
        {
          name: i18n.t('mywb.common.actions'),
          align: 'left',
          minWidth: 300,
          lineBehaviour: 'multiline'
        }
      ].filter(Boolean as unknown as ExcludesNullish) as TableProps['options']['columns'],
      stickyHead: mq.current === 'mobile' ? 'var(--header-height)' : 'var(--header-filters-height)',
      empty: {
        icon: 'table_rows',
        title: i18n.t('mywb.common.table-empty')
      }
    }
  })
})

const methods = {
  hasPermisionToActions (email: string) {
    const isOwner = state.user.userLogged.email === state.organizations.getCurrentOrganization.ownerEmail
    return isOwner || hasRoleGreaterThan(email)
  }
}
</script>
