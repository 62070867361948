<template>
  <wb-table-row
    @click="emit('on-change-organization', props.organization)"
  >
    <wb-table-row-item data-test-id="name">
      <span class="is-font-weight-500">{{ props.organization.name }}</span>
      <span
        v-if="state.organizations.getCurrentOrganization.id === organization.id"
        class="wb-icons is-size-400 ml-8 has-text-primary-500"
      >check</span>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="owner" class="has-text-overflow">
      <p class="has-text-overflow">
        {{ props.organization.ownerEmail }}
      </p>
    </wb-table-row-item>
    <wb-table-row-item data-test-id="role">
      {{ i18n.t(props.organization.role.key) }}
    </wb-table-row-item>
    <wb-table-row-item data-test-id="plan">
      {{ i18n.t(props.organization.plan.label) }}
    </wb-table-row-item>
    <wb-table-row-item>
      <wb-button
        icon="edit"
        size="small"
        variant="white"
        outlined
        :disabled="!compute.canEditOrganization"
        data-test-id="editButton"
        class="group-item"
        @click.stop="methods.handleOrganizationDetail"
      />
    </wb-table-row-item>
  </wb-table-row>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import state from '@/state'
import type { Organization } from '@/core/organization'
import { useI18n } from 'vue-i18n'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

const props = defineProps<{
  organization: Organization
}>()

const emit = defineEmits(['on-change-organization'])

const compute = reactive({
  hasOperatorPlanInRootGroup: computed((): boolean => state.organizations.allOrganizations
    .find(organization => organization.role.id === EnumRoles['super-admin'])?.plan.product_id === EnumPlans.OPERATOR),

  canEditOrganization: computed((): boolean => {
    return [EnumRoles['super-admin'], EnumRoles.operator].includes(props.organization.role.id) ||
      ((compute.hasOperatorPlanInRootGroup && props.organization.role.id === EnumRoles.operator) ||
        props.organization.role.id === EnumRoles['super-admin'])
  })
})

const methods = {
  handleOrganizationDetail () {
    router.push({ name: ROUTES.ORGANIZATION_DETAIL, params: { organizationUid: props.organization.groupUid } })
  }
}
</script>
