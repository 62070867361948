import LocationsView from '@/views/LocationsView.vue'
import LocationView from '@/views/LocationView.vue'
import type { RouteRecordRaw } from 'vue-router'
import ROUTES from './routes'

export default [
  {
    path: 'locations',
    name: ROUTES.LOCATIONS,
    component: LocationsView,
    meta: {
      resetBreadcrumb: true,
      breadcrumb: {
        name: ROUTES.LOCATIONS,
        text: 'mywb.common.locations'
      }
    }
  },
  {
    path: 'locations/:locationId',
    name: ROUTES.LOCATION_DETAIL,
    component: LocationView,
    props: true,
    meta: {
      breadcrumb: {
        name: ROUTES.LOCATION_DETAIL
      }
    }
  }
] as RouteRecordRaw[]
