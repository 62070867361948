import i18n from '@/engine/lang'
import { EnumRoles } from '@/utilities/user-roles'
import { EnumPlans } from '@/utilities/plans'
import {
  hasRole,
  hasPlan,
  and,
  or,
  isOwner,
  hasCountryPaymentsSupport
} from '../validators'
import type { Client } from './client'
import { getGallonsFromLiters, getPoundsFromKilograms, toLocaleUnit } from '@/core/international'

const wallbox: Client = {
  name: 'wallbox',
  partner: 'wallbox',
  brandingId: 'com.wallbox',
  actions: {
    editRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showSelectedChargers: true,
    canChangePlan: false,
    canSeeSupport: true,
    showPayments: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showBusinessPage: false,
    showBilling: and(
      hasCountryPaymentsSupport(),
      hasRole(EnumRoles['super-admin'], EnumRoles.operator)
    ),
    canEditChargerName: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    showAutoReporting: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    hasBulkActions: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    hasAuthToRestartAndUpdate: hasRole(EnumRoles['super-admin'], EnumRoles.admin, EnumRoles.operator),
    canMarkAsPaid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canAssignedRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canSeeRfid: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCharge: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeChargerDetail: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddChargerFromEmptyState: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddCharger: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canWriteEnergyCost: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeLocations: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canViewUpdateChargersPending: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canCreateUserGroups: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    isDisabledSelectAccessGroup: hasRole(EnumRoles.admin, EnumRoles.user),
    showUserGroupOptions: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canSeeDashboard: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canSeeUsers: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canAddUser: hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
    canEditAndDeleteUserGroupOption: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canAddRfidInInvitations: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canUserListRfidEdit: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    canUserListRfidActions: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    canRevokeOrResendUserInvitation: hasRole(EnumRoles.operator, EnumRoles.admin, EnumRoles['super-admin']),
    hasSocialSingin: true,
    showPaymentsEngage: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    showUpSellingToPlanBusiness: false,
    canConnectStripe: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
    canOperateOrganizations: or(
      hasPlan(EnumPlans.OPERATOR),
      hasRole(EnumRoles.operator)
    ),
    showCarouselLogin: true,
    canAddOperators: and(
      isOwner(),
      hasRole(EnumRoles.operator)
    ),
    canSeeModalEditOrganization: and(
      isOwner()
    ),
    canAddChargePoints: and(hasRole(EnumRoles['super-admin']))
  },

  energyManagmentSupport: 'https://support.wallbox.com/en/knowledge-base/energy-management-solutions/',

  ecommerceUrl: 'https://wallbox.com/',

  docs: {
    terms: '/docs/wallbox_terms_conditions_en_uk.pdf',
    privacy: '/docs/wallbox_privacy_policy_en.pdf'
  },

  logo: {
    login: {
      lottie: true,
      src: 'logos/wallbox_logo.svg',
      alt: 'myWallbox',
      style: 'width: 75%; max-width: 300px'
    },
    aside: { src: 'logos/wallbox-logo-inverted.svg', alt: 'Wallbox' },
    asideCollapse: { src: 'logos/logo-wallbox-icon-inverted.svg', alt: 'Wallbox' },
    header: { src: 'logos/wallbox_logo.svg', style: 'height: 56px;', alt: 'wallbox-logo' }
  },

  units: {
    volume: {
      unit: 'L',
      name: 'liters',
      formula: liters => toLocaleUnit({
        locale: i18n.locale,
        unit: 'liter',
        value: liters
      })
    },
    mass: {
      unit: 'Kg',
      name: 'Kilograms',
      formula: kilograms => toLocaleUnit({
        locale: i18n.locale,
        unit: 'kilogram',
        value: kilograms
      })
    }
  },

  regions: {
    usa: {
      name: 'wallbox-usa',

      actions: {
        canChangePlan: and(
          hasRole(EnumRoles['super-admin'])
        ),
        showPayments: and(
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR),
          hasRole(EnumRoles['super-admin'], EnumRoles.operator)
        ),
        showBusinessPage: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BASIC)
        ),
        canSeeLocations: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        canCreateUserGroups: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        isDisabledSelectAccessGroup: or(
          hasRole(EnumRoles.admin, EnumRoles.user),
          hasPlan(EnumPlans.BASIC)
        ),
        showAutoReporting: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        hasBulkActions: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator, EnumRoles.admin),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        canEditAndDeleteUserGroupOption: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        showPaymentsEngage: hasRole(EnumRoles['super-admin'], EnumRoles.operator),
        showUpSellingToPlanBusiness: and(
          hasRole(EnumRoles['super-admin']),
          hasPlan(EnumPlans.BASIC)
        ),
        canConnectStripe: and(
          hasRole(EnumRoles['super-admin'], EnumRoles.operator),
          hasPlan(EnumPlans.BUSINESS, EnumPlans.OPERATOR)
        ),
        canSeeSupport: false
      },

      docs: {
        terms: '/docs/wallbox_terms_conditions_usa_en_05_04_20.pdf',
        privacy: '/docs/wallbox_privacy_policy_usa_en_08_27_20.pdf'
      },

      units: {
        volume: {
          unit: 'gal',
          name: 'gallons ',
          formula: liters => toLocaleUnit({
            unit: 'gallon',
            value: getGallonsFromLiters(liters)
          })
        },
        mass: {
          unit: 'lb',
          name: 'Pounds',
          formula: kilograms => toLocaleUnit({
            unit: 'pound',
            value: getPoundsFromKilograms(kilograms)
          })
        }
      }
    },

    spain: {
      name: 'wallbox-spain',
      docs: {
        privacy: '/docs/wallbox_privacy_policy_es.pdf',
        terms: '/docs/wallbox_terms_conditions_es.pdf'
      }
    },

    europeanUnion: {
      docs: {
        terms: '/docs/wallbox_terms_conditions_en.pdf'
      }
    }
  }
}

export default wallbox
