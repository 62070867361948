<template>
  <div class="wrapper-grid g-16" data-test-id="chargerDetailSettings">
    <wb-card class="menu-sticky">
      <template #content>
        <wb-menu
          v-model="data.selected"
          :title="i18n.t('mywb.common.general')"
          :items="data.generalMenu"
          class="mb-16"
        />
        <wb-menu
          v-model="data.selected"
          :title="i18n.t('mywb.common.external-management')"
          :items="compute.externalManagementMenu"
          class="mb-16"
        />
        <wb-menu
          v-if="!isOcpp(state.charger.getChargerCore) || data.isGunLockCompatible"
          v-model="data.selected"
          :title="i18n.t('mywb.common.security')"
          :items="compute.securityMenu"
          class="mb-16"
        />
        <wb-menu
          v-model="data.selected"
          :title="i18n.t('mywb.common.payments')"
          :items="compute.paymentsMenu"
          class="mb-16"
        />
        <wb-menu
          v-if="hasFeatureFlagActive('auto-update')"
          v-model="data.selected"
          :title="i18n.t('mywb.common.software')"
          :items="compute.softwareMenu"
        />
      </template>
    </wb-card>

    <div>
      <h2 v-t="'mywb.common.general'" class="mb-16 is-header-text" />
      <div class="is-flex g-8 general-settings">
        <charger-edit-name-card
          id="name"
          data-test-id="chargerNameCard"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'name'}"
        />
        <charger-edit-location-card
          id="location"
          :loading="props.loading"
          :location="data.location"
          :class="{ 'selected-blink': data.selected === 'location'}"
          @on-change-location="methods.getLocation()"
        />
        <charger-power-limit-card
          id="current"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'current'}"
        />
        <charger-edit-timezone-card
          id="timezone"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'timezone'}"
        />
      </div>

      <h2 v-t="'mywb.common.external-management'" class="mt-32 mb-16 is-header-text" />
      <div class="is-flex g-8 general-settings">
        <charger-edit-ocpp-card
          v-if="compute.supportsOcpp"
          id="ocpp"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'ocpp'}"
        />

        <charger-remote-assistance-card
          id="remoteAssistance"
          :loading="props.loading"
          :class="{ 'selected-blink': data.selected === 'remoteAssistance'}"
        />
      </div>

      <h2
        v-if="!isOcpp(state.charger.getChargerCore)"
        v-t="'mywb.common.security'"
        class="mt-32 mb-16 is-header-text"
      />
      <charger-settings-charge-mode-card
        v-if="!isOcpp(state.charger.getChargerCore)"
        :loading="props.loading"
        :selected="data.selected"
      />

      <charger-lock-gun
        v-if="data.isGunLockCompatible"
        id="gunLock"
        :loading="props.loading"
        :class="{ 'selected-blink': data.selected === 'gunLock'}"
      />

      <h2 id="payments" v-t="'mywb.common.payments'" class="mt-32 mb-16 is-header-text" />
      <charger-settings-payments-card
        v-if="permissions.showPayments && !isOcpp(state.charger.getChargerCore)"
        :payments-compatible="data.paymentsCompatible"
        :loading="props.loading || data.loading"
        :selected="data.selected ?? ''"
      />

      <charger-settings-energy-cost-card
        v-if="permissions.canWriteEnergyCost && data.location"
        id="energyCost"
        :loading="props.loading || data.loading"
        :location="data.location"
        :class="{ 'selected-blink': data.selected === 'energyCost'}"
        class="mt-8"
      />

      <template v-if="hasFeatureFlagActive('auto-update')">
        <h2 id="software" v-t="'mywb.common.software'" class="mt-32 mb-16 is-header-text" />
        <charger-settings-auto-update
          :loading="props.loading || data.loading"
          :class="{ 'selected-blink': data.selected === 'software'}"
        />
      </template>
    </div>
  </div>
  <accept-serviceability-modal
    v-if="data.isModalAcceptServiceabilityOpen"
    @close="data.isModalAcceptServiceabilityOpen = false"
  />
</template>

<script setup lang="ts">
import ChargerLockGun from '@/components/charger/ChargerLockGun.vue'
import ChargerEditNameCard from '@/components/charger/ChargerEditNameCard.vue'
import ChargerPowerLimitCard from '@/components/charger/ChargerPowerLimitCard.vue'
import ChargerEditTimezoneCard from '@/components/charger/ChargerEditTimezoneCard.vue'
import ChargerEditLocationCard from '@/components/charger/ChargerEditLocationCard.vue'
import ChargerEditOcppCard from '@/components/charger/ChargerEditOcppCard.vue'
import ChargerRemoteAssistanceCard from '@/components/charger/ChargerRemoteAssistanceCard.vue'
import ChargerSettingsPaymentsCard from '@/components/charger/ChargerSettingsPaymentsCard.vue'
import ChargerSettingsChargeModeCard from '@/components/charger/ChargerSettingsChargeModeCard.vue'
import ChargerSettingsAutoUpdate from '@/components/charger/ChargerSettingsAutoUpdate.vue'
import { reactive, computed, watchEffect, onMounted, watch, nextTick } from 'vue'
import { useI18n } from '@/hooks/useI18n.hook'
import { supportsOcpp, isOcpp } from '@/utilities/charger/chargerOcppSettings'
import ChargerSettingsEnergyCostCard from '@/components/charger/ChargerSettingsEnergyCostCard.vue'
import { permissions } from '@/engine/clients'
import state from '@/state'
import { useRoute } from 'vue-router'
import type { ExcludesNullish, MenuItem } from '@wallbox/toolkit-ui'
import type { Location } from '@/core/location'
import { CHARGER_USE_CASES, LOCATION_USE_CASES, injectStrict } from '@/engine/injectors'
import type { ChargerPaymentsCompatible } from '@/core/charger/domain/ChargerPaymentsCompatible'
import { hasFeatureFlagActive } from '@/engine/featureFlags/featureFlags.state'
import { trackDataScreen } from '@/engine/metrics/trackDataManager'
import AcceptServiceabilityModal from '@/components/modals/AuthAcceptServiceabilityModal.vue'

const i18n = useI18n()
const route = useRoute()
const usesCases = injectStrict(LOCATION_USE_CASES)
const chargerUseCases = injectStrict(CHARGER_USE_CASES)

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  }
})

interface Data {
  selected?: string
  generalMenu: MenuItem[]
  location?: Location
  loading?: boolean
  isGunLockCompatible: boolean
  paymentsCompatible: ChargerPaymentsCompatible
  isModalAcceptServiceabilityOpen?: boolean
}

const data: Data = reactive({
  generalMenu: [
    {
      name: 'name',
      icon: 'charger',
      label: i18n.t('mywb.charger.charger-name')
    },
    {
      name: 'location',
      icon: 'location',
      label: i18n.t('mywb.common.location')
    },
    {
      name: 'current',
      icon: 'bolt',
      label: i18n.t('mywb.common.power-limit')
    },
    {
      name: 'timezone',
      icon: 'clock',
      label: i18n.t('mywb.common.timezone')
    }
  ],

  isGunLockCompatible: false,

  paymentsCompatible: {
    payPerCharge: {
      isCompatible: false
    },
    payPerMonth: {
      isCompatible: false
    }
  }
})

const compute = reactive({
  externalManagementMenu: computed((): MenuItem[] => [
    compute.supportsOcpp && {
      name: 'ocpp',
      icon: 'ocpp_control',
      label: i18n.t('mywb.charger.ocpp')
    },
    {
      name: 'remoteAssistance',
      icon: 'remote_assistance',
      label: i18n.t('mywb.common.remote-assistance')
    }
  ].filter(Boolean as unknown as ExcludesNullish)),

  supportsOcpp: computed(() => {
    return supportsOcpp(state.charger.getChargerCore)
  }),

  hasOrganizationPaymentsAccount: computed(() => !!state.organizations.getCurrentOrganization.paymentsAccount),

  securityMenu: computed((): MenuItem[] => [
    !isOcpp(state.charger.getChargerCore) && {
      name: 'access',
      icon: '',
      label: i18n.t('mywb.common.open-access')
    },
    !isOcpp(state.charger.getChargerCore) && {
      name: 'lock',
      icon: '',
      label: i18n.t('mywb.common.restricted-or-paid-access')
    },
    data.isGunLockCompatible && {
      name: 'gunLock',
      icon: '',
      label: i18n.t('mywb.charger.gun-lock')
    }
  ].filter(Boolean as unknown as ExcludesNullish)),

  paymentsMenu: computed((): MenuItem[] => [
    (permissions.showPayments &&
      data.paymentsCompatible.payPerCharge.isCompatible &&
      !isOcpp(state.charger.getChargerCore)
    ) && {
      name: 'payPerCharge',
      icon: 'card',
      label: i18n.t('mywb.charger.pay-per-charge')
    },
    (permissions.showPayments &&
      data.paymentsCompatible.payPerMonth.isCompatible &&
      !isOcpp(state.charger.getChargerCore)
    ) && {
      name: 'payPerMonth',
      icon: 'calendar',
      label: i18n.t('mywb.charger.pay-per-month')
    },
    permissions.canWriteEnergyCost && {
      name: 'energyCost',
      icon: 'money',
      label: i18n.t('mywb.common.energy-cost')
    }
  ].filter(Boolean as unknown as ExcludesNullish)),

  softwareMenu: computed((): MenuItem[] => [
    {
      name: 'software',
      icon: 'download_circle',
      label: i18n.t('mywb.charger.auto-update')
    }
  ])
})

const methods = {
  async getLocation () {
    data.location = await usesCases.getLocation(state.charger.getChargerCore.locationId)
  }
}

watch(() => props.loading, async () => {
  if (!props.loading) {
    data.loading = true
    await methods.getLocation()
    data.loading = false
  }
}, { immediate: true })

watch([() => props.loading, () => state.charger.getChargerCore.autoLock === false], async () => {
  if (props.loading) return

  if (permissions.showPayments && compute.hasOrganizationPaymentsAccount) {
    data.paymentsCompatible = await chargerUseCases.getIsChargeCompatibleWithPayments(
      state.charger.getChargerCore
    )
  }
}, { immediate: true })

watchEffect(() => {
  data.selected && document.getElementById(data.selected)?.scrollIntoView?.({
    behavior: 'smooth',
    block: 'center'
  })
})

watchEffect(() => {
  if (route.query.accept_link) {
    data.isModalAcceptServiceabilityOpen = true
  }
})

watchEffect(async () => {
  const supportGunLock = await chargerUseCases.getIsChargerCompatibleWithGunLock(state.charger.getChargerCore)
  data.isGunLockCompatible = supportGunLock.isCompatible
})

onMounted(() => {
  trackDataScreen('charger-settings-view')

  if (route.query?.selected) {
    nextTick(() => {
      setTimeout(() => {
        data.selected = route.query.selected?.toString() ?? ''
        data.selected && document.getElementById(data.selected)?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'center'
        })
      }, 1000)
    })
  }
})

</script>

<style lang="postcss" scoped>
.wrapper-grid {
  @media (--tablet) {
    display: grid;
    grid-template-columns: min-content auto;
  }
}

.general-settings {
  flex-direction: column;
}

.menu-sticky {
  display: none;

  @media (--tablet) {
    display: block;
    position: sticky!important;
    top: 160px!important;
    height: fit-content;
  }
}
</style>
