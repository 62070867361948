export enum STATUSES {
  WAITING = 'waiting',
  CHARGING = 'charging',
  READY = 'ready',
  PAUSED = 'paused',
  SCHEDULED = 'scheduled',
  DISCHARGING = 'discharging',
  ERROR = 'error',
  DISCONNECTED = 'disconnected',
  LOCKED = 'locked',
  RESERVED = 'reserved',
  UPDATING = 'updating'
}

export interface Status {
  code: STATUSES
  label: string
  description: string
  ids: Array<number | null>
  remote: boolean
  filter: boolean
  dataTestId: string
}

export const chargerStatuses: readonly Status[] = [
  {
    code: STATUSES.WAITING,
    label: 'mywb.charger.status.waiting',
    description: 'mywb.charger.status.waiting.description',
    ids: [164, 180, 181, 183, 184, 185, 186, 187, 188, 189],
    remote: false,
    filter: true,
    dataTestId: 'waitingFilterTag'
  },
  {
    code: STATUSES.CHARGING,
    label: 'mywb.charger.status.charging',
    description: 'mywb.charger.status.charging.description',
    ids: [193, 194],
    remote: true,
    filter: true,
    dataTestId: 'chargingFilterTag'
  },
  {
    code: STATUSES.READY,
    label: 'mywb.charger.status.ready',
    description: 'mywb.charger.status.ready.description',
    ids: [161],
    remote: false,
    filter: true,
    dataTestId: 'readyFilterTag'
  },
  {
    code: STATUSES.PAUSED,
    label: 'mywb.charger.status.paused',
    description: 'mywb.charger.status.paused.description',
    ids: [178, 182],
    remote: true,
    filter: true,
    dataTestId: 'pausedFilterTag'
  },
  {
    code: STATUSES.SCHEDULED,
    label: 'mywb.charger.status.scheduled',
    description: 'mywb.charger.status.scheduled.description',
    ids: [177, 179],
    remote: true,
    filter: true,
    dataTestId: 'scheduledFilterTag'
  },
  {
    code: STATUSES.DISCHARGING,
    label: 'mywb.charger.status.discharging',
    description: 'mywb.charger.status.charging.description',
    ids: [195, 196],
    remote: true,
    filter: true,
    dataTestId: 'dischargingFilterTag'
  },
  {
    code: STATUSES.ERROR,
    label: 'mywb.charger.status.error',
    description: 'mywb.charger.status.error.description',
    ids: [14, 15, 162],
    remote: false,
    filter: true,
    dataTestId: 'errorFilterTag'
  },
  {
    code: STATUSES.DISCONNECTED,
    label: 'mywb.charger.status.disconnected',
    description: 'mywb.charger.status.disconnected.description',
    ids: [0, 163, null],
    remote: false,
    filter: true,
    dataTestId: 'disconnectedFilterTag'
  },
  {
    code: STATUSES.LOCKED,
    label: 'mywb.charger.status.locked',
    description: 'mywb.charger.status.locked.description',
    ids: [209, 210],
    remote: true,
    filter: true,
    dataTestId: 'lockedFilterTag'
  },
  {
    code: STATUSES.RESERVED,
    label: 'mywb.charger.status.reserved',
    description: 'mywb.charger.status.reserved.description',
    ids: [165],
    remote: true,
    filter: true,
    dataTestId: 'reservedFilterTag'
  },
  {
    code: STATUSES.UPDATING,
    label: 'mywb.charger.status.updating',
    description: 'mywb.charger.status.updating.description',
    ids: [166],
    remote: false,
    filter: false,
    dataTestId: 'updatingFilterTag'
  }
]

export const waitingStatuses = [
  {
    ids: [180, 181],
    description: 'mywb.charger.status.waiting.waiting-for-car-demand'
  },
  {
    ids: [183, 184],
    description: 'mywb.charger.status.waiting.in-queue-by-power-sharing'
  },
  {
    ids: [185, 186],
    description: 'mywb.charger.status.waiting.in-queue-by-power-boost'
  },
  {
    ids: [187],
    description: 'mywb.charger.status.waiting.mid-failed'
  },
  {
    ids: [188],
    description: 'mywb.charger.status.waiting.mid-safety-margin-exceeded'
  },
  {
    ids: [189],
    description: 'mywb.charger.status.waiting.in-queue-by-eco-smart'
  },
  {
    ids: [164],
    description: 'mywb.charger.status.waiting.ocpp-charge-finish'
  }
] as const

export const getColorByStatus = (statusCode: STATUSES) => {
  const colors = {
    available: {
      bg: 'var(--waiting)',
      text: 'var(--white)'
    },
    locked: {
      bg: 'var(--locked)',
      text: 'var(--black)'
    },
    reserved: {
      bg: 'var(--locked)',
      text: 'var(--black)'
    },
    waiting: {
      bg: 'var(--waiting)',
      text: 'var(--white)'
    },
    paused: {
      bg: 'var(--paused)',
      text: 'var(--white)'
    },
    disconnected: {
      bg: 'var(--disconnected)',
      text: 'var(--black)'
    },
    ready: {
      bg: 'var(--ready)',
      text: 'var(--white)'
    },
    charging: {
      bg: 'var(--charging)',
      text: 'var(--white)'
    },
    discharging: {
      bg: 'var(--discharging)',
      text: 'var(--white)'
    },
    scheduled: {
      bg: 'var(--scheduled)',
      text: 'var(--white)'
    },
    error: {
      bg: 'var(--error)',
      text: 'var(--white)'
    },
    updating: {
      bg: 'var(--updating)',
      text: 'var(--white)'
    }
  }
  return colors[statusCode] ?? colors.disconnected
}

export const statusWaitingById = (id: number) => {
  return waitingStatuses.find(status => (status.ids as unknown as number[]).includes(id))?.description ?? ''
}

export const getIdsByStatuses = (statuses: STATUSES[]) => {
  return chargerStatuses
    .filter(status => statuses.some(code => code === status.code))
    .flatMap(status => status.ids)
}
