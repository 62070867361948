<template>
  <shared-teleport-header>
    <template #title>
      {{ i18n.t('mywb.common.locations') }}
    </template>
  </shared-teleport-header>

  <wb-card
    filled
  >
    <template #content>
      <wb-empty-state
        :title="i18n.t('mywb.common.start-creating-locations')"
        :subtitle="i18n.t('mywb.common.locations-empty-state')"
      >
        <template #image>
          <client-img-component
            inline
            src="locations"
            width="180"
          />
        </template>
        <template #subheading>
          <wb-badge
            v-if="!permissions.canSeeLocations"
            data-test-id="labelLock"
            inverted
            variant="grey"
            icon="lock"
            class="mb-8"
          >
            {{ i18n.t('mywb.plans.business') }}
          </wb-badge>
        </template>
        <wb-link
          v-if="permissions.canChangePlan && !permissions.canSeeLocations"
          class="is-size-400 u-block mb-8"
          data-test-id="upgradePlanBtn"
          @click="methods.goToPlans"
        >
          {{ i18n.t('mywb.plan.upgrade') }}
        </wb-link>
        <wb-button
          v-if="permissions.canSeeLocations"
          icon="add"
          data-test-id="addLocationBtn"
          :label="i18n.t('mywb.charger.add-location')"
          variant="primary"
          @click="emit('on-add-group')"
        />
      </wb-empty-state>
    </template>
  </wb-card>
</template>

<script setup lang="ts">
import { useI18n } from '@/hooks/useI18n.hook'
import { permissions } from '@/engine/clients'
import { trackDataAction } from '@/engine/metrics/trackDataManager'
import SharedTeleportHeader from '@/components/headers/SharedTeleportHeader.vue'
import ClientImgComponent from '@/components/ClientImgComponent.vue'
import { useRouter } from 'vue-router'
import ROUTES from '@/engine/router/routes'

const i18n = useI18n()
const router = useRouter()

interface Events {
  (e: 'on-add-group'): void
}

const emit = defineEmits<Events>()

const methods = {
  goToPlans () {
    router.push({ name: ROUTES.PLAN_BUSINESS })
    trackDataAction('upgrade_plan', {
      source: 'locations'
    })
  }
}
</script>
