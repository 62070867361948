<template>
  <wb-modal
    width="50"
    @close="emit('close')"
  >
    <template #title>
      {{ i18n.t('mywb.sessions.reporting-settings') }}
    </template>

    <div class="justify-between is-flex">
      <p class="is-size-400 is-font-weight-500">
        {{ i18n.t('mywb.sessions.reporting-enable') }}
      </p>
      <wb-switch
        v-model="data.autoReporting"
        data-test-id="reportingSwitch"
        @update:model-value="data.valueChanged = true"
      />
    </div>
    <p
      v-t="'mywb.sessions.reporting-desc'"
      class="is-size-400 my-8"
    />

    <wb-radio-card
      :model-value="data.autoReporting"
      :disabled="!data.autoReporting"
      value
      class="my-8"
    >
      <span v-t="'mywb.common.month'" class="is-size-400 has-text-grey-700 is-font-weight-500" />
      &nbsp;
      <span v-t="'mywb.sessions.reporting-month-desc'" class="is-size-400" />
    </wb-radio-card>

    <template #actions>
      <wb-button
        data-test-id="reportingCta"
        size="block"
        :disabled="!data.valueChanged"
        :label="i18n.t('mywb.common.save')"
        :loading="data.loading"
        @click="methods.activeAutoReporting"
      />
    </template>
  </wb-modal>
</template>

<script setup lang="ts">
import state from '@/state'
import { trackDataEvent } from '@/engine/metrics/trackDataManager'
import { useToast } from '@wallbox/toolkit-ui'
import { useI18n } from '@/hooks/useI18n.hook'
import { reactive } from 'vue'
import { AUTH_USE_CASES, injectStrict } from '@/engine/injectors'

const authUseCases = injectStrict(AUTH_USE_CASES)
const i18n = useI18n()
const toast = useToast()

interface Props {
  modelValue: boolean
}

const props = defineProps<Props>()

type Events = {
  (e: 'close'): void
  (e: 'update:modelValue', reporting: boolean): void
}

const emit = defineEmits<Events>()

type DataType = {
  autoReporting: boolean
  loading: boolean
  valueChanged: boolean
}

const data: DataType = reactive({
  autoReporting: false,
  loading: false,
  valueChanged: false
})

function created () {
  data.autoReporting = props.modelValue
}

const methods = {
  async activeAutoReporting () {
    data.loading = true
    try {
      authUseCases.updateAutoReporting({
        userId: state.user.userLogged.id,
        autoReporting: data.autoReporting
      })

      emit('update:modelValue', data.autoReporting)

      trackDataEvent('automatic-reporting')
    } catch {
      toast.error(i18n.t('mywb.error.unexpected-error'))
    }

    data.loading = false
    emit('close')
  }
}

created()
</script>

<style lang="postcss" scoped>
.justify-between {
  justify-content: space-between;
}
</style>
